import dayjs from 'dayjs';
import { isArray, isObject } from 'lodash';
import camelCase from 'lodash/fp/camelCase';
import compose from 'lodash/fp/compose';
import mapKeys from 'lodash/fp/mapKeys';
import snakeCase from 'lodash/fp/snakeCase';
import store from 'store2';

export const mapKeysToCamelCase = compose(mapKeys(camelCase));
export const mapKeysToSnakeCase = compose(mapKeys(snakeCase));
export const transformToSnakeCase = (obj: any): any =>
  isArray(obj)
    ? obj.reduce((acc: any, value: any) => {
        const newElement = isObject(value) ? transformToSnakeCase(value) : value;
        return [...acc, newElement];
      }, [])
    : Object.entries(obj).reduce((acc: any, [key, value]: any) => {
        const snakeKey = snakeCase(key);
        return { ...acc, [snakeKey]: isObject(value) && key !== 'file' ? transformToSnakeCase(value) : value };
      }, {});

const mapDateFormat: Record<string, string> = {
  'dd/mm/yyyy': 'DD-MM-YYYY',
  'mm/dd/yyyy': 'MM-DD-YYYY',
};

export const formatDate = (value: string): string => {
  const localSettings = store('driverbook_settings');
  const format = localSettings ? mapDateFormat[localSettings.date_format as string] : 'DD-MM-YYYY';

  return dayjs(value).format(format);
};

export const roundNumber = (value: number) => {
  const m = Number((Math.abs(value) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(value);
};

export const formatNumberMileageK = (input: string) => {
  const num = parseInt(input, 10);

  if (isNaN(num)) {
    return input;
  }

  if (num % 1000 === 0) {
    const thousands = Math.floor(num / 1000);
    return `${thousands}k`;
  } else {
    return num.toString();
  }
};
