import { Box, Button, Theme, Typography } from '@mui/material';
import { ComponentType } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useClasses } from 'utils/hooks/useClasses';
import EmptyShelves from 'assets/empty-shelves.png';
import ReminderBell from 'assets/reminder-bell.png';
import { StyledButton } from 'components/StyledButton/StyledButton';
interface EmptyBoxProps {
  entityName: 'cars' | 'records' | 'no-records' | 'public-records' | 'reminders';
  addDisabled?: boolean;
  link?: string;
}

const styles = ({ breakpoints, spacing }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 780,
    minHeight: 600,
    margin: spacing(0, 'auto'),
    padding: spacing(4),
    // backgroundColor: 'rgba(255,255,255,0.08)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconWrapper: {
    marginBottom: spacing(3),
  },
  icon: {
    fontSize: 100,
  },
  text: {
    fontSize: 16,
    opacity: 1,
    lineHeight: '24px',
    [breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
  active: {
    cursor: 'pointer',
  },
  inactive: {
    cursor: 'default',
  },
  emptyRecords: {
    maxWidth: 160,
  },
  title: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '24px',
  },
});

const getTitle = (entityName: string) => {
  switch (entityName) {
    case 'cars':
      return 'empty_box_title_cars';
    case 'records':
      return 'empty_box_title_records';
    case 'public-records':
      return 'empty_box_title_public_records';
    case 'reminders':
      return 'empty_box_title_reminders';
    default:
      return 'empty_box_title_no_records';
  }
};

export const EmptyBox: ComponentType<EmptyBoxProps> = ({ entityName, addDisabled, link }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const navigate = useNavigate();

  const { carId } = useParams<{ carId: string }>();

  const getIcon = () => {
    if (entityName === 'records' || entityName === 'cars') {
      return <img className={classes.emptyRecords} src={EmptyShelves} alt='empty' />;
    } else if (entityName === 'reminders') {
      return <img className={classes.emptyRecords} src={ReminderBell} alt='empty' />;
    } else {
      return '🤔';
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.iconWrapper}>
          <span className={classes.icon}>{getIcon()}</span>
        </div>
        <Typography className={classes.title} align='center'>
          <Trans i18nKey={getTitle(entityName)}>
            You have no
            <br />
            reminders yet.
          </Trans>
        </Typography>
        {entityName === 'no-records' && (
          <Typography variant='body1' align='center'>
            <Trans
              defaults={t('empty_box_text_no_records', { addCar: t('empty_box_text_add_car') })}
              components={{ button: <Button color='primary' onClick={() => navigate('/cars/new')}></Button> }}
            />
          </Typography>
        )}
        {entityName === 'reminders' && (
          <Box mt={2}>
            <StyledButton
              label={t('empty_box_add_reminder_button')}
              onClick={() => {
                navigate(`/reminders/${carId}/new`);
              }}
            />
          </Box>
        )}
        {entityName === 'records' && (
          <Box mt={2}>
            <StyledButton
              label={t('empty_box_add_record_button')}
              onClick={() => {
                navigate(`/records/${carId}/new`);
              }}
            />
          </Box>
        )}
      </div>
    </div>
  );
};
