import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material';
import { capitalize } from 'lodash';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import { AdaptiveSelect } from 'components';
import { Car } from 'types';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('dashboard_form');

export interface BoardFilters {
  carId: string;
  year: number;
  category: string;
}

interface DashboardFiltersProps {
  cars?: Car[];
  years: number[];
  categories: string[];
  filters: BoardFilters;
  onFiltersChange: (filters: Partial<BoardFilters>) => void;
  publicChart?: boolean;
}

const styles = ({ breakpoints, spacing }: Theme) => ({
  formControl: {
    marginBottom: spacing(2),
    '& select': {
      width: 'unset',
    },
    [breakpoints.up('md')]: {
      margin: spacing(1, 1, 2, 1),
      minWidth: 120,
    },
  },
  selectEmpty: {
    marginTop: spacing(2),
  },
});

export const DashboardFilters: ComponentType<DashboardFiltersProps> = ({
  cars = [],
  years,
  categories,
  filters,
  publicChart,
  onFiltersChange,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);

  const handleChange = (key: keyof BoardFilters) => (event: SelectChangeEvent) => {
    const value = event.target.value;
    onFiltersChange({ [key]: key === 'year' ? Number(value) : value });
  };

  return (
    <Grid container spacing={2}>
      {!publicChart && (
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth size='small' className={classes.formControl}>
            <>
              <InputLabel htmlFor='car'>{t(tKey('car'))}</InputLabel>

              <Select
                size='small'
                sx={{ display: { xs: 'block', md: 'none' } }}
                id='car'
                value={filters.carId}
                onChange={handleChange('carId')}
                label={t(tKey('car'))}
              >
                  {cars.map((car) => (
                  <MenuItem key={car.id} value={car.id}>
                    {capitalize(car.make)} {car.model}
                  </MenuItem>
                ))}
              </Select>

              <Select
                size='small'
                sx={{ display: { md: 'block', xs: 'none' } }}
                id='car'
                value={filters.carId}
                onChange={handleChange('carId')}
                label={t(tKey('car'))}
              >
                {cars.map((car) => (
                  <MenuItem key={car.id} value={car.id}>
                    {capitalize(car.make)} {car.model}
                  </MenuItem>
                ))}
              </Select>
            </>
          </FormControl>
        </Grid>
      )}
      {!!years.length && (
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth size='small' className={classes.formControl}>
            <InputLabel htmlFor='year'>{t(tKey('year'))}</InputLabel>
            <AdaptiveSelect
              id='year'
              label={t(tKey('year'))}
              options={years}
              value={filters.year}
              onChange={handleChange('year')}
            />
          </FormControl>
        </Grid>
      )}
      {!!categories.length && (
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth size='small' className={classes.formControl}>
            <InputLabel htmlFor='category'>{t(tKey('category'))}</InputLabel>
            <AdaptiveSelect
              id='category'
              label={t(tKey('category'))}
              emptyValue={t(tKey('all_categories'))}
              options={categories}
              value={filters.category}
              onChange={handleChange('category')}
              optionLabelTKey='__record_category__'
            />
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};
