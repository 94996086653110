import AutoAwesome from 'components/CustomIcons/AutoAwesome';
import { InputBaseComponentProps, TextField, TextFieldProps, Theme } from '@mui/material';
import clsx from 'clsx';
import { ComponentType, useEffect, useState } from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { useClasses } from 'utils/hooks/useClasses';

interface TextInputProps {
  name: string;
  label: string;
  textarea?: boolean;
  textFieldProps?: TextFieldProps;
  inputProps?: InputBaseComponentProps;
  customHelperText?: string;
  setHeight?: any;
  toLowerCase?: boolean;
  ai?: boolean;
}

const styles = ({ spacing }: Theme) => ({
  root: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '& label.Mui-focused': {
      color: '#bb86fc',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#bb86fc',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.87)',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#bb86fc',
      },
    },
  },
  setHeight: {
    '&.MuiFormControl-root': {
      height: '85%',
    },
    '& .MuiInputBase-root': {
      padding: '10.5px 14px',
      height: '100%',
    },
  },
  removeArrows: {
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  ai: {
    '&::before': {
      content: `""`,
      position: 'absolute',
      width: 'calc(100% - 16px)',
      height: 'calc(100% - 16px)',
      margin: 8,
      borderRadius: 2,
      background: 'rgba(187, 134, 252, 0.1)',
    },
  },
});

export const TextInput: ComponentType<TextInputProps> = ({
  name,
  label,
  textarea = false,
  textFieldProps,
  inputProps,
  customHelperText,
  setHeight,
  toLowerCase,
  ai,
}) => {
  const { control } = useFormContext();
  const { errors } = useFormState();
  const hasError = Boolean(errors[name]);
  const helperText = customHelperText ?? errors[name]?.message;

  const [value, setLocalValue] = useState('');
  const classes = useClasses(styles);

  useEffect(() => {
    setLocalValue((textFieldProps?.value as string) ?? '');
  }, [textFieldProps?.value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          {...textFieldProps}
          inputProps={{ ...inputProps }}
          {...(textarea && { multiline: true })}
          {...(textarea && { rows: 4 })}
          fullWidth
          margin='normal'
          label={label}
          variant='outlined'
          error={hasError}
          helperText={helperText}
          defaultValue={value}
          onChange={(event) => {
            toLowerCase ? field.onChange(event.target.value.toLowerCase()) : field.onChange(event);
            setLocalValue(event.target.value);
          }}
          classes={{
            root: clsx(
              classes.root,
              setHeight && classes.setHeight,
              textFieldProps?.type === 'number' && classes.removeArrows,
              ai && classes.ai
            ),
          }}
          InputProps={{
            endAdornment: ai && (
              <>
                <AutoAwesome />
              </>
            ),
          }}
          autoComplete='off'
        />
      )}
    />
  );
};
