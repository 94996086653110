import { ComponentType } from "react";

export const CopyToClipboard: ComponentType<{text: string}> = ({text}) => {
  const handleCopyClick = () => {
    navigator.clipboard.writeText(text);
  };
    return (
        <span onClick={handleCopyClick}>
          {text}
        </span>
    )
} 