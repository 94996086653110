import { Description, FormatListBulleted, ImportContacts, Menu, SvgIconComponent } from '@mui/icons-material';
import { Badge, BottomNavigation, BottomNavigationAction, Theme } from '@mui/material';
import React, { ComponentType, useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import clsx from 'clsx';
import AssistantIconColor from 'components/CustomIcons/AssistantIconColor';
import { UnregisteredPopup } from 'components/UnregisteredPopup/UnregisteredPopup';
import { useTranslation } from 'react-i18next';
import { ProfileContext as Context } from 'store/profile/store';
import { isLoggedIn } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import useCustomMatch from 'utils/hooks/useCustomMatch';
import { AddNewButton } from '../AddNewButton/AddNewButton';
import { SettingsMenuMobile } from '../SettingsMenu/SettingsMenuMobile';

interface MobileNavigationItem {
  to: string;
  icon: SvgIconComponent | React.FunctionComponent;
  label: string;
  awesomeStyle?: boolean;
  alertBadge?: boolean;
}

let mobileNavigationItems: MobileNavigationItem[] = [
  // { to: 'cars', icon: DriveEta, label: '__bottom_navigation_garage' },
  { to: 'records', icon: Description, label: '__bottom_navigation_records' },
  { to: 'stories', icon: ImportContacts, label: '__bottom_navigation_stories' },
  // { to: 'alerts', icon: Notifications, alertBadge: true, label: '__bottom_navigation_alerts' },
  { to: 'reminders', icon: FormatListBulleted, label: '__bottom_navigation_reminders' },
  { to: 'assistant', icon: AssistantIconColor, awesomeStyle: true, label: '__bottom_navigation_assistant' },
];
const styles = ({ palette, spacing }: Theme) => ({
  root: {
    position: 'fixed',
    zIndex: 5,
    bottom: 0,
    left: 0,
    width: '100%',
  },
  rootHidden: {
    display: 'none',
  },
  navigationWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: `calc(64px + env(safe-area-inset-bottom))`,
    padding: spacing(0, 0.5, 1),
    paddingBottom: 'env(safe-area-inset-bottom)',
    backgroundColor: '#303030',
    boxShadow:
      '0 -8px 10px 1px rgba(0, 0, 0, 0.14), 0 -3px 14px 2px rgba(0, 0, 0, 0.12), 0 -5px 5px -3px rgba(0, 0, 0, 0.2)',
  },
  addButton: {
    position: 'absolute',
    top: -80,
    right: 16,
  },
  item: {
    flex: 'initial',
    minWidth: 0,
    padding: `${spacing(0, 2.5)} !important`,

    '& svg': {
      fill: 'rgba(255, 255, 255, 0.6)',
    },

    '& .MuiBottomNavigationAction-label': {
      marginTop: 3,
      fontSize: 10,
      color: 'rgba(255, 255, 255, 0.6)',
    },
  },
  itemSelected: {
    '& svg': {
      fill: `${palette.primary.main} !important`,
    },
    '& .MuiBottomNavigationAction-label': {
      color: `${palette.primary.main} !important`,
    },
  },
  badge: {
    '& .MuiBadge-badge': {
      top: 8,
      right: 6,
      outline: '1px solid #303030',
    },
  },
  awesome: {
    '& .MuiBottomNavigationAction-label': {
      // color: '#FFFFFFBD !important',
      background: 'linear-gradient(145deg, rgba(187,134,252,1) 0%, rgba(0,60,214,1) 80%, rgba(0,162,214,1) 100%);',
      backgroundClip: 'text',
      color: 'rgba(0, 0, 0, 0.0) !important',
    },
  },
});

const spacerStyles = {
  '@global': {
    '#root': {
      paddingBottom: 64,
    },
  },
};

const Spacer: ComponentType = () => {
  const classes = useClasses(spacerStyles);

  return <div className={classes.spacer} />;
};

type MobileNavigationProps = {
  startDemo: () => void;
};

export const MobileNavigation: ComponentType<MobileNavigationProps> = ({ startDemo }) => {
  const classes = useClasses(styles);
  const { pathname } = useLocation();
  const internalRoutes = useCustomMatch(['/cars/new', '/cars/:carId', '/records/:carId/new', '/records/:carId/edit']);
  const isCarCreateEdit = useCustomMatch(['/stories/new', '/stories/edit/:carId/:storyId']);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { state } = useContext(Context);
  const isAuth = isLoggedIn();
  const [openUnregistered, setOpenUnregistered] = useState<boolean>(false);
  const [loginFrom, setLoginFrom] = useState('');

  useEffect(() => {}, [state.alertActive]);

  const extractPathSegment = (pathname: string): string => {
    const path = pathname.replace(/^\//, '');
    if (!path) {
      return '';
    }
    return path.split('/')[0];
  };

  const onOpenMenu = () => {
    if (!isAuth) {
      setLoginFrom('/settings/profile');
      setOpenUnregistered(true);
    } else {
      setIsMenuOpen(true);
    }
  };

  const onNavigate = (event: any, to: any) => {
    if (!isAuth && to !== 'stories') {
      event.preventDefault();
      setLoginFrom(to);
      setOpenUnregistered(true);
    }
  };

  const storiesActive = (to: string) => {
    return pathname === '/' && to === 'stories';
  };

  return !internalRoutes ? (
    <>
      {isMenuOpen && <SettingsMenuMobile startDemo={startDemo} onClose={() => setIsMenuOpen(false)} />}
      <Spacer />
      <div className={clsx(classes.root, isCarCreateEdit && classes.rootHidden)}>
        <BottomNavigation classes={{ root: classes.navigationWrapper }} showLabels value={extractPathSegment(pathname)}>
          <BottomNavigationAction
            onClick={onOpenMenu}
            icon={<Menu />}
            label={t('__bottom_navigation_menu')}
            showLabel
            className={classes.item}
          />
          {mobileNavigationItems.reduce((prev, curr) => {
            const Icon = curr.icon;

            return [
              ...prev,
              <BottomNavigationAction
                key={curr.to}
                onClick={(event: any) => {
                  onNavigate(event, curr.to);
                }}
                component={NavLink}
                to={`/${curr.to}`}
                value={curr.to}
                label={t(curr.label)}
                showLabel
                icon={
                  curr.alertBadge && state.alertActive === true ? (
                    <Badge color='error' variant='dot' className={classes.badge} key={curr.to}>
                      <Icon />
                    </Badge>
                  ) : (
                    <Icon />
                  )
                }
                className={clsx(
                  classes.item,
                  curr.awesomeStyle && classes.awesome,
                  storiesActive(curr.to) && classes.itemSelected
                )}
                sx={{
                  '& .MuiSelected': classes.itemSelected,
                }}
                classes={{ selected: classes.itemSelected }}
              />,
            ];
          }, [] as any)}
        </BottomNavigation>
        <div className={classes.addButton}>
          <AddNewButton />
        </div>
        <UnregisteredPopup
          open={openUnregistered}
          from={loginFrom}
          onClose={() => {
            setOpenUnregistered(false);
          }}
        />
      </div>
    </>
  ) : null;
};
