import { useMutation } from 'react-query';

import { ProfileValues } from 'types';
import { axiosApiInstance as api, mapKeysToCamelCase } from 'utils';

export interface LoginResponse {
  accessToken?: string;
  refreshToken?: string;
  error?: string;
}

const login = async (body: ProfileValues): Promise<LoginResponse> => {
  try {
    const { data } = await api.post('/users/login', body);
    return mapKeysToCamelCase(data);
  } catch (error: any) {
    return { error: error.response.data.message };
  }
};

export const useLoginMutation = () => {
  return useMutation(login);
};
