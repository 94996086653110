import { Theme } from '@mui/material';
import dayjs from 'dayjs';
import { ComponentType, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setAlertActive } from 'store/profile/slice';
import { ProfileContext as Context } from 'store/profile/store';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { useAlertsModify } from '../useAlertsModify';
import { AlertSubList } from './AlertSubList';
import { Alert } from './AlertTypes';
const _ = require('lodash');
const tKey = getTKey('alert_list');
const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    margin: 'auto',
    maxWidth: 550,
    minHeight: '85vh',
  },
  sectionDateTitle: {
    color: palette.text.hint,
    fontSize: 10,
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '1.5px',
  },
  section: {
    padding: spacing(2, 0, 2),
  },
});

interface AlertsListType {
  alerts: Alert[];
}

export const AlertsList: ComponentType<AlertsListType> = ({ alerts }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();

  let [unreadAlerts, setUnreadAlerts] = useState<Alert[]>([]);
  let [weekAlerts, setWeekAlerts] = useState<Alert[]>([]);
  let [earlierAlerts, setEarlierAlerts] = useState<Alert[]>([]);

  const { dispatch } = useContext(Context);
  const readMutations = useAlertsModify();

  useEffect(() => {
    const prepareAlerts: Alert[] = _.uniqBy(alerts, (alert: Alert) => alert.id);
    let unread: Alert[] = prepareAlerts.filter((alert) => !alert.is_read);
    setUnreadAlerts(prepareAlerts.filter((alert) => !alert.is_read));
    setWeekAlerts(
      prepareAlerts.filter((alert) => dayjs(alert.date_created).isAfter(dayjs().subtract(7, 'days')) && alert.is_read)
    );
    setEarlierAlerts(
      prepareAlerts.filter((alert) => dayjs(alert.date_created).isBefore(dayjs().subtract(7, 'days')) && alert.is_read)
    );
    let unreadAlertIds = unread.map((alert) => alert.id);
    const onReadAlerts = () => {
      readMutations.readAlert(unreadAlertIds);
    };

    let timeoutReadAlerts: NodeJS.Timeout;

    if (unreadAlertIds.length) {
      timeoutReadAlerts = setTimeout(() => {
        onReadAlerts();
        dispatch(setAlertActive(false));
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutReadAlerts);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts]);

  return (
    <div className={classes.root}>
      {unreadAlerts.length > 0 && (
        <div className={classes.section}>
          <div className={classes.sectionDateTitle}>{t(tKey('new'))}</div>
          <AlertSubList alerts={unreadAlerts} />
        </div>
      )}
      {weekAlerts.length > 0 && (
        <div className={classes.section}>
          <div className={classes.sectionDateTitle}>{t(tKey('this_week'))}</div>
          <AlertSubList alerts={weekAlerts} />
        </div>
      )}
      {earlierAlerts.length > 0 && (
        <div className={classes.section}>
          <div className={classes.sectionDateTitle}>{t(tKey('earlier'))}</div>
          <AlertSubList alerts={earlierAlerts} />
        </div>
      )}
    </div>
  );
};
