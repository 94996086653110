import { useMutation } from 'react-query';

import { axiosWithFileInstance } from 'utils';

import { DraftPayload } from '../types';

const saveDraft = async ({ carId, formData }: DraftPayload): Promise<any> => {
  const { data } = await axiosWithFileInstance.post(`/cars/${carId}/records/draft`, formData);

  return data;
};

export const useCreateDraftMutation = () => {
  return useMutation(saveDraft);
};
