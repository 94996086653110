import { useQuery, UseQueryResult } from 'react-query';
import { axiosApiInstance as api } from 'utils';

interface historyBody {
  query: string;
}

interface getAssistantHistoryResult {
  messages: any[];
}

const getAssistantHistory = async (body: historyBody) => {
  const { data } = await api.post(`assistant/search`, body);
  return data;
};

export const useAssistantHistorySearchQuery = (body: historyBody): UseQueryResult<getAssistantHistoryResult> => {
  return useQuery(['historySearch', body.query], () => getAssistantHistory(body), { cacheTime: 8600, enabled: false });
};
