import { useEffect } from 'react';
import store from 'store2';

import { useProfileSettingsQuery } from 'services';

export const useInitialSettings = (publicRecord = false): void => {
  const { data, isLoading } = useProfileSettingsQuery(publicRecord);

  useEffect(() => {
    if (!isLoading && data) {
      store('driverbook_settings', data);
    }
  }, [data, isLoading]);
};
