import { Grid } from '@mui/material';
import { CommonLayout, InnerMobileHeader } from 'components';
import { ComponentType } from 'react';
import { RecordsCarsList } from 'features/records/components';
import { useTranslation } from 'react-i18next';
import { ReminderVoiceForm } from './components/ReminderVoiceForm';
import { useLocation } from 'react-router-dom';

export const VoiceToReminderPage: ComponentType = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dataOfLocation = location.state;

  return (
    <CommonLayout hideMobileNav>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sx={{ display: { md: 'block', xs: 'none' } }}>
          <RecordsCarsList />
        </Grid>

        <Grid item xs={12} md={8}>
          <InnerMobileHeader title={t('reminder_voice_title')} />
          <ReminderVoiceForm siri={dataOfLocation?.state?.siri} />
        </Grid>
      </Grid>
    </CommonLayout>
  );
};
