import { Box, Grid, Theme } from '@mui/material';
import getOr from 'lodash/fp/getOr';
import { ComponentType, useEffect, useState, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import store from 'store2';
import * as yup from 'yup';

import { Form, ImageUploaderMultiple, PageLoader, StyledButton, TextInput } from 'components';
import { DatePickerSelect } from 'components/Form/DatePickerSelect';
import dayjs from 'dayjs';
import { isNull } from 'lodash';
import { useCarQuery } from 'services';
import { NewRecordValues, Record, RecordAITypeShort } from 'types';
import { DEFAULT_CURRENCY, buildRecordFormImages, getTKey, yupNumberValidator, yupPriceValidator } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { useRecordFormImages } from '../hooks/useRecordFormImages';

const tKey = getTKey('record_form');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  column: {
    [breakpoints.up('md')]: {
      position: 'absolute',
      top: 30,
      right: 0,
    },
    marginTop: spacing(-1),
  },
});

interface RecordFormProps {
  onSubmit: (values: any) => void;
  defaultValues?: Record;
  category: string;
  editMode?: boolean;
  isSubmitting?: boolean;
  ai?: RecordAITypeShort;
  onRemove?: (id: string) => void;
}

export const recordFormValidationSchema = (t: TFunction): yup.AnyObjectSchema => {
  return yup.object().shape({
    title: yup.string().max(50, t('__validation__maximum_symbols', { length: 50 })),
    partName: yup.string().max(400, t('__validation__maximum_symbols', { length: 400 })),
    cost: yupPriceValidator(t),
    details: yup.string().max(1000, t('__validation__maximum_symbols', { length: 1000 })),
    mileage: yupNumberValidator(t).max(9999999, t('__validation__maximum_9999999')),
  });
};

const createDefaultValues = (values?: Record) => ({
  title: isNull(values?.title) ? '' : getOr('', 'title', values),
  partName: isNull(values?.partName) ? '' : getOr('', 'partName', values),
  cost: isNull(values?.cost) ? 0 : getOr(0, 'cost', values),
  details: isNull(values?.details) ? '' : getOr('', 'details', values),
  mileage: isNull(values?.mileage) ? 0 : getOr(0, 'mileage', values),
  datePerformed:
    values?.datePerformed === 'None'
      ? null
      : values && dayjs(values.datePerformed).isValid()
      ? dayjs(values.datePerformed)
      : dayjs(),
});

export const RecordDefaultForm: ComponentType<RecordFormProps> = ({
  onSubmit,
  defaultValues,
  editMode,
  category,
  isSubmitting,
  ai,
  onRemove,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const { carId } = useParams<{ carId: string }>() as { carId: string };
  const settingsData = store('driverbook_settings');
  const { data: carData, isLoading } = useCarQuery(carId);
  const [values, setValues] = useState<any>(defaultValues);
  const { images, onImageChange } = useRecordFormImages(values);
  const localSettings = store('driverbook_settings');
  const isSubmittingRef = useRef(false);

  useEffect(() => {
    if (defaultValues) {
      const cloneData = JSON.parse(JSON.stringify(defaultValues));
      setValues(cloneData);
    }
  }, [defaultValues]);

  const handleSubmit = async (data: NewRecordValues) => {
    if (isSubmittingRef.current) return; 
    isSubmittingRef.current = true;
    const fd = new FormData();

    await buildRecordFormImages(fd, images, values);

    fd.append('category', category);
    fd.append('cost', data.cost);
    fd.append('details', data.details);
    fd.append('mileage', data.mileage.toString());
    fd.append('part_name', data.partName);
    fd.append('title', data.title);
    fd.append('date_performed', dayjs(data.datePerformed).format('YYYY-MM-DD HH:mm:ss'));
    if (ai) {
      fd.append('ai_type', ai);
    }

    onSubmit(fd);
  };

  return isLoading ? (
    <PageLoader />
  ) : (
    <Form
      onSubmit={handleSubmit}
      schema={recordFormValidationSchema(t)}
      defaultValues={createDefaultValues(values)}
      mode='onChange'
      // recordForm={true}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput name='title' label={t(tKey('title'))} ai={Boolean(ai && values?.title)} />
          <TextInput name='details' label={t(tKey('details'))} textarea ai={Boolean(ai && values?.details)} />
          <TextInput
            name='mileage'
            label={`${t(tKey('current_mileage'))}, ${carData?.mileageMetric}`}
            ai={Boolean(ai && (values?.mileage || values?.mileage === 0))}
          />
          <DatePickerSelect
            name='datePerformed'
            label={`${t(tKey('date_performed'))}`}
            datePickerProps={{ value: values ? values?.date_performed : dayjs() }}
            format={localSettings.date_format}
            ai={Boolean(ai && values?.date_performed)}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.column} sx={{ top: ai && { md: '-16px !important' } }}>
          <TextInput name='partName' label={t(tKey('item_name'))} ai={Boolean(ai && values?.partName)} />
          <TextInput
            name='cost'
            label={`${t(tKey('price'))}, ${settingsData ? settingsData.currency : DEFAULT_CURRENCY}`}
            ai={Boolean(ai && values?.cost)}
          />

          <ImageUploaderMultiple
            images={images}
            onChange={onImageChange}
            editMode={editMode}
            data={defaultValues as Record}
          />
          {onRemove && (
            <Box sx={{ marginTop: 2 }} display='flex' justifyContent='center'>
              <StyledButton label={t(tKey('remove_record'))} type='button' onClick={onRemove} color='secondary' />
            </Box>
          )}
        </Grid>
      </Grid>
      <Box mt={3} mb={4} textAlign='center' sx={{ display: ai ? 'none' : 'block' }}>
        <StyledButton
          className='submit-record-form'
          disabled={isSubmitting}
          loading={isSubmitting}
          label={editMode ? t(tKey('edit_record')) : t(tKey('create_record'))}
          type='submit'
        />
      </Box>
    </Form>
  );
};
