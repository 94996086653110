import { CommentPayload, ThreadCommentResponse } from 'features/stories-feed/StoriesTypes';
import { useInfiniteQuery } from 'react-query';
import { axiosApiInstance as api } from 'utils';

export const getThreadComment = async (
  { carId, storyId, commentId }: CommentPayload,
  page: number
): Promise<ThreadCommentResponse> => {
  const { data } = await api.get(`cars/${carId}/stories/${storyId}/comments/${commentId}/thread`, { params: { page } });
  return data;
};

export const useThreadCommentQuery = ({ carId, storyId, commentId, list }: CommentPayload) => {
  return useInfiniteQuery(
    ['useThreadComment', carId, storyId, commentId, list],
    ({ pageParam: page = 1 }) => getThreadComment({ carId, storyId, commentId }, page),
    {
      cacheTime: 0,
      getNextPageParam(lastPage, allPages) {
        return lastPage.thread_comments.length > 0 ? lastPage.current_page + 1 : undefined;
      },
      enabled: false,
    }
  );
};
