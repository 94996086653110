import LanguageIcon from '@mui/icons-material/Language';
import { Button, FormControl, Menu, MenuItem, Theme, useMediaQuery } from '@mui/material';
import { languageOptions, useChangeLanguage } from 'lib';
import { ComponentType, useState } from 'react';
import { useClasses } from 'utils/hooks/useClasses';
const styles = ({ breakpoints, spacing, transitions }: Theme) => ({
  select: {
    minWidth: 36,

    [breakpoints.down('sm')]: {
      '& .MuiSelect-select': {
        padding: spacing(0),
      },

      '& .MuiSelect-icon': {
        position: 'initial',
      },

      '& fieldset': {
        borderColor: 'transparent',
      },
    },

    [breakpoints.up('md')]: {
      minWidth: 20,

      '& .MuiSelect-select': {
        paddingTop: spacing(1.5),
        paddingBottom: spacing(1.5),
      },
    },
  },
  menuPaper: {
    backgroundColor: 'transparent',
  },
  menu: {
    '& .MuiMenu-list': {
      backgroundColor: 'rgba(42, 42, 42)',
      borderRadius: 4,
      minWidth: 168,
      boxShadow: '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)',
    },
    '& .MuiMenuItem-root': {
      padding: spacing(1, 2, 1),
      backgroundColor: 'rgba(42, 42, 42)',
    },
  },
  expandButton: {
    marginLeft: spacing(1),
    display: 'flex',
    alignItems: 'center',
    transform: 'rotate(0deg)',
    transition: transitions.create('transform', {
      duration: transitions.duration.shortest,
    }),
  },
  expandButtonOpen: {
    transform: 'rotate(180deg)',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 36,
  },
});
export const LanguageSwitch: ComponentType = () => {
  const matches = useMediaQuery('(min-width: 960px)');
  const classes = useClasses(styles);
  const { chosenLanguage, changeLanguage } = useChangeLanguage();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <FormControl variant={matches ? 'outlined' : undefined} className={classes.select}>
        <Button color='primary' onClick={handleClick} className={classes.button}>
          <LanguageIcon />
        </Button>
        <Menu
          elevation={10}
          // getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClick={handleClose}
          className={classes.menu}
          classes={{ paper: classes.menuPaper }}
        >
          {languageOptions.map((language) => (
            <MenuItem
              onClick={(event) => {
                //@ts-ignore
                gtag?.('event', 'language_clicked', { method: 'language_clicked' });
                changeLanguage({ ...event, target: { value: language.value } });
              }}
              key={language.value}
              value={language.value}
              disabled={language.value === chosenLanguage}
            >
              {language.label}
            </MenuItem>
          ))}
        </Menu>
      </FormControl>
    </>
  );
};
