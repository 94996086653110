import createSvgIcon from '@mui/icons-material/esm/utils/createSvgIcon';

export default createSvgIcon(
  <svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='29' height='29' rx='14.5' fill='white' />
    <rect width='29' height='29' rx='14.5' fill='url(#paint0_linear_3579_15966)' />
    <mask id='mask0_3579_15966' maskUnits='userSpaceOnUse' x='2' y='2' width='25' height='25'>
      <rect x='2.4165' y='2.41666' width='24.1667' height='24.1667' fill='#D9D9D9' />
    </mask>
    <g mask='url(#mask0_3579_15966)'>
      <path
        d='M16.3888 13.4325L16.8465 12.4255L17.8535 11.9678L16.8465 11.5101L16.3888 10.5031L15.9311 11.5101L14.9242 11.9678L15.9311 12.4255L16.3888 13.4325ZM13.6426 13.6155L12.7272 11.6016L11.8118 13.6155L9.79785 14.5309L11.8118 15.4464L12.7272 17.4603L13.6426 15.4464L15.6565 14.5309L13.6426 13.6155ZM16.3888 15.6294L15.9311 16.6364L14.9242 17.0941L15.9311 17.5518L16.3888 18.5588L16.8465 17.5518L17.8535 17.0941L16.8465 16.6364L16.3888 15.6294Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.757 24.4184C14.0003 24.5191 14.2479 24.5694 14.4996 24.5694C14.7514 24.5694 14.9989 24.5191 15.2422 24.4184C15.4856 24.3177 15.708 24.1667 15.9093 23.9653L23.9649 15.9097C24.1663 15.7083 24.3173 15.486 24.418 15.2426C24.5187 14.9993 24.5691 14.7517 24.5691 14.5C24.5691 14.2483 24.5187 14.0007 24.418 13.7574C24.3173 13.514 24.1663 13.2917 23.9649 13.0903L15.9093 5.03471C15.708 4.83332 15.4856 4.68228 15.2422 4.58158C14.9989 4.48089 14.7514 4.43054 14.4996 4.43054C14.2479 4.43054 14.0003 4.48089 13.757 4.58158C13.5137 4.68228 13.2913 4.83332 13.0899 5.03471L5.03434 13.0903C4.83295 13.2917 4.68191 13.514 4.58122 13.7574C4.48052 14.0007 4.43018 14.2483 4.43018 14.5C4.43018 14.7517 4.48052 14.9993 4.58122 15.2426C4.68191 15.486 4.83295 15.7083 5.03434 15.9097L13.0899 23.9653C13.2913 24.1667 13.5137 24.3177 13.757 24.4184ZM22.5552 14.5L14.4996 22.5555L6.44406 14.5L14.4996 6.44443L22.5552 14.5Z'
        fill='white'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_3579_15966'
        x1='8.96377'
        y1='2.63649'
        x2='24.782'
        y2='28.7365'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#BB86FC' />
        <stop offset='0.630116' stopColor='#003CD6' />
        <stop offset='1' stopColor='#00A2D6' />
      </linearGradient>
    </defs>
  </svg>
);
