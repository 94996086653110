import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { FormControl, IconButton, MenuItem, Select, SelectChangeEvent, Theme } from "@mui/material";
import { ComponentType, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTKey } from "utils";
import { useClasses } from "utils/hooks/useClasses";

const tKey = getTKey('admin_filter_pagination');

const styles = ({palette, spacing}: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        marginTop: 20,
        alignItems: 'baseline',
        fontSize: 12,
    },
    field: {
        minWidth: 190
    },
    fieldPerPage: {
        minWidth: 40,
        marginLeft: 12
    },
    space:{
        flex: 1
    },
    itemsPagination: {
        margin: '0 40px',
    },
    navigation: {
        color: palette.text.primary
    },
    navButton: {
        '&:disabled svg':{
            color: 'rgba(255, 255, 255 ,0.6)'
        }
    }
})

type perPage = 10 | 20 | 50 | 100;
const perPageOptions: number[] = [10 , 20 , 50 , 100];

interface PaginationBarType {
    changeCurrentPage: (currentPage: number) => void,
    changePerPage: (perPage: number) => void,
    currentPage: number,
    total: number,
    pages: number,
}

export const PaginationBar: ComponentType<PaginationBarType>  = ({
    changeCurrentPage,
    changePerPage,
    currentPage,
    pages,
    total

}) =>{
    const classes = useClasses(styles);
    const { t } = useTranslation();

    const [perPage, setPerPage ] = useState<number>(20); 

    const handleChangePerPage = (event: SelectChangeEvent) => {
        const newValue = Number(event.target.value) as perPage
        setPerPage(newValue);
        changePerPage(newValue)
    };

    return (
        <div className={classes.root}>
            <div className={classes.fieldPerPageLabel}>{t(tKey('rows_per_page_label'))}: </div>
            <FormControl  className={classes.fieldPerPage}>
                <Select
                    labelId="per-page-select-label"
                    id="per-page-select"
                    value={perPage.toString()}
                    onChange={handleChangePerPage}
                    variant={'standard'}
                    className={classes.fieldPerPageSelect}
                >
                    {perPageOptions.map(option=>{
                        return (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
            <div className={classes.space}></div>
            <div className={classes.itemsPagination}>
                {total > 0 ? perPage*currentPage-perPage+1 : 0}-{perPage*currentPage > total ? total : perPage*currentPage} {t(tKey('of'))} {total} {t(tKey('items'))}
            </div>
            <div>
                <IconButton 
                    disabled={currentPage === 1} 
                    onClick={()=>{changeCurrentPage(currentPage-1)}} 
                    className={classes.navButton}
                >
                    <ChevronLeft className={classes.navigation}/>
                </IconButton>
                <IconButton 
                    disabled={currentPage === pages || pages === 0} 
                    onClick={()=>{changeCurrentPage(currentPage+1)}} 
                    className={classes.navButton}
                >
                    <ChevronRight className={classes.navigation}/>
                </IconButton>
            </div>
        </div>
    )
}