import { Tab, Tabs, Theme } from '@mui/material';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
const tKey = getTKey('story_tabs');

const styles = ({ spacing, palette, breakpoints }: Theme) => ({
  tabs: {
    '&  .Mui-disabled': {
      width: 0,
      transition: '0.5s ease',
    },
    '& .MuiTabs-flexContainer': {},
    '& .MuiTab-root': {
      color: palette.text.hint,
      fontSize: 14,
      fontWeight: 600,
      padding: spacing(1.75, 1.5),
      letterSpacing: '1.25px',
      lineHeight: '16px',
    },
    '& .Mui-selected': {
      color: palette.text.primary,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#6200EE',
    },
  },
});

interface StoryTabsType {
  onChangeTab: (value: string) => void;
  authorized: boolean;
  defaultTab?: string;
}

export const StoryTabs: ComponentType<StoryTabsType> = ({ onChangeTab, authorized, defaultTab }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);

  const [tab, setTab] = useState(defaultTab || 'recent');

  const handleTab = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
    onChangeTab(newValue);
  };

  useEffect(() => {
    const onChangeStoryTab = (event: any) => {
      setTab(event.detail);
    };
    window.addEventListener('onChangeStoryTab', onChangeStoryTab);
    return () => {
      window.removeEventListener('onChangeStoryTab', onChangeStoryTab);
    };
  }, []);

  return (
    <Tabs variant='scrollable' value={tab} onChange={handleTab} aria-label='story tabs' className={classes.tabs}>
      <Tab value='recent' label={t(tKey('recent'))} wrapped component={Link} to={'/stories/recent'} />
      <Tab value='news' label={t(tKey('news'))} wrapped component={Link} to={'/stories/news'} />
      {authorized && <Tab value='follow' label={t(tKey('following'))} component={Link} to={'/stories/follow'} />}
      <Tab value='d' label={t(tKey('today'))} component={Link} to={'/stories/d'} />
      <Tab value='w' label={t(tKey('weekly'))} component={Link} to={'/stories/w'} />
      <Tab value='m' label={t(tKey('monthly'))} component={Link} to={'/stories/m'} />
    </Tabs>
  );
};
