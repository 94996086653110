import { useQuery, UseQueryResult } from 'react-query';

import { Car } from 'types';
import { axiosApiInstance as api } from 'utils';

import { createCarUI } from '../utils';

const getCars = async (): Promise<Car[]> => {
  const { data } = await api.get(`/cars`);

  return data.cars.map(createCarUI);
};

export const useCarsQuery = (): UseQueryResult<Car[]> => {
  return useQuery('cars', getCars, { cacheTime: 0 });
};
