import { Place } from '@mui/icons-material';
import { Box, Grid, Theme, Typography } from '@mui/material';
import userPlaceholder from 'assets/user-placeholder.jpeg';
import clsx from 'clsx';
import { VerifiedIcon } from 'components';
import { PatronLarge } from 'components/PatronBadges/PatronLarge';
import { Wrapper } from 'components/Wrapper/Wrapper';
import { useTranslation } from 'react-i18next';
import { PublicUserFollowers } from 'services';
import { theme } from 'theme';
import { UserProfile } from 'types';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { nFormatter } from 'utils/n-formatter';

type UserProfileCardParams = {
  profile: UserProfile;
  followers?: PublicUserFollowers;
  admin?: boolean;
};
const styles = ({ breakpoints, spacing }: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  count: {
    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'center',
  },
  subHeading: {
    fontWeight: '400',
    fontSize: 12,
    color: '#828796',
    textAlign: 'center',
  },
  userProfilePic: {
    borderRadius: 99,
    objectFit: 'cover',
    objectPosition: 'center',
  },
  userProfilePicAdmin: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    borderRadius: 0,
  },
  imgContainer: {
    position: 'relative',
    width: 87,
  },
  imgContainerAdmin: {
    position: 'relative',
    width: '250px',
    height: '250px',
    overflow: 'hidden',
  },
  verified: {
    color: theme.palette.secondary.main,
    width: 18,
    position: 'absolute',
    top: 0,
    right: 0,
  },
  username: {
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
  },
  usernamePatron: {
    color: '#FFCB40',
  },
  icon: {
    fontSize: 15,
    color: '#828796',
    marginRight: 12,
  },
  descContainer: {
    margin: spacing(4, 10, 0, 10),
    [breakpoints.down('sm')]: {
      margin: spacing(4, 2, 0, 2),
    },
  },
  desc: {
    marginTop: 10,
    overflowWrap: 'break-word',
  },
  location: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },
  followerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  followerContainerLeft: {
    alignItems: 'flex-end',
    [breakpoints.down('sm')]: {
      paddingRight: 15,
    },
  },
  followerContainerRight: {
    alignItems: 'flex-start',
    [breakpoints.down('sm')]: {
      paddingLeft: 15,
    },
  },
});

const tKey = getTKey('user_profile');
export const UserProfileCard = ({ profile, followers, admin }: UserProfileCardParams) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);

  const imageUrl = profile?.image === '' ? userPlaceholder : profile?.image;
  return (
    <Wrapper>
      <Grid container className={classes.container}>
        {followers && (
          <Grid item xs={4} className={clsx(classes.followerContainer, classes.followerContainerLeft)}>
            <Box>
              <Typography className={classes.count}>{nFormatter(followers?.followers ?? 0)}</Typography>
              <Typography className={classes.subHeading}>{t(tKey('followers'))}</Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={followers ? 4 : 12} className={classes.container}>
          <Box className={admin ? classes.imgContainerAdmin : classes.imgContainer}>
            <img
              src={imageUrl}
              alt='Logo'
              width={87}
              height={87}
              className={admin ? classes.userProfilePicAdmin : classes.userProfilePic}
            />
            {profile?.is_trusted && <VerifiedIcon size='md' />}
          </Box>
        </Grid>
        {followers && (
          <Grid item xs={4} className={clsx(classes.followerContainer, classes.followerContainerRight)}>
            <Box>
              <Typography className={classes.count}>{nFormatter(followers?.following ?? 0)}</Typography>
              <Typography className={classes.subHeading}>{t(tKey('following'))}</Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      <Box className={followers && classes.descContainer}>
        <Typography className={clsx(classes.username, profile?.is_patron && classes.usernamePatron)}>
          {profile.username}
        </Typography>
        {profile?.bio && <Typography className={clsx(classes.desc, classes.subHeading)}>{profile.bio}</Typography>}{' '}
        {(profile?.city || profile?.country) && (
          <Box className={classes.location}>
            <Place className={classes.icon} />
            <Typography className={classes.subHeading}>{`${profile.city ? profile.city + ',' : ''} ${
              profile.country
            }`}</Typography>
          </Box>
        )}
      </Box>
      {profile?.is_patron && (
        <Grid display={'flex'} justifyContent='center' padding={1}>
          <PatronLarge />
        </Grid>
      )}
    </Wrapper>
  );
};
