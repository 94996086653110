import { useQuery } from 'react-query';
import { axiosApiInstance as api } from 'utils';
import { carReminderChangePayload } from './reminderPayloads';

export const getReminderCar = async ({ carId, reminderId }: carReminderChangePayload) => {
  const { data } = await api.get(`cars/${carId}/reminders/${reminderId}`);

  return data;
};

export const useReminderCarQuery = ({ carId, reminderId }: carReminderChangePayload) => {
  return useQuery(['reminderCar', carId, reminderId], () => getReminderCar({ carId, reminderId }), { enabled: false });
};
