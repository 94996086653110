import { useQuery, UseQueryResult } from 'react-query';
import { UserNotifications } from 'types';

import { axiosApiInstance as api } from 'utils';

const userNotifications = async () => {
  try {
    const { data } = await api.get('users/settings/notifications/push');
    return data;
  } catch (err) {
    return { error: true };
  }
};

export const useUserNotificationsQuery = (): UseQueryResult<UserNotifications> => {
  return useQuery(['userNotificationsQuery'], () => userNotifications(), {
    cacheTime: 0,
  });
};
