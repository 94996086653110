import { useQuery } from 'react-query';

import { axiosApiInstance as api } from 'utils';

const getDoNotTranslate = async (): Promise<any> => {
  const { data } = await api.get('/users/languages');
  return data;
};

export const useGetDoNotTranslate = () => {
  return useQuery('doNotTranslate', getDoNotTranslate);
};
