import { useMutation } from 'react-query';

import { axiosApiInstance as api } from 'utils';

const deleteProfile = async (): Promise<any> => {
  try {
    const { data } = await api.delete('/user');
    return data;
  } catch (error) {
    return { error: true };
  }
};

export const useDeleteProfileMutation = () => {
  return useMutation(deleteProfile);
};
