import { Box, Divider } from '@mui/material';
import { ComponentType } from 'react';
import { NewRecordValues, Record } from 'types';
import { RecordForm } from './RecordForm';

interface RecordAIFormType {
  records: NewRecordValues[];
  onSubmit: (data: any, id: string) => void;
  idSubmitting: string;
  onRemove: (id: string) => void;
  aiType: 'itr' | 'vtr';
}

export const RecordsAIForm: ComponentType<RecordAIFormType> = ({
  records,
  onSubmit,
  idSubmitting,
  onRemove,
  aiType,
}) => {
  const handleSubmit = (data: any, id: string) => {
    onSubmit(data, id);
  };

  const handleRemove = (id: string) => {
    onRemove(id);
  };

  return (
    <Box>
      {records.map((record, i) => {
        return (
          <>
            <RecordForm
              onSubmit={(data) => handleSubmit(data, record.tmpId as string)}
              defaultValues={record as Record}
              key={record.tmpId}
              isSubmitting={record.tmpId === idSubmitting}
              ai={aiType}
              onRemove={() => handleRemove(record.tmpId as string)}
            />
            {i === records.length - 1 ? (
              <Box sx={{ height: 32 }}></Box>
            ) : (
              <Divider sx={{ margin: '24px 0', borderColor: '#313131' }} />
            )}
          </>
        );
      })}
    </Box>
  );
};
