import { useEffect, useState } from 'react';

import { useCarModelsQuery } from 'services';
import { CarOption } from 'types';

type mapCarModelsType = (data: string[]) => CarOption[];
const mapCarModels: mapCarModelsType = (data) => {
  return data.map((item) => {
    return { label: item, value: item };
  });
};

type useCarModelsDataType = (chosenMake: string) => { models: CarOption[]; modelsLoading: boolean };
export const useCarModelsData: useCarModelsDataType = (chosenMake) => {
  const { data: carModels, isLoading: modelsLoading } = useCarModelsQuery(chosenMake);

  const [models, setCarModels] = useState<CarOption[]>([]);

  useEffect(() => {
    if (!modelsLoading && carModels && carModels.length) {
      setCarModels(mapCarModels(carModels));
    }
  }, [carModels, modelsLoading]);

  return {
    models,
    modelsLoading,
  };
};
