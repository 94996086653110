import { useInfiniteQuery } from 'react-query';
import { axiosApiInstance as api, mapKeysToSnakeCase } from 'utils';
import { storiesFeedResponse } from './useStoriesFeedQuery';

const cacheTime = 1000 * 60 * 3;

export const getUserStories = async (page: number, userId: string): Promise<storiesFeedResponse> => {
  const { data } = await api.get(`users/${userId}/stories`, { params: mapKeysToSnakeCase({ page }) });
  return data;
};

export const useUserStoriesQuery = (userId: string) => {
  return useInfiniteQuery(['getUserStories', userId], ({ pageParam: page = 1 }) => getUserStories(page, userId), {
    cacheTime: cacheTime,
    getNextPageParam(lastPage, allPages) {
      return lastPage.stories.length > 0 ? lastPage.current_page + 1 : undefined;
    },
  });
};
