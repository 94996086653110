import { EventRepeat, Notes, Link, Tag } from '@mui/icons-material';
import { Box, Grid, Theme, Typography } from '@mui/material';
import clsx from 'clsx';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import Mileage from 'assets/swap_driving_apps_wheel.svg';
const tKey = getTKey('add_part_reminder');

const MileageIcon: ComponentType<{ width?: number; height?: number; className?: any }> = ({ className }) => (
  <img className={className} src={Mileage} alt='mileage' style={{ margin: '0 4px' }} />
);

const styles = ({ spacing, breakpoints }: Theme) => ({
  menuContainer: {
    maxWidth: '100%',
    margin: spacing(3, 0),
    overflowY: 'hidden',
    height: 40,
    display: 'flex',
    flex: 1,
  },
  menu: {
    height: 40,
    overflowY: 'hidden',
    backgroundColor: 'rgba(32, 32, 32, 1)',
    margin: 'auto',
    borderRadius: 56,
    borderWidth: 1,
    borderColor: 'rgba(47, 47, 47, 1)',
    borderStyle: 'solid',
    scrollbarWidth: 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing(0, 1),
    cursor: 'pointer',
    height: 40,
  },
  menuItemBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: 'rgba(47, 47, 47, 1)',
    borderStyle: 'solid',
    height: 40,
  },
  menuItemIcon: {
    height: 16,
  },
  menuItemLabel: {
    fontSize: 16,
  },
});

interface AddPartReminderProps {
  addNotes: () => void;
  addLink: () => void;
  addDate: () => void;
  addMileage: () => void;
  addCategory: () => void;
  enableNotes: boolean;
  enableLink: boolean;
  enableDate: boolean;
  enableMileage: boolean;
  enableCategory: boolean;
}

export const AddPartReminder: ComponentType<AddPartReminderProps> = ({
  addNotes,
  addCategory,
  addDate,
  addLink,
  addMileage,
  enableNotes,
  enableCategory,
  enableDate,
  enableLink,
  enableMileage,
}) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const [numberShowItems, setNumberShowItems] = useState(5);
  useEffect(() => {
    let newNumberShowItems = 0;
    if (!enableNotes) newNumberShowItems++;
    if (!enableCategory) newNumberShowItems++;
    if (!enableDate) newNumberShowItems++;
    if (!enableLink) newNumberShowItems++;
    if (!enableMileage) newNumberShowItems++;
    setNumberShowItems(newNumberShowItems);
  }, [enableNotes, enableCategory, enableDate, enableLink, enableMileage]);

  return (
    <Box
      className={classes.menuContainer}
      style={{
        height: numberShowItems === 0 ? '0' : 'auto',
      }}
    >
      <Grid
        className={classes.menu}
        style={{
          display: numberShowItems === 0 ? 'none' : 'flex',
        }}
      >
        {!enableDate && (
          <Grid item className={clsx(classes.menuItem, classes.menuItemBorder)} onClick={() => addDate()}>
            <EventRepeat className={classes.menuItemIcon} />
            <Typography className={classes.menuItemLabel}>{t(tKey('date'))}</Typography>
          </Grid>
        )}
        {!enableMileage && (
          <Grid item className={clsx(classes.menuItem, classes.menuItemBorder)} onClick={() => addMileage()}>
            <MileageIcon className={classes.menuItemIcon} />
            <Typography className={classes.menuItemLabel}>{t(tKey('mileage'))}</Typography>
          </Grid>
        )}
        {!enableNotes && (
          <Grid className={clsx(classes.menuItem, classes.menuItemBorder)} onClick={() => addNotes()}>
            <Notes className={classes.menuItemIcon} />
            <Typography className={classes.menuItemLabel}>{t(tKey('notes'))}</Typography>
          </Grid>
        )}
        {!enableLink && (
          <Grid item className={clsx(classes.menuItem, classes.menuItemBorder)} onClick={() => addLink()}>
            <Link className={classes.menuItemIcon} />
            <Typography className={classes.menuItemLabel}>{t(tKey('link'))}</Typography>
          </Grid>
        )}
        {!enableCategory && (
          <Grid item className={clsx(classes.menuItem, classes.menuItem)} onClick={() => addCategory()}>
            <Tag className={classes.menuItemIcon} />
            <Typography className={classes.menuItemLabel}>{t(tKey('category'))}</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
