import { useQuery, UseQueryResult } from 'react-query';
import { axiosApiInstance as api } from 'utils';

const getPublicCarCurrencyData = async (identifier: string, currency?: boolean): Promise<any> => {
  try {
    const { data } = await api.get('/book/public', { params: { identifier } });
    return data.currency;
  } catch (err: any) {
    return { error: true };
  }
};

export const usePublicCarCurrencyQuery = (identifier: string, currency?: boolean): UseQueryResult<any> => {
  return useQuery(['publicCarCurrencyData'], () => getPublicCarCurrencyData(identifier, currency));
};
