import { useMutation } from 'react-query';

import { queryClient } from 'components';
import { axiosApiInstance as api } from 'utils';

const deleteCar = async (id: string): Promise<void> => {
  await api.delete(`/cars/${id}`);
};

export const useDeleteCarMutation = () => {
  return useMutation(deleteCar, {
    onSuccess: () => {
      queryClient.invalidateQueries('cars');
    },
  });
};
