import { useMutation } from 'react-query';

import { queryClient } from 'components';
import { axiosApiInstance as api, mapKeysToSnakeCase } from 'utils';

type ProfileInfoPayload = {
  username?: string;
  email?: string;
  newPassword?: string;
  language?: string;
  currentPassword?: string;
  code?: string;
}

const updateProfileInfo = async (body: ProfileInfoPayload): Promise<any> => {
  try {
    const { data } = await api.put('/user-info', mapKeysToSnakeCase(body));
    return data;
  } catch (error) {
    return { error: true };
  }
};

export const useProfileInfoMutation = () => {
  return useMutation(updateProfileInfo, {
    onSuccess: () => {
      queryClient.invalidateQueries('userInfo');
    },
  });
};
