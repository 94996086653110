import { UpdateCommentPayload } from 'features/stories-feed/StoriesTypes';
import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

const updateComment = async ({ carId, storyId, commentId, body }: UpdateCommentPayload): Promise<any> => {
  const { data } = await api.put(`/cars/${carId}/stories/${storyId}/comments/${commentId}`, body);
  return data;
};

export const useUpdateCommentMutation = () => {
  return useMutation(updateComment);
};
