import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

const approveComment = async (id: string): Promise<any> => {
    try{
        const { data } = await api.post(`engineroom/comments/${id}/approve`);
        return data;
    } catch {
        return { error: true };
    }
};

export const useApproveCommentMutation = () => {
  return useMutation(approveComment);
};
