import { FacebookRounded, Link, Telegram, ThumbDownAlt, ThumbUpAlt } from '@mui/icons-material';
import { Theme } from '@mui/material';
import clsx from 'clsx';
import { UnregisteredPopup } from 'components/UnregisteredPopup/UnregisteredPopup';
import { IStory, LikeStateType } from 'features/stories-feed/StoriesTypes';
import { useLikeDislikeStory } from 'features/stories-feed/hooks/useLikeDislikeStory';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ breakpoints, spacing, palette }: Theme) => ({
  root: {
    [breakpoints.up('sm')]: {
      padding: spacing(0, 1.25),
      marginLeft: spacing(3),
    },
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: spacing(2),
    },
  },
  item: {
    color: '#A5A5A5',
    padding: spacing(1),
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  },
  counter: {
    textAlign: 'center',
    width: '100%',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: '1.25px',
    paddingBottom: spacing(0.5),
    [breakpoints.down('sm')]: {
      paddingLeft: spacing(0.5),
    },
  },
  filled: {
    color: '#985EFF',
  },
  icon: {
    cursor: 'pointer',
  },
  spaceLittle: {
    width: 8,
    height: 8,
  },
  spaceBig: {
    width: 40,
    height: 40,
    [breakpoints.down('sm')]: {
      flex: 1,
    },
  },
});

interface LikeAndShareType {
  story: IStory;
  authorized: boolean;
}

export const LikeAndShare: ComponentType<LikeAndShareType> = ({ story, authorized }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [likes, setLikes] = useState(story.likes);
  const [dislikes, setDislikes] = useState(story.dislikes);
  const [likeState, setLikeState] = useState<LikeStateType>(
    story.is_liked ? 'liked' : story.is_disliked ? 'disliked' : null
  );
  const [openUnregistered, setOpenUnregistered] = useState<boolean>(false);

  const { like, removeLike, dislike, removeDislike } = useLikeDislikeStory();

  const getLink = () => {
    return process.env.REACT_APP_PUBLIC_API_URL?.replace('/api/', '') + window.location.pathname;
  };

  const shareOnTelegram = () => {
    const url = 'https://t.me/share/url?url=' + getLink() + '&text=' + story.title;
    window.open(url, '_blank', 'noreferrer');
  };

  const shareOnFacebook = () => {
    const url = 'https://www.facebook.com/sharer/sharer.php?u=' + getLink();
    window.open(url, '_blank', 'noreferrer');
  };

  const shareOnCopyLink = () => {
    navigator.clipboard.writeText(getLink());
    toast(t('__link_copied_clipboard'), { toastId: 'link_copied_clipboard' });
  };

  const onLike = () => {
    if (!authorized) {
      setOpenUnregistered(true);
      return;
    }
    if (likeState === 'liked') {
      removeLike({ carId: story.car.id, storyId: story.id });
      setLikes(likes - 1);
      setLikeState(null);
    } else if (likeState === 'disliked') {
      like({ carId: story.car.id, storyId: story.id });
      setLikes(Number(likes) + 1);
      setDislikes(dislikes - 1);
      setLikeState('liked');
    } else if (likeState === null) {
      like({ carId: story.car.id, storyId: story.id });
      setLikes(Number(likes) + 1);
      setLikeState('liked');
    }
  };

  const onDislike = () => {
    if (!authorized) {
      setOpenUnregistered(true);
      return;
    }
    if (likeState === 'liked') {
      dislike({ carId: story.car.id, storyId: story.id });
      setLikes(likes - 1);
      setDislikes(Number(dislikes) + 1);
      setLikeState('disliked');
    } else if (likeState === 'disliked') {
      removeDislike({ carId: story.car.id, storyId: story.id });
      setDislikes(dislikes - 1);
      setLikeState(null);
    } else if (likeState === null) {
      dislike({ carId: story.car.id, storyId: story.id });
      setDislikes(Number(dislikes) + 1);
      setLikeState('disliked');
    }
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.item, [likeState === 'liked' && classes.filled])}>
        <div className={classes.counter}>{likes}</div>
        <ThumbUpAlt className={classes.icon} onClick={onLike} />
      </div>
      <div className={classes.spaceLittle}></div>
      <div className={clsx(classes.item, [likeState === 'disliked' && classes.filled])}>
        <div className={classes.counter}>{dislikes}</div>
        <ThumbDownAlt className={classes.icon} onClick={onDislike} />
      </div>
      <div className={classes.spaceBig}></div>
      <div className={classes.item}>
        <Link className={classes.icon} onClick={shareOnCopyLink} />
      </div>
      <div className={classes.spaceLittle}></div>
      <div className={classes.item}>
        <FacebookRounded className={classes.icon} onClick={shareOnFacebook} />
      </div>
      <div className={classes.spaceLittle}></div>
      <div className={classes.item}>
        <Telegram className={classes.icon} onClick={shareOnTelegram} />
      </div>
      <UnregisteredPopup
        open={openUnregistered}
        from={pathname}
        onClose={() => {
          setOpenUnregistered(false);
        }}
      />
    </div>
  );
};
