import { Box, Button, Slide, Theme, Toolbar } from '@mui/material';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmationDialog, ImageLogo, StyledButton, Wrapper } from 'components';
import { AssistedRecordInstructionDropdown } from 'components/AssistedRecordInstruction/AssistedRecordInstructionDropdown';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ breakpoints, spacing }: Theme) => ({
  root: {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',

    [breakpoints.up('md')]: {
      backgroundColor: 'rgba(187, 134, 252, 0.12)',
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(1.5, 0),

    [breakpoints.up('md')]: {
      padding: spacing(3, 0),
    },
  },
  button: {
    letterSpacing: 1.25,
  },
  right: {
    alignItems: 'center',
  },
  reminder: {
    marginRight: spacing(3),
    position: 'relative',
    width: 30,
  },
});

interface AssistedRecordsHeaderProps {
  canFinish?: boolean;
  onSessionFinish?: () => void;
  notification?: boolean;
  appear?: boolean | null;
}

export const AssistedRecordsHeader: ComponentType<AssistedRecordsHeaderProps> = ({
  canFinish,
  onSessionFinish,
  notification,
  appear,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const [initFinishing, setInitFinishing] = useState<boolean>(false);

  const handleFinishSession = (): void => {
    setInitFinishing(true);
  };

  return (
    <div className={classes.root}>
      <Wrapper>
        <Toolbar disableGutters classes={{ root: classes.toolbar }}>
          <Box component='div' sx={{ display: { md: 'block', xs: 'none' } }}>
            <ImageLogo />
          </Box>
          <Box component='div' sx={{ display: { md: 'none', xs: 'block' } }}>
            <ImageLogo width={32} height={32} />
          </Box>
          {onSessionFinish && (
            <>
              <ConfirmationDialog
                description={t('confirm_dialog_session_finish')}
                open={initFinishing}
                onConfirm={onSessionFinish}
                onClose={() => setInitFinishing(false)}
                confirmText={t('__common__confirm')}
              />

              <Box component='div' sx={{ display: { md: 'flex', xs: 'none' } }} className={classes.right}>
                <Slide
                  appear={appear ?? false}
                  direction='up'
                  in={notification}
                  mountOnEnter
                  unmountOnExit
                  exit={false}
                >
                  <div className={classes.reminder}>
                    <AssistedRecordInstructionDropdown />
                  </div>
                </Slide>
                <StyledButton
                  label={t('assisted_record_finish')}
                  color='secondary'
                  disabled={!canFinish}
                  onClick={handleFinishSession}
                />
              </Box>
              <Box component='div' sx={{ display: { md: 'none', xs: 'flex' } }} className={classes.right}>
                <Slide
                  appear={appear ?? false}
                  direction='up'
                  in={notification}
                  mountOnEnter
                  unmountOnExit
                  exit={false}
                >
                  <div className={classes.reminder}>
                    <AssistedRecordInstructionDropdown />
                  </div>
                </Slide>
                <Button color='primary' className={classes.button} disabled={!canFinish} onClick={handleFinishSession}>
                  {t('assisted_record_finish')}
                </Button>
              </Box>
            </>
          )}
        </Toolbar>
      </Wrapper>
    </div>
  );
};
