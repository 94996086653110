import { default as i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const DEFAULT_LANGUAGE = 'en';
const DETECTION_OPTIONS = {
  order: ['navigator'],
  caches: [''],
};

i18n
  .use(backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: DETECTION_OPTIONS,
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: DEFAULT_LANGUAGE,
    whitelist: ['uk', 'en', 'es'],
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
