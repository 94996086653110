import { useMutation } from 'react-query';

import { queryClient } from 'components';
import { axiosApiInstance as api } from 'utils';

const generateAssistedLink = async (id: string): Promise<any> => {
  const { data } = await api.post(`/cars/${id}/record/assisted/generate`);

  return data;
};

export const useGenerateAssistedLinkMutation = () => {
  return useMutation(generateAssistedLink, {
    onSuccess: () => {
      queryClient.invalidateQueries('generatedAmountLeft');
    },
  });
};
