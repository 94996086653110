import { FetchNextPageOptions, InfiniteData, RefetchOptions } from 'react-query';
import { useUsersSearchQuery, userPagination, usersSearchResponse } from "services/stories";

type useUsersSearchDataType = (
  pagination: userPagination
) => {
  data?: InfiniteData<usersSearchResponse>,
  hasNextPage?: boolean,
  isFetchingNextPage: boolean,
  isLoading: boolean,
  fetchNextPage: (options?: FetchNextPageOptions)=>void,
  refetch: (options?: RefetchOptions)=>void,
}

export const useUsersSearchData: useUsersSearchDataType = (pagination) => {

  const {data, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading, refetch } = useUsersSearchQuery(pagination);

  return{
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  }
}