import { Grid } from '@mui/material';
import { CommonLayout, InnerMobileHeader } from 'components';
import { ComponentType } from 'react';
import { RecordsCarsList } from './components';
import { InvoiceForm } from './components/InvoiceForm';
import { useTranslation } from 'react-i18next';

export const InvoiceToRecordPage: ComponentType = () => {
  const { t } = useTranslation();
  
  return (
    <CommonLayout hideMobileNav>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sx={{ display: { md: 'block', xs: 'none' } }}>
          <RecordsCarsList />
        </Grid>

        <Grid item xs={12} md={8}>
          <InnerMobileHeader title={t('record_invoice_title')} />
          <InvoiceForm />
        </Grid>
      </Grid>
    </CommonLayout>
  );
};
