import { useMutation } from 'react-query';

import { axiosApiInstance as api } from 'utils';

type ChangeProfileLanguagePayload = {
  language: string;
};

const changeLanguage = async (body: ChangeProfileLanguagePayload): Promise<any> => {
  try {
    const { data } = await api.put('/settings/language', body);
    return data;
  } catch (error) {
    return { error: true };
  }
};

export const useChangeProfileLanguageMutation = () => {
  return useMutation(changeLanguage);
};
