import { Grid } from '@mui/material';
import { CommonLayout } from 'components';
import { FeatureCarsList } from 'components/FeatureCarsList/FeatureCarsList';
import { ComponentType } from 'react';

import { RemindersList } from './components/RemindersList';

export const RemindersDashboardPage: ComponentType = () => {
  return (
    <CommonLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FeatureCarsList entityPath='reminders' />
        </Grid>
        <Grid item xs={12} md={8}>
          <RemindersList />
        </Grid>
      </Grid>
    </CommonLayout>
  );
};
