import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Theme,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { capitalize, isArray } from 'lodash';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Record } from 'types';
import { getTKey, months } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { RecordItem } from './RecordItem';
import { RecordsGroup } from './RecordsGroup';
import { Filters, useCarRecords } from './useCarRecords';
import { toast } from 'react-toastify';
const styles = ({ breakpoints, spacing, palette }: Theme) => ({
  modal: {
    margin: spacing(1),
  },
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    outline: 'none',
  },
  container: {
    backgroundColor: 'rgba(46, 46, 46, 1)',
    width: 548,
    minHeight: 400,
    padding: spacing(4, 3, 1),
    borderRadius: spacing(1),
    position: 'relative',
    [breakpoints.down('sm')]: {
      padding: spacing(4, 2, 1),
    },
  },
  title: {
    fontSize: 20,
    fontWeight: '500',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
  footer: {
    paddingTop: spacing(1),
    minHeight: 40,
  },
  footerContent: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  divider: {
    margin: spacing(2, 0),
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
  },
  close: {
    cursor: 'pointer',
  },
  formControl: {
    marginBottom: spacing(2),
    [breakpoints.up('md')]: {
      margin: spacing(1, 1, 2, 1),
      minWidth: 120,
    },
  },
  recordsList: {
    height: 300,
    overflow: 'scroll',
  },
  button: {
    color: 'rgba(72,72,72, 1) !important',
  },
  filters: {
    [breakpoints.up('md')]: {
      marginLeft: spacing(-2),
    },
  },
});
interface RecordType {
  open: boolean;
  carId: string;
  addRecord: (records: Record[]) => void;
  addRecordGroup: (records: Record[][]) => void;
  onClose: any;
}

const tKey = getTKey('record_multi_select');
export const RecordMultiSelect: ComponentType<RecordType> = ({ open, carId, addRecord, addRecordGroup, onClose }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();

  const [records, setRecords] = useState<Record[]>([]);
  const [recordGroup, setRecordGroup] = useState<Record[][]>([]);
  const [filters, setFilters] = useState<Filters>({
    category: 'all',
    year: dayjs().year(),
    month: 'all',
    type: 'all',
  });
  const { allCategories, allMonths, allYears, filteredRecords, carData } = useCarRecords({ carId, ...filters });

  const handleChange = (checked: boolean, selected: Record) => {
    if (recordGroup.length > 0 || (records.length > 2 && checked === true)) {
      toast(t('record_multi_select_note_selecting'), { toastId: 'record_multi_select_note_selecting' });
      return;
    }
    if (checked && !records.some((record) => record.id === selected.id)) {
      setRecords((values) => [...values, selected]);
    } else {
      setRecords((values) => {
        const index = values.indexOf(selected);
        if (index > -1) {
          const temp = [...values];
          temp.splice(index, 1);
          return temp;
        }
        return values;
      });
    }
  };

  const saveChanges = () => {
    if (records.length > 0) {
      records.map((record) => addRecord([record]));
    }
    if (recordGroup.length > 0) {
      addRecordGroup(recordGroup);
    }
    onClose();
  };

  const handleChangeGroup = (checked: boolean, selected: Record[]) => {
    if ((recordGroup.length > 0 && checked === true) || records.length > 0) {
      toast(t('record_multi_select_note_selecting'), { toastId: 'record_multi_select_note_selecting' });
      return;
    }
    if (checked && !recordGroup.some((record) => record[0].groupId === selected[0].groupId)) {
      setRecordGroup((values) => [...values, selected]);
    } else {
      setRecordGroup((values) => {
        const index = values.indexOf(selected);
        if (index > -1) {
          const temp = [...values];
          temp.splice(index, 1);
          return temp;
        }
        return values;
      });
    }
  };
  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className={classes.modal}
    >
      <Box className={classes.main}>
        <Box className={classes.container}>
          <Box className={classes.header}>
            <Typography className={classes.title}>{t(tKey('title'))}</Typography>
            <Close onClick={onClose} className={classes.close} />
          </Box>
          <Grid container spacing={1} className={classes.filters}>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                size='small'
                className={classes.formControl}
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                <>
                  <InputLabel htmlFor='type'>{t(tKey('type'))}</InputLabel>
                  <Select
                    size='small'
                    native
                    sx={{ display: { xs: 'block', md: 'none' } }}
                    id='type'
                    value={filters.type}
                    label={t(tKey('type'))}
                    onChange={(event) => {
                      setFilters((filter) => ({ ...filter, type: event.target.value }));
                    }}
                  >
                    {['all', 'local', 'assisted'].map((type) => (
                      <option key={type} value={type}>
                        {capitalize(t(tKey(type)))}
                      </option>
                    ))}
                  </Select>
                </>
              </FormControl>
              <FormControl
                fullWidth
                size='small'
                className={classes.formControl}
                sx={{ display: { md: 'block', xs: 'none' } }}
              >
                <>
                  <InputLabel htmlFor='type'>{t(tKey('type'))}</InputLabel>
                  <Select
                    size='small'
                    sx={{ display: { md: 'block', xs: 'none' } }}
                    id='type'
                    label={t(tKey('type'))}
                    value={filters.type}
                    onChange={(event) => {
                      setFilters((filter) => ({ ...filter, type: event.target.value }));
                    }}
                  >
                    {['all', 'local', 'assisted'].map((type) => (
                      <MenuItem key={type} value={type}>
                        {capitalize(t(tKey(type)))}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                size='small'
                className={classes.formControl}
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                <>
                  <InputLabel htmlFor='year'>{t(tKey('year'))}</InputLabel>
                  <Select
                    size='small'
                    native
                    sx={{ display: { xs: 'block', md: 'none' } }}
                    id='year'
                    value={filters.year}
                    onChange={(event) => {
                      setFilters((filter) => ({ ...filter, year: Number(event.target.value) }));
                    }}
                    label={t(tKey('year'))}
                  >
                    {allYears.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Select>
                </>
              </FormControl>
              <FormControl
                fullWidth
                size='small'
                className={classes.formControl}
                sx={{ display: { md: 'block', xs: 'none' } }}
              >
                <>
                  <InputLabel htmlFor='year'>{t(tKey('year'))}</InputLabel>
                  <Select
                    size='small'
                    sx={{ display: { md: 'block', xs: 'none' } }}
                    id='year'
                    value={filters.year}
                    onChange={(event) => {
                      setFilters((filter) => ({ ...filter, year: Number(event.target.value) }));
                    }}
                    label={t(tKey('year'))}
                  >
                    {allYears.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                size='small'
                className={classes.formControl}
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                <>
                  <InputLabel htmlFor='month'>{t(tKey('month'))}</InputLabel>
                  <Select
                    size='small'
                    native
                    sx={{ display: { xs: 'block', md: 'none' } }}
                    id='month'
                    value={filters.month}
                    onChange={(event) => {
                      setFilters((filter) => ({ ...filter, month: event.target.value }));
                    }}
                    label={t(tKey('month'))}
                  >
                    {['all', ...allMonths].map((month) => (
                      <option key={month} value={month}>
                        {month === 'all' ? capitalize(t(tKey('all'))) : t(`__month__${months[Number(month)]}`)}
                      </option>
                    ))}
                  </Select>
                </>
              </FormControl>
              <FormControl
                fullWidth
                size='small'
                className={classes.formControl}
                sx={{ display: { md: 'block', xs: 'none' } }}
              >
                <>
                  <InputLabel htmlFor='month'>{t(tKey('month'))}</InputLabel>
                  <Select
                    size='small'
                    sx={{ display: { md: 'block', xs: 'none' } }}
                    id='month'
                    value={filters.month}
                    onChange={(event) => {
                      setFilters((filter) => ({ ...filter, month: event.target.value }));
                    }}
                    label={t(tKey('month'))}
                  >
                    {['all', ...allMonths].map((month) => (
                      <MenuItem key={month} value={month}>
                        {month === 'all' ? capitalize(t(tKey('all'))) : t(`__month__${months[Number(month)]}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                size='small'
                className={classes.formControl}
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                <>
                  <InputLabel htmlFor='category'>{t(tKey('category'))}</InputLabel>
                  <Select
                    size='small'
                    native
                    sx={{ display: { xs: 'block', md: 'none' } }}
                    id='category'
                    value={filters.category}
                    onChange={(event) => {
                      setFilters((filter) => ({ ...filter, category: event.target.value }));
                    }}
                    label={t(tKey('category'))}
                  >
                    {['all', ...allCategories].map((category) => (
                      <option key={category} value={category}>
                        {capitalize(t(`__record_category__${category}`))}
                      </option>
                    ))}
                  </Select>
                </>
              </FormControl>
              <FormControl
                fullWidth
                size='small'
                className={classes.formControl}
                sx={{ display: { md: 'block', xs: 'none' } }}
              >
                <>
                  <InputLabel htmlFor='category'>{t(tKey('category'))}</InputLabel>
                  <Select
                    size='small'
                    sx={{ display: { md: 'block', xs: 'none' } }}
                    id='category'
                    value={filters.category}
                    onChange={(event) => {
                      setFilters((filter) => ({ ...filter, category: event.target.value }));
                    }}
                    label={t(tKey('category'))}
                  >
                    {['all', ...allCategories].map((category) => (
                      <MenuItem key={category} value={category}>
                        {capitalize(t(`__record_category__${category}`))}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              </FormControl>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.recordsList}>
              {filteredRecords.map((record) => {
                if (isArray(record)) {
                  return (
                    <RecordsGroup
                      handleChange={handleChangeGroup}
                      value={recordGroup.some((r) => r[0].groupId === record[0].groupId)}
                      key={record[0].groupId}
                      data={record}
                    />
                  );
                } else
                  return (
                    <RecordItem
                      handleChange={handleChange}
                      value={records.some((r) => r.id === record.id)}
                      key={record.id}
                      data={record}
                      mileageMetric={carData?.mileageMetric}
                    />
                  );
              })}
            </Grid>
          </Grid>

          <Box className={classes.footer}>
            <Box className={classes.footerContent}>
              <Button
                disabled={recordGroup.length === 0 && records.length === 0}
                onClick={saveChanges}
                classes={{ disabled: classes.button }}
              >
                {t(tKey('add'))}
              </Button>
              <Button onClick={onClose}>{t(tKey('cancel'))} </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
