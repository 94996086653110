import { QuestionAnswer, ThumbDownAlt, ThumbUpAlt, Visibility } from '@mui/icons-material';
import { Theme } from '@mui/material';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  reaction: {
    color: palette.text.hint,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 700,
    '& svg': {
      width: 20,
      height: 20,
      paddingRight: spacing(0.5),
    },
  },
  dot: {
    padding: spacing(0, 1.75),
    color: 'rgba(168, 166, 171, 1)',
    [breakpoints.down('sm')]: {
      padding: spacing(0, 0.5),
    },
  },
  hasUserReact: {
    color: palette.primary.main,
    '& svg': {
      color: palette.primary.main,
    },
  },
});

interface ReactionsType {
  isLiked: boolean;
  likes: number;
  isDisliked: boolean;
  dislikes: number;
  views: number;
  comments: number;
}

export const Reactions: ComponentType<ReactionsType> = ({ comments, dislikes, isDisliked, isLiked, likes, views }) => {
  const classes = useClasses(styles);

  return (
    <div className={classes.root}>
      {isDisliked}
      <div className={clsx(classes.reaction, [isLiked && classes.hasUserReact])}>
        <ThumbUpAlt></ThumbUpAlt>
        {likes}
      </div>
      <div className={classes.dot}>•</div>
      <div className={clsx(classes.reaction, [isDisliked && classes.hasUserReact])}>
        <ThumbDownAlt></ThumbDownAlt>
        {dislikes}
      </div>
      <div className={classes.dot}>•</div>
      <div className={classes.reaction}>
        <QuestionAnswer></QuestionAnswer>
        {comments}
      </div>
      <div className={classes.dot}>•</div>
      <div className={classes.reaction}>
        <Visibility></Visibility>
        {views}
      </div>
    </div>
  );
};
