import { Story } from 'components/Stories/AddStoryContent';
import { uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecordsByCarQuery, useStoryQuery } from 'services';
import { useUpdateStoryMutation } from 'services/story/hooks/useUpdateStoryMutation';
import { useUploadStoryImageMutation } from 'services/story/hooks/useUploadStoryImageMutation';
import { Record } from 'types';
import { STORY_ELEMENT_TYPE } from 'utils';
import { getAvailableYoutubeCoverUrl, getYoutubeIdFromUrl } from 'utils/YouTubeUtil';
import { transformStory } from 'utils/transformStory';
import { transformStoryEdit } from 'utils/trasformDraft';
type useEditStoryType = (
  carId: string,
  storyId: string
) => {
  elements: Story[];
  records: any;
  recordGroup: any;
  title: string;
  descLength: number;
  disabled: boolean;
  activeStep: number;
  coverIndex: { elementIndex: number; imageIndex: number };
  isDirty: boolean;
  loading: boolean;
  isLoading: boolean;
  saving: boolean;
  setCover: (cover: { elementIndex: number; imageIndex: number }) => void;
  setDisabled: (disables: any) => void;
  setElements: (elements: any) => void;
  setTitle: (title: any) => void;
  addGallery: (images: any) => void;
  addText: () => void;
  addVideo: (link: string) => void;
  addDescription: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => void;
  addRecord: (records: Record[]) => void;
  addRecordGroup: (records: Record[][]) => void;
  removeElement: (index: number) => void;
  handlePrevious: () => void;
  handleNext: () => void;
  setIsDirty: (state: boolean) => void;
  autoSelectCover: () => boolean;
  setSaving: (state: boolean) => void;
};

export const useEditStory: useEditStoryType = (carId, storyId) => {
  const { t } = useTranslation();
  const [elements, setElements] = useState<Story[]>([]);
  const [title, setTitle] = useState('');
  const [coverIndex, setCoverIndex] = useState({ elementIndex: -1, imageIndex: -1 });
  const { data, isLoading } = useStoryQuery({ carId, storyId });
  const [records, setRecords] = useState<{ [id: string]: Record }>({});
  const [recordGroup, setRecordGroup] = useState<{ [id: string]: Record[] }>({});
  const [descLength, setDescLength] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const navigate = useNavigate();
  const { data: recordsCar } = useRecordsByCarQuery(carId ?? '', 'records', {
    enabled: true,
  });
  const [disabled, setDisabled] = useState(true);
  const [activeStep, setActiveStep] = useState(0);

  const imageUploadMutation = useUploadStoryImageMutation();
  const mutation = useUpdateStoryMutation();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    if (!isLoading && data && recordsCar) {
      transformDraftNext(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  useEffect(() => {
    setDescLength(
      elements.reduce((prev, element) => {
        if (element.elementType === STORY_ELEMENT_TYPE.TEXT) {
          return (element?.texts?.[0]?.originalText?.replaceAll(' ', '')?.length ?? 0) + prev;
        }
        return prev;
      }, 0)
    );
    setIsDirty(true);
  }, [elements]);

  useEffect(() => {
    setIsDirty(true);
  }, [title]);

  const transformDraftNext = (story: any) => {
    const { stories, title } = transformStoryEdit(story);
    setTitle(title);

    if (stories) {
      setElements(stories);

      stories?.forEach((story, sIndex) => {
        if (story.elementType === STORY_ELEMENT_TYPE.GALLERY) {
          story.images?.map((image, iIndex) => {
            if (image.isCover === true) {
              setCoverIndex({ elementIndex: sIndex, imageIndex: iIndex });
              return null;
            }
            return null;
          });
        }
        if (story.elementType === STORY_ELEMENT_TYPE.RECORD) {
          var records = (story.records ?? []).reduce(
            (prev, record) => ({
              ...prev,
              [record.recordId]: recordsCar.find((carRecord: any) => record.recordId === carRecord.id) as Record,
            }),
            {}
          );
          setRecords((prevRecords) => ({ ...prevRecords, ...records }));
        }
        if (story.elementType === STORY_ELEMENT_TYPE.ASSISTED_RECORD) {
          let records = (story.assistedRecords ?? []).reduce(
            (prev, record) => ({
              ...prev,
              [record.groupId]: recordsCar.find(
                (carRecord: any, i: number) => Array.isArray(carRecord) && record.groupId === carRecord[0].groupId
              ) as Record,
            }),
            {}
          );
          setRecordGroup((prevRecords) => ({ ...prevRecords, ...records }));
        }
      });
      setTimeout(() => {
        setIsDirty(false);
      }, 100);
    }
  };

  const addGallery = (images: any[]) => {
    setElements((elements) => {
      const urls: Story = images.reduce((prev, image, index) => {
        const filename = image.file.name.split('.');
        const ext = filename[filename.length - 1];
        const file = `${elements.length === 0 ? 1 : elements.length + 1}_${index}.${ext}`;
        if (prev?.images) {
          return {
            ...prev,
            images: [
              ...prev.images,
              {
                id: uniqueId('element'),
                dataUrl: image.data_url,
                url: image.url,
                isCover: false,
                file: image.file,
                filename: file,
              },
            ],
          };
        }
        return {
          id: uniqueId('element'),
          elementType: STORY_ELEMENT_TYPE.GALLERY,
          images: [
            {
              dataUrl: image.data_url,
              url: image.url,
              isCover: false,
              file: image.file,
              filename: file,
            },
          ],
        };
      }, {});
      return [...elements, urls];
    });
    setIsDirty(true);
  };

  const addDescription = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    setElements((arr) => {
      let temp = [...arr];
      if (temp?.[index].texts?.[0]) {
        temp[index].texts = [{ originalText: event.target.value }];
        return temp;
      } else return [...arr];
    });
    setIsDirty(true);
  };

  const addText = () => {
    setElements((elements) => [
      ...elements,
      {
        id: uniqueId('element'),
        elementType: STORY_ELEMENT_TYPE.TEXT,
        texts: [
          {
            originalText: '',
          },
        ],
      },
    ]);
    setIsDirty(true);
  };

  const removeElement = (index: number) => {
    setElements((elements) => {
      if (index > -1) {
        const temp = [...elements];
        temp.splice(index, 1);
        return temp;
      }
      return elements;
    });
    setIsDirty(true);
  };

  const addRecord = (records: Record[]) => {
    const group = records.reduce(
      (prev, record) => {
        return { ...prev, [record.id ?? '']: record };
      },

      {} as { [id: string]: Record }
    );

    setRecords((groups) => ({ ...groups, ...group }));
    setElements((elements) => {
      const temp = [
        ...elements,
        {
          id: uniqueId('element'),
          elementType: STORY_ELEMENT_TYPE.RECORD,
          records: records.map((record) => ({ recordId: record.id })),
        },
      ];

      return temp;
    });
    setIsDirty(true);
  };

  const addRecordGroup = (records: Record[][]) => {
    const group = records.reduce(
      (prev, record) => {
        return { ...prev, [record[0]?.groupId ?? '']: record };
      },

      {} as { [id: string]: Record[] }
    );

    setRecordGroup((groups) => ({ ...groups, ...group }));

    setElements((elements) => {
      const temp = [
        ...elements,
        {
          id: uniqueId('element'),
          elementType: STORY_ELEMENT_TYPE.ASSISTED_RECORD,
          assistedRecords: records.map((record) => ({ groupId: record[0]?.groupId ?? '' })),
        },
      ];
      return temp;
    });
    setIsDirty(true);
  };

  const addVideo = async (link: string) => {
    const cover = await getAvailableYoutubeCoverUrl(getYoutubeIdFromUrl(link) as string);
    setElements((elements) => [
      ...elements,
      {
        id: uniqueId('element'),
        elementType: STORY_ELEMENT_TYPE.VIDEO,
        youtubeVideoUrl: link,
        youtubeVideoCoverUrl: cover,
        images: [
          {
            url: cover as string,
            imageId: uniqueId('element'),
            isCover: false,
            dataUrl: cover as string,
          },
        ],
      },
    ]);
  };

  const setCover = ({ elementIndex, imageIndex }: { elementIndex: number; imageIndex: number }) => {
    setCoverIndex({ elementIndex, imageIndex });
    setElements((elements) => {
      let temp = [...elements];
      temp = elements.map((element, eIndex) => {
        if (element.elementType === STORY_ELEMENT_TYPE.GALLERY) {
          element.images = element?.images?.map((image, iIndex) => {
            return {
              ...image,
              dataUrl: image.dataUrl,
              url: image.url,
              isCover: (eIndex === elementIndex && imageIndex === iIndex) || false,
            };
          });
        }
        return element;
      });
      // updateDraft(temp);
      return temp;
    });
    setIsDirty(true);
  };

  const autoSelectCover = () => {
    if (coverIndex.elementIndex !== -1) {
      return false;
    }
    const images = elements
      // eslint-disable-next-line array-callback-return
      .map((element) => {
        if (element.elementType === STORY_ELEMENT_TYPE.GALLERY) {
          return element.images;
        }
      })
      .flat();
    if (images.length === 0) {
      return false;
    } else if (images.length === 1) {
      setCover({ elementIndex: 0, imageIndex: 0 });
      return false;
    } else {
      return true;
    }
  };

  const handlePrevious = () => {
    const imageElementsLength = elements.filter((element) => element.elementType === STORY_ELEMENT_TYPE.GALLERY).length;
    if (activeStep === 2 && !imageElementsLength) {
      setActiveStep((step) => {
        return step - 2;
      });
    } else {
      setActiveStep((step) => {
        return step - 1;
      });
    }
  };

  const handleNext = () => {
    const imageElementsLength = elements.filter(
      (element) =>
        element.elementType === STORY_ELEMENT_TYPE.GALLERY || element.elementType === STORY_ELEMENT_TYPE.VIDEO
    ).length;
    if (activeStep === 0 && !imageElementsLength) {
      setActiveStep((step) => {
        return step + 2;
      });
      return;
    } else if (activeStep !== 2) {
      setActiveStep((step) => {
        return step + 1;
      });
      return;
    } else if (activeStep === 2) {
      setLoading(true);
      setSaving(true);
      const elementsFiltered = elements.map((element) => {
        if (element.elementType === STORY_ELEMENT_TYPE.VIDEO) {
          const images = element.images?.map((image, _index) => {
            image.dataUrl = undefined;
            return image;
          });
          element.images = images;
          return element;
        } else return element;
      });
      if (imageElementsLength) {
        let updatedElements = elements
          .map((element, index) => {
            if (element.elementType === STORY_ELEMENT_TYPE.GALLERY) {
              return element.images?.map((image, _index) => {
                if (image.filename) {
                  const filename = image.filename.split('.');
                  const ext = filename[filename.length - 1];
                  const file = `${index + 1}_${_index}.${ext.split('?')[0]}`;
                  image.filename = file;
                }
                return image;
              });
            }
            return [];
          })
          .flat();
        var imagesForMutationCount = updatedElements.filter((image) => image?.file).length;

        var storyElement = JSON.parse(JSON.stringify(elementsFiltered));
        if (imagesForMutationCount > 0) {
          imageUploadMutation.mutate(
            { carId: carId, images: updatedElements ?? [] },
            {
              onSuccess(data) {
                Object.values(data ?? []).forEach((image: any) => {
                  //@ts-ignore
                  storyElement[image.position - 1].images[image.index] = {
                    url: image.path,
                    imageId: image.image_id,
                    isCover: storyElement?.[image.position - 1]?.images?.[image.index].isCover,
                  };
                });
                publishStory(storyElement);
              },
              onError(e: any) {
                if (e?.response?.data?.message === 'vehicle_not_found_error') {
                  setLoading(false);
                  setSaving(false);
                  toast(t('edit_story_no_vehicle'));
                }
              },
            }
          );
        } else return publishStory();
      } else return publishStory();
    }
  };

  const publishStory = (updatedElements = elements) => {
    try {
      mutation.mutate(
        {
          carId,
          storyId,
          elements: transformStory(
            JSON.parse(JSON.stringify(updatedElements)),
            title,
            data?.is_mileage_hidden ?? false,
            Number(data?.car.mileage)
          ),
        },
        {
          onSuccess() {
            navigate(`/stories/${carId}/${storyId}`, { state: { prev: { path: 'stories/edit' } } });
            toast(t('edit_story_save_success'));
            setLoading(false);
            setSaving(false);
          },
          onError(e: any) {
            if (e?.response?.data?.message === 'story_banned') {
              toast(t('create_story_banned'));
            } else if (e?.response?.data?.message === 'vehicle_not_found_error') {
              toast(t('edit_story_no_vehicle'));
            } else {
              toast(t('__common__error'));
            }
            setLoading(false);
            setSaving(false);
          },
        }
      );
    } catch (e) {
      toast(t('edit_story_save_failed'));
      setLoading(false);
      setSaving(false);
    }
  };

  return {
    elements,
    records,
    recordGroup,
    title,
    descLength,
    disabled,
    activeStep,
    coverIndex,
    isDirty,
    loading,
    isLoading,
    saving,
    setCover,
    setDisabled,
    setDescLength,
    setElements,
    setTitle,
    addDescription,
    addGallery,
    addText,
    addRecord,
    addRecordGroup,
    addVideo,
    removeElement,
    handlePrevious,
    handleNext,
    setIsDirty,
    autoSelectCover,
    setSaving,
  };
};
