import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransferCarPayload, useTransferCarMutation } from 'services';
import { getTKey } from 'utils';

type useCarTransferType = () => {
  idToTransfer: string;
  transferSuccess: boolean;
  newOwnerEmail: string;
  errorMessage: string;
  transferInProgress: boolean;
  handleOnTransfer: ({ id, new_owner_email, password }: TransferCarPayload) => void;
  handleClose: () => void;
  handleConfirmTransfer: () => void;
};

const tKey = getTKey('transfer_car');

export const useCarTransfer: useCarTransferType = () => {
  const { t } = useTranslation();
  const mutation = useTransferCarMutation();
  const [transferInProgress, setTransferInProgress] = useState<boolean>(false);
  const [transferSuccess, setTransferSuccess] = useState<boolean>(false);
  const [idToTransfer, setIdToTransfer] = useState<string>('');
  const [newOwnerEmail, setNewOwnerEmail] = useState<string>('');
  const [password, setPassword] = useState<string | undefined>('');
  const [accessToken, setAccessToken] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleOnTransfer = ({ id, new_owner_email, password, access_token, code }: TransferCarPayload) => {
    setIdToTransfer(id);
    setNewOwnerEmail(new_owner_email);
    setPassword(password);
    setAccessToken(access_token as string);
    setCode(code as string);
  };

  const handleClose = () => {
    setIdToTransfer('');
    setNewOwnerEmail('');
    setPassword('');
  };

  const handleError = (status: number) => {
    switch (status) {
      case 403:
        setErrorMessage(t(tKey('error_own_account')));
        break;
      case 401:
        setErrorMessage(t(tKey('error_user_email_not_confirmed')));
        break;
      case 405:
        setErrorMessage(t(tKey('error_new_owner_email_not_confirmed')));
        break;
      case 409:
        setErrorMessage(t(tKey('error_limit_exceeded')));
        break;
      case 400:
        setErrorMessage(t(tKey('error_add_time')));
        break;
      case 404:
        setErrorMessage(t(tKey('error_incorrect_password')));
        break;
      case 500:
        setErrorMessage(t(tKey('error_invalid_email')));
        break;
      default:
        setErrorMessage(t(tKey('error_default')));
    }
    handleClose();
  };

  const handleConfirmTransfer = () => {
    setTransferInProgress(true);
    mutation.mutate(
      { id: idToTransfer, new_owner_email: newOwnerEmail, password, access_token: accessToken, code: code },
      {
        onSuccess: () => {
          //@ts-ignore
          gtag?.('event', 'transfer', { method: 'transfer' });

          setTransferInProgress(false);
          setTransferSuccess(true);
          handleClose();
        },
        onError: (data: any) => {
          setTransferInProgress(false);
          handleError(data?.response?.status);
        },
      }
    );
  };

  return {
    idToTransfer,
    transferSuccess,
    newOwnerEmail,
    errorMessage,
    transferInProgress,
    handleOnTransfer,
    handleClose,
    handleConfirmTransfer,
  };
};
