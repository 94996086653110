import { Container } from '@mui/material';
import { ComponentType } from 'react';
import { useClasses } from 'utils/hooks/useClasses';

const styles = {
  maxWidthLg: {
    maxWidth: 1280,
  },
};

interface WrapperProps {
  maxwidth?: any;
  children?: any;
  className?: string;
}

export const Wrapper: ComponentType<WrapperProps> = ({ children, maxwidth, className }) => {
  const classes = useClasses(styles);

  return (
    <Container classes={{ maxWidthLg: classes.maxWidthLg }} className={className} maxWidth={maxwidth}>
      <>{children}</>
    </Container>
  );
};
