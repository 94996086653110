import { ArrowBack, ArrowForward, ChevronLeft, ChevronRight, CloseRounded } from '@mui/icons-material';
import { Fab, Theme } from '@mui/material';
import clsx from 'clsx';
import { IImage } from 'features/stories-feed/StoriesTypes';
import { ComponentType, useMemo, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import Lightbox from 'react-spring-lightbox';
import { useClasses } from 'utils/hooks/useClasses';

interface AdminStoryContentImagesType {
  images: Array<IImage>;
}

const stylesImage = ({ palette, spacing, breakpoints }: Theme) => ({
  carouselGallery: {
    '& .control-dots  .dot': {
      background: palette.primary.main,
    },
  },
  image: {
    width: '100%',
    marginTop: spacing(1.5),
    cursor: 'pointer',
    '& img': {
      maxWidth: '100%',
      maxHeight: 300,
      width: 'unset !important',
    },
  },
  galleryButton: {
    width: 28,
    height: 28,
    borderRadius: 14,
    background: 'rgba(255, 255, 255, 0.3)',
    position: 'absolute',
    top: '50%',
    zIndex: 1,
    transition: 'background-color 200ms',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
      color: 'rgba(0, 0, 0, 0.64)',
    },
    opacity: 0,
  },
  allowPress: {
    opacity: 1,
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.5)',
    },
  },
  prevArrowButton: {
    left: 16,
  },
  nextArrowButton: {
    right: 16,
  },
  header: {
    margin: spacing(2),
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  button: {
    height: 40,
    width: 45,
    [breakpoints.up('md')]: {
      margin: spacing(3),
    },
  },
  closeButton: {
    height: 40,
    width: 40,
    [breakpoints.up('md')]: {
      margin: spacing(3),
    },
    marginTop: 'env(safe-area-inset-top)',
  },
});

export const StoryContentImages: ComponentType<AdminStoryContentImagesType> = ({ images }) => {
  const classes = useClasses(stylesImage);

  const [currentImageIndex, setCurrentIndex] = useState(0);

  const gotoPrevious = () => currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () => currentImageIndex + 1 < (imagesList?.length ?? 0) && setCurrentIndex(currentImageIndex + 1);

  const imagesList = useMemo(() => {
    return images.filter((i) => i).map((image, i) => ({ alt: `Image ${i}`, src: image.url ?? '' }));
  }, [images]);

  const [expand, setExpand] = useState(false);

  return (
    <>
      <Carousel
        autoPlay={false}
        showThumbs={false}
        showStatus={false}
        className={classes.carouselGallery}
        showIndicators={images.length > 1}
        renderArrowPrev={(clickHandler, hasPrev, labelPrev) => (
          <div
            className={clsx(classes.prevArrowButton, classes.galleryButton, { [classes.allowPress]: hasPrev })}
            onClick={clickHandler}
          >
            <ArrowBack />
          </div>
        )}
        renderArrowNext={(clickHandler, hasNext, labelNext) => (
          <div
            className={clsx(classes.nextArrowButton, classes.galleryButton, { [classes.allowPress]: hasNext })}
            onClick={clickHandler}
          >
            <ArrowForward />
          </div>
        )}
      >
        {images.map((image, index) => {
          return (
            <div
              key={image.id}
              className={classes.image}
              onClick={() => {
                setExpand(true);
                setCurrentIndex(index);
              }}
            >
              <img src={image.url} alt='' />
            </div>
          );
        })}
      </Carousel>

      <Lightbox
        isOpen={expand}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        onClose={() => setExpand(false)}
        renderHeader={() => (
          <div className={classes.header}>
            <Fab color='primary' aria-label='Add' className={classes.closeButton} onClick={() => setExpand(false)}>
              <CloseRounded />
            </Fab>
          </div>
        )}
        images={imagesList}
        style={{ background: 'rgba(0, 0, 0, 0.87)', zIndex: 1400 }}
        currentIndex={currentImageIndex}
        renderNextButton={() =>
          images.length > 1 && (
            <Fab color='secondary' aria-label='Add' className={classes.button} onClick={gotoNext}>
              <ChevronRight />
            </Fab>
          )
        }
        renderPrevButton={() =>
          images.length > 1 && (
            <Fab color='secondary' aria-label='Add' className={classes.button} onClick={gotoPrevious}>
              <ChevronLeft />
            </Fab>
          )
        }
      />
    </>
  );
};
