import { Box, Tab, Tabs, Theme, Typography } from '@mui/material';
import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useCarQuery, useUserProfile } from 'services';
import { Record } from 'types';
import { useClasses } from 'utils/hooks/useClasses';
import { Story } from './AddStoryContent';
import { RecordItem } from './RecordItem';
import { RecordsGroup } from './RecordsGroup';
import { TabPanel } from './TabPanel';

import dayjs from 'dayjs';
import { Reactions } from 'features/stories-feed/components/Reactions';
import { StoryHeader } from 'features/stories-feed/components/StoryHeader';
import { upperCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { STORY_ELEMENT_TYPE } from 'utils';
import { getYoutubeIdFromUrl } from 'utils/YouTubeUtil';
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const styles = ({ palette, spacing, breakpoints }: Theme): { [key: string]: React.CSSProperties } => ({
  tab: {
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 500,
    cursor: 'pointer',
    padding: spacing(2, 0, 3),
    width: '100%',
    color: 'white',
  },
  text: {
    margin: spacing(0, 0, 3),
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
  },

  imgContainer: {
    position: 'relative',
    width: 40,
  },
  userProfilePic: {
    borderRadius: 99,
  },
  dot: {
    width: 5,
    height: 5,
    borderRadius: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
  carInfo: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
  divider: {
    margin: spacing(2, 0),
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
  },
  titleFull: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '30px',
    letterSpacing: '0.15px',
    paddingBottom: spacing(3),
    [breakpoints.down('sm')]: {
      paddingBottom: spacing(1.5),
    },
  },
  title: {
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    margin: spacing(2, 0),
    fontSize: 20,
  },
  desc: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    margin: spacing(2, 0),
  },
  cover: {
    width: '100%',
    margin: spacing(0, 0, 3),
  },
  previewText: {
    padding: spacing(1, 0),
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  timeAgo: {
    color: palette.text.hint,
  },
  record: {
    margin: spacing(0, 0, 3),
  },
  galley: {
    marginBottom: spacing(3),
  },
});
export const Preview = ({
  elements,
  title,
  recordGroup,
  records,
  carId,
  coverIndex,
}: {
  elements: Story[];
  carId: string | undefined;
  title: string;
  records: { [id: string]: Record };
  recordGroup: { [id: string]: Record[] };
  coverIndex: { elementIndex: number; imageIndex: number };
}) => {
  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const getLang = () => {
    return i18n.language;
  };
  const lang = getLang();

  try {
    if (lang === 'uk') {
      dayjs.locale(require(`dayjs/locale/uk`));
    } else if (lang === 'es') {
      dayjs.locale(require(`dayjs/locale/es`));
    } else {
      dayjs.locale(require(`dayjs/locale/en`));
    }
  } catch (e) {
    console.log(`Cannot load ${lang} language. Will be used english language`);
  }

  const classes = useClasses(styles);
  const [tab, setTab] = useState(0);

  const { data: profile } = useUserProfile();
  const { data: carData } = useCarQuery(carId ?? '');
  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const firstDesc = () => {
    const index = elements.findIndex((element) => element.elementType === STORY_ELEMENT_TYPE.TEXT);
    if (index > -1) {
      const text = elements?.[index]?.texts?.[0].originalText;
      return text?.substring(0, 100) + '...';
    }
    return '';
  };

  const statusFormatter = (currentIndex: number, totalItems: number) => {
    return `${currentIndex} ${t('__common__of')} ${totalItems}`;
  };
  return (
    <Box sx={{ width: '100%' }} className={classes.root}>
      <Box>
        <Typography variant='body2'>*{' ' + t('create_story_preview_description')}</Typography>
      </Box>
      <Tabs
        variant='fullWidth'
        classes={{ root: classes.tab }}
        value={tab}
        onChange={(_, value) => {
          setTab(value);
        }}
        aria-label='basic tabs example'
      >
        <Tab label={upperCase(t('__common__full'))} {...a11yProps(0)} />
        <Tab label={upperCase(t('__common__thumbnail'))} {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyItems: 'center',
            gap: 0,
            marginTop: 2,
            flexDirection: 'column',
            marginBottom: 2,
          }}
        >
          <Typography variant='body1' className={classes.titleFull}>
            {title}
          </Typography>
          {elements.map((element, index) => {
            switch (element.elementType) {
              case STORY_ELEMENT_TYPE.GALLERY: {
                return (
                  <Carousel
                    key={index}
                    swipeable
                    emulateTouch
                    showThumbs={false}
                    // dynamicHeight
                    className={classes.galley}
                    statusFormatter={statusFormatter}
                  >
                    {element.images?.map((image, i) => (
                      <img key={i} src={image.dataUrl} alt='language-icon' />
                    ))}
                  </Carousel>
                );
              }
              case STORY_ELEMENT_TYPE.TEXT: {
                return (
                  <Typography className={classes.text} key={index}>
                    {element?.texts?.[0].originalText}
                  </Typography>
                );
              }
              case STORY_ELEMENT_TYPE.RECORD: {
                return element?.records?.map((record) => {
                  return (
                    <div className={classes.record} key={record.recordId}>
                      <RecordItem
                        data={records[record.recordId]}
                        mileageMetric={carData?.mileageMetric}
                        key={record.recordId}
                      />
                    </div>
                  );
                });
              }
              case STORY_ELEMENT_TYPE.ASSISTED_RECORD: {
                return element?.assistedRecords?.map((record) => {
                  return (
                    <div className={classes.record} key={record.groupId}>
                      <RecordsGroup data={recordGroup[record.groupId]} key={record.groupId} />
                    </div>
                  );
                });
              }
              case STORY_ELEMENT_TYPE.VIDEO: {
                return (
                  <Box style={{ position: 'relative', width: '100%', overflow: 'hidden', paddingTop: '56.25%' }}>
                    {element.youtubeVideoUrl && (
                      <iframe
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          width: '100%',
                          height: '100%',
                          border: 'none',
                        }}
                        src={`https://www.youtube.com/embed/${getYoutubeIdFromUrl(element.youtubeVideoUrl)}`}
                        frameBorder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                        title='Embedded youtube'
                      />
                    )}
                  </Box>
                );
              }
            }
            return <div></div>;
          })}
        </Box>
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <Box
          className={classes.previewStoryBox}
          sx={{
            width: '100%',
            padding: 2,
            backgroundColor: 'rgba(255, 255, 255, 0.07)',
            borderRadius: 1,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          {carData !== undefined && profile !== undefined && (
            <StoryHeader
              car={carData}
              user={profile}
              authorized={false}
              is_mileage_hidden={false}
              openUnregisteredDialog={() => {}}
            />
          )}
          <hr className={classes.divider} />

          <div className={classes.title}>{title}</div>
          {firstDesc() !== '' && <div className={classes.previewText}>{firstDesc()}</div>}

          {elements?.[coverIndex.elementIndex]?.images?.[coverIndex.imageIndex].dataUrl && (
            <div className={classes.coverWrapper}>
              <img
                src={elements?.[coverIndex.elementIndex]?.images?.[coverIndex.imageIndex].dataUrl}
                alt=''
                className={classes.cover}
              />
            </div>
          )}
          <div className={classes.footer}>
            <Reactions comments={19} dislikes={45} isDisliked={false} isLiked={false} likes={423} views={19} />
            <div className={classes.timeAgo}>
              {
                // @ts-ignore
                dayjs.utc().subtract(1, 'day').fromNow()
              }
            </div>
          </div>
        </Box>
      </TabPanel>
    </Box>
  );
};
