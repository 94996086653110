import { ArrowBack, Check, Delete, Edit } from '@mui/icons-material';
import { Button, IconButton, TextField, Theme, Typography } from '@mui/material';
import clsx from 'clsx';
import { upperCase } from 'lodash';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'store2';

import { ConfirmationDialog } from 'components';
import { useUpdateRecordSecureMutation } from 'services';
import { Currency, Record, Volume } from 'types';
import { countTotalMoneySpend, currencySymbol, formatDate, getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { useRecordGroupTitle } from '../hooks/useRecordGroupTitle';
import { useRecordsGroup } from '../hooks/useRecordsGroup';
import { RecordItem } from './RecordItem';

const tKey = getTKey('record_group');

interface RecordsGroupProps {
  data: Record[];
  openGroupId: string;
  mileageMetric: string | undefined;
  totalExpenses: number;
  filterCategory: string;
  currency?: string;
  volume?: Volume;
  publicRecord?: boolean;
  handleOnDelete?: (id: string) => void;
  handleOnEdit?: (id: string) => void;
  handleOnCopy?: (id: string) => void;
  onOpen: (id: string) => void;
  onClose: () => void;
}

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    marginBottom: spacing(2),
    padding: spacing(2, 2, 1),
    backgroundColor: '#414141',
    borderRadius: spacing(0.5),
    boxShadow: '0px 6px 0px -1px #333, 0px 12px 0px -2px #242424',

    [breakpoints.up('md')]: {
      padding: spacing(1, 2),
    },
  },
  rootHidden: {
    display: 'none',
  },
  rootOpenHeader: {
    display: 'flex',
    [breakpoints.up('md')]: {
      display: 'none',
    },
  },
  rootOpen: {
    backgroundColor: '#121212',
    boxShadow: 'none',
    position: 'fixed',
    zIndex: 5,
    left: 0,
    top: 0,
    bottom: 48,
    width: '100%',
    overflowY: 'auto',
    [breakpoints.up('md')]: {
      backgroundColor: 'transparent',
      position: 'static',
    },
    marginTop: 'env(safe-area-inset-top)',
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',

    [breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  innerOpenInfo: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: spacing(3),
    color: 'rgba(255, 255, 255, 0.6)',
  },
  innerOpenTitle: {
    flexGrow: 1,
    order: -1,
    fontSize: 20,
    fontWeight: 500,
    minWidth: 300,
  },
  innerOpen: {
    flexWrap: 'wrap',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(3),
    fontSize: 10,
    letterSpacing: 1.5,
    color: 'rgba(255, 255, 255, 0.6)',
    lineHeight: '16px',
    minWidth: 300,
  },
  infoOpen: {
    marginBottom: spacing(1),
    [breakpoints.up('md')]: {
      display: 'block',
      minWidth: 200,
      marginBottom: 0,
      marginRight: spacing(3),
      color: 'inherit',
      whiteSpace: 'nowrap',
    },
  },
  subInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginBottom: spacing(1),
    [breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
  titleClosed: {
    [breakpoints.down('md')]: {
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 0.3,
    },
  },
  actions: {
    alignSelf: 'flex-end',
    whiteSpace: 'nowrap',

    '& .MuiIconButton-root': {
      color: 'rgba(255, 255, 255, 0.6)',
    },

    [breakpoints.up('md')]: {
      alignSelf: 'flex-start',
      marginLeft: 'auto',
    },
  },
  actionsPublic: {
    minWidth: 280,
    flexDirection: 'row-reverse',
    display: 'flex',

    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  actionButton: {
    letterSpacing: 1.25,
  },
  actionButtons: {
    '& .MuiIconButton-root': {
      color: 'rgba(255, 255, 255, 0.6)',
    },
  },
  date: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
  dot: {
    display: 'inline-block',
    margin: spacing(0, 0.5),
    fontSize: 16,
    color: palette.additionalPrimary['200'],
  },

  dotOut: {
    display: 'inline-block',
    margin: spacing(0, 0.5),
    fontSize: 16,
    color: palette.additionalPrimary['200'],
    [breakpoints.up('md')]: {
      display: 'none',
    },
  },

  dotOutOpen: {
    display: 'inline-block',
    margin: spacing(0, 0.5),
    fontSize: 16,
    color: palette.additionalPrimary['200'],
  },
  input: {
    '& input': {
      padding: 0,
      fontSize: 20,
      fontWeight: 500,
      color: 'rgba(255, 255, 255, 0.87)',

      '&::placeholder': {
        color: 'rgba(255, 255, 255, 0.6)',
      },
    },

    '& fieldset': {
      border: 'none',
    },
  },
  headerButton: {
    marginLeft: spacing(-2.5),
  },
  header: {
    alignItems: 'center',
    marginBottom: spacing(1),

    '& .MuiTypography-root': {
      margin: spacing(0, 'auto', 0, 2),
      fontSize: 20,
    },
  },
  hiddenHeader: {
    display: 'none',
  },
  btnBack: {
    color: 'rgba(255, 255, 255, 0.87)',
  },
  rootOpenAction: {
    display: 'none',
    order: -1,
    [breakpoints.up('md')]: {
      display: 'block',
    },
  },
});

export const RecordsGroup: ComponentType<RecordsGroupProps> = ({
  data,
  openGroupId,
  mileageMetric,
  totalExpenses,
  filterCategory,
  publicRecord,
  currency,
  volume,
  handleOnDelete,
  handleOnEdit,
  handleOnCopy,
  onOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const settingsData = store('driverbook_settings');
  const firstRecord = data[0];
  const groupId = firstRecord.groupId;
  const isOpen = openGroupId === groupId;
  const isHidden = Boolean(openGroupId && openGroupId !== groupId);
  const filteredGroupData =
    filterCategory !== 'all' ? data.filter((record) => record.category === filterCategory) : data;
  const { deleteId, handleGroupDelete, handleClose, handleConfirmDelete } = useRecordsGroup(() => onClose());
  const [editMode, setEditMode] = useState<boolean>(false);
  const { title, setTitle, handleTitleChange } = useRecordGroupTitle(firstRecord.groupTitle as string);
  const mutation = useUpdateRecordSecureMutation();

  const handleTitleUpdate = (): void => {
    if (title) {
      mutation.mutate({ group_id: groupId as string, title });
      setEditMode(false);
    }
  };

  const handleCloseGroup = (): void => {
    setTitle(firstRecord.groupTitle as string);
    setEditMode(false);
    onClose();
  };

  const actionButtons = (
    <>
      {editMode ? (
        <IconButton onClick={handleTitleUpdate} size='large'>
          <Check />
        </IconButton>
      ) : (
        <IconButton onClick={() => setEditMode(true)} size='large'>
          <Edit />
        </IconButton>
      )}
      <IconButton onClick={() => handleGroupDelete(groupId as string)} size='large'>
        <Delete />
      </IconButton>
    </>
  );

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootOpen]: isOpen,
        [classes.rootHidden]: isHidden,
      })}
    >
      <div className={clsx(classes.header, isOpen ? classes.rootOpenHeader : classes.hiddenHeader)}>
        <Button size='small' className={clsx(classes.btnBack, classes.headerButton)} onClick={handleCloseGroup}>
          <ArrowBack width={16} height={16} />
        </Button>
        <Typography variant='h4'>{t(tKey('mobile_title'))}</Typography>
        {!publicRecord && <div className={classes.actionButtons}>{actionButtons}</div>}
      </div>

      <ConfirmationDialog
        description={t(tKey('confirm'))}
        open={Boolean(deleteId)}
        onConfirm={handleConfirmDelete}
        onClose={handleClose}
      />
      <div className={clsx(classes.inner, { [classes.innerOpen]: isOpen })}>
        <div className={clsx(classes.info, !isOpen && classes.infoOpen)}>
          <div className={classes.date}>{formatDate(firstRecord.dateCreated)}</div>
          <span className={clsx(isOpen ? classes.dotOutOpen : classes.dotOut)}>·</span>
          <div className={classes.subInfo}>
            {upperCase(t(tKey('records')))}: {data.length} <span className={classes.dot}>·</span>{' '}
            {upperCase(t(tKey('cost')))}: {countTotalMoneySpend(data)}
            {currencySymbol[(publicRecord ? currency : settingsData?.currency) as Currency]}
          </div>
        </div>
        <div className={clsx(classes.title, isOpen ? classes.innerOpenTitle : classes.titleClosed)}>
          {editMode ? (
            <TextField
              fullWidth
              placeholder={t(tKey('title'))}
              variant='outlined'
              inputRef={(input) => input && input.focus()}
              className={classes.input}
              disabled={!editMode}
              value={title}
              onChange={(e) => handleTitleChange(e.target.value)}
            />
          ) : (
            <span>{title}</span>
          )}
        </div>

        <div className={clsx(classes.actions, isOpen && classes.rootOpenAction, !editMode && classes.actionsPublic)}>
          {isOpen ? (
            <>
              <Button variant='text' color='primary' onClick={handleCloseGroup} className={classes.actionButton}>
                {t(tKey('close_group'))}
              </Button>
              {!publicRecord && actionButtons}
            </>
          ) : (
            <Button
              variant='text'
              color='primary'
              onClick={() => onOpen(firstRecord.groupId as string)}
              className={classes.actionButton}
            >
              {t(tKey('open_group'))}
            </Button>
          )}
        </div>
      </div>
      {isOpen &&
        filteredGroupData.map((record) => (
          <RecordItem
            publicRecord={publicRecord}
            key={record.id}
            data={record}
            mileageMetric={mileageMetric}
            totalExpenses={totalExpenses}
            currency={currency ?? undefined}
            volume={volume}
            onDelete={handleOnDelete}
            onEdit={handleOnEdit}
            onCopy={handleOnCopy}
          />
        ))}
    </div>
  );
};
