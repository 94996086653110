import { queryClient } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ShareCarPayload, useCarShareMutation } from 'services';
import { getTKey } from 'utils';

type useCarShareType = () => {
  identifier: string;
  errorMessage: string;
  handleOnShare: ({ days, carId }: ShareCarPayload) => void;
};

const tKey = getTKey('share_car');

export const useCarShare: useCarShareType = () => {
  const { t } = useTranslation();
  const mutation = useCarShareMutation();
  const [identifier, setIdentifier] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleOnShare = ({ days, carId, show_optional }: ShareCarPayload) => {
    mutation.mutate(
      { carId, days, show_optional },
      {
        onSuccess: ({ link }) => {
          //@ts-ignore
          gtag?.('event', 'share_public_link', { method: 'share_public_link' });

          setIdentifier(link);
        },
        onError: (data: any) => {
          handleError(data?.response?.status);
          queryClient.invalidateQueries('carShareAmount');
        },
      }
    );
  };

  const handleError = (status: number) => {
    switch (status) {
      case 409:
        setErrorMessage(t(tKey('error_limit_exceeded')));
        break;
      default:
        setErrorMessage(t(tKey('error_default')));
    }
  };

  return {
    identifier,
    errorMessage,
    handleOnShare,
  };
};
