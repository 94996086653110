import { Box, FormControlLabel, ListItem, Radio, RadioGroup, Switch, TextField, Theme } from '@mui/material';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Car } from 'types';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'start',
    flexShrink: 0,
    width: '100%',
    padding: spacing(2.5),
    borderRadius: spacing(0.5),
    backgroundColor: 'rgba(42, 42, 42)',
    cursor: 'pointer',
    marginTop: spacing(2),
    flexDirection: 'column',
    '&:not(:last-of-type)': {
      marginRight: spacing(2),
    },

    [breakpoints.up('md')]: {
      flexShrink: 1,
      minWidth: 'auto',
      marginBottom: spacing(2),
    },
  },
  head: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    width: '100%',
  },
  active: {
    border: 1,
    borderStyle: 'solid',
    borderColor: palette.primary.main,
    backgroundColor: 'rgba(187, 134, 252, 0.1) !important',
  },
  image: {
    flexShrink: 0,
    width: 44,
    height: 44,
    marginRight: spacing(2),
    borderRadius: '50%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: '#ccc',
  },
  info: {
    flexGrow: 1,
    maxWidth: 'calc(100% )',
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'white',
    [breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
  make: {
    textTransform: 'capitalize',
  },

  fieldRoot: {
    width: '110%',
    marginTop: spacing(3),
    '& .MuiOutlinedInput-input.Mui-disabled': {
      WebkitTextFillColor: 'rgba(255, 255, 255, 0.87) !important',
    },
    '& .MuiTextField-root': {
      color: '#bb86fc',
    },

    '& .MuiInput-underline:after': {
      borderBottomColor: '#bb86fc',
    },
    '& .MuiOutlinedInput-root': {
      padding: 5,
      '& fieldset': {
        borderColor: '',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.87)',
      },
      '& input[type=number]': {
        MozAppearance: 'textfield',
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
    },
  },
  setHeight: {
    '&.MuiFormControl-root': {
      height: '65%',
    },
    '& .MuiInputBase-root': {
      padding: '10.5px 14px',
      height: '100%',
    },
  },
  radioStyle: {
    margin: spacing(2, 0),
    [breakpoints.up('md')]: {
      width: '100%',
      marginLeft: spacing(2),
    },
  },
  details: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between !important',
    alignItems: 'center',
    marginBottom: 2,
    flexWrap: 'wrap',
    [breakpoints.up('md')]: {
      flexWrap: 'inherit',
    },
  },
});

const tKey = getTKey('create_story_select_car');

type CarListParams = {
  cars: Car[] | undefined;
  selectedCar: { car: Car | undefined; hideMileage: boolean; mileage: number };
  setSelectedCar: (car: { car: Car | undefined; hideMileage: boolean; mileage: number }) => void;
};

export const CarList = ({ cars, selectedCar, setSelectedCar }: CarListParams) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);

  const [mileage, setMileage] = useState<number>(selectedCar.mileage ?? 0);
  const handleChange = (car: Car, checked: boolean) => {
    setMileage(car.mileage);
    if (checked) {
      setSelectedCar({
        ...selectedCar,
        car,
        mileage: mileage,
      });
    }
  };

  const handleChangeMileage = (evt: any) => {
    const value = evt.target.value;
    if (Number(value) < 9999999999 && Number(value >= 0)) {
      setMileage(value);
    } else {
      setMileage(mileage);
    }
  };

  return (
    <RadioGroup>
      {cars?.map((car) => {
        return (
          <ListItem
            className={clsx(classes.root, selectedCar?.car?.id === car.id && classes.active)}
            role='button'
            onClick={() => {
              if (!(selectedCar?.car?.id === car.id)) handleChange(car, !(selectedCar?.car?.id === car.id));
            }}
            tabIndex={0}
            key={car.id}
          >
            <div className={classes.head}>
              <div
                className={classes.image}
                style={{ backgroundImage: car.image ? `url('${car.image}')` : undefined }}
              />
              <div className={classes.info}>
                <div className={classes.title}>
                  <span className={classes.make}>{car.make}</span> {car.model}
                </div>
                <div className={classes.subtitle}>{car.year}</div>
              </div>
              <Radio
                checked={selectedCar?.car?.id === car.id}
                value={car}
                onChange={(event, checked) => handleChange(car, checked)}
              />
            </div>
            {selectedCar?.car?.id === car.id && (
              <Box className={classes.details}>
                <TextField
                  type='number'
                  onKeyDown={(evt: any) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                  onChange={handleChangeMileage}
                  value={mileage}
                  onBlur={(event) => {
                    setSelectedCar({
                      ...selectedCar,
                      hideMileage: selectedCar.hideMileage,
                      mileage: Number(event.target.value),
                    });
                  }}
                  fullWidth
                  size='small'
                  margin='normal'
                  label={`${t(tKey('current_mileage'))}, ${car?.mileageMetric}`}
                  variant='outlined'
                  classes={{
                    root: clsx(classes.fieldRoot, classes.setHeight),
                  }}
                  autoComplete='off'
                />
                <FormControlLabel
                  className={classes.radioStyle}
                  label={t(tKey('hide_mileage'))}
                  control={
                    <Switch
                      checked={selectedCar?.hideMileage}
                      onChange={(event, checked) => setSelectedCar({ ...selectedCar, hideMileage: checked, mileage })}
                    />
                  }
                  labelPlacement='start'
                />
              </Box>
            )}
          </ListItem>
        );
      })}
    </RadioGroup>
  );
};
