import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { getTKey } from 'utils';
const tKey = getTKey('block_dialog');
interface BlockUserDialogProps {
  open: boolean;
  username: string;
  onClose: () => void;
  onBlock: () => void;
}

export const BlockUserDialog: ComponentType<BlockUserDialogProps> = ({ open, username, onClose, onBlock }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography variant='h6'>{t(tKey('title'), { username })}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='body1'>{t(tKey('description'), { username })}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onBlock}>
          {t('__common__block')}
        </Button>
        <Button color='info' onClick={onClose}>
          {t('__common__cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
