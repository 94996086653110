import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';
import { createReminderPayload } from './reminderPayloads';

const createReminder = async ({ carId, body }: createReminderPayload) => {
  const { data } = await api.post(`cars/${carId}/reminders`, body);
  return data;
};

export const useCreateReminderMutation = () => {
  return useMutation(createReminder);
};
