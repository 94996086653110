import { Button, Theme } from '@mui/material';
import clsx from 'clsx';
import { UserProfileCard } from 'features/users/components/UserProfileCard';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserProfile } from 'types';
import { useClasses } from 'utils/hooks/useClasses';
import { AdminCommentContent } from './AdminCommentContent';
import { AdminItemReports } from './AdminItemReports';
import { IItem, ReviewType } from './AdminItemTypes';
import { AdminStoryContent } from './AdminStoryContent';
import { AdminUserInfo } from './AdminUserInfo';
import { AdminUserWarnBanInfo } from './AdminUserWarnBanInfo';
import { VehicleItem } from 'features/users/components/VehicleItem';

const styles = ({ palette, spacing }: Theme) => ({
  itemBox: {
    flex: 1,
    background: '#202020',
    display: 'flex',
    flexDirection: 'row',
  },
  story: {
    flex: 1,
    borderRight: '1px solid #484848',
    display: 'flex',
    flexDirection: 'column',
  },
  details: {
    flex: 2,
  },
  userBox: {
    height: 74,
    borderBottom: '1px solid #484848',
    paddingTop: spacing(3),
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  translateButton: {
    color: palette.text.hint,
  },
  translateButtonOn: {
    color: palette.primary.main,
  },
  storyBox: {
    maxHeight: `calc(100vh - 202px - ${spacing(10)})`,
    overflowY: 'scroll',
  },
  profileBox: {
    padding: spacing(3),
  },
  detailsPartWrapper: {
    maxHeight: `calc(60vh - ${spacing(30)})`,
    overflowY: 'scroll',
  },
  carBox: {
    padding: spacing(1),
  },
});

interface AdminReviewItemType {
  item: IItem;
  detailsMode?: boolean;
}

export const AdminReviewItem: ComponentType<AdminReviewItemType> = ({ item, detailsMode, children }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const [showTranslate, setShowTranslate] = useState(false);
  const getReviewType = (): ReviewType => {
    if (item.username) {
      return 'user';
    } else if (item.model) {
      return 'car';
    }
    return 'story_comment';
  };
  const reviewType = getReviewType();
  const translate = () => {
    setShowTranslate(!showTranslate);
  };

  useEffect(() => {
    setShowTranslate(false);
  }, [item]);

  const transformUser = (item: IItem): UserProfile => {
    return {
      id: item.user_id,
      bio: item.bio || '',
      city: item.city || '',
      country: item.country || '',
      date_created: item.date_created,
      is_trusted: item.user.is_trusted,
      image: item.image || '',
      username: item.username || '',
      user_id: item.user_id,
    };
  };

  return (
    <div className={classes.itemBox}>
      <div className={classes.story}>
        {reviewType === 'story_comment' && (
          <>
            <div className={classes.userBox}>
              <AdminUserInfo user={item.user} />
              <Button
                className={clsx(classes.translateButton, { [classes.translateButtonOn]: showTranslate })}
                onClick={translate}
              >
                {t('admin_translate_button')}
              </Button>
            </div>

            <div className={classes.storyBox}>
              {item.type === 'story' && <AdminStoryContent story={item} showTranslate={showTranslate} />}
              {item.type === 'comment' && <AdminCommentContent comment={item} showTranslate={showTranslate} />}
            </div>
          </>
        )}
        {reviewType === 'user' && (
          <div className={classes.profileBox}>
            <UserProfileCard profile={transformUser(item)} admin={true} />
          </div>
        )}
        {reviewType === 'car' && (
          <>
            <div className={classes.userBox}>
              <AdminUserInfo user={item.user} />
              <Button
                className={clsx(classes.translateButton, { [classes.translateButtonOn]: showTranslate })}
                onClick={translate}
              >
                {t('admin_translate_button')}
              </Button>
            </div>

            <div className={classes.carBox}>
              <>
                <VehicleItem
                  vehicle={{
                    image: item.image ?? '',
                    make: item.make ?? '',
                    mileage: item.mileage ? Number(item.mileage) : 0,
                    mileage_metric: item.mileage_metric ?? '',
                    model: item.model ?? '',
                    year: Number(item.year),
                  }}
                />
              </>
            </div>
          </>
        )}
      </div>
      <div className={classes.details}>
        {detailsMode && <div>{children}</div>}
        <div className={classes.detailsPartWrapper}>
          <AdminUserWarnBanInfo user={item.user} warns={item.user_warnings || []} showTitle={true} />
        </div>
        <div className={classes.detailsPartWrapper}>
          <AdminItemReports reports={item.reports} />
        </div>
      </div>
    </div>
  );
};
