import { CommentsResponse, StoryPayload } from 'features/stories-feed/StoriesTypes';
import { useInfiniteQuery } from 'react-query';
import { axiosApiInstance as api, isLoggedIn } from 'utils';

export const getStoryCommentsRecent = async (
  { carId, storyId }: StoryPayload,
  page: number
): Promise<CommentsResponse> => {
  const isAuth = isLoggedIn();
  if (isAuth) {
    const { data } = await api.get(`cars/${carId}/stories/${storyId}/comments/recent`, { params: { page } });
    return data;
  } else {
    return { current_page: '0', per_page: '10', comments: [] };
  }
};

export const useStoryCommentsRecentQuery = ({ carId, storyId }: StoryPayload) => {
  return useInfiniteQuery(
    ['useStoryCommentsRecent', carId, storyId],
    ({ pageParam: page = 1 }) => getStoryCommentsRecent({ carId, storyId }, page),
    {
      cacheTime: 0,
      getNextPageParam(lastPage, allPages) {
        return lastPage.comments.length > 0 ? lastPage.current_page + 1 : undefined;
      },
    }
  );
};
