import { ArrowBack, ArrowForward} from '@mui/icons-material'
import { ComponentType, useState } from 'react';
import { IStoryImage } from './AdminItemTypes';
import { Dialog, DialogContent, Theme } from '@mui/material';
import { useClasses } from 'utils/hooks/useClasses';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import clsx from 'clsx';

interface AdminStoryContentImagesType{
    images: Array<IStoryImage>
}

const stylesImage = ({palette, spacing}: Theme) => ({
    carouselGallery: {
        '& .control-dots  .dot': {
            background: palette.primary.main
        }
    },
    image: {
        width: '100%',
        marginTop: spacing(1.5),
        cursor: 'zoom-in',
        '& img': {
            maxWidth: '100%',
            maxHeight: 300,
            width: 'unset !important',
        }
    },
    galleryButton: {
        width: 28,
        height: 28,
        borderRadius: 14,
        background: 'rgba(255, 255, 255, 0.3)',
        position: 'absolute',
        top: '50%',
        zIndex: 1,
        transition: 'background-color 200ms',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiSvgIcon-root': {
            width: 20,
            height: 20,
            color: 'rgba(0, 0, 0, 0.64)',
        },
        opacity: 0,
    },
    allowPress: {
        opacity: 1,
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.5)',
            
            
        }
    },
    prevArrowButton:{
        left: 16,
    },
    nextArrowButton: {
        right: 16,
    },
    zoomIn: {
        cursor: 'zoom-in',
        maxHeight: 'calc(100vh - 64px)',
        maxWeight: 'calc(100wv - 64px)',
    },
    zoomOut: {
        cursor: 'zoom-out',
    },
    header: {
        margin: spacing(2),
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    imageContent: {
        padding: 0
    }
})


export const AdminStoryContentImages: ComponentType<AdminStoryContentImagesType> = ({
    images
})=>
{
    const classes = useClasses(stylesImage);


    const [fullImage, setFullImage] = useState("");
    const [showFullImage, setShowFullImage] = useState<boolean>(false);
    const [zoomIn, setZoomIn] = useState<boolean>(false);
    const onOpenFullImageDialog = (image: string) => {
        setZoomIn(false);
        setFullImage(image);
        setShowFullImage(true);
    }

    const onCloseFullImageDialog = () => {
        setFullImage("");
        setShowFullImage(false);
    }


    return (
        <>
            <Carousel 
                autoPlay={false} 
                showThumbs={false} 
                showStatus={false}
                className={classes.carouselGallery}
                showIndicators={images.length > 1}
                renderArrowPrev={(clickHandler, hasPrev, labelPrev) => (
                    <div className={clsx(classes.prevArrowButton, classes.galleryButton, {[classes.allowPress]: hasPrev })} onClick={clickHandler}>
                        <ArrowBack/>
                    </div>
                  )}
                  renderArrowNext={(clickHandler, hasNext, labelNext) => (
                    <div className={clsx(classes.nextArrowButton, classes.galleryButton, {[classes.allowPress]: hasNext }) } onClick={clickHandler}>
                        <ArrowForward/>
                    </div>
                 )}
            >
            {images.map((image) => {
                return (
                    <div  key={image.id} className={classes.image} onClick={()=>onOpenFullImageDialog(image.url)}>
                        <img src={image.url} alt=''/>
                    </div>
                )
            })}
            </Carousel>
            <Dialog open={showFullImage} maxWidth='xl' onClose={onCloseFullImageDialog}>
                <DialogContent className={classes.imageContent}>
                    <img src={fullImage} alt='' onClick={()=>setZoomIn(!zoomIn)} className={zoomIn ?  classes.zoomOut : classes.zoomIn }/>
                </DialogContent>
            </Dialog>
        </>
    )
}