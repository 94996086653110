import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import { axiosApiInstance as api } from 'utils';

export type assistantMessagesResponse = {
  current_page: number;
  per_page: number;
  messages: any[];
};

const getAssistantHistory = async (page: number) => {
  const { data } = await api.get(`assistant`, { params: { page } });
  return data;
};

export const useAssistantGetHistoryQuery = (page: number): UseInfiniteQueryResult<assistantMessagesResponse> => {
  return useInfiniteQuery(['assistantHistory', page], ({ pageParam: page = 1 }) => getAssistantHistory(page), {
    cacheTime: 0,
    getNextPageParam(lastPage, allPages) {
      const nextPage = lastPage.messages.length === lastPage.per_page ? lastPage.current_page + 1 : undefined;
      return nextPage;
    },
  });
};
