import { Tab, Tabs, Theme } from '@mui/material';
import { AutoLoadContent, SpinFeedLoader } from 'components';
import { IComment } from 'features/stories-feed/StoriesTypes';
import { useCommentActions } from 'features/stories-feed/hooksComments/useCommentActions';
import { useCommentsPopular } from 'features/stories-feed/hooksComments/useCommentsPopularData';
import { useCommentsRecent } from 'features/stories-feed/hooksComments/useCommentsRecentData';
import { ComponentType, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClasses } from 'utils/hooks/useClasses';
import { CommentInput } from './CommentInput';
import { CommentsList } from './CommentsList';
import { Suggestions } from './SuggestionsContext';
import { useLocation } from 'react-router-dom';

const styles = ({ breakpoints, spacing, palette }: Theme) => ({
  divider: {
    border: '1px solid #484848',
    margin: spacing(2, 0, 1),
  },
  seeAllComments: {
    textAlign: 'center',
    marginBottom: spacing(4),
  },
  tabs: {
    width: '100%',
    zIndex: 1,
    padding: spacing(2, 0),
    '& .MuiTab-root': {
      color: palette.text.hint,
      fontSize: 14,
      fontWeight: 600,
      padding: spacing(1.75, 1.5),
      letterSpacing: '1.25px',
      lineHeight: '16px',
      width: '50%',
    },
    '& .Mui-selected': {
      color: palette.text.primary,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#6200EE',
    },
  },
  tabPanel: {
    padding: spacing(2, 0, 0),
  },
  noComments: {
    textAlign: 'center',
  },
  guestComments: {
    paddingTop: spacing(2),
  },
});

interface CommentsStoryDetailsType {
  carId: string;
  storyId: string;
  comments: IComment[];
  commentCount: number;
  authorized: boolean;
  story_creator_id: string;
}

export const CommentsStoryDetails: ComponentType<CommentsStoryDetailsType> = ({
  carId,
  storyId,
  comments,
  commentCount,
  authorized,
  story_creator_id
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const { add, update } = useCommentActions();
  const [addedComments, setAddedComments] = useState<IComment[]>([]);
  const [userMentionSuggestions, setUserMentionSuggestions] = useState<any[]>([]);
  const [noComments, setNoComments] = useState<boolean>(commentCount === 0);
  const ref = useRef(null);
  const location = useLocation();
  const [tab, setTab] = useState(0);
  const [commentTrigger, setCommentTrigger] = useState(1);

  const {
    recentComments,
    fetchNextPageRecentComments,
    isFetchingNextPageRecentComments,
    isLoadingRecentComments,
    refetchRecentComments,
    hasNextPageRecentComments,
  } = useCommentsRecent(carId, storyId);

  const {
    popularComments,
    fetchNextPagePopularComments,
    isFetchingNextPagePopularComments,
    isLoadingPopularComments,
    refetchPopularComments,
    hasNextPagePopularComments,
  } = useCommentsPopular(carId, storyId);

  const onSend = async (comment: string, reply?: string) => {
    try {
      const newComment = await add({ body: { text: comment, replies_to_id: reply }, carId, storyId });
      if (newComment?.id && !newComment?.replies_to_id) {
        setAddedComments([newComment, ...addedComments]);
      }
      return newComment;
    } catch (error: any) {
      console.log(error);
    }
  };

  const onEdit = async (comment: string, id: string) => {
    const editComment = await update({ body: { text: comment }, carId, storyId, commentId: id });
    return editComment;
  };

  useEffect(() => {
    if (authorized) {
      refetchPopularComments();
      if (location.hash === '#comments') {
        setTimeout(() => {
          //@ts-ignore
          ref.current?.scrollIntoView({ behavior: 'smooth' });
        }, 200);
      }
    }

    const onDeleteCommentHandle = async () => {
      await refetchPopularComments();
      await refetchRecentComments();
      setAddedComments([]);
      setCommentTrigger((prev) => prev + 1);
    };

    window.addEventListener('deleteComment', onDeleteCommentHandle);

    return () => {
      window.removeEventListener('deleteComment', onDeleteCommentHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTab = (event: React.SyntheticEvent, newValue: number) => {
    setAddedComments([]);
    if (newValue === 0) {
      refetchPopularComments();
    } else if (newValue === 1) {
      refetchRecentComments();
    }
    setTab(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `comments-tab-${index}`,
      'aria-controls': `comments-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    let set = new Set();
    popularComments?.pages.map((page, i) => {
      return page.comments.map((comment) => {
        const mention = JSON.stringify({ name: comment.user.username, id: comment.user.id });
        set.add(mention);
        return true;
      });
    });
    const array: any[] = [];
    set.forEach((value) => {
      array.push(JSON.parse(value as string));
    });
    setUserMentionSuggestions(array);
  }, [popularComments]);

  useEffect(() => {
    setNoComments(
      addedComments.filter((comment) => !comment.is_deleted).length === 0 &&
        popularComments?.pages[0].comments.length === 0 &&
        recentComments?.pages[0].comments.length === 0
    );
  }, [addedComments, popularComments, recentComments, commentTrigger]);

  return (
    <>
      <hr className={classes.divider} />
      <Suggestions.Provider value={userMentionSuggestions}>
        <CommentInput send={onSend} defaultSuggestions={userMentionSuggestions} authorized={authorized} edit={onEdit} story_creator_id={story_creator_id} />
        {!authorized && (
          <div className={classes.guestComments}>
            <CommentsList comments={comments} carId={carId} storyId={storyId} authorized={authorized} />
          </div>
        )}
        {authorized && (
          <Tabs value={tab} onChange={handleTab} className={classes.tabs}>
            <Tab {...a11yProps(0)} label={t('comments_tab_title_popular')}></Tab>
            <Tab {...a11yProps(1)} label={t('comments_tab_title_recent')}></Tab>
          </Tabs>
        )}
        {tab === 0 && (
          <div className={classes.tabPanel}>
            <CommentsList comments={addedComments} carId={carId} storyId={storyId} authorized={authorized} />
            {popularComments?.pages.map((page, i) => {
              return (
                <CommentsList
                  key={i}
                  comments={page.comments}
                  carId={carId}
                  storyId={storyId}
                  authorized={authorized}
                  list='popular'
                />
              );
            })}
            {isFetchingNextPagePopularComments && (
              <SpinFeedLoader loading={isFetchingNextPagePopularComments || isLoadingPopularComments} />
            )}
            {hasNextPagePopularComments && (
              <AutoLoadContent
                onScrollToBottom={fetchNextPagePopularComments}
                loading={isFetchingNextPagePopularComments}
              />
            )}
          </div>
        )}
        {tab === 1 && (
          <div className={classes.tabPanel}>
            <CommentsList comments={addedComments} carId={carId} storyId={storyId} authorized={authorized} />
            {recentComments?.pages.map((page, i) => {
              return (
                <CommentsList
                  key={i}
                  comments={page.comments}
                  carId={carId}
                  storyId={storyId}
                  authorized={authorized}
                  list='recent'
                />
              );
            })}
            {isFetchingNextPageRecentComments && (
              <SpinFeedLoader loading={isFetchingNextPageRecentComments || isLoadingRecentComments} />
            )}
            {hasNextPageRecentComments && (
              <AutoLoadContent
                onScrollToBottom={fetchNextPageRecentComments}
                loading={isFetchingNextPageRecentComments}
              />
            )}
          </div>
        )}
        {noComments && <div className={classes.noComments}>{t('comments_no_comments')} 🤷🏼‍♂️</div>}
        <div ref={ref}></div>
      </Suggestions.Provider>
    </>
  );
};
