import { useMutation } from 'react-query';

import { axiosApiInstance as api } from 'utils';

export type TimezoneResponse = {
  timezone: string;
  error?: string
};

type TimezoneRequest = {
  timezone: string;
};

const changeTimezone = async (body: TimezoneRequest): Promise<Partial<TimezoneResponse>> => {
  const { data } = await api.put(`/users/timezone`, {"timezone": body.timezone});
  return data.timezone;
};

export const useChangeTimezoneMutation = () => {
  return useMutation(changeTimezone);
};
