import { Close, DocumentScanner, KeyboardVoice } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ComponentType, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import patronIcon from 'assets/Patron-Icon.svg';
import { ProfileContext } from 'store/profile/store';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { StyledButton } from 'components/StyledButton/StyledButton';
import { Link } from 'react-router-dom';
import { theme } from 'theme';
const tKey = getTKey('upgrade_dialog');

const styles = ({ palette, breakpoints, spacing }: Theme) => ({
  dialog: {
    paddingTop: 'env(safe-area-inset-top)',
    paddingBottom: 'env(safe-area-inset-bottom)',
    '& .MuiPaper-root': {
      background: '#121212',
    },
    [breakpoints.up('md')]: {
      '& .MuiPaper-root': {
        background: '#383838',
        minWidth: 762,
      },
    },
  },
  content: {
    [breakpoints.down('md')]: {
      maxWidth: 550,
      margin: '0 auto',
    },
  },
  whiteCircle: {
    width: 80,
    height: 80,
    background: 'rgba(255, 255, 255, 1)',
    borderRadius: 40,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    borderColor: '#313131',
    margin: spacing(2, 0),
  },
  benefits: {
    border: '1px solid rgba(255, 199, 0, 0.2)',
    borderRadius: 3,
    background: 'rgba(255, 199, 0, 0.05)',
    padding: spacing(2, 1.5),
    margin: spacing(2, 0, 1),
  },

  benefitPoints: {
    marginTop: spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  },
  benefitBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    [breakpoints.up('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      width: '33%',
    },
  },
  benefitIcon: {
    width: 40,
    height: 40,
    background: '#FFCB40',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  benefitText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',

    [breakpoints.down('md')]: {
      marginLeft: spacing(2),
      alignItems: 'start',
    },
  },
  helpLink: {
    textDecoration: 'none',
  },
});

interface UpgradeDialogType {
  open: boolean;
  onClose: () => void;
}

export const UpgradeDialog: ComponentType<UpgradeDialogType> = ({ open, onClose }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const { state } = useContext(ProfileContext);
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Dialog open={open} fullScreen={mobile} className={classes.dialog}>
      <DialogTitle display='flex' justifyContent='end'>
        <IconButton onClick={onClose} sx={{ color: '#A0A0A0' }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box display='flex' justifyContent='center' margin={2}>
          <Box className={classes.whiteCircle}>
            <Typography variant='h2'>👋</Typography>
          </Box>
        </Box>
        <Box display='flex' justifyContent='center' flexDirection='column' textAlign='center' alignItems='center'>
          <Typography
            variant='body1'
            sx={{ textTransform: 'uppercase', fontWeight: 600, letterSpacing: 1.25 }}
            paddingBottom={2}
          >
            {t(tKey('title'), { user_name: state.username })}
          </Typography>
          <Typography variant='body2' sx={{ maxWidth: { md: 400 }, fontSize: 12 }}>
            {t(tKey('description'))}
          </Typography>
        </Box>

        <Box className={classes.benefits}>
          <Typography
            variant='body2'
            sx={{ textTransform: 'uppercase', letterSpacing: 1.5, fontSize: 10 }}
            display='flex'
            justifyContent={mobile ? 'left' : 'center'}
          >
            {t(tKey('patrons_have'))}
          </Typography>
          <Box className={classes.benefitPoints}>
            <Box className={classes.benefitBlock}>
              <Box className={classes.benefitIcon}>
                <KeyboardVoice sx={{ color: '#000000' }} />
              </Box>
              <Box className={classes.benefitText}>
                <Typography variant='body1' sx={{ fontSize: 14 }}>
                  {t(tKey('voice_to_record'))}
                </Typography>
                <Typography variant='body2'>{t(tKey('5_per_week'))}</Typography>
              </Box>
            </Box>
            <Divider className={classes.divider} sx={{ opacity: { md: 0 } }} />
            <Box className={classes.benefitBlock}>
              <Box className={classes.benefitIcon}>
                <DocumentScanner sx={{ color: '#000000' }} />
              </Box>
              <Box className={classes.benefitText}>
                <Typography variant='body1' sx={{ fontSize: 14 }}>
                  {t(tKey('invoice_to_record'))}
                </Typography>
                <Typography variant='body2'>{t(tKey('5_per_week'))}</Typography>
              </Box>
            </Box>
            <Divider className={classes.divider} sx={{ opacity: { md: 0 } }} />
            <Box className={classes.benefitBlock}>
              <Box sx={{ width: 40, height: 40 }}>
                <img src={patronIcon} alt='Logo' width={40} height={40}></img>
              </Box>
              <Box className={classes.benefitText}>
                <Typography variant='body1' sx={{ fontSize: 14 }}>
                  {t(tKey('profile_badge'))}
                </Typography>
                <Typography variant='body2'>{t(tKey('profile_badge_description'))}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* <Box display='flex' justifyContent='center' alignItems='center'>
          <Typography
            variant='body2'
            sx={{ color: 'rgba(166, 166, 166, 1)', maxWidth: { md: 400 }, fontSize: 12 }}
            textAlign='center'
          >
            {t(tKey('system_limited'))}
          </Typography>
        </Box> */}
        <Box display='flex' justifyContent='center' margin={2}>
          <Link to={{ pathname: 'https://buymeacoffee.com/driverbook' }} target='_blank' className={classes.helpLink}>
            <StyledButton label={t(tKey('help_button'))} />
          </Link>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
