import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { Car, Record } from 'types';
import {
  aggregateExpensesByMonth,
  aggregateMileageByMonth,
  aggregateSpendingByCategory,
  aggregateSpendingByFrequency,
  calcAverageMonthlyExpenses,
  calcAverageMonthlyMileage,
  getAllCategories,
  getAllYears,
  getFilteredRecordsByCar,
  getTotalExpenses,
  getTotalMileage,
} from 'utils';

import { useLocationQuery } from 'lib';
import { useNavigate, useParams } from 'react-router-dom';
import { usePublicRecordsQuery } from 'services';
import { BoardFilters, Stats } from './components';

interface PublicDashboardPageData {
  car: Car | undefined;
  records: Record[] | undefined;
  carLoading: boolean;
  showRecordsCharts: boolean;
  allYears: number[];
  allCategories: string[];
  filters: BoardFilters;
  handleFiltersChange: (filters: Partial<BoardFilters>) => void;
  expensesByMonth: number[];
  mileageByMonth: number[];
  averageMonthlyExpenses: number;
  averageMonthlyMileage: number;
  totalExpenses: number;
  totalMileage: number;
  spendingByCategory: Stats[];
  spendingByFrequency: Stats[];
}

type usePublicDashboardPageType = () => PublicDashboardPageData;
export const usePublicDashboardPage: usePublicDashboardPageType = () => {
  const { identifier } = useParams<{ identifier: string }>() as { identifier: string };

  const { data, isLoading: carLoading } = usePublicRecordsQuery(identifier);

  const [showRecordsCharts, setShowRecordsCharts] = useState<boolean>(true);
  const params = useLocationQuery();
  const navigate = useNavigate();
  const date = dayjs();
  const { identifier: carId } = useParams<{ identifier: string }>() as { identifier: string };
  const year = Number(params.get('year')) || date.year();
  const category = params.get('category') || 'all';

  const [filters, setFilters] = useState<BoardFilters>(() => {
    return {
      carId,
      year,
      category,
    };
  });
  const [filteredRecords, setFilteredRecords] = useState<Record[]>([]);
  const [filteredRecordsForStats, setFilteredRecordsForStats] = useState<Record[]>([]);

  const flattenRecords = data?.records?.flat();
  const allYears = getAllYears(flattenRecords);
  const allCategories = getAllCategories(flattenRecords);

  const expensesByMonth = aggregateExpensesByMonth(filteredRecords);
  const mileageByMonth = aggregateMileageByMonth(filteredRecords);
  const averageMonthlyExpenses = calcAverageMonthlyExpenses(filteredRecords, year);
  const averageMonthlyMileage = calcAverageMonthlyMileage(filteredRecords);
  const totalExpenses = getTotalExpenses(filteredRecords);
  const totalMileage = getTotalMileage(filteredRecords);
  const spendingByCategory = aggregateSpendingByCategory(filteredRecordsForStats);
  const spendingByFrequency = aggregateSpendingByFrequency(filteredRecordsForStats);

  useEffect(() => {
    setFilters({
      carId,
      year,
      category,
    });
  }, [carId, year, category]);

  useEffect(() => {
    if (!carLoading && data?.records.length) {
      const { filteredRecords: recordsAfterFilter, filteredRecordsForStats: statsRecords } = getFilteredRecordsByCar(
        filters,
        data.records
      );

      if (recordsAfterFilter.length > 0) {
        setFilteredRecords(recordsAfterFilter);
        setFilteredRecordsForStats(statsRecords);
        setShowRecordsCharts(true);
      } else {
        setShowRecordsCharts(false);
      }
    }

    if (!carLoading && data?.records.length === 0) {
      setShowRecordsCharts(false);
    }
  }, [data, carLoading, filters]);

  const handleFiltersChange = (filter: Partial<BoardFilters>) => {
    navigate(
      `/book/dashboard/${carId}?year=${filter.year ?? filters.year}&category=${filter.category ?? filters.category}`
    );
  };

  return {
    car: data?.car,
    records: data?.records,
    carLoading,
    showRecordsCharts,
    allYears,
    allCategories,
    filters,
    handleFiltersChange,
    expensesByMonth,
    mileageByMonth,
    averageMonthlyExpenses,
    averageMonthlyMileage,
    totalExpenses,
    totalMileage,
    spendingByCategory,
    spendingByFrequency,
  };
};
