import { ArrowBack, Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { ComponentType, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSafeUrlQuery } from 'services/data';
import { theme } from 'theme';
import { useClasses } from 'utils/hooks/useClasses';
import successSign from './../../../../assets/external-link/success.png';
import warnTriangle from './../../../../assets/external-link/warn-triangle.svg';
import warnSign from './../../../../assets/external-link/warn-sign.png';
import { getTKey } from 'utils';
import Lottie from 'lottie-react';
import animationData from '../../../../components/SpinFeedLoader/loader.json';
const tKey = getTKey('external_link_dialog');
const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      background: '#202020',
      [breakpoints.up('sm')]: {
        background: '#2a2a2a',
        width: 390,
        minHeight: 360,
      },
    },
  },

  backBtn: {
    color: palette.text.hint,
    width: 44,
    height: 44,
    [breakpoints.down('sm')]: {
      paddingRight: spacing(3),
    },
  },
  mobileTitleBlock: {
    width: 'unset',
  },
  content: {
    paddingTop: spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    padding: spacing(2, 0),
    textAlign: 'center',
  },
  resultSuccessCircleBackground: {
    width: 80,
    height: 80,
    borderRadius: 64,
    background: '#63D7C5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  resultWarning: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  grayCircleLoading: {
    width: 80,
    height: 80,
    borderRadius: 64,
    background: 'rgba(0, 0, 0, 0.2)',
    position: 'relative',
    '& svg': {
      scale: '3',
      position: 'absolute',
      top: -30,
    },
  },
  warnSign: {
    position: 'absolute',
    top: 20,
  },
});

interface ExternalLinkDialogType {
  onClose: () => void;
  url: string;
  storyId: string;
}

export const ExternalLinkDialog: ComponentType<ExternalLinkDialogType> = ({ onClose, storyId, url }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const { data, isLoading, error } = useSafeUrlQuery({ storyId, urlToCheck: url });
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const lottieRef = useRef<any>(null);

  const openLink = () => {
    window.open(url, '_blank', 'noreferrer');
    onClose();
  };

  return (
    <Dialog className={classes.dialog} open={true} fullScreen={fullScreen} onClose={onClose}>
      <DialogTitle>
        {fullScreen ? (
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item container direction='row' alignItems='center' className={classes.mobileTitleBlock}>
              <IconButton onClick={onClose} className={classes.backBtn}>
                <ArrowBack />
              </IconButton>
              <div>{t(tKey('title'))}</div>
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent='space-between' alignItems='center'>
            <div>{t(tKey('title'))}</div>
            <IconButton onClick={onClose} className={classes.backBtn}>
              <Close />
            </IconButton>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {isLoading && (
          <>
            <div className={classes.grayCircleLoading}>
              <Lottie animationData={animationData} lottieRef={lottieRef} />
            </div>
            <div className={classes.text}>{t(tKey('loading_text'))}</div>
          </>
        )}
        {data?.message === 'safe_url' && (
          <>
            <div className={classes.resultSuccessCircleBackground}>
              <img src={successSign} alt='safe' />
            </div>
            <div className={classes.text}> {t(tKey('safe_text'))}</div>
          </>
        )}

        {error && (
          <>
            <div className={classes.resultWarning}>
              <img src={warnTriangle} alt='danger' />
              <img src={warnSign} alt='danger' className={classes.warnSign} />
            </div>
            <div className={classes.text}>{t(tKey('danger_text'))}</div>
          </>
        )}
      </DialogContent>

      <DialogActions>
        {isLoading && <Button onClick={onClose}>{t('__common__ok')}</Button>}
        {!isLoading && <Button onClick={onClose}>{t('__common__cancel')}</Button>}
        {data?.message === 'safe_url' && (
          <>
            <Button onClick={openLink} color='primary'>
              {t('__common__open')}
            </Button>
          </>
        )}
        {error && (
          <>
            <Button onClick={openLink} color='primary'>
              {t(tKey('open_anyway'))}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
