import { useEffect, useState } from 'react';
import { useCarQuery, useRecordsByCarQuery } from 'services';

import { Car, Record } from 'types';
import { getAllCategories, getAllMonths, getAllYears, getFilteredRecordsForStories } from 'utils';

interface CarRecordsData {
  allYears: number[];
  allCategories: string[];
  allMonths: number[];
  filteredRecords: Record[];
  carData: Car | undefined;
}
export type Filters = {
  year: number;
  category: string;
  type: string;
  month: string;
};
type useCarRecordsType = (data: { carId: string } & Filters) => CarRecordsData;
export const useCarRecords: useCarRecordsType = ({ carId, category, month, type, year }) => {
  const [filteredRecords, setFilteredRecords] = useState<Record[]>([]);
  const { data: records, isLoading: recordsLoading } = useRecordsByCarQuery(carId ?? '', 'records');
  const { data: carData } = useCarQuery(carId);

  const flattenRecords = records?.flat();
  const allYears = getAllYears(flattenRecords);
  const allCategories = getAllCategories(flattenRecords);
  const allMonths = getAllMonths(flattenRecords);

  const [filters, setFilters] = useState<Filters>(() => {
    return {
      category,
      year,
      month,
      type,
    };
  });

  useEffect(() => {
    setFilters({ category, year, month, type });
  }, [category, carId, month, type, year]);

  useEffect(() => {
    if (!recordsLoading && records && records.length) {
      const { filteredRecords: recordsAfterFilter } = getFilteredRecordsForStories(filters, records);

      if (recordsAfterFilter.length > 0) {
        setFilteredRecords(recordsAfterFilter);
      }
    }
  }, [records, recordsLoading, filters]);

  return {
    allCategories,
    allMonths,
    allYears,
    filteredRecords,
    carData,
  };
};
