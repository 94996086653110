import { toast } from 'react-toastify';

export function handleErrorGoogleAuth(error: any) {
  // const errorMessage = error.message;
  const errorCode = error.code;
  switch (errorCode) {
    case 'auth/operation-not-allowed':
      toast('Email/password accounts are not enabled.');
      break;
    case 'auth/operation-not-supported-in-this-environment':
      toast('HTTP protocol is not supported. Please use HTTPS.');
      break;
    case 'auth/popup-blocked':
      toast('Popup has been blocked by the browser. Please allow popups for this website.');
      break;
    case 'auth/popup-closed-by-user':
      break;
    case 'auth/cancelled-popup-request':
      break;
    default:
      // toast(errorMessage);
      break;
  }
}
