import { Box, List } from '@mui/material';
import { ComponentType } from 'react';
import { useParams } from 'react-router-dom';
import { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { PageLoader } from 'components';
import { useCarsQuery } from 'services';

import { useClasses } from 'utils/hooks/useClasses';
import { FeatureCarItem } from './FeatureCarItem';

const styles = {
  slide: {
    width: '88%',
  },
};

interface FeatureCarsListProps {
  entityPath: 'records' | 'reminders';
}

export const FeatureCarsList: ComponentType<FeatureCarsListProps> = ({ entityPath }) => {
  const classes = useClasses(styles);
  const { carId } = useParams<{ carId: string }>();
  const { data, isLoading } = useCarsQuery();
  const chosenCarIndex = data?.findIndex((item) => item.id === carId);

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      {data && data.length && (
        <>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            {data.length === 1 ? (
              data.map((car) => (
                <FeatureCarItem key={car.id} entityPath={entityPath} car={car} isActive={carId === car.id} />
              ))
            ) : (
              <Swiper
                slidesOffsetAfter={0}
                slidesOffsetBefore={0}
                slidesPerView={'auto'}
                spaceBetween={24}
                freeMode={true}
                modules={[FreeMode]}
                initialSlide={chosenCarIndex}
              >
                {data.map((car) => (
                  <SwiperSlide key={car.id} className={classes.slide}>
                    <FeatureCarItem entityPath={entityPath} car={car} isActive={carId === car.id} />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </Box>

          <List sx={{ display: { md: 'block', xs: 'none' } }}>
            {data.map((car) => (
              <FeatureCarItem key={car.id} entityPath={entityPath} car={car} isActive={carId === car.id} />
            ))}
          </List>
        </>
      )}
    </>
  );
};
