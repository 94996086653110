import { useStoryQuery } from 'services/stories';
import { IStory } from '../StoriesTypes';

type useStoryDataType = (
  carId: string,
  storyId: string,
  language?: string,
) => {
  data: IStory | undefined;
  isLoading: boolean;
  error: any;
};

export const useStoryData: useStoryDataType = (carId, storyId, language) => {
  const { data, isLoading, error } = useStoryQuery({ carId, storyId, language });

  return {
    data,
    isLoading,
    error,
  };
};
