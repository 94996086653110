import { useMutation } from 'react-query';

import { axiosApiInstance } from 'utils';

import { storyDraftMutationOptions } from '../storyDraftMutationOptions';

export type UpdateStoryDraftPayload = { carId: string; storyId: string; elements: any };

const updateStoryDraft = async ({ carId, storyId, elements }: UpdateStoryDraftPayload): Promise<any> => {
  const { data } = await axiosApiInstance.put(`cars/${carId}/stories/${storyId}/draft`, elements);
  return data;
};

export const useUpdateStoryDraft = () => {
  return useMutation(updateStoryDraft, storyDraftMutationOptions);
};
