import { ComponentType, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const FormErrorsCleaner: ComponentType = () => {
  const { i18n } = useTranslation();
  const { clearErrors } = useFormContext();

  useEffect(() => {
    i18n.on('languageChanged', () => {
      clearErrors();
    });
  }, [i18n]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
