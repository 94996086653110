import { responseCommentsType } from "../components/AdminDashboardTypes";
import { filtersType, paginationType } from "./useAdminFilteredStories";
import { useCommentsQuery } from "services/admin/hooks/useCommentsQuery";


type AdminFilteredCommentsType = (
    filters: filtersType,
    pagination: paginationType
) => {
    data: responseCommentsType;
    isFetching: boolean;
    refetch: () => void
}

export const AdminFilteredComments: AdminFilteredCommentsType = (
    filters,
    pagination
)=>{
    const {data, isFetching, refetch } = useCommentsQuery({...filters, ...pagination}, {});

    return {
        data,
        isFetching,
        refetch
    }
}