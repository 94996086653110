import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { Box, CircularProgress, FormHelperText, FormLabel, Grid, Theme, Typography } from '@mui/material';
import {
  Form,
  FormRadio,
  InnerMobileHeader,
  PageLoader,
  SearchSelect,
  StyledButton,
  TextInputControlled,
} from 'components';
import { ProfileImageUploader } from 'components/ProfileImageUploader/ProfileImageUploader';
import { getUserLocation } from 'lib/useUserLocation';
import { ComponentType, useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { ImageType } from 'react-images-uploading';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUserProfile } from 'services';
import { useLocations } from 'services/profile/v2/useLocations';
import { useUserProfileInfoMutation } from 'services/profile/v2/useProfileInfoMutation';
import { ProfileContext as Context } from 'store/profile/store';
import { ProfileVehiclesDisplayMode, UserProfile } from 'types';
import { compareFiles, getTKey, yupStringValidator } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import * as yup from 'yup';
import { useSearchInCountries } from '../useUserLocationData';

const tKey = getTKey('profile_settings');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  imageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  current: {
    display: 'flex',
    gap: 15,
    color: palette.primary.main,
    cursor: 'pointer',
  },
  note: {
    color: palette.info,
    fontSize: 12,
    marginLeft: 10,
  },
  formGroup: {
    padding: 0,
    width: '100%',
  },
  radioStyle: {
    justifyContent: 'space-between',
    margin: spacing(2, 0),
    width: '100%',
  },
  vehicleFormControl: {
    marginTop: spacing(4),
    '& .MuiFormLabel-root': {
      textTransform: 'uppercase',
    },
    '& .MuiFormControlLabel-root': {
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.3px',
    },
  },
  subHeading: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 10,
    textTransform: 'uppercase',
  },
  locationLabel: {
    marginTop: spacing(1.5),
  },
  saveButtonWrap: {
    position: 'relative',
    height: 0,
    [breakpoints.up('md')]: {
      top: -210,
    },
    [breakpoints.down('md')]: {
      top: -220,
    },
  },
  saveButton: {
    [breakpoints.down('md')]: {
      padding: spacing(1, 2.75) + ' !important',
    },
  },
});

const createDefaultValues = (profile: UserProfile | undefined) => {
  return {
    username: profile?.username,
    bio: profile?.bio || '',
    country: profile?.country ? { label: profile?.country, value: profile?.country } : null,
    city: profile?.city ? { label: profile?.city, value: profile?.city } : null,
    image: profile?.image ?? null,
    profile_vehicles_display_mode: profile?.profile_vehicles_display_mode,
  };
};

const LocationFields: ComponentType<{
  locations: string[];
  profile: UserProfile | undefined;
  fetchingCurrentLocation: boolean;
  setFetchingCurrentLocation: (value: boolean) => void;
}> = ({ locations, profile, fetchingCurrentLocation, setFetchingCurrentLocation }) => {
  const chosenCountry = useWatch({ name: 'country' });
  const { setValue } = useFormContext();
  const [country, setCountry] = useState(chosenCountry);
  const { cities } = useSearchInCountries(country);
  const { t } = useTranslation();
  useEffect(() => {
    setCountry(chosenCountry);
    if (chosenCountry?.value !== profile?.country && !fetchingCurrentLocation) {
      setValue('city', null);
    }
  }, [chosenCountry, profile?.country, setValue, setFetchingCurrentLocation, fetchingCurrentLocation]);

  return (
    <>
      <Grid item xs={12} md={6}>
        <SearchSelect
          name='country'
          options={locations?.map((i) => ({ label: i, value: i })) ?? []}
          placeholder='country'
          onChange={() => {
            setFetchingCurrentLocation(false);
          }}
          label={t('search_select_label_country')}
          labelBackground='#121212'
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SearchSelect
          disabled={chosenCountry === null}
          name='city'
          options={cities}
          placeholder='city'
          label={t('search_select_label_city')}
          labelBackground='#121212'
        />
      </Grid>
    </>
  );
};
const ProfileFormFields: ComponentType<{
  image: ImageType[];
  uploaderIsDirty: boolean;
  formError: string | undefined;
  usernameExists: boolean;
}> = ({ uploaderIsDirty, formError, usernameExists }) => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const bio = watch('bio');
  const { isDirty } = useFormState();
  const classes = useClasses(styles);
  const { data: locations, isLoading: locationsLoading } = useLocations();
  const { data: profile, isLoading } = useUserProfile();
  const [fetchingCurrentLocation, setFetchingCurrentLocation] = useState(false);

  const fillUserLocation = async () => {
    setFetchingCurrentLocation(true);
    getUserLocation()
      .then((userLocation) => {
        if (userLocation?.country && userLocation?.city) {
          setValue('country', { label: userLocation?.country, value: userLocation?.country }, { shouldDirty: true });
          setValue('city', { label: userLocation?.city, value: userLocation?.city }, { shouldDirty: true });
        } else {
          toast(t(tKey('try_again')));
        }
      })
      .catch((error) => {
        toast(t(tKey('try_again')));
      });
  };

  const vehiclesDisplayMode: { id: number; value: ProfileVehiclesDisplayMode; label: string }[] = [
    { id: 1, value: 'hide', label: t(tKey('vehicles_display_mode_hide')) },
    { id: 2, value: 'show', label: t(tKey('vehicles_display_mode_show')) },
    { id: 3, value: 'show_vehicles_from_stories', label: t(tKey('vehicles_display_mode_from_stories')) },
  ];

  const handleUserLocation = () => {
    if (Capacitor.isNativePlatform()) {
      // Request location permission
      Geolocation.requestPermissions().then((permissionResult) => {
        if (permissionResult.location === 'granted') {
          // Location permission granted, you can proceed with using Geolocation API
          fillUserLocation();
        } else {
          // Location permission denied, handle accordingly
        }
      });
    } else {
      fillUserLocation();
    }
  };
  return !locationsLoading && !isLoading ? (
    <>
      <Grid textAlign='right' mt={4} mb={4} className={classes.saveButtonWrap}>
        <StyledButton
          className={classes.saveButton}
          label={t(tKey('submit'))}
          type='submit'
          disabled={!isDirty && !uploaderIsDirty}
        />
      </Grid>
      <Grid container spacing={2.5}>
        <Grid item xs={12}>
          <TextInputControlled name='username' inputProps={{ maxLength: 30 }} label={t(tKey('username'))} />
          {usernameExists && (
            <FormHelperText margin='dense' error>
              {t('signin_username_exists_error')}
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <TextInputControlled inputProps={{ maxLength: 300 }} name='bio' label={t(tKey('bio'))} textarea />
          <Typography className={classes.note}>{bio?.length ?? 0}/300</Typography>
        </Grid>
        <Grid item xs={12} className={classes.locationLabel}>
          <FormLabel className={classes.subHeading}>{t(tKey('location_title'))}</FormLabel>
        </Grid>
        <LocationFields
          locations={locations ?? []}
          profile={profile}
          fetchingCurrentLocation={fetchingCurrentLocation}
          setFetchingCurrentLocation={setFetchingCurrentLocation}
        />
      </Grid>
      <Box onClick={handleUserLocation} className={classes.current}>
        <MyLocationIcon />
        <Typography>{t(tKey('current_location'))}</Typography>
      </Box>
      <Grid item xs={12} className={classes.vehicleFormControl}>
        <FormLabel className={classes.subHeading}>{t(tKey('vehicles_title'))}</FormLabel>
        <FormRadio
          name='profile_vehicles_display_mode'
          options={vehiclesDisplayMode}
          formControlLabelProps={{ labelPlacement: 'start', className: classes.radioStyle }}
          controlClass={classes.formGroup}
          defaultValue={profile?.profile_vehicles_display_mode}
        />
      </Grid>
      {formError && (
        <FormHelperText margin='dense' error>
          {formError}
        </FormHelperText>
      )}
    </>
  ) : (
    <div className={classes.imageContainer}>
      <CircularProgress />
    </div>
  );
};

export const ProfileSettings: ComponentType = () => {
  const { t } = useTranslation();
  const [shouldReset, setShouldReset] = useState<boolean>(false);
  const { state } = useContext(Context);
  const [usernameExists, setUsernameExists] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { data: profile, isLoading } = useUserProfile();
  const [uploaderIsDirty, setUploaderIsDirty] = useState(false);
  const [images, setImages] = useState<ImageType[]>(profile?.image ? [{ dataURL: profile?.image }] : []);
  const [formError, setFormError] = useState<string | undefined>('');
  const mutation = useUserProfileInfoMutation();
  const [defaultValues, setDefaultValues] = useState<any>();
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    //@ts-ignore
    gtag?.('event', 'visited_profile', { method: 'visited_profile' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profile) {
      setDefaultValues(createDefaultValues(profile));
    }
  }, [profile]);

  const validationSchema = useMemo(() => {
    return (t: TFunction): yup.AnyObjectSchema =>
      yup.object().shape({
        username: yupStringValidator(t)
          .min(3, t('__validation__minimum_three'))
          .matches(/^(?=.*[a-z].*)[a-z0-9_.-]+$/, { message: t('__validation__wrong_characters') }),
      });
  }, []);

  useEffect(() => {
    if (state.email) {
      setShouldReset(true);
    }
  }, [state]);

  useEffect(() => {
    setImages(profile?.image ? [{ dataURL: profile?.image }] : []);
  }, [profile]);

  const onImageChange = (image: ImageType[]) => {
    setUploaderIsDirty(true);
    setImages(image);
  };

  const submitProfileForm = async (values: any) => {
    setSaving(true);
    const fd = new FormData();
    const country = values.country?.value;
    const city = values.city?.value;
    fd.append('bio', values.bio ?? '');
    fd.append('username', values.username);
    fd.append('country', country ?? '');
    fd.append('city', city ?? '');
    fd.append('profile_vehicles_display_mode', values.profile_vehicles_display_mode ?? '');
    if (uploaderIsDirty) {
      if (images?.length && images[0]?.file) {
        const file = await compareFiles(profile?.image as string, images[0].file);
        fd.append('image', file, images[0].file.name);
      } else {
        fd.append('image', '');
      }
    } else {
      fd.append('old_image', 'True');
    }

    mutation.mutate(fd, {
      onSuccess(res) {
        if (res?.error) {
          if (res?.error === 'Conflict') {
            setUsernameExists(true);
          } else if (res?.error === 'profile_banned') {
            toast(t(tKey('profile_banned')));
          } else {
            setFormError(t('__common__error'));
          }
          setSaving(false);
        } else {
          setSaving(false);
          if (location?.state?.from === 'profileTab') {
            navigate(-1);
          } else {
            navigate('/settings/profile', { replace: true });
          }
          toast(t(tKey('success_message')));
        }
      },
    });
  };

  return (
    <>
      <InnerMobileHeader title={t(tKey('header'))} showBack />
      {!isLoading && (
        <Form
          schema={validationSchema(t)}
          defaultValues={defaultValues}
          shouldReset={shouldReset}
          onSubmit={submitProfileForm}
        >
          <ProfileImageUploader images={images} onChange={onImageChange} setIsDirty={setUploaderIsDirty} />
          <ProfileFormFields
            image={images}
            uploaderIsDirty={uploaderIsDirty}
            formError={formError}
            usernameExists={usernameExists}
          />
        </Form>
      )}
      {saving && <PageLoader />}
    </>
  );
};
