import { IStory, StoryPayload } from 'features/stories-feed/StoriesTypes';
import { useQuery } from 'react-query';
import { axiosApiInstance as api } from 'utils';

export const getStoryDetails = async ({ carId, storyId, language }: StoryPayload): Promise<IStory> => {
  if (language){
  const { data } = await api.get(`cars/${carId}/stories/${storyId}?language=${language}`);
  return data
  }
  else {
  const { data } = await api.get(`cars/${carId}/stories/${storyId}`);
  return data;
  }
};

export const useStoryQuery = ({ carId, storyId, language }: StoryPayload) => {
  return useQuery(['useStory', carId, storyId, language], () => getStoryDetails({ carId, storyId, language }), {
    cacheTime: 0,
    retry: false,
  });
};
