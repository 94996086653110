import { Currency } from 'types';
import { chosenLang } from 'features/settings/Tabs/LanguageTab';

export const DEFAULT_CURRENCY: Currency = 'USD';

type Language = { value: string; label: string; src?: any };

export const googleLanguages = () => {
  if (chosenLang.includes('uk')) {
    return ukGoogleLanguages;
  } else if (chosenLang.includes('en')) {
    return enGoogleLanguages;
  } else {
    return esGoogleLanguages;
  }
};

const ukGoogleLanguages: Language[] = [
  { label: 'Азербайджанська', value: 'az' },
  { label: 'Аймара', value: 'ay' },
  { label: 'Албанська', value: 'sq' },
  { label: 'Амхарська', value: 'am' },
  { label: 'Англійська', value: 'en' },
  { label: 'Арабська', value: 'ar' },
  { label: 'Ассамська', value: 'as' },
  { label: 'Африкаанс', value: 'af' },
  { label: 'Бамбара', value: 'bm' },
  { label: 'Баскська', value: 'eu' },
  { label: 'Бенгальська', value: 'bn' },
  { label: 'Білоруська', value: 'be' },
  { label: 'Болгарська', value: 'bg' },
  { label: 'Боснійська', value: 'bs' },
  { label: 'Бходжпурі', value: 'bho' },
  { label: 'Валлійська', value: 'cy' },
  { label: "В'єтнамська", value: 'vi' },
  { label: 'Вірменська', value: 'hy' },
  { label: 'Гавайська', value: 'haw' },
  { label: 'Гаїтянська креольська', value: 'ht' },
  { label: 'Галісійська', value: 'gl' },
  { label: 'Гінді', value: 'hi' },
  { label: 'Грецька', value: 'el' },
  { label: 'Грузинська', value: 'ka' },
  { label: 'Гуарані', value: 'gn' },
  { label: 'Гуджараті', value: 'gu' },
  { label: 'Данська', value: 'da' },
  { label: 'Дівехі', value: 'dv' },
  { label: 'Догрі', value: 'doi' },
  { label: 'Еве', value: 'ee' },
  { label: 'Есперанто', value: 'eo' },
  { label: 'Естонська', value: 'et' },
  { label: 'Зулуська', value: 'zu' },
  { label: 'Іврит', value: 'he' },
  { label: 'Ігбо', value: 'ig' },
  { label: 'Ілокано', value: 'ilo' },
  { label: 'Індонезійська', value: 'id' },
  { label: 'Ірландська', value: 'ga' },
  { label: 'Ісландська', value: 'is' },
  { label: 'Іспанська', value: 'es' },
  { label: 'Італійська', value: 'it' },
  { label: 'Їддиш', value: 'yi' },
  { label: 'Йоруба', value: 'yo' },
  { label: 'Казахська', value: 'kk' },
  { label: 'Каннада', value: 'kn' },
  { label: 'Каталонська', value: 'ca' },
  { label: 'Кечуа', value: 'qu' },
  { label: 'Киргизька', value: 'ky' },
  { label: 'Китайська (спрощена)', value: 'zh-CN' },
  { label: 'Китайська (традиційна)', value: 'zh-TW' },
  { label: 'Кіньяруанда', value: 'rw' },
  { label: 'Конкані', value: 'gom' },
  { label: 'Корейська', value: 'ko' },
  { label: 'Корсиканська', value: 'co' },
  { label: 'Коса', value: 'xh' },
  { label: 'Кріо', value: 'kri' },
  { label: 'Курдська', value: 'ku' },
  { label: 'Курдська (сорані)', value: 'ckb' },
  { label: 'Кхмерська', value: 'km' },
  { label: 'Лаоська', value: 'lo' },
  { label: 'Латвійська', value: 'lv' },
  { label: 'Латинська', value: 'la' },
  { label: 'Литовська', value: 'lt' },
  { label: 'Лінгала', value: 'ln' },
  { label: 'Луганда', value: 'lg' },
  { label: 'Люксембурзька', value: 'lb' },
  { label: 'Майтілі', value: 'mai' },
  { label: 'Македонська', value: 'mk' },
  { label: 'Малагасійська', value: 'mg' },
  { label: 'Малайська', value: 'ms' },
  { label: 'Малаялам', value: 'ml' },
  { label: 'Мальтійська', value: 'mt' },
  { label: 'Маорі', value: 'mi' },
  { label: 'Маратхі', value: 'mr' },
  { label: 'Мейтейлон (Маніпурі)', value: 'mni-Mtei' },
  { label: 'Мізо', value: 'lus' },
  { label: 'Монгольська', value: 'mn' },
  { label: "М'янма (бірманська)", value: 'my' },
  { label: 'Непальська', value: 'ne' },
  { label: 'Нідерландська', value: 'nl' },
  { label: 'Німецька', value: 'de' },
  { label: 'Норвезька', value: 'no' },
  { label: "Н'янджа (Чічева)", value: 'ny' },
  { label: 'Орія (Орія)', value: 'or' },
  { label: 'Оромо', value: 'om' },
  { label: 'Панджабі', value: 'pa' },
  { label: 'Пашто', value: 'ps' },
  { label: 'Перська', value: 'fa' },
  { label: 'Польська', value: 'pl' },
  { label: 'Португальська (Португалія, Бразилія)', value: 'pt' },
  { label: 'Російська', value: 'ru' },
  { label: 'Румунська', value: 'ro' },
  { label: 'Самоанська', value: 'sm' },
  { label: 'Санскрит', value: 'sa' },
  { label: 'Себуано', value: 'ceb' },
  { label: 'Сепеді', value: 'nso' },
  { label: 'Сербська', value: 'sr' },
  { label: 'Сесото', value: 'st' },
  { label: 'Сінгальська (Сінгальська)', value: 'si' },
  { label: 'Сіндхі', value: 'sd' },
  { label: 'Словацька', value: 'sk' },
  { label: 'Словенська', value: 'sl' },
  { label: 'Сомалі', value: 'so' },
  { label: 'Суахілі', value: 'sw' },
  { label: 'Сунданська', value: 'su' },
  { label: 'Тагальська (Філіпінська)', value: 'tl' },
  { label: 'Таджицька', value: 'tg' },
  { label: 'Тайська', value: 'th' },
  { label: 'Тамільська', value: 'ta' },
  { label: 'Татарська', value: 'tt' },
  { label: 'Тві (Акан)', value: 'ak' },
  { label: 'Телугу', value: 'te' },
  { label: 'Тігринія', value: 'ti' },
  { label: 'Турецька', value: 'tr' },
  { label: 'Туркменська', value: 'tk' },
  { label: 'Угорська', value: 'hu' },
  { label: 'Узбецька', value: 'uz' },
  { label: 'Уйгурська', value: 'ug' },
  { label: 'Українська', value: 'uk' },
  { label: 'Урду', value: 'ur' },
  { label: 'Філіпінська (тагалог)', value: 'fil' },
  { label: 'Фінська', value: 'fi' },
  { label: 'Французька', value: 'fr' },
  { label: 'Фрізійська', value: 'fy' },
  { label: 'Хауса', value: 'ha' },
  { label: 'Хмонг', value: 'hmn' },
  { label: 'Хорватська', value: 'hr' },
  { label: 'Цонга', value: 'ts' },
  { label: 'Чеська', value: 'cs' },
  { label: 'Шведська', value: 'sv' },
  { label: 'Шона', value: 'sn' },
  { label: 'Шотландська гельська', value: 'gd' },
  { label: 'Яванська', value: 'jv' },
  { label: 'Японська', value: 'ja' },
];

const enGoogleLanguages: Language[] = [
  { label: 'Afrikaans', value: 'af' },
  { label: 'Albanian', value: 'sq' },
  { label: 'Amharic', value: 'am' },
  { label: 'Arabic', value: 'ar' },
  { label: 'Armenian', value: 'hy' },
  { label: 'Assamese', value: 'as' },
  { label: 'Aymara', value: 'ay' },
  { label: 'Azerbaijani', value: 'az' },
  { label: 'Bambara', value: 'bm' },
  { label: 'Basque', value: 'eu' },
  { label: 'Belarusian', value: 'be' },
  { label: 'Bengali', value: 'bn' },
  { label: 'Bhojpuri', value: 'bho' },
  { label: 'Bosnian', value: 'bs' },
  { label: 'Bulgarian', value: 'bg' },
  { label: 'Catalan', value: 'ca' },
  { label: 'Cebuano', value: 'ceb' },
  { label: 'Chinese (Simplified)', value: 'zh-CN' },
  { label: 'Chinese (Traditional)', value: 'zh-TW' },
  { label: 'Corsican', value: 'co' },
  { label: 'Croatian', value: 'hr' },
  { label: 'Czech', value: 'cs' },
  { label: 'Danish', value: 'da' },
  { label: 'Dhivehi', value: 'dv' },
  { label: 'Dogri', value: 'doi' },
  { label: 'Dutch', value: 'nl' },
  { label: 'English', value: 'en' },
  { label: 'Esperanto', value: 'eo' },
  { label: 'Estonian', value: 'et' },
  { label: 'Ewe', value: 'ee' },
  { label: 'Filipino (Tagalog)', value: 'fil' },
  { label: 'Finnish', value: 'fi' },
  { label: 'French', value: 'fr' },
  { label: 'Frisian', value: 'fy' },
  { label: 'Galician', value: 'gl' },
  { label: 'Georgian', value: 'ka' },
  { label: 'German', value: 'de' },
  { label: 'Greek', value: 'el' },
  { label: 'Guarani', value: 'gn' },
  { label: 'Gujarati', value: 'gu' },
  { label: 'Haitian Creole', value: 'ht' },
  { label: 'Hausa', value: 'ha' },
  { label: 'Hawaiian', value: 'haw' },
  { label: 'Hebrew', value: 'he' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Hmong', value: 'hmn' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Icelandic', value: 'is' },
  { label: 'Igbo', value: 'ig' },
  { label: 'Ilocano', value: 'ilo' },
  { label: 'Indonesian', value: 'id' },
  { label: 'Irish', value: 'ga' },
  { label: 'Italian', value: 'it' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Javanese', value: 'jv' },
  { label: 'Kannada	', value: 'kn' },
  { label: 'Kazakh', value: 'kk' },
  { label: 'Khmer', value: 'km' },
  { label: 'Kinyarwanda', value: 'rw' },
  { label: 'Konkani', value: 'gom' },
  { label: 'Korean', value: 'ko' },
  { label: 'Krio', value: 'kri' },
  { label: 'Kurdish', value: 'ku' },
  { label: 'Kurdish (Sorani)', value: 'ckb' },
  { label: 'Kyrgyz', value: 'ky' },
  { label: 'Lao', value: 'lo' },
  { label: 'Latin', value: 'la' },
  { label: 'Latvian', value: 'lv' },
  { label: 'Lingala', value: 'ln' },
  { label: 'Lithuanian', value: 'lt' },
  { label: 'Luganda', value: 'lg' },
  { label: 'Luxembourgish', value: 'lb' },
  { label: 'Macedonian', value: 'mk' },
  { label: 'Maithili', value: 'mai' },
  { label: 'Malagasy', value: 'mg' },
  { label: 'Malay', value: 'ms' },
  { label: 'Malayalam', value: 'ml' },
  { label: 'Maltese', value: 'mt' },
  { label: 'Maori', value: 'mi' },
  { label: 'Marathi', value: 'mr' },
  { label: 'Meiteilon (Manipuri)', value: 'mni-Mtei' },
  { label: 'Mizo', value: 'lus' },
  { label: 'Mongolian', value: 'mn' },
  { label: 'Myanmar (Burmese)', value: 'my' },
  { label: 'Nepali', value: 'ne' },
  { label: 'Norwegian', value: 'no' },
  { label: 'Nyanja (Chichewa)', value: 'ny' },
  { label: 'Odia (Oriya)', value: 'or' },
  { label: 'Oromo', value: 'om' },
  { label: 'Pashto', value: 'ps' },
  { label: 'Persian', value: 'fa' },
  { label: 'Polish', value: 'pl' },
  { label: 'Portuguese (Portugal, Brazil)', value: 'pt' },
  { label: 'Punjabi', value: 'pa' },
  { label: 'Quechua', value: 'qu' },
  { label: 'Romanian', value: 'ro' },
  { label: 'Russian', value: 'ru' },
  { label: 'Samoan', value: 'sm' },
  { label: 'Sanskrit', value: 'sa' },
  { label: 'Scots Gaelic', value: 'gd' },
  { label: 'Sepedi', value: 'nso' },
  { label: 'Serbian', value: 'sr' },
  { label: 'Sesotho', value: 'st' },
  { label: 'Shona', value: 'sn' },
  { label: 'Sindhi', value: 'sd' },
  { label: 'Sinhala (Sinhalese)', value: 'si' },
  { label: 'Slovak', value: 'sk' },
  { label: 'Slovenian', value: 'sl' },
  { label: 'Somali', value: 'so' },
  { label: 'Spanish', value: 'es' },
  { label: 'Sundanese', value: 'su' },
  { label: 'Swahili', value: 'sw' },
  { label: 'Swedish', value: 'sv' },
  { label: 'Tagalog (Filipino)', value: 'tl' },
  { label: 'Tajik', value: 'tg' },
  { label: 'Tamil', value: 'ta' },
  { label: 'Tatar', value: 'tt' },
  { label: 'Telugu', value: 'te' },
  { label: 'Thai', value: 'th' },
  { label: 'Tigrinya', value: 'ti' },
  { label: 'Tsonga', value: 'ts' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Turkmen', value: 'tk' },
  { label: 'Twi (Akan)', value: 'ak' },
  { label: 'Ukrainian', value: 'uk' },
  { label: 'Urdu', value: 'ur' },
  { label: 'Uyghur', value: 'ug' },
  { label: 'Uzbek', value: 'uz' },
  { label: 'Vietnamese', value: 'vi' },
  { label: 'Welsh', value: 'cy' },
  { label: 'Xhosa', value: 'xh' },
  { label: 'Yiddish', value: 'yi' },
  { label: 'Yoruba', value: 'yo' },
  { label: 'Zulu', value: 'zu' },
];

const esGoogleLanguages: Language[] = [
  { label: 'Afrikáans', value: 'af' },
  { label: 'Aimara', value: 'ay' },
  { label: 'Albanés', value: 'sq' },
  { label: 'Alemán', value: 'de' },
  { label: 'Amárico', value: 'am' },
  { label: 'Árabe', value: 'ar' },
  { label: 'Armenio', value: 'hy' },
  { label: 'Asamés', value: 'as' },
  { label: 'Azerí', value: 'az' },
  { label: 'Bambara', value: 'bm' },
  { label: 'Bengalí', value: 'bn' },
  { label: 'Bhojpurí', value: 'bho' },
  { label: 'Bielorruso', value: 'be' },
  { label: 'Birmano (Myanmar)', value: 'my' },
  { label: 'Bosnio', value: 'bs' },
  { label: 'Búlgaro', value: 'bg' },
  { label: 'Canarés', value: 'kn' },
  { label: 'Catalán', value: 'ca' },
  { label: 'Cebuano', value: 'ceb' },
  { label: 'Checo', value: 'cs' },
  { label: 'Chichewa (Nyanja)', value: 'ny' },
  { label: 'Chino (Simplificado)', value: 'zh-CN' },
  { label: 'Chino (Tradicional)', value: 'zh-TW' },
  { label: 'Cingalés', value: 'si' },
  { label: 'Coreano', value: 'ko' },
  { label: 'Corso', value: 'co' },
  { label: 'Criollo haitiano', value: 'ht' },
  { label: 'Croata', value: 'hr' },
  { label: 'Danés', value: 'da' },
  { label: 'Divehí', value: 'dv' },
  { label: 'Dogri', value: 'doi' },
  { label: 'Eslovaco', value: 'sk' },
  { label: 'Esloveno', value: 'sl' },
  { label: 'Español', value: 'es' },
  { label: 'Esperanto', value: 'eo' },
  { label: 'Estonio', value: 'et' },
  { label: 'Ewé', value: 'ee' },
  { label: 'Filipino (Tagalo)', value: 'fil' },
  { label: 'Finlandés', value: 'fi' },
  { label: 'Francés', value: 'fr' },
  { label: 'Frisón', value: 'fy' },
  { label: 'Gallego', value: 'gl' },
  { label: 'Galés', value: 'cy' },
  { label: 'Gaélico escocés', value: 'gd' },
  { label: 'Georgiano', value: 'ka' },
  { label: 'Griego', value: 'el' },
  { label: 'Guaraní', value: 'gn' },
  { label: 'Gujarati', value: 'gu' },
  { label: 'Hausa', value: 'ha' },
  { label: 'Hawaiano', value: 'haw' },
  { label: 'Hebreo', value: 'he' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Hmong', value: 'hmn' },
  { label: 'Húngaro', value: 'hu' },
  { label: 'Igbo', value: 'ig' },
  { label: 'Ilocano', value: 'ilo' },
  { label: 'Indonesio', value: 'id' },
  { label: 'Inglés', value: 'en' },
  { label: 'Irlandés', value: 'ga' },
  { label: 'Islandés', value: 'is' },
  { label: 'Italiano', value: 'it' },
  { label: 'Japonés', value: 'ja' },
  { label: 'Javanés', value: 'jv' },
  { label: 'Jemer', value: 'km' },
  { label: 'Kazajo', value: 'kk' },
  { label: 'Kinyarwanda', value: 'rw' },
  { label: 'Kirguís', value: 'ky' },
  { label: 'Konkani', value: 'gom' },
  { label: 'Krio', value: 'kri' },
  { label: 'Kurdo', value: 'ku' },
  { label: 'Kurdo (Sorani)', value: 'ckb' },
  { label: 'Lao', value: 'lo' },
  { label: 'Latín', value: 'la' },
  { label: 'Letón', value: 'lv' },
  { label: 'Lingala', value: 'ln' },
  { label: 'Lituano', value: 'lt' },
  { label: 'Luganda', value: 'lg' },
  { label: 'Luxemburgués', value: 'lb' },
  { label: 'Macedonio', value: 'mk' },
  { label: 'Maithili', value: 'mai' },
  { label: 'Malayalam', value: 'ml' },
  { label: 'Malayo', value: 'ms' },
  { label: 'Malgache', value: 'mg' },
  { label: 'Maltés', value: 'mt' },
  { label: 'Maorí', value: 'mi' },
  { label: 'Maratí', value: 'mr' },
  { label: 'Meiteilon (Manipuri)', value: 'mni-Mtei' },
  { label: 'Mizo', value: 'lus' },
  { label: 'Mongol', value: 'mn' },
  { label: 'Neerlandés', value: 'nl' },
  { label: 'Nepalí', value: 'ne' },
  { label: 'Noruego', value: 'no' },
  { label: 'Oriya (Odia)', value: 'or' },
  { label: 'Oromo', value: 'om' },
  { label: 'Panyabí', value: 'pa' },
  { label: 'Pastún', value: 'ps' },
  { label: 'Persa', value: 'fa' },
  { label: 'Polaco', value: 'pl' },
  { label: 'Portugués (Portugal, Brasil)', value: 'pt' },
  { label: 'Quechua', value: 'qu' },
  { label: 'Rumano', value: 'ro' },
  { label: 'Ruso', value: 'ru' },
  { label: 'Samoano', value: 'sm' },
  { label: 'Sepedi', value: 'nso' },
  { label: 'Serbio', value: 'sr' },
  { label: 'Sesotho', value: 'st' },
  { label: 'Shona', value: 'sn' },
  { label: 'Sindhi', value: 'sd' },
  { label: 'Somalí', value: 'so' },
  { label: 'Suajili', value: 'sw' },
  { label: 'Sueco', value: 'sv' },
  { label: 'Sundanés', value: 'su' },
  { label: 'Sánscrito', value: 'sa' },
  { label: 'Tagalo (Filipino)', value: 'tl' },
  { label: 'Tailandés', value: 'th' },
  { label: 'Tamil', value: 'ta' },
  { label: 'Tayiko', value: 'tg' },
  { label: 'Telugu', value: 'te' },
  { label: 'Tigriña', value: 'ti' },
  { label: 'Tsonga', value: 'ts' },
  { label: 'Turco', value: 'tr' },
  { label: 'Turcomano', value: 'tk' },
  { label: 'Twi (Akan)', value: 'ak' },
  { label: 'Tártaro', value: 'tt' },
  { label: 'Ucraniano', value: 'uk' },
  { label: 'Uigur', value: 'ug' },
  { label: 'Urdu', value: 'ur' },
  { label: 'Uzbeko', value: 'uz' },
  { label: 'Vasco', value: 'eu' },
  { label: 'Vietnamita', value: 'vi' },
  { label: 'Xhosa', value: 'xh' },
  { label: 'Yidis', value: 'yi' },
  { label: 'Yoruba', value: 'yo' },
  { label: 'Zulú', value: 'zu' },
];

export enum STORY_ELEMENT_TYPE {
  'TEXT' = 'original_text',
  'GALLERY' = 'gallery',
  'RECORD' = 'records',
  'ASSISTED_RECORD' = 'assisted_records',
  'VIDEO' = 'youtube_video',
}
