import { ComponentType, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import withClearCache from './ClearCache';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'react-toastify/dist/ReactToastify.css';

import { App } from './App';
import './assets/fonts/AGFatumC.otf';
import './i18n';
import './index.css';
const container = document.getElementById('root');
const root = createRoot(container!);

const ClearCacheComponent: ComponentType = withClearCache(App);

root.render(
  <Suspense fallback={<div>Loading...</div>}>
    <ClearCacheComponent />
  </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
