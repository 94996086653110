import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';
import { carReminderChangePayload } from './reminderPayloads';

export const incompleteReminder = async ({ carId, reminderId }: carReminderChangePayload) => {
  const { data } = await api.delete(`cars/${carId}/reminders/${reminderId}/complete`);
  return data;
};

export const useIncompleteReminderMutation = () => {
  return useMutation(incompleteReminder);
};
