import Lottie from 'lottie-react';
import { useEffect, useRef, useState } from 'react';
import animationData from './data.json';
import { Typography } from '@mui/material';

export type AILoaderTextType = {
  text: string;
  duration: number;
};

interface LottieLoaderProps {
  loading: boolean;
  messages?: AILoaderTextType[];
}

export const AILoader: React.FC<LottieLoaderProps> = ({ loading, messages }) => {
  const lottieRef = useRef<any>(null);

  const [currentMessage, setCurrentMessage] = useState(messages ? messages[0].text : '');

  useEffect(() => {
    if (loading && lottieRef.current) {
      lottieRef.current.goToAndPlay(27, true);
      messages?.forEach((message, index) => {
        setTimeout(() => {
          if (index < messages.length - 1) {
            setCurrentMessage(messages[index + 1].text);
          }
        }, message.duration);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (!loading) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
      }}
    >
      <Lottie animationData={animationData} lottieRef={lottieRef} />
      {currentMessage && <Typography variant='body1'>{currentMessage}</Typography>}
    </div>
  );
};
