import { DeleteForever, Send } from '@mui/icons-material';
import { Box, IconButton, Theme } from '@mui/material';
import { ConfirmationDialog } from 'components';
import Union from 'components/CustomIcons/Union';
import { ComponentType, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ spacing, breakpoints, palette }: Theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    background: '#2E2E2E',
    borderTop: '1px #FFFFFF1A solid',
    [breakpoints.down('md')]: {
      minHeight: `calc(64px + env(safe-area-inset-bottom))`,
      padding: spacing(1, 2, 2, 2),
    },
    [breakpoints.up('md')]: {
      minHeight: 88,
      padding: spacing(2),
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'end',
    height: '100%',
    [breakpoints.up('md')]: {
      maxWidth: 643,
      margin: '0 auto',
    },
  },

  field: {
    background: '#202020',
    minHeight: 40,
    width: 'calc(100% - 48px)',
    padding: spacing(1),
    border: '1px #FFFFFF13 solid',
    borderRadius: 4,
    marginRight: spacing(1),
    position: 'relative',
  },
  input: {
    '&:focus-visible': {
      outline: 0,
    },
    '&:empty:before': {
      content: 'attr(placeholder)',
      pointerEvents: 'none',
      display: 'block',
      color: '#FFFFFF73',
    },
    '& p': {
      margin: 0,
    },
  },
  voiceButton: {
    color: '#FFFFFFBD',
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

interface EditableInputProps {
  onSubmit: (message: string) => void;
  handleVoice: () => void;
  onClearHistory: () => void;
  clearHistoryAvailability: boolean;
}
export const InputField: ComponentType<EditableInputProps> = ({
  onSubmit,
  handleVoice,
  onClearHistory,
  clearHistoryAvailability,
}) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>('');
  const divRef = useRef<HTMLDivElement>(null);

  const handleSubmit = () => {
    if (divRef.current) {
      onSubmit(message.trim());
      setMessage('');
      divRef.current.innerHTML = '';
    }
  };

  const handleInput = () => {
    if (divRef.current) {
      setMessage(divRef.current.innerText);
      const brElements = divRef.current.querySelectorAll('br');
      brElements.forEach((br) => {
        if (!br.previousSibling && !br.nextSibling) {
          br.remove();
        }
      });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Backspace') {
      if (divRef.current?.innerHTML === '<br><br>' || divRef.current?.innerHTML === '') {
        setMessage('');
        divRef.current.innerHTML = '';
      }
    }
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (message.trim()) {
        onSubmit(message.trim());
        if (divRef.current) {
          divRef.current.innerText = '';
          setMessage('');
        }
      }
    }
  };
  const handlePaste = (e: any) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  };

  const [openDialogClearHistory, setOpenDialogClearHistory] = useState(false);

  const handleOpenDialogClearHistory = () => {
    setOpenDialogClearHistory(true);
  };

  const handleClearHistory = () => {
    setOpenDialogClearHistory(false);
    onClearHistory();
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>
        <IconButton color='secondary' disabled={!clearHistoryAvailability} onClick={handleOpenDialogClearHistory}>
          <DeleteForever />
        </IconButton>
        <Box className={classes.field}>
          <div
            contentEditable
            className={classes.input}
            placeholder={t('landing_page_content_field')}
            ref={divRef}
            onInput={handleInput}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
          ></div>
          {!message && (
            <IconButton className={classes.voiceButton} onClick={handleVoice}>
              <Union className={classes.voiceIcon} />
            </IconButton>
          )}
        </Box>
        <IconButton color='secondary' disabled={!message} onClick={handleSubmit}>
          <Send />
        </IconButton>
      </Box>
      <ConfirmationDialog
        open={openDialogClearHistory}
        onConfirm={handleClearHistory}
        onClose={() => {
          setOpenDialogClearHistory(false);
        }}
        title={t('assistant_clear_chat_history_popup_title')}
        description={t('assistant_clear_chat_history_popup_description')}
      />
    </Box>
  );
};
