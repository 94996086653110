import { Grid, Theme } from '@mui/material';
import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'store2';

import { CommonLayout, PageLoader } from 'components';
import { EmptyGarage } from 'components/Stories/EmptyGarage';
import { useInitialSettings } from 'lib';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { DashboardChart, DashboardFilters, DashboardStatCard, DashboardTile, EmptyRecords } from './components';
import { useDashboardPage } from './useDashboardPage';

const tKey = getTKey('dashboard');

const styles = ({ spacing }: Theme) => ({
  note: {
    margin: spacing(3, 0, 2),
    textAlign: 'left',
    fontSize: 12,
    opacity: 0.6,
  },
});

export const DashboardPage: ComponentType = () => {
  useInitialSettings();
  const { t } = useTranslation();
  const settingsData = store('driverbook_settings');
  const classes = useClasses(styles);
  const {
    loading,
    showFilters,
    showRecordsCharts,
    cars,
    allYears,
    allCategories,
    filters,
    handleFiltersChange,
    expensesByMonth,
    mileageByMonth,
    averageMonthlyExpenses,
    averageMonthlyMileage,
    totalExpenses,
    totalMileage,
    spendingByCategory,
    spendingByFrequency,
    refetch
  } = useDashboardPage();
  const currentCarMileageMetric = cars?.find((car) => car.id === filters.carId)?.mileageMetric;

  useEffect(() => {
    refetch()
  }, [refetch, averageMonthlyExpenses, totalExpenses])

  return (
    <CommonLayout>
      {loading && <PageLoader />}
      {showFilters ? (
        <>
          <DashboardFilters
            cars={cars}
            years={allYears}
            categories={allCategories}
            filters={filters}
            onFiltersChange={handleFiltersChange}
          />
          {showRecordsCharts ? (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} md={9}>
                  <DashboardChart
                    type='expenses'
                    totalValue={totalExpenses}
                    data={expensesByMonth}
                    mileageMetric={currentCarMileageMetric}
                    filters={filters}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DashboardTile
                    heading={`${t(tKey('average_expenses'))} (${settingsData.currency})`}
                    count={averageMonthlyExpenses}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ display: { xs: 'none' } }}>
                <Grid item xs={12} md={9}>
                  <DashboardChart
                    type='mileage'
                    totalValue={totalMileage}
                    data={mileageByMonth}
                    mileageMetric={currentCarMileageMetric}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DashboardTile heading={t(tKey('average_mileage'))} count={averageMonthlyMileage} />
                </Grid>
              </Grid>

              <div className={classes.note}>{t(tKey('statistics_note'))}</div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <DashboardStatCard heading={t(tKey('spending_category'))} data={spendingByCategory} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DashboardStatCard
                    color='secondary'
                    heading={t(tKey('spending_frequency'))}
                    data={spendingByFrequency}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <EmptyRecords title={t(tKey('no_records'))} />
          )}
        </>
      ) : (
        <EmptyGarage />
      )}
    </CommonLayout>
  );
};
