import { useReviewQuery } from 'services/admin';
import { IItem } from '../components/AdminReview/AdminItemTypes';

type useAdminReviewDataType = () => {
  loading: boolean;
  total: number;
  items: IItem[];
  refreshItems: () => void;
};

export const useAdminReview: useAdminReviewDataType = () => {
  const { data, isLoading: loading, refetch: refetchReviews } = useReviewQuery();
  var items = data?.items;
  var total = data?.total;
  const refreshItems = () => {
    return refetchReviews();
  };

  return {
    items,
    total,
    loading,
    refreshItems,
  };
};
