import { Switch, Theme } from '@mui/material';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClasses } from 'utils/hooks/useClasses';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { StyledButton } from 'components';
import { useAdminUserModerate, useAdminWarnBanUser } from '../hooks';
import { UserBanDialog } from './Dialogs';
import { getTKey } from 'utils';
import { BanType, DurationType, IUser } from './AdminDashboardTypes';
import { CopyToClipboard } from 'components/CopyToClipboard/CopyToClipboard';
import { UserUnblockDialog } from './Dialogs/UserUnblockDialog';
import { useAdminUnblockUser } from '../hooks/useAdminUnblockUser';
import { UserDetailsDialog } from './Dialogs';
import { ItemActionType } from './AdminReview/AdminItemTypes';
const tKey = getTKey('admin_user_list');
const styles = ({ palette, spacing }: Theme) => ({
  header: {
    color: palette.text.hint,
    display: 'flex',
    flexDirection: 'row',
    padding: spacing(1, 2, 1),
    textTransform: 'uppercase',
  },
  contentRow: {
    display: 'flex',
    flexDirection: 'row',
    background: '#202020',
    border: '1px solid rgba(255, 255, 255, 0.06)',
    borderRadius: 4,
    padding: spacing(2),
    margin: spacing(0.5, 0),
    alignItems: 'center',
  },
  usernameColumn: {
    width: `calc(25% - ${spacing(5)})`,
    margin: spacing(0, 5, 0, 0),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  idColumn: {
    width: `calc(20% - ${spacing(5)})`,
    margin: spacing(0, 5, 0, 0),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  storiesColumn: {
    width: `calc(15% - ${spacing(5)})`,
    margin: spacing(0, 5, 0, 0),
  },
  followersColumn: {
    width: `calc(15% - ${spacing(5)})`,
    margin: spacing(0, 5, 0, 0),
  },
  trustedColumn: {
    width: `calc(15% - ${spacing(5)})`,
    margin: spacing(0, 5, 0, 0),
  },
  endOfBanColumn: {
    width: `calc(15% - ${spacing(5)})`,
    margin: spacing(0, 5, 0, 0),
  },
  banDateColumn: {
    width: `calc(15% - ${spacing(5)})`,
    margin: spacing(0, 5, 0, 0),
  },
  actionColumn: {
    width: '20%',
    margin: 0,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'end',
  },
  userTitle: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 600,
  },
  userId: {
    cursor: 'pointer',
  },
  hintCell: {
    color: palette.text.hint,
  },
  hideButton: {
    background: '#FF5050',
  },
  actionButton: {
    padding: spacing(0.5, 1.5),
    margin: spacing(0, 0.5),
    textTransform: 'none',
  },
  detailsButton: {
    background: '#C4C4C4',
  },
});

interface UserListType {
  users: IUser[];
  statusBanned: boolean;
  refreshUsers: () => void;
}
export const UserList: ComponentType<UserListType> = ({ users, statusBanned, refreshUsers }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);

  const { trustUser, untrustUser } = useAdminUserModerate();
  const { onBan, openBanUserDialog, setHiddenUser, onCloseBanDialog, onBanOpenDialog } = useAdminWarnBanUser();

  const onChangeTrust = async (user: IUser) => {
    if (user.is_trusted) {
      await untrustUser(user.id);
    } else {
      await trustUser(user.id);
    }
    user.is_trusted = !user.is_trusted;
  };

  const lastDateEndOfBan = (user: IUser) => {
    if (user.is_story_banned && !user.is_comment_banned) {
      return user.story_banned_until;
    }
    if (!user.is_story_banned && user.is_comment_banned) {
      return user.comment_banned_until;
    }
    if (user.is_story_banned && user.is_comment_banned) {
      return dayjs(user.story_banned_until).isAfter(dayjs(user.comment_banned_until))
        ? user.story_banned_until
        : user.comment_banned_until;
    }
  };

  const onBanUserDialogAction = (user: IUser) => {
    setHiddenUser(user.id);
    onBanOpenDialog();
  };

  const { onCloseUnblockUserDialog, onOpenUnblockUserDialog, onUnblockUser, openUnblockUserDialog, unblockedUser } =
    useAdminUnblockUser();

  const [openDetails, setOpenDetails] = useState(false);
  const [detailUser, setDetailUser] = useState<IUser | null>(null);

  const onOpenUserDetails = (user: IUser) => {
    setDetailUser(user);
    setOpenDetails(true);
  };

  const onCloseUserDetails = () => {
    setDetailUser(null);
    setOpenDetails(false);
  };

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.usernameColumn}>{t(tKey('header_username'))}</div>
        <div className={classes.idColumn}>{t(tKey('header_id'))}</div>
        {statusBanned ? (
          <>
            <div className={classes.storiesColumn}>{t(tKey('header_stories'))}</div>
            <div className={classes.followersColumn}>{t(tKey('header_followers'))}</div>
            <div className={classes.trustedColumn}>{t(tKey('header_trusted'))}</div>
          </>
        ) : (
          <>
            <div className={classes.endOfBanColumn}>{t(tKey('header_end_of_ban'))}</div>
            <div className={classes.banDateColumn}>{t(tKey('header_ban_date'))}</div>
          </>
        )}

        <div className={classes.actionColumn}></div>
      </div>
      {users.map((user) => {
        return (
          <div key={user.id} className={classes.contentRow}>
            <div className={clsx(classes.usernameColumn, classes.userTitle)} onClick={() => onOpenUserDetails(user)}>
              {user.username}
            </div>
            <div className={clsx(classes.idColumn, classes.hintCell, classes.userId)}>
              <CopyToClipboard text={user.id} />
            </div>
            {statusBanned ? (
              <>
                <div className={clsx(classes.storiesColumn, classes.hintCell)}>{user.stories}</div>
                <div className={clsx(classes.followersColumn, classes.hintCell)}>{user.followers}</div>

                <div>
                  <Switch checked={user.is_trusted} onChange={() => onChangeTrust(user)} />
                </div>
              </>
            ) : (
              <>
                <div className={clsx(classes.endOfBanColumn, classes.hintCell)}>
                  {dayjs(lastDateEndOfBan(user)).format('DD-MM-YYYY')}
                </div>
                <div className={clsx(classes.banDateColumn, classes.hintCell)}>
                  {user.last_banned_date && dayjs(user.last_banned_date).format('DD-MM-YYYY')}
                </div>
              </>
            )}
            <div className={classes.actionColumn}>
              {!user.is_comment_banned && !user.is_story_banned && !user.is_profile_banned && (
                <StyledButton
                  label={t(tKey('action_ban'))}
                  className={clsx(classes.actionButton, classes.hideButton)}
                  onClick={() => {
                    onBanUserDialogAction(user);
                  }}
                />
              )}
              {(user.is_comment_banned || user.is_story_banned || user.is_profile_banned) && (
                <>
                  <StyledButton
                    label={t(tKey('action_restore'))}
                    color='secondary'
                    className={clsx(classes.actionButton)}
                    onClick={() => onOpenUnblockUserDialog(user)}
                  />
                  <StyledButton
                    label={t(tKey('action_details'))}
                    color='secondary'
                    className={clsx(classes.actionButton, classes.detailsButton)}
                    onClick={() => onOpenUserDetails(user)}
                  />
                </>
              )}
            </div>
          </div>
        );
      })}
      <UserBanDialog
        onClose={onCloseBanDialog}
        onConfirm={async (ban_from: ItemActionType, duration: DurationType, note: string) => {
          await onBan(ban_from, duration, note);
          refreshUsers();
        }}
        open={openBanUserDialog}
      />
      <UserUnblockDialog
        user={unblockedUser}
        onClose={onCloseUnblockUserDialog}
        onConfirm={async (from) => {
          await onUnblockUser(from as BanType);
          refreshUsers();
        }}
        open={openUnblockUserDialog}
      />
      <UserDetailsDialog onClose={onCloseUserDetails} open={openDetails} user={detailUser} refresh={refreshUsers} />
    </div>
  );
};
