import { Cancel } from '@mui/icons-material';
import { IconButton, Theme } from '@mui/material';
import clsx from 'clsx';
import { TFunctionResult } from 'i18next';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing(1),
    padding: spacing(0.25, 1, 0.25, 0.75),
    backgroundColor: '#4B01D0',
    borderRadius: spacing(2),
  },
  withoutAction: {
    padding: spacing(0.875, 1, 0.875, 0.75),
  },
  label: {
    whiteSpace: 'nowrap',

    '& button': {
      marginLeft: spacing(0.5),
    },
  },
});

type ChipLabelType = number | string | TFunctionResult;

interface FilterChipProps {
  type: 'years' | 'months' | 'categories';
  label: ChipLabelType;
  onReset?: () => void;
}

export const FilterChip: ComponentType<FilterChipProps> = ({ type, label, onReset }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);

  const prepareChipLabel = (value: ChipLabelType): ChipLabelType => {
    if (value === 'all') {
      return t(`__common__all_${type}`);
    }

    return value;
  };

  return (
    <div className={clsx(classes.root, { [classes.withoutAction]: label === 'all' })}>
      <span className={classes.label}>{prepareChipLabel(label)}</span>
      {onReset && (
        <IconButton size='small' onClick={onReset}>
          <Cancel />
        </IconButton>
      )}
    </div>
  );
};
