import { Block, Report } from '@mui/icons-material';
import { Theme } from '@mui/material';
import clsx from 'clsx';
import { FollowButton } from 'components';
import { PatronMedium } from 'components/PatronBadges/PatronMedium';
import dayjs from 'dayjs';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { Alert } from './AlertTypes';
import weekly_post from 'assets/weekly_post.jpg';
import ReminderBell from 'assets/reminder-bell.png';

var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
var localeData = require('dayjs/plugin/localeData');
dayjs.extend(localeData);
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const tKey = getTKey('alert_item');
const pcKey = getTKey('profile_correct');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: spacing(2),
  },
  wrapperToast: {
    display: 'flex',
    flexDirection: 'row',
  },
  imageWrapper: {
    paddingRight: spacing(2),
  },
  content: {
    flex: 1,
    paddingRight: spacing(1),
  },
  backBan: {
    width: 32,
    height: 32,
    borderRadius: 16,
    background: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backUser: {
    width: 32,
    height: 32,
    borderRadius: 16,
    background: '#202020',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  blockIcon: {
    color: '#000000',
  },
  title: {
    fontWeight: 700,
    paddingBottom: spacing(0.5),
    fontSize: 12,
  },
  contentText: {
    fontSize: 12,
    fontWeight: 400,
    wordBreak: 'break-word',
  },
  timeAgo: {
    fontWeight: 800,
    fontSize: 12,
    color: palette.text.hint,
  },
  alertImageInitiator: {
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  banPeriodText: {
    fontWeight: 700,
  },
  itemImage: {
    width: 44,
    height: 44,
    borderRadius: 10,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  pointer: {
    cursor: 'pointer',
  },
  usernamePatron: {
    color: '#FFCB40',
  },
  userNameBox: {
    display: 'flex',
  },

  patronBox: {
    marginLeft: spacing(1),
    height: 16,
  },
});

interface AlertItemType {
  alert: Alert;
  toast?: boolean;
}
export const AlertItem: ComponentType<AlertItemType> = ({ alert, toast }) => {
  const classes = useClasses(styles);
  const { t, i18n } = useTranslation();

  const getLang = () => {
    return i18n.language;
  };
  const lang = getLang();
  const navigate = useNavigate();

  if (
    alert.message.type === 'story_got_liked' ||
    alert.message.type === 'story_got_disliked' ||
    alert.message.type === 'comment_got_liked' ||
    alert.message.type === 'comment_got_disliked' ||
    alert.message.type === 'story_got_commented' ||
    alert.message.type === 'comment_got_commented' ||
    alert.message.type === 'weekly_story' ||
    alert.message.type === 'system_story' ||
    alert.message.type === 'reminder'
  ) {
    if (alert.message.target_id?.length > 30) {
      alert.message.target_id = alert.message.target_id.substring(0, 18) + '...';
    }
  }

  try {
    if (lang === 'uk') {
      dayjs.locale(require(`dayjs/locale/uk`));
    } else if (lang === 'es') {
      dayjs.locale(require(`dayjs/locale/es`));
    } else {
      dayjs.locale(require(`dayjs/locale/en`));
    }
  } catch (e) {
    console.log(`Cannot load ${lang} language. Will be used english language`);
  }

  const getTimeAgoString = (dateString: string) => {
    //@ts-ignore
    const date = dayjs.utc(dateString);
    //@ts-ignore
    return date.fromNow();
  };

  const getBanPeriod = (alert: Alert) => {
    const bannedUntil = dayjs(alert.message.details);
    const bannedDate = dayjs(alert.date_created);
    //@ts-ignore
    return bannedDate.from(bannedUntil, true);
  };

  const linkToUser = (id: string) => {
    navigate('/user/' + id);
  };

  const linkToComment = () => {
    navigate(`/stories/${alert.car_id}/${alert.item_id}#comments`);
  };
  const linkToReply = () => {
    navigate(`/stories/${alert.car_id}/${alert.item_id}#comments`);
  };
  const linkToStory = () => {
    navigate(`/stories/${alert.car_id}/${alert.item_id}`);
  };
  const linkToReminder = () => {
    navigate(`/reminders/${alert.car_id}/${alert.item_id}/details`);
  };
  const linkToCar = (id: string) => {
    navigate(`/cars/${id}/edit`);
  };

  const transformAlertDetailsCorrected = (details: string) => {
    const arrayString = details.replaceAll("'", '"');
    const array = JSON.parse(arrayString);
    let correctedSting = '';
    array.map((info: string) => (correctedSting += t(pcKey(info)) + ', '));
    correctedSting = correctedSting.substring(0, correctedSting.length - 2);
    return correctedSting;
  };

  return (
    <div>
      <div className={toast ? classes.wrapperToast : classes.wrapper}>
        <div className={classes.imageWrapper}>
          {(alert.message.type === 'is_comment_banned' ||
            alert.message.type === 'is_story_banned' ||
            alert.message.type === 'is_profile_banned' ||
            alert.message.type === 'is_car_banned') && (
            <div className={classes.backBan}>
              <Block className={classes.blockIcon} />
            </div>
          )}
          {(alert.message.type === 'warning' ||
            alert.message.type === 'is_profile_corrected' ||
            alert.message.type === 'is_car_corrected') && (
            <div className={classes.backBan}>
              <Report className={classes.blockIcon} />
            </div>
          )}
          {(alert.message.type === 'followed' ||
            alert.message.type === 'story_got_liked' ||
            alert.message.type === 'comment_got_liked' ||
            alert.message.type === 'comment_got_commented' ||
            alert.message.type === 'story_got_commented' ||
            alert.message.type === 'tagged_in_comment') && (
            <div className={clsx(classes.backUser, classes.pointer)} onClick={() => linkToUser(alert.initiator_id)}>
              {alert.initiator_image ? (
                <div
                  className={classes.alertImageInitiator}
                  style={{ backgroundImage: `url(${alert.initiator_image})` }}
                ></div>
              ) : (
                '😎'
              )}
            </div>
          )}
          {alert.message.type === 'weekly_story' && (
            <div className={clsx(classes.backUser, classes.pointer)} onClick={() => linkToStory()}>
              <img className={classes.alertImageInitiator} src={weekly_post} alt='Weekly Story' />
            </div>
          )}
          {alert.message.type === 'system_story' && (
            <div className={clsx(classes.backUser, classes.pointer)} onClick={() => linkToUser(alert.initiator_id)}>
              {alert.initiator_image ? (
                <div
                  className={classes.alertImageInitiator}
                  style={{ backgroundImage: `url(${alert.initiator_image})` }}
                ></div>
              ) : (
                '😎'
              )}
            </div>
          )}
          {alert.message.type === 'reminder' && (
            <div className={clsx(classes.backUser, classes.pointer)} onClick={() => linkToReminder()}>
              <img className={classes.alertImageInitiator} src={ReminderBell} alt='Reminder' />
            </div>
          )}
          {(alert.message.type === 'story_got_disliked' || alert.message.type === 'comment_got_disliked') && (
            <div className={classes.backUser}>😎</div>
          )}
        </div>
        <div className={classes.content}>
          <div className={classes.title}>
            {(alert.message.type === 'is_comment_banned' ||
              alert.message.type === 'is_story_banned' ||
              alert.message.type === 'is_profile_banned' ||
              alert.message.type === 'is_car_banned') &&
              t(tKey('ban'))}
            {(alert.message.type === 'warning' ||
              alert.message.type === 'is_profile_corrected' ||
              alert.message.type === 'is_car_corrected') &&
              t(tKey(`warning`))}
            {(alert.message.type === 'followed' ||
              alert.message.type === 'story_got_liked' ||
              alert.message.type === 'comment_got_liked' ||
              alert.message.type === 'comment_got_commented' ||
              alert.message.type === 'story_got_commented' ||
              alert.message.type === 'tagged_in_comment' ||
              alert.message.type === 'system_story') && (
              <div className={classes.userNameBox}>
                <span
                  className={clsx(classes.pointer, alert.is_patron && classes.usernamePatron)}
                  onClick={() => linkToUser(alert.initiator_id)}
                >
                  {alert.initiator_username}
                </span>
                {alert.is_patron && (
                  <span className={classes.patronBox}>
                    <PatronMedium />
                  </span>
                )}
              </div>
            )}
            {alert.message.type === 'story_got_disliked' && t(tKey('someone'))}
            {alert.message.type === 'comment_got_disliked' && t(tKey('someone'))}
            {alert.message.type === 'weekly_story' && t(tKey('weekly_story_wow'))}
            {alert.message.type === 'reminder' && t(tKey('reminder_oh'))}
          </div>
          <div className={classes.contentText}>
            {(alert.message.type === 'is_comment_banned' || alert.message.type === 'is_story_banned') && (
              <span>
                {alert.message.type === 'is_story_banned' && t(tKey(`you_banned_story`))}
                {alert.message.type === 'is_comment_banned' && t(tKey(`you_banned_comment`))}

                {'\u00A0'}
                <span className={classes.banPeriodText}>
                  {t(tKey('you_banned_period_pretext'))}
                  {'\u00A0'}
                  {getBanPeriod(alert)}.
                </span>
                {'\u00A0'}
                {alert.message.type === 'is_story_banned' && (
                  <>
                    {t(tKey(`you_banned_reason_story`))}
                    {'\u00A0'}"{alert.message.target_id}"{'\u00A0'}
                  </>
                )}
                {alert.message.type === 'is_comment_banned' && (
                  <>
                    {t(tKey(`you_banned_reason_comment`))}
                    {'\u00A0'}"{alert.message.target_id}"{'\u00A0'}
                  </>
                )}

                {t(tKey(`you_banned_rules`))}
              </span>
            )}
            {alert.message.type === 'is_profile_banned' && (
              <>
                {t(tKey(`you_banned_profile`))}
                {'\u00A0'}
                <span className={classes.banPeriodText}>
                  {t(tKey('you_banned_period_pretext'))}
                  {'\u00A0'}
                  {getBanPeriod(alert)}.
                </span>
                {'\u00A0'}
                {t(tKey(`you_banned_check_policy`))}
              </>
            )}
            {alert.message.type === 'is_profile_corrected' && (
              <span>
                {t(tKey('profile_violated'))}
                {'\u00A0'}
                {transformAlertDetailsCorrected(alert.message.details)}
                {'\u00A0'}
                {t(tKey('remove_profile_elements'))}
              </span>
            )}
            {alert.message.type === 'weekly_story' && (
              <span onClick={() => linkToStory()}>
                {t(tKey('weekly_story'))}
                <span className={classes.pointer}>'{alert.message.target_id}':</span>
              </span>
            )}
            {alert.message.type === 'system_story' && (
              <span onClick={() => linkToStory()}>
                {t(tKey('system_story'))}
                <span className={classes.pointer}>'{alert.message.target_id}':</span>
              </span>
            )}
            {alert.message.type === 'reminder' && (
              <span onClick={() => linkToReminder()}>
                {t('reminder_for_popup')}
                <span className={classes.pointer}>'{alert.message.target_id}':</span>
              </span>
            )}
            {alert.message.type === 'is_car_corrected' && (
              <span>
                {t(tKey('vehicle_corrected_1'))}
                {'\u00A0'}
                <span className={classes.pointer} onClick={() => linkToCar(alert.message.target_id)}>
                  '{alert.message.details}'
                </span>
                {'\u00A0'}
                {t(tKey('vehicle_corrected_2'))}
              </span>
            )}
            {alert.message.type === 'is_car_banned' && (
              <span>{t(tKey('car_banned'), { car: alert.message.target_id, period: getBanPeriod(alert) })}</span>
            )}
            {alert.message.type === 'warning' && (
              <span>
                {alert.message.details === 'story' && t(tKey('type_story'))}
                {alert.message.details === 'comment' && t(tKey('type_comment'))}
                <b
                  onClick={() => (alert.message.details === 'story' ? linkToStory() : linkToComment())}
                  className={classes.pointer}
                >
                  {'\u00A0' + alert.message.target_id + '\u00A0'}
                </b>
                {t(tKey(`warning_text`))}
              </span>
            )}
            {alert.message.type === 'followed' && t(tKey(`started_following`))}
            {alert.message.type === 'story_got_commented' && (
              <span>
                {t(tKey('commented'))}
                {'\u00A0'}
                <span className={classes.pointer} onClick={() => linkToStory()}>
                  '{alert.message.target_id}':
                </span>
                {'\u00A0'}
                <span className={classes.pointer} onClick={() => linkToComment()}>
                  {alert.message.details}
                </span>
              </span>
            )}
            {alert.message.type === 'comment_got_commented' && (
              <span>
                {t(tKey('replied_comment'))}
                {'\u00A0'}
                <span className={classes.pointer} onClick={() => linkToStory()}>
                  '{alert.message.target_id}'
                </span>
                :{'\u00A0'}
                <span className={classes.pointer} onClick={() => linkToReply()}>
                  {alert.message.details}
                </span>
              </span>
            )}
            {alert.message.type === 'tagged_in_comment' && (
              <span>
                {t(tKey('tagged_in_comment'))}
                {'\u00A0'}
                <span className={classes.pointer} onClick={() => linkToStory()}>
                  '{alert.message.target_id}'
                </span>
                :{'\u00A0'}
                <span className={classes.pointer} onClick={() => linkToReply()}>
                  {alert.message.details}
                </span>
              </span>
            )}
            {alert.message.type === 'story_got_liked' && (
              <span>
                {t(tKey(`liked_story`))}
                {'\u00A0'}
                <span className={classes.pointer} onClick={() => linkToStory()}>
                  '{alert.message.target_id}'
                </span>
              </span>
            )}
            {alert.message.type === 'comment_got_liked' && (
              <span>
                {t(tKey(`liked_comment`))}:{'\u00A0'}
                <span className={classes.pointer} onClick={() => linkToComment()}>
                  {alert.message.details}
                </span>
              </span>
            )}
            {alert.message.type === 'story_got_disliked' && (
              <span>
                {t(tKey('dislike_story'))}
                {'\u00A0'}
                <span className={classes.pointer} onClick={() => linkToStory()}>
                  '{alert.message.target_id}'
                </span>
              </span>
            )}
            {alert.message.type === 'comment_got_disliked' && (
              <span>
                {t(tKey('dislike_comment'))}:{'\u00A0'}
                <span className={classes.pointer} onClick={() => linkToComment()}>
                  {alert.message.details}
                </span>
              </span>
            )}
            <span className={classes.timeAgo}>
              {'\u00A0\u00A0'}
              {getTimeAgoString(alert.date_created)}
            </span>
          </div>
        </div>
        <div>
          {alert.message.type === 'followed' && (
            <FollowButton userId={alert.initiator_id} followed={alert.message.already_following || false} />
          )}
          {!toast && alert.item_image && (
            <div
              className={clsx(classes.itemImage, classes.pointer)}
              style={{ backgroundImage: `url(${alert.item_image})` }}
              onClick={() => linkToStory()}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};
