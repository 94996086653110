import { Box, Button, CircularProgress, Theme, Typography } from '@mui/material';
import { animated } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { getTKey } from 'utils';

import { StyledButton } from 'components/StyledButton/StyledButton';
import { ComponentType, useEffect, useState } from 'react';
import { theme } from 'theme';
import { useClasses } from 'utils/hooks/useClasses';
import { imagesMD } from './SlideImages';

type DemoSlidesProps = {
  handleClose: () => void;
  handleFinish: () => void;
};

const tKey = getTKey('demo_slides');

const styles = ({ breakpoints, spacing }: Theme) => ({
  container: {
    backgroundColor: theme.palette.grey[800],
    width: 792,
    height: 624,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(0, 5.5, 2),
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [breakpoints.down('sm')]: {
      padding: spacing(2, 0, 6),
      paddingTop: `calc(${spacing(2)} + env(safe-area-inset-top))`,
      backgroundColor: '#000000',
      height: '100vh',
    },
    position: 'relative',
  },
  skipContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    [breakpoints.down('sm')]: {
      width: '100vw',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: spacing(0, 2),
    },
  },
  skip: {
    fontSize: 14,
    color: '#03DAC5',
    margin: spacing(2, 0, 1),
    [breakpoints.down('sm')]: {
      margin: spacing(1),
    },
    textTransform: 'none',
  },
  imageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    width: 704,
    height: 360,
    touchAction: 'none',
    [breakpoints.down('sm')]: {
      height: 360,
      width: '100%',
    },
  },
  image: {
    borderRadius: 5,
    borderWidth: 0,
    borderColor: 'white',
    borderStyle: 'solid',
    height: 359,
    objectFit: 'cover',
    [breakpoints.down('sm')]: {
      height: 350,
    },
  },
  help: {
    padding: spacing(2, 0, 1),
    textAlign: 'center',
    height: 200,
    lineHeight: '19px',
    letterSpacing: '0.4px',
    [breakpoints.down('sm')]: {
      maxWidth: '100vw',
      fontSize: 16,
      padding: spacing(1),
    },
  },
  dots: {
    display: 'flex',
  },
  dot: {
    width: 15,
    height: 15,
    borderRadius: 999,
    marginRight: spacing(1),
    [breakpoints.down('sm')]: {
      width: 10,
      height: 10,
    },
  },
  inactiveDot: {
    backgroundColor: 'rgba(187, 134, 252, 0.3)',
  },
  activeDot: {
    backgroundColor: 'rgba(187, 134, 252, 1)',
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90%',
    [breakpoints.down('sm')]: {
      justifyContent: 'center',
      flexDirection: 'column',
      bottom: spacing(8),
    },
    position: 'absolute',
    bottom: 25,
  },
  next: {
    [breakpoints.down('sm')]: {
      margin: spacing(2, 0, 1),
    },
  },
  swipe: {
    fontSize: 12,
    margin: spacing(1),
    color: 'rgba(255, 255, 255, 0.38)',
    [breakpoints.up('sm')]: {
      display: 'none',
    },
  },
});

export const DemoSlides: ComponentType<DemoSlidesProps> = ({ handleClose, handleFinish }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const length = imagesMD.length;
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 250);
  }, [index]);

  const handleNext = ({ cancel }: { cancel?: () => void }) => {
    if (index === length - 1) {
      handleFinish();
      return;
    }
    setIndex((index + 1) % length);
    if (cancel) cancel();
  };

  const handlePrevious = ({ cancel }: { cancel?: () => void }) => {
    if ((index - 1) % length >= 0) setIndex((index - 1) % length);
    else {
      setIndex(length - 1);
    }
    if (cancel) cancel();
  };

  const bind = useDrag(({ active, movement: [mx], direction: [xDir], cancel }) => {
    if (!active) {
      cancel();
    } else {
      if (xDir < 0) {
        handleNext({ cancel });
      }
      if (xDir > 0) {
        handlePrevious({ cancel });
      }
    }
  });
  return (
    <Box>
      <Box className={classes.container}>
        <div id='skipped' className={classes.skipContainer}>
          <Button onClick={handleClose}>
            <Typography className={classes.skip}>{t(tKey('skip'))}</Typography>
          </Button>
          <Typography sx={{ display: { md: 'none', xs: 'block' } }} className={classes.swipe}>
            {t(tKey('swipe'))}
          </Typography>
        </div>

        {loading ? (
          <div className={classes.imageContainer}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Box component={'div'} className={classes.imageContainer} sx={{ display: { md: 'flex', xs: 'none' } }}>
              <img src={imagesMD[index].src} alt={imagesMD[index].key} className={classes.image} loading='eager' />
            </Box>
            <Box sx={{ display: { md: 'none', xs: 'block' } }}>
              <animated.div className={classes.imageContainer} {...bind()}>
                <img src={imagesMD[index].src} alt={imagesMD[index].key} className={classes.image} loading='eager' />
              </animated.div>
            </Box>
            <Typography className={classes.help}>{t(tKey(imagesMD[index].key))}</Typography>
          </>
        )}
        <div className={classes.pagination}>
          <div className={classes.dots}>
            {imagesMD.map((_, i) => {
              return (
                <div
                  key={i + _.key}
                  onClick={() => setIndex(i)}
                  className={clsx(classes.dot, index === i ? classes.activeDot : classes.inactiveDot)}
                ></div>
              );
            })}
          </div>
          {index !== length - 1 ? (
            <div className={classes.next}>
              <StyledButton label={t(tKey('next'))} onClick={handleNext} />
            </div>
          ) : (
            <div className={classes.next}>
              <StyledButton label={t(tKey('next'))} onClick={handleFinish} />
            </div>
          )}
        </div>
      </Box>
    </Box>
  );
};
