import { InnerMobileHeader, PageLoader } from 'components';
import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useProfileSettingsQuery } from 'services';
import { SigninForm } from '../../../components/v2/Settings/SigninForm';
import store from 'store2';

export const SigninTab: ComponentType = () => {
  const { t } = useTranslation();
  const { data, isFetching } = useProfileSettingsQuery();

  useEffect(() => {
    //@ts-ignore
    gtag?.('event', 'visited_settings', { method: 'visited_settings' });
  }, []);

  useEffect(() => {
    if (!isFetching && data) {
      if (data?.is_google_login_used === false) {
        store.remove('driverbook_google_user');
      }
    }
  }, [data, isFetching]);

  return (
    <>
      <InnerMobileHeader title={t('settings_signin_title')} showBack />
      {isFetching && <PageLoader />}
      {!isFetching && data && <SigninForm preloadedValues={data} />}
    </>
  );
};
