import { isArray } from 'lodash';
import { ComponentType } from 'react';
import { useParams } from 'react-router-dom';
import { Record } from 'types';

import { EmptyBox } from 'components';
import { usePublicCarCurrencyQuery, usePublicCarVolumeQuery } from 'services';
import { countTotalMoneySpend } from 'utils';
import { RecordItem } from './RecordItem';
import { RecordsGroup } from './RecordsGroup';

interface PublicRecordsListProps {
  data: Record[];
  mileageMetric: string | undefined;
  openGroupId: string;
  filterCategory: string;
  handleOpenGroup: (id: string) => void;
  handleCloseGroup: () => void;
}

export const PublicRecordList: ComponentType<PublicRecordsListProps> = ({
  data,
  mileageMetric,
  openGroupId,
  filterCategory,
  handleCloseGroup,
  handleOpenGroup,
}) => {
  const { identifier } = useParams<{ identifier: string }>() as { identifier: string };
  const { data: currency, isLoading: currencyLoading } = usePublicCarCurrencyQuery(identifier);
  const { data: volume, isLoading: volumeLoading } = usePublicCarVolumeQuery(identifier);

  const totalExpenses = countTotalMoneySpend(data?.flat());
  return (
    <>
      {!currencyLoading && data?.length && !volumeLoading ? (
        <>
          {/* eslint-disable-next-line array-callback-return */}
          {data?.map((record: Record | Record[]) => {
            if (!isArray(record) && !openGroupId) {
              return (
                <RecordItem
                  key={record.id}
                  publicRecord
                  data={record}
                  mileageMetric={mileageMetric}
                  totalExpenses={totalExpenses}
                  currency={currency}
                  volume={volume}
                />
              );
            }

            if (isArray(record)) {
              const id = record[0].groupId;

              // @ts-ignore
              return (
                <RecordsGroup
                  key={id}
                  publicRecord
                  data={record as Record[]}
                  openGroupId={openGroupId}
                  mileageMetric={mileageMetric}
                  totalExpenses={totalExpenses}
                  filterCategory={filterCategory}
                  currency={currency}
                  volume={volume}
                  onOpen={handleOpenGroup}
                  onClose={handleCloseGroup}
                />
              );
            }
          })}
        </>
      ) : (
        <EmptyBox entityName='public-records' addDisabled />
      )}
    </>
  );
};
