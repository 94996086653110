import _ from 'lodash';
import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

export type TransferCarPayload = {
  id: string;
  new_owner_email: string;
  password?: string | undefined;
  access_token?: string;
  code?: string;
};

const transferCar = async ({ id, new_owner_email, password, access_token, code }: TransferCarPayload): Promise<any> => {
  const { data } = await api.post(
    `/cars/${id}/transfer`,
    _.omitBy(
      {
        new_owner_email,
        password,
        access_token,
        code
      },
      _.isEmpty
    )
  );
  return data;
};

export const useTransferCarMutation = () => {
  return useMutation(transferCar);
};
