import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ComponentType, Dispatch, createContext, useReducer } from 'react';

export interface StoryPublishAction {
  type: string;
  payload?: Partial<State>;
}

interface State {
  progress?: number;
  stage?: string;
}

interface StoryPublish {
  state: State;
  dispatch: Dispatch<StoryPublishAction>;
}

export const initialState: State = {
  progress: 0,
  stage: '',
};

const init = () => {
  return initialState;
};

const storySlice = createSlice<
  State,
  {
    setState: (state: State, action: PayloadAction<Partial<State>>) => void;
  }
>({
  name: 'storyPublish',
  initialState,
  reducers: {
    setState(state, action) {
      return { ...state, ...action.payload };
    },
  },
});

export const { setState } = storySlice.actions;

const StoryPublishReducer = storySlice.reducer;

export const StoryPublishContext = createContext({} as StoryPublish);

export const StoryPublishProvider: ComponentType = ({ children }) => {
  const [state, dispatch] = useReducer(StoryPublishReducer, initialState, init);

  return <StoryPublishContext.Provider value={{ state, dispatch }}>{children}</StoryPublishContext.Provider>;
};
