import { Button, Theme } from '@mui/material';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useConfirmEmailMutation } from 'services';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('confirm_email_banner');

const styles = ({ breakpoints, palette, spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: spacing(2),
    padding: spacing(1, 2, 0),
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.16)',

    '& .MuiButton-root': {
      marginLeft: 'auto',
      letterSpacing: 1.25,
      whiteSpace: 'nowrap',
    },

    [breakpoints.up('md')]: {
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: spacing(1, 2),
    },
  },
  text: {
    width: '100%',
    color: palette.text.hint,

    [breakpoints.up('md')]: {
      width: 'auto',
      paddingRight: spacing(1),
    },
  },
});

export const ConfirmEmailBanner: ComponentType<{ email: string }> = ({ email }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const [confirmEmailRequestSent, setConfirmEmailRequestSent] = useState<boolean>(false);
  const confirmEmailMutation = useConfirmEmailMutation();

  const confirmEmail = () => {
    setConfirmEmailRequestSent(true);
    confirmEmailMutation.mutate(
      { email },
      {
        onSuccess() {
          toast(t(tKey('snackbar')));
        },
      }
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.text}>{t(tKey('title'))}</div>
      <Button color='primary' onClick={confirmEmail} disabled={confirmEmailRequestSent}>
        {t(tKey('button'))}
      </Button>
    </div>
  );
};
