import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

export const trustUser = async (id: String): Promise<any> => {
  try {
    const { data } = await api.post(`/engineroom/users/${id}/trust`);
    return  data;
  } catch (err) {
    return { error: true };
  }
};

export const useTrustUserMutation = () => {
  return useMutation(trustUser)
};
