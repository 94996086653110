import { ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Theme,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { useInitialSettings } from 'lib';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'store2';

import { Currency, Record, UserSettings, Volume } from 'types';
import { currencySymbol, formatDate, getTKey, volumeMetric } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { RecordAttachmentGallery } from './RecordAttachmentGallery';
import AutoAwesome from 'components/CustomIcons/AutoAwesome';

const tKey = getTKey('record_item');

interface RecordItemProps {
  data: Record;
  mileageMetric: string | undefined;
  totalExpenses: number;
  publicRecord?: boolean;
  volume?: Volume;
  currency?: string;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  onCopy?: (id: string) => void;
}

const styles = ({ palette, spacing, breakpoints, transitions }: Theme) => ({
  root: {
    marginBottom: spacing(1),
    padding: spacing(1, 2),
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    border: '1px solid rgba(255, 255, 255, 0.08)',
    borderRadius: spacing(0.5),

    [breakpoints.up('md')]: {
      padding: spacing(1, 0.5, 1, 2),
    },
  },
  rootExpanded: {
    backgroundColor: 'rgba(187, 134, 252, 0.1)',
    borderColor: palette.additionalPrimary['200'],
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
    [breakpoints.up('md')]: {
      flexWrap: 'nowrap',

      '& > div': {
        marginRight: spacing(2),
      },
    },
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 10,
    letterSpacing: 1.5,
    color: 'rgba(255, 255, 255, 0.6)',

    [breakpoints.up('md')]: {
      display: 'block',
    },
  },
  dot: {
    [breakpoints.up('md')]: {
      display: 'none',
    },
    display: 'inline-block',
    margin: spacing(0, 0.5),
    fontSize: 16,
    color: palette.additionalPrimary['200'],
  },
  title: {
    width: '100%',
    marginTop: spacing(1),
    fontSize: 20,
    fontWeight: 500,
    color: 'rgba(255, 255, 255, 0.6)',
    letterSpacing: 1.5,

    [breakpoints.up('md')]: {
      textOverflow: 'ellipsis',
      width: 180,
      maxHeight: 48,
      marginTop: 0,
      lineClamp: 2,
      overflow: 'hidden',
      fontSize: 'inherit',
      boxOrient: 'vertical',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
    },
  },
  subtitle: {
    marginBottom: spacing(0.5),
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.6)',
  },
  expenses: {
    order: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    margin: spacing(2, 0),

    [breakpoints.up('md')]: {
      order: 'unset',
      display: 'block',
      width: 'auto',
      margin: 0,
      marginLeft: 'auto',
    },
  },
  expensesLine: {
    width: 180,

    [breakpoints.up('md')]: {
      width: 120,
    },
  },
  expensesLineColor: {
    backgroundColor: palette.additionalSecondary['900'],
  },
  expensesLineBarColor: {
    backgroundColor: palette.additionalSecondary['200'],
  },
  cost: {
    order: 1,
    fontSize: 34,
    fontWeight: 500,

    [breakpoints.up('md')]: {
      width: 120,
      order: 'unset',
      fontSize: 24,
    },
  },
  chip: {
    order: 1,
    flexShrink: 1,
    maxWidth: 130,
    marginLeft: spacing(2),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: palette.additionalSecondary['800'],
    color: palette.text.primary,

    [breakpoints.up('md')]: {
      order: 'unset',
      maxWidth: 'unset',
      marginLeft: 'auto',
    },
    [breakpoints.down('xs')]: {
      maxWidth: 100,
    },
  },
  expandButton: {
    order: 3,
    marginLeft: 'auto',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: 'rotate(0deg)',
    transition: transitions.create('transform', {
      duration: transitions.duration.shortest,
    }),

    [breakpoints.up('md')]: {
      order: 'unset',
      marginLeft: 0,
    },
  },
  expandButtonOpen: {
    color: palette.primary.main,
    transform: 'rotate(180deg)',
  },
  expandButtonStub: {
    order: 3,
    marginLeft: 'auto',

    [breakpoints.up('md')]: {
      order: 'unset',
      width: 48,
      marginLeft: 0,
    },
  },
  divider: {
    margin: spacing(2, 0),
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
  },
  attachmentsRow: {
    marginTop: spacing(0.5),

    '& > div': {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
    },
  },
  details: {
    wordBreak: 'break-word',
    lineHeight: '24px',
    letterSpacing: '0.2px',
  },
  aiIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    [breakpoints.up('sm')]: {
      right: 40,
    },
  },
});

export const RecordItem: ComponentType<RecordItemProps> = ({
  data,
  mileageMetric,
  totalExpenses,
  publicRecord,
  currency,
  volume,
  onEdit,
  onDelete,
  onCopy,
}) => {
  useInitialSettings(publicRecord);
  const { t } = useTranslation();
  const settingsData = store('driverbook_settings') as UserSettings;
  const classes = useClasses(styles);
  const [expanded, setExpanded] = useState(false);
  const partOfExpenses = (Number(data.cost) / totalExpenses) * 100;
  const expandCondition = data.partName || data.details || data?.imageOne || (onEdit && onDelete);
  const handleToggleClick = () => {
    setExpanded(!expanded);
  };

  const fuelCategoryUnit: { [category: string]: Volume | 'kWh' } = {
    refueling: publicRecord ? (volume as Volume) : settingsData?.volume_metric,
    recharging: 'kWh',
  };

  const fuelCategory = (category: string) => category === 'refueling' || category === 'recharging';

  const getUnit = (plural?: boolean) =>
    t(tKey(volumeMetric[fuelCategoryUnit[data.category] as Volume] + (plural ? 's' : '')));

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootExpanded]: expanded,
      })}
    >
      <div className={classes.header} onClick={expandCondition ? handleToggleClick : undefined}>
        {data.aiType && data.aiType !== 'None' && <AutoAwesome className={classes.aiIcon} />}
        <div className={classes.info}>
          {formatDate(data.datePerformed)}
          <span className={classes.dot}>·</span>
          <div>
            {data.mileage}
            {` ${mileageMetric?.toUpperCase()}`}
          </div>
        </div>
        <div className={classes.title}>
          {fuelCategory(data.category) && `${getUnit(true)}: `}
          {data.title}
        </div>
        <div className={classes.cost}>
          {data.cost}
          {currencySymbol[(publicRecord ? currency : settingsData?.currency) as Currency]}
        </div>
        <Chip label={t(`__record_category__${data.category}`)} className={classes.chip} />
        <div className={classes.expenses}>
          <div className={classes.subtitle}>{t(tKey('part_of_expenses'))}</div>
          <div className={classes.expensesLine}>
            <LinearProgress
              value={partOfExpenses}
              variant='determinate'
              color='secondary'
              classes={{
                colorSecondary: classes.expensesLineColor,
                barColorSecondary: classes.expensesLineBarColor,
              }}
            />
          </div>
        </div>
        {expandCondition ? (
          <IconButton
            aria-expanded={expanded}
            aria-label='show more'
            className={clsx(classes.expandButton, {
              [classes.expandButtonOpen]: expanded,
            })}
            size='large'
          >
            <ExpandMore />
          </IconButton>
        ) : (
          <span className={classes.expandButtonStub} />
        )}
      </div>
      <Collapse in={expanded} timeout='auto' mountOnEnter>
        <Divider className={classes.divider} />
        <Grid container spacing={3}>
          {!fuelCategory(data.category) && data.partName && (
            <Grid item xs={12} md={3}>
              <div className={classes.subtitle}>{t(tKey('item_name'))}:</div>
              <Typography variant='body1'>{data.partName}</Typography>
            </Grid>
          )}
          {fuelCategory(data.category) ? (
            <>
              {data.details && (
                <Grid item xs={3} md={3}>
                  <div className={classes.subtitle}>{t(tKey('cost_per'), { unit: getUnit() })}:</div>
                  <Typography variant='body1' className={classes.details}>
                    {data.details}
                  </Typography>
                </Grid>
              )}
              {data.partName && (
                <Grid item xs={3} md={3}>
                  <div className={classes.subtitle}>{t(tKey('fuel_type'))}:</div>
                  <Typography variant='body1' className={classes.details}>
                    {data.partName}
                  </Typography>
                </Grid>
              )}
            </>
          ) : (
            data.details && (
              <Grid item xs={12} md={5}>
                <div className={classes.subtitle}>{t(tKey('details'))}:</div>
                <Typography variant='body1' className={classes.details}>
                  {data.details}
                </Typography>
              </Grid>
            )
          )}
          {data?.imageOne && (
            <Grid item xs={12} md={4}>
              <div className={classes.subtitle}>{t(tKey('attachment'))}:</div>
              <div className={classes.attachmentsRow}>
                <RecordAttachmentGallery
                  images={[data?.imageOne, data?.imageTwo, data?.imageThree, data?.imageFour, data?.imageFive]}
                />
              </div>
            </Grid>
          )}
          <Grid item xs={12} md={5}>
            <div className={classes.subtitle}>{t(tKey('date_created'))}:</div>
            <Typography variant='body1' className={classes.details}>
              {data.dateCreated}
            </Typography>
          </Grid>
        </Grid>
        {!publicRecord && onEdit && onDelete && (
          <Box display='flex' justifyContent='flex-end' mt={3}>
            <Button onClick={onCopy ? () => onCopy(data.id) : () => {}} color='primary'>
              {t(tKey('copy'))}
            </Button>
            <Button onClick={() => onEdit(data.id)} color='primary'>
              {t(tKey('edit'))}
            </Button>
            <Button onClick={() => onDelete(data.id)} color='primary'>
              {t(tKey('delete'))}
            </Button>
          </Box>
        )}
      </Collapse>
    </div>
  );
};
