import { useEffect, useState } from 'react';

import { useCarMakesQuery } from 'services';
import { CarMake, CarOption, MappedCarMakeGroup } from 'types';

type mapCarMakesType = (data: CarMake[]) => MappedCarMakeGroup[];
const mapCarMakes: mapCarMakesType = (data) => {
  const cars: MappedCarMakeGroup = {
    label: 'Cars',
    options: [],
  };
  const trucks: MappedCarMakeGroup = {
    label: 'Trucks',
    options: [],
  };
  const moto: MappedCarMakeGroup = {
    label: 'Moto',
    options: [],
  };
  const carsMoto: MappedCarMakeGroup = {
    label: 'Cars, Moto',
    options: [],
  };
  const carsTrucks: MappedCarMakeGroup = {
    label: 'Cars, Trucks',
    options: [],
  };
  const carsTrucksMoto: MappedCarMakeGroup = {
    label: 'Cars, Trucks, Moto',
    options: [],
  };
  const trucksMoto: MappedCarMakeGroup = {
    label: 'Trucks, Moto',
    options: [],
  };

  data.forEach((item) => {
    if (item.type.length === 1) {
      if (item.type[0] === 'cars') {
        cars.options.push({ label: item.name, value: item.name });
      }

      if (item.type[0] === 'trucks') {
        trucks.options.push({ label: item.name, value: item.name });
      }

      if (item.type[0] === 'moto') {
        moto.options.push({ label: item.name, value: item.name });
      }
    }
    if (item.type.length === 2) {
      if (item.type.includes('cars') && item.type.includes('moto')) {
        carsMoto.options.push({ label: item.name, value: item.name });
      }

      if (item.type.includes('cars') && item.type.includes('trucks')) {
        carsTrucks.options.push({ label: item.name, value: item.name });
      }

      if (item.type.includes('trucks') && item.type.includes('moto')) {
        trucksMoto.options.push({ label: item.name, value: item.name });
      }
    }
    if (item.type.length === 3) {
      carsTrucksMoto.options.push({ label: item.name, value: item.name });
    }
  });

  return [cars, trucks, moto, carsMoto, carsTrucks, trucksMoto, carsTrucksMoto];
};

type useCarMakesType = () => { makes: MappedCarMakeGroup[]; makesLoading: boolean };

export const searchInMakes = (makes: MappedCarMakeGroup[], name: string): CarOption | undefined => {
  const combinedOptions: CarOption[] = makes.reduce(
    (summary, current) => [].concat(summary, current.options as any),
    []
  );

  return combinedOptions.find((option) => option.value.toLowerCase() === name?.toLowerCase());
};

export const useCarMakesData: useCarMakesType = () => {
  const [makes, setCarMakes] = useState<MappedCarMakeGroup[]>([]);
  const { data: carMakes, isFetching: makesLoading } = useCarMakesQuery();

  useEffect(() => {
    if (!makesLoading && carMakes?.length) {
      setCarMakes(mapCarMakes(carMakes));
    }
  }, [carMakes, makesLoading]);

  return {
    makes,
    makesLoading,
  };
};
