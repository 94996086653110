import { AxiosError } from 'axios';
import { UseMutationOptions } from 'react-query';

import { queryClient } from 'components';

import { Story } from 'components/Stories/AddStoryContent';
import { UpdateStoryDraftPayload } from './hooks/useUpdateStoryDraft';

export const storyDraftMutationOptions: UseMutationOptions<Story[], AxiosError, UpdateStoryDraftPayload> = {
  onSuccess: () => {
    queryClient.invalidateQueries('stories');
  },
};
