import axios, { AxiosError, AxiosResponse } from 'axios';
import { createBrowserHistory } from 'history';
import store from 'store2';
import secureLocalStorage from 'react-secure-storage';

export const history = createBrowserHistory();

export const isLoggedIn = (): boolean => Boolean(store.get('accessToken'));

export const isAppId = (): boolean =>
  Boolean(
    store.get('accessToken') &&
      secureLocalStorage.getItem('app_id_hash') === process.env.REACT_APP_FIREBASE_APPSTORAGEID
  );

const responseSuccessHandler = (response: AxiosResponse) => {
  return response;
};

const responseErrorHandler = (error: AxiosError | Error | any) => {
  if (axios.isAxiosError(error)) {
    if (
      error.response?.status === 401 &&
      (error?.response?.data?.message === 'Token has expired' ||
        error?.response?.data?.message === 'Token has been revoked or invalid')
    ) {
      store.remove('accessToken');
      store.remove('refreshToken');
      history.push('/login');
      window.location.reload();
    }
  }

  return Promise.reject(error);
};

export const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  responseType: 'json',
});

axiosApiInstance.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

axiosApiInstance.interceptors.request.use(
  (config) => {
    const token = store('accessToken');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.Accept = 'application/json';
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const axiosWithFileInstance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  responseType: 'json',
  headers: { 'Content-Type': 'multipart/form-data' },
});

axiosWithFileInstance.interceptors.request.use(
  (config) => {
    const token = store('accessToken');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.Accept = 'application/json';
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
