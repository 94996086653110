import { Theme } from '@mui/material';
import { ContentLoader } from 'components/ContentLoader/ContentLoader';
import { useInitialLanguage, useInitialSettings } from 'lib';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClasses } from 'utils/hooks/useClasses';
import { AdminPageWrapper } from './components/AdminPageWrapper';
import { ContentList } from './components/ContentList';
import { EmptyData } from './components/EmptyData';
import { FilterSortItemsBar } from './components/FilterSortItemsBar';
import { PaginationBar } from './components/PaginationBar';
import { AdminFilteredStories, filtersType, paginationType } from './hooks/useAdminFilteredStories';

const styles = ({ palette, spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: '100%',
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
  },
  space: {
    flex: 1,
  },
  stories: {
    flex: 1,
  },
  pagination: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export const AdminStoriesPage: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);

  useInitialSettings();
  useInitialLanguage();

  const [filters, setFilters] = useState<filtersType>({
    status: 'in_review',
    date: 'oldest',
    reportsOrder: 'desc',
  });

  const [pagination, setPagination] = useState<paginationType>({
    page: 1,
    perPage: 20,
  });

  const { data, isFetching, refetch } = AdminFilteredStories(filters, pagination);

  const onChangeFilter = (name: string, filter: any) => {
    setFilters({ ...filters, ...{ [name]: filter } });
    setPagination({ ...pagination, ...{ page: 1 } });
  };

  useEffect(() => {
    refetch();
  }, [pagination, filters, refetch]);
  return (
    <AdminPageWrapper>
      <div className={classes.root}>
        <FilterSortItemsBar
          onChangeDate={(date) => {
            onChangeFilter('date', date);
          }}
          onChangeOrderOfReports={(reportsOrder) => {
            onChangeFilter('reports_order', reportsOrder);
          }}
          onChangeStatus={(status) => {
            onChangeFilter('status', status);
          }}
        />
        {isFetching ? (
          <ContentLoader />
        ) : data?.stories.length > 0 ? (
          <div className={classes.stories}>
            {!isFetching && <ContentList items={data?.stories} refreshItems={refetch} />}
          </div>
        ) : (
          <EmptyData title={t('admin_stories_empty_title')} />
        )}
        <PaginationBar
          changeCurrentPage={(page) => {
            setPagination({ page: page, perPage: pagination.perPage });
          }}
          changePerPage={(perPage) => {
            setPagination({ page: pagination.page, perPage: perPage });
          }}
          currentPage={pagination.page}
          pages={data?.pages}
          total={data?.total}
        />
      </div>
    </AdminPageWrapper>
  );
};
