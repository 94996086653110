import { CircularProgress } from '@mui/material';
import { ComponentType } from 'react';

import { useClasses } from 'utils/hooks/useClasses';

const styles = {
  container: {
    position: 'flex',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#212121',
  },
};

export const ContentLoader: ComponentType = () => {
  const classes = useClasses(styles);

  return (
    <div className={classes.container}>
      <CircularProgress />
    </div>
  );
};
