import { useStoryViewMutation } from 'services/stories';
import { StoryPayload } from '../StoriesTypes';

type useViewStoryType = () => {
  viewStory: (payload: StoryPayload) => any;
};

export const useViewStory: useViewStoryType = () => {
  const mutation = useStoryViewMutation();

  const viewStory = (payload: StoryPayload) => {
    return new Promise((resolve) => {
      mutation.mutate(payload, {
        onSuccess(data, variables, context) {
          resolve(data);
        },
      });
    });
  };

  return {
    viewStory,
  };
};
