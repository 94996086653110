import { ArrowForward } from '@mui/icons-material';
import { Box, Divider, Theme, Typography } from '@mui/material';
import { CommonLayout, InnerMobileHeader } from 'components';
import { documentItemsNativeApp } from 'components/SettingsMenu/menuItems';
import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  subHeading: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 10,
  },
  menuItem: {
    fontSize: 16,
    fontWeight: '500',
  },
  menuContainer: {
    display: 'flex',
    color: 'rgba(255, 255, 255, 0.87)',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: spacing(2, 0),
    cursor: 'pointer',
  },
  divider: {
    margin: spacing(2, 0),
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
  },
  link: {
    textDecorationLine: 'none',
  },
});

export const Documents: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);

  useEffect(() => {
    //@ts-ignore
    gtag?.('event', 'visited_settings', { method: 'visited_settings' });
  }, []);

  return (
    <>
      <CommonLayout>
        <InnerMobileHeader title={t('documents')} showBack />
        {documentItemsNativeApp.map((item) => (
          <NavLink className={classes.link} to={item.to} key={item.to}>
            <Box className={classes.menuContainer}>
              <Typography className={classes.menuItem}>{t(item.label)}</Typography>
              <ArrowForward width={16} height={16} />
            </Box>
            <Divider className={classes.divider} />
          </NavLink>
        ))}
      </CommonLayout>
    </>
  );
};
