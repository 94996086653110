import { Box, Grid, Theme } from '@mui/material';
import getOr from 'lodash/fp/getOr';
import { ComponentType, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import store from 'store2';

import { Form, ImageUploaderMultiple, PageLoader, StyledButton, TextInput } from 'components';
import { recordFormValidationSchema } from 'features/records/components/RecordFuelForm';
import { useRecordFormImages } from 'features/records/hooks/useRecordFormImages';
import { useAssistedCarQuery } from 'services';
import { NewRecordValues, Record, Volume } from 'types';
import { buildRecordFormImages, getTKey, volumeMetric } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { DatePickerSelect } from 'components/Form/DatePickerSelect';
import dayjs from 'dayjs';

const tKey = getTKey('record_form');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  column: {
    position: 'absolute',
    top: -15,
    right: 0,
  },
  refuel: {
    display: 'flex',
  },
  select: {
    padding: spacing(1, 1),
  },
});

const FormStateUpdater: ComponentType<{
  recordId: string;
  defaultValues: Record | undefined;
  localMileage: number;
}> = ({ recordId, defaultValues, localMileage }) => {
  const { setValue, watch } = useFormContext();

  const [reset, setReset] = useState(false);
  useEffect(() => {
    setReset(true);
  }, [watch('category')]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (defaultValues && defaultValues.id === recordId) {
      setValue('title', defaultValues.title);
      setValue('partName', defaultValues.partName);
      setValue('category', defaultValues.category);
      setValue('cost', defaultValues.cost);
      setValue('details', defaultValues.details);
      setValue('mileage', defaultValues.mileage);
    }
  }, [recordId, defaultValues, reset]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!defaultValues && localMileage) {
      setValue('mileage', localMileage);
    }
  }, [defaultValues, localMileage]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

interface AssistedRecordFuelFormProps {
  onSubmit: (values: any) => void;
  defaultValues?: Record;
  editMode?: boolean;
  isSubmitting?: boolean;
  category: string;
}

const createDefaultValues = (mileage: number, defaultCategory: string, values?: any) => ({
  title: getOr('', 'title', values),
  partName: getOr('', 'latestFuelType', values),
  category: getOr(defaultCategory, 'category', values),
  cost: getOr('', 'cost', values),
  details: getOr('', 'details', values),
  mileage: mileage ? mileage : values?.mileage ? values?.mileage : '',
  datePerformed:
    values?.datePerformed === 'None'
      ? null
      : values && dayjs(values.datePerformed).isValid()
      ? dayjs(values.datePerformed)
      : dayjs(),
});

export const AssistedRecordFuelForm: ComponentType<AssistedRecordFuelFormProps> = ({
  onSubmit,
  defaultValues,
  editMode,
  isSubmitting,
  category,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const localStorageMileage = store('driverbook_draftMileage');
  const { identifier, recordId } = useParams<{ identifier: string; recordId: string }>() as {
    identifier: string;
    recordId: string;
  };
  const [localMileage, setLocalMileage] = useState<number>(0);
  const { data: carData, isLoading: carDataLoading } = useAssistedCarQuery(identifier);
  const { images, onImageChange } = useRecordFormImages(defaultValues);

  useEffect(() => {
    if (!editMode && !carDataLoading && carData && localStorageMileage) {
      if (localStorageMileage.carId === carData.id) {
        setLocalMileage(localStorageMileage.mileage);
      } else {
        store.remove('driverbook_draftMileage');
      }
    }
  }, [carDataLoading, carData, localStorageMileage, editMode]);

  const handleSubmit = async (data: NewRecordValues) => {
    const fd = new FormData();

    await buildRecordFormImages(fd, images, defaultValues);

    fd.append('category', category);
    fd.append('cost', data.cost);
    fd.append('details', data.details);
    fd.append('mileage', data.mileage.toString());
    fd.append('part_name', data.partName);
    fd.append('title', data.title);
    fd.append('date_performed', dayjs(data.datePerformed).format('YYYY-MM-DD HH:mm:ss'));
    fd.append('identifier', identifier);

    if (!localStorageMileage) {
      store('driverbook_draftMileage', { carId: carData.id, mileage: data.mileage });
    }

    onSubmit(fd);
  };

  const fuelCategoryUnit: { [category: string]: Volume | 'kWh' } = {
    refueling: carData?.volumeMetric,
    recharging: 'kWh',
  };

  const getUnit = (category: string, plural?: boolean, prefix = '') =>
    t(tKey(prefix + volumeMetric[fuelCategoryUnit[category] as Volume] + (plural ? 's' : '')));

  return carDataLoading ? (
    <PageLoader />
  ) : (
    <Form
      onSubmit={handleSubmit}
      schema={recordFormValidationSchema(t)}
      defaultValues={createDefaultValues(localMileage, 'refueling', {
        ...defaultValues,
        latestFuelType: carData?.latestFuelType,
      })}
      mode='onChange'
    >
      <FormStateUpdater recordId={recordId} defaultValues={defaultValues} localMileage={localMileage} />
      <Grid container spacing={2} className={classes.row}>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextInput name='cost' label={t(tKey('total_cost'))} textFieldProps={{ type: 'number' }} />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  name='title'
                  label={getUnit(category, false, 'total_')}
                  textFieldProps={{ type: 'number' }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              {category === 'refueling' ? (
                <>
                  <Grid item xs={6}>
                    <TextInput name='partName' label={t(tKey('fuel_type'))} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      name='details'
                      label={t(tKey('cost_per'), { unit: getUnit(category) })}
                      textFieldProps={{ type: 'number' }}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={6}>
                    <TextInput
                      name='details'
                      label={t(tKey('cost_per'), { unit: getUnit(category) })}
                      textFieldProps={{ type: 'number' }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput name='mileage' label={`${t(tKey('current_mileage'))}, ${carData?.mileageMetric}`} />
                  </Grid>
                  <DatePickerSelect
                    name='datePerformed'
                    label={`${t(tKey('date_performed'))}`}
                    datePickerProps={{ value: dayjs() }}
                  />
                </>
              )}
            </Grid>
            {category === 'refueling' && (
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextInput name='mileage' label={`${t(tKey('current_mileage'))}, ${carData?.mileageMetric}`} />
                </Grid>
                <Grid item xs={6}>
                  <DatePickerSelect
                    name='datePerformed'
                    label={`${t(tKey('date_performed'))}`}
                    datePickerProps={{ value: dayjs() }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          <Box component={'div'} sx={{ display: { xs: 'block', md: 'none' } }}>
            <ImageUploaderMultiple
              images={images}
              onChange={onImageChange}
              editMode={editMode}
              data={defaultValues as Record}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className={classes.column}>
          <Box component={'div'} sx={{ display: { md: 'block', xs: 'none' } }}>
            <ImageUploaderMultiple
              images={images}
              onChange={onImageChange}
              editMode={editMode}
              data={defaultValues as Record}
            />
          </Box>
        </Grid>
      </Grid>
      <Box mt={3} mb={4} textAlign='center'>
        <StyledButton
          label={editMode ? t(tKey('edit_record')) : t(tKey('create_record'))}
          disabled={isSubmitting}
          loading={isSubmitting}
          type='submit'
        />
      </Box>
    </Form>
  );
};
