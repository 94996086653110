import { Description, Widgets } from '@mui/icons-material';
import { Box, Theme, Toolbar } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ImageLogo, MenuButton, PublicMobileNavigation, Wrapper } from 'components';
import { PublicBookInstructionDropdown } from 'components/PublicBookInstruction/PublicBookInstruction';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('header_page');

const styles = ({ breakpoints, spacing }: Theme) => ({
  root: {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',

    [breakpoints.up('md')]: {
      backgroundColor: 'rgba(187, 134, 252, 0.12)',
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(1.5, 0),

    [breakpoints.up('md')]: {
      padding: spacing(3, 0),
    },
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  button: {
    letterSpacing: 1.25,
  },
  reminder: {
    marginRight: spacing(3),
    position: 'relative',
    width: 30,
  },
});

export const PublicRecordsHeader: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const { identifier } = useParams<{ identifier: string }>();
  return (
    <div className={classes.root}>
      <Wrapper>
        <Box component={'div'} sx={{ display: { md: 'block', xs: 'none' } }}>
          <Toolbar disableGutters classes={{ root: classes.toolbar }}>
            <Box component={'div'} sx={{ display: { md: 'block', xs: 'none' } }}>
              <ImageLogo />
            </Box>
            <Box component={'div'} sx={{ display: { xs: 'block', md: 'none' } }}>
              <ImageLogo width={32} height={32} />
            </Box>
            <nav className={classes.nav}>
              <div className={classes.reminder}>
                <PublicBookInstructionDropdown />
              </div>
              <MenuButton text={t(tKey('records'))} startIcon={Description} to={`/book/${identifier}`} />
              <MenuButton text={t(tKey('dashboard'))} startIcon={Widgets} to={`/book/dashboard/${identifier}`} />
            </nav>
          </Toolbar>
        </Box>
      </Wrapper>

      <Box component={'div'} sx={{ display: { md: 'none', xs: 'block' } }}>
        <PublicMobileNavigation />
      </Box>
    </div>
  );
};
