import { Box, Dialog, Theme, Typography } from '@mui/material';
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import { ComponentType, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClasses } from 'utils/hooks/useClasses';
const styles = ({ spacing, breakpoints, palette }: Theme) => ({
  root: {
    '& .MuiPaper-root': {
      width: '100%',
      bottom: 0,
      height: '40vh',
      position: 'absolute',
    },
  },
  header: {
    fontSize: 18,
    lineHeight: '28.8px',
    textAlign: 'center',
  },
  recordButton: {
    border: '2px solid',
    borderColor: 'rgba(255, 255, 255, 0.3)',
    width: 80,
    height: 80,
    borderRadius: 40,
  },
  recordCircle: {
    width: 67,
    height: 67,
    borderRadius: 40,
    background: '#FF5050',
    transition: 'all 0.2s ease-out',
  },
  recordSquare: {
    width: 45,
    height: 45,
    borderRadius: 5,
    background: '#FF5050',
    transition: 'all 0.2s ease-out',
  },
});

const RECORD_LENGTH = 60;

interface AssistantVoiceToTextPopupProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (audioFile: File) => void;
}

export const AssistantVoiceToTextPopup: ComponentType<AssistantVoiceToTextPopupProps> = ({
  open,
  handleClose,
  handleSubmit,
}) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const [timer, setTimer] = useState(RECORD_LENGTH);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<any>(null);
  const increment = useRef<any>(null);
  const userSpeak = useRef(false);

  const formatTime = (time: any) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
      seconds
    ).padStart(2, '0')}`;
    return formattedTime;
  };
  useEffect(() => {
    if (open) {
      startRecording();
    } else {
      userSpeak.current = false;
      stopRecording();
      setTimer(RECORD_LENGTH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    const setup = async () => {
      const mimeType = 'audio/wav';
      if (!MediaRecorder.isTypeSupported(mimeType)) {
        await register(await connect());
      }
    };
    setup();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const quit = useRef(false);
  const startRecording = async () => {
    try {
      userSpeak.current = true;
      let stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream, { mimeType: 'audio/wav' });
      setMediaRecorder(recorder);
      recorder.start();
      setRecording(true);
      increment.current = setInterval(() => {
        setTimer((timer) => {
          if (timer > 0) return timer - 1;
          else {
            clearInterval(increment.current);
            setRecording(false);
            recorder.stop();
            return timer;
          }
        });
      }, 1000);
      const chunks: any = [];
      recorder.ondataavailable = async (e) => {
        const audioData = e.data;
        chunks.push(audioData);
      };
      recorder.onstop = async (e: any) => {
        const blob = new Blob(chunks, { type: 'audio/wav' });

        const audioFile = new File([blob], 'audio.wav', { type: 'audio/wav' });
        stream.getAudioTracks().forEach(function (track) {
          track.stop();
        });
        if (quit.current === true) {
          handleClose();
        } else {
          handleSubmit(audioFile);
        }
        quit.current = false;
      };
    } catch (err) {
      console.error('Error accessing audio devices.', err);
    }
  };
  const stopRecording = async () => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop();
      clearInterval(increment.current);
    }
    setRecording(false);
  };

  const onClose = () => {
    quit.current = true;
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop();
      clearInterval(increment.current);
    }
    setRecording(false);
  };

  return (
    <Dialog fullScreen open={open} className={classes.root} onClose={onClose}>
      <Box display='flex' alignItems='space-between' flexDirection='column' justifyContent='space-evenly' height='40vh'>
        <Typography className={classes.header}>{t('assistance_listening')}...</Typography>
        <Box display='flex' justifyContent='center' margin={3}>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Box style={{ marginBottom: '10px', fontWeight: 700, fontVariantNumeric: 'tabular-nums' }}>
              {formatTime(timer)}
            </Box>

            <Box
              className={classes.recordButton}
              display='flex'
              justifyContent='center'
              alignItems='center'
              id='record-button'
              onClick={() => {
                if (recording) {
                  stopRecording();
                }
              }}
            >
              <Box className={recording ? classes.recordSquare : classes.recordCircle}></Box>
            </Box>
            <Typography variant='body2' padding='10px 0 0'>
              {t('assistance_tap_to_stop')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
