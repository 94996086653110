import React, { useEffect, useRef } from 'react';

interface AutoLoadContentProps {
  onScrollToBottom: () => void;
  loading: boolean;
}

export const AutoLoadContent: React.FC<AutoLoadContentProps> = ({ onScrollToBottom, loading }) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const handleScroll = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      const element = elementRef.current;
      if (element) {
        const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
        //100 px for safari bottom bar case
        if (scrollTop + clientHeight + 100 >= scrollHeight - element.clientHeight) {
          // Reached the bottom of the page, call the onScrollToBottom function
          onScrollToBottom();
        }
      }
    }, 20); // Adjust the debounce delay (in milliseconds) as per your needs
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutRef.current!);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div ref={elementRef} />
    </>
  );
};
