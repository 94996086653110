import {
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Switch,
  Theme,
} from '@mui/material';
import { ChangeEvent, ComponentType, SyntheticEvent, useState } from 'react';
import { useClasses } from 'utils/hooks/useClasses';
import { filterUserStatus } from './AdminDashboardTypes';
import { useTranslation } from 'react-i18next';
import { Close, Search } from '@mui/icons-material';
import { getTKey } from 'utils';
import { filtersType } from '../hooks/useAdminUserData';
const tKey = getTKey('admin_filter_user');
const styles = ({ palette, spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    margin: spacing(1, 0, 2),
    alignItems: 'center',
    paddingTop: spacing(0.5),
  },
  field: {
    minWidth: 190,
    margin: spacing(0, 2, 0, 0),
  },
  searchInput: {
    background: '#202020',
    '& svg': {
      color: palette.text.primary,
    },
  },
  trustedSwitchWrap: {
    marginLeft: spacing(3),
  },
});

const filterUserStatusOptions: filterUserStatus[] = ['active', 'blocked'];

interface FilterUserBarType {
  filters: filtersType;
  onChangeStatus: (status: filterUserStatus) => void;
  onChangeTrust: (trust: boolean) => void;
  onChangeSearch: (username: string) => void;
}

export const FilterUserBar: ComponentType<FilterUserBarType> = ({
  filters,
  onChangeStatus,
  onChangeTrust,
  onChangeSearch,
}) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const handleChangeStatus = (event: SelectChangeEvent) => {
    onChangeStatus(event.target.value as filterUserStatus);
  };

  const handleChangeTrust = (event: SyntheticEvent, checked: boolean) => {
    onChangeTrust(checked);
  };

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSubmitSearch = (searchForce?: string) => {
    onChangeSearch(searchForce ?? search);
  };

  return (
    <div className={classes.root}>
      <FormControl className={classes.field}>
        <InputLabel
          htmlFor='outlined-adornment-username'
          id='outlined-adornment-username-label'
          className={classes.searchLabel}
        >
          {t(tKey('search_label'))}
        </InputLabel>
        <OutlinedInput
          id='outlined-adornment-username'
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                onClick={() => {
                  handleSubmitSearch();
                }}
              >
                <Search />
              </IconButton>
              {search !== '' && (
                <IconButton
                  onClick={() => {
                    setSearch('');
                    handleSubmitSearch('');
                  }}
                >
                  <Close />
                </IconButton>
              )}
            </InputAdornment>
          }
          label={t(tKey('search_label'))}
          value={search}
          className={classes.searchInput}
          onChange={handleChangeSearch}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              handleSubmitSearch();
            }
          }}
        />
      </FormControl>
      <FormControl className={classes.field}>
        <InputLabel id='status-select-label'>{t(tKey('status_label'))}</InputLabel>
        <Select
          labelId='status-select-label'
          id='status-select'
          value={filters.status}
          label={t(tKey('status_label'))}
          onChange={handleChangeStatus}
          className={classes.fieldSelect}
        >
          {filterUserStatusOptions.map((option) => {
            return (
              <MenuItem key={option} value={option}>
                {t(tKey('status') + '_' + option)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {filters.status === 'active' && (
        <FormControl className={classes.trustedSwitchWrap}>
          <FormControlLabel
            value={filters.trustedOnly}
            onChange={handleChangeTrust}
            control={<Switch color='primary' className={classes.trustedSwitch} />}
            label={t(tKey('trusted_only_label'))}
            labelPlacement='start'
          />
        </FormControl>
      )}
    </div>
  );
};
