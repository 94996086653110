import { IconButton, Menu, Theme, Typography } from '@mui/material';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { getTKey } from 'utils';

import clsx from 'clsx';
import { PuffLoader } from 'components/PuffLoader/PuffLoader';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('assisted_record_instruction');

const styles = ({ spacing, palette, breakpoints }: Theme) => ({
  menuPaper: {
    backgroundColor: 'transparent',
    maxHeight: '500px',
  },
  menu: {
    '& .MuiMenu-list': {
      backgroundColor: 'rgba(42, 42, 42)',
      borderRadius: 4,
      minWidth: 168,
      boxShadow: '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)',
    },
    '& .MuiMenuItem-root': {
      padding: spacing(1, 2, 1),
      backgroundColor: 'rgba(42, 42, 42)',
      color: 'black',

      height: 500,
    },
  },
  right: {
    display: 'flex',
    alignItems: 'center',
  },
  reminder: {
    marginRight: spacing(3),
    position: 'relative',
    width: 30,
    padding: spacing(2.5),
    borderRadius: spacing(999),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  reminderIcon: {
    width: 25,
    height: 25,
    position: 'absolute',
    top: -8,
    left: 22,
  },
  reminderEmoji: {
    position: 'absolute',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 450,
    [breakpoints.down('md')]: {
      width: 300,
    },
  },

  text: {
    fontSize: 14,
    opacity: 1,
    color: palette.additionalPrimary[100],
    padding: spacing(1, 0),
    [breakpoints.up('lg')]: {
      fontSize: 16,
    },
  },
  instruction: {
    width: '90%',
    fontSize: 14,
    [breakpoints.down('lg')]: {
      textAlign: 'justify',
      paddingRight: spacing(2),
    },
    [breakpoints.up('md')]: {
      fontSize: 16,
    },
  },
  span: {
    color: palette.additionalSecondary[100],
  },
  watch: {
    margin: spacing(2),
  },
  watchText: {
    fontSize: 14,
    [breakpoints.up('lg')]: {
      fontSize: 16,
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  border: {
    background: 'black',
  },
});

export const AssistedRecordInstructionDropdown: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size='medium'
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
        className={clsx(classes.reminder, Boolean(anchorEl) && classes.border)}
      >
        <span className={clsx(classes.reminderIcon, Boolean(anchorEl) && classes.hidden)}>
          <PuffLoader />
        </span>
        <span className={classes.reminderEmoji}>🤓</span>
      </IconButton>

      <Menu
        elevation={10}
        // getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
        classes={{ paper: classes.menuPaper }}
      >
        <div className={classes.content}>
          <Typography variant='body1' align='center' className={classes.text}>
            {t(tKey('remind_you'))}
          </Typography>
          <ul>
            <li>
              <Typography variant='body1' className={classes.instruction}>
                {t(tKey('one'))}
              </Typography>
            </li>

            <li>
              <Typography variant='body1' className={classes.instruction}>
                {t(tKey('two'))}
              </Typography>
            </li>
            <li>
              <Typography variant='body1' className={classes.instruction}>
                {t(tKey('three'))}
              </Typography>
            </li>
          </ul>
        </div>
      </Menu>
    </>
  );
};
