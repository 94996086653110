import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandle } from '@mui/icons-material';
import { Box, IconButton, Input, Theme } from '@mui/material';
import React, { createContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import { Car, Record as IRecord } from 'types';
import { STORY_ELEMENT_TYPE, getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { Story } from './AddStoryContent';
import { RecordItem } from './RecordItem';
import { RecordsGroup } from './RecordsGroup';
import { StoryElementButton } from './StoryElementButton';
import { getYoutubeIdFromUrl } from 'utils/YouTubeUtil';
const styles = ({ spacing, palette }: Theme) => ({
  desc: {
    width: '100%',
    padding: 5,
    borderRadius: 8,
  },
  focused: {
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
  },
  image: {
    height: 330,
    objectFit: 'cover',
  },
  icon: {
    color: 'white',
  },
});
const tKey = getTKey('stories_add_content');
interface Context {
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}
const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {},
});

export const AddStoryContentItem = ({
  element,
  index,
  records,
  recordGroup,
  descLength,
  focused,
  carData,
  removeElement,
  setElements,
  addGallery,
  addDescription,
  addRecord,
  addRecordGroup,
  addText,
  setTitle,
  updateDraft,
  setFocused,
}: {
  element: Story;
  index: number;
  records: { [id: string]: IRecord };
  recordGroup: { [id: string]: IRecord[] };
  descLength: number;
  focused: number;
  carData: Car | undefined;
  removeElement: (index: number) => void;
  setElements: React.Dispatch<React.SetStateAction<Story[]>>;
  addGallery: (images: any[]) => void;
  addText: () => void;
  addRecord: (record: IRecord[]) => void;
  setTitle: (title: string) => void;
  addRecordGroup: (record: IRecord[][]) => void;
  updateDraft: (newArray?: any[]) => void;
  addDescription: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => void;
  setFocused: (index: number) => void;
}) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const { attributes, listeners, setNodeRef, transform, setActivatorNodeRef, transition, isDragging } = useSortable({
    id: element.id,
  });

  const style = {
    opacity: isDragging ? 0 : undefined,
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef]
  );
  const statusFormatter = (currentIndex: number, totalItems: number) => {
    return `${currentIndex} ${t('__common__of')} ${totalItems}`;
  };
  return (
    <SortableItemContext.Provider value={context}>
      <div style={style} className={classes.item}>
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyItems: 'center',
            width: '100%',
            gap: 2,
            marginTop: 2,
            flexDirection:
              element?.elementType === STORY_ELEMENT_TYPE.RECORD ||
              element?.elementType === STORY_ELEMENT_TYPE.ASSISTED_RECORD
                ? 'column'
                : 'row',
            marginBottom: 2,
          }}
        >
          {element.elementType === STORY_ELEMENT_TYPE.TEXT && (
            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'flex-end' }}>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <div {...attributes} {...listeners} ref={setNodeRef}>
                  <IconButton aria-label='more' aria-controls='long-menu' aria-haspopup='true' size='large'>
                    <DragHandle className={classes.icon} />
                  </IconButton>
                </div>
                <Input
                  onFocus={() => setFocused(index)}
                  placeholder={t(tKey('desc'))}
                  classes={{ root: classes.desc, focused: classes.focused }}
                  value={element.texts?.[0].originalText}
                  onChange={(event) => {
                    if (event.target.value.length < (element.texts?.[0].originalText.length || 0))
                      addDescription(event, index);
                    return descLength >= 4000 ? null : addDescription(event, index);
                  }}
                  onPaste={(event) => {
                    if (descLength + (event.clipboardData.getData('text').replace(/\s/g, '').length || 0) > 4000) {
                      event.preventDefault();
                    }
                  }}
                  disableUnderline={true}
                  multiline
                  onBlur={() => {
                    // updateDraft();
                    setFocused(-1);
                  }}
                />
                <StoryElementButton onDelete={() => removeElement(index)} />
              </Box>
              {descLength > 0 && focused === index && (
                <Box
                  sx={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    paddingTop: 1,
                    paddingBottom: 1,
                    color: descLength > 4000 ? 'red' : 'white',
                    backgroundColor: 'rgba(255, 255, 255, 0.07)',
                    margin: 1,
                    borderRadius: 2,
                    fontWeight: 'bold',
                    marginRight: 6,
                  }}
                >
                  {descLength} / 4000
                </Box>
              )}
            </Box>
          )}
          {element.elementType === STORY_ELEMENT_TYPE.GALLERY && (
            <>
              <div {...attributes} {...listeners} ref={setNodeRef}>
                <IconButton aria-label='more' aria-controls='long-menu' aria-haspopup='true' size='large'>
                  <DragHandle className={classes.icon} />
                </IconButton>
              </div>
              <div className='slider-container'>
                <Carousel swipeable emulateTouch showThumbs={false} dynamicHeight statusFormatter={statusFormatter}>
                  {element.images?.map((image, i) => (
                    <img key={i} src={image.dataUrl} className={classes.image} alt='language-icon' />
                  ))}
                </Carousel>
              </div>
              <StoryElementButton onDelete={() => removeElement(index)} />
            </>
          )}
          {element.elementType === STORY_ELEMENT_TYPE.RECORD &&
            element?.records?.map((record) => {
              return (
                <Box sx={{ width: '100%', display: 'flex', flex: 1 }} key={record.recordId}>
                  <div {...attributes} {...listeners} ref={setNodeRef}>
                    <IconButton aria-label='more' aria-controls='long-menu' aria-haspopup='true' size='large'>
                      <DragHandle className={classes.icon} />
                    </IconButton>
                  </div>
                  <Box
                    sx={{
                      width: '100%',
                      padding: 2,
                      backgroundColor: '#232323',
                      margin: 1,
                      borderRadius: 5,
                    }}
                  >
                    <RecordItem
                      data={records[record.recordId]}
                      mileageMetric={carData?.mileageMetric}
                      key={record.recordId}
                    />
                  </Box>
                  <StoryElementButton onDelete={() => removeElement(index)} />
                </Box>
              );
            })}
          {element.elementType === STORY_ELEMENT_TYPE.ASSISTED_RECORD &&
            element?.assistedRecords?.map((record) => {
              return (
                <Box sx={{ width: '100%', display: 'flex', flex: 1 }} key={record.groupId}>
                  <div {...attributes} {...listeners} ref={setNodeRef}>
                    <IconButton aria-label='more' aria-controls='long-menu' aria-haspopup='true' size='large'>
                      <DragHandle className={classes.icon} />
                    </IconButton>
                  </div>
                  <Box
                    sx={{
                      width: '100%',
                      padding: 2,
                      backgroundColor: '#232323',
                      margin: 1,
                      borderRadius: 5,
                    }}
                  >
                    <RecordsGroup data={recordGroup[record.groupId]} key={record.groupId} />
                  </Box>
                  <StoryElementButton onDelete={() => removeElement(index)} />
                </Box>
              );
            })}
          {element.elementType === STORY_ELEMENT_TYPE.VIDEO && (
            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'flex-end' }}>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <div {...attributes} {...listeners} ref={setNodeRef}>
                  <IconButton aria-label='more' aria-controls='long-menu' aria-haspopup='true' size='large'>
                    <DragHandle className={classes.icon} />
                  </IconButton>
                </div>

                <Box style={{ position: 'relative', width: '100%', overflow: 'hidden', paddingTop: '56.25%' }}>
                  {element.youtubeVideoUrl && (
                    <iframe
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        width: '100%',
                        height: '100%',
                        border: 'none',
                      }}
                      src={`https://www.youtube.com/embed/${getYoutubeIdFromUrl(element.youtubeVideoUrl)}`}
                      frameBorder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen
                      title='Embedded youtube'
                    />
                  )}
                </Box>
                <StoryElementButton onDelete={() => removeElement(index)} />
              </Box>
            </Box>
          )}
        </Box>
      </div>
    </SortableItemContext.Provider>
  );
};
