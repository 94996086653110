import { FormControl, InputLabel, MenuItem, Select, Theme } from '@mui/material';
import getOr from 'lodash/fp/getOr';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Record } from 'types';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { recordCategories } from '../../records/components/record-categories';
import { AssistedRecordDefaultForm } from './AssistedRecordDefaultForm';
import { AssistedRecordFuelForm } from './AssistedRecordFuelForm';

const tKey = getTKey('record_form');

const styles = ({ spacing, breakpoints }: Theme) => ({
  category: {
    padding: spacing(1, 1),
    width: '100%',
    marginBottom: spacing(1),
    [breakpoints.up('md')]: {
      width: '49%',
    },
  },
  container: {
    position: 'relative',
    [breakpoints.down('sm')]: {
      marginTop: spacing(-4),
    },
  },
});

interface AssistedRecordFormProps {
  onSubmit: (values: any) => void;
  defaultValues?: Record;
  editMode?: boolean;
  isSubmitting?: boolean;
}

export const AssistedRecordForm: ComponentType<AssistedRecordFormProps> = ({
  onSubmit,
  defaultValues,
  editMode,
  isSubmitting,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);

  const [category, setCategory] = useState(getOr('refueling', 'category', defaultValues));

  const fuelCategory = () => category === 'refueling' || category === 'recharging';

  useEffect(() => {
    setCategory(getOr('refueling', 'category', defaultValues));
  }, [defaultValues]);

  return (
    <div className={classes.container}>
      <FormControl fullWidth variant='outlined' size='small' className={classes.formControl}>
        <InputLabel htmlFor={category}>{t(tKey('category'))}</InputLabel>
        <Select
          size='small'
          sx={{ display: { md: 'block', xs: 'none' } }}
          name='category'
          className={classes.category}
          label={t(tKey('category'))}
          value={category}
          onChange={(event) => setCategory(event.target.value as string)}
        >
          {recordCategories.map((option: any) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {t(option.label)}
              </MenuItem>
            );
          })}
        </Select>
        <Select
          size='small'
          sx={{ display: { xs: 'block', md: 'none' } }}
          native
          name='category'
          className={classes.category}
          label={t(tKey('category'))}
          value={category}
          onChange={(event) => setCategory(event.target.value as string)}
        >
          {recordCategories.map((option: any) => {
            return (
              <option key={option.value} value={option.value}>
                {t(option.label)}
              </option>
            );
          })}
        </Select>
      </FormControl>
      {fuelCategory() ? (
        <AssistedRecordFuelForm
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          editMode={editMode}
          category={category}
          isSubmitting={isSubmitting}
        />
      ) : (
        <AssistedRecordDefaultForm
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          editMode={editMode}
          category={category}
          isSubmitting={isSubmitting}
        />
      )}
    </div>
  );
};
