import { InfiniteData } from "react-query"
import { followersProfileResponse, useProfileFollowersQuery } from "services/profile/v2/useProfileFollowers"

type useProfileFollowersType = (
  pagination: any,
  follows: string,
  story_creator_id?: string,
  options?: object,
) => {
  data: InfiniteData<followersProfileResponse> | undefined,
  isLoading: boolean,
  isFetchingNextPage: boolean,
  isFetching: boolean,
  hasNextPage: boolean | undefined,
  fetchNextPage: () => void,
  refetch: () => void,
}

export const useProfileFollowers: useProfileFollowersType = (pagination, follows, story_creator_id = "", options = {}) =>{

  const {
    data,
    isLoading,
    isFetching,
    isFetchingNextPage, 
    hasNextPage,
    fetchNextPage,
    refetch,


  } = useProfileFollowersQuery(pagination, follows, options, story_creator_id)

  return {
    data,
    isLoading,
    isFetchingNextPage,
    isFetching,
    hasNextPage,
    fetchNextPage,
    refetch,
  }

}