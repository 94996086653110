import { Grid, Theme } from '@mui/material';
import { Form, FormCheckbox, SelectBox, StyledButton } from 'components';
import { ComponentType } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ShareCarPayload } from 'services';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import * as yup from 'yup';

const tKey = getTKey('share_car_form');
interface ShareCarFormProp {
  onSubmit: ({ carId, days, show_optional }: ShareCarPayload) => void;
  count: number;
  countLoading: boolean;
}

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  button: {
    margin: spacing(2, 0),
    display: 'flex',
    marginTop: spacing(2),
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  column: {
    margin: spacing(2, 0),
  },
  field: {
    height: 80,
    [breakpoints.down('sm')]: {
      height: 80,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'end',
    },
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkbox: {
    padding: spacing(2, 2.5),
    [breakpoints.down('sm')]: {
      padding: spacing(1, 1),
    },
  },
});

export type NewShareValues = {
  days: number;
  show_optional: boolean;
};

const validationSchema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    days: yup.number().required(),
    show_optional: yup.boolean().required(),
  });

export const ShareCarForm: ComponentType<ShareCarFormProp> = ({ onSubmit, count, countLoading }) => {
  const classes = useClasses(styles);
  const validityOptions = [
    { label: 'share_car_form_1_day', value: 1 },
    { label: 'share_car_form_7_days', value: 7 },
    { label: 'share_car_form_14_days', value: 14 },
    { label: 'share_car_form_30_days', value: 30 },
    { label: 'share_car_form_120_days', value: 120 },
  ];
  const { t } = useTranslation();
  const { carId } = useParams<{ carId: string }>() as { carId: string };

  const handleSubmit = ({ days, show_optional }: NewShareValues) => {
    onSubmit({
      carId,
      days,
      show_optional,
    });
  };

  return (
    <Form schema={validationSchema(t)} onSubmit={handleSubmit} defaultValues={{ days: 1, show_optional: false }}>
      <Grid container justifyContent='center' spacing={1}>
        <Grid item sm={12} className={classes.column}>
          <Grid className={classes.container} container spacing={2}>
            <Grid className={classes.field} item xs={12} sm={6}>
              <SelectBox name='days' label={t(tKey('period'))} options={validityOptions} optionsWithTranslations />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.checkbox}>
                <FormCheckbox name='show_optional' label={t(tKey('checkbox_label'))} />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} className={classes.button}>
          <StyledButton type='submit' label={t(tKey('submit'))} />
          {!countLoading && (
            <span className={classes.column}>
              {count > 1
                ? t(tKey('remaining_many'), { count: Number(count) })
                : t(tKey('remaining_one'), { count: Number(count) })}
            </span>
          )}
        </Grid>
      </Grid>
    </Form>
  );
};
