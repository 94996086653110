import { Theme, Typography } from "@mui/material";
import { ComponentType } from "react";
import { useClasses } from "utils/hooks/useClasses";
import {NotificationsOff} from '@mui/icons-material';
const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    textAlign: 'center',
    height: '60vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  iconWrapper: {
    marginBottom: spacing(3),
  },
  icon: {
    width: 100,
    height: 100,
  },
  text: {
    fontSize: 16,
    opacity: 1,

    [breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
});

export const EmptyAlerts: ComponentType<{title: string}> = ({title}) =>{
  const classes = useClasses(styles);

  return (
    <div className={classes.root}>
      <div className={classes.iconWrapper}>
        
          <NotificationsOff className={classes.icon}/>
        
      </div>
      <Typography variant='body1' color='inherit' className={classes.text}>
        {title}
      </Typography>
    </div>
  );
  
}