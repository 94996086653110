import { Check, Edit } from '@mui/icons-material';
import { IconButton, TextField, Theme } from '@mui/material';
import dayjs from 'dayjs';
import { upperCase } from 'lodash';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GroupDataType } from 'features/assisted-records/hooks/useAssistedLayout';
import { useRecordGroupMutation } from 'services';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { useRecordGroupTitle } from '../../features/records/hooks/useRecordGroupTitle';

const tKey = getTKey('record_group');

const styles = ({ palette, spacing }: Theme) => ({
  root: {
    marginBottom: spacing(3),
  },
  formControl: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    '& input': {
      padding: 0,
      fontSize: 20,
      fontWeight: 500,
      color: 'rgba(255, 255, 255, 0.87)',

      '&::placeholder': {
        color: 'rgba(255, 255, 255, 0.6)',
      },
    },

    '& fieldset': {
      border: 'none',
    },
  },
  staticTitle: {
    flexGrow: 1,
    fontSize: 20,
    fontWeight: 500,
    color: 'rgba(255, 255, 255, 0.87)',
  },
  button: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
  stats: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 10,
    color: 'rgba(255, 255, 255, 0.6)',
    letterSpacing: 1.5,
  },
  dot: {
    margin: spacing(0, 0.5),
    fontSize: 16,
    color: palette.additionalPrimary['200'],
  },
});

interface RecordTitleProps {
  data: GroupDataType;
  recordsCount: number;
  totalCost: number;
  identifier: string;
}

export const RecordTitle: ComponentType<RecordTitleProps> = ({ data, recordsCount, totalCost, identifier }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const [editMode, setEditMode] = useState<boolean>(false);
  const { title, handleTitleChange } = useRecordGroupTitle(data.title as string);
  const mutation = useRecordGroupMutation();

  const handleTitleSubmit = (): void => {
    if (title) {
      mutation.mutate({ id: data.id, title, identifier });
      setEditMode(false);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.formControl}>
        {editMode ? (
          <TextField
            fullWidth
            placeholder={t(tKey('title'))}
            variant='outlined'
            inputRef={(input) => input && input.focus()}
            className={classes.input}
            disabled={!editMode}
            value={title}
            onChange={(e) => handleTitleChange(e.target.value)}
          />
        ) : (
          <span className={classes.staticTitle}>{title}</span>
        )}
        {editMode ? (
          <IconButton className={classes.button} onClick={handleTitleSubmit} size='large'>
            <Check />
          </IconButton>
        ) : (
          <IconButton className={classes.button} onClick={() => setEditMode(true)} size='large'>
            <Edit />
          </IconButton>
        )}
      </div>
      <div className={classes.stats}>
        <span>{dayjs(data.date_performed).format('DD-MM-YYYY')}</span> <span className={classes.dot}>·</span>{' '}
        <span>
          {upperCase(t(tKey('records')))}: {recordsCount}
        </span>{' '}
        <span className={classes.dot}>·</span>{' '}
        <span>
          {upperCase(t(tKey('total_cost')))}: {totalCost}
        </span>
      </div>
    </div>
  );
};
