import { MenuItem, Select, useMediaQuery } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';

interface AdaptiveSelectProps {
  id: string;
  label: string;
  emptyValue?: string;
  options: number[] | string[];
  value: number | string;
  onChange: any;
  optionLabelTKey?: string;
}

export const AdaptiveSelect: ComponentType<AdaptiveSelectProps> = ({
  id,
  label,
  emptyValue,
  options,
  value,
  onChange,
  optionLabelTKey,
}) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      {mobile ? (
        <Select
          size='small'
          sx={{ display: { md: 'none', xs: 'block' } }}
          id={id}
          value={value}
          onChange={onChange}
          label={label}
        >
          {emptyValue && (
            <MenuItem value='all'>
              <span>{emptyValue}</span>
            </MenuItem>
          )}
          {options.map((optionItem: any) => (
            <MenuItem key={optionItem} value={optionItem}>
              {optionLabelTKey ? t(`${optionLabelTKey}${optionItem}`) : optionItem}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          size='small'
          sx={{ display: { md: 'block', xs: 'none' } }}
          id={id}
          value={value}
          onChange={onChange}
          label={label}
        >
          {emptyValue && (
            <MenuItem value='all'>
              <span>{emptyValue}</span>
            </MenuItem>
          )}
          {options.map((optionItem: any) => (
            <MenuItem key={optionItem} value={optionItem}>
              {optionLabelTKey ? t(`${optionLabelTKey}${optionItem}`) : optionItem}
            </MenuItem>
          ))}
        </Select>
      )} 
    </>
  );
};
