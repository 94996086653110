import { ChevronLeft, ChevronRight, CloseRounded } from '@mui/icons-material';
import { Fab, Theme } from '@mui/material';
import { ComponentType, useMemo, useState } from 'react';
import Lightbox from 'react-spring-lightbox';
import { useClasses } from 'utils/hooks/useClasses';

interface RecordAttachmentGalleryProps {
  images: (null | string)[];
}

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  attachmentImage: {
    display: 'block',
    marginRight: spacing(0.5),
    gap: spacing(0.5),
    '& img': {
      cursor: 'pointer',
      width: 90,
      height: 64,
      objectFit: 'cover',
    },
  },
  imageContainer: {
    display: 'flex',
  },
  img: {
    width: 90,
    height: 64,
    objectFit: 'cover',
    margin: spacing(2),
  },
  button: {
    height: 40,
    width: 45,
    [breakpoints.up('md')]: {
      margin: spacing(3),
    },
  },
  closeButton: {
    height: 40,
    width: 40,
    [breakpoints.up('md')]: {
      margin: spacing(3),
    },
    marginTop: 'env(safe-area-inset-top)',
  },
  header: {
    margin: spacing(2),
    display: 'flex',
    flexDirection: 'row-reverse',
  },
});

export const RecordAttachmentGallery: ComponentType<RecordAttachmentGalleryProps> = ({ images }) => {
  const classes = useClasses(styles);
  const [currentImageIndex, setCurrentIndex] = useState(0);

  const gotoPrevious = () => currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () => currentImageIndex + 1 < (imagesList?.length ?? 0) && setCurrentIndex(currentImageIndex + 1);

  const imagesList = useMemo(() => {
    return images.filter((i) => i).map((url, i) => ({ alt: `Image ${i}`, src: url ?? '' }));
  }, [images]);

  const [expand, setExpand] = useState(false);
  return (
    <>
      <div className={classes.attachmentImage}>
        {imagesList.map((image, index) => (
          <img
            key={index}
            alt={`attachment-${index}`}
            src={image.src}
            onClick={() => {
              setExpand(true);
              setCurrentIndex(index);
            }}
          />
        ))}
      </div>
      <Lightbox
        isOpen={expand}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        onClose={() => setExpand(false)}
        renderHeader={() => (
          <div className={classes.header}>
            <Fab color='primary' aria-label='Add' className={classes.closeButton} onClick={() => setExpand(false)}>
              <CloseRounded />
            </Fab>
          </div>
        )}
        images={imagesList}
        style={{ background: 'rgba(0, 0, 0, 0.87)', zIndex: 1400 }}
        currentIndex={currentImageIndex}
        renderNextButton={() => (
          <Fab color='secondary' aria-label='Add' className={classes.button} onClick={gotoNext}>
            <ChevronRight />
          </Fab>
        )}
        renderPrevButton={() => (
          <Fab color='secondary' aria-label='Add' className={classes.button} onClick={gotoPrevious}>
            <ChevronLeft />
          </Fab>
        )}
      />
    </>
  );
};
