import { useMutation } from 'react-query';

import { queryClient } from 'components';
import { axiosApiInstance as api } from 'utils';

type DeleteDraftPayload = { carId: string; storyId: string };

const deleteDraft = async ({ carId, storyId }: DeleteDraftPayload): Promise<void> => {
  const { data } = await api.delete(`/cars/${carId}/stories/${storyId}/draft`);
  return data;
};

export const useDeleteDraftMutation = () => {
  return useMutation(deleteDraft, {
    onSuccess: () => {
      queryClient.invalidateQueries('allDrafts');
    },
  });
};
