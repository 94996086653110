import { STORY_ELEMENT_TYPE } from './constants';
import { uniqueId } from 'lodash';
import { IStory } from 'features/stories-feed/StoriesTypes';
import { Story } from 'components/Stories/AddStoryContent';

export type StoryDraft = {
  is_mileage_hidden: boolean;
  id: string;
  views: number;
  comments: any[];
  title: string;
  car_mileage: number;
  is_draft: true;
  elements: {
    date_created: string;
    id: string;
    element_type: string;
    position: 1;
    youtube_video_url?: string;
    youtube_video_cover_url?: string;
    texts?: {
      date_created: string;
      id: string;
      original_text: string;
      original_language: string | null;
      do_not_translate: boolean;
    }[];
    images?: {
      id: string;
      url: string;
      is_cover: boolean;
      element_id: string;
      date_created: string;
      image_id: string;
      thumbnail_url?: string;
    }[];
    records?: {
      id: string;
      car_id: string;
      title: string;
      category: string;
      cost: string;
      part_name: string;
      details: string;
      image_one: string;
      image_two: string;
      image_three: string;
      mileage: string;
      group_id: string | null;
      group_title: string | null;
      date_created: string;
    }[];
    assisted_records?: {
      id: string;
      car_id: string;
      title: string;
      category: string;
      cost: string;
      part_name: string;
      details: string;
      image_one: string;
      image_two: string;
      image_three: string;
      mileage: string;
      group_id: string | null;
      group_title: string | null;
      date_created: string;
    }[];
  }[];
  likes: number;
  date_created: string;
  user: {
    id: string;
    username: string;
    country: string;
  };
  car: {
    id: string;
    make: string;
    model: string;
    year: string;
    mileage: string;
  };
  comments_count: number;
};

export const transformDraft = (
  draft: StoryDraft
): {
  stories: any;
  title: string;
  isMileageHidden: boolean;
  carId: string;
  storyId: string;
  car_mileage: number;
} => {
  return {
    title: draft.title,
    isMileageHidden: draft.is_mileage_hidden,
    car_mileage: draft.car_mileage,
    carId: draft.car.id,
    storyId: draft.id,

    stories: draft.elements.map((story, storyIndex) => {
      if (story.element_type === STORY_ELEMENT_TYPE.GALLERY) {
        return {
          id: story.id,
          elementType: story.element_type,
          images: (story.images ?? []).map((image, index) => {
            const splitUrl = image.url.split('.');
            const ext = splitUrl[splitUrl.length - 1];
            return {
              id: image.id,
              url: image.url,
              filename: `${storyIndex}_${index}.${ext}`,
              dataUrl: image.url,
              isCover: image.is_cover,
              isDraft: true,
              imageId: image.image_id,
            };
          }),
        };
      }
      if (story.element_type === STORY_ELEMENT_TYPE.TEXT) {
        var texts = (story.texts ?? []).map((text) => ({ originalText: text.original_text }));
        return { id: story.id, elementType: story.element_type, texts };
      }
      if (story.element_type === STORY_ELEMENT_TYPE.RECORD) {
        var records: any = (story.records ?? []).map((record) => ({ recordId: record.id ?? '' }));
        return { id: story.id, elementType: story.element_type, records };
      }
      if (story.element_type === STORY_ELEMENT_TYPE.VIDEO) {
        return {
          id: story.id,
          elementType: story.element_type,
          youtubeVideoUrl: story.youtube_video_url,
          youtubeVideoCoverUrl: story.youtube_video_cover_url,
          images: (story.images ?? []).map((image, index) => {
            return {
              url: image.thumbnail_url,

              dataUrl: image.thumbnail_url,
              isCover: image.is_cover,
              isDraft: true,
              imageId: image.image_id,
            };
          }),
        };
      }
      var assistedRecords = (story.assisted_records ?? []).map((record) => ({ groupId: record.group_id ?? '' }));
      assistedRecords = assistedRecords.length > 0 ? [assistedRecords[0]] : [];
      return { id: uniqueId('element'), elementType: story.element_type, assistedRecords: assistedRecords };
    }),
  };
};

export const transformStoryEdit = (
  draft: IStory
): {
  stories: Story[];
  title: string;
  isMileageHidden: boolean;
  carId: string;
  storyId: string;
  car_mileage: number;
} => {
  return {
    title: draft.title,
    isMileageHidden: draft.is_mileage_hidden,
    car_mileage: draft.car_mileage,
    carId: draft.car.id,
    storyId: draft.id,

    stories: draft.elements.map((story, storyIndex) => {
      if (story.element_type === STORY_ELEMENT_TYPE.GALLERY) {
        return {
          id: story.id,
          elementType: story.element_type,
          images: (story.images ?? []).map((image, index) => {
            const splitUrl = image.url?.split('.');
            const ext = splitUrl ? splitUrl[splitUrl.length - 1] : '';
            return {
              url: image.url,
              filename: `${storyIndex}_${index}.${ext}`,
              dataUrl: image.url,
              isCover: image.is_cover,
              isDraft: true,
              imageId: image.image_id,
            };
          }),
        };
      }
      if (story.element_type === STORY_ELEMENT_TYPE.TEXT) {
        var texts = (story.texts ?? []).map((text) => ({ originalText: text.original_text }));
        return { id: story.id, elementType: story.element_type, texts };
      }
      if (story.element_type === STORY_ELEMENT_TYPE.RECORD) {
        var records = (story.records ?? []).map((record) => ({ recordId: record.id ?? '' }));
        return { id: story.id, elementType: story.element_type, records };
      }
      if (story.element_type === STORY_ELEMENT_TYPE.VIDEO) {
        return {
          id: story.id,
          elementType: story.element_type,
          youtubeVideoUrl: story.youtube_video_url,
          youtubeVideoCoverUrl: story.youtube_video_cover_url,
          images: (story.images ?? []).map((image, index) => {
            return {
              url: image.thumbnail_url,
              dataUrl: image.thumbnail_url,
              isCover: image.is_cover,
              isDraft: true,
              imageId: image.image_id,
            };
          }),
        };
      }
      var assistedRecords = (story.assisted_records ?? []).map((record) => ({ groupId: record.group_id ?? '' }));
      assistedRecords = assistedRecords.length > 0 ? [assistedRecords[0]] : [];
      return { id: story.id, elementType: story.element_type, assistedRecords: assistedRecords };
    }),
  };
};
