import { useMutation } from 'react-query';

import { Record } from 'types';
import { axiosWithFileInstance } from 'utils';

interface UpdateRecordPayload {
  id: string;
  carId: string;
  formData: any;
}

const updateRecord = async ({ carId, id, formData }: UpdateRecordPayload): Promise<Record> => {
  const { data } = await axiosWithFileInstance.put(`/cars/${carId}/records/${id}`, formData);
  return data;
};

export const useUpdateRecordMutation = () => {
  return useMutation(updateRecord);
};
