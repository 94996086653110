import { ComponentType } from 'react';
import { Theme, Button } from '@mui/material';
import { useClasses } from 'utils/hooks/useClasses';
import { ArrowBack, ArrowForward } from '@mui/icons-material'

interface PrevNextButtonProps {
    prev?: () => void,
    next?: () => void,
    prevDisabled?: boolean,
    nextDisabled?: boolean,

}

const styles = ({ palette, spacing }: Theme) => ({
    buttonBlock: {
        
        '& button': {
            height: 56,
            background: '#C4C4C4',
            color: '#0000008A',
            border: 0,
            width: 52,
            minWidth: 52

        },
        '& button:hover' : {
            background: '#A4A4A4',
        }
    },
    prev: {
        marginRight: '0px !important',
        borderRadius: '100px 0% 0% 100px',
        textAlign: 'right',
        '& svg': {
            position: 'relative',
            right: -4,
        }
    },
    next: {
        marginLeft: '0px !important',
        borderRadius: '0% 100px 100px 0%',
        textAlign: 'left',
        '& svg': {
            position: 'relative',
            left: -4,
        }
    },
})

export const PrevNextButton: ComponentType<PrevNextButtonProps>  = (
    {
        prev,
        next,
        prevDisabled,
        nextDisabled
    }
) => {

    const classes = useClasses(styles);

    return ( 
        <div className={classes.buttonBlock}>

            <Button className={classes.prev} onClick={prev} disabled={prevDisabled}>
                <ArrowBack/>
            </Button>
            <Button className={classes.next} onClick={next} disabled={nextDisabled}>
                <ArrowForward/>
            </Button>
        </div>
     );
}