import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Theme } from "@mui/material";
import { ComponentType, useState } from "react";
import { useClasses } from "utils/hooks/useClasses";
import { filterItemDate, filterItemReportsOrder, filterItemStatus } from "./AdminDashboardTypes";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { getTKey } from "utils";
const tKey = getTKey('admin_filter');
const styles = ({palette, spacing}: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        margin: spacing(1,0,2)
    },
    space: {
        flex: 1
    },
    field: {
        minWidth: 190,
    },
    fieldSelect: {
        maxHeight: 44,
    },
    dateField: {
        marginRight: 16
    }
})

const filterItemStatusOptions: filterItemStatus[] = ['in_review', "approved", "hidden"];
const filterItemDateOptions: filterItemDate[] = ['newest', "oldest"];
const filterItemReportOptions: filterItemReportsOrder[] = ['desc', "asc"];

interface FilterSortItemsBarType {
    onChangeStatus: (status: filterItemStatus) => void,
    onChangeDate: (date: filterItemDate) => void,
    onChangeOrderOfReports: (order: filterItemReportsOrder) => void,
}

export const FilterSortItemsBar: ComponentType<FilterSortItemsBarType> = ({
    onChangeStatus,
    onChangeDate,
    onChangeOrderOfReports
    
}) => {
    const classes = useClasses(styles);
    const { t } = useTranslation();

    const [currentStatus, setCurrentStatus] = useState<filterItemStatus>('in_review');
    const handleChangeStatus = (event: SelectChangeEvent) => {
        onChangeStatus(event.target.value as filterItemStatus);
        setCurrentStatus(event.target.value as filterItemStatus)
    };

    const [currentDate, setCurrentDate] = useState<filterItemDate>('newest');
    const handleChangeDate = (event: SelectChangeEvent) => {
        onChangeDate(event.target.value as filterItemDate);
        setCurrentDate(event.target.value as filterItemDate)
    };
    
    const [currentOrder, setCurrentOrder] = useState<filterItemReportsOrder>('desc');
    const handleChangeOrder = (event: SelectChangeEvent) => {
        onChangeOrderOfReports(event.target.value as filterItemReportsOrder);
        setCurrentOrder(event.target.value as filterItemReportsOrder)
    };

    return (
        <div className={classes.root}>
            <FormControl  className={classes.field}>
                <InputLabel id="status-select-label">{t(tKey('status_label'))}</InputLabel>
                <Select
                    labelId="status-select-label"
                    id="status-select"
                    value={currentStatus}
                    label={t('admin_filter_status_label')}
                    onChange={handleChangeStatus}
                    className={classes.fieldSelect}
                >
                    {filterItemStatusOptions.map(option=>{
                        return (
                            <MenuItem key={option} value={option}>{t(tKey('status')+'_'+option)}</MenuItem>
                        )
                    })}
                    
                </Select>
            </FormControl>
            <div className={classes.space}></div>
            <FormControl  className={clsx(classes.field, classes.dateField)}>
                <InputLabel id="date-select-label">{t(tKey('date_label'))}</InputLabel>
                <Select
                    labelId="date-select-label"
                    id="date-select"
                    value={currentDate}
                    label={t(tKey('date_label'))}
                    onChange={handleChangeDate}
                    className={classes.fieldSelect}
                >
                    {filterItemDateOptions.map(option=>{
                        return (
                            <MenuItem key={option} value={option}>{t(tKey('date')+'_'+option)}</MenuItem>
                        )
                    })}
                    
                </Select>
            </FormControl>
            <FormControl  className={classes.field} >
                <InputLabel id="order-select-label">{t(tKey('order_of_reports_label'))}</InputLabel>
                <Select
                    labelId="order-select-label"
                    id="order-select"
                    value={currentOrder}
                    label={t(tKey('order_of_reports_label'))}
                    onChange={handleChangeOrder}
                    className={classes.fieldSelect}
                >
                    {filterItemReportOptions.map(option=>{
                        return (
                            <MenuItem key={option} value={option}>{t(tKey('order_of_reports')+'_'+option)}</MenuItem>
                        )
                    })}
                    
                </Select>
            </FormControl>
           
        </div>
    )
}