import { Theme } from '@mui/material';
import { FollowButton } from 'components';
import { ComponentType, useContext } from 'react';
import { countryFlags } from 'utils/country-flags';
import { useClasses } from 'utils/hooks/useClasses';
import { ICar, IUser } from '../StoriesTypes';
import { UserPicture } from './UserPicture';
import { useNavigate } from 'react-router-dom';
import { PublicUserPageContext } from 'features/users/PublicUserPageContext';
import { ProfileContext } from 'store/profile/store';
import clsx from 'clsx';
import { PatronMedium } from 'components/PatronBadges/PatronMedium';

const styles = ({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  userWrap: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  gap: {
    flex: 1,
  },
  userDataWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  userProfileImageWrapper: {
    width: 40,
    minWidth: 40,
    height: 40,
    position: 'relative',
    marginRight: spacing(1),
    overflow: 'hidden',

    '& img': {
      borderRadius: 20,
    },
  },
  userImage: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 20,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 19,
  },
  user: {
    fontSize: 16,
    fontWeight: 600,
    display: 'flex',
  },
  flag: {
    marginLeft: spacing(1),
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
  },
  followWrap: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: spacing(1),
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  car: {
    color: palette.text.hint,
  },
  clickable: {
    cursor: 'pointer',
  },
  patronWrapperDesktop: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: spacing(1),
  },
  usernamePatron: {
    color: '#FFCB40',
  },

  username: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 'calc(37vw - 320px)',

    [breakpoints.down(1400)]: {
      maxWidth: 220,
    },
    [breakpoints.down('sm')]: {
      maxWidth: 'calc(100vw - 210px)',
    },
  },
});

interface StoryHerderType {
  user: IUser;
  car: ICar;
  authorized: boolean;
  is_mileage_hidden?: boolean;
  openUnregisteredDialog?: () => void;
}

export const StoryHeader: ComponentType<StoryHerderType> = ({
  user,
  car,
  authorized,
  is_mileage_hidden,
  openUnregisteredDialog,
}) => {
  const classes = useClasses(styles);
  const { state } = useContext(ProfileContext);
  const navigate = useNavigate();

  const { isPublicUserPage } = useContext(PublicUserPageContext);

  const goToUser = () => {
    if (authorized && !isPublicUserPage) {
      navigate(`/user/${user.id}`);
    }
    if (!authorized) {
      if (openUnregisteredDialog) openUnregisteredDialog();
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.userWrap}>
        <div className={clsx([!isPublicUserPage && classes.clickable])} onClick={goToUser}>
          <UserPicture user={user} />
        </div>
        <div className={classes.userDataWrap}>
          <div
            className={clsx(
              classes.user,
              [!isPublicUserPage && classes.clickable],
              user.is_patron && classes.usernamePatron
            )}
          >
            <span onClick={goToUser} className={classes.username}>
              {user.username}
            </span>
            {user.country !== null && user.country !== undefined && user.country !== '' && (
              <div className={classes.flag} onClick={goToUser}>
                {user?.country ? countryFlags[user?.country] : ''}
              </div>
            )}
            {!isPublicUserPage && user.is_patron && (
              <div className={classes.patronWrapperDesktop}>
                <PatronMedium />
              </div>
            )}
          </div>

          <div className={classes.car}>
            {car.make} {car.model} • {car.year}{' '}
            {!is_mileage_hidden && car.mileage && (
              <>
                • {car.mileage} {car.mileage_metric}{' '}
              </>
            )}
          </div>
        </div>
      </div>
      <div className={classes.gap}></div>
      <div className={classes.followWrap}>
        {authorized && user.already_following !== undefined && state.id !== user.id && !isPublicUserPage && (
          <FollowButton followed={user.already_following} userId={user.id} />
        )}
      </div>
    </div>
  );
};
