import { isArray } from 'lodash';
import { useQuery, UseQueryResult } from 'react-query';
import { createCarUI } from 'services/cars/utils';
import { createRecordUI } from 'services/records/utils';
import { Record } from 'types';

import { axiosApiInstance as api } from 'utils';

const LINK_EXPIRED_ERROR = 'link expired or already claimed';

const getPublicDashboardCarData = async (identifier: string): Promise<any> => {
  try {
    const { data } = await api.get('/book/public/dashboard', { params: { identifier } });
    const arrayOfRecords: Record[] = [];

    data.records.forEach((item: any) => {
      if (isArray(item)) {
        const mapped = item.map(createRecordUI) as Record[];
        arrayOfRecords.push(mapped as any);
      } else {
        arrayOfRecords.push(createRecordUI(item));
      }
    });

    return { car: createCarUI(data?.car), records: arrayOfRecords };
  } catch (err: any) {
    if (err.response.data.message === LINK_EXPIRED_ERROR) {
      return { error: true };
    }
  }
};

export const usePublicDashboardCarQuery = (identifier: string): UseQueryResult<any> => {
  return useQuery(['publicDashboardCarData'], () => getPublicDashboardCarData(identifier));
};
