import { useMutation } from 'react-query';

import { axiosApiInstance } from 'utils';

const createStoryDraft = async ({ elements, carId }: { elements: any; carId: string }): Promise<any> => {
  const { data } = await axiosApiInstance.post(`/cars/${carId}/stories/draft`, elements);
  return data;
};

export const useCreateStoryDraftMutation = () => {
  return useMutation(createStoryDraft);
};
