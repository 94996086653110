import { AutoLoadContent, CommonLayout, PageLoader, SpinFeedLoaderMini } from 'components';
import { useInitialSettings } from 'lib';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from './components/AlertTypes';
import { AlertsList } from './components/AlertsList';
import { EmptyAlerts } from './components/EmptyAlerts';
import { useAlertsPage } from './useAlertsPage';

export const AlertsPage: ComponentType = () => {
  useInitialSettings();
  const { t } = useTranslation();

  const [pagination, setPagination] = useState<{ page: number }>({
    page: 1,
  });

  const { data, isFetching, refetch, isLoading } = useAlertsPage(pagination);
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [hasAlert, setHasAlert] = useState<boolean>(true);
  useEffect(() => {
    if (data?.alerts) {
      setAlerts([...alerts, ...data.alerts]);
      setPagination({ page: data.alerts.length > 0 ? data.current_page + 1 : pagination.page });
      if (data.alerts.length < 10) {
        setHasAlert(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const loadMore = async () => {
    await refetch();
  };

  return (
    <CommonLayout>
      {isLoading ? (
        <PageLoader />
      ) : data && alerts.length !== 0 ? (
        <>
          <AlertsList alerts={alerts} />
          <SpinFeedLoaderMini loading={isFetching} />
          {hasAlert && !isFetching && <AutoLoadContent onScrollToBottom={loadMore} loading={isFetching} />}
        </>
      ) : (
        <EmptyAlerts title={t('alert_page_no_alerts')} />
      )}
    </CommonLayout>
  );
};
