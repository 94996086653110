import { ArrowBack, Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Theme, useMediaQuery } from '@mui/material';
import { AutoLoadContentDialog } from 'components';
import { SpinFeedLoaderMini } from 'components/SpinFeedLoader/SpinFeedLoader';
import { UsersList } from 'features/stories-feed/components/UsersList';
import { useProfileFollowers } from 'lib/useProfileFollowers';
import { ComponentType, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';
import { useClasses } from 'utils/hooks/useClasses';
import { SearchFollowsForm } from './SearchFollowsForm';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      background: '#202020',

      [breakpoints.up('sm')]: {
        background: '#2a2a2a',
        width: 390,
        height: 512,
      },
    },
  },
  title: {
    textTransform: 'capitalize',
  },
  backBtn: {
    color: palette.text.hint,
    width: 44,
    height: 44,
    [breakpoints.down('sm')]: {
      paddingRight: spacing(3),
    },
  },
  users: {
    '& > div': {
      background: '#2a2a2a',
      [breakpoints.up('sm')]: {
        background: '#202020',
      },
    },
  },
});

interface FollowersDialogType {
  open: boolean;
  onClose: () => void;
  follows: string;
}

type followsPagination = {
  page: number;
  perPage: number;
  username: string;
};

export const FollowersDialog: ComponentType<FollowersDialogType> = ({ open, onClose, follows }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();

  const [pagination, setPagination] = useState<followsPagination>({ page: 1, perPage: 10, username: '' });

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useProfileFollowers(
    pagination,
    follows
  );

  const onSubmit = (event: any) => {
    setPagination({ ...pagination, ...{ username: event.username } });
    setTimeout(() => {
      refetch();
    }, 0);
  };
  const ref = useRef(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const title =
    follows === 'followers' ? t('user_profile_followers') : follows === 'followings' ? t('user_profile_following') : '';

  const onReset = () => {
    setPagination({ ...pagination, ...{ username: '' } });
    setTimeout(() => {
      refetch();
    }, 0);
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose} className={classes.dialog}>
      <DialogTitle>
        {fullScreen ? (
          <Grid container alignItems='center'>
            <IconButton onClick={onClose} className={classes.backBtn}>
              <ArrowBack />
            </IconButton>
            <div className={classes.title}>{title}</div>
          </Grid>
        ) : (
          <Grid container justifyContent='space-between' alignItems='center' marginTop={4}>
            <div className={classes.title}>{title}</div>
            <IconButton onClick={onClose} className={classes.backBtn}>
              <Close />
            </IconButton>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent ref={ref}>
        <SearchFollowsForm onSearch={onSubmit} onReset={onReset} />
        {!isLoading && (
          <>
            {data?.pages[0]?.followers?.length !== 0 ? (
              data?.pages.map((page) => {
                return (
                  <div key={page.current_page} className={classes.users}>
                    <UsersList
                      users={
                        follows === 'followers'
                          ? page.followers || []
                          : follows === 'followings'
                          ? page.followings || []
                          : []
                      }
                    />
                  </div>
                );
              })
            ) : (
              <div className={classes.emptyUsers}>{t('story_feed_page_no_result_users')}</div>
            )}
            <SpinFeedLoaderMini loading={isFetchingNextPage || isLoading} />
            {hasNextPage && (
              <AutoLoadContentDialog
                loading={isFetchingNextPage}
                onScrollToBottom={fetchNextPage}
                observeRef={ref.current}
              />
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
