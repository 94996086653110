import { Box, Button, Theme, Typography } from '@mui/material';
import { ComponentType } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { Form, FormErrorsCleaner, LeanLayout, PasswordInput, StyledButton } from 'components';
import { ResetPasswordPayload, useResetPasswordMutation } from 'services';
import { getTKey, yupPasswordValidator, yupStringValidator } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('reset_password');

const styles = ({ spacing, breakpoints }: Theme) => ({
  formContainer: {
    margin: spacing(0, 'auto', 4),

    [breakpoints.up('md')]: {
      maxWidth: 588,
      padding: spacing(4, 12),
      backgroundColor: 'rgba(187, 134, 252, 0.08)',
    },
  },
  title: {
    marginBottom: spacing(4),
    fontWeight: 600,
  },
  signInRedirect: {
    textAlign: 'center',
    marginTop: spacing(3),
  },
  btn: {
    textTransform: 'none',
  },
  formError: {
    textAlign: 'center',
    marginTop: spacing(2),
  },
});

const validationSchema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    password: yupPasswordValidator(t).required(t('__validation__required')),
    repeatPassword: yupStringValidator(t).oneOf([yup.ref('password')], t('__validation__passwords_dont_match')),
  });

const defaultValues = {
  password: '',
  repeatPassword: '',
};

export const ResetPasswordPage: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>() as { token: string };
  const mutation = useResetPasswordMutation();

  const submitResetPasswordForm = (data: ResetPasswordPayload) => {
    const formData = {
      password: data.password,
      reset_token: token,
    };

    mutation.mutate(formData, {
      onSuccess() {
        toast(t(tKey('snackbar')));
        navigate('/');
      },
    });
  };

  return (
    <LeanLayout>
      <div className={classes.formContainer}>
        <Typography variant='h4' align='center' className={classes.title}>
          {t(tKey('title'))}
        </Typography>
        <Form defaultValues={defaultValues} schema={validationSchema(t)} onSubmit={submitResetPasswordForm}>
          <FormErrorsCleaner />
          <PasswordInput name='password' label={t('__common__new_password')} />
          <PasswordInput name='repeatPassword' label={t('__common__repeat_password')} />
          <Box textAlign='center' mt={4} mb={4}>
            <StyledButton label={t(tKey('submit'))} type='submit' />
          </Box>
        </Form>
        <div className={classes.signInRedirect}>
          <Button className={classes.btn} variant='text' color='secondary' onClick={() => navigate('/login')}>
            {t(tKey('login'))}
          </Button>
        </div>
      </div>
    </LeanLayout>
  );
};
