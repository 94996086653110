
import { useQuery } from 'react-query';
import { axiosApiInstance as api } from 'utils';

const hasUnreadAlert = async () =>{
  try{
    const { data } = await api.get('users/notifications/has-unread');
    return data;
  }
  catch (error){
    return { error: true };
  }
}

export const useHasUnreadAlertQuery = () => {
  return useQuery("hasUnreadAlerts", () => hasUnreadAlert(), {cacheTime: 0});
}