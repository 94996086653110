import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

export const assistantClearHistory = async (): Promise<any> => {
  try {
    let { data } = await api.delete(`assistant/history`);
    return data;
  } catch (err) {
    return { error: true, message: '', text: '' };
  }
};

export const useAssistantClearHistoryMutation = () => {
  return useMutation(assistantClearHistory);
};
