import { useQuery, UseQueryResult } from 'react-query';

export const getUserLocation = async (): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    await fetch('https://ipapi.co/json')
      .then((res) => res.json())
      .then((response) => {
        resolve({ country: response?.country_name ?? '', city: response?.city ?? '' });
      })
      .catch((er) => {
        reject(er);
      });
  });
};

export const useUserLocation = (): UseQueryResult<any> => {
  return useQuery(['userLocation'], () => getUserLocation(), { enabled: false });
};
