import { Button, Theme, Typography } from '@mui/material';
import { LeanLayout } from 'components';
import { UpdateEmailDialog } from 'components/UpdateEmailDialog/UpdateEmailDialog';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useConfirmEmailMutation, useProfileInfoQuery } from 'services';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import imageKey from '../../assets/magic-car-key.png';

const tKey = getTKey('unconfirmed_email');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  formContainer: {
    margin: spacing(0, 'auto', 4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [breakpoints.up('md')]: {
      maxWidth: 588,
      padding: spacing(4, 12),
      backgroundColor: 'rgba(187, 134, 252, 0.08)',
    },
  },
  title: {
    marginBottom: spacing(1),
    fontWeight: 600,
    letterSpacing: '0.4px',
  },
  subtitle: {
    opacity: '0.6',
    letterSpacing: '0.25px',
  },
  imageContainer: {
    width: 121,
    height: 121,
    borderRadius: 97,
    background: '#63D7C5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: spacing(4, 0),
  },
  keyImage: {
    width: 65,
    height: 65,
  },
  buttonResend: {
    textTransform: 'none',
    '&.Mui-disabled': {
      color: palette.text.hint,
    },
  },
  question: {
    display: 'flex',
    alignItems: 'baseline',
    letterSpacing: '0.25px',
    opacity: '0.6',
    margin: spacing(6, 0, 1),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const UnconfirmedEmailPage: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const navigate = useNavigate();
  const data = useProfileInfoQuery();

  useEffect(() => {
    if (data?.data?.email_confirmed) {
      navigate('/', { replace: true });
    }
  }, [data, navigate]);

  const confirmEmailMutation = useConfirmEmailMutation();

  const [resendEmail, setResendEmail] = useState(false);

  const confirmEmail = () => {
    confirmEmailMutation.mutate(
      { email: data?.data?.email },
      {
        onSuccess() {
          toast(t(tKey('snackbar')));
        },
        onError() {
          toast(t(tKey('snackbar_try_later')));
        },
        onSettled() {
          setResendEmail(true);
        },
      }
    );
  };
  const [openUpdateEmail, setOpenUpdateEmail] = useState(false);

  const onOpenUpdateEmail = () => {
    setOpenUpdateEmail(true);
  };

  const onCloseUpdateEmail = () => {
    setOpenUpdateEmail(false);
  };
  const logout = () => {
    navigate('/settings/logout', { replace: true });
  };

  return (
    <LeanLayout>
      <div className={classes.formContainer}>
        <Typography variant='h4' align='center' className={classes.title}>
          {t(tKey('title'), { username: data?.data?.username })}
        </Typography>
        <Typography variant='body2' align='center' className={classes.subtitle}>
          {t(tKey('subtitle'))}
        </Typography>
        <div className={classes.imageContainer}>
          <img alt='key' src={imageKey} className={classes.keyImage} />
        </div>
        <Typography variant='body2' align='center' className={classes.subtitle}>
          {t(tKey('description'))}
        </Typography>
        <Typography variant='body2' align='center' className={classes.question}>
          {t(tKey('question'))}
        </Typography>
        <Typography variant='body2' align='center' className={classes.actions}>
          <Button className={classes.buttonResend} color='secondary' onClick={confirmEmail} disabled={resendEmail}>
            {t(tKey('resend'))}{' '}
          </Button>
          {t(tKey('or'))}
          <Button className={classes.buttonResend} color='secondary' onClick={onOpenUpdateEmail}>
            {t(tKey('update_email_address'))}{' '}
          </Button>
          {t(tKey('or'))}
          <Button className={classes.buttonResend} color='secondary' onClick={logout}>
            {t(tKey('logout'))}{' '}
          </Button>
        </Typography>
      </div>
      <UpdateEmailDialog email={data?.data?.email || ''} onClose={onCloseUpdateEmail} open={openUpdateEmail} />
    </LeanLayout>
  );
};
