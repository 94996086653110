import { Box, FormHelperText, Theme, Typography } from '@mui/material';
// import { User } from 'firebase/auth';

import { SignInResult } from '@capacitor-firebase/authentication';
import { Form, FormErrorsCleaner, LeanLayout, PasswordInput, StyledButton, TextInput } from 'components';
import { ComponentType, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { useAdminLoginMutation } from 'services';
import store from 'store2';
import { ProfileValues } from 'types';
import { getTKey, yupStringValidator } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import * as yup from 'yup';
import { SocialUsernameForm } from './SocialUsernameForm';
const tKey = getTKey('signin');

const styles = ({ spacing, breakpoints }: Theme) => ({
  formContainer: {
    margin: spacing(0, 'auto', 4),

    [breakpoints.up('md')]: {
      maxWidth: 588,
      padding: spacing(4, 12),
      backgroundColor: 'rgba(187, 134, 252, 0.08)',
    },
  },
  formError: {
    textAlign: 'center',
    marginTop: spacing(2),
  },
  title: {
    marginBottom: spacing(1),
    fontWeight: 600,
    letterSpacing: '0.4px',
  },
  subtitle: {
    marginBottom: spacing(4),
    opacity: '0.8',
    letterSpacing: '0.2px',
  },
  btn: {
    textTransform: 'none',
  },
  inputStyle: {
    WebkitBoxShadow: '0 0 0 1000px #201C24 inset',
    WebkitTextFillColor: 'white',
    [breakpoints.down('sm')]: {
      WebkitBoxShadow: '0 0 0 1000px #121212 inset',
    },
  },
});

const validationSchema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    email_username: yupStringValidator(t),
    password: yupStringValidator(t),
  });

const defaultValues = {
  email_username: '',
  password: '',
};

const ERROR_INVALID_CREDENTIALS = 'Invalid credentials';
const ERROR_TRY_TO_USE_GOOGLE_LOGIN = 'Error, try to use Google login';

export const parseApiError = (error: string) => {
  if (error === ERROR_INVALID_CREDENTIALS) {
    return 'signin_invalid_credentials';
  } else if (error === ERROR_TRY_TO_USE_GOOGLE_LOGIN) {
    return 'signin_use_google_login';
  } else {
    return '__common__error';
  }
};

export const AdminLoginPage: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const navigate = useNavigate();
  const [formError, setFormError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const mutation = useAdminLoginMutation();
  const [user, setUser] = useState<SignInResult>();
  const [emailUsernameValue, setEmailUsernameValue] = useState<string>('');
  const secretPanel = '/secret/machineroom/stories';

  const submitLoginForm = (data: ProfileValues) => {
    setFormError('');
    setLoading(true);

    mutation.mutate(data, {
      onSuccess(data) {
        //@ts-ignore
        gtag?.('event', 'login', { method: 'email' });
        if (data.accessToken) {
          store(data);
          setUser(undefined);
          secureLocalStorage.setItem('app_id_hash', process.env.REACT_APP_FIREBASE_APPSTORAGEID || '');
          navigate(secretPanel, { replace: true });
        } else {
          setFormError(parseApiError(data.error as string));
          setLoading(false);
        }
      },
    });
  };

  const handleEmailUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailUsernameValue(event.target.value.toLowerCase());
  };

  return (
    <LeanLayout>
      <div className={classes.formContainer}>
        <Typography variant='h4' align='center' className={classes.title}>
          {t(tKey('title'))}
        </Typography>
        <Typography variant='body2' align='center' className={classes.subtitle}>
          {t(tKey('description'))}
        </Typography>
        {user ? (
          <SocialUsernameForm user={user} />
        ) : (
          <>
            <Form defaultValues={defaultValues} schema={validationSchema(t)} onSubmit={submitLoginForm}>
              <FormErrorsCleaner />
              <TextInput
                name='email_username'
                label={t(tKey('email_or_username'))}
                inputProps={{
                  autoCapitalize: 'none',
                  className: classes.inputStyle,
                  onChange: handleEmailUsernameChange,
                }}
                textFieldProps={{ value: emailUsernameValue }}
              />
              {!user && (
                <PasswordInput name='password' label={t(tKey('password'))} inputPropsClassName={classes.inputStyle} />
              )}
              {user ? (
                <Box textAlign='center' mt={4} mb={4}>
                  <StyledButton label={t(tKey('continue'))} type='submit' loading={loading} />
                </Box>
              ) : (
                <>
                  <Box textAlign='center' mt={4} mb={4}>
                    <StyledButton label={t(tKey('submit'))} type='submit' loading={loading} />
                  </Box>
                </>
              )}
              {formError && (
                <FormHelperText margin='dense' classes={{ root: classes.formError }} error>
                  {t(formError)}
                </FormHelperText>
              )}
            </Form>
          </>
        )}
      </div>
    </LeanLayout>
  );
};
