import {  Dialog, IconButton, Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useClasses } from 'utils/hooks/useClasses';
import { IItem} from '../AdminReview/AdminItemTypes';
import { AdminReviewItem } from '../AdminReview/AdminReviewItem'
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { StyledButton } from 'components';
import clsx from 'clsx';


const styles = ({ palette, spacing }: Theme) => ({
    root: {
        width: 'calc(100vw - 160px)',
        height: 'calc(100vh - 180px)',
        display: 'flex',
        flexDirection: 'column',
    },
    itemDetailsHeader:{
        display: 'flex',
        flexDirection: 'row',
        padding: spacing(3),
        paddingBottom: 0,
        alignItems: 'center',
    },
    space: {
        flex: 1
    },
    itemDetailsHeaderTitle:{
        fontSize: 20
    },
    closeButton: {
        color: palette.text.primary,
    },
    actionButton: {
        padding: spacing(0.5, 1.5),
        margin: '0 4px'
    },
    hideButton:{
        background: '#FF5050',
    },
});

interface ItemDetailsDialogProps {
  item: IItem | null
  open: boolean;
  onClose: () => void,
  onRestore: (item: IItem) => void,
  onHide: (item: IItem) => void,
}

export const ItemDetailsDialog: ComponentType<ItemDetailsDialogProps> = ({
    item,
    open,
    onClose,
    onRestore,
    onHide
}) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='xl'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      
    >
      <div className={classes.root}>
        {item && 
            <AdminReviewItem item={item} detailsMode={true}>
                <div className={classes.itemDetailsHeader}>
                    <div className={classes.itemDetailsHeaderTitle}>{t('admin_content_details_title')}</div>
                    <div className={classes.space}></div>
                    <div>
                        {item.is_hidden === true && 
                            <StyledButton
                            label={t('Restore')} 
                            color='secondary' 
                            className={classes.actionButton}
                            onClick={()=>onRestore(item)}
                            />
                        }
                        {item.is_approved === true && 
                        <StyledButton 
                            label={t('admin_item_hide')} 
                            className={clsx(classes.actionButton, classes.hideButton)} 
                            onClick={()=>onHide(item)}/>
                        } 
                        {}
                    </div>
                    <IconButton onClick={onClose}>
                        <Close className={classes.closeButton} />
                    </IconButton>
                </div>
               
            </AdminReviewItem>}
      </div>
    </Dialog>
  );
};
