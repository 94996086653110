import { ComponentType, CSSProperties, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { useClasses } from 'utils/hooks/useClasses';
import { ChatMessage, START_INDEX } from '../AssistantChat';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { AssistantUserMessage } from './AssistantUserMessage';
import { AssistantMessage } from './AssistantMessage';
import { AssistantSystemMessage } from './AssistantSystemMessage';
import { FetchNextPageOptions } from 'react-query';
import { SearchHighlightContext } from '../SearchHighlightContext';
import { Capacitor } from '@capacitor/core';
import debounce from 'lodash/debounce';
import { theme } from 'theme';
import { AssistantWelcome } from './AssistantWelcome';
interface AssistantMessageListProps {
  messages: ChatMessage[];
  startReached?: (options?: FetchNextPageOptions) => any;
  virtuoso: React.RefObject<VirtuosoHandle>;
  showWelcome?: boolean;
  onWelcomeSubmit?: (text: string) => void;
  showSuggest?: boolean;
  onSuggestSubmit?: (text: string) => void;
}

const styles = ({ spacing, breakpoints, palette }: Theme) => ({
  root: {
    WebkitOverflowScrolling: 'touch',
    [breakpoints.up('md')]: {
      height: 'calc(100vh - 120px)',
    },
    [breakpoints.down('md')]: {
      height: Capacitor.isNativePlatform() ? 'calc(100vh - 172px)' : 'calc(100vh - 65px)',
    },
  },
});

interface VirtuosoState {
  ranges: Array<{
    startIndex: number;
    endIndex: number;
  }>;
}

const getBrowser = () => {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {
    return 'safari';
  }
  if (ua.indexOf('chrome') !== -1) {
    return 'chrome';
  }
  return 'other';
};

export const AssistantMessageList: ComponentType<AssistantMessageListProps> = ({
  messages,
  startReached,
  virtuoso,
  showWelcome,
  onWelcomeSubmit,
  showSuggest,
  onSuggestSubmit,
}) => {
  const classes = useClasses(styles);
  const heightCache = useRef(new Map());
  const [firstItemIndex, setFirstItemIndex] = useState(START_INDEX - messages.length);
  const scrollingRef = useRef(false);
  const previousMessagesLength = useRef(messages.length);
  const searchContext = useContext(SearchHighlightContext);
  const [browserType, setBrowserType] = useState<string>('');
  const [viewportHeight, setViewportHeight] = useState<number>(window.innerHeight);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const handleStartReached = useCallback(async () => {
    if (!startReached || scrollingRef.current) return;

    try {
      scrollingRef.current = true;

      const currentState = await new Promise<VirtuosoState | null>((resolve) => {
        virtuoso.current?.getState((state) => resolve(state));
      });

      if (!currentState) return;

      const currentMessagesLength = messages.length;

      await startReached();

      const hasNewMessages = messages.length > currentMessagesLength;

      if (hasNewMessages) {
        await new Promise((resolve) => setTimeout(resolve, 100));
        virtuoso.current?.scrollToIndex({
          index: messages.length - currentState.ranges[0].endIndex,
          align: 'start',
          behavior: 'auto',
        });
      }
      previousMessagesLength.current = messages.length;
    } finally {
      setTimeout(() => {
        scrollingRef.current = false;
      }, 150);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages.length, startReached]);

  const itemContent = useCallback((index: number, item: ChatMessage) => {
    if (!item) return null;

    return (
      <div
        ref={(element) => {
          if (element && !heightCache.current.has(item.id)) {
            heightCache.current.set(item.id, element.getBoundingClientRect().height);
          }
        }}
        style={{
          padding: '8px',
          contain: 'content',
          willChange: 'transform',
          transform: 'translate3d(0, 0, 0)',
          backfaceVisibility: 'hidden',
          WebkitBackfaceVisibility: 'hidden',
        }}
      >
        {item.actor === 'user' && (item.question !== '' || item.id.toString().length < 10) && (
          <AssistantUserMessage message={item.question} isLoading={item.isStreaming} index={index} />
        )}
        {item.actor === 'assistant' && (item.response !== '' || item.id.toString().length < 10) && (
          <AssistantMessage
            country={item.country || 'uk'}
            message={item.response}
            isLoading={item.isStreaming}
            index={index}
          />
        )}
        {item.actor === 'system' && <AssistantSystemMessage country={item.country} />}
      </div>
    );
  }, []);

  const internalMessages = useMemo(() => {
    const nextFirstItemIndex = START_INDEX - messages.length;
    setFirstItemIndex(nextFirstItemIndex);
    return messages;
  }, [messages]);

  const followOutput = useCallback((isAtBottom: boolean) => {
    return isAtBottom ? 'auto' : false;
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      document?.querySelector('mark.active')?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      });
    }, 100);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchContext?.current]);

  const handleTopStateChange = (state: any) => {
    if (state === true) {
      handleStartReached();
    }
  };

  const debouncedHeightUpdate = useMemo(
    () =>
      debounce((height: number) => {
        setViewportHeight(height);
      }, 100),
    []
  );

  useEffect(() => {
    return () => {
      debouncedHeightUpdate.cancel();
    };
  }, [debouncedHeightUpdate]);

  useEffect(() => {
    setBrowserType(getBrowser());

    const handleResize = () => {
      debouncedHeightUpdate(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      debouncedHeightUpdate.cancel();
    };
  }, [debouncedHeightUpdate]);

  const calculatedHeight = useMemo(() => {
    if (Capacitor.isNativePlatform()) {
      return 'calc(100vh - 172px - env(safe-area-inset-bottom))';
    }

    let offset = 65; // Default offset for Chrome and others

    if (browserType === 'safari') {
      offset = isDesktop ? 240 : 120; // 240px for desktop Safari, 120px for mobile Safari
    }
    return `calc(${viewportHeight}px - ${offset}px - env(safe-area-inset-bottom))`;
  }, [browserType, viewportHeight, isDesktop]);

  const rootStyles = useMemo<CSSProperties>(
    () => ({
      WebkitOverflowScrolling: 'touch',
      height: calculatedHeight,
      maxHeight: calculatedHeight,
    }),
    [calculatedHeight]
  );

  const virtuosoStyles = useMemo<CSSProperties>(
    () => ({
      height: '100%',
      overscrollBehavior: 'contain',
      transform: 'translate3d(0, 0, 0)',
      WebkitTransform: 'translate3d(0, 0, 0)',
      backfaceVisibility: 'hidden',
      WebkitBackfaceVisibility: 'hidden',
      perspective: '1000',
      WebkitPerspective: '1000',
    }),
    []
  );

  return (
    <div className={classes.root} style={rootStyles}>
      <Virtuoso
        ref={virtuoso}
        initialTopMostItemIndex={internalMessages.length - 1}
        itemContent={itemContent}
        firstItemIndex={Math.max(0, firstItemIndex)}
        data={internalMessages}
        atTopStateChange={handleTopStateChange}
        followOutput={followOutput}
        style={virtuosoStyles}
        components={{
          Header: () =>
            showWelcome && onWelcomeSubmit ? (
              <AssistantWelcome onClickSuggestion={onWelcomeSubmit} />
            ) : (
              <div style={{ height: 0, margin: 0, padding: 0 }} />
            ),
          Footer: () =>
            showSuggest && onSuggestSubmit ? (
              <>
                <AssistantWelcome onClickSuggestion={onSuggestSubmit} />
                <div style={{ height: 20, margin: 0, padding: 0 }} />
              </>
            ) : (
              <div style={{ height: 0, margin: 0, padding: 0 }} />
            ),
        }}
        overscan={1200}
      />
    </div>
  );
};
