import { useEffect, useState } from 'react';

import { useRecordsByCarQuery } from 'services';
import { Record, RecordsFiltersInterface } from 'types';
import { filterByCategory, filterByMonth, filterByYear } from 'utils';

type useFilteredRecordsProps = (
  filters: RecordsFiltersInterface,
  id: string,
  page: 'dashboard' | 'records'
) => {
  records: Record[];
  filteredRecords: Record[];
  isLoading: boolean;
  lastMileage: number;
};

export const useFilteredRecords: useFilteredRecordsProps = ({ year, month, category }, id, page) => {
  const [filteredRecords, setFilteredRecords] = useState<Record[]>([]);
  const [lastMileage, setLastMileage] = useState<number>(0);
  const { data, isFetching } = useRecordsByCarQuery(id, page, { cacheTime: 0 });

  useEffect(() => {
    if (!isFetching && data?.length) {
      const flattenData = data.flat();
      let filteredRecords = [...data];

      setLastMileage(Math.max(...flattenData.map((record: Record) => record.mileage)));

      if (year && year !== 'all') {
        filteredRecords = filterByYear(filteredRecords, Number(year));
      }

      if (month !== 'all') {
        filteredRecords = filterByMonth(filteredRecords, Number(month));
      }

      if (category && category !== 'all') {
        filteredRecords = filterByCategory(filteredRecords, category);
      }

      setFilteredRecords(filteredRecords);
    } else if (!isFetching && data?.length === 0) {
      setFilteredRecords([]);
      setLastMileage(0);
    }
  }, [id, data, isFetching, year, month, category]); // eslint-disable-line react-hooks/exhaustive-deps

  return { records: data, filteredRecords, isLoading: isFetching, lastMileage };
};
