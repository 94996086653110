import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Theme } from '@mui/material';
import dayjs from 'dayjs';
import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { IUserItem, ItemActionType } from '../AdminReview/AdminItemTypes';

import { Close } from '@mui/icons-material';
import { useAdminUserWarns } from 'features/admin-panel/hooks/useAdminUserWarns';

const tKey = getTKey('admin_hide_item_dialog');

const styles = ({ palette, spacing }: Theme) => ({
  description: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
  actions: {
    '& button': {
      letterSpacing: 1.25,
    },
  },
  titleWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  closeButton: {
    color: palette.text.hint,
  },
  warnsWrap: {
    padding: spacing(5, 0),
  },
  warn: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacing(1.25, 0),
    borderBottom: '1px solid',
    borderColor: palette.text.hint,
    alignItems: 'center',
  },
  chip: {
    color: palette.text.primary,
    background: palette.additionalSecondary[700],
    maxWidth: 250,
    height: 'unset',
    minHeight: 32,
    '& span': {
      whiteSpace: 'break-spaces',
      padding: spacing(0.75, 1.5),
    },
  },
});

interface ItemHiddenDialogProps {
  open: boolean;
  reason: ItemActionType;
  onClose: () => void;
  onBan: () => void;
  onWarn: () => void;
  userId: string;
  user?: IUserItem;
  wasCorrected?: boolean;
}

export const ItemHiddenDialog: ComponentType<ItemHiddenDialogProps> = ({
  open,
  reason,
  onClose,
  onBan,
  onWarn,
  userId,
  user,
  wasCorrected,
}) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const { warns, refetch } = useAdminUserWarns(userId);

  useEffect(() => {
    if (userId) {
      refetch();
    }
  }, [refetch, userId]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='xs'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title' className={classes.titleWrap}>
        <div>{t(tKey('title'))}</div>
        <IconButton onClick={onClose}>
          <Close className={classes.closeButton} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={classes.description}>
          {warns?.length === 0 ? (
            t(tKey('description_ban_or_warn'), { type: t('admin_dialog_' + reason ?? '') })
          ) : (
            <>
              {' '}
              {t(tKey('description_ban'), { count: warns?.length })}
              <div className={classes.warnsWrap}>
                {Array.isArray(warns) &&
                  warns?.map((warn) => {
                    return (
                      <div key={warn.id} className={classes.warn}>
                        <div className={classes.date}>{dayjs(warn.date_created).format('DD-MM-YYYY')}</div>
                        <div className={classes.reason}>
                          <Chip label={warn.reason} className={classes.chip} />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button onClick={onBan} color='error'>
          {t(tKey('ban'))}
        </Button>
        <Button
          onClick={onWarn}
          color='error'
          disabled={
            warns?.length > 0 || user?.is_story_banned === true || user?.is_comment_banned === true || wasCorrected
          }
        >
          {t(tKey('warn'))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
