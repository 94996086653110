import { Box, Button, Theme, Typography } from '@mui/material';
import { ComponentType, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { Form, FormErrorsCleaner, LeanLayout, StyledButton, TextInput } from 'components';
import { ForgotPasswordPayload, useForgotPasswordMutation } from 'services';
import { getTKey, yupStringValidator } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('forgot_password');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  formContainer: {
    margin: spacing(0, 'auto', 4),

    [breakpoints.up('md')]: {
      maxWidth: 588,
      padding: spacing(4, 12),
      backgroundColor: 'rgba(187, 134, 252, 0.08)',
    },
  },
  formError: {
    textAlign: 'center',
    marginTop: spacing(2),
  },
  title: {
    marginBottom: spacing(1),
    fontWeight: 600,
    letterSpacing: '0.4px',
  },
  subtitle: {
    marginBottom: spacing(4),
    opacity: '0.8',
    letterSpacing: '0.2px',
  },
  btn: {
    textTransform: 'none',
  },
});

const validationSchema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    email: yupStringValidator(t).email(t('__validation__email')),
  });

const defaultValues = {
  email: '',
};

export const ForgotPasswordPage: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [shouldReset, setShouldReset] = useState<boolean>(false);
  const forgotPasswordMutation = useForgotPasswordMutation();

  const forgotPassword = (data: ForgotPasswordPayload) => {
    setLoading(true);

    forgotPasswordMutation.mutate(
      { email: data.email },
      {
        onSuccess() {
          setShouldReset(true);
          setLoading(false);
          toast(t(tKey('snackbar')));
        },
      }
    );
  };

  return (
    <LeanLayout>
      <div className={classes.formContainer}>
        <Typography variant='h4' align='center' className={classes.title}>
          {t(tKey('title'))}
        </Typography>
        <Typography variant='subtitle1' align='center' className={classes.subtitle}>
          {t(tKey('description'))}
        </Typography>
        <Form
          defaultValues={defaultValues}
          schema={validationSchema(t)}
          onSubmit={forgotPassword}
          shouldReset={shouldReset}
        >
          <FormErrorsCleaner />
          <TextInput name='email' label={t(tKey('email'))} />
          <Box textAlign='center' mt={4} mb={4}>
            <StyledButton label={t(tKey('submit'))} type='submit' loading={loading} />
          </Box>
        </Form>
        <Box mt={4} textAlign='center'>
          <Button className={classes.btn} variant='text' color='secondary' onClick={() => navigate('/login')}>
            {t(tKey('login'))}
          </Button>
        </Box>
      </div>
    </LeanLayout>
  );
};
