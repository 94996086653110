import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { isEqual } from 'lodash';
import { ComponentType, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import store from 'store2';
import * as yup from 'yup';

import {
  AEFlag,
  AUFlag,
  CAFlag,
  EUFlag,
  GBFlag,
  MXFlag,
  NZFlag,
  PHFlag,
  PLFlag,
  SGFlag,
  UAFlag,
  USFlag,
  ZAFlag,
} from 'assets/flags';

import { Form, StyledButton, queryClient } from 'components';
import {
  UpdateCurrencyPayload,
  useProfileSettingsMutation,
  useRecordsQuery,
  useUpdateCurrencyMutation,
} from 'services';
import { Currency, UserSettings } from 'types';
import { getTKey, yupStringValidator } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { CurrencyChangeDialog } from './CurrencyChangeDialog';
import { theme } from 'theme';
const tKey = getTKey('settings_currency');

type CurrencyOption = {
  id: number;
  label: Currency;
  value: Currency;
  icon: any;
  emoji: string;
};

const currencyOptions: CurrencyOption[] = [
  { id: 1, label: 'USD', value: 'USD', icon: USFlag, emoji: '🇺🇸' },
  { id: 2, label: 'EUR', value: 'EUR', icon: EUFlag, emoji: '🇪🇺' },
  { id: 6, label: 'AED', value: 'AED', icon: AEFlag, emoji: '🇦🇪' },
  { id: 5, label: 'AUD', value: 'AUD', icon: AUFlag, emoji: '🇦🇺' },
  { id: 4, label: 'CAD', value: 'CAD', icon: CAFlag, emoji: '🇨🇦' },
  { id: 8, label: 'GBP', value: 'GBP', icon: GBFlag, emoji: '🇬🇧' },
  { id: 12, label: 'MXN', value: 'MXN', icon: MXFlag, emoji: '🇲🇽' },
  { id: 9, label: 'NZD', value: 'NZD', icon: NZFlag, emoji: '🇳🇿' },
  { id: 3, label: 'PLN', value: 'PLN', icon: PLFlag, emoji: '🇵🇱' },
  { id: 7, label: 'PHP', value: 'PHP', icon: PHFlag, emoji: '🇵🇭' },
  { id: 11, label: 'SGD', value: 'SGD', icon: SGFlag, emoji: '🇸🇬' },
  { id: 0, label: 'UAH', value: 'UAH', icon: UAFlag, emoji: '🇺🇦' },
  { id: 10, label: 'ZAR', value: 'ZAR', icon: ZAFlag, emoji: '🇿🇦' },
];

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  formControl: {
    maxWidth: 120,
  },
  formLabel: {
    display: 'block',
    marginBottom: spacing(1.5),
    fontSize: 20,
    fontWeight: 600,
    color: palette.text.primary,
  },
  column: {
    marginBottom: spacing(2),

    [breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: spacing(1),
  },
  formGroup: {
    padding: 0,
    width: '90%',
  },
  radioStyle: {
    justifyContent: 'space-between',
    margin: spacing(2, 0),
    width: '97%',
    display: 'flex',
  },
  divider: {
    margin: spacing(2, 0),
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
  },
  subHeading: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 10,
    marginBottom: spacing(2),
  },
});
const validationSchema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    currency: yupStringValidator(t),
  });

const CurrencyFormContent: ComponentType<{ values: UserSettings; onSubmit: (data: UserSettings) => void }> = ({
  values,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const { control, setValue, watch } = useFormContext();
  const currencyWatch = watch('currency');
  const defaultCurrency = values.currency as Currency;
  const [showCurrencyDialog, setShowCurrencyDialog] = useState<boolean>(false);
  const [currentCurrency, setCurrentCurrency] = useState<Currency>(defaultCurrency);
  const mutation = useUpdateCurrencyMutation();
  const { data, isLoading } = useRecordsQuery();
  const userHasRecords = !isLoading && data;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(() => {
    if (userHasRecords && currentCurrency !== currencyWatch) {
      setShowCurrencyDialog(true);
    }

    if (!userHasRecords && currentCurrency !== currencyWatch) {
      setValue('currency', currencyWatch);
      setCurrentCurrency(currencyWatch);
    }
  }, [currentCurrency, currencyWatch, userHasRecords]); // eslint-disable-line react-hooks/exhaustive-deps

  const cancelHandler = (): void => {
    setValue('currency', currentCurrency);
    setCurrentCurrency(currentCurrency);
    setShowCurrencyDialog(false);
  };

  const confirmHandler = (data: UpdateCurrencyPayload): void => {
    const newCurrency = data.toCurrency;
    setValue('currency', newCurrency);
    setCurrentCurrency(newCurrency);
    //@ts-ignore
    gtag?.('event', 'currency_changed', { currency: newCurrency });

    mutation.mutate(data, {
      onSuccess() {
        setShowCurrencyDialog(false);
        toast(t(tKey('success_message')));
      },
    });
  };

  return (
    <>
      {showCurrencyDialog && (
        <CurrencyChangeDialog
          from={currentCurrency as Currency}
          to={currencyWatch}
          onClose={cancelHandler}
          onCancel={cancelHandler}
          onConfirm={confirmHandler}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.column}>
          <FormControl fullWidth variant='outlined'>
            <Box component={'div'}>
              <Controller
                name='currency'
                control={control}
                render={({ field }) => (
                  <FormGroup {...field}>
                    <FormControlLabel
                      className={classes.radioStyle}
                      labelPlacement='start'
                      control={
                        <>
                          {isMobile && (
                            <Select {...field} native id='currency' sx={{ display: { md: 'none', xs: 'block' } }}>
                              {currencyOptions.map((currency) => (
                                <option key={currency.id} value={currency.value}>
                                  {currency.emoji} {currency.label}
                                </option>
                              ))}
                            </Select>
                          )}
                          {!isMobile && (
                            <Select
                              sx={{ display: { md: 'block', xs: 'none' } }}
                              {...field}
                              value={currentCurrency}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                },
                              }}
                            >
                              {currencyOptions.map((currency) => {
                                const Icon = currency.icon;

                                return (
                                  <MenuItem key={currency.id} value={currency.value}>
                                    <>
                                      <Icon className={classes.icon} />
                                      {currency.label}
                                    </>
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                        </>
                      }
                      label={t(tKey('title'))}
                    />
                  </FormGroup>
                )}
              />
              <Divider className={classes.divider} />
            </Box>
          </FormControl>
        </Grid>
        {!userHasRecords ? (
          <Grid item xs={12}>
            <Box textAlign='center' mt={4} mb={4}>
              <StyledButton label={t(tKey('submit'))} type='submit' />
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export const CurrencyForm: ComponentType<{ preloadedValues: UserSettings }> = ({ preloadedValues }) => {
  const { t } = useTranslation();
  const mutation = useProfileSettingsMutation();

  const handleSettingsSubmit = (data: UserSettings) => {
    if (!isEqual(preloadedValues, data)) {
      mutation.mutate(data, {
        onSuccess(res) {
          if (res?.error) {
            console.warn('Something went wrong');
          } else {
            queryClient.invalidateQueries('userSettings');
            store('driverbook_settings', data);
            toast(t(tKey('success_message')));
          }
        },
      });
    }
  };

  return (
    <Form onSubmit={handleSettingsSubmit} schema={validationSchema(t)} defaultValues={preloadedValues}>
      <CurrencyFormContent values={preloadedValues} onSubmit={handleSettingsSubmit} />
    </Form>
  );
};
