import { Theme } from '@mui/material';
import { IRecord } from 'features/stories-feed/StoriesTypes';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Currency } from 'types';
import { countTotalMoneySpendI, currencySymbol, formatDate } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { StoryGroupRecordsPopup } from './StoryGroupRecordsPopup';
import { TranslateContext } from '../StoryWrapper';

interface RecordsGroupProps {
  data: IRecord[];
}

const styles = ({ palette, spacing, breakpoints, transitions }: Theme) => ({
  root: {
    display: 'flex',
    backgroundColor: 'rgba(65,65,65,1)',
    minHeight: 89,
    padding: spacing(2, 2, 1),
    borderRadius: spacing(0.5),
    cursor: 'pointer',
    boxShadow: '0px 6px 0px -1px #333, 0px 12px 0px -2px #242424',
  },
  selectRoot: {
    marginBottom: spacing(1.5),
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 10,
    letterSpacing: 1.5,
    color: 'rgba(255, 255, 255, 0.6)',
  },
  dot: {
    display: 'inline-block',
    margin: spacing(0, 0.5),
    fontSize: 12,
    color: palette.additionalPrimary['200'],
  },
  title: {
    width: '100%',
    marginTop: spacing(1),
    fontSize: 20,
    fontWeight: 500,
    color: 'rgba(255, 255, 255, 0.6)',
    letterSpacing: 1.5,

    [breakpoints.up('sm')]: {
      textOverflow: 'ellipsis',
      maxHeight: 48,
      marginTop: 0,
      lineClamp: 2,
      overflow: 'hidden',
      fontSize: 'inherit',
      boxOrient: 'vertical',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
    },
  },
});

export const StoryGroupRecords: ComponentType<RecordsGroupProps> = ({ data }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const [open, setOpen] = useState<boolean>(false);
  const totalExpenses = countTotalMoneySpendI(data?.flat());
  const firstRecord = data[0];
  return (
    <div>
      <div
        className={classes.root}
        onClick={() => {
          setOpen(true);
        }}
      >
        <div className={classes.header}>
          <div className={classes.info}>
            {formatDate(firstRecord.date_created)}
            <span className={classes.dot}>•</span>
            <div>
              {t('record_group_notes')} {data.length}
            </div>
            <span className={classes.dot}>•</span>
            <div>
              {totalExpenses} {currencySymbol[firstRecord.currency as Currency]}
            </div>
          </div>
          <div className={classes.title}>
            <TranslateContext.Consumer>
              {(noTranslate) =>
                !firstRecord.do_not_translate && !noTranslate
                  ? firstRecord.translated_group_title
                  : firstRecord.group_title
              }
            </TranslateContext.Consumer>
          </div>
        </div>
      </div>
      <StoryGroupRecordsPopup
        data={data}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </div>
  );
};
