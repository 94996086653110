import React, { ComponentType, useEffect, useState } from 'react';
import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ConfirmationDialog, EmptyBox } from 'components';
import { useDeleteRecordMutation } from 'services';
import { Record } from 'types';
import { countTotalMoneySpend } from 'utils';

import { RecordItem } from './RecordItem';
import { RecordsGroup } from './RecordsGroup';

interface RecordsListProps {
  carId: string;
  data: Record[];
  mileageMetric: string | undefined;
  openGroupId: string;
  filterCategory: string;
  handleOpenGroup: (id: string) => void;
  handleCloseGroup: () => void;
}

export const RecordsList: ComponentType<RecordsListProps> = ({
  carId,
  mileageMetric,
  data,
  openGroupId,
  filterCategory,
  handleOpenGroup,
  handleCloseGroup,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [idToDelete, setIdToDelete] = useState<string>('');
  const [wasDeleted, setWasDeleted] = useState<boolean>(false);
  const mutation = useDeleteRecordMutation();
  const totalExpenses = countTotalMoneySpend(data.flat());

  useEffect(() => {
    if (wasDeleted) {
      const flatRecords = data.flat();
      const groupRecord = (element: Record) => element.groupId === openGroupId;

      if (!flatRecords.some(groupRecord)) {
        handleCloseGroup();
      }
    }
  }, [wasDeleted, data, openGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnEdit = (id: string) => {
    navigate(`/records/${carId}/edit/${id}`);
  };

  const handleOnCopy = (id: string) => {
    navigate(`/records/${carId}/${id}/copy`);
  };

  const handleOnDelete = (id: string) => {
    setIdToDelete(id);
  };

  const handleClose = () => {
    setIdToDelete('');
  };

  const handleConfirmDelete = () => {
    setWasDeleted(false);

    mutation.mutate(
      { id: idToDelete, carId },
      {
        onSuccess() {
          setIdToDelete('');
          toast(t('__toast__record_was_deleted'));

          setWasDeleted(true);
        },
      }
    );
  };

  return (
    <>
      <ConfirmationDialog
        description={t('confirm_dialog_record')}
        open={Boolean(idToDelete)}
        onConfirm={handleConfirmDelete}
        onClose={handleClose}
      />
      {data && data.length ? (
        <>
          {/* eslint-disable-next-line array-callback-return */}
          {data.map((record: Record | Record[]) => {
            if (!isArray(record) && !openGroupId) {
              return (
                <RecordItem
                  key={record.id}
                  data={record}
                  mileageMetric={mileageMetric}
                  totalExpenses={totalExpenses}
                  onDelete={handleOnDelete}
                  onEdit={handleOnEdit}
                  onCopy={handleOnCopy}
                />
              );
            }

            if (isArray(record)) {
              const id = record[0].groupId;

              // @ts-ignore
              return (
                <RecordsGroup
                  key={id}
                  data={record as Record[]}
                  openGroupId={openGroupId}
                  mileageMetric={mileageMetric}
                  totalExpenses={totalExpenses}
                  filterCategory={filterCategory}
                  handleOnDelete={handleOnDelete}
                  handleOnEdit={handleOnEdit}
                  handleOnCopy={handleOnCopy}
                  onOpen={handleOpenGroup}
                  onClose={handleCloseGroup}
                />
              );
            }
          })}
        </>
      ) : (
        <EmptyBox entityName='records' />
      )}
    </>
  );
};
