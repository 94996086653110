import { useQuery, UseQueryResult } from 'react-query';
import { axiosApiInstance as api } from 'utils';

const reviewQuery = async (): Promise<any> => {
  try {
    const { data } = await api.get('/engineroom/for-review');
    return  data;
  } catch (err) {
    return { error: true };
  }
};

export const useReviewQuery = (): UseQueryResult<any> => {
  return useQuery(['adminForReview'], () => reviewQuery(), {
    cacheTime: 0,
    enabled: true,
    refetchOnWindowFocus: false,
  });
};
