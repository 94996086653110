import { useMutation } from 'react-query';

import { axiosApiInstance as api } from 'utils';

export interface ForgotPasswordPayload {
  email: string;
}

const forgotPassword = async (payload: ForgotPasswordPayload): Promise<any> => {
  const { data } = await api.post('/users/forgot', payload);
  return data;
};

export const useForgotPasswordMutation = () => {
  return useMutation(forgotPassword);
};
