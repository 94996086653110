import { Theme, Typography } from '@mui/material';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { roundNumber } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

export type Stats = {
  title: string;
  count: string | number;
};

interface DashboardStatItemProps {
  data: Stats;
  color: string;
}

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textTransform: 'capitalize',
    borderRadius: spacing(0.5),
    border: '1px solid rgba(255, 255, 255, 0.08)',
    width: '100%',
    padding: spacing(2, 1.5),
    margin: spacing(1, 0),

    [breakpoints.up('md')]: {
      height: '100%',
      minHeight: 100,
      margin: spacing(0),
    },
  },

  primary: {
    backgroundColor: '#30009C',
  },
  secondary: {
    backgroundColor: '#004143',
  },
  count: {
    fontSize: '25px',
    paddingBottom: spacing(0.5),
    overflow: 'hidden',
    fontWeight: 500,
    [breakpoints.down('md')]: {
      fontSize: '20px',
    },
    [breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  title: {
    fontSize: '14px',
    [breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
});

export const DashboardStatItem: ComponentType<DashboardStatItemProps> = ({ data, color }) => {
  const { title, count } = data;
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const style = clsx(classes.root, classes[color]);
  const value = Number(count);

  return (
    <div className={style}>
      <Typography noWrap className={classes.count}>
        {roundNumber(value)}
      </Typography>
      <Typography noWrap variant='body2' className={classes.title}>
        {t(`__record_category__${title}`)}
      </Typography>
    </div>
  );
};
