import { ComponentType } from 'react';
import { IUser } from '../StoriesTypes';
import { UserItem } from './UserItem';

interface UsersListType {
  users: IUser[];
  followers?: boolean;
  block?: boolean;
}

export const UsersList: ComponentType<UsersListType> = ({ users, followers, block }) => {
  return (
    <>
      {users.map((user) => {
        return <UserItem key={user.id} user={user} follow={followers} block={block} />;
      })}
    </>
  );
};
