import { CommentPayload, LikeDislikeResponse } from 'features/stories-feed/StoriesTypes';
import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

const removeDislikeComment = async ({ carId, storyId, commentId }: CommentPayload): Promise<LikeDislikeResponse> => {
  const { data } = await api.delete(`/cars/${carId}/stories/${storyId}/comments/${commentId}/dislike`);
  return data;
};

export const useRemoveDislikeCommentMutation = () => {
  return useMutation(removeDislikeComment);
};
