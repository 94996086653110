import { ComponentType, useState } from 'react';
import { useAdminUserBansWarns } from '../hooks/useAdminUserBansWarns';
import { useTranslation } from 'react-i18next';
import { useClasses } from 'utils/hooks/useClasses';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { getTKey } from 'utils';
import { Chip, Collapse, IconButton, Theme } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
const tKey = getTKey('admin_user_details');
const styles = ({ palette, spacing }: Theme) => ({
  space: {
    flex: 1,
  },
  hideButton: {
    background: '#FF5050',
  },
  actionButton: {
    padding: spacing(0.5, 1.5),
    margin: '0 4px',
  },
  warn: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacing(1.25, 0),
    borderBottom: '1px solid',
    borderColor: palette.text.hint,
    alignItems: 'center',
  },
  chip: {
    color: palette.text.primary,
    background: palette.additionalSecondary[700],
    maxWidth: 250,
    height: 'unset',
    minHeight: 32,
    '& span': {
      whiteSpace: 'break-spaces',
      padding: spacing(0.75, 1.5),
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowWrap: {
    borderBottom: '1px solid',
    borderColor: '#484848',
    margin: spacing(1, 0),
  },
  banDate: {
    minWidth: 100,
    color: palette.text.hint,
  },
  type: {
    minWidth: 130,
    fontWeight: 600,
    margin: spacing(0, 0.5),
  },
  banTypeName: {
    color: '#FF5050',
  },
  warnTypeName: {
    color: '#FFB950',
  },
  warnsBans: {
    marginTop: spacing(2),
  },
  expandButtonOpen: {
    color: palette.primary.main,
    transform: 'rotate(180deg)',
  },
  expandWrap: {
    margin: spacing(2, 0, 1),
  },
  noteLabel: {
    color: palette.text.hint,
  },
  banName: {
    marginLeft: spacing(4),
  },
});
interface AdminUserBansWarnsType {
  user: string;
}

export const AdminUserBansWarns: ComponentType<AdminUserBansWarnsType> = ({ user }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const { bansWarns, isFetching } = useAdminUserBansWarns(user);

  const [open, setOpen] = useState<{ [id: string]: boolean }>({});

  const handleCollapse = (id: string) => {
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };
  return (
    <div className={classes.warnsBans}>
      {!isFetching && bansWarns && (
        <>
          {bansWarns.warnings?.map((warn) => (
            <div className={classes.warn} key={warn.id}>
              <div className={classes.row}>
                <div className={classes.banDate}>{dayjs(warn.date_created).format('DD-MM-YYYY')}</div>
                <div className={classes.type}>
                  <div className={classes.warnTypeName}>{t(tKey('type_warn'))}</div>
                </div>
                {warn.reason && (
                  <div className={classes.banName}>
                    <Chip className={classes.chip} label={warn.reason} />
                  </div>
                )}
              </div>
            </div>
          ))}

          {bansWarns.bans?.map((ban) => (
            <div className={classes.rowWrap} key={ban.id}>
              <div className={classes.row}>
                <div className={classes.banDate}>{dayjs(ban.ban_date).format('DD-MM-YYYY')}</div>
                <div className={classes.type}>
                  <div className={classes.banTypeName}>{t(tKey('type_ban_' + ban.type))}</div>
                </div>
                <div>{ban.banned_until === 'None' ? 'Unbanned' : dayjs(ban.banned_until).format('DD-MM-YYYY')}</div>
                <div className={classes.space}></div>
                <IconButton
                  color='inherit'
                  onClick={() => {
                    handleCollapse(ban.id);
                  }}
                  size='large'
                  className={clsx(classes.expandButton, {
                    [classes.expandButtonOpen]: open[ban.id],
                  })}
                >
                  <ExpandMore />
                </IconButton>
              </div>
              <Collapse in={open[ban.id]} timeout='auto' unmountOnExit>
                <div className={classes.expandWrap}>
                  <div className={classes.noteLabel}>{t(tKey('note_label'))}: </div>
                  {ban.note}
                </div>
              </Collapse>
            </div>
          ))}
        </>
      )}
    </div>
  );
};
