import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useDeleteRecordGroupMutation } from 'services';

type useRecordsGroupType = (callbackFn: () => void) => {
  deleteId: string;
  handleGroupDelete: (id: string) => void;
  handleClose: () => void;
  handleConfirmDelete: () => void;
};

export const useRecordsGroup: useRecordsGroupType = (callbackFn) => {
  const { t } = useTranslation();
  const mutation = useDeleteRecordGroupMutation();
  const [deleteId, setDeleteId] = useState<string>('');

  const handleGroupDelete = (id: string) => {
    setDeleteId(id);
  };

  const handleClose = () => {
    setDeleteId('');
  };

  const handleConfirmDelete = () => {
    mutation.mutate(deleteId, {
      onSuccess() {
        toast(t('__toast__records_group_was_deleted'));
        setDeleteId('');
        callbackFn();
      },
    });
  };

  return {
    deleteId,
    handleGroupDelete,
    handleClose,
    handleConfirmDelete,
  };
};
