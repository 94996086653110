import { Story } from 'components/Stories/AddStoryContent';
import { uniqueId } from 'lodash';
import PQueue from 'p-queue';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCarsQuery, useRecordsByCarQuery } from 'services';
import { useCreateStoryDraftMutation } from 'services/story/hooks/useCreateStoryDraftMutation';
import { useDeleteDraftMutation } from 'services/story/hooks/useDeleteDraftQuery';
import { useDraftQuery } from 'services/story/hooks/useDraftsQuery';
import { usePublishStoryDraft } from 'services/story/hooks/usePublishStoryDraft';
import { useUpdateStoryDraft } from 'services/story/hooks/useUpdateStoryDraft';
import { useUploadStoryImageMutation } from 'services/story/hooks/useUploadStoryImageMutation';
import { Car, Record } from 'types';
import { STORY_ELEMENT_TYPE } from 'utils';
import { getAvailableYoutubeCoverUrl, getYoutubeIdFromUrl } from 'utils/YouTubeUtil';
import { transformStory } from 'utils/transformStory';
import { transformDraft } from 'utils/trasformDraft';

export const useCreateStory = () => {
  const { t } = useTranslation();
  const imageUploadMutation = useUploadStoryImageMutation();
  const draftImageUploadQueue = new PQueue({ concurrency: 1 });
  const [elements, setElements] = useState<Story[]>([]);
  const [title, setTitle] = useState('');
  const [records, setRecords] = useState<{ [id: string]: Record }>({});
  const [recordGroup, setRecordGroup] = useState<{ [id: string]: Record[] }>({});
  const [coverIndex, setCoverIndex] = useState({ elementIndex: -1, imageIndex: -1 });
  const [disabled, setDisabled] = useState(true);
  const [unfinishedDraft, setUnfinishedDraft] = useState(false);
  const [leaveDraft, setLeaveDraft] = useState(false);

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { data: cars, isLoading } = useCarsQuery();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedCar, setSelectedCar] = useState<{ car: Car | undefined; hideMileage: boolean; mileage: number }>({
    hideMileage: false,
    car: undefined,
    mileage: 0,
  });
  const [storyId, setStoryId] = useState('');
  const [descLength, setDescLength] = useState(0);
  const mutation = useCreateStoryDraftMutation();
  const updateStoryMutation = useUpdateStoryDraft();
  const deleteDraftMutation = useDeleteDraftMutation();
  const { data: recordsCar, refetch: recordRefetch } = useRecordsByCarQuery(selectedCar?.car?.id ?? '', 'records', {
    enabled: selectedCar.car?.id !== undefined,
  });
  const navigate = useNavigate();
  const {
    data: carDraft,
    isLoading: carDraftLoading,
    refetch,
  } = useDraftQuery(selectedCar?.car?.id ?? '', selectedCar.car?.id !== undefined);
  const publishMutation = usePublishStoryDraft();

  useEffect(() => {
    setDescLength(
      elements.reduce((prev, element) => {
        if (element.elementType === STORY_ELEMENT_TYPE.TEXT) {
          return (element?.texts?.[0]?.originalText?.replaceAll(' ', '')?.length ?? 0) + prev;
        }
        return prev;
      }, 0)
    );
  }, [elements]);
  const clearState = () => {
    setStoryId('');
    setElements([]);
    setTitle('');
    setRecords({});
    setRecordGroup({});
    setCoverIndex({ elementIndex: -1, imageIndex: -1 });
    setSelectedCar({
      hideMileage: false,
      car: undefined,
      mileage: 0,
    });
  };
  const transformDraftNext = () => {
    if (carDraft) {
      const { carId, isMileageHidden, car_mileage, stories, storyId, title } = transformDraft(carDraft);
      setTitle(title);
      setSelectedCar({
        hideMileage: isMileageHidden,
        mileage: car_mileage,
        car: cars?.find((car) => car.id === carId),
      });
      if (stories) {
        setElements(stories);

        stories?.forEach((story: Story, sIndex: number) => {
          if (story.elementType === STORY_ELEMENT_TYPE.GALLERY) {
            story.images?.map((image, iIndex) => {
              if (image.isCover === true) {
                setCoverIndex({ elementIndex: sIndex, imageIndex: iIndex });
                return null;
              }
              return null;
            });
          }
          if (story.elementType === STORY_ELEMENT_TYPE.RECORD) {
            var records = (story.records ?? []).reduce(
              (prev, record) => ({
                ...prev,
                [record.recordId]: recordsCar.find((carRecord: any) => record.recordId === carRecord.id) as Record,
              }),
              {}
            );
            setRecords((prevRecords) => ({ ...prevRecords, ...records }));
          }
          if (story.elementType === STORY_ELEMENT_TYPE.ASSISTED_RECORD) {
            let records = (story.assistedRecords ?? []).reduce(
              (prev, record) => ({
                ...prev,
                [record.groupId]: recordsCar.find((carRecord: any) => record.groupId === carRecord.groupId) as Record,
              }),
              {}
            );
            setRecordGroup((prevRecords) => ({ ...prevRecords, ...records }));
          }
        });
      }
      setStoryId(storyId);
      setUnfinishedDraft(false);
      setActiveStep((step) => {
        return step + 1;
      });
    }
  };
  const onDeleteDraft = () => {
    return new Promise((resolve, reject) => {
      deleteDraftMutation.mutate(
        { carId: selectedCar.car?.id ?? '', storyId: carDraft?.id ?? '' },
        {
          onSuccess(data, variables, context) {
            resolve(data);
          },
        }
      );
    });
  };
  const addRecord = (records: Record[]) => {
    const group = records.reduce(
      (prev, record) => {
        return { ...prev, [record.id ?? '']: record };
      },

      {} as { [id: string]: Record }
    );

    setRecords((groups) => ({ ...groups, ...group }));
    setElements((elements) => {
      const temp = [
        ...elements,
        {
          id: uniqueId('element'),
          elementType: STORY_ELEMENT_TYPE.RECORD,
          records: records.map((record) => ({ recordId: record.id })),
        },
      ];
      // updateDraft(temp);

      return temp;
    });
  };

  const addRecordGroup = (records: Record[][]) => {
    const group = records.reduce(
      (prev, record) => {
        return { ...prev, [record[0]?.groupId ?? '']: record };
      },

      {} as { [id: string]: Record[] }
    );

    setRecordGroup((groups) => ({ ...groups, ...group }));

    setElements((elements) => {
      const temp = [
        ...elements,
        {
          id: uniqueId('element'),
          elementType: STORY_ELEMENT_TYPE.ASSISTED_RECORD,
          assistedRecords: records.map((record) => ({ groupId: record[0]?.groupId ?? '' })),
        },
      ];
      // updateDraft(temp);
      return temp;
    });
  };

  const addGallery = (images: any[]) => {
    setElements((elements) => {
      const urls: Story = images.reduce((prev, image, index) => {
        const filename = image.file.name.split('.');
        const ext = filename[filename.length - 1];
        const file = `${elements.length === 0 ? 1 : elements.length}_${index}.${ext}`;
        if (prev?.images) {
          const newElement = {
            ...prev,
            images: [
              ...prev.images,
              {
                id: image?.id || uniqueId('element'),
                dataUrl: image.data_url,
                url: image.url,
                isCover: false,
                file: image.file,
                filename: file,
                imageId: image?.image_id,
              },
            ],
          };
          return newElement;
        }

        const newElement = {
          id: uniqueId('element'),
          elementType: STORY_ELEMENT_TYPE.GALLERY,
          images: [
            {
              dataUrl: image.data_url,
              url: image.url,
              isCover: false,
              file: image.file,
              filename: file,
            },
          ],
        };
        return newElement;
      }, {});
      // updateDraft([...elements, urls]);
      return [...elements, urls];
    });
  };

  const addDescription = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    setElements((arr) => {
      let temp = [...arr];
      if (temp?.[index].texts?.[0]) {
        temp[index].texts = [{ originalText: event.target.value }];
        return temp;
      } else return [...arr];
    });
  };

  const addText = () => {
    setElements((elements) => [
      ...elements,
      {
        id: uniqueId('element'),
        elementType: STORY_ELEMENT_TYPE.TEXT,
        texts: [
          {
            originalText: '',
          },
        ],
      },
    ]);
  };

  const addVideo = async (link: string) => {
    const cover = await getAvailableYoutubeCoverUrl(getYoutubeIdFromUrl(link) as string);
    setElements((elements) => [
      ...elements,
      {
        id: uniqueId('element'),
        elementType: STORY_ELEMENT_TYPE.VIDEO,
        youtubeVideoUrl: link,
        youtubeVideoCoverUrl: cover,
        images: [
          {
            url: cover as string,
            imageId: uniqueId('element'),
            isCover: false,
            dataUrl: cover as string,
          },
        ],
      },
    ]);
  };

  const removeElement = (index: number) => {
    setElements((elements) => {
      if (index > -1) {
        const temp = [...elements];
        temp.splice(index, 1);
        // updateDraft(temp);
        return temp;
      }
      return elements;
    });
  };
  const setCover = ({ elementIndex, imageIndex }: { elementIndex: number; imageIndex: number }) => {
    setCoverIndex({ elementIndex, imageIndex });
    setElements((elements) => {
      let temp = [...elements];
      temp = elements.map((element, eIndex) => {
        if (element.elementType === STORY_ELEMENT_TYPE.GALLERY || element.elementType === STORY_ELEMENT_TYPE.VIDEO) {
          element.images = element?.images?.map((image, iIndex) => {
            return {
              ...image,
              dataUrl: image.dataUrl,
              url: image.url,
              isCover: (eIndex === elementIndex && imageIndex === iIndex) || false,
            };
          });
        }
        return element;
      });
      // updateDraft(temp);
      return temp;
    });
    setDisabled(false);
  };

  const autoSelectCover = () => {
    if (coverIndex.elementIndex !== -1) {
      return false;
    }
    const images = elements
      // eslint-disable-next-line array-callback-return
      .map((element) => {
        if (element.elementType === STORY_ELEMENT_TYPE.GALLERY) {
          return element.images;
        }
      })
      .flat();
    if (images.length === 0) {
      return false;
    } else if (images.length === 1) {
      setCover({ elementIndex: 0, imageIndex: 0 });
      return false;
    } else {
      return true;
    }
  };

  const publishStory = (updatedElements = elements) => {
    setDisabled(true);
    setSaving(true);
    try {
      if (loading) {
        return;
      }
      setLoading(true);

      const creatingElements = JSON.parse(JSON.stringify(updatedElements));

      publishMutation.mutate(
        {
          carId: selectedCar.car?.id ?? '',
          elements: transformStory(creatingElements, title, selectedCar.hideMileage, selectedCar.mileage),
        },
        {
          onSuccess(data) {
            setLoading(false);
            navigate(`/stories/${data.car.id}/${data.id}`);
            toast(t('create_story_publish_success'));
            setDisabled(false);
          },
          onError(error: any, variables, context) {
            if (error?.response?.data?.message === 'story_banned') {
              toast(t('create_story_banned'));
            } else {
              toast(t('__common__error'));
            }
            setSaving(false);
            setLoading(false);
            setDisabled(false);
          },
        }
      );
    } catch (e) {
      setDisabled(false);
      setLoading(false);
      setSaving(false);
      toast(t('create_story_publish_failed'));
    }
  };
  const handleNext = async () => {
    try {
      if (loading) {
        return;
      }
      if (activeStep === 0 && !carDraftLoading && carDraft && carDraft?.id) {
        return setUnfinishedDraft(true);
      }
      const imageElementsLength = elements.filter(
        (element) =>
          element.elementType === STORY_ELEMENT_TYPE.GALLERY || element.elementType === STORY_ELEMENT_TYPE.VIDEO
      ).length;
      const textElementsLength = elements.filter(
        (element) =>
          element.elementType === STORY_ELEMENT_TYPE.TEXT && element?.texts && element.texts[0].originalText.length
      ).length;
      if (activeStep === 1 && !imageElementsLength && !textElementsLength) {
        toast(t('create_story_no_text_or_image'));
        setDisabled(true);
        return;
      }
      if (activeStep === 1 && !title) {
        toast(t('create_story_no_title'));
        setDisabled(true);
        return;
      }
      if (activeStep === 1 && !imageElementsLength) {
        setActiveStep((step) => {
          return step + 2;
        });
      } else if (activeStep !== 3) {
        setActiveStep((step) => {
          return step + 1;
        });
      } else {
        setLoading(true);
        setSaving(true);
        const elementsFiltered = elements.map((element) => {
          if (element.elementType === STORY_ELEMENT_TYPE.VIDEO) {
            const images = element.images?.map((image, _index) => {
              image.dataUrl = undefined;
              return image;
            });
            element.images = images;
            return element;
          } else return element;
        });
        if (imageElementsLength) {
          let updatedElements = elements
            .map((element, index) => {
              if (element.elementType === STORY_ELEMENT_TYPE.GALLERY) {
                return element.images?.map((image, _index) => {
                  if (image.filename) {
                    const filename = image.filename.split('.');
                    const ext = filename[filename.length - 1];
                    const file = `${index + 1}_${_index}.${ext.split('?')[0]}`;
                    image.filename = file;
                  }
                  return image;
                });
              }
              return [];
            })
            .flat();
          var storyElement = JSON.parse(JSON.stringify(elementsFiltered));
          imageUploadMutation.mutate(
            { carId: selectedCar?.car?.id ?? '', images: updatedElements ?? [] },
            {
              onSuccess(data) {
                Object.values(data ?? []).forEach((image: any) => {
                  //@ts-ignore
                  storyElement[image.position - 1].images[image.index] = {
                    ...storyElement?.[image.position - 1]?.images?.[image.index],
                    url: image.path,
                    path: image.path,
                    imageId: image.image_id,
                    isDraft: true,
                  };
                });
                publishStory(storyElement);
              },
            }
          );
        } else return publishStory();
      }
    } catch (e) {
      setLoading(false);
      setSaving(false);
      toast(t('create_story_publish_failed'));
    }
  };

  const updateDraft = (newElement = elements) => {
    return new Promise((resolve, reject) => {
      try {
        newElement = elements.map((element) => {
          if (element.elementType === STORY_ELEMENT_TYPE.VIDEO) {
            const images = element.images?.map((image, _index) => {
              image.dataUrl = undefined;
              return image;
            });
            element.images = images;
            return element;
          } else return element;
        });
        if (newElement.filter((element) => element.elementType === STORY_ELEMENT_TYPE.GALLERY).length) {
          let cover = true;
          let updatedElements = newElement
            .map((element, index) => {
              if (element.elementType === STORY_ELEMENT_TYPE.GALLERY) {
                return element.images?.map((image, _index) => {
                  if (image.filename) {
                    const filename = image.filename.split('.');
                    const ext = filename[filename.length - 1];
                    const file = `${index + 1}_${_index}.${ext.split('?')[0]}`;
                    image.filename = file;
                  }
                  return image;
                });
              }

              return [];
            })
            .flat();
          var storyElement = JSON.parse(JSON.stringify(newElement));

          resolve(
            draftImageUploadQueue.add(
              () =>
                new Promise((resolve, reject) => {
                  imageUploadMutation.mutate(
                    { carId: selectedCar?.car?.id ?? '', images: updatedElements ?? [] },
                    {
                      onSuccess(data) {
                        Object.values(data ?? []).forEach((image: any) => {
                          storyElement[image.position - 1].images[image.index] = {
                            ...storyElement?.[image.position - 1]?.images?.[image.index],
                            url: image.path,
                            path: image.path,
                            isCover: cover,
                            imageId: image.image_id,
                            isDraft: true,
                          };

                          cover = false;
                        });
                        const storyElementPrepare = JSON.parse(JSON.stringify(storyElement));
                        resolve(updateDraftMutation(storyElementPrepare));
                      },
                      onError: (error) => {
                        setLoading(false);
                      },
                    }
                  );
                })
            )
          );
        } else {
          const storyElementForUpdate = JSON.parse(JSON.stringify(newElement));
          resolve(updateDraftMutation(storyElementForUpdate));
        }
      } catch (e) {
        setLoading(false);
        toast('Failed to save draft');
        reject(e);
      }
    });
  };

  const handlePrevious = () => {
    const imageElementsLength = elements.filter((element) => element.elementType === STORY_ELEMENT_TYPE.GALLERY).length;
    if (activeStep === 3 && !imageElementsLength) {
      setActiveStep((step) => {
        return step - 2;
      });
    } else {
      setActiveStep((step) => {
        return step - 1;
      });
    }
  };
  const updateDraftMutation = (updatedElements = elements) => {
    return new Promise((resolve, reject) => {
      try {
        if (storyId) {
          updateStoryMutation.mutate(
            {
              carId: selectedCar.car?.id ?? '',
              elements: transformStory(
                JSON.parse(JSON.stringify(updatedElements)),
                title,
                selectedCar.hideMileage,
                selectedCar.mileage
              ),
              storyId,
            },
            {
              onSuccess(data) {
                setLoading(false);
                resolve(data);
              },
            }
          );
        } else if (storyId === '' && (title !== '' || updatedElements.length > 0)) {
          mutation.mutate(
            {
              elements: transformStory(
                JSON.parse(JSON.stringify(updatedElements)),
                title,
                selectedCar.hideMileage,
                selectedCar.mileage
              ),
              carId: selectedCar.car?.id ?? '',
            },
            {
              onSuccess: (data) => {
                setLoading(false);
                setStoryId(data.id);
                toast(t('saving_draft_success'));
                resolve(data);
              },
            }
          );
        }
      } catch (e) {
        setLoading(false);
        toast(t('saving_draft_failed'));
        reject(e);
      }
    });
  };

  return {
    elements,
    activeStep,
    recordGroup,
    records,
    title,
    coverIndex,
    disabled,
    selectedCar,
    carDraft,
    carDraftLoading,
    cars,
    unfinishedDraft,
    loading,
    isLoading,
    descLength,
    leaveDraft,
    saving,
    onDeleteDraft,
    recordRefetch,
    setUnfinishedDraft,
    transformDraftNext,
    refetch,
    setDisabled,
    setElements,
    setActiveStep,
    setCover,
    addDescription,
    addGallery,
    addRecord,
    addText,
    addRecordGroup,
    addVideo,
    setSelectedCar,
    handleNext,
    updateDraft,
    removeElement,
    setTitle,
    handlePrevious,
    clearState,
    setLeaveDraft,
    autoSelectCover,
    setSaving,
  };
};
