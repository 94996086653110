import { useInfiniteQuery } from "react-query";
import { axiosApiInstance as api, mapKeysToSnakeCase } from 'utils';
import { storiesFeedResponse } from "./useStoriesFeedQuery";

export interface storySearchPagination {
  query?: string,
  language?: string;
  make?: string;
  model?: string;
  page: number;
  perPage: number;
}

export const getStoriesSearch = async (page: number, pagination: storySearchPagination): Promise<storiesFeedResponse> => {
  pagination = {...pagination, page};
  const { data } = await api.get('/stories/search', {params: mapKeysToSnakeCase(pagination)});
  return data;
};

export const useStoriesSearchQuery = (pagination: storySearchPagination) => {
  return useInfiniteQuery(
    ['getStories', pagination],
    ({pageParam: page = 1}) => getStoriesSearch(page, pagination),
    {
      cacheTime: 0,
      getNextPageParam(lastPage, allPages){
        return lastPage.stories.length > 0 ? lastPage.current_page + 1 : undefined
      },
      enabled: false,
    },
    
  );
};
