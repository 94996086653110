import { useQuery } from 'react-query';

import { axiosApiInstance as api } from 'utils';

const getTimezone = async () => {
  const { data } = await api.get(`/users/timezone`);
  return data;
};

export const useTimezoneQuery = () => {
  return useQuery(['userTimezone'], () => getTimezone())
};
