import { useMutation } from 'react-query';

import { axiosApiInstance as api, mapKeysToCamelCase } from 'utils';

export type AppleConnectResponse = {
  message: string;
  error?: string;
};

type AppleConnectRequest = {
  email: string;
  apple_id: string;
  language?: string;
  access_token: string;
};

const connectApple = async (body: AppleConnectRequest): Promise<Partial<AppleConnectResponse>> => {
  try {
    const { data } = await api.post('/users/apple-connect', body);
    return mapKeysToCamelCase({ ...data, apple_access_token: body.access_token });
  } catch (error: any) {
    return { error: error.response.data.message };
  }
};

export const useAppleConnectMutation = () => {
  return useMutation(connectApple);
};
