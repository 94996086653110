import { ExpandMore } from '@mui/icons-material';
import { Chip, Collapse, Divider, Grid, IconButton, Theme, Typography } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { RecordAttachmentGallery } from 'features/records/components';
import { IRecord } from 'features/stories-feed/StoriesTypes';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Currency, Volume } from 'types';
import { currencySymbol, getTKey, volumeMetric } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { TranslateContext } from '../StoryWrapper';
const tKey = getTKey('record_item');
const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {},
  record: {
    display: 'flex',
    flexDirection: 'column',
    background: 'rgba(255, 255, 255, 0.06)',
    borderRadius: 4,
    padding: spacing(2),
    margin: spacing(1.25, 0),
    border: '1px solid rgba(255, 255, 255, 0.06)',
  },
  rootExpanded: {
    background: 'rgba(187, 134, 252, 0.1)',
    border: '1px solid #BB86FC',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: palette.text.hint,
  },
  title: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '26px',
    letterSpacing: 0.15,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerMain: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dot: {
    margin: spacing(0, 0.5),
    width: 4,
    height: 4,
    borderRadius: 2,
    background: palette.additionalPrimary['200'],
  },
  cost: {
    fontSize: 34,
    marginRight: 16,
    fontWeight: 600,
  },
  chip: {
    order: 1,
    flexShrink: 1,
    maxWidth: 160,
    marginLeft: spacing(2),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: palette.additionalSecondary['800'],
    color: palette.text.primary,

    [breakpoints.up('md')]: {
      order: 'unset',
      marginLeft: 'auto',
    },
    [breakpoints.down('sm')]: {
      maxWidth: 140,
      marginLeft: spacing(1),
    },
  },
  expandButtonOpen: {
    color: palette.primary.main,
    transform: 'rotate(180deg)',
  },
  hint: {
    fontSize: 12,
    color: palette.text.hint,
  },
  recordText: {
    fontSize: 16,
    marginBottom: 16,
  },
  divider: {
    background: palette.text.hint,
    marginTop: 20,
    marginBottom: 16,
  },
  subtitle: {
    marginBottom: spacing(0.5),
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.6)',
  },
  attachmentsRow: {
    marginTop: spacing(0.5),

    '& > div': {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
    },
  },
});

interface StoryRecordsType {
  records: Array<IRecord>;
  isGroup?: boolean;
}

export const StoryRecords: ComponentType<StoryRecordsType> = ({ records, isGroup }) => {
  const classes = useClasses(styles);
  const isShowHr = isGroup && records.length !== 0;
  return (
    <div className={classes.root}>
      {isShowHr && <hr />}
      {records.map((record) => {
        return <StoryRecord key={record.id} record={record} />;
      })}
      {isShowHr && <hr />}
    </div>
  );
};

interface StoryRecordType {
  record: IRecord;
}

export const StoryRecord: ComponentType<StoryRecordType> = ({ record }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const expandCondition = record.part_name || record.details || record?.image_one;

  const fuelCategoryUnit: { [category: string]: Volume | 'kWh' } = {
    refueling: (record.volume_metric ?? 'l') as Volume,
    recharging: 'kWh',
  };

  const fuelCategory = (category: string) => category === 'refueling' || category === 'recharging';

  const getUnit = (plural?: boolean) =>
    t(tKey(volumeMetric[fuelCategoryUnit[record.category] as Volume] + (plural ? 's' : '')));

  const handleToggleClick = () => {
    setExpanded(!expanded);
  };
  return (
    <div
      className={clsx(classes.record, {
        [classes.rootExpanded]: expanded,
      })}
      onClick={expandCondition ? handleToggleClick : undefined}
    >
      <div className={classes.header}>
        {dayjs(record.date_performed).format('DD-MM-YYYY')}
        <div className={classes.dot}></div>
        {record.mileage} {record.mileage_metric}
      </div>
      <div className={classes.title}>
        <div className={classes.title}>
          {fuelCategory(record.category) && `${getUnit(true)}: `}
          <TranslateContext.Consumer>
            {(noTranslate) => (!record.do_not_translate && !noTranslate ? record.translated_title : record.title)}
          </TranslateContext.Consumer>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.footerMain}>
          <div className={classes.cost}>
            {record.cost}
            {currencySymbol[record.currency as Currency]}
          </div>
          <Chip label={t(`__record_category__${record.category}`)} className={classes.chip} />
        </div>
        {expandCondition && (
          <div className='footerExpand'>
            <IconButton
              color='inherit'
              size='large'
              className={clsx(classes.expandButton, {
                [classes.expandButtonOpen]: expanded,
              })}
            >
              <ExpandMore />
            </IconButton>
          </div>
        )}
      </div>
      <Collapse in={expanded} timeout='auto' mountOnEnter>
        <Divider className={classes.divider} />
        <Grid container spacing={2}>
          {fuelCategory(record.category) ? (
            <>
              {record.details && (
                <Grid item xs={3} md={3}>
                  <div className={classes.subtitle}>{t(tKey('cost_per'), { unit: getUnit() })}:</div>
                  <Typography variant='body1' className={classes.details}>
                    {record.details}
                  </Typography>
                </Grid>
              )}
              {record.part_name && (
                <Grid item xs={3} md={3}>
                  <div className={classes.subtitle}>{t(tKey('fuel_type'))}:</div>
                  <Typography variant='body1' className={classes.details}>
                    <TranslateContext.Consumer>
                      {(noTranslate) =>
                        !record.do_not_translate && !noTranslate ? record.translated_part_name : record.part_name
                      }
                    </TranslateContext.Consumer>
                  </Typography>
                </Grid>
              )}
            </>
          ) : (
            record.details && (
              <Grid item xs={12} md={5}>
                <div className={classes.subtitle}>{t(tKey('details'))}:</div>
                <Typography variant='body1' className={classes.details}>
                  <TranslateContext.Consumer>
                    {(noTranslate) =>
                      !record.do_not_translate && !noTranslate ? record.translated_details : record.details
                    }
                  </TranslateContext.Consumer>
                </Typography>
              </Grid>
            )
          )}
          {record?.image_one && (
            <Grid item xs={12} md={12}>
              <div className={classes.subtitle}>{t(tKey('attachment'))}:</div>
              <div className={classes.attachmentsRow}>
                <RecordAttachmentGallery
                  images={[
                    record?.image_one || null,
                    record?.image_two || null,
                    record?.image_three || null,
                    record?.image_four || null,
                    record?.image_five || null,
                  ]}
                />
              </div>
            </Grid>
          )}
        </Grid>
      </Collapse>
    </div>
  );
};
