import { InnerMobileHeader, PageLoader } from 'components';
import { CurrencyForm } from 'components/v2/Settings';
import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useProfileSettingsQuery } from 'services';

export const CurrencyTab: ComponentType = () => {
  const { t } = useTranslation();
  const { data, isFetching } = useProfileSettingsQuery();

  useEffect(() => {
    //@ts-ignore
    gtag?.('event', 'visited_settings', { method: 'visited_settings' });
  }, []);

  return (
    <>
      <InnerMobileHeader title={t('settings_currency_title')} showBack />
      {isFetching && <PageLoader />}
      {!isFetching && data && <CurrencyForm preloadedValues={data} />}
    </>
  );
};
