import {
  carReminderChangePayload,
  useCompleteReminderMutation,
  useIncompleteReminderMutation,
} from 'services/reminders';

export const useCompleteReminder = () => {
  const mutationsComplete = useCompleteReminderMutation();
  const mutationsIncomplete = useIncompleteReminderMutation();

  const completeReminder = ({ carId, reminderId }: carReminderChangePayload) => {
    return new Promise((resolve, reject) => {
      mutationsComplete.mutate(
        { carId, reminderId },
        {
          onSuccess(data, variables, context) {
            resolve(data);
          },
        }
      );
    });
  };

  const incompleteReminder = ({ carId, reminderId }: carReminderChangePayload) => {
    return new Promise((resolve, reject) => {
      mutationsIncomplete.mutate(
        { carId, reminderId },
        {
          onSuccess(data, variables, context) {
            resolve(data);
          },
        }
      );
    });
  };

  return {
    completeReminder,
    incompleteReminder,
  };
};
