
import { ReportStoryPayload } from 'features/stories-feed/StoriesTypes';
import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

export const reportStory = async ({carId, storyId, body }: ReportStoryPayload) => {
  const { data } = await api.post(`/cars/${carId}/stories/${storyId}/report`, body);
  return data;
}

export const useStoryReportMutation = () =>{
  return useMutation(reportStory);
}