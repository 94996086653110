import { InnerMobileHeader } from 'components';
import { NotificationsForm } from 'components/v2/Settings';
import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserNotificationsQuery } from 'services';

export const NotificationsTab: ComponentType = () => {
  const { t } = useTranslation();

  const { data: notifications, isFetching } = useUserNotificationsQuery();
  useEffect(() => {}, []);

  return (
    <>
      <InnerMobileHeader title={t('settings_notifications_title')} showBack />
      {notifications && !isFetching && <NotificationsForm notificationsData={notifications} />}
    </>
  );
};
