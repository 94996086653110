import React, { ComponentType } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@mui/material';

interface FormCheckboxProps {
  name: string;
  defaultValue?: boolean;
  label?: string;
}

export const FormCheckbox: ComponentType<FormCheckboxProps> = ({ name, defaultValue, label }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              defaultChecked={defaultValue}
              color='primary'
            />
          }
          label={label}
        />
      )}
    />
  );
};
