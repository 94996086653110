import { Button } from "@mui/material";
import { useFollowUserModify } from "features/alerts/useFollowUserModify";
import mitt from 'mitt';
import { ComponentType, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTKey } from "utils";
import { useClasses } from "utils/hooks/useClasses";
const emitter = mitt();
const tKey = getTKey('follow_button')

const styles = () => ({
  button: {
    fontSize: 12,
    borderRadius: 32,
    width: 120
  }
})

interface FollowButtonType {
  userId: string;
  followed: boolean,
}

export const FollowButton: ComponentType<FollowButtonType> = ({userId, followed}) => {
  const {t} = useTranslation()
  const classes = useClasses(styles)
  const [ followedState, setFollowedState] = useState(followed);

  const mutation  = useFollowUserModify()

  const handler = async () =>{
    await mutation.follow(userId, !followedState)
    setFollowedState(!followedState)
    const event = new Event('follow');
    document.dispatchEvent(event);
    emitter.emit('follow', {userId, follow: !followedState})
  } 

  useEffect(() => {
    emitter.on('follow', (event)=>{
      //@ts-ignore
      if(event['userId'] === userId){
        //@ts-ignore
        setFollowedState(event['follow'])
      }
    });
    return () => {
      emitter.off('follow');
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 

  return (
    <Button 
      className={classes.button} 
      color={followedState ? 'neutral' : 'primary'} 
      variant='contained' 
      size="small"
      onClick={handler}
    >
      {followedState ? t(tKey('unfollow')) : t(tKey('follow'))}
    </Button>
  )
}