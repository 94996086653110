import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
} from '@mui/material';
import { ChangeEvent, ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { ItemActionType } from '../AdminReview/AdminItemTypes';
import { Close } from '@mui/icons-material';
import { DurationType, durationsBan } from '../AdminDashboardTypes';

const tKey = getTKey('admin_dialog_ban');
const styles = ({ palette, spacing }: Theme) => ({
  description: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
  titleWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  closeButton: {
    color: palette.text.hint,
  },
  actions: {
    '& button': {
      letterSpacing: 1.25,
    },
  },
  field: {
    margin: spacing(2.75, 0),
  },
  helperText: {
    padding: spacing(0, 2.5),
    color: palette.text.hint,
    fontSize: 12,
  },
});

interface UserBanDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (ban_from: ItemActionType, duration: DurationType, note: string) => void;
  haveCarOption?: boolean;
}

export const UserBanDialog: ComponentType<UserBanDialogProps> = ({ open, onClose, onConfirm, haveCarOption }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const fromOptions: ItemActionType[] = ['creating_story', 'commenting', 'profile'];
  if (haveCarOption) {
    fromOptions.push('car');
  }
  const [from, setFrom] = useState<ItemActionType>('creating_story');

  const handleChangeFrom = (event: SelectChangeEvent) => {
    setFrom(event.target.value as ItemActionType);
  };

  const [duration, setDuration] = useState<DurationType>('24_hours');

  const handleChangeDuration = (event: SelectChangeEvent) => {
    setDuration(event.target.value as DurationType);
  };

  const [note, setNote] = useState<string>('');

  const handleChangeNote = (event: ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value as string);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='xs'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title' className={classes.titleWrap}>
        {t(tKey('title'))}
        <IconButton onClick={onClose}>
          <Close className={classes.closeButton} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth className={classes.field}>
          <InputLabel id='ban-from-select-label'>{t(tKey('ban_from'))}</InputLabel>
          <Select
            labelId='ban-from-select-label'
            id='ban-from-select'
            value={from}
            label={t(tKey('ban_from'))}
            onChange={handleChangeFrom}
          >
            {fromOptions.map((fromOption) => {
              return (
                <MenuItem key={fromOption} value={fromOption}>
                  {t('admin_dialog_' + fromOption)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth className={classes.field}>
          <InputLabel id='duration-select-label'>{t(tKey('duration'))}</InputLabel>
          <Select
            labelId='duration-select-label'
            id='duration-select'
            value={duration}
            label={t(tKey('duration'))}
            onChange={handleChangeDuration}
          >
            {durationsBan.map((durationOption) => {
              return (
                <MenuItem key={durationOption} value={durationOption}>
                  {t(tKey(durationOption))}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth className={classes.field}>
          <TextField
            required
            id='standard-multiline-static'
            label={t(tKey('note'))}
            multiline
            rows={4}
            value={note}
            onChange={handleChangeNote}
          />
        </FormControl>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button onClick={onClose} color='inherit'>
          {t('__common__cancel')}
        </Button>
        <Button onClick={() => onConfirm(from, duration, note)} color='error' disabled={note === ''}>
          {t('__common__confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
