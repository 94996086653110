import React, { ComponentType, useEffect } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';

import { SearchSelect } from 'components';
import { useCarModelsData } from './useCarModelsData';
import { useTranslation } from 'react-i18next';

interface CarModelSelectProps {
  defaultModel: any;
  labelBackground?: string; 
}

export const CarModelSelect: ComponentType<CarModelSelectProps> = ({ defaultModel, labelBackground }) => {
  const {t} = useTranslation();
  const chosenMake = useWatch({ name: 'make' });
  const { setValue } = useFormContext();
  const { models } = useCarModelsData(chosenMake && chosenMake.value);

  useEffect(() => {
    if (!chosenMake) {
      setValue('model', null);
    } else if (defaultModel && models.length) {
      setValue('model', { label: defaultModel, value: defaultModel });
    }
  }, [defaultModel, models, chosenMake]); // eslint-disable-line react-hooks/exhaustive-deps

  return <SearchSelect name='model' options={models} placeholder='models' label={t('search_select_label_model')} labelBackground={labelBackground}/>;
};
