import { useMutation } from 'react-query';

import { Record } from 'types';
import { axiosWithFileInstance } from 'utils';

interface CreateRecordPayload {
  carId: string;
  formData: any;
}

const createRecords = async ({ carId, formData }: CreateRecordPayload): Promise<Record[]> => {
  const { data } = await axiosWithFileInstance.post(`/cars/${carId}/records/batch`, formData);
  return data;
};

export const useCreateRecordsBatchMutation = () => {
  return useMutation(createRecords);
};
