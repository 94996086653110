import { useQuery, UseQueryResult } from 'react-query';

import { axiosApiInstance as api } from 'utils';

const getCarModels = async (name: string): Promise<any> => {
  var clean_name = name.replace(" ", "_")
  const { data } = await api.get(`/data/car-model?make_query=${clean_name}`);

  return data;
};

export const useCarModelsQuery = (name: string): UseQueryResult<any> => {
  return useQuery(['carModels', name], () => getCarModels(name), { enabled: !!name });
};
