import { TFunction } from 'react-i18next';
import * as yup from 'yup';

export const passwordSchema = /^(?=.*[A-Za-z].*)(?=.*[0-9].*)[A-Za-z0-9~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/]{6,}$/;

type yupPasswordValidatorType = (t: TFunction, excludeEmptyString?: boolean) => yup.StringSchema;
export const yupPasswordValidator: yupPasswordValidatorType = (t, excludeEmptyString = false) =>
  yup.string().matches(passwordSchema, { message: t('__validation__password_schema'), excludeEmptyString });

type yupStringValidatorType = (t: TFunction) => yup.StringSchema;
export const yupStringValidator: yupStringValidatorType = (t) => yup.string().required(t('__validation__required'));

type yupOptionalStringValidatorType = (t: TFunction) => yup.StringSchema;
export const yupOptionalStringValidator: yupOptionalStringValidatorType = (t) => yup.string();

type yupPriceValidatorType = (t: TFunction) => yup.NumberSchema;
export const yupPriceValidator: yupPriceValidatorType = (t) =>
  yup
    .number()
    .typeError(t('__validation__number'))
    .max(9999999, t('__validation__maximum_9999999'))
    .required(t('__validation__required'));

type yupNumberValidatorType = (t: TFunction) => yup.NumberSchema;
export const yupNumberValidator: yupNumberValidatorType = (t) =>
  yup
    .number()
    .typeError(t('__validation__number'))
    .integer(t('__validation__integer'))
    .moreThan(-1)
    .required(t('__validation__required'));
