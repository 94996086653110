import { Box, Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import clsx from 'clsx';
import { ImageLogo } from 'components';
import { useClasses } from 'utils/hooks/useClasses';
const appVersion = process.env.REACT_APP_VERSION;

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    marginLeft: spacing(3),
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    color: palette.additionalPrimary['50'],
  },
  versionTitle: {
    fontSize: 14,
    fontWeight: 600,
  },
  versionSubtitle: {
    fontSize: 12,
  },
  title1: {
    fontSize: 40,
    fontWeight: 700,
    color: palette.additionalPrimary['50'],
    [breakpoints.down('md')]: {
      fontSize: 32,
    },
    [breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  start: {
    flex: 1,
    justifyContent: 'start',
  },
});

export const TextLogo: ComponentType<{ withVersion?: boolean }> = ({ withVersion = false }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const { pathname } = useLocation();
  const landingPage = pathname === '/';

  const navigate = useNavigate();

  return (
    <div className={clsx(classes.root, landingPage && classes.start)}>
      <Box component={'div'} sx={{ display: { xs: 'block', md: 'none' } }} onClick={() => navigate('/')}>
        <ImageLogo width={24} height={24} />
      </Box>

      <Box component={'div'} sx={{ display: { md: 'block', xs: 'none' } }} onClick={() => navigate('/')}>
        <ImageLogo />
      </Box>
      <div className={classes.text}>
        {withVersion ? (
          <>
            <div className={classes.versionTitle}>Driverbook</div>
            <div className={classes.versionSubtitle}>
              {t('header_version')} {appVersion}
            </div>
          </>
        ) : (
          <div className={clsx(landingPage ? classes.title1 : classes.title)}>Driverbook</div>
        )}
      </div>
    </div>
  );
};
