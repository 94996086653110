import {Group, Forum, ImportContacts, ExitToApp, SecurityUpdateGood } from "@mui/icons-material"

export const adminMenuItems = [
    {
        label: 'admin_menu_stories',
        icon: ImportContacts,
        to: '/secret/machineroom/stories'
    },
    {
        label: 'admin_menu_comments',
        icon: Forum,
        to: '/secret/machineroom/comments'
    },
    {
        label: 'admin_menu_users',
        icon: Group,
        to: '/secret/machineroom/users'
    },
    {
        label: 'admin_menu_new_version',
        icon: SecurityUpdateGood,
        to: '/secret/machineroom/new-version'
    },
]

export const adminMenuItemLogout = {
    label: 'settings_menu_logout',
    icon: ExitToApp,
    to: '/secret/machineroom/logout',
}
