import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowBack, Close } from '@mui/icons-material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputBaseComponentProps,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { enUS, esES, ukUA } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import clsx from 'clsx';
import { InnerMobileHeader, PageLoader, StyledButton } from 'components';
import AutoAwesome from 'components/CustomIcons/AutoAwesome';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/es';
import 'dayjs/locale/uk';
import { recordCategories } from 'features/records/components/record-categories';
import { isNull } from 'lodash';
import { getOr } from 'lodash/fp';
import { ComponentType, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCarQuery } from 'services';
import store from 'store2';
import { theme } from 'theme';
import { NewReminderValues, ReminderAITypeShort, RemindEveryDateType, remindEveryDateTypes } from 'types';
import { getTKey, yupNumberValidator } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import * as yup from 'yup';
import { useCreateReminder } from '../hooks/useCreateReminder';
import { AddPartReminder } from './AddPartReminder';
import { CategorySelectWithDialog } from './CategorySelectWithDialog';

const locales: Record<string, any> = {
  en: enUS.components.MuiLocalizationProvider.defaultProps.localeText,
  uk: ukUA.components.MuiLocalizationProvider.defaultProps.localeText,
  es: esES.components.MuiLocalizationProvider.defaultProps.localeText,
};

const tKey = getTKey('reminder_form');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  partHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacing(0, 0.5, 0, 0.25),
  },
  datePicker: {
    width: '100%',
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '& label.Mui-focused': {
      color: '#bb86fc',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#bb86fc',
    },
    '& .MuiOutlinedInput-root': {
      width: '100%',
      '& fieldset': {
        borderColor: '',
        width: '100%',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.87)',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#bb86fc',
      },
    },
    '& .MuiButtonBase-root': {
      color: '#FFFFFF99',
    },
    '& .MuiFormLabel-root': {
      maxWidth: 'calc(100% - 42px)',
    },
  },
  mileageInput: {
    '& .MuiInputAdornment-root': {
      color: '#FFFFFF73',
    },
  },
  ai: {
    '&::before': {
      content: `""`,
      position: 'absolute',
      width: 'calc(100% - 16px)',
      height: 'calc(100% - 16px)',
      margin: 8,
      borderRadius: 2,
      background: 'rgba(187, 134, 252, 0.1)',
    },
  },
});

const reminderFormValidationSchema = (t: TFunction): yup.AnyObjectSchema => {
  return yup.object().shape({
    title: yup
      .string()
      .max(50, t('__validation__maximum_symbols', { length: 50 }))
      .required(),
    category: yup
      .string()
      .max(50, t('__validation__maximum_symbols', { length: 50 }))
      .nullable(),
    comment: yup.string().nullable(),
    link: yup.string().nullable(),
    remindBeforeMileage: yupNumberValidator(t).max(9999999, t('__validation__maximum_9999999')),
    remindDate: yup.date().nullable(),
    remindEveryDateType: yup.string().nullable(),
    remindMileage: yupNumberValidator(t).max(9999999, t('__validation__maximum_9999999')).nullable(),
    toRepeatMileage: yupNumberValidator(t).max(9999999, t('__validation__maximum_9999999')).nullable(),
  });
};

const createDefaultValues = (values?: NewReminderValues) => ({
  title: isNull(values?.title) ? '' : getOr('', 'title', values),
  category: isNull(values?.category) ? '' : getOr('', 'category', values),
  comment: isNull(values?.comment) ? '' : getOr('', 'comment', values),
  link: isNull(values?.link) ? '' : getOr('', 'link', values),
  remindBeforeMileage: isNull(values?.remindBeforeMileage) ? 0 : getOr(0, 'remindBeforeMileage', values),
  remindDate: values?.remindDate && dayjs(values.remindDate).isValid() ? dayjs(values.remindDate) : null,
  remindEveryDateType: isNull(values?.remindEveryDateType) ? 'day' : getOr('', 'remindEveryDateType', values),
  remindMileage: isNull(values?.remindMileage) ? 0 : getOr(0, 'remindMileage', values),
  toRepeatMileage: isNull(values?.toRepeatMileage) ? 0 : getOr(0, 'toRepeatMileage', values),
  tmpId: isNull(values?.tmpId) ? '' : getOr('', 'tmpId', values),
});

interface ReminderFormProps {
  edit?: boolean;
  defaultValues?: NewReminderValues | undefined;
  InputProps?: InputBaseComponentProps;
  ai?: ReminderAITypeShort;
  onRemove?: (id: string) => void;
}

export const ReminderForm: ComponentType<ReminderFormProps> = ({ edit, defaultValues, onRemove, InputProps, ai }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [language] = useState(i18n.language === 'en' ? 'en-gb' : i18n.language);
  dayjs.locale('en-gb');
  dayjs.locale('uk');
  dayjs.locale('es');
  const navigate = useNavigate();
  const onCloseReminderForm = () => {
    navigate(-1);
  };
  const [remindValues, setRemindValues] = useState(createDefaultValues(defaultValues));
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { carId } = useParams<{ carId: string }>() as { carId: string };
  const location = useLocation();
  const isEditPath = location.pathname.includes('/edit/');
  const isCopyPath = location.pathname.endsWith('/copy');
  const localSettings = store('driverbook_settings');
  const localeDateFormat = localSettings.date_format.toUpperCase();
  const {
    enableNotes,
    enableCategory,
    enableDate,
    enableLink,
    enableMileage,
    toggleNotes,
    toggleLink,
    toggleDate,
    toggleMileage,
    toggleCategory,
    title,
    onChangeTitle,
    onChangeNotes,
    onChangeLink,
    onChangeDateDate,
    onChangeDateTime,
    onChangeDateRepeat,
    onChangeCategories,
    onChangeMileageWhen,
    onChangeMileageBefore,
    onChangeMileageEvery,
    notes,
    link,
    date,
    mileage,
    categories,
    pasteLink,
    submit,
    validTitle,
    validNotes,
    validLink,
    validDate,
    validTime,
    validMileageWhen,
    validMileageBefore,
    validCategory,
  } = useCreateReminder(ai);

  useEffect(() => {
    if (defaultValues) {
      const cloneData = JSON.parse(JSON.stringify(defaultValues));
      setRemindValues(cloneData);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (remindValues) {
      if (remindValues.title) {
        onChangeTitle(remindValues.title);
      }

      if (remindValues?.comment) {
        onChangeNotes(remindValues.comment);
        if (!enableNotes) {
          toggleNotes();
        }
      }

      if (remindValues.link) {
        onChangeLink(remindValues.link);
        if (!enableLink) {
          toggleLink();
        }
      }

      if (remindValues.remindDate) {
        const dateTime = dayjs(remindValues.remindDate);
        const dateOnly = dateTime.startOf('day');
        const timeOnly = dateTime;
        onChangeDateDate(dateOnly);
        onChangeDateTime(dayjs(timeOnly));
      }

      if (remindValues.remindEveryDateType) {
        onChangeDateRepeat(remindValues.remindEveryDateType as RemindEveryDateType);
      }

      if (remindValues.remindDate && remindValues.remindEveryDateType) {
        if (!enableDate) {
          toggleDate();
        }
      }

      if (remindValues.remindMileage) {
        onChangeMileageWhen(remindValues.remindMileage.toString());
      }

      if (remindValues.remindBeforeMileage) {
        onChangeMileageBefore(remindValues.remindBeforeMileage.toString());
      }

      if (remindValues.toRepeatMileage) {
        onChangeMileageEvery(remindValues.toRepeatMileage.toString());
      }

      if (remindValues.remindMileage || remindValues.remindBeforeMileage || remindValues.toRepeatMileage) {
        if (!enableMileage) {
          toggleMileage();
        }
      }

      if (remindValues.category) {
        const categories = Array.isArray(remindValues.category) ? remindValues.category : [remindValues.category];
        onChangeCategories(categories);
        if (!enableCategory) {
          toggleCategory();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remindValues]);

  const getTitle = () => {
    if (isEditPath) {
      return 'title_edit';
    } else if (isCopyPath) {
      return 'title_copy';
    } else return 'title';
  };

  const { data: carData, isFetching } = useCarQuery(carId);

  const { control } = useForm({
    defaultValues: createDefaultValues(defaultValues),
    resolver: yupResolver(reminderFormValidationSchema(t)),
  });

  return (
    <Box className={classes.root}>
      {!isFetching && carData ? (
        <>
          <InnerMobileHeader
            title={t(tKey(getTitle())) + ` ${carData.make} ${carData.model}`}
            leftIcon={<ArrowBack width={16} height={16} />}
            showBack
            onLeftClick={onCloseReminderForm}
          />
          <Controller
            name='title'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className={clsx(classes.root, { [classes.ai]: ai && title })}
                label={t(tKey('name_label'))}
                fullWidth
                onChange={(event) => onChangeTitle(event.target.value)}
                value={title}
                inputProps={{ maxLength: 50 }}
                error={!validTitle}
                helperText={!validTitle ? t('__validation__required') : ''}
                InputProps={{
                  endAdornment: ai && title && (
                    <>
                      <AutoAwesome />
                    </>
                  ),
                }}
              />
            )}
          />
          {enableNotes && (
            <Grid marginTop={2}>
              <Grid className={classes.partHeader}>
                <Typography>{t(tKey('note_label'))}</Typography>
                <Close onClick={toggleNotes} />
              </Grid>
              <FormControl sx={{ mt: 1, mb: 1, width: 1 }}>
                <Controller
                  name='comment'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className={clsx(classes.root, { [classes.ai]: ai && remindValues?.comment !== null })}
                      fullWidth
                      onChange={(event) => onChangeNotes(event.target.value)}
                      value={notes}
                      // textarea ai={Boolean(ai && remindValues?.comment)}
                      inputProps={{ maxLength: 50 }}
                      error={!validNotes}
                      helperText={!validNotes ? t('__validation__required') : ''}
                      InputProps={{
                        endAdornment: ai && remindValues?.comment !== null && (
                          <>
                            <AutoAwesome />
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}
          {enableLink && (
            <Grid marginTop={2}>
              <Grid className={classes.partHeader}>
                <Typography>{t(tKey('link_label'))}</Typography>
                <Close onClick={toggleLink} />
              </Grid>
              <Controller
                name='link'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className={clsx(classes.root, { [classes.ai]: ai && remindValues.link !== null })}
                    value={link}
                    fullWidth
                    onChange={(event) => onChangeLink(event.target.value)}
                    error={!validLink}
                    helperText={!validLink ? t('__validation__required') : ''}
                    InputProps={{
                      endAdornment:
                        ai && remindValues.link !== null ? (
                          <>
                            <AutoAwesome />
                          </>
                        ) : (
                          <InputAdornment position='end'>
                            <Button onClick={pasteLink}>{t(tKey('button_paste'))}</Button>
                          </InputAdornment>
                        ),
                    }}
                  />
                )}
              />
            </Grid>
          )}
          {enableDate && (
            <Grid marginTop={2}>
              <Grid className={classes.partHeader}>
                <Typography>{t(tKey('date_reminder_label'))}</Typography>
                <Close onClick={toggleDate} />
              </Grid>

              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={language}
                localeText={locales[i18n.language]}
              >
                <FormControl sx={{ mt: 1, mb: 1, width: 1 }}>
                  {!fullScreen ? (
                    <Controller
                      name='remindDate'
                      control={control}
                      render={({ field }) => (
                        <DesktopDatePicker
                          {...field}
                          // className={classes.datePicker}
                          className={clsx(classes.datePicker, classes.root, {
                            [classes.ai]: ai && remindValues.remindDate !== null,
                          })}
                          sx={{ display: { sm: 'block', xs: 'none' } }}
                          label={t(tKey('date_label'))}
                          format={localeDateFormat}
                          onChange={(newValue) => {
                            onChangeDateDate(newValue);
                          }}
                          disablePast
                          value={date?.date || dayjs()}
                          slots={{ openPickerIcon: CalendarMonthIcon }}
                          slotProps={{
                            textField: {
                              error: !validDate,
                              helperText: !validDate ? t('__validation__required') : '',
                              // InputProps:
                              //   ai && remindValues.remindDate !== null
                              //     ? {
                              //         endAdornment: (
                              //           <InputAdornment position='end'>
                              //             <Box display='flex' alignItems='center'>
                              //               <AutoAwesome sx={{ ml: 1 }} />
                              //             </Box>
                              //           </InputAdornment>
                              //         ),
                              //       }
                              //     : {},
                            },
                          }}
                        />
                      )}
                    />
                  ) : (
                    <Controller
                      name='remindDate'
                      control={control}
                      render={({ field }) => (
                        <MobileDatePicker
                          {...field}
                          className={clsx(classes.datePicker, classes.root, {
                            [classes.ai]: ai && remindValues.remindDate !== null,
                          })}
                          sx={{ display: { sm: 'block', xs: 'block' } }}
                          label={t(tKey('date_label'))}
                          format={localeDateFormat}
                          disablePast
                          value={date?.date || dayjs()}
                          onChange={(newValue) => {
                            onChangeDateDate(newValue);
                          }}
                          localeText={{ toolbarTitle: t('__date_picker__toolbar_title') }}
                          slotProps={{
                            textField: {
                              error: !validDate,
                              helperText: !validDate ? t('__validation__required') : '',
                              // InputProps: {
                              //   endAdornment: (
                              //     <>
                              //       {ai && remindValues.remindDate !== null && (
                              //         <InputAdornment position='end'>
                              //           <AutoAwesome />
                              //         </InputAdornment>
                              //       )}
                              //     </>
                              //   ),
                              // },
                            },
                          }}
                        />
                      )}
                    />
                  )}
                </FormControl>
                <FormControl sx={{ mt: 1, mb: 1, width: 1 }}>
                  <Controller
                    name='remindDate'
                    control={control}
                    render={({ field }) => (
                      <TimeField
                        {...field}
                        className={clsx(classes.root, { [classes.ai]: ai && remindValues.remindDate !== null })}
                        label={t(tKey('time_label'))}
                        format='HH:mm'
                        value={date?.time}
                        onChange={(newValue) => {
                          onChangeDateTime(newValue);
                        }}
                        slotProps={{
                          textField: {
                            error: !validTime,
                            helperText: !validTime ? t('__validation__required') : '',
                          },
                        }}
                        InputProps={{
                          endAdornment: ai && remindValues.remindDate !== null && <AutoAwesome />,
                        }}
                      />
                    )}
                  />
                </FormControl>
              </LocalizationProvider>

              <FormControl sx={{ mt: 1, mb: 1, width: 1 }}>
                <InputLabel id='date_repeat_label'>{t(tKey('repeat_label'))}</InputLabel>
                <Controller
                  name='remindEveryDateType'
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className={classes.datePicker}
                      // className={clsx(classes.root, { [classes.ai]: ai && remindValues.remindEveryDateType !== null })}
                      labelId='date_repeat_label'
                      label={t(tKey('repeat_label'))}
                      fullWidth
                      defaultValue='do_not_repeat'
                      value={date?.repeat}
                      onChange={(newValue) => {
                        onChangeDateRepeat(newValue.target.value as RemindEveryDateType);
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            bgcolor: '#121212',
                          },
                        },
                      }}
                    >
                      {remindEveryDateTypes.map((type) => {
                        return (
                          <MenuItem key={type} value={type}>
                            {t(tKey('remind_type_' + type))}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          )}
          {enableMileage && (
            <Grid marginTop={2}>
              <Grid className={classes.partHeader}>
                <Typography>{t(tKey('mileage_reminder_label'))}</Typography>
                <Close onClick={toggleMileage} />
              </Grid>
              <FormControl sx={{ mt: 1, mb: 1, width: 1 }}>
                <Controller
                  name='remindMileage'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className={clsx(classes.mileageInput, classes.root, {
                        [classes.ai]: ai && remindValues.remindMileage !== null,
                      })}
                      fullWidth
                      type='number'
                      label={t(tKey('remind_when_label'))}
                      value={mileage?.when || ''}
                      onChange={(event) => {
                        onChangeMileageWhen(event.target.value);
                      }}
                      error={!validMileageWhen}
                      helperText={!validMileageWhen ? t('__validation__required') : ''}
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position='end'>
                              <Typography>KM</Typography>
                            </InputAdornment>
                            {ai && remindValues.remindMileage !== null && <AutoAwesome sx={{ ml: 1 }} />}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
              <FormControl sx={{ mt: 1, mb: 1, width: 1 }}>
                <Controller
                  name='remindBeforeMileage'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className={clsx(classes.mileageInput, classes.root, {
                        [classes.ai]: ai && remindValues.remindBeforeMileage !== null,
                      })}
                      fullWidth
                      type='number'
                      label={t(tKey('remind_before_label'))}
                      value={mileage?.before}
                      error={!validMileageBefore}
                      helperText={!validMileageBefore ? t('__validation__required') : ''}
                      onChange={(event) => {
                        onChangeMileageBefore(event.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position='end'>
                              <Typography>KM</Typography>
                            </InputAdornment>
                            {ai && remindValues.remindBeforeMileage !== null && <AutoAwesome sx={{ ml: 1 }} />}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
              <FormControl sx={{ mt: 1, mb: 1, width: 1 }}>
                <Controller
                  name='toRepeatMileage'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className={clsx(classes.mileageInput, classes.root, {
                        [classes.ai]: ai && remindValues.toRepeatMileage !== null,
                      })}
                      fullWidth
                      type='number'
                      label={t(tKey('repeat_every_label'))}
                      value={mileage?.every}
                      onChange={(event) => {
                        onChangeMileageEvery(event.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position='end'>
                              <Typography>KM</Typography>
                            </InputAdornment>
                            {ai && remindValues.toRepeatMileage !== null && <AutoAwesome sx={{ ml: 1 }} />}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}
          {enableCategory && (
            <Grid marginTop={2}>
              <Grid className={classes.partHeader}>
                <Typography>{t(tKey('category_label'))}</Typography>
                <Close onClick={toggleCategory} />
              </Grid>
              <FormControl sx={{ mt: 1, mb: 1, width: 1 }}>
                <Controller
                  name='category'
                  control={control}
                  render={({ field }) => (
                    <CategorySelectWithDialog
                      {...field}
                      options={recordCategories}
                      values={categories}
                      onChange={onChangeCategories}
                      error={!validCategory}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}

          <AddPartReminder
            addNotes={toggleNotes}
            addDate={toggleDate}
            addLink={toggleLink}
            addMileage={toggleMileage}
            addCategory={toggleCategory}
            enableNotes={enableNotes}
            enableCategory={enableCategory}
            enableDate={enableDate}
            enableLink={enableLink}
            enableMileage={enableMileage}
          />

          <Box display='flex' textAlign='center' sx={{ display: ai ? 'none' : 'block' }}>
            <StyledButton
              className='submit-reminder-form'
              label={isEditPath ? t('__common__save') : t(tKey('button_create'))}
              type='submit'
              onClick={submit}
            />
          </Box>

          {onRemove && (
            <Box sx={{ marginTop: 2 }} display='flex' justifyContent='center'>
              <StyledButton label={t(tKey('remove_record'))} type='button' onClick={onRemove} color='secondary' />
            </Box>
          )}
        </>
      ) : (
        <PageLoader />
      )}
    </Box>
  );
};
