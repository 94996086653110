import { ArrowForward } from '@mui/icons-material';
import { Box, Grid, Theme, Typography } from '@mui/material';
import { StyledButton } from 'components';
import { AILoader } from 'components/AILoader/AILoader';
import { ModalSearchSelect } from 'components/Form/ModalSearchSelect';
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import { useChangeLanguage } from 'lib';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCreateRecordsFromVoiceMutation } from 'services/records/hooks/useCreateRecordFromVoiceMutation';
import { useCreateRecordsBatchMutation } from 'services/records/hooks/useCreateRecordsBatchMutation';
import { useRecordsLimitsQuery } from 'services/records/hooks/useRecordsLimitsQuery';
import store from 'store2';
import { NewRecordValues } from 'types';
import { createNestedFormData, getTKey, googleLanguages } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { AIHowTo, Step } from './AIHowTo';
import { AIRecordLeft } from './AIRecordLeft';
import { RecordsAIForm } from './RecordsAIForm';
import { VoiceFormLoader } from './VoiceFormLoader';
import { AIRecordsMileage, AIRecordsMileageModeType } from './AIRecordsMileage';

const tKey = getTKey('record_voice_form');
const styles = ({ palette, breakpoints, spacing }: Theme) => ({
  root: {},
  menuItem: {
    fontSize: 16,
    fontWeight: '500',
  },
  menuItemSub: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 12,
    fontWeight: '400',
  },
  menuContainer: {
    display: 'flex',
    color: 'rgba(255, 255, 255, 0.87)',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: spacing(2, 0),
    cursor: 'pointer',
  },
  recordButton: {
    border: '2px solid',
    borderColor: 'rgba(255, 255, 255, 0.3)',
    width: 80,
    height: 80,
    borderRadius: 40,
  },
  recordCircle: {
    width: 67,
    height: 67,
    borderRadius: 40,
    background: '#FF5050',
    transition: 'all 0.2s ease-out',
  },
  recordSquare: {
    width: 45,
    height: 45,
    borderRadius: 5,
    background: '#FF5050',
    transition: 'all 0.2s ease-out',
  },
  recordCircleDisabled: {
    width: 67,
    height: 67,
    borderRadius: 40,
    background: 'rgba(255, 255, 255, 0.3)',
  },
});

const stepsHowTo: Step[] = [
  {
    number: 1,
    textKey: 'example1',
    text: (
      <>
        I've refilled <b>50 liters of gasoline</b>, which costed me <b>100 dollars</b> and my current mileage is
        <b>55000 km.</b>
      </>
    ),
  },
  {
    number: 2,
    textKey: 'example2',
    text: (
      <>
        I've changed <b>tires</b>, new tires are <b>Bridgestone 250/65/R16</b> and total cost was <b>200 dollars</b>. My
        current mileage is <b>10000 km</b>.
      </>
    ),
  },
];

interface ComponentProps {
  siri?: boolean;
}

export const VoiceForm = ({ siri }: ComponentProps) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const { carId } = useParams<{ carId: string }>() as { carId: string };
  const [open, setOpen] = useState(false);
  const { chosenLanguage } = useChangeLanguage();
  const [finalRecordLanguage, setFinalRecordLanguage] = useState(chosenLanguage);

  const [activeStep, setActiveStep] = useState(1);

  const [records, setRecords] = useState<NewRecordValues[]>([]);
  const [idSubmitting, setIdSubmitting] = useState('');
  const mutation = useCreateRecordsBatchMutation();
  const navigate = useNavigate();

  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<any>(null);
  const [timer, setTimer] = useState(60);
  const increment = useRef<any>(null);
  const mutationProcess = useCreateRecordsFromVoiceMutation();
  const [available, setAvailable] = useState(true);
  const { data, isLoading, refetch } = useRecordsLimitsQuery();
  const storeKey = 'driverbook_voice_records_' + carId;
  const refData = useRef<any>([]);
  const [mileageData, setMileageData] = useState<{ mode: AIRecordsMileageModeType; mileage: string }>({
    mode: 'default',
    mileage: '',
  });

  useEffect(() => {
    if (siri) {
      startRecording();
      store.set('redirectToRecent', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siri]);

  const onChangeLanguage = (data: any) => {
    const chosenLang = data.target.value;
    setFinalRecordLanguage(chosenLang);
  };

  useEffect(() => {
    if (!isLoading && data?.audio_record_count === 0) {
      setAvailable(false);
    }
  }, [data, isLoading]);

  useEffect(() => {
    const storedRecords = store(storeKey);
    if (storedRecords) {
      setRecords(storedRecords);
      setActiveStep(3);
    }
    const setup = async () => {
      const mimeType = 'audio/wav';
      if (!MediaRecorder.isTypeSupported(mimeType)) {
        await register(await connect());
      }
    };
    setup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (data: any, id: string) => {
    refData.current = [...refData.current, { id: id, data: data }];

    if (refData.current.length === records.length) {
      const array = refData.current.map((item: any) => item.data);
      const nested: any = createNestedFormData(array);
      nested.append('records_count', records.length);

      mutation.mutate(
        { carId, formData: nested },
        {
          onSuccess: () => {
            setIdSubmitting('');
            toast(t('record_added'));

            updateStore([]);
            navigate(`/records/${carId}`);
          },
          onError: () => {
            toast(t('__common__error'));
            setIdSubmitting('');
          },
        }
      );
    } else {
      document.querySelector('.Mui-error')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const formatTime = (time: any) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
      seconds
    ).padStart(2, '0')}`;
    return formattedTime;
  };

  const startRecording = async () => {
    try {
      let stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream, { mimeType: 'audio/wav' });
      setMediaRecorder(recorder);
      recorder.start();
      setRecording(true);
      increment.current = setInterval(() => {
        setTimer((timer) => {
          if (timer > 0) return timer - 1;
          else {
            clearInterval(increment.current);
            setRecording(false);
            recorder.stop();
            return timer;
          }
        });
      }, 1000);
      const chunks: any = [];
      recorder.ondataavailable = async (e) => {
        const audioData = e.data;
        chunks.push(audioData);
      };
      recorder.onstop = async (e: any) => {
        const blob = new Blob(chunks, { type: 'audio/wav' });
        // setAudioBlob(blob);
        const audioFile = new File([blob], 'audio.wav', { type: 'audio/wav' });
        stream.getAudioTracks().forEach(function (track) {
          track.stop();
        });
        setActiveStep(2);
        mutationProcess.mutate(
          { body: { audio: audioFile, language: finalRecordLanguage }, carId: carId },
          {
            onError() {
              setActiveStep(1);
              toast(t('__common__error'));
              setTimer(60);
            },
            onSuccess(data, variables, context) {
              if (!data.error) {
                if (data.records.length === 0) {
                  setActiveStep(1);
                  toast(t(tKey('not_found_records_in_voice')));
                  setTimer(60);
                  refetch();
                } else {
                  let prepareRecords = data.records;
                  if (mileageData.mode === 'custom') {
                    prepareRecords = prepareRecords.map((record) => {
                      record.mileage = Number(mileageData.mileage);
                      return record;
                    });
                  }
                  setRecords(prepareRecords);
                  updateStore(prepareRecords);
                  setActiveStep(3);
                }
              } else {
                setActiveStep(1);
                toast(t('__common__error'));
              }
            },
          }
        );
      };
    } catch (err) {
      console.error('Error accessing audio devices.', err);
    }
  };

  const stopRecording = async () => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop();
      clearInterval(increment.current);
    }
    setRecording(false);
  };

  const handleRemove = (id: string) => {
    if (records.length === 1) {
      navigate(`/records/${carId}`);
      updateStore([]);
    } else {
      const updatedRecords = records.filter((record) => record.tmpId !== id);
      setRecords(updatedRecords);
      updateStore(updatedRecords);
    }
  };

  const updateStore = (records: any) => {
    if (records.length === 0) {
      store.remove(storeKey);
    } else {
      store(storeKey, records);
    }
  };

  const createRecordBatch = () => {
    setTimeout(() => {
      refData.current = [];
    }, 0);

    const buttons = document.getElementsByClassName('submit-record-form');
    for (let btn in buttons) {
      if (buttons[btn] instanceof HTMLElement) {
        const element = buttons[btn] as HTMLElement;
        setTimeout(() => {
          element.click();
        }, 0);
      }
    }
  };

  return (
    <>
      {open && (
        <ModalSearchSelect
          open={open}
          onClose={() => setOpen(false)}
          title={t('settings_language_select_language')}
          name='language'
          defaultValue={finalRecordLanguage}
          onChange={onChangeLanguage}
          options={googleLanguages()}
        />
      )}
      {activeStep === 1 && (
        <Grid className={classes.root}>
          <Box className={classes.menuContainer} onClick={() => setOpen(true)}>
            <Box>
              <Typography className={classes.menuItem}>{t('record_ai_select_language')}</Typography>
              <Typography className={classes.menuItemSub}>
                {googleLanguages().find((lang) => lang.value === finalRecordLanguage)?.label}
              </Typography>
            </Box>
            <ArrowForward width={16} height={16} />
          </Box>
          <AIHowTo steps={stepsHowTo} type='voice' title={t('record_how_to_voice_title')} />
          {isLoading ? (
            <VoiceFormLoader />
          ) : (
            <>
              {data && (
                <AIRecordLeft
                  type='voice'
                  description={t('record_voice_left_records_description')}
                  count={data.audio_record_count}
                />
              )}
              <AIRecordsMileage onChange={(mode, mileage) => setMileageData({ mode, mileage })} page='vtr' />
              <Box display='flex' justifyContent='center' margin={3}>
                <Box display='flex' flexDirection='column' alignItems='center'>
                  {available && (
                    <Box style={{ marginBottom: '10px', fontWeight: 700, fontVariantNumeric: 'tabular-nums' }}>
                      {formatTime(timer)}
                    </Box>
                  )}
                  <Box
                    className={classes.recordButton}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    id='record-button'
                    onClick={() => {
                      if (available) {
                        if (recording) {
                          stopRecording();
                        } else {
                          startRecording();
                        }
                      }
                    }}
                  >
                    <Box
                      className={
                        available
                          ? recording
                            ? classes.recordSquare
                            : classes.recordCircle
                          : classes.recordCircleDisabled
                      }
                    ></Box>
                  </Box>
                  <Typography variant='body2' padding='10px 0 0'>
                    {t(tKey('tap_to_record'))}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Grid>
      )}
      <AILoader
        loading={activeStep === 2}
        messages={[
          { text: t('record_ai_loader_parsing_audio'), duration: 1500 },
          { text: t('record_ai_loader_analyzing'), duration: 2500 },
          { text: t('record_ai_loader_generating'), duration: 3500 },
          { text: t('record_ai_loader_final'), duration: 5000 },
        ]}
      />
      {activeStep === 3 && (
        <>
          <RecordsAIForm
            records={records}
            onSubmit={handleSubmit}
            idSubmitting={idSubmitting}
            onRemove={handleRemove}
            aiType='vtr'
          />
          <Box display='flex' justifyContent='center'>
            <StyledButton label={t('record_ai_create_records')} onClick={createRecordBatch} />
          </Box>
        </>
      )}
    </>
  );
};
