import { useQuery } from 'react-query';
import { RecordsLimits } from 'types';

import { axiosApiInstance as api } from 'utils';

export const getRecordsLimits = async (): Promise<RecordsLimits> => {
  const { data } = await api.get('/records/limits');
  return data;
};

export const useRecordsLimitsQuery = () => {
  return useQuery('recordsLimits', () => getRecordsLimits(), { cacheTime: 0 });
};
