import { CommentPayload, LikeDislikeResponse } from 'features/stories-feed/StoriesTypes';
import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

const likeComment = async ({ carId, storyId, commentId }: CommentPayload): Promise<LikeDislikeResponse> => {
  const { data } = await api.post(`/cars/${carId}/stories/${storyId}/comments/${commentId}/like`);
  return data;
};

export const useLikeCommentMutation = () => {
  return useMutation(likeComment);
};
