import { useInfiniteQuery } from "react-query";
import { axiosApiInstance as api, mapKeysToSnakeCase } from 'utils';
import { storiesFeedResponse } from "./useStoriesFeedQuery";




export const getStoriesLiked = async (page: number): Promise<storiesFeedResponse> => {
  const { data } = await api.get(`stories/liked`, {params: mapKeysToSnakeCase({page})});
  return data;
};

export const useStoriesLikedQuery = () => {
  return useInfiniteQuery(
    ['getStoriesLiked'],
    ({pageParam: page = 1}) => getStoriesLiked(page),
    {
      cacheTime: 0,
      getNextPageParam(lastPage, allPages){
        return lastPage.stories.length > 0 ? lastPage.current_page + 1 : undefined
      },
    },
  );
};