import { ElementToCorrect } from 'features/admin-panel/components/AdminReview/AdminItemTypes';
import { useMutation } from 'react-query';
import { axiosApiInstance as api, mapKeysToSnakeCase } from 'utils';

export type wrapWarnUserPayload = {
  id: string;
  body: warnUserPayload;
};

export type warnUserPayload = {
  reason: string;
  contentId: string;
  contentType: string;
  elementsToCorrect?: ElementToCorrect[];
};

export const warnUser = async (payload: wrapWarnUserPayload): Promise<any> => {
  try {
    const { data } = await api.post(`/engineroom/users/${payload.id}/warn`, mapKeysToSnakeCase(payload.body));
    return data;
  } catch (err) {
    return { error: true };
  }
};

export const useWarnUserMutation = () => {
  return useMutation(warnUser);
};
