import { useQuery, UseQueryResult } from 'react-query';

import { axiosApiInstance as api } from 'utils';

const getTransferAmount = async (): Promise<any> => {
  const { data } = await api.get(`/car-transfers/amount`);
  return data.amount;
};

export const useCarTransferQuery = (): UseQueryResult<any> => {
  return useQuery(['carTransferAmount'], () => getTransferAmount());
};
