import { useQuery, UseQueryResult } from 'react-query';
import { axiosApiInstance as api } from 'utils';

const getShareAmount = async (carId: string): Promise<any> => {
  const { data } = await api.get(`/cars/${carId}/records/public`);
  return data;
};

export const useCarShareQuery = (carId: string): UseQueryResult<any> => {
  return useQuery(['carShareAmount'], () => getShareAmount(carId));
};
