import { useState } from 'react';
import { ElementToCorrect, IItem, ItemActionType, WarnType } from '../components/AdminReview/AdminItemTypes';
import { useAdminUserModerate } from './useAdminUserModerate';
import { useAdminStoryModerate } from './useAdminStoryModerate';
import { useAdminCommentModerate } from './useAdminCommentModerate';
import { DurationType, IWarn } from '../components/AdminDashboardTypes';
import { correctCar } from 'services/admin/hooks/useCorrectCarMutation';

type useAdminWarnBanUserType = () => {
  onBan: (ban_from: ItemActionType, duration: DurationType, note: string) => void;
  onWarn: (reason: string) => void;
  onBanOpenDialog: () => void;
  warns: IWarn[];
  setWarns: (warns: IWarn[]) => void;
  openBanUserDialog: boolean;
  openHiddenDialog: boolean;
  setOpenHiddenDialog: (state: boolean) => void;
  onOpenHiddenDialog: (item: IItem) => void;
  hiddenType: ItemActionType;
  setHiddenType: (type: ItemActionType) => void;
  setHiddenUser: (id: string) => void;
  openWarnUserDialog: boolean;
  onWarnOpenDialog: () => void;
  setHiddenItemId: (id: string) => void;
  onCloseWarnDialog: () => void;
  onCloseBanDialog: () => void;
  hiddenUser: string;
  setHiddenItem: (item: IItem) => void;
  hiddenItem: IItem | null;
  openProfileWarnDialog: boolean;
  onWarnProfile: (elementsToCorrect: ElementToCorrect[]) => void;
  setOpenProfileWarnDialog: (state: boolean) => void;
  getWarnType: () => WarnType;
  setWarnNote: (state: string) => void;
};

export const useAdminWarnBanUser: useAdminWarnBanUserType = () => {
  const { banUser, warnUser } = useAdminUserModerate();
  const [openBanUserDialog, setOpenBanUserDialog] = useState<boolean>(false);
  const [openWarnUserDialog, setOpenWarnUserDialog] = useState<boolean>(false);
  const [warns, setWarns] = useState<IWarn[]>([]);
  const [openHiddenDialog, setOpenHiddenDialog] = useState<boolean>(false);
  const [openProfileWarnDialog, setOpenProfileWarnDialog] = useState<boolean>(false);
  const [hiddenType, setHiddenType] = useState<ItemActionType>('creating_story');
  const [hiddenUser, setHiddenUser] = useState<string>('');
  const [hiddenItemId, setHiddenItemId] = useState<string>('');
  const [hiddenItem, setHiddenItem] = useState<IItem | null>(null);
  const [warnNote, setWarnNote] = useState('');
  const { hideStory } = useAdminStoryModerate();
  const { hideComment } = useAdminCommentModerate();

  const onOpenHiddenDialog = (item: IItem) => {
    setHiddenItem(item);
    setOpenHiddenDialog(true);
  };

  const clearHiddenState = () => {
    setHiddenUser('');
    setHiddenItemId('');
    setHiddenItem(null);
    setWarnNote('');
  };

  const onWarn = async (reason: string, elementsToCorrect?: ElementToCorrect[]) => {
    setOpenWarnUserDialog(false);
    const warnType = getWarnType();
    await warnUser({
      id: hiddenUser ?? hiddenItemId,
      body: {
        reason,
        contentId: hiddenItem?.id || '',
        contentType: warnType === 'content' ? hiddenItem?.type ?? '' : warnType,
        elementsToCorrect: elementsToCorrect,
      },
    }).then(async (data) => {
      if (!data.error) {
        if (warnType === 'content') {
          await hideItem(hiddenItemId);
        }
        if (warnType === 'car' && hiddenItem?.id) {
          await correctCar(hiddenItem?.id);
        }
        clearHiddenState();
      }
    });
  };

  const onBanOpenDialog = () => {
    setWarns([]);
    setOpenHiddenDialog(false);
    setOpenBanUserDialog(true);
  };

  const getWarnType = (): WarnType => {
    if (hiddenItem?.username !== undefined) {
      return 'profile';
    } else if (hiddenItem?.model) {
      return 'car';
    }
    return 'content';
  };

  const getBanType = () => {
    const warnType = getWarnType();
    if (warnType === 'content') {
      return hiddenItem?.type;
    } else {
      return warnType;
    }
  };

  const onWarnOpenDialog = () => {
    setWarns([]);
    setOpenHiddenDialog(false);
    setOpenWarnUserDialog(true);
  };

  const onWarnProfile = async (elementsToCorrect: ElementToCorrect[]) => {
    await onWarn(warnNote, elementsToCorrect);
    setOpenProfileWarnDialog(false);
    const refreshEvent = new CustomEvent('refreshReviewItems');
    window.dispatchEvent(refreshEvent);
  };

  const onBan = async (banFrom: ItemActionType, duration: DurationType, note: string) => {
    let isStoryBanned,
      storyBannedUntil,
      commentBannedUntil,
      isCommentBanned,
      isProfileBanned,
      profileBannedUntil,
      isCarBanned,
      carBannedUntil;
    if (banFrom === 'creating_story') {
      isStoryBanned = true;
      storyBannedUntil = convertDurationToUntilData(duration);
    }
    if (banFrom === 'commenting') {
      isCommentBanned = true;
      commentBannedUntil = convertDurationToUntilData(duration);
    }
    if (banFrom === 'profile') {
      isProfileBanned = true;
      profileBannedUntil = convertDurationToUntilData(duration);
    }
    if (banFrom === 'car') {
      isCarBanned = true;
      carBannedUntil = convertDurationToUntilData(duration);
    }
    setOpenBanUserDialog(false);
    if (hiddenUser === '') {
      return;
    }
    await banUser({
      id: hiddenUser,
      body: {
        note,
        isStoryBanned,
        storyBannedUntil,
        commentBannedUntil,
        isCommentBanned,
        isProfileBanned,
        profileBannedUntil,
        isCarBanned,
        carBannedUntil,
        contentId: hiddenItem?.id,
        contentType: getBanType(),
      },
    }).then(async (data) => {
      if (!data.error) {
        if (getWarnType() === 'content' && hiddenItemId) {
          await hideItem(hiddenItemId);
        }
        clearHiddenState();
      }
    });
  };

  const hideItem = async (item_id: string) => {
    if (hiddenType === 'creating_story') await hideStory(item_id);
    if (hiddenType === 'commenting') await hideComment(item_id);
  };

  const convertDurationToUntilData = (duration: DurationType): string => {
    const currentDate = new Date();

    switch (duration) {
      case '24_hours':
        currentDate.setDate(currentDate.getDate() + 1);
        break;
      case '30_days':
        currentDate.setDate(currentDate.getDate() + 30);
        break;
      case 'forever':
        currentDate.setFullYear(currentDate.getFullYear() + 100);
        break;
      default:
        throw new Error('Invalid duration');
    }

    const formattedDate = currentDate.toISOString().slice(0, 19).replace('T', ' ');

    return formattedDate;
  };

  const onCloseBanDialog = () => {
    setOpenBanUserDialog(false);
    clearHiddenState();
  };

  const onCloseWarnDialog = () => {
    setOpenWarnUserDialog(false);
    clearHiddenState();
  };

  return {
    onBan,
    onWarn,
    warns,
    setWarns,
    onBanOpenDialog,
    openBanUserDialog,
    openHiddenDialog,
    setOpenHiddenDialog,
    onOpenHiddenDialog,
    hiddenType,
    setHiddenType,
    setHiddenUser,
    openWarnUserDialog,
    onWarnOpenDialog,
    setHiddenItemId,
    setHiddenItem,
    onCloseBanDialog,
    onCloseWarnDialog,
    hiddenUser,
    hiddenItem,
    openProfileWarnDialog,
    onWarnProfile,
    setOpenProfileWarnDialog,
    getWarnType,
    setWarnNote,
  };
};
