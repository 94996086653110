import createSvgIcon from '@mui/icons-material/esm/utils/createSvgIcon';

export default createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g>
      <path
        d='M13.8756 10.9398L14.3301 9.93981L15.3302 9.48526L14.3301 9.03071L13.8756 8.0307L13.421 9.03071L12.421 9.48526L13.421 9.93981L13.8756 10.9398ZM11.1483 11.1216L10.2392 9.12162L9.3301 11.1216L7.33008 12.0307L9.3301 12.9398L10.2392 14.9399L11.1483 12.9398L13.1483 12.0307L11.1483 11.1216ZM13.8756 13.1217L13.421 14.1217L12.421 14.5762L13.421 15.0308L13.8756 16.0308L14.3301 15.0308L15.3302 14.5762L14.3301 14.1217L13.8756 13.1217Z'
        fill='#BB86FC'
      />
      <path
        // fill-rule='evenodd'
        // clip-rule='evenodd'
        d='M11.2625 21.85C11.5042 21.95 11.75 22 12 22C12.25 22 12.4958 21.95 12.7375 21.85C12.9792 21.75 13.2 21.6 13.4 21.4L21.4 13.4C21.6 13.2 21.75 12.9792 21.85 12.7375C21.95 12.4958 22 12.25 22 12C22 11.75 21.95 11.5042 21.85 11.2625C21.75 11.0208 21.6 10.8 21.4 10.6L13.4 2.6C13.2 2.4 12.9792 2.25 12.7375 2.15C12.4958 2.05 12.25 2 12 2C11.75 2 11.5042 2.05 11.2625 2.15C11.0208 2.25 10.8 2.4 10.6 2.6L2.6 10.6C2.4 10.8 2.25 11.0208 2.15 11.2625C2.05 11.5042 2 11.75 2 12C2 12.25 2.05 12.4958 2.15 12.7375C2.25 12.9792 2.4 13.2 2.6 13.4L10.6 21.4C10.8 21.6 11.0208 21.75 11.2625 21.85ZM20 12L12 20L4 12L12 4L20 12Z'
        fill='#BB86FC'
      />
    </g>
  </svg>
);
