import { useEffect, useState } from 'react';
import { ImageListType } from 'react-images-uploading';

import { Record } from 'types';
import { getDataBlob } from 'utils';

export const useRecordFormImages = (defaultValues: Record | undefined) => {
  const [images, setImages] = useState<ImageListType>([]);
  const imagesList: Partial<ImageListType> = [];

  useEffect(() => {
    const createFilesFromUrl = async () => {
      if (defaultValues?.imageOne) {
        const result = await getDataBlob(defaultValues.imageOne as string);

        imagesList.push({ dataURL: defaultValues.imageOne as string, file: result as File });
      }

      if (defaultValues?.imageTwo) {
        const result = await getDataBlob(defaultValues.imageTwo as string);

        imagesList.push({ dataURL: defaultValues.imageTwo as string, file: result as File });
      }

      if (defaultValues?.imageThree) {
        const result = await getDataBlob(defaultValues.imageThree as string);

        imagesList.push({ dataURL: defaultValues.imageThree as string, file: result as File });
      }
      if (defaultValues?.imageFour) {
        const result = await getDataBlob(defaultValues.imageFour as string);

        imagesList.push({ dataURL: defaultValues.imageFour as string, file: result as File });
      }
      if (defaultValues?.imageFive) {
        const result = await getDataBlob(defaultValues.imageFive as string);

        imagesList.push({ dataURL: defaultValues.imageFive as string, file: result as File });
      }

      setImages(imagesList as ImageListType);
    };

    createFilesFromUrl().catch(console.error);
  }, [defaultValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const onImageChange = (imageList: ImageListType) => {
    setImages(imageList);
  };

  return {
    images,
    onImageChange,
  };
};
