import { CommentPayload, LikeDislikeResponse } from 'features/stories-feed/StoriesTypes';
import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

const deleteComment = async ({ carId, storyId, commentId }: CommentPayload): Promise<LikeDislikeResponse> => {
  const { data } = await api.delete(`/cars/${carId}/stories/${storyId}/comments/${commentId}`);
  return data;
};

export const useDeleteCommentMutation = () => {
  return useMutation(deleteComment);
};
