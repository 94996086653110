import { useMutation } from 'react-query';
import Compressor from 'compressorjs';
import { axiosWithFileInstance, getFileNameClean } from 'utils';

const uploadImage = async ({ images, carId }: { images: any[]; carId: string }): Promise<any> => {
  const formData = new FormData();
  await Promise.all(
    images.map(async (image) => {
      return new Promise((resolve) => {
        if (image?.isDraft && image?.imageId) {
          var filename = getFileNameClean(image.filename);
          formData.append(image.imageId, filename);
          resolve(image);
        } else {
          new Compressor(image.file, {
            quality: 0.9,
            maxWidth: 1000,
            maxHeight: 1000,
            success: (res) => {
              image.file = res as File;
              let file = new File([image.file], image.filename);
              formData.append('file', file);
              resolve(image);
            },
          });
        }
      });
    })
  );
  const { data } = await axiosWithFileInstance.post(`/cars/${carId}/stories/upload`, formData);

  return data;
};

export const useUploadStoryImageMutation = () => {
  return useMutation(uploadImage);
};
