import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Theme } from '@mui/material';
import clsx from 'clsx';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
const tKey = getTKey('record_ai_milage');
const styles = ({ spacing }: Theme) => ({
  root: {
    background: '#252525',
    borderRadius: 4,
    padding: spacing(2),
    margin: spacing(4, 0, 1),
  },
  removeArrows: {
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
});

export type AIRecordsMileageModeType = 'default' | 'custom';

interface AIRecordsMileageProps {
  page: 'itr' | 'vtr';
  onChange: (mode: AIRecordsMileageModeType, mileage: string) => void;
}

export const AIRecordsMileage: ComponentType<AIRecordsMileageProps> = ({ page, onChange }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const [value, setValue] = useState<AIRecordsMileageModeType>('default');
  const [mileage, setMileage] = useState('');
  const handleChangeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = (event.target as HTMLInputElement).value as AIRecordsMileageModeType;
    setValue(newValue);
    onChange(newValue, mileage);
  };

  const handleChangeMileage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMileage = (event.target as HTMLInputElement).value as AIRecordsMileageModeType;
    setMileage(newMileage);
    onChange(value, newMileage);
  };

  return (
    <Box className={classes.root}>
      <Box>
        <FormControl>
          <FormLabel id='mileage-radio-buttons-group-label'>{t(tKey('title'))}</FormLabel>
          <RadioGroup
            aria-labelledby='mileage-radio-buttons-group-label'
            defaultValue='default'
            value={value}
            onChange={handleChangeMode}
          >
            <FormControlLabel value='default' control={<Radio />} label={t(tKey('default_' + page))} />
            <Box display='flex' flexDirection='row'>
              <FormControlLabel value='custom' control={<Radio />} label={t(tKey('manual'))} />
              {value === 'custom' && (
                <TextField
                  label={t(tKey('title'))}
                  name='mileage'
                  type='number'
                  defaultValue={mileage}
                  onChange={handleChangeMileage}
                  size='small'
                  classes={{
                    root: clsx(classes.removeArrows),
                  }}
                />
              )}
            </Box>
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};
