import { Theme } from '@mui/material';
import { IStoryElement } from 'features/admin-panel/components/AdminReview/AdminItemTypes';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useClasses } from 'utils/hooks/useClasses';
import { IStory } from '../StoriesTypes';
import { StoryContentImages } from './StoryContent/StoryContentImages';
import { StoryContentTexts } from './StoryContent/StoryContentTexts';
import { StoryRecords } from './StoryContent/StoryRecords';
import { StoryGroupRecords } from './StoryContent/StoryGroupRecords';
import { TranslateContext } from './StoryWrapper';
import { PlayerStory } from 'components';
import { StoryContentVideo } from './StoryContent/StoryContentVideo';

const styles = ({ spacing, breakpoints, palette }: Theme) => ({
  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '30px',
    letterSpacing: '0.15px',
    paddingBottom: spacing(3),
    [breakpoints.down('sm')]: {
      paddingBottom: spacing(1.5),
    },
  },
  elements: {
    '& > div': {
      paddingBottom: spacing(3),
      [breakpoints.down('sm')]: {
        paddingBottom: spacing(1.5),
      },
    },
  },
});

interface StoryBodyType {
  story: IStory;
}

export const StoryBody: ComponentType<StoryBodyType> = ({ story }) => {
  const classes = useClasses(styles);
  const { i18n } = useTranslation();
  const getSortedElements = (story?.elements || []).reduce((acc: any[], element: any) => {
    const index = acc.findIndex((e: IStoryElement) => e.position > element.position);
    acc.splice(index === -1 ? acc.length : index, 0, element);
    return acc;
  }, []);

  return (
    <div>
      <div className={classes.title}>
        <TranslateContext.Consumer>
          {(noTranslate) =>
            story.do_not_translate || i18n.language === story.original_title_language || noTranslate
              ? story.title
              : story.translated_title
          }
        </TranslateContext.Consumer>
      </div>
      <PlayerStory carId={story.car.id} storyId={story.id} />
      <div className={classes.elements}>
        {
          // eslint-disable-next-line array-callback-return
          getSortedElements.map((element) => {
            if (element.element_type === 'original_text' && element?.texts.length > 0) {
              return <StoryContentTexts key={element.id} texts={element.texts} />;
            }

            if (element.element_type === 'gallery' && element?.images.length > 0) {
              return <StoryContentImages key={element.id} images={element.images} />;
            }

            if (element.element_type === 'assisted_records' && element?.assisted_records.length > 0) {
              return <StoryGroupRecords key={element.id} data={element.assisted_records} />;
            }

            if (element.element_type === 'records' && element?.records.length > 0) {
              return <StoryRecords key={element.id} records={element.records} />;
            }
            if (element.element_type === 'youtube_video') {
              return <StoryContentVideo key={element.id} element={element} />;
            }
          })
        }
      </div>
    </div>
  );
};
