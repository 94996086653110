import { useMutation } from 'react-query';

import { axiosApiInstance as api, mapKeysToCamelCase } from 'utils';

export type AppleLoginResponse = {
  accessToken: string;
  username: string;
  error?: string;
};

type AppleLoginRequest = {
  firebaseData: any;
  language: string;
};

const login = async (body: AppleLoginRequest): Promise<Partial<AppleLoginResponse>> => {
  try {
    const { data } = await api.post('/users/apple-callback', body);
    return mapKeysToCamelCase({ ...data });
  } catch (error: any) {
    return { error: error.response.data.message };
  }
};

export const useAppleLoginMutation = () => {
  return useMutation(login);
};
