import { IUser } from 'features/stories-feed/StoriesTypes';
import { useInfiniteQuery } from 'react-query';
import { axiosApiInstance as api, isLoggedIn, mapKeysToSnakeCase } from 'utils';

export type followersProfileResponse = {
  current_page: number;
  per_page: number;
  followers?: IUser[];
  followings?: IUser[];
};

export const getProfileFollowers = async (
  page: number,
  pagination: any,
  follows: string,
  story_creator_id: string,
): Promise<followersProfileResponse> => {
  const isAuth = isLoggedIn();
  if (isAuth) {
    pagination = { ...pagination, page, story_creator_id };
    const { data } = await api.get(`users/${follows}${pagination.username ? '/search' : ''}`, {
      params: mapKeysToSnakeCase(pagination),
    });
    return data;
  } else
    return {
      per_page: 0,
      current_page: 0,
    };
};

export const useProfileFollowersQuery = (pagination: any, follows: string, options: object, story_creator_id: string) => {
  return useInfiniteQuery(
    ['getProfileFollowers', follows, pagination],
    ({ pageParam: page = 1 }) => getProfileFollowers(page, pagination, follows, story_creator_id),
    {
      cacheTime: 0,
      getNextPageParam(lastPage, allPages) {
        if (follows === 'followers' && lastPage.followers) {
          return lastPage.followers.length > 0 ? lastPage.current_page + 1 : undefined;
        }
        if (follows === 'followings' && lastPage.followings) {
          return lastPage.followings.length > 0 ? lastPage.current_page + 1 : undefined;
        }
      },
      ...options,
    }
  );
};
