import { useMutation, useQuery } from 'react-query';
import { axiosApiInstance as api, isLoggedIn } from 'utils';

const bannedCreation = async () => {
  try {
    const isAuth = isLoggedIn();
    if (isAuth) {
      const { data } = await api.get('users/is-banned');
      return data;
    } else {
      return false;
    }
  } catch (error) {
    return { error: true };
  }
};

export const useUserIsBannedCreationQuery = () => {
  return useQuery(['bannedFromStoriesCreation'], () => bannedCreation(), { cacheTime: 0 });
};

export const useUserIsBannedCreationMutation = () => {
  return useMutation(['bannedFromStoriesCreation'], () => bannedCreation());
};
