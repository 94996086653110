import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from '@mui/material';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validateYouTubeUrl } from 'utils/YouTubeUtil';
import { pasteText } from 'utils/clipboard';

interface VideoSelectDialogProps {
  open: boolean;
  cancel: () => void;
  confirm: (link: string) => void;
}

export const VideoSelectDialog: ComponentType<VideoSelectDialogProps> = ({ open, cancel, confirm }) => {
  const { t } = useTranslation();
  const [link, setLink] = useState('');
  const [isValid, setIsValid] = useState(false);
  const handleChange = (event: any) => {
    const value = event.target.value;
    setLink(value);
    setIsValid(validateYouTubeUrl(value));
  };

  const handlePaste = async () => {
    const text = await pasteText();
    setLink(text);
    setIsValid(validateYouTubeUrl(text));
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{t('story_add_video_dialog_title')}</DialogTitle>
      <DialogContent>
        <TextField
          placeholder={t('story_add_video_dialog_placeholder')}
          label={t('story_add_video_dialog_label')}
          variant='outlined'
          value={link}
          onChange={handleChange}
          fullWidth
          margin='normal'
          error={!isValid && link !== ''}
          helperText={!isValid && link !== '' ? t('story_add_video_dialog_helper') : ''}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Button onClick={handlePaste} style={{}}>
                  {t('__common__paste')}
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>{t('__common__cancel')}</Button>
        <Button onClick={() => confirm(link)} disabled={!isValid}>
          {t('__common__confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
