import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Theme,
} from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import { Volume } from 'types';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { UpdateVolumePayload } from './DateMetricForm';

const tKey = getTKey('volume_change_dialog');

const styles = ({ breakpoints }: Theme) => ({
  paper: {
    maxWidth: 460,
    backgroundColor: '#333',
  },
  description: {
    color: 'rgba(255, 255, 255, 0.87)',
  },
  actions: {
    '& button': {
      letterSpacing: 1.25,
    },
  },
  loadingContainer: {
    minWidth: 412,
    minHeight: 60,
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      minWidth: 280,
    },
  },
});

interface VolumeChangeDialogProps {
  from: Volume;
  to: Volume;
  loading: Boolean;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: (data: UpdateVolumePayload) => void;
}

export const VolumeChangeDialog: ComponentType<VolumeChangeDialogProps> = ({
  from,
  to,
  loading,
  onClose,
  onCancel,
  onConfirm,
}) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const rate =
    from === 'g'
      ? `1 ${t(tKey('gallon'))} = 3.78541 ${t(tKey('liter'))}`
      : `1 ${t(tKey('liter'))} = 0.264172 ${t(tKey('gallon'))}`;

  return (
    <Dialog open={true} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogContent>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          <DialogContentText classes={{ root: classes.description }}>
            {t(tKey('description'))} {rate}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button onClick={onCancel} color='primary'>
          {t(tKey('cancel'))}
        </Button>
        <Button onClick={() => onConfirm({ fromVolume: from, toVolume: to })} color='primary'>
          {t(tKey('convert'))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
