import { Apple } from '@mui/icons-material';
import { Button, Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  appleButton: {
    backgroundColor: 'rgb(255,255,255)',
    borderRadius: spacing(3),
    minWidth: 140,
    minHeight: 30,
    padding: spacing(1.5, 7.5),
    '&:hover': {
      backgroundColor: 'rgb(230,230,230)',
    },
  },
});

interface AppleSignInButtonProps {
  onClick: () => void;
  label: string;
}

export const AppleSignInButton: ComponentType<AppleSignInButtonProps> = ({ onClick, label }) => {
  const classes = useClasses(styles);
  return (
    <Button variant='contained' className={classes.appleButton} startIcon={<Apple />} size='large' onClick={onClick}>
      {label}
    </Button>
  );
};
