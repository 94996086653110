import { Theme } from "@mui/material";
import { Form } from "components/Form/Form";
import { SearchInputControlled } from "components/Form/SearchInputControlled";
import { TFunction } from "i18next";
import { ComponentType, useState } from "react";
import { useTranslation } from "react-i18next";
import { yupStringValidator } from "utils";
import { useClasses } from "utils/hooks/useClasses";
import * as yup from 'yup';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  searchInputWrap: {
    marginBottom: spacing(3),
    [breakpoints.down('sm')]:{
      '& .MuiFormControl-root':{
        background: '#2a2a2a',
      }
    },
    [breakpoints.up('sm')]:{
      '& .MuiFormControl-root':{
        background: '#202020',
      }
    }
  },
})

interface SearchFollowsFormType {
  onSearch: (data: any) => void;
  onReset: () => void;
}

const validationSchema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    username: yupStringValidator(t),
  });

export const SearchFollowsForm: ComponentType<SearchFollowsFormType> = ({onSearch,onReset}) => {
  const classes = useClasses(styles);
  const {t} = useTranslation()
  
  const defaultValues = {username: ''};

  const onSubmit = (data: any) =>{
    onSearch(data);
  }

  const [resetValue, setResetValue] = useState(0);

  const reset = () => {
    setResetValue(resetValue+1);
    onReset()
  }

  return (

    <Form 
      schema={validationSchema(t)} 
      onSubmit={onSubmit} shouldReset={false} 
      defaultValues={defaultValues}
      resetValue={resetValue}
      >
      <div className={classes.searchInputWrap}>
        <SearchInputControlled 
          name="username" 
          label={t("story_search_search_label")} 
          onReset={reset} 
          onSearch={onSubmit}
        />
      </div>
    </Form>
  )
}