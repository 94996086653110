import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, {
  MentionData,
  MentionPluginTheme,
  defaultSuggestionsFilter,
} from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import { Send } from '@mui/icons-material';
import { Chip, IconButton, Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { UnregisteredPopup } from 'components/UnregisteredPopup/UnregisteredPopup';
import { ContentState, DraftHandleValue, EditorState, Entity } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { IComment } from 'features/stories-feed/StoriesTypes';
import { useProfileFollowers } from 'lib/useProfileFollowers';
import { ComponentType, ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useClasses } from 'utils/hooks/useClasses';
import editorStyles from './MentionEditorStyles.module.css';
import mentionsStyles from './MentionsStyles.module.css';
import { Suggestions } from './SuggestionsContext';
import { theme } from 'theme';
import { isBlink, isGecko } from 'utils/webClient';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';

const styles = ({ breakpoints, spacing, palette }: Theme) => ({
  rootFixed: {
    zIndex: 10,

    position: 'fixed',
    bottom: 0,
    width: '37vw',
    margin: spacing(0, 0, 0),
    paddingRight: 84,
    minWidth: 548,
    [breakpoints.down('md')]: {
      paddingRight: 0,
      width: '100%',
      minWidth: 'unset',
      left: 0,
      right: 0,
    },
  },
  inputWrapper: {
    padding: spacing(2),
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '100%',
    alignItems: 'center',
  },
  commentBox: {
    background: '#2E2E2E',
    paddingBottom: 'env(safe-area-inset-bottom)',
  },
  sendBtn: {
    marginLeft: spacing(1),
    '&.Mui-disabled': {
      color: 'rgba(3, 218, 197, 0.38)',
    },
  },
  inputBlock: {
    width: '100%',
    height: spacing(4),
    background: '#202020',
    margin: spacing(2),
    padding: spacing(1),
    borderRadius: 4,
    maxWidth: '100%',
  },
  mentionsContainer: {
    margin: spacing(1),
    paddingTop: spacing(1),
  },
  isFocused: {
    [breakpoints.down('sm')]: {
      position: 'absolute',
      left: '0px',
      right: '0px',
      zIndex: 1000,
    },
  },
  fixedFocusedBlinkGecko: {
    position: 'fixed',
    zIndex: 1000,
  },
  replyToBox: {
    padding: spacing(1, 3, 1),
    marginBottom: spacing(-2),
    display: 'flex',
  },
  replyUsername: {
    color: palette.text.primary,
    background: palette.additionalSecondary[800],
    padding: spacing(0, 1),
    height: spacing(3),
  },
});

interface CommentInputType {
  send: (comment: string, reply?: string) => any;
  edit: (comment: string, id: string) => any;
  defaultText?: string;
  defaultSuggestions?: any[];
  authorized: boolean;
  story_creator_id: string;
}

const MAX_LENGTH_COMMENT = 500;

export const CommentInput: ComponentType<CommentInputType> = ({
  send,
  defaultText,
  edit,
  defaultSuggestions,
  authorized,
  story_creator_id,
}) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [sendBtnActive, setSendBtnActive] = useState(false);
  const [openUnregistered, setOpenUnregistered] = useState<boolean>(false);
  const suggestionsFromContext = useContext(Suggestions);
  const handleEditorChange = (newEditorState: EditorState) => {
    handleScroll();
    const contentState = newEditorState.getCurrentContent();
    const rawContent = contentState.getPlainText();

    if (rawContent.length === 0) {
      setSendBtnActive(false);
    } else {
      setSendBtnActive(true);
    }
    setEditorState(newEditorState);
  };

  const [reply, setReply] = useState<IComment | undefined>(undefined);
  const [editingId, setEditingId] = useState<string | undefined>(undefined);
  const [pagination, setPagination] = useState<any>({ page: 1, perPage: 10, username: '' });
  const { data, isFetching, refetch } = useProfileFollowers(pagination, 'followers', story_creator_id, {
    enabled: false,
  });
  const [isFocused, setIsFocused] = useState(false);
  const [top, setTop] = useState(0);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isBlinkOrGecko = isBlink || isGecko;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  useEffect(() => {
    setSuggestions(defaultSuggestions || suggestionsFromContext || []);
  }, [defaultSuggestions, suggestionsFromContext]);

  useEffect(() => {
    if (data && data.pages.length > 0 && data.pages[0].followers) {
      const followers = data.pages[0].followers.map((user) => {
        return { name: user.username, id: user.id };
      });
      const mergedSuggestions = [...searchSuggestions, ...followers];
      const uniqueSuggestions = mergedSuggestions.reduce((accumulator, current) => {
        const existingItem = accumulator.find((item: any) => item.id === current.id);
        if (!existingItem) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
      setSearchSuggestions(uniqueSuggestions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetching]);

  const ref = useRef<Editor>(null);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [searchSuggestions, setSearchSuggestions] = useState<any[]>([]);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: mentionsStyles,
      mentionPrefix: '@',
      supportWhitespace: true,
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const onSearchChange = ({ value }: { value: string }) => {
    setPagination({ ...pagination, username: value });
    const countMentions = countMentionEntities(editorState.getCurrentContent());
    if (countMentions < 3) setSearchSuggestions(defaultSuggestionsFilter(value, suggestions));
    else {
      setSearchSuggestions([]);
    }
    handleScroll();
  };

  const handleFocus = () => {
    setIsFocused(true);
    if (ref.current) {
      const editorDOM = ReactDOM.findDOMNode(ref.current);
      if (editorDOM instanceof HTMLElement) {
        const rect = editorDOM?.getBoundingClientRect();
        const inputBottom = rect.bottom;
        const windowHeight = window.innerHeight;
        const keyboardEstimatedHeight = windowHeight * 0.3;

        if (inputBottom + keyboardEstimatedHeight > windowHeight) {
          const overlap = inputBottom + keyboardEstimatedHeight - windowHeight;
          window.scrollTo({ top: overlap, behavior: 'smooth' });
        }
      }
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      refetch();
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    const contentState = editorState.getCurrentContent();

    const plain = convertEditorContentToCustomFormat(contentState);
    if (plain === '') {
      setIsSubmitting(false);
      return;
    }
    if (editingId) {
      let response = await edit(plain, editingId);
      if (response.id) {
        const edited = new CustomEvent('editedComment', { detail: response });
        window.dispatchEvent(edited);
        toast(t('comment_edited'));
        setEditingId(undefined);
      }
      setIsSubmitting(false);
    } else {
      let response = await send(plain, reply?.id);
      if (reply?.id) {
        const closeEvent = new CustomEvent('sentReply', { detail: response });
        window.dispatchEvent(closeEvent);
      }
      const addCommentEvent = new CustomEvent('addComment');
      window.dispatchEvent(addCommentEvent);
      if (response?.id) {
        setReply(undefined);
        toast(t('comment_published'));
      }
      setIsSubmitting(false);
    }
    setEditorState(EditorState.createEmpty());
    if (/Mobi/.test(navigator.userAgent) && Capacitor.isNativePlatform()) {
      Keyboard.hide();
    }
  };

  const elementRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (defaultText) {
      setEditorState(EditorState.createWithContent(ContentState.createFromText(defaultText)));
    }

    const onReplyStart = (event: any) => {
      const comment: IComment = event.detail;
      setReply(comment);
      setSendBtnActive(true);
    };

    window.addEventListener('onReplyStart', onReplyStart);

    const onEditStart = (event: any) => {
      const comment: IComment = event.detail;
      setEditingId(comment.id);
      setEditorState(EditorState.createWithContent(ContentState.createFromText(comment.text)));
      setSendBtnActive(true);
    };
    window.addEventListener('startEdit', onEditStart);

    const onEditEnd = (event: any) => {
      setEditorState(EditorState.createWithContent(ContentState.createFromText('')));
      setSendBtnActive(true);
    };
    window.addEventListener('endEdit', onEditEnd);

    const onScreenScroll = (event: any) => {
      handleScroll();
    };

    const onScreenResize = (event: any) => {
      handleScroll();
    };

    if (!isBlinkOrGecko) {
      window.addEventListener('scroll', onScreenScroll);
      window?.visualViewport?.addEventListener('resize', onScreenResize);
    }
    return () => {
      window.removeEventListener('onReplyStart', onReplyStart);
      window.removeEventListener('startEdit', onEditStart);
      window.removeEventListener('endEdit', onEditEnd);
      window.removeEventListener('scroll', onScreenScroll);
      window?.visualViewport?.removeEventListener('resize', onScreenResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = () => {
    const innerHeight = window.innerHeight;
    const documentScrollTop = document.documentElement.scrollTop;
    const isMobileDevice = window.innerWidth <= 600;
    const commentDivHeight = elementRef.current?.clientHeight || 0;
    if (isMobileDevice && 'visualViewport' in window) {
      const visualViewport = window.visualViewport;
      const visualViewportHeight = visualViewport?.height || 0;
      const keyboardHeight = visualViewportHeight - window.innerHeight;
      if (Capacitor.getPlatform() === 'ios' && Capacitor.isNativePlatform()) {
        const newTop =
          innerHeight - commentDivHeight + documentScrollTop + keyboardHeight - (innerHeight > 450 ? 48 : 20);
        setTop(newTop);
      } else if (Capacitor.getPlatform() === 'web') {
        const newTop = (visualViewport?.height || 0) + (visualViewport?.pageTop || 0) - commentDivHeight;
        setTop(newTop);
      } else if (keyboardHeight > 0) {
        const newTop = documentScrollTop + keyboardHeight;
        setTop(newTop);
      } else {
        const newTop = visualViewportHeight - commentDivHeight + documentScrollTop - keyboardHeight;
        setTop(newTop);
      }
    } else {
      const newTop = document.documentElement.clientHeight - commentDivHeight;
      setTop(newTop);
    }
  };

  function convertEditorContentToCustomFormat(editorContent: ContentState): string {
    const blockMap = editorContent.getBlockMap();
    let modifiedBlocks: string[] = [];

    blockMap.forEach((block) => {
      let blockText = block?.getText();
      let offset = 0;

      block?.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();
          return (
            entityKey !== null &&
            (Entity.get(entityKey).getType() === 'MENTION' || Entity.get(entityKey).getType() === 'mention')
          );
        },
        (start, end) => {
          const entityKey = block.getEntityAt(start);
          const entity = Entity.get(entityKey);
          const mention = entity.getData().mention;
          const mentionReplacement = `<user_id>${mention.id}</user_id>`;
          blockText = blockText?.substring(0, start + offset) + mentionReplacement + blockText?.substring(end + offset);

          offset += mentionReplacement.length - (end - start);
        }
      );

      modifiedBlocks.push(blockText || '');
    });

    return modifiedBlocks.join('\n');
  }

  function countMentionEntities(editorContent: ContentState): number {
    let mentionEntityCount = 0;
    editorContent.getBlocksAsArray().forEach((block) => {
      block?.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();
          return (
            entityKey !== null &&
            (Entity.get(entityKey).getType() === 'MENTION' || Entity.get(entityKey).getType() === 'mention')
          );
        },
        () => {
          mentionEntityCount += 1;
        }
      );
    });
    return mentionEntityCount;
  }

  const _getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }
    return length;
  };

  const _handleBeforeInput = (chars: string, editorState: EditorState, eventTimeStamp: number): DraftHandleValue => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > MAX_LENGTH_COMMENT - 1) {
      // console.log('you can type max ten characters');
      return 'handled';
    }
    return 'not-handled';
  };

  const handleClickInput = () => {
    if (!authorized) {
      setOpenUnregistered(true);
    }
  };

  const handleCursorInEditorClick = () => {
    if (ref.current) {
      ref.current.focus();
    }
  };

  return (
    <>
      <div
        className={clsx([
          classes.rootFixed,
          isFocused && isBlinkOrGecko && classes.fixedFocusedBlinkGecko,
          isFocused && !isBlinkOrGecko && classes.isFocused,
        ])}
        ref={elementRef}
        style={
          fullScreen && !isBlinkOrGecko ? { top: isFocused ? top : 'unset', bottom: !isFocused ? 0 : 'unset' } : {}
        }
      >
        <div className={classes.commentBox}>
          <MentionSuggestions
            open={open}
            onOpenChange={onOpenChange}
            suggestions={searchSuggestions}
            onSearchChange={onSearchChange}
            onAddMention={() => {
              handleScroll();
            }}
            // @ts-ignore
            entryComponent={Entry}
            popoverContainer={({ children }) => <div className={classes.mentionsContainer}>{children}</div>}
          />
          {reply && (
            <>
              <div className={classes.replyToBox}>
                replying to {'\u00A0'}
                <Chip
                  className={classes.replyUsername}
                  label={reply?.user.username}
                  onDelete={() => {
                    setReply(undefined);
                  }}
                />
              </div>
            </>
          )}
          <div className={classes.inputWrapper} onClick={handleCursorInEditorClick}>
            <div className={clsx(editorStyles.editor)} onClick={handleClickInput} id='edtr'>
              <Editor
                editorKey={'editor'}
                editorState={editorState}
                onChange={handleEditorChange}
                handleBeforeInput={_handleBeforeInput}
                plugins={plugins}
                ref={ref}
                placeholder={t('comment_input_placeholder')}
                readOnly={!authorized}
                onBlur={handleBlur}
                onFocus={handleFocus}
                autoCorrect='off'
                autoComplete='off'
              />
            </div>
            <IconButton
              className={clsx(classes.sendBtn, sendBtnActive && classes.sendBtnActive)}
              color='secondary'
              type='submit'
              disabled={!sendBtnActive}
              onClick={(event) => {
                if (!Capacitor.isNativePlatform() && !isSubmitting) {
                  event.stopPropagation();
                  onSubmit(event);
                }
              }}
              onTouchEnd={(event) => {
                if (Capacitor.isNativePlatform() && !isSubmitting) {
                  event.stopPropagation();
                  onSubmit(event);
                }
              }}
            >
              <Send />
            </IconButton>
          </div>
        </div>
        <UnregisteredPopup
          open={openUnregistered}
          from={pathname}
          onClose={() => {
            setOpenUnregistered(false);
          }}
        />
      </div>
    </>
  );
};

export interface EntryComponentProps {
  className?: string;
  onMouseDown(event: MouseEvent): void;
  onMouseUp(event: MouseEvent): void;
  onMouseEnter(event: MouseEvent): void;
  role: string;
  id: string;
  'aria-selected'?: boolean | 'false' | 'true';
  theme?: MentionPluginTheme;
  mention: MentionData;
  selectMention: any;
  isFocused: boolean;
  searchValue?: string;
}

function Entry(props: EntryComponentProps): ReactElement {
  const {
    mention,
    theme,
    searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
    isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
    selectMention, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...parentProps
  } = props;

  return (
    //@ts-ignore
    <div {...parentProps}>
      <div className={theme?.mentionSuggestionsEntryContainer}>
        <div className={theme?.mentionSuggestionsEntryContainerRight}>
          <div className={theme?.mentionSuggestionsEntryText}>{mention.name}</div>

          <div className={theme?.mentionSuggestionsEntryTitle}>{mention.title}</div>
        </div>
      </div>
    </div>
  );
}
