import { Box, Button, Modal, Theme, Typography } from '@mui/material';
import { ComponentType, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'store2';
import { theme } from 'theme';
import { getTKey } from 'utils';

import { ImageLogo } from 'components/ImageLogo/ImageLogo';
import { StyledButton } from 'components/StyledButton/StyledButton';
import { useProfileInfoQuery, useSetDemoTourMutation } from 'services';
import { useClasses } from 'utils/hooks/useClasses';
import { DemoSlides } from './DemoSlides';
import { happyEmoji, starsEmoji, thumbEmoji } from 'assets/demo-final';
import { ProfileContext as Context } from 'store/profile/store';
import { setProfile } from 'store/profile/slice';

const styles = ({ breakpoints, spacing }: Theme) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  container: {
    backgroundColor: theme.palette.grey[800],
    width: 792,
    height: 624,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(10, 5, 4),
    textAlign: 'center',
    flexDirection: 'column',
    [breakpoints.down('sm')]: {
      padding: spacing(10, 2, 4),
      paddingBottom: `calc(${spacing(4)} + env(safe-area-inset-bottom))`,
      backgroundColor: theme.palette.background.default,
      height: '100vh',
    },
  },
  title: {
    color: theme.palette.primary.main,
  },
  motto: {
    fontSize: 13,
    color: '#C8FFF4',
  },
  username: {
    fontSize: 24,
  },
  help: {
    textAlign: 'center',
    width: 512,
    [breakpoints.down('sm')]: {
      width: '100%',
      padding: spacing(0, 1),
    },
  },
  skip: {
    fontSize: 14,
    color: '#03DAC5',
    textTransform: 'none',
  },
  hint: {
    color: 'rgba(255,255,255,0.6)',
    fontSize: 14,
    textAlign: 'center',
    padding: spacing(0, 10),
    [breakpoints.down('sm')]: {
      padding: spacing(0, 5),
    },
  },
  finalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: '100%',
  },
  finalGraffiti: {
    height: 214,
    width: 214,
    borderRadius: 128,
    background: 'rgba(255, 255, 255, 0.07)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  enjoy: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.2px',
  },
  thank: {
    marginTop: spacing(2),
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.2px',
  },

  happyEmoji: {
    width: 102,
    height: 102,
  },
  starsEmoji: {
    width: 34,
    height: 33,
    position: 'absolute',
    top: 19,
    right: 11,
  },
  thumbEmoji: {
    width: 34,
    height: 33,
    position: 'absolute',
    top: 63,
    left: -7,
  },
  finalTexts: {
    margin: spacing(5, 0),
  },
  finalButton: {
    margin: spacing(5, 0),
  },
});

const tKey = getTKey('demo_tour');

type DemoTourProps = {
  handleCloseModal: () => void;
};

export const DemoTour: ComponentType<DemoTourProps> = ({ handleCloseModal }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const [open] = useState(true);
  const [startDemo, setStartDemo] = useState(false);
  const [finishDemo, setFinishDemo] = useState(false);

  const mutation = useSetDemoTourMutation();
  const { data, refetch } = useProfileInfoQuery();
  const username = data?.username ?? '';
  const { dispatch } = useContext(Context);
  const handleClose = () => {
    store('driverbook_demoTour', false);
    handleCloseModal();
  };
  const handleNext = () => {
    setStartDemo(true);
  };
  const skipTour = () => {
    mutation.mutate({ tourSkipped: true });
    handleClose();
  };

  const finishTour = async () => {
    setStartDemo(false);
    setFinishDemo(true);
    await handleFinishTour();
    await refetch();
    dispatch(setProfile(data));
  };

  const handleFinishTour = () => {
    return new Promise((resolve) => {
      mutation.mutate(
        { tourFinished: true },
        {
          onSuccess(data, variables, context) {
            resolve(data);
          },
        }
      );
    });
  };

  return (
    <Modal
      open={open}
      onClose={skipTour}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box className={classes.main}>
        {startDemo && <DemoSlides handleClose={skipTour} handleFinish={finishTour} />}
        {!startDemo && !finishDemo && (
          <Box className={classes.container}>
            <ImageLogo width={100} height={100} />
            <div>
              <Typography variant='h4' className={classes.title}>
                Driverbook
              </Typography>
            </div>
            <Typography className={classes.username}>{t(tKey('username'), { username })}</Typography>
            <Typography className={classes.help}>{t(tKey('text'))}</Typography>
            <StyledButton onClick={handleNext} label={t(tKey('button'))}></StyledButton>
            <Button onClick={skipTour}>
              <Typography className={classes.skip}>{t(tKey('skip'))}</Typography>
            </Button>
            <div>
              <Typography className={classes.hint}>{t(tKey('note1'))}</Typography>
              <Typography className={classes.hint}>{t(tKey('note2'))}</Typography>
            </div>
          </Box>
        )}
        {finishDemo && (
          <Box className={classes.container}>
            <div className={classes.finalContainer}>
              <div className={classes.finalGraffiti}>
                <img src={happyEmoji} alt={''} className={classes.happyEmoji} loading='eager' />
                <img src={starsEmoji} alt={''} className={classes.starsEmoji} loading='eager' />
                <img src={thumbEmoji} alt={''} className={classes.thumbEmoji} loading='eager' />
              </div>
              <div className={classes.finalTexts}>
                <Typography className={classes.enjoy}>{t(tKey('enjoy_one'))}</Typography>
                <Typography className={classes.enjoy}>{t(tKey('enjoy_two'))}</Typography>
                <Typography className={classes.thank}>{t(tKey('thank'))}</Typography>
              </div>
              <StyledButton
                onClick={handleClose}
                label={t(tKey('finish'))}
                className={classes.finalButton}
              ></StyledButton>
            </div>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
