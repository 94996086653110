import io, { Socket } from 'socket.io-client';

const URL = process.env.REACT_APP_PUBLIC_WS_URL;

let socket: undefined | Socket;

if (URL) {
  socket = io(URL, { transports: ['websocket'], reconnection: true, secure: true });
}

export { socket };
