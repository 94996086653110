import { CommonLayout } from 'components';

import { useClasses } from 'utils/hooks/useClasses';
import { Theme } from '@mui/material';
import { AssistantChat } from './components/AssistantChat';
const styles = ({ spacing, breakpoints }: Theme) => ({
  container: {
    width: '50%',
    margin: 'auto',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
    [breakpoints.up('sm')]: {
      minWidth: 643,
    },
  },
});
export const AssistantPage = () => {
  const classes = useClasses(styles);
  return (
    <CommonLayout hideMobileNav assistant>
      <div className={classes.container}>
        <AssistantChat />
      </div>
    </CommonLayout>
  );
};
