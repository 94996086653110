import { useMutation } from 'react-query';

import { ProfileValues } from 'types';
import { axiosApiInstance as api, mapKeysToCamelCase } from 'utils';
import { LoginResponse } from './useLoginMutation';

const login = async (body: ProfileValues): Promise<LoginResponse> => {
  try {
    const { data } = await api.post('/engineroom/admin-login', body);
    return mapKeysToCamelCase(data);
  } catch (error: any) {
    return { error: error.response.data.message };
  }
};

export const useAdminLoginMutation = () => {
  return useMutation(login);
};
