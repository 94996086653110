import { ComponentType } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { useClasses } from 'utils/hooks/useClasses';
import { AssistantCountryCode, getLabelByCode } from 'utils/assistantLanguage';
import { useTranslation } from 'react-i18next';
const styles = ({ spacing, breakpoints, palette }: Theme) => ({
  root: {
    '& p': {
      textTransform: 'uppercase',
      margin: '0 auto',
      textAlign: 'center',
      fontSize: 10,
      letterSpacing: '0.2px',
      lineHeight: '10px',
      padding: spacing(1),
      color: '#FFFFFF73',
      maxWidth: '70%',
    },
  },
});

interface AssistantSystemMessageProps {
  country?: AssistantCountryCode;
}

export const AssistantSystemMessage: ComponentType<AssistantSystemMessageProps> = ({ country }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  return (
    <Box className={classes.root}>
      <Typography>
        {t('assistant_change_language')} {t(getLabelByCode(country || 'es'))}
      </Typography>
    </Box>
  );
};
