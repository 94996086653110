import { useEffect, useState } from 'react';
import Compressor from 'compressorjs';
import { Maybe } from 'yup/lib/types';

export const useCompressImage = (image: Maybe<File>, isDefault: boolean, uploaderIsDirty: boolean) => {
  const [compressedImage, setCompressedImage] = useState<File | null>(image as File);
  const [isOldImage, setIsOldImage] = useState<boolean>(false);

  useEffect(() => {
    // completely clean image
    if (!image) {
      setCompressedImage(null);
      setIsOldImage(false);
    }

    // do nothing if there is old image
    if (image && isDefault && !uploaderIsDirty) {
      setIsOldImage(true);
    }

    // set new image
    if ((image && !isDefault) || (image && isDefault && uploaderIsDirty)) {
      setIsOldImage(false);

      new Compressor(image, {
        quality: 0.9,
        maxWidth: 1000,
        maxHeight: 1000,
        success: (res) => {
          setCompressedImage(res as File);
        },
      });
    }
  }, [image, isDefault, uploaderIsDirty]);

  return { compressedImage, isOldImage };
};
