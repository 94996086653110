import { ArrowForward } from '@mui/icons-material';
import { Box, Grid, Theme, Typography } from '@mui/material';
import clsx from 'clsx';
import { StyledButton } from 'components';
import { AILoader } from 'components/AILoader/AILoader';
import { ModalSearchSelect } from 'components/Form/ModalSearchSelect';
import { useChangeLanguage } from 'lib';
import { ChangeEvent, ComponentType, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCreateRecordsBatchMutation } from 'services/records/hooks/useCreateRecordsBatchMutation';
import { useCreateRecordsFromInvoiceMutation } from 'services/records/hooks/useCreateRecordsFromInvoiceMutation';
import { useRecordsLimitsQuery } from 'services/records/hooks/useRecordsLimitsQuery';
import store from 'store2';
import { NewRecordValues } from 'types';
import { createNestedFormData, getTKey, googleLanguages } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { AIHowTo, Step } from './AIHowTo';
import { AIRecordLeft } from './AIRecordLeft';
import { InvoiceFormLoader } from './InvoiceFormLoader';
import { RecordsAIForm } from './RecordsAIForm';
import { AIRecordsMileage, AIRecordsMileageModeType } from './AIRecordsMileage';

const tKey = getTKey('record_invoice_form');
const styles = ({ palette, breakpoints, spacing }: Theme) => ({
  root: {},
  menuItem: {
    fontSize: 16,
    fontWeight: '500',
  },
  menuItemSub: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 12,
    fontWeight: '400',
  },
  menuContainer: {
    display: 'flex',
    color: 'rgba(255, 255, 255, 0.87)',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: spacing(2, 0),
    cursor: 'pointer',
  },
  uploadArea: {
    marginTop: spacing(4),
    border: '1px dashed #BB86FC',
    background: 'rgba(187, 134, 252, 0.1)',
    color: 'rgba(187, 134, 252, 1)',
    borderRadius: 4,
    minHeight: 110,
    '& input[type="file"]': {
      display: 'none',
    },
  },
  uploadAreaDisabled: {
    border: '1px dashed #BBB',
    color: palette.text.hint,
    background: 'rgba(200, 200, 200, 0.1)',
    '& p': {
      cursor: 'default',
    },
  },
  uploadText: {
    textTransform: 'uppercase',
    fontWeight: 700,
    cursor: 'pointer',
  },
});

const allowFileTypes = ['jpg', 'jpeg', 'png', 'pdf'];

const stepsHowTo: Step[] = [
  {
    number: 1,
    textKey: 'prepare',
  },
  {
    number: 2,
    textKey: 'upload',
  },
  {
    number: 3,
    textKey: 'review',
  },
];

export const InvoiceForm: ComponentType = () => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const { carId } = useParams<{ carId: string }>();
  const [open, setOpen] = useState(false);
  const { chosenLanguage } = useChangeLanguage();
  const [finalRecordLanguage, setFinalRecordLanguage] = useState(chosenLanguage);
  const [file, setFile] = useState<File>();
  const refFileUploader = useRef<any>(null);
  const [activeStep, setActiveStep] = useState(1);
  const mutationProcess = useCreateRecordsFromInvoiceMutation();
  const [records, setRecords] = useState<NewRecordValues[]>([]);
  const [idSubmitting, setIdSubmitting] = useState('');
  const mutation = useCreateRecordsBatchMutation();
  const navigate = useNavigate();
  const { data, isLoading } = useRecordsLimitsQuery();
  const [available, setAvailable] = useState(true);
  const storeKey = 'driverbook_invoice_records_' + carId;
  const refData = useRef<any>([]);
  const [mileageData, setMileageData] = useState<{ mode: AIRecordsMileageModeType; mileage: string }>({
    mode: 'default',
    mileage: '',
  });

  useEffect(() => {
    const storedRecords = store(storeKey);
    if (storedRecords) {
      setRecords(storedRecords);
      setActiveStep(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading && data?.invoice_record_count === 0) {
      setAvailable(false);
    }
  }, [data, isLoading]);

  const onChangeLanguage = (data: any) => {
    const chosenLang = data.target.value;
    setFinalRecordLanguage(chosenLang);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (validateFile(file)) {
        setFile(e.target.files[0]);
      }
    }
  };

  const handleDrop = function (e: any) {
    if (!available) return;
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      if (validateFile(file)) {
        setFile(e.dataTransfer.files[0]);
      }
    }
  };

  const validateFile = (file: File) => {
    if (allowFileTypes.indexOf(file.type.split('/')[1]) < 0) {
      toast(t(tKey('error_formats')));
      return false;
    }
    return true;
  };

  const uploadOpen = () => {
    if (available) refFileUploader.current.click();
  };

  const handleProcess = () => {
    setActiveStep(2);
    if (file) {
      mutationProcess.mutate(
        { body: { file: file, language: finalRecordLanguage }, carId: carId! },
        {
          onError(error: any, variables, context) {
            if (error.message === 'wrong_document_error') {
              toast(t(tKey('wrong_document_error')));
            } else {
              toast(t('__common__error'));
            }
            setActiveStep(1);
          },
          onSuccess(data, variables, context) {
            if (!data.error) {
              let prepareRecords = data.records;
              if (mileageData.mode === 'custom') {
                prepareRecords = prepareRecords.map((record) => {
                  record.mileage = Number(mileageData.mileage);
                  return record;
                });
              }
              setRecords(prepareRecords);
              updateStore(prepareRecords);
              setActiveStep(3);
            } else if (data.errorData.message === 'wrong_document_error') {
              toast(t(tKey('wrong_document_error')));
              setActiveStep(1);
            } else {
              setActiveStep(1);
              toast(t('__common__error'));
            }
          },
        }
      );
    }
  };

  const handleSubmit = (data: any, id: string) => {
    refData.current = [...refData.current, { id: id, data: data }];

    if (refData.current.length === records.length) {
      const array = refData.current.map((item: any) => item.data);
      const nested: any = createNestedFormData(array);
      nested.append('records_count', records.length);

      mutation.mutate(
        { carId: carId!, formData: nested },
        {
          onSuccess: () => {
            setIdSubmitting('');
            toast(t('record_added'));

            updateStore([]);
            navigate(`/records/${carId}`);
          },
          onError: () => {
            toast(t('__common__error'));
            setIdSubmitting('');
          },
        }
      );
    } else {
      document.querySelector('.Mui-error')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const handleRemove = (id: string) => {
    if (records.length === 1) {
      updateStore([]);
      navigate(`/records/${carId}`);
    } else {
      const updatedRecords = records.filter((record) => record.tmpId !== id);
      setRecords(updatedRecords);
      updateStore(updatedRecords);
    }
  };

  const updateStore = (records: any) => {
    if (records.length === 0) {
      store.remove(storeKey);
    } else {
      store(storeKey, records);
    }
  };

  const createRecordBatch = () => {
    setTimeout(() => {
      refData.current = [];
    }, 0);

    const buttons = document.getElementsByClassName('submit-record-form');
    for (let btn in buttons) {
      if (buttons[btn] instanceof HTMLElement) {
        const element = buttons[btn] as HTMLElement;
        setTimeout(() => {
          element.click();
        }, 0);
      }
    }
  };

  return (
    <>
      {open && (
        <ModalSearchSelect
          open={open}
          onClose={() => setOpen(false)}
          title={t('settings_language_select_language')}
          name='language'
          defaultValue={finalRecordLanguage}
          onChange={onChangeLanguage}
          options={googleLanguages()}
        />
      )}
      {activeStep === 1 && (
        <Grid className={classes.root}>
          <Box className={classes.menuContainer} onClick={() => setOpen(true)}>
            <Box>
              <Typography className={classes.menuItem}>{t('record_ai_select_language')}</Typography>
              <Typography className={classes.menuItemSub}>
                {googleLanguages().find((lang) => lang.value === finalRecordLanguage)?.label}
              </Typography>
            </Box>
            <ArrowForward width={16} height={16} />
          </Box>
          <AIHowTo steps={stepsHowTo} type='invoice' title={t('record_how_to_invoice_title')} />
          {isLoading ? (
            <InvoiceFormLoader />
          ) : (
            <>
              {data && <AIRecordLeft type='invoice' count={data.invoice_record_count} />}
              <AIRecordsMileage onChange={(mode, mileage) => setMileageData({ mode, mileage })} page='itr' />
              <Box
                className={clsx(classes.uploadArea, !available && classes.uploadAreaDisabled)}
                display='flex'
                alignItems='center'
                justifyContent='center'
                onClick={uploadOpen}
                onDrop={handleDrop}
                onDragOver={handleDrop}
              >
                {file ? (
                  file.name
                ) : (
                  <Typography variant='body1' className={classes.uploadText}>
                    {t(tKey('upload_text'))}
                  </Typography>
                )}
                <input
                  type='file'
                  ref={refFileUploader}
                  accept='.jpg,.jpeg,.png,.pdf'
                  onChange={handleFileChange}
                  disabled={!available}
                />
              </Box>
              {available && (
                <Box padding={2} display='flex' justifyContent='center'>
                  <StyledButton label={t(tKey('process'))} onClick={handleProcess} disabled={!file} />
                </Box>
              )}
            </>
          )}
        </Grid>
      )}
      <AILoader
        loading={activeStep === 2}
        messages={[
          { text: t('record_ai_loader_parsing_document'), duration: 3500 },
          { text: t('record_ai_loader_analyzing'), duration: 7000 },
          { text: t('record_ai_loader_generating'), duration: 10500 },
          { text: t('record_ai_loader_final'), duration: 14000 },
        ]}
      />
      {activeStep === 3 && (
        <>
          <RecordsAIForm
            records={records}
            onSubmit={handleSubmit}
            idSubmitting={idSubmitting}
            onRemove={handleRemove}
            aiType='itr'
          />
          <Box display='flex' justifyContent='center'>
            <StyledButton label={t('record_ai_create_records')} onClick={createRecordBatch} />
          </Box>
        </>
      )}
    </>
  );
};
