import { ElementToCorrect } from 'features/admin-panel/components/AdminReview/AdminItemTypes';
import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

export const correctUser = async (body: { id: string; elements: ElementToCorrect[] }): Promise<any> => {
  try {
    const { data } = await api.post(`/engineroom/users/${body.id}/correct`, {
      elements_to_correct: body.elements,
    });
    return data;
  } catch (err) {
    return { error: true };
  }
};

export const useCorrectUserMutation = () => {
  return useMutation(correctUser);
};
