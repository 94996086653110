import { ComponentType } from 'react';

import { CommonLayout, PageLoader } from 'components';
import { useInitialLanguage, useInitialSettings } from 'lib';
import { useCarsQuery } from 'services';

import { EmptyGarage } from 'components/Stories/EmptyGarage';
import { CarsList } from './components/CarsList';

export const CarsPage: ComponentType = () => {
  useInitialSettings();
  useInitialLanguage();
  const { data, isLoading } = useCarsQuery();

  return (
    <CommonLayout>
      {isLoading ? <PageLoader /> : data && data.length > 0 ? <CarsList cars={data} /> : <EmptyGarage />}
    </CommonLayout>
  );
};
