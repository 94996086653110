import { useContext, useEffect, useRef } from 'react';
import { setAlertActive, setAlertPulse } from 'store/profile/slice';
import { ProfileContext as Context } from 'store/profile/store';
import store from 'store2';
import { socket } from 'utils/hooks/useSocket';
export const Sockets = () => {
  const { dispatch } = useContext(Context);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    if (typeof socket !== 'undefined') {
      const onConnect = () => {
        if (store('accessToken')) socket?.emit('authenticate', { token: store('accessToken') });
      };

      if (store('accessToken')) {
        socket.on('connect', onConnect);

        socket.on('connect_error', (error) => {
          console.log(error);
        });

        socket.on('notification', (notification) => {
          dispatch(setAlertActive(true));
          dispatch(setAlertPulse(true));
          timeoutRef.current = setTimeout(() => {
            dispatch(setAlertPulse(false));
          }, 3000);
        });
      }

      return () => {
        clearTimeout(timeoutRef.current!);
        dispatch(setAlertPulse(false));
        if (onConnect) socket?.off('connect', onConnect);
        socket?.off('connect_error');
        socket?.off('notification');
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
