import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { isAppId, isLoggedIn } from 'utils/api';

export const AdminRoute = () => {
  const location = useLocation();

  if (!isLoggedIn()) {
    return <Navigate to='/login' state={{ from: location }} />;
  }
  if (!isAppId()) {
    return <Navigate to='/stories' state={{ from: location }} />;
  }

  return <Outlet />;
};
