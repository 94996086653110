import patronIcon from 'assets/Patron-Icon.svg';
import { Link } from 'react-router-dom';

export const PatronSmall = () => {
  return (
    <Link to={{ pathname: 'https://buymeacoffee.com/driverbook' }} target='_blank'>
      <div>
        <img src={patronIcon} alt='Logo' width={18} height={18}></img>
      </div>
    </Link>
  );
};
