import { CommentsResponse, StoryCommentsPayload } from 'features/stories-feed/StoriesTypes';
import { useInfiniteQuery } from 'react-query';
import { axiosApiInstance as api, isLoggedIn } from 'utils';

export const getStoryCommentsPopular = async (
  { carId, storyId }: StoryCommentsPayload,
  page: number
): Promise<CommentsResponse> => {
  const isAuth = isLoggedIn();
  if (isAuth) {
    const { data } = await api.get(`cars/${carId}/stories/${storyId}/comments/popular`, { params: { page } });
    return data;
  } else {
    return { current_page: '0', per_page: '10', comments: [] };
  }
};

export const useStoryCommentsPopularQuery = ({ carId, storyId }: StoryCommentsPayload) => {
  return useInfiniteQuery(
    ['useStoryCommentsPopular', carId, storyId],
    ({ pageParam: page = 1 }) => getStoryCommentsPopular({ carId, storyId }, page),
    {
      cacheTime: 0,
      getNextPageParam(lastPage, allPages) {
        return lastPage.comments.length > 0 ? lastPage.current_page + 1 : undefined;
      },
      enabled: false,
    }
  );
};
