import { Theme } from "@mui/material";
import { ComponentType } from "react";
import { PublicCar } from "types";
import { useClasses } from "utils/hooks/useClasses";
import ImagePlaceholder from 'assets/image-placeholder.jpg';

const styles = ({spacing, palette, breakpoints}: Theme) => ({
  root: {
    borderRadius: 4,
    border: '1px solid rgba(255, 255, 255, 0.08)',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%), #121212',
    padding: spacing(1),
  },
  vehicleTitle: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: '0.15px',
  },
  vehicleDetails: {
    color: '#A8A6AB',
    fontSize: 12,
  },
  image: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    paddingTop: '56.25%',
    maxWidth: '100%',
    width: '100%',
    borderRadius: 4,
    marginTop: spacing(1),
  }
})

interface VehicleItemType {
  vehicle: PublicCar;
}

export const VehicleItem: ComponentType<VehicleItemType> = ({vehicle}) => {
  const classes = useClasses(styles);
  return (
    <div className={classes.root}>
      <div className={classes.vehicleTitle}>
        {vehicle.make} {vehicle.model}
      </div>
      <div className={classes.vehicleDetails}>
        Year {vehicle.year} • {vehicle.mileage} {vehicle.mileage_metric}
      </div>
    
        <div
          className={classes.image}
          style={{ backgroundImage: vehicle.image ? `url(${vehicle.image})` : `url(${ImagePlaceholder})` }}
        >
        </div>
    </div>
  )
}