import { Box, Theme } from '@mui/material';
import { CommonHeader, MobileNavigation, Wrapper } from 'components';
import { ComponentType } from 'react';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ breakpoints, spacing }: Theme) => ({
  root: {
    position: 'relative',
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%',
    },
  },
  innerWrapper: {
    margin: spacing(2, 0, 10, 0),

    [breakpoints.up('md')]: {
      margin: spacing(4, 0),
    },
  },
});

export const CommonPublicLayout: ComponentType<{ hideMobileNav?: boolean }> = ({ children, hideMobileNav }) => {
  const classes = useClasses(styles);

  return (
    <div className={classes.root}>
      <Box sx={{ display: { md: 'block', xs: 'none' } }}>
        <CommonHeader />
      </Box>
      <Wrapper>
        <div className={classes.innerWrapper}>{children}</div>
      </Wrapper>
      {!hideMobileNav && (
        <Box component={'div'} sx={{ display: { xs: 'block', md: 'none' } }}>
          <MobileNavigation
            startDemo={() => {
              //@ts-ignore
              gtag?.('event', 'demo_tour_clicked', { method: 'demo_tour_clicked' });
            }}
          />
        </Box>
      )}
    </div>
  );
};
