
import { ReportCommentPayload } from 'features/stories-feed/StoriesTypes';
import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

export const reportComment = async ({carId, storyId, commentId, body }: ReportCommentPayload) => {
  const { data } = await api.post(`/cars/${carId}/stories/${storyId}/comments/${commentId}/report`, body);
  return data;
}

export const useCommentReportMutation = () =>{
  return useMutation(reportComment);
}