import { ComponentType } from "react";
import { AdminLeftBar } from "./AdminLeftBar";
import {Theme} from '@mui/material';
import { useClasses } from "utils/hooks/useClasses";

const styles = ({palette, spacing}: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        minHeight: '100vh',
        
    },
    content: {
        display: 'flex',
        position: "relative",
        left: "342px",
        overflowY: "scroll",
        margin: '49px 80px 24px 24px',
        width: 'calc(100% - 342px - 80px - 24px)',
        maxWidth: 'calc(100vw - 342px - 80px - 24px)',
        flex: 1,
    }
})

export const AdminPageWrapper: ComponentType = ({children}) => {

    const classes = useClasses(styles);


    return (
        <div className={classes.root}>
            <AdminLeftBar/>
            <div className={classes.content}>
                {children}
            </div>
        </div>
    )
}