import { useMutation } from 'react-query';

import { axiosApiInstance as api, mapKeysToCamelCase } from 'utils';

export interface RegisterValues {
  username: string;
  email: string;
  password: string;
  language: string;
  captcha_response: string;
}

export interface RegisterResponse {
  message?: string;
  error?: string;
}

const register = async (body: RegisterValues): Promise<RegisterResponse> => {
  try {
    const { data } = await api.post(`/users/register`, {
      ...body,
    });

    return mapKeysToCamelCase(data);
  } catch (error: any) {
    return error.response.data.message;
  }
};

export const useRegisterMutation = () => {
  return useMutation(register);
};
