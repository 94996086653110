import { ComponentType, useState } from 'react';
import { IStoryRecord } from './AdminItemTypes';
import { Chip, Collapse, Divider, IconButton, Theme } from '@mui/material';
import { useClasses } from 'utils/hooks/useClasses';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ExpandMore } from '@mui/icons-material';
import clsx from 'clsx';
import store from 'store2';
import { RecordAttachmentGallery } from 'features/records/components';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    marginTop: 8,
  },
  record: {
    display: 'flex',
    flexDirection: 'column',
    background: 'rgba(255, 255, 255, 0.06)',
    borderRadius: 4,
    padding: spacing(2),
    margin: spacing(0.5, 0),
    border: '1px solid rgba(255, 255, 255, 0.06)',
  },
  rootExpanded: {
    background: 'rgba(187, 134, 252, 0.1)',
    border: '1px solid #BB86FC',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: palette.text.hint,
  },
  title: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '26px',
    letterSpacing: 0.15,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerMain: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dot: {
    margin: spacing(0, 0.5),
    width: 4,
    height: 4,
    borderRadius: 2,
    background: palette.additionalPrimary['200'],
  },
  cost: {
    fontSize: 34,
    marginRight: 16,
    fontWeight: 600,
  },
  chip: {
    order: 1,
    flexShrink: 1,
    maxWidth: 130,
    marginLeft: spacing(2),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: palette.additionalSecondary['800'],
    color: palette.text.primary,

    [breakpoints.up('md')]: {
      order: 'unset',
      maxWidth: 'unset',
      marginLeft: 'auto',
    },
    [breakpoints.down('xs')]: {
      maxWidth: 100,
    },
  },
  expandButtonOpen: {
    color: palette.primary.main,
    transform: 'rotate(180deg)',
  },
  hint: {
    fontSize: 12,
    color: palette.text.hint,
  },
  recordText: {
    fontSize: 16,
    marginBottom: 16,
  },
  divider: {
    background: palette.text.hint,
    marginTop: 20,
    marginBottom: 16,
  },
});

interface AdminStoryRecordsType {
  records: Array<IStoryRecord>;
  isGroup?: boolean;
  showTranslate: boolean;
}

export const AdminStoryRecords: ComponentType<AdminStoryRecordsType> = ({ records, isGroup, showTranslate }) => {
  const classes = useClasses(styles);
  const isShowHr = isGroup && records.length !== 0;
  return (
    <div className={classes.root}>
      {isShowHr && <hr />}
      {records.map((record) => {
        return <AdminStoryRecord key={record.id} record={record} showTranslate={showTranslate} />;
      })}
      {isShowHr && <hr />}
    </div>
  );
};

enum translateTitleProp {
  'en' = 'en_title',
  'es' = 'es_title',
  'uk' = 'uk_title',
}

enum translatePartNameProp {
  'en' = 'en_part_name',
  'es' = 'es_part_name',
  'uk' = 'uk_part_name',
}

enum translateDetailsProp {
  'en' = 'en_details',
  'es' = 'es_details',
  'uk' = 'uk_details',
}

interface AdminStoryRecordType {
  record: IStoryRecord;
  showTranslate: boolean;
}

export const AdminStoryRecord: ComponentType<AdminStoryRecordType> = ({ record, showTranslate }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const storedLanguage: 'en' | 'es' | 'uk' = store('driverbook_language') ?? 'en';

  return (
    <div
      className={clsx(classes.record, {
        [classes.rootExpanded]: expanded,
      })}
    >
      <div className={classes.header}>
        {dayjs(record.date_created).format('DD-MM-YYYY')}
        <div className={classes.dot}></div>
        {record.mileage}
      </div>
      <div className={classes.title}>{showTranslate ? record[translateTitleProp[storedLanguage]] : record.title}</div>
      <div className={classes.footer}>
        <div className={classes.footerMain}>
          <div className={classes.cost}>{record.cost}</div>
          <Chip label={t(`__record_category__${record.category.toLowerCase()}`)} className={classes.chip} />
        </div>
        <div className='footerExpand'>
          <IconButton
            color='inherit'
            onClick={() => {
              setExpanded(!expanded);
            }}
            size='large'
            className={clsx(classes.expandButton, {
              [classes.expandButtonOpen]: expanded,
            })}
          >
            <ExpandMore />
          </IconButton>
        </div>
      </div>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <Divider className={classes.divider} />
        <div className={classes.hint}>{t('record_item_item_name')}</div>
        <div className={classes.recordText}>
          {showTranslate ? record[translatePartNameProp[storedLanguage]] : record.part_name}
        </div>
        <div className={classes.hint}>{t('record_form_details')}</div>
        <div className={classes.recordText}>
          {showTranslate ? record[translateDetailsProp[storedLanguage]] : record.details}
        </div>
        <RecordAttachmentGallery
          images={[record?.image_one || null, record?.image_two || null, record?.image_three || null]}
        />
      </Collapse>
    </div>
  );
};
