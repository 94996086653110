import { useMutation } from 'react-query';

import { Record } from 'types';
import { axiosWithFileInstance } from 'utils';

interface CreateRecordPayload {
  carId: string;
  formData: any;
}

const createRecord = async ({ carId, formData }: CreateRecordPayload): Promise<Record> => {
  const { data } = await axiosWithFileInstance.post(`/cars/${carId}/records`, formData);
  return data;
};

export const useCreateRecordMutation = () => {
  return useMutation(createRecord);
};
