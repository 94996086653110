import { Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { IWarn } from '../AdminDashboardTypes';
import { AdminUserBansWarns } from '../AdminUserBansWarns';
import { IUserItem } from './AdminItemTypes';

const tKey = getTKey('admin_user_warn_ban_info');

const styles = ({ palette, spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(3, 3, 0),
  },
  title: {
    fontSize: 20,
    padding: spacing(0, 0, 1),
  },
});

interface AdminUserWarnBanInfoType {
  user: IUserItem;
  warns: IWarn[];
  showTitle: boolean;
}

export const AdminUserWarnBanInfo: ComponentType<AdminUserWarnBanInfoType> = ({ user, warns, showTitle }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      {showTitle && <div className={classes.title}>{t(tKey('title'))}</div>}
      <AdminUserBansWarns user={user.id} />
    </div>
  );
};
