import { Grid } from '@mui/material';
import { ComponentType, useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { SettingsLayout, SettingsMenuDesktop } from 'components';
import { useProfileInfoQuery } from 'services';
import { setProfile } from 'store/profile/slice';
import { ProfileContext as Context } from 'store/profile/store';

import { DemoTour } from 'components/DemoTour/DemoTour';

export const SettingsPage: ComponentType = () => {
  const { dispatch, state } = useContext(Context);
  const { data } = useProfileInfoQuery();
  const [demo, setDemo] = useState(false);

  useEffect(() => {
    if (data) {
      dispatch(setProfile(data));
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SettingsLayout>
      <Grid container spacing={8}>
        <Grid item md={4} sx={{ display: { md: 'block', xs: 'none' } }}>
          <SettingsMenuDesktop
            startDemo={() => {
              //@ts-ignore
              gtag?.('event', 'demo_tour_clicked', { method: 'demo_tour_clicked' });
              setDemo(true);
            }}
          />
        </Grid>
        {state.id && (
          <Grid item xs={12} md={8}>
            <Outlet/>
          </Grid>
        )}
      </Grid>
      {demo && <DemoTour handleCloseModal={() => setDemo(false)} />}
    </SettingsLayout>
  );
};
