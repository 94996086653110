import { ComponentType, useEffect, useState } from 'react';
import { useClasses } from 'utils/hooks/useClasses';
import { AdminPageWrapper } from './components/AdminPageWrapper';
import { FilterUserBar } from './components/FilterUserBar';
import { PaginationBar } from './components/PaginationBar';
import { EmptyData } from './components/EmptyData';
import { useTranslation } from 'react-i18next';
import { ContentLoader } from 'components/ContentLoader/ContentLoader';
import { filterUserStatus } from './components/AdminDashboardTypes';
import { UserList } from './components/UserList';
import { filtersType, paginationType, useAdminUsers } from './hooks/useAdminUserData';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: '100%',
  },
});

export const AdminUsersPage: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);

  const [filters, setFilters] = useState<filtersType>({
    status: 'active',
    trustedOnly: false,
  });
  const [pagination, setPagination] = useState<paginationType>({
    page: 1,
    perPage: 20,
  });

  const { users, isFetching, data, refetch } = useAdminUsers(filters, pagination);

  const onChangeStatus = (status: filterUserStatus) => {
    status === 'blocked'
      ? setFilters({ ...filters, ...{ status, trustedOnly: false } })
      : setFilters({ ...filters, ...{ status } });
  };

  useEffect(() => {
    refetch();
  }, [pagination, filters.status, filters.trustedOnly, filters.usernameOrId, refetch]);

  return (
    <AdminPageWrapper>
      <div className={classes.root}>
        <FilterUserBar
          filters={filters}
          onChangeStatus={onChangeStatus}
          onChangeSearch={(usernameOrId) => {
            setFilters({ ...filters, ...{ usernameOrId } });
            setPagination({ ...pagination, ...{ page: 1 } });
          }}
          onChangeTrust={(trustedOnly) => {
            setFilters({ ...filters, ...{ trustedOnly } });
          }}
        />
        {isFetching ? (
          <ContentLoader />
        ) : users?.length > 0 ? (
          <UserList users={users} statusBanned={filters.status === 'active'} refreshUsers={refetch} />
        ) : (
          <EmptyData title={t('admin_users_empty_title')} />
        )}
        <PaginationBar
          changeCurrentPage={(page) => {
            setPagination({ page: page, perPage: pagination.perPage });
          }}
          changePerPage={(perPage) => {
            setPagination({ page: pagination.page, perPage: perPage });
          }}
          currentPage={pagination.page}
          pages={data?.pages}
          total={data?.total}
        />
      </div>
    </AdminPageWrapper>
  );
};
