import { StoryPayload } from 'features/stories-feed/StoriesTypes';
import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

const removeLikeStory = async ({ carId, storyId }: StoryPayload): Promise<any> => {
  const { data } = await api.delete(`/cars/${carId}/stories/${storyId}/like`);
  return data;
};

export const useRemoveLikeStoryMutation = () => {
  return useMutation(removeLikeStory);
};
