import { IStory } from 'features/stories-feed/StoriesTypes';
import { useInfiniteQuery } from 'react-query';
import { axiosApiInstance as api, mapKeysToSnakeCase } from 'utils';

export type storyPeriod = 'd' | 'm' | 'w' | 'recent' | 'news';

export interface storyPagination {
  period?: storyPeriod;
  language?: string;
  page: number;
  following?: boolean;
}

export type storiesFeedResponse = {
  current_page: number;
  per_page: number;
  stories: IStory[];
};

const cacheTime = 1000 * 60 * 3;

export const getStories = async (page: number, pagination: storyPagination): Promise<storiesFeedResponse> => {
  const path = pagination.following ? `/stories/following` : '/stories';
  pagination = pagination.following ? { page } : { ...pagination, page };
  delete pagination.following;
  const { data } = await api.get(path, { params: mapKeysToSnakeCase(pagination) });
  return data;
};

export const useStoriesFeedQuery = (pagination: storyPagination) => {
  return useInfiniteQuery(
    ['getStories', pagination.period, pagination.following],
    ({ pageParam: page = 1 }) => getStories(page, pagination),
    {
      cacheTime: cacheTime,
      getNextPageParam(lastPage, allPages) {
        return lastPage.stories.length > 0 ? lastPage.current_page + 1 : undefined;
      },
    }
  );
};
