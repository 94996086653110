import { InfiniteData } from 'react-query';
import { PublicUserFollowers, usePublicUserFollowers, usePublicUserProfile, usePublicUserVehicles } from 'services';
import { storiesFeedResponse } from 'services/stories';
import { useUserStoriesQuery } from 'services/stories/hooks/useUsersStoriesQuery';
import { PublicCar, UserProfile } from 'types';

type useUserDataType = (userId: string) => {
  profile: UserProfile | undefined;
  followers: PublicUserFollowers | undefined;
  vehicles: PublicCar[] | undefined;
  isLoadingProfile: boolean;
  isLoadingFollowers: boolean;
  isLoadingVehicles: boolean;
  stories: InfiniteData<storiesFeedResponse> | undefined;
  isLoadingStories: boolean;
  isFetchingNextPageStories: boolean;
  hasNextPageStories: boolean | undefined;
  fetchNextPageStories: () => void;
  refetchFollowers: () => void;
  errorProfile: any;
  errorFollowers: any;
  errorVehicles: any;
};

export const useUserData: useUserDataType = (userId) => {
  const { data: profile, isLoading: isLoadingProfile, error: errorProfile } = usePublicUserProfile(userId);

  const {
    data: followers,
    isLoading: isLoadingFollowers,
    refetch: refetchFollowers,
    error: errorFollowers,
  } = usePublicUserFollowers(userId);

  const { data: vehicles, isLoading: isLoadingVehicles, error: errorVehicles } = usePublicUserVehicles(userId);

  const {
    data: stories,
    isLoading: isLoadingStories,
    isFetchingNextPage: isFetchingNextPageStories,
    hasNextPage: hasNextPageStories,
    fetchNextPage: fetchNextPageStories,
  } = useUserStoriesQuery(userId);

  return {
    profile,
    isLoadingProfile,
    followers,
    isLoadingFollowers,
    vehicles,
    isLoadingVehicles,
    stories,
    isLoadingStories,
    isFetchingNextPageStories,
    hasNextPageStories,
    fetchNextPageStories,
    refetchFollowers,
    errorProfile,
    errorFollowers,
    errorVehicles,
  };
};
