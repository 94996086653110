import { useMutation } from 'react-query';
import { axiosApiInstance as api, mapKeysToSnakeCase } from 'utils';
const _ = require('lodash');

export interface unblockUserPayload {
  id: string;
  isStoryBanned?: boolean;
  isCommentBanned?: boolean;
  isProfileBanned?: boolean;
  isCarBanned?: boolean;
}

export const unblockUser = async (bodyPayload: unblockUserPayload): Promise<any> => {
  try {
    let body = _.omitBy(bodyPayload, _.truthy);
    delete body.id;
    const { data } = await api.patch(`/engineroom/users/${bodyPayload.id}/ban`, mapKeysToSnakeCase(body));
    return data;
  } catch (err) {
    return { error: true };
  }
};

export const useUnblockUserMutation = () => {
  return useMutation(unblockUser);
};
