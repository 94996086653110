import _ from 'lodash';
import { useMutation } from 'react-query';
import { axiosApiInstance as api, mapKeysToSnakeCase } from 'utils';

export type wrapBanUserPayload = {
  id: string;
  body: banUserPayload;
};

export type banUserPayload = {
  note?: string;
  isStoryBanned?: boolean;
  storyBannedUntil?: string;
  isCommentBanned?: boolean;
  commentBannedUntil?: string;
  isProfileBanned?: boolean;
  profileBannedUntil?: string;
  isCarBanned?: boolean;
  carBannedUntil?: string;
  contentId?: string;
  contentType?: string;
};

const banUser = async (payload: wrapBanUserPayload): Promise<any> => {
  try {
    const { data } = await api.patch(
      `/engineroom/users/${payload.id}/ban`,
      mapKeysToSnakeCase(_.omitBy(payload.body, (v) => v == null))
    );
    return data;
  } catch {
    return { error: true };
  }
};

export const useBanUserMutation = () => {
  return useMutation(banUser);
};
