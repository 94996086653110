import { Theme, Typography } from '@mui/material';
import { InnerMobileHeader, LeanLayout, Wrapper } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  email: { color: palette.additionalPrimary[200] },
  container: {
    backgroundColor: 'rgba(42, 42, 42)',
    padding: spacing(3),
    borderRadius: 10,
  },
});

const tKey = getTKey('cookie_policy');
export const CookiePolicy = () => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  return (
    <LeanLayout>
      <InnerMobileHeader title=' ' showBack />
      <Wrapper>
        <div className={classes.container}>
          <Typography variant='h5' align='center'>
            {t(tKey('heading'))}
          </Typography>
          <br />
          <Typography variant='body1'>{t(tKey('effective_day'))}</Typography>
          <Typography variant='body1'>
            {t(tKey('heading_description_1'))}{' '}
            <a className={classes.email} href='https://driverbook.io'>
              https://driverbook.io
            </a>
            {t(tKey('heading_description_2'))}
          </Typography>
          <br />
          <Typography variant='h6'>{t(tKey('cookies'))}</Typography>
          <Typography variant='body1'>{t(tKey('cookies_1'))}</Typography>
          <br />
          <Typography variant='h6'>{t(tKey('types_cookies'))}</Typography>
          <Typography variant='body1'>{t(tKey('types_cookies_1'))}</Typography>
          <ul>
            <li>
              <Typography variant='body1'>{t(tKey('types_cookies_2'))}</Typography>
            </li>
            <li>
              <Typography variant='body1'>{t(tKey('types_cookies_3'))}</Typography>
            </li>
          </ul>
          <br />
          <Typography variant='h6'>{t(tKey('cookies_duration'))}</Typography>
          <Typography variant='body1'>{t(tKey('cookies_duration_1'))}</Typography>
          <br />
          <Typography variant='h6'>{t(tKey('consent'))}</Typography>
          <Typography variant='body1'>{t(tKey('consent_1'))}</Typography>
          <br />
          <Typography variant='h6'>{t(tKey('cookie_preferences'))}</Typography>
          <Typography variant='body1'>{t(tKey('cookie_preferences_1'))}</Typography>
          <br />
          <Typography variant='h6'>{t(tKey('third_party_cookies'))}</Typography>
          <Typography variant='body1'>{t(tKey('third_party_cookies_1'))}</Typography>
          <ul>
            <li>
              <Typography variant='body1'>{t(tKey('third_party_cookies_2'))}</Typography>
            </li>
            <li>
              <Typography variant='body1'>{t(tKey('third_party_cookies_3'))}</Typography>
            </li>
          </ul>
          <br />
          <Typography variant='h6'>{t(tKey('responsibility'))}</Typography>
          <Typography variant='body1'>{t(tKey('responsibility_1'))}</Typography>
          <br />
          <Typography variant='h6'>{t(tKey('location'))}</Typography>
          <Typography variant='body1'>
            {t(tKey('location_1'))}{' '}
            <Link to='cookie-policy' className={classes.email}>
              {process.env.REACT_APP_PUBLIC_API_URL?.replace('/api/', '')}/cookie-policy
            </Link>
          </Typography>
          <br />
          <Typography variant='h6'>{t(tKey('contact'))}</Typography>
          <Typography variant='body1'>
            {t(tKey('contact_1'))}{' '}
            <a className={classes.email} href='mailto:support@driverbook.io'>
              support@driverbook.io
            </a>
          </Typography>
        </div>
      </Wrapper>
    </LeanLayout>
  );
};
