import { ComponentType, useEffect, useState } from 'react';
import { IconButton, TextField, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useClasses } from 'utils/hooks/useClasses';
import { useNavigate } from 'react-router-dom';
import { ConfirmationDialog, StyledButton } from 'components';
import { Close } from '@mui/icons-material';
import sky from '../../../assets/sky.jpg';
import { useSendNewVersionMutation } from 'services/admin/hooks/useNewVersionMutation';
import { toast } from 'react-toastify';

const styles = ({ spacing }: Theme) => ({
  root: {
    margin: spacing(5, 10),
    width: `calc(100vw - ${spacing(20)})`,
    height: `calc(100vh - ${spacing(10)})`,
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${sky})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'noRepeat',
  },
  appBar: {
    height: 56,
    display: 'flex',
    flexDirection: 'row',
    itemAlign: 'center',
    justifyContent: 'space-between',
  },
});

export const AdminNewVersionPage: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const [version, setVersion] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const mutation = useSendNewVersionMutation();

  const handleChangeVersion = (event: any) => {
    setVersion(event.target.value);
  };

  useEffect(() => {
    if (version && disabled) {
      setDisabled(false);
    }
    if (!version && !disabled) {
      setDisabled(true);
    }
  }, [version, disabled]);

  const handleConfirmSendNewVersion = () => {
    return new Promise((resolve) => {
      mutation.mutate(
        { version: version },
        {
          onSuccess(data) {
            setOpenDialog(false);
            toast(t('confirm_dialog_new_version_toast'));
          },
        }
      );
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.appBar}>
        <IconButton color='inherit' onClick={() => navigate('/secret/machineroom/stories')} size='large'>
          <Close />
        </IconButton>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            backgroundColor: 'black',
            borderRadius: '15px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
          }}
        >
          <TextField
            onChange={handleChangeVersion}
            value={version}
            label={t('header_version')}
            variant='outlined'
            autoComplete='off'
            style={{ width: '300px', paddingBottom: '20px' }} // Adjust width and padding
          />
          <StyledButton
            label={t('__common__send')}
            color='primary'
            onClick={() => setOpenDialog(true)}
            disabled={disabled}
          />
        </div>
      </div>
      <ConfirmationDialog
        title={t('confirm_dialog_new_version_title')}
        description={t('confirm_dialog_new_version_body')}
        open={openDialog}
        onConfirm={handleConfirmSendNewVersion}
        onClose={() => setOpenDialog(false)}
        confirmText={t('__common__confirm')}
      />
    </div>
  );
};
