import { Box, Button, Chip, Theme, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { InnerMobileHeader, PageLoader, PublicRecordsLayout } from 'components';
import { usePublicCarCurrencyQuery, usePublicCarVolumeQuery } from 'services';
import { countTotalMoneySpend, normalizeChipName } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { RecordItem, RecordsCarItem } from '../records/components';
import { usePublicDashboardDetails } from './usePublicDashboardDetails';

const styles = ({ spacing }: Theme) => ({
  wrapper: {
    maxWidth: 800,
    margin: '0 auto',
    marginTop: spacing(2),
    marginBottom: spacing(10),
  },
  header: {
    display: 'flex',
    marginRight: spacing(-2),
    paddingRight: spacing(2),
    whiteSpace: 'nowrap',
  },
  chips: {
    overflowX: 'auto',
    marginLeft: spacing(2),

    '& > *:not(:last-child)': {
      marginRight: spacing(1),
    },
  },
  back: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: spacing(2, 0),
  },
});

export const PublicDashboardDetailsPage: ComponentType = () => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { car, carLoading, records, chips, month } = usePublicDashboardDetails();
  const loading = carLoading;
  const totalExpenses = countTotalMoneySpend(records);
  const mileageMetric = car?.mileageMetric;
  const { identifier } = useParams<{ identifier: string }>() as { identifier: string };
  const { data: currency, isLoading: currencyLoading } = usePublicCarCurrencyQuery(identifier);
  const { data: volume, isLoading: volumeLoading } = usePublicCarVolumeQuery(identifier);

  return (
    <PublicRecordsLayout>
      {loading ? (
        <PageLoader />
      ) : (
        <div className={classes.wrapper}>
          <Box component={'div'} sx={{ display: { md: 'flex', xs: 'none' } }} className={classes.back}>
            <Typography variant='h6'>
              {t('breadcrumbs_records')} {t(`__month__${dayjs().set('month', month).format('MMMM')}`)}
            </Typography>
            <Button color='primary' onClick={() => navigate(-1)}>
              {t('dashboard_back_to')}
            </Button>
          </Box>
          {car && (
            <>
              <Box component={'div'} sx={{ display: { md: 'block', xs: 'none' } }} mb={3}>
                <RecordsCarItem car={car} isSingle chips={chips} />
              </Box>

              <Box component={'div'} sx={{ display: { xs: 'block', md: 'none' } }}>
                <div className={classes.header}>
                  <InnerMobileHeader title={`${capitalize(car.make)}`} />
                  <div className={classes.chips}>
                    {chips.map((chip: string) => (
                      <Chip key={chip} label={normalizeChipName(chip)} />
                    ))}
                  </div>
                </div>
              </Box>
            </>
          )}
          {!currencyLoading &&
            !volumeLoading &&
            records?.map((record) => (
              <RecordItem
                key={record.id}
                data={record}
                mileageMetric={mileageMetric}
                totalExpenses={totalExpenses}
                publicRecord
                volume={volume}
                currency={currency}
              />
            ))}
        </div>
      )}
    </PublicRecordsLayout>
  );
};
