import { Story } from 'components/Stories/AddStoryContent';
import { STORY_ELEMENT_TYPE } from './constants';
import { transformToSnakeCase } from './formatters';

export const transformStory = (stories: Story[], title: string, isMileageHidden: boolean, mileage: number) => {
  return {
    is_mileage_hidden: isMileageHidden,
    title,
    car_mileage: mileage,
    elements: [...stories].map((story, index) => {
      if (story.elementType === STORY_ELEMENT_TYPE.GALLERY) {
        story.images = story.images?.map((image) => ({
          url: image.url,
          isCover: image.isCover,
          imageId: image.imageId,
        }));
      }
      return transformToSnakeCase({ position: index + 1, ...story });
    }),
  };
};
