import { useMutation } from 'react-query';

import { axiosApiInstance as api } from 'utils';

const unblockUser = async (id: string): Promise<any> => {
  try {
    const { data } = await api.delete(`/users/${id}/unblock`);
    return data;
  } catch (error) {
    return { error: true };
  }
};

export const useUnblockUserMutation = () => {
  return useMutation(unblockUser);
};
