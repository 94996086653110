import { useMutation } from 'react-query';

import { Currency } from 'types';
import { axiosApiInstance as api, mapKeysToSnakeCase } from 'utils';

export interface UpdateCurrencyPayload {
  fromCurrency: Currency;
  toCurrency: Currency;
  rate?: number;
}

const updateCurrency = async (payload: UpdateCurrencyPayload): Promise<any> => {
  const { data } = await api.post('/settings/currency', mapKeysToSnakeCase(payload));
  return data;
};

export const useUpdateCurrencyMutation = () => {
  return useMutation(updateCurrency);
};
