import { InnerMobileHeader } from 'components';
import { PasswordForm } from 'components/v2/Settings';
import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const PasswordTab: ComponentType = () => {
  const { t } = useTranslation();

  useEffect(() => {
    //@ts-ignore
    gtag?.('event', 'visited_settings', { method: 'visited_settings' });
  }, []);

  return (
    <>
      <InnerMobileHeader title={t('settings_password_title')} showBack />
      <PasswordForm />
    </>
  );
};
