import React, { ComponentType } from 'react';

import { PageLoader } from 'components';
import { Record, Volume } from 'types';
import { AssistedDraftItem } from '../../assisted-records/components';

interface DraftsListProps {
  loading: boolean;
  data: Record[];
  carId: string;
  currency: string;
  handleDraftDelete: (id: string) => void;
  volume: Volume;
}

export const DraftsList: ComponentType<DraftsListProps> = ({
  loading,
  data,
  carId,
  currency,
  handleDraftDelete,
  volume,
}) => {
  return (
    <>
      {loading ? (
        <PageLoader />
      ) : !loading && data && data.length ? (
        data.map((draftItem: Record) => {
          return (
            <AssistedDraftItem
              key={draftItem.id}
              data={draftItem}
              carId={carId}
              currency={currency}
              volume={volume}
              onDraftDelete={handleDraftDelete}
            />
          );
        })
      ) : null}
    </>
  );
};
