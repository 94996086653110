import { ComponentType, createRef, LegacyRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { AssistedLayout } from 'components';

import { AssistedRecordForm } from './components/AssistedRecordForm';
import { useEditAssistedRecordPage } from './hooks/useEditAssistedRecordPage';

export const EditAssistedRecordPage: ComponentType = () => {
  const { recordId } = useParams<{ recordId: string }>();
  const { currentDraft, onSubmit, isSubmitting } = useEditAssistedRecordPage();
  const draft = currentDraft && currentDraft.id === recordId;
  const myRef = createRef<LegacyRef<HTMLDivElement>>();

  useEffect(() => {
    //    @ts-ignore
    myRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [recordId, myRef]);

  return (
    <AssistedLayout>
      {/* @ts-ignore */}
      <div ref={myRef}>
        <AssistedRecordForm
          onSubmit={onSubmit}
          defaultValues={draft ? currentDraft : undefined}
          editMode
          isSubmitting={isSubmitting}
        />
      </div>
    </AssistedLayout>
  );
};
