import { AddPhotoAlternate, Description, TextFields, YouTube } from '@mui/icons-material';
import { Box, Grid, Input, Theme, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCarQuery } from 'services';
import { Record } from 'types';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { ImageUploadModal } from './ImageUploadModal';
import { RecordMultiSelect } from './RecordsMultiSelect';
//@ts-ignore
import {
  DndContext,
  DragOverlay,
  DropAnimation,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  defaultDropAnimationSideEffects,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import './AddStoryContent.css';
import { AddStoryContentItem } from './AddStoryContentItem';
import { VideoSelectDialog } from './VideoSelectDialog';

const styles: any = ({ palette, spacing, breakpoints }: Theme) => ({
  title: {
    width: '100%',
    fontSize: 24,
    fontWeight: '600',
  },
  menuContainer: {
    width: '100%',
    marginTop: spacing(5),
  },
  menu: {
    maxWidth: 500,
    height: 40,
    backgroundColor: 'rgba(32, 32, 32, 1)',
    margin: 'auto',
    borderRadius: 56,
    borderWidth: 1,
    borderColor: 'rgba(47, 47, 47, 1)',
    borderStyle: 'solid',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing(0, 1),
    cursor: 'pointer',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  menuItemBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: 'rgba(47, 47, 47, 1)',
    borderStyle: 'solid',
  },
  menuItemIcon: {
    height: 16,
  },
  menuItemLabel: {
    fontSize: 16,
  },
  overlay: {
    // width: `calc(100% - ${spacing(4)}) !important`,
    width: '50% !important',
    margin: 'auto',
    [breakpoints.down('sm')]: {
      width: `calc(100% - ${spacing(4)}) !important`,
    },
    [breakpoints.up('sm')]: {
      minWidth: 532,
    },
    maxWidth: 800,
    height: 200,
  },
});

const tKey = getTKey('stories_add_content');

export type Story = {
  storyId?: string;
  id: string;
  elementType: string;
  texts?: { originalText: string }[];
  images?: {
    imageId: string;
    elementId?: any;
    isDraft?: boolean;
    url: string;
    isCover?: boolean;
    dataUrl?: string;
    file?: any;
    filename?: string;
  }[];
  records?: { recordId: string }[];
  assistedRecords?: { groupId: string }[];
  position?: any;
  youtubeVideoUrl?: string;
  youtubeVideoCoverUrl?: string;
};
export const AddStoryContent = ({
  carId,
  elements,
  records,
  recordGroup,
  title,
  descLength,
  removeElement,
  setElements,
  addGallery,
  addDescription,
  addRecord,
  addRecordGroup,
  addText,
  setTitle,
  updateDraft,
  addVideo,
}: {
  carId: string | undefined;
  elements: Story[];
  records: { [id: string]: Record };
  recordGroup: { [id: string]: Record[] };
  title: string;
  descLength: number;
  removeElement: (index: number) => void;
  setElements: React.Dispatch<React.SetStateAction<Story[]>>;
  addGallery: (images: any[]) => void;
  addText: () => void;
  addRecord: (record: Record[]) => void;
  setTitle: (title: string) => void;
  addRecordGroup: (record: Record[][]) => void;
  updateDraft: (newArray?: any[]) => void;
  addDescription: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => void;
  addVideo: (link: string) => void;
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const [open, setOpen] = useState(false);
  const [openRecordSelect, setOpenRecordSelect] = useState(false);
  const [openVideoSelect, setOpenVideoSelect] = useState(false);
  const { data: carData } = useCarQuery(carId ?? '');

  const [focused, setFocused] = useState(-1);

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);
  const [activeId, setActiveId] = useState(null);
  function handleDragStart(event: any) {
    setActiveId(event.active.id);
  }

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 250) {
      setTitle(event.target.value);
    }
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id === over.id) {
      return;
    }
    const activeIndex = elements.findIndex((element) => element.id === active.id);
    const overIndex = elements.findIndex((element) => element.id === over.id);

    const updatedItems = [...elements];
    updatedItems.splice(activeIndex, 1);
    updatedItems.splice(overIndex, 0, elements[activeIndex]);

    updateDraft(updatedItems);
    setElements(updatedItems);
    setActiveId(null);
  };

  const dropAnimationConfig: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
      styles: {
        active: {
          opacity: '0.4',
        },
      },
    }),
  };

  const onConfirmVideo = async (link: string) => {
    await addVideo(link);
    setOpenVideoSelect(false);
  };

  return (
    <div className='todo-container'>
      {open && (
        <ImageUploadModal
          position={elements.length}
          carId={carId ?? ''}
          onClose={() => {
            setOpen(false);
          }}
          open={open}
          sendImages={addGallery}
        />
      )}
      {openRecordSelect && (
        <RecordMultiSelect
          open={openRecordSelect}
          addRecord={addRecord}
          addRecordGroup={addRecordGroup}
          carId={carId ?? ''}
          onClose={() => setOpenRecordSelect(false)}
        />
      )}
      {openVideoSelect && (
        <VideoSelectDialog open={openVideoSelect} cancel={() => setOpenVideoSelect(false)} confirm={onConfirmVideo} />
      )}
      <Input
        value={title}
        onChange={handleChangeTitle}
        placeholder={t(tKey('title'))}
        className={classes.title}
        disableUnderline={true}
        multiline
        // onBlur={() => updateDraft()}
        maxRows={2}
      />
      <div className='display-todo-container'>
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          sensors={sensors}
        >
          <SortableContext
            items={elements.map((element) => ({ id: element.id }))}
            strategy={verticalListSortingStrategy}
          >
            {elements.map((element, index) => {
              return (
                <React.Fragment key={element.id}>
                  <AddStoryContentItem
                    element={element}
                    addDescription={addDescription}
                    addGallery={addGallery}
                    addRecord={addRecord}
                    addRecordGroup={addRecordGroup}
                    addText={addText}
                    carData={carData}
                    descLength={descLength}
                    focused={focused}
                    index={index}
                    recordGroup={recordGroup}
                    records={records}
                    removeElement={removeElement}
                    setElements={setElements}
                    setFocused={setFocused}
                    setTitle={setTitle}
                    updateDraft={updateDraft}
                  />
                </React.Fragment>
              );
            })}
          </SortableContext>

          <DragOverlay dropAnimation={dropAnimationConfig} className={classes.overlay}>
            {activeId && elements.find((el) => activeId === el.id) !== undefined ? (
              <AddStoryContentItem
                element={elements.find((el) => activeId === el.id) as Story}
                addDescription={addDescription}
                addGallery={addGallery}
                addRecord={addRecord}
                addRecordGroup={addRecordGroup}
                addText={addText}
                carData={carData}
                descLength={descLength}
                focused={focused}
                index={0}
                recordGroup={recordGroup}
                records={records}
                removeElement={removeElement}
                setElements={setElements}
                setFocused={setFocused}
                setTitle={setTitle}
                updateDraft={updateDraft}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
        <Box className={classes.menuContainer}>
          <Grid container className={classes.menu}>
            <Grid xs={3} item className={clsx(classes.menuItem, classes.menuItemBorder)} onClick={() => addText()}>
              <TextFields className={classes.menuItemIcon} />
              <Typography className={classes.menuItemLabel}>{t(tKey('text_field'))}</Typography>
            </Grid>
            <Grid xs={3} item className={clsx(classes.menuItem, classes.menuItemBorder)} onClick={() => setOpen(true)}>
              <AddPhotoAlternate className={classes.menuItemIcon} />
              <Typography className={classes.menuItemLabel}>{t(tKey('image'))}</Typography>
            </Grid>
            <Grid
              xs={3}
              item
              className={clsx(classes.menuItem, classes.menuItemBorder)}
              onClick={() => setOpenRecordSelect(true)}
            >
              <Description className={classes.menuItemIcon} />
              <Typography className={classes.menuItemLabel}>{t(tKey('record'))}</Typography>
            </Grid>
            <Grid xs={3} item className={classes.menuItem} onClick={() => setOpenVideoSelect(true)}>
              <YouTube className={classes.menuItemIcon} />
              <Typography className={classes.menuItemLabel}>{t(tKey('video'))}</Typography>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};
