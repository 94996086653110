const createAnimation = (alertName: string, frames: string, suffix: string): string => {
  const animationName = `react-pulse-${alertName}-${suffix}`;

  if (typeof window === 'undefined' || !window.document) {
    return animationName;
  }

  const styleEl = document.createElement('style');
  document.head.appendChild(styleEl);
  const styleSheet = styleEl.sheet;

  const keyFrames = `
    @keyframes ${animationName} {
      ${frames}
    }
  `;

  if (styleSheet) {
    styleSheet.insertRule(keyFrames, 0);
  }

  return animationName;
};
export const pulse = [
  createAnimation(
    'pulseAnimation',
    '0% {transform: scale(0.7);opacity: 1;}50% {transform: scale(1.5);opacity: 0.5;}100% {transform: scale(0.7);opacity: 1;}',
    'push'
  ),
];
