import { ComponentType } from 'react';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useClasses } from 'utils/hooks/useClasses';
import { adminMenuItemLogout, adminMenuItems } from './AdminMenuItems';
import { NavLink, useNavigate } from 'react-router-dom';
import { StyledButton } from 'components';
import { PlayCircle, ExitToApp } from '@mui/icons-material';

const styles = ({ palette, spacing }: Theme) => ({
  root: {
    display: 'flex',
    position: 'fixed',
    height: '100vh',
    width: 342,
    background: '#BB86FC1F',
    padding: spacing(10),
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  menuItem: {
    width: '182px',
  },
  logoImage: {
    width: 24,
    height: 24,
    marginRight: spacing(2.5),
  },
  logoBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: palette.additionalPrimary[400],
    color: palette.text.hint,
    marginBottom: spacing(10),
  },
  itemLink: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: spacing(1.5),
    color: palette.text.primary,
    fontSize: 16,
    textDecoration: 'none',

    '& .MuiSvgIcon-root': {
      marginRight: spacing(4),
      color: palette.text.hint,
    },
  },
  activeLink: {
    color: palette.primary.main,
    background: palette.primary.main + '14',
    borderRadius: spacing(0.5),
  },
  buttonReview: {
    width: 170,
    marginBottom: spacing(5),
  },
});

const LinkLabel: ComponentType<{ label: string }> = ({ label }) => {
  const { t } = useTranslation();

  return <div>{t(label)}</div>;
};

export const AdminLeftBar: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const links = adminMenuItems;
  const navigate = useNavigate();
  const getNavLinkClass = ({ isActive }: { isActive: boolean }) => {
    return isActive ? `${classes.itemLink} ${classes.activeLink}` : classes.itemLink;
  };
  return (
    <div className={classes.root}>
      <div className={classes.menuItem}>
        <div className={classes.logoBlock}>
          <img src='/favicon-32x32.png' className={classes.logoImage} alt='logo' />
          {t('admin_menu_admin_panel_logo_title')}
        </div>

        <StyledButton
          className={classes.buttonReview}
          label={t('admin_menu_button_review')}
          LeftMuiIcon={PlayCircle}
          onClick={() => navigate('/secret/machineroom/review')}
        />

        {links.map(({ to, label, icon: Icon }) => {
          return (
            <NavLink key={to} to={to} className={getNavLinkClass}>
              <Icon />
              <LinkLabel label={label} />
            </NavLink>
          );
        })}
      </div>
      <div className={classes.menuItem}>
        <NavLink key={adminMenuItemLogout.to} to={adminMenuItemLogout.to} className={getNavLinkClass}>
          <ExitToApp />
          <LinkLabel label={adminMenuItemLogout.label} />
        </NavLink>
      </div>
    </div>
  );
};
