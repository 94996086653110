import { useApproveCommentMutation, useHideCommentMutation } from "services/admin";

type useAdminCommentModerateType = () => {
    approveComment: (id: string) => Promise<any>,
    hideComment: (id: string) => Promise<any>
}

export const useAdminCommentModerate: useAdminCommentModerateType =  () => {
    const mutationApproveComment = useApproveCommentMutation();
    const mutationHideComment = useHideCommentMutation();

    const approveComment = (id: string) => {
        return new Promise((resolve)=>{
            mutationApproveComment.mutate(id, {
                onSuccess(data){
                    resolve(data);
                }
            });
        });
    }

    const hideComment = (id: string) => {
        return new Promise((resolve)=>{
            mutationHideComment.mutate(id, {
                onSuccess(data){
                    resolve(data);
                }
            });
        });
    }

    return {
        approveComment,
        hideComment
    }
}