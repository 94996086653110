import { IUser } from 'features/stories-feed/StoriesTypes';
import { useQuery, UseQueryResult } from 'react-query';

import { axiosApiInstance as api } from 'utils';

const getBlockedUsers = async (): Promise<IUser[] | { error?: boolean }> => {
  try {
    const { data } = await api.get(`users/blocked`);
    return data;
  } catch (err) {
    return { error: true };
  }
};

export const useBlockedUsersQuery = (): UseQueryResult<IUser[]> => {
  return useQuery(['publicUseVehicles'], () => getBlockedUsers(), {
    cacheTime: 0,
  });
};
