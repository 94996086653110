import { Record, RecordData } from 'types';
import { mapKeysToCamelCase } from 'utils';

export const createRecordUI = (data: RecordData): Record => {
  const { id, ...rest } = data;
  return {
    id,
    ...mapKeysToCamelCase(rest),
  };
};
