import { useApproveCarMutation } from 'services/admin/hooks/useApproveCarMutation';
import { useCorrectCarMutation } from 'services/admin/hooks/useCorrectCarMutation';

type useAdminCarModerateType = () => {
  approveCar: (id: string) => Promise<any>;
  correctCar: (id: string) => Promise<any>;
};

export const useAdminCarModerate: useAdminCarModerateType = () => {
  const mutationApproveCar = useApproveCarMutation();
  const mutationCorrectCar = useCorrectCarMutation();

  const approveCar = (id: string) => {
    return new Promise((resolve) => {
      mutationApproveCar.mutate(id, {
        onSuccess(data) {
          resolve(data);
        },
      });
    });
  };

  const correctCar = (id: string) => {
    return new Promise((resolve) => {
      mutationCorrectCar.mutate(id, {
        onSuccess(data) {
          resolve(data);
        },
      });
    });
  };

  return {
    approveCar,
    correctCar,
  };
};
