import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, TextField, Theme } from '@mui/material';
import { ChangeEvent, ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('admin_dialog_warn');

const styles = ({ palette, spacing }: Theme) => ({
  description: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
  titleWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    
  },
  closeButton: {
    color: palette.text.hint
  },
  actions: {
    '& button': {
      letterSpacing: 1.25,
    },
  },
  field: {
    margin: spacing(2.75, 0), 
  },
  helperText: {
    padding: spacing(0, 2.5),
    color: palette.text.hint,
    fontSize: 12
  }
});

interface UserWarnDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (reason: string) => void;

}

export const UserWarnDialog: ComponentType<UserWarnDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
    const classes = useClasses(styles);
    const { t } = useTranslation();

    const [reason, setReason] = useState<string>('');

    const handleChangeNote = (event: ChangeEvent<HTMLInputElement>) => {
      setReason(event.target.value as string);
    };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='xs'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title' className={classes.titleWrap}>
        {t(tKey('title'))}
        <IconButton onClick={onClose}>
            <Close className={classes.closeButton} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth className={classes.field}>
            <TextField 
                required
                id="standard-multiline-static"
                label={t(tKey('reason_label'))}
                multiline
                rows={4}
                value={reason}
                onChange={handleChangeNote}
                />

        </FormControl>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button onClick={onClose} color='inherit'>
          {t('__common__cancel')}
        </Button>
        <Button onClick={()=>onConfirm(reason)} color='error' disabled={!reason.length}>
          {t('__common__confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
