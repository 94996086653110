import { Theme } from '@mui/material';
import { PageLoader } from 'components';
import { ComponentType, createContext, useContext, useEffect, useRef, useState } from 'react';
import { ProfileContext } from 'store/profile/store';
import { useClasses } from 'utils/hooks/useClasses';
import { useStoryData } from '../hooks/useStoryData';
import { useViewStory } from '../hooks/useViewStory';
import { StoryBody } from './StoryBody';
import { StoryHeader } from './StoryHeader';
import { CommentsStoryDetails } from './StorydDetailsWrap/Comments/CommentsStoryDetails';
import { LikeAndShare } from './StorydDetailsWrap/LikeAndShare';
import { StoryStatistics } from './StorydDetailsWrap/Statistics';
import { TopBar } from './StorydDetailsWrap/TopBar';
import { TopBarManage } from './StorydDetailsWrap/TopBarManage';
import clsx from 'clsx';
import MetaTags from 'components/MetaTags/MetaTegs';
import { NotFoundStory } from './StorydDetailsWrap/NotFoundStory';
import { ExternalLinkDialog } from './StorydDetailsWrap/ExternalLinkDialog';
import { useTranslation } from 'react-i18next';
export const TranslateContext = createContext(false);
const styles = ({ spacing, breakpoints, palette }: Theme) => ({
  root: {
    width: '37vw',
    margin: 'auto',
    paddingBottom: spacing(8),
    [breakpoints.down('md')]: {
      paddingBottom: spacing(4),
    },
    [breakpoints.down('sm')]: {
      width: '100%',
    },
    [breakpoints.up('sm')]: {
      minWidth: 548,
    },
  },
  divider: {
    border: '1px solid #484848',
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: spacing(5),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: spacing(2),
    },
  },
  storyBody: {
    width: `100%`,
    display: 'flex',
    flexDirection: 'column',
  },
  likeAndShare: {
    padding: spacing(0, 1.25),
    marginLeft: spacing(3),
  },
  comments: {
    width: '100%',
    padding: spacing(0, 10.5, 0, 0),
    [breakpoints.down('sm')]: {
      padding: spacing(0, 0, 0, 0),
    },
  },
  space: {
    flex: 1,
  },
});

interface StoryWrapperType {
  authorized: boolean;
  carId: string;
  storyId: string;
}

export const StoryWrapper: ComponentType<StoryWrapperType> = ({ authorized, storyId, carId }) => {
  const classes = useClasses(styles);
  const { i18n } = useTranslation();

  const getLang = () => {
    if (!authorized){
    return i18n.language;}
    else {
      return undefined
    }
  };
  const lang = getLang();

  const { data, isLoading, error } = useStoryData(carId, storyId, lang);
  const { state } = useContext(ProfileContext);
  const [noTranslate, setNoTranslate] = useState(false);
  const { viewStory } = useViewStory();
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [views, setViews] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);

  const [openExternalLink, setOpenExternalLink] = useState(false);
  const [externalLink, setExternalLink] = useState('');

  useEffect(() => {
    if (!isLoading && data) {
      setViews(data.views);
      setCommentsCount(data.comments_count);
      timeoutRef.current = setTimeout(async () => {
        const viewResponse = await viewStory({ carId: data.car.id, storyId: data.id });
        if (viewResponse.message === 'success') {
          setViews(Number(data.views) + 1);
        }
      }, 1500);
    }
    return () => {
      clearTimeout(timeoutRef.current!);
    };
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const onDeleteCommentHandle = () => {
      setCommentsCount((count) => count - 1);
    };
    const onAddCommentHandle = () => {
      setCommentsCount((count) => Number(count) + 1);
    };
    const onOpenExternalLinkDialog = (event: any) => {
      setExternalLink(event.detail.link);
      setOpenExternalLink(true);
    };
    window.addEventListener('deleteComment', onDeleteCommentHandle);
    window.addEventListener('addComment', onAddCommentHandle);
    window.addEventListener('openExternalLink', onOpenExternalLinkDialog);

    return () => {
      window.removeEventListener('deleteComment', onDeleteCommentHandle);
      window.removeEventListener('addComment', onAddCommentHandle);
      window.removeEventListener('openExternalLink', onOpenExternalLinkDialog);
    };
  });

  const onCloseExternalLink = () => {
    setExternalLink('');
    setOpenExternalLink(false);
  };
  return (
    <div className={classes.root}>
      {!isLoading && data !== undefined ? (
        <>
          <MetaTags image={data.cover} title={noTranslate ? data.title : data.translated_title}></MetaTags>
          {state.id === data.user.id ? (
            <TopBarManage story={data} />
          ) : (
            <TopBar
              story={data}
              onShowOriginal={() => {
                setNoTranslate(!noTranslate);
              }}
              noTranslate={noTranslate}
              authorized={authorized}
            />
          )}
          <StoryHeader authorized={authorized} car={data.car} user={data.user} />
          <hr className={classes.divider} />
          <div className={clsx(classes.body, 'body-story')}>
            <div className={classes.storyBody}>
              <TranslateContext.Provider value={noTranslate}>
                <StoryBody story={data} />
              </TranslateContext.Provider>
              <div className={classes.space}></div>
              <StoryStatistics dateCreated={data.date_created} comments={commentsCount} views={views} />
            </div>
            <LikeAndShare story={data} authorized={authorized} />
          </div>

          <div className={classes.comments}>
            <CommentsStoryDetails
              carId={carId}
              storyId={storyId}
              comments={data.comments}
              commentCount={data.comments_count}
              authorized={authorized}
              story_creator_id={data.user.id}
            />
          </div>
          {openExternalLink && externalLink !== '' && (
            <ExternalLinkDialog onClose={onCloseExternalLink} storyId={storyId} url={externalLink} />
          )}
        </>
      ) : error ? (
        <NotFoundStory error={error} />
      ) : (
        <PageLoader />
      )}
    </div>
  );
};
