import { useMutation } from 'react-query';
import { UserNotifications } from 'types';

import { axiosApiInstance as api } from 'utils';

const userNotificationsMutate = async (payload: UserNotifications) => {
  try {
    const { data } = await api.patch('users/settings/notifications/push', payload);
    return data;
  } catch (err) {
    return { error: true };
  }
};

export const useUserNotificationsMutation = () => {
  return useMutation(userNotificationsMutate);
};
