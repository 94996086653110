import { useContext, useEffect, useRef } from 'react';

import { StoryPublishContext as Context, initialState, setState } from 'features/stories/hooks/StoryPublishContext';
import store from 'store2';
import { socketStory as socket } from 'utils/hooks/useSocketStory';

type typeMessage = { message: string; progress: string };
export const SocketsStory = () => {
  const { dispatch } = useContext(Context);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    if (typeof socket !== 'undefined') {
      const onConnect = () => {
        if (store('accessToken')) socket?.emit('authenticate', { token: store('accessToken') });
      };

      if (store('accessToken')) {
        socket.on('connect', onConnect);

        socket.on('connect_error', (error) => {
          console.log(error);
        });

        socket.on('story', (story: typeMessage) => {
          dispatch(setState({ progress: Number(story.progress), stage: story.message }));
          if (story?.progress === '100') {
            timeoutRef.current = setTimeout(() => {
              dispatch(setState(initialState));
            }, 3000);
          }
        });
      }

      return () => {
        socket?.off('connect', onConnect);
        socket?.off('connect_error');
        socket?.off('story');
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
