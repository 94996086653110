import { useQuery, UseQueryResult } from 'react-query';
import { UserProfile } from 'types';

import { axiosApiInstance as api } from 'utils';

const getUserProfile = async (): Promise<UserProfile | { error?: boolean }> => {
  try {
    const { data } = await api.get('users/user-profile');
    return data;
  } catch (err) {
    return { error: true };
  }
};

export const useUserProfile = (): UseQueryResult<UserProfile> => {
  return useQuery(['userProfile'], () => getUserProfile(), { cacheTime: 0 });
};
