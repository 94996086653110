import { useQuery } from 'react-query';
import { axiosApiInstance as api } from 'utils';

const userBansWarnsQuery = async (id: string) => {
  try {
    if (!id) return {};
    const { data } = await api.get(`/engineroom/users/${id}/bans-warnings`);
    return data;
  } catch (err) {
    return { error: true };
  }
};

export const useUserBansWarnsQuery = (id: string) => {
  return useQuery(['getBansWarns', id], () => userBansWarnsQuery(id));
};
