import { Box, Divider, FormHelperText, Grid, Switch, Theme, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import { ComponentType, useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import store from 'store2';
import * as yup from 'yup';

import googleIcon from 'assets/google.png';

import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Form, PageLoader, PasswordInput, StyledButton, TextInput } from 'components';
import { auth } from 'configurations/firebaseConfig';
import { useAppleConnectMutation, useGoogleConnectMutation, useProfileInfoMutation } from 'services';
import { ProfileContext as Context } from 'store/profile/store';
import { UserSettings } from 'types';
import { getTKey, yupOptionalStringValidator, yupStringValidator } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { ConnectGoogleDialog } from '../../../features/settings/ConnectGoogleDialog';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { Capacitor } from '@capacitor/core';
import { handleErrorGoogleAuth } from 'utils/google-auth';
import { Apple } from '@mui/icons-material';

const tKey = getTKey('settings_profile');

const styles = ({ palette, spacing }: Theme) => ({
  divider: {
    margin: spacing(1, 0),
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
  },
  fieldsWrapperDisabled: {
    position: 'relative',
    opacity: 0.4,

    '&::after': {
      position: 'absolute',
      zIndex: 5,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      content: '""',
    },
  },
  inputStyle: {
    WebkitTextFillColor: 'white',
    WebkitBoxShadow: '0 0 0 1000px #121212 inset',
  },
  note: {
    margin: spacing(3, 0, 2),
    textAlign: 'center',
    fontSize: 12,
    opacity: 0.6,
  },
  socialContainer: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  socialTitle: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    gap: 10,
    margin: spacing(0, 2),
  },
  icon: {
    width: 24,
    height: 24,
  },
});

const createDefaultValues = (email?: string) => ({
  email: email ? email : '',
  currentPassword: '',
});

const SigninFormContent: ComponentType<{
  resetGoogleUser: (value: boolean) => void;
  resetAppleUser: (value: boolean) => void;
  preloadedValues: UserSettings;
}> = ({ resetGoogleUser, preloadedValues }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const [loading, setLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string | undefined>('');

  const navigate = useNavigate();
  const { dirtyFields, isDirty } = useFormState();
  const { handleSubmit, getValues } = useFormContext();
  const mutation = useProfileInfoMutation();
  const googleConnectMutation = useGoogleConnectMutation();
  const appleConnectMutation = useAppleConnectMutation();
  const [googleUser, setGoogleUser] = useState(
    preloadedValues.is_google_login_used ? store('driverbook_google_user') : null
  );
  const [appleUser, setAppleUser] = useState(
    preloadedValues.is_apple_login_used ? store('driverbook_apple_user') : null
  );
  const storedLanguage = store('driverbook_language');
  const [open, setOpen] = useState(false);
  const [connectingSocial, setConnectingSocial] = useState<'apple' | 'google' | null>(null);

  const submitProfileForm = async () => {
    const formValues = getValues();
    var values: any = {
      email: dirtyFields?.email ? formValues.email : null,
    };
    if (googleUser) {
      await FirebaseAuthentication.getIdToken().then(
        (access_token) => (values = { ...values, access_token: access_token.token })
      );
    } else {
      values = { ...values, currentPassword: formValues.currentPassword };
    }
    mutation.mutate(values, {
      onSuccess(res) {
        if (res?.error) {
          setFormError(t('__common__error'));
        } else {
          toast(t(tKey('success_message')));
          if (dirtyFields?.newPassword && googleUser) {
            store.remove('driverbook_google_user');
            store.remove('googleAccessToken');
            if (Capacitor.isNativePlatform()) {
              FirebaseAuthentication.signOut();
            }
          }
          navigate('/');
        }
      },
    });
  };

  const connectWithGoogle = async () => {
    setLoading(true);
    setOpen(false);
    if (/Mobi/.test(navigator.userAgent) && !Capacitor.isNativePlatform()) {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      signInWithPopup(auth, provider)
        .then((result: any) => {
          const dataMutate = {
            email: result.user.email ?? '',
            google_id: result.user.providerData[0].uid ?? '',
            language: storedLanguage ? storedLanguage : 'en',
            access_token: result.user.stsTokenManager.accessToken,
          };
          googleConnectMutation.mutate(dataMutate, {
            onSuccess(data) {
              if (data.message === 'success') {
                store(data);
                store('driverbook_google_user', true);
                setGoogleUser(store('driverbook_google_user'));
                toast(t(tKey('success_message')));
                resetGoogleUser(true);
              } else {
                toast(capitalize(t(tKey(data?.error ?? ''))));
              }
              setLoading(false);
            },
          });
        })
        .catch((error) => {
          handleErrorGoogleAuth(error);
          setLoading(false);
        });
    } else {
      try {
        const result = await FirebaseAuthentication.signInWithGoogle({
          customParameters: [{ key: 'prompt', value: 'select_account' }],
        });
        const idToken = (await FirebaseAuthentication.getIdToken()).token;
        googleConnectMutation.mutate(
          {
            // @ts-ignore
            email: result.user.email,
            // @ts-ignore
            google_id: result.additionalUserInfo.profile.sub || result.additionalUserInfo.profile.id,
            language: storedLanguage ? storedLanguage : 'en',
            access_token: idToken ?? '',
          },
          {
            onSuccess(data) {
              if (data.message === 'success') {
                store(data);
                store('driverbook_google_user', true);
                setGoogleUser(store('driverbook_google_user'));
                toast(t(tKey('success_message')));
                resetGoogleUser(true);
              } else {
                toast(capitalize(t(tKey(data?.error ?? ''), { social: 'Google' })));
              }
              setLoading(false);
            },
          }
        );
      } catch (error: any) {
        FirebaseAuthentication.signOut();
        handleErrorGoogleAuth(error);
        setLoading(false);
      }
    }
  };

  const handleOpenClick = (social: 'apple' | 'google') => {
    setConnectingSocial(social);
    setOpen(true);
  };

  const handleConnect = () => {
    if (connectingSocial === 'apple') {
      connectWithApple();
    }
    if (connectingSocial === 'google') {
      connectWithGoogle();
    }
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const connectWithApple = () => {
    //@ts-ignore
    gtag?.('event', 'connect', { method: 'apple' });
    try {
      FirebaseAuthentication.signInWithApple({ scopes: ['email'] })
        .then((firebaseResult: any) => {
          firebaseResult.language = storedLanguage ? storedLanguage : 'en';
          if (Capacitor.isNativePlatform()) {
            firebaseResult.code = Capacitor.isNativePlatform()
              ? firebaseResult.credential.authorizationCode
              : firebaseResult.credential.accessToken;
          }
          appleConnectMutation.mutate(firebaseResult, {
            onSuccess(data, variables, context) {
              if (!data.error) {
                store(
                  'apple_id',
                  firebaseResult.additionalUserInfo.profile.id || firebaseResult.additionalUserInfo.profile.sub
                );
                store('driverbook_apple_user', true);
                store(data);
                setAppleUser(firebaseResult);
              } else {
                setLoading(false);

                toast(capitalize(t(tKey(data?.error ?? ''), { social: 'Apple' })));
              }
            },
          });
        })
        .catch((error) => {
          console.log(error);
          handleErrorGoogleAuth(error);
        });
    } catch (error) {
      toast(t('__common__error'));
    }
  };

  return loading ? (
    <PageLoader />
  ) : (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.column}>
          <TextInput name='email' label={t(tKey('email'))} inputProps={{ disabled: googleUser || appleUser }} />
        </Grid>
        {!googleUser && !appleUser && (
          <Grid item xs={12} className={classes.column}>
            <PasswordInput
              name='currentPassword'
              label={t(tKey('current_password'))}
              inputPropsClassName={classes.inputStyle}
            />
          </Grid>
        )}
        {isDirty && (
          <Grid item xs={12}>
            <Box textAlign='center' mt={4} mb={4}>
              <StyledButton label={t(tKey('submit'))} onClick={handleSubmit(submitProfileForm)} />
            </Box>
          </Grid>
        )}
        {open && (
          <ConnectGoogleDialog
            open={open}
            onClose={handleClose}
            onConnect={handleConnect}
            social={connectingSocial as string}
          />
        )}
        {!appleUser && (
          <Grid item xs={12} mt={4} className={classes.socialContainer}>
            <Box className={classes.socialTitle}>
              <img src={googleIcon} alt='icon' className={classes.icon} />
              <Typography>{googleUser ? t(tKey('google_connected')) : t(tKey('google_connect'))}</Typography>
            </Box>
            <Switch
              color='primary'
              disabled={googleUser}
              checked={googleUser}
              onChange={(event) => handleOpenClick('google')}
            />
          </Grid>
        )}
        {!googleUser && (
          <Grid item xs={12} mt={4} className={classes.socialContainer}>
            <Box className={classes.socialTitle}>
              <Apple className={classes.icon} />
              <Typography>{appleUser ? t(tKey('apple_connected')) : t(tKey('apple_connect'))}</Typography>
            </Box>
            <Switch
              color='primary'
              disabled={appleUser}
              checked={appleUser}
              onChange={(event) => handleOpenClick('apple')}
            />
          </Grid>
        )}
        <Grid item xs={12} mb={4}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>

      {googleUser && <Typography className={classes.note}>{t(tKey('google_disconnect_hint'))}</Typography>}
      {appleUser && <Typography className={classes.note}>{t(tKey('apple_disconnect_hint'))}</Typography>}
      {formError && (
        <FormHelperText margin='dense' error>
          {formError}
        </FormHelperText>
      )}
    </>
  );
};

export const SigninForm: ComponentType<{ preloadedValues: UserSettings }> = ({ preloadedValues }) => {
  const { t } = useTranslation();
  const [shouldReset, setShouldReset] = useState<boolean>(false);
  const { state } = useContext(Context);
  const { email } = state;
  const [googleUser, setGoogleUser] = useState(
    preloadedValues.is_google_login_used === true ? store('driverbook_google_user') : null
  );
  const [appleUser, setAppleUser] = useState(
    preloadedValues.is_apple_login_used === true ? store('driverbook_apple_user') : null
  );

  useEffect(() => {
    //@ts-ignore
    gtag?.('event', 'visited_profile', { method: 'visited_profile' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = useMemo(() => {
    return (t: TFunction): yup.AnyObjectSchema =>
      yup.object().shape({
        email: yupStringValidator(t).email(t('__validation__email')),
        currentPassword:
          (googleUser && preloadedValues.is_google_login_used === true) ||
          (appleUser && preloadedValues.is_apple_login_used === true)
            ? yupOptionalStringValidator(t)
            : yupStringValidator(t),
      });
  }, [googleUser, appleUser, preloadedValues.is_google_login_used, preloadedValues.is_apple_login_used]);

  useEffect(() => {
    if (state.email) {
      setShouldReset(true);
    }
  }, [state]);

  return (
    <Form schema={validationSchema(t)} defaultValues={createDefaultValues(email)} shouldReset={shouldReset}>
      <SigninFormContent
        resetGoogleUser={(value: boolean) => setGoogleUser(value)}
        resetAppleUser={(value: boolean) => setAppleUser(value)}
        preloadedValues={preloadedValues}
      />
    </Form>
  );
};
