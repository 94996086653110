import { useQuery } from 'react-query';

import { UserSettings } from 'types';
import { axiosApiInstance as api } from 'utils';

const getProfileSettings = async (publicRecord: boolean): Promise<UserSettings | undefined> => {
  if (publicRecord) return;

  const { data } = await api.get('/settings');
  return data;
};

export const useProfileSettingsQuery = (publicRecord = false) => {
  return useQuery(['userSettings'], () => getProfileSettings(publicRecord), { cacheTime: 0 });
};
