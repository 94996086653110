import { useUserWarnsQuery } from 'services/admin/hooks/useUserWarnsQuery';
import { IWarn } from '../components/AdminDashboardTypes';

type useAdminUserWarnsType = (id: string) => {
  warns: IWarn[];
  isFetching: boolean;
  refetch: () => void;
};

export const useAdminUserWarns: useAdminUserWarnsType = (id) => {
  const { data: warns, isFetching, refetch } = useUserWarnsQuery(id);

  return {
    warns,
    isFetching,
    refetch,
  };
};
