import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('confirm_dialog');

const styles = ({ palette }: Theme) => ({
  paper: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
  description: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
  actions: {
    '& button': {
      letterSpacing: 1.25,
    },
  },
  light: {
    background: '#fff',
    color: '#000',
  },
});

interface ConfirmationDialogProps {
  title?: string;
  description?: string;
  confirmText?: string;
  light?: boolean;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmationDialog: ComponentType<ConfirmationDialogProps> = ({
  title,
  open,
  description,
  light,
  confirmText,
  onClose,
  onConfirm,
}) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='xs'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      classes={{ paper: clsx(light ? classes.light : classes.paper) }}
    >
      <DialogTitle id='alert-dialog-title'>{title ?? t(tKey('warning'))}</DialogTitle>
      <DialogContent>
        <DialogContentText classes={{ root: clsx(light ? classes.light : classes.description) }}>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button onClick={onClose} color='primary'>
          {t(tKey('cancel'))}
        </Button>
        <Button onClick={onConfirm} color='primary' autoFocus>
          {confirmText ?? t(tKey('delete'))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
