import { Box, Theme } from '@mui/material';
import { useClasses } from 'utils/hooks/useClasses';
const styles = ({ palette, breakpoints, spacing }: Theme) => ({
  recordLeft: {
    background: 'rgba(255, 203, 64, 0.1)',
    borderRadius: 40,
    minHeight: 33,
    padding: spacing(1, 2),
    margin: spacing(2, 0, 1),
    width: '100%',
  },
  description: {
    width: '80%',
    margin: 'auto',
    background: 'rgba(200, 200, 200, 0.1)',
    minHeight: 32,
    borderRadius: 10,
  },
  recordCircle: {
    width: 67,
    height: 67,
    borderRadius: 40,
    background: 'rgba(255, 255, 255, 0.2)',
    margin: '32px auto',
  },
});
export const VoiceFormLoader = () => {
  const classes = useClasses(styles);
  return (
    <Box>
      <Box className={classes.recordLeft}></Box>
      <Box className={classes.description}></Box>
      <Box className={classes.recordCircle}></Box>
    </Box>
  );
};
