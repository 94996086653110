import { useQuery, UseQueryResult } from 'react-query';

import { axiosApiInstance as api } from 'utils';

const getCarMakes = async (): Promise<any> => {
  const { data } = await api.get('/data/car-make');

  return data;
};

export const useCarMakesQuery = (): UseQueryResult<any> => {
  return useQuery(['carMakes'], () => getCarMakes(), { cacheTime: 86400000 });
};
