import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Record } from 'types';

type useAssistedDraftItemType = (data: Record) => { isActive: boolean; identifier: string };
export const useAssistedDraftItem: useAssistedDraftItemType = (data) => {
  const { identifier, recordId } = useParams<{ identifier: string; recordId: string }>() as {
    identifier: string;
    recordId: string;
  };
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    if (data.id === recordId) {
      setIsActive(true);
    }

    return () => {
      setIsActive(false);
    };
  }, [data, recordId]);

  return {
    isActive,
    identifier,
  };
};
