import { Box, FormControlLabel, Switch, Theme, Typography, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Type as ListType, SwipeAction, SwipeableList, SwipeableListItem, TrailingActions } from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import { theme } from 'theme';
import { Reminder } from 'types';
import { useClasses } from 'utils/hooks/useClasses';
import { useDeleteReminder } from '../hooks/useDeleteReminder';
import { useRemindersData } from '../hooks/useRemindersData';
import { DeleteReminderDialog } from './DeleteReminderDialog';
import { ReminderItem } from './ReminderItem';
import { useReadAllRemindersMutation } from 'services/reminders/hooks/useReadAllRemindersMutation';
import { ProfileContext as Context } from 'store/profile/store';
import { setRemindersActive } from 'store/profile/slice';
import { EmptyBox, PageLoader } from 'components';
const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: 500,
  },
  actionButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  actionButtonText: {
    fontSize: 10,
    fontWeight: 500,
    margin: spacing(0, 1.5),
    textTransform: 'uppercase',
  },
});

export const RemindersList = () => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const { carId } = useParams<{ carId: string }>() as { carId: string };
  const location = useLocation();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { dispatch, state } = useContext(Context);
  useEffect(() => {
    if (location?.state?.state?.reminderCreated) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showCompleted, setShowCompleted] = useState(false);
  const { data, filteredReminders, isFetching, changeCompleteReminderById, refetch } = useRemindersData({
    carId,
    showCompleted,
  });

  const readRemindersMutation = useReadAllRemindersMutation();
  const [read, setRead] = useState(false);
  useEffect(() => {
    if (!read && !isFetching && carId && state.remindersActive.find((car_id: string) => car_id === carId)) {
      readRemindersMutation.mutate(
        { carId },
        {
          onSuccess(data, variables, context) {
            setRead(true);
            const newState = state.remindersActive.filter((id) => {
              return id !== carId;
            });
            dispatch(setRemindersActive(newState));
          },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, carId]);

  const onShowCompleted = (event: any) => {
    setShowCompleted((state) => !state);
  };

  const onCopy = (reminder: Reminder) => {
    navigate(`/reminders/${reminder.car_id}/${reminder.id}/copy`);
  };

  const onEdit = (reminder: Reminder) => {
    navigate(`/reminders/${reminder.car_id}/edit/${reminder.id}`);
  };
  const trailingActions = (reminder: Reminder) => (
    <TrailingActions>
      <SwipeAction onClick={() => onCopy(reminder)}>
        <Box className={classes.actionButtonWrapper} sx={{ background: '#BB86FC' }}>
          <Typography className={classes.actionButtonText}>{t('__common__copy')}</Typography>
        </Box>
      </SwipeAction>
      <SwipeAction onClick={() => onEdit(reminder)}>
        <Box className={classes.actionButtonWrapper} sx={{ background: '#FFFFFF1F' }}>
          <Typography className={classes.actionButtonText}>{t('__common__edit')}</Typography>
        </Box>
      </SwipeAction>
      <SwipeAction onClick={() => onDeleteOpenDialog(reminder)}>
        <Box className={classes.actionButtonWrapper} sx={{ background: '#FF5050' }}>
          <Typography className={classes.actionButtonText}>{t('__common__delete')}</Typography>
        </Box>
      </SwipeAction>
    </TrailingActions>
  );

  const { deleteReminder } = useDeleteReminder();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [currentDeleteReminder, setCurrentDeleteReminder] = useState<any>(null);
  const onDeleteOpenDialog = (reminder: Reminder) => {
    setOpenDeleteDialog(true);
    setCurrentDeleteReminder(reminder);
  };

  const onCancelDelete = () => {
    setOpenDeleteDialog(false);
    setCurrentDeleteReminder(null);
  };

  const onDelete = () => {
    deleteReminder({
      carId: currentDeleteReminder.car_id as string,
      reminderId: currentDeleteReminder.id as string,
    }).then((data) => {
      setCurrentDeleteReminder(null);
      setOpenDeleteDialog(false);
      refetch();
    });
  };

  return (
    <Box>
      {isFetching ? (
        <PageLoader />
      ) : data.length ? (
        <Box mb={8}>
          <Box className={classes.header} mb={2}>
            <Typography component={'h1'} className={classes.headerTitle}>
              {t('header_page_reminders')}
            </Typography>
            <FormControlLabel
              value='end'
              checked={showCompleted}
              onChange={onShowCompleted}
              control={<Switch color='primary' id='showCompleted' />}
              label={t('reminder_list_show_complete_label')}
              labelPlacement='start'
            />
          </Box>
          {fullScreen ? (
            <>
              {!isFetching && filteredReminders && (
                <SwipeableList type={ListType.IOS}>
                  {filteredReminders.map((reminder: Reminder) => {
                    return (
                      <SwipeableListItem key={reminder.id} trailingActions={trailingActions(reminder)}>
                        <ReminderItem
                          reminder={reminder}
                          changeCompleteReminderById={changeCompleteReminderById}
                          refetch={refetch}
                        />
                      </SwipeableListItem>
                    );
                  })}
                </SwipeableList>
              )}
            </>
          ) : (
            <>
              {!isFetching &&
                filteredReminders &&
                filteredReminders.map((reminder: Reminder) => {
                  return (
                    <ReminderItem
                      key={reminder.id}
                      reminder={reminder}
                      changeCompleteReminderById={changeCompleteReminderById}
                      refetch={refetch}
                    />
                  );
                })}
            </>
          )}
          <DeleteReminderDialog open={openDeleteDialog} cancel={onCancelDelete} remove={onDelete} />
        </Box>
      ) : (
        <EmptyBox entityName='reminders' />
      )}
    </Box>
  );
};
