import { Box, Divider, FormHelperText, Grid, Theme, Typography } from '@mui/material';
import { ComponentType, useEffect, useMemo, useState } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import store from 'store2';
import * as yup from 'yup';

import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Form, PasswordInput, StyledButton } from 'components';
import { useProfileInfoMutation } from 'services';
import { getTKey, yupOptionalStringValidator, yupPasswordValidator, yupStringValidator } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { Capacitor } from '@capacitor/core';

const tKey = getTKey('settings_profile');

const styles = ({ palette, spacing }: Theme) => ({
  divider: {
    margin: spacing(1, 0),
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
  },
  fieldsWrapperDisabled: {
    position: 'relative',
    opacity: 0.4,

    '&::after': {
      position: 'absolute',
      zIndex: 5,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      content: '""',
    },
  },
  inputStyle: {
    WebkitTextFillColor: 'white',
    WebkitBoxShadow: '0 0 0 1000px #121212 inset',
  },
  note: {
    margin: spacing(3, 0, 2),
    textAlign: 'center',
    fontSize: 12,
    opacity: 0.6,
  },
  googleContainer: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  google: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    gap: 10,
    margin: spacing(0, 2),
  },
  icon: {
    width: 24,
    height: 24,
  },
});

const PasswordFormContent: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const [formError, setFormError] = useState<string | undefined>('');

  const navigate = useNavigate();
  const { dirtyFields, isDirty } = useFormState();
  const { handleSubmit, getValues } = useFormContext();
  const mutation = useProfileInfoMutation();
  const [googleUser] = useState(store('driverbook_google_user'));
  const [appleUser] = useState(store('driverbook_apple_user'));
  const [isNativePlatform] = useState(Capacitor.isNativePlatform());
  const submitProfileForm = async () => {
    const formValues = getValues();
    var values: any = {
      newPassword: dirtyFields?.newPassword ? formValues.newPassword : null,
    };
    if (googleUser) {
      await FirebaseAuthentication.getIdToken().then(
        (access_token) => (values = { ...values, access_token: access_token.token })
      );
    } else if (appleUser) {
      await FirebaseAuthentication.signInWithApple({ scopes: ['email'] }).then((firebaseResult: any) => {
        const code = Capacitor.isNativePlatform()
          ? firebaseResult.credential.authorizationCode
          : firebaseResult.credential.accessToken;
        values = { ...values, code };
      });
    } else {
      values = { ...values, currentPassword: formValues.currentPassword };
    }

    mutation.mutate(values, {
      onSuccess(res) {
        if (res?.error) {
          setFormError(t('__common__error'));
        } else {
          toast(t(tKey('success_message')));
          if (dirtyFields?.newPassword && googleUser) {
            store.remove('driverbook_google_user');
            store.remove('driverbook_apple_user');
            store.remove('google_id');
            store.remove('apple_id');
            store.remove('googleAccessToken');
            store.remove('appleAccessToken');
            if (Capacitor.isNativePlatform()) {
              FirebaseAuthentication.signOut();
            }
          }
          navigate('/');
        }
      },
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.column}>
          <PasswordInput
            name='newPassword'
            label={t(tKey('new_password'))}
            inputPropsClassName={classes.inputStyle}
            inputProps={{ disabled: appleUser && !isNativePlatform }}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordInput
            name='repeatPassword'
            label={t(tKey('repeat_password'))}
            inputPropsClassName={classes.inputStyle}
            inputProps={{ disabled: appleUser && !isNativePlatform }}
          />
        </Grid>
        {!googleUser && !appleUser && (
          <Grid item xs={12} className={classes.column}>
            <PasswordInput
              name='currentPassword'
              label={t(tKey('current_password'))}
              inputPropsClassName={classes.inputStyle}
            />
          </Grid>
        )}
        {isDirty && (
          <Grid item xs={12}>
            <Box textAlign='center' mt={4} mb={4}>
              <StyledButton label={t(tKey('submit'))} onClick={handleSubmit(submitProfileForm)} />
            </Box>
          </Grid>
        )}

        <Grid xs={12} mb={4}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
      {appleUser && !isNativePlatform && (
        <Typography className={classes.note}>{t(tKey('note_change_password_in_app'))}</Typography>
      )}
      {dirtyFields?.newPassword && googleUser && (
        <Typography className={classes.note}>{t(tKey('note_google'))}</Typography>
      )}
      {dirtyFields?.newPassword && appleUser && (
        <Typography className={classes.note}>{t(tKey('note_apple'))}</Typography>
      )}
      {formError && (
        <FormHelperText margin='dense' error>
          {formError}
        </FormHelperText>
      )}
    </>
  );
};

export const PasswordForm: ComponentType = () => {
  const { t } = useTranslation();
  const [googleUser] = useState(store('driverbook_google_user'));
  const [appleUser] = useState(store('driverbook_apple_user'));

  useEffect(() => {
    //@ts-ignore
    gtag?.('event', 'visited_profile', { method: 'visited_profile' });
  }, []);

  const validationSchema = useMemo(() => {
    return (t: TFunction): yup.AnyObjectSchema =>
      yup.object().shape({
        newPassword: yupPasswordValidator(t, true),
        repeatPassword: yup
          .string()
          .when('newPassword', {
            is: (newPassword: string) => newPassword.length > 0,
            then: yupStringValidator(t),
          })
          .oneOf([yup.ref('newPassword')], t('__validation__passwords_dont_match')),
        currentPassword: googleUser || appleUser ? yupOptionalStringValidator(t) : yupStringValidator(t),
      });
  }, [googleUser, appleUser]);

  return (
    <Form schema={validationSchema(t)}>
      <PasswordFormContent />
    </Form>
  );
};
