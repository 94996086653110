import { useApproveStoryMutation, useHideStoryMutation } from "services/admin";

type useAdminStoryModerateType = () => {
    approveStory: (id: string) => Promise<any>,
    hideStory: (id: string) => Promise<any>
}

export const useAdminStoryModerate: useAdminStoryModerateType =  () => {
    const mutationApproveStory = useApproveStoryMutation();
    const mutationHideStory = useHideStoryMutation();

    const approveStory = (id: string) => {
        return new Promise((resolve)=>{
            mutationApproveStory.mutate(id, {
                onSuccess(data){
                    resolve(data);
                }
            });
        });
    }

    const hideStory = (id: string) => {
        return new Promise((resolve)=>{
            mutationHideStory.mutate(id, {
                onSuccess(data){
                    resolve(data);
                }
            });
        });
    }

    return {
        approveStory,
        hideStory
    }
}