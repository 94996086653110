import { useMutation } from 'react-query';

import { axiosApiInstance as api } from 'utils';

export interface ResetPasswordPayload {
  password: string;
  reset_token: string;
}

const resetPassword = async (payload: ResetPasswordPayload): Promise<any> => {
  const { data } = await api.post('/users/reset', payload);
  return data;
};

export const useResetPasswordMutation = () => {
  return useMutation(resetPassword);
};
