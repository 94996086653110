import { useQuery, UseQueryResult } from 'react-query';

import { axiosApiInstance as api } from 'utils';

const getCities = async (country: string): Promise<string[] | { error?: boolean }> => {
  try {
    const { data } = await api.get('data/cities', { params: { country } });

    return data;
  } catch (err) {
    return { error: true };
  }
};

export const useCities = (country: string | undefined): UseQueryResult<string[]> => {
  return useQuery(['cities', country], () => getCities(country ?? ''));
};
