import { useMutation } from 'react-query';

import _ from 'lodash';
import { UserSettings } from 'types';
import { axiosApiInstance as api, mapKeysToSnakeCase } from 'utils';

const updateProfileSettings = async (body: Partial<UserSettings>): Promise<any> => {
  try {
    body = _.omitBy(body, (item, key) => !(key !== 'is_google_login_used' && key !== 'is_push_notification_on' && key !== 'is_apple_login_used'));
    const { data } = await api.patch('/settings', mapKeysToSnakeCase(body));
    return data;
  } catch (error) {
    return { error: true };
  }
};

export const useProfileSettingsMutation = () => {
  return useMutation(updateProfileSettings);
};
