import { Box, Theme } from '@mui/material';
import { uniqueId } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
  assistanceStream,
  useAssistantClearHistoryMutation,
  useAssistantGetHistoryQuery,
  useAssistantHistorySearchQuery,
  useVoiceToTextMutation,
} from 'services/assistant';
import { AssistantCountryCode, changeAssistantCounty, cleanText, useAssistantCountry } from 'utils/assistantLanguage';
import { useClasses } from 'utils/hooks/useClasses';
import { AssistantHeader } from './AssistantHeader';
import { AssistantStopGenerateButton } from './ChatElements/AssistantStopGenerateButton';
import { AssistantVoiceToTextPopup } from './ChatElements/AssistantVoiceToTextPopup';
import { InputField } from './InputField';
import { SearchHighlightContext } from './SearchHighlightContext';
import { AssistantSearchBarControl } from './AssistantSearchBarControl';
import { AssistantMessageList } from './ChatElements/AssistantMessageList';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const START_INDEX = 50000;
const styles = ({ spacing, breakpoints, palette }: Theme) => ({
  root: {
    [breakpoints.up('md')]: {
      maxHeight: 'calc(100vh - 192px)',
    },
    [breakpoints.down('md')]: {
      maxHeight: 'calc(100vh - 65px)',
    },

    display: 'flex',
    flexDirection: 'column',
    position: 'relative',

    scrollbarWidth: 'thin', // For Firefox, slim scrollbar
    scrollbarColor: '#444 #222', // Dark scrollbar for Firefox (thumb and track)

    '&::-webkit-scrollbar': {
      width: '6px', // Slim scrollbar width
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#222', // Dark background for track
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#444', // Dark color for the scrollbar thumb
      borderRadius: '10px', // Rounded thumb edges
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#666', // Lighter on hover for better visibility
    },

    '& mark': {
      backgroundColor: '#BB86FC33',
      borderRadius: 4,
      padding: 2,
      color: 'white',
    },
    '& mark.active': {
      backgroundColor: '#BB86FC',
    },
  },
});

function extractThreadNumber(str: string) {
  const match = str.match(/thread_(\w+)/);
  return match ? match[1] : null;
}
export interface ChatMessage {
  actor: 'user' | 'assistant' | 'system';
  country?: AssistantCountryCode;
  response: string;
  question: string;
  //state to show animate like assistant thinking
  isStreaming?: boolean;
  id: string;
}

interface messageLoad {
  id: string;
  response: string;
  question: string;
  country: string;
  type: 'question' | 'response';
}

export const AssistantChat = () => {
  const classes = useClasses(styles);
  const childRef = useRef<any>(null);
  const { t } = useTranslation();

  // State to hold the chat history
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);

  // State to track the current page of data
  const [page, setPage] = useState(1);

  // Fetching and handling pagination and loading states
  const {
    data,
    isLoading,
    fetchNextPage,
    refetch,
    //  isFetchingNextPage, isFetching
  } = useAssistantGetHistoryQuery(page);
  // const [existNextPage, setExistNextPage] = useState(false);
  // const [firstPageLoaded, setFirstPageLoaded] = useState(false);

  // State to handle loading state for chat history
  // const [isLoadingHistory, setIsLoadingHistory] = useState(false);

  //State for current country
  const [currentCountry, setCurrentCountry] = useState(useAssistantCountry());

  //controller to stop the streaming process
  const [controller, setController] = useState<AbortController | null>(null);

  //state to show stop stream button
  const [isStreaming, setIsStreaming] = useState(false);

  const [searchQuery, setSearchQuery] = useState({ query: '', total: 0, current: 1 });

  const virtuoso = useRef(null);

  const [threadId, setThreadId] = useState<string | null>(null);

  const mutationClearHistory = useAssistantClearHistoryMutation();

  const [showSuggest, setShowSuggest] = useState(false);

  //Fetching search results
  const {
    data: dataSearch,
    isLoading: isLoadingSearch,
    refetch: refetchHistory,
  } = useAssistantHistorySearchQuery(searchQuery);

  const [chatSearchHistory, setChatSearchHistory] = useState<ChatMessage[]>([]);

  const scrollToDown = () => {
    window.setTimeout(function () {
      //@ts-ignore
      virtuoso?.current?.scrollToIndex({ index: START_INDEX - 1 });
    }, 100);
  };

  // Effect to update chat history and handle pagination
  useEffect(() => {
    if (!isLoading && data?.pages[page - 1]) {
      const messages = data?.pages[page - 1].messages.map((item: messageLoad) => {
        return {
          id: item.id,
          actor: item.type === 'question' ? 'user' : 'assistant',
          response: item.response ? item.response.replace(/【.+†source】/g, '').replace(/^thread_[\w\d]+/, '') : '',
          question: item.question ? item.question.replace(/【.+†source】/g, '').replace(/^thread_[\w\d]+/, '') : '',
          country: item.country,
        };
      });
      setChatHistory((state: any[]) => {
        const news: any[] = [];
        messages.map((item: any) => news.unshift(item));
        return [...news, ...state];
      });
      // setExistNextPage(data?.pages[page - 1].messages.length === data?.pages[page - 1].per_page);
      // setIsLoadingHistory(false);
      setPage(page + 1);
      if (messages.length) {
        setShowSuggest(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data]);

  const [countSearch, setCountSearch] = useState(0);
  useEffect(() => {
    if (!isLoadingSearch && dataSearch?.messages) {
      const messages = dataSearch?.messages.map((item: messageLoad) => {
        return {
          id: item.id,
          actor: item.type === 'question' ? 'user' : 'assistant',
          response: item.response ? cleanText(item.response) : '',
          question: item.question ? cleanText(item.question) : '',
          country: item.country,
        };
      });

      setChatSearchHistory(() => {
        const news: any[] = [];
        messages.map((item: any) => news.unshift(item));
        return news;
      });

      scrollToDown();
      setTimeout(() => {
        setCountSearch(messages.length);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSearch, isLoadingSearch]);

  const handleStartStreaming = async (message: string, voice?: boolean) => {
    setShowSuggest(false);
    scrollToDown();
    // Create a new AbortController instance for each request
    const newController = new AbortController();
    setController(newController); // Store the controller to allow later aborting
    setIsStreaming(true); // Enable streaming state
    if (voice) {
      setChatHistory((state: any) => {
        const newState = [...state];
        newState[newState.length - 1] = {
          ...newState[newState.length - 1],
          question: message,
          isStreaming: false,
        };
        if (childRef.current) {
          childRef.current.listRef.current.scrollToRow(-1);
        }
        return newState;
      });
    } else {
      setChatHistory((state: any[]) => [...state, { actor: 'user', question: message, id: uniqueId() }]);
    }
    setChatHistory((state: any[]) => [
      ...state,
      { actor: 'assistant', country: currentCountry, response: '', isStreaming: true, id: uniqueId() },
    ]);
    try {
      await assistanceStream(
        { message, country: currentCountry, thread_id: threadId },
        (chunk) => {
          processStreamData(chunk);
        },
        newController.signal
      );
    } catch (error) {
      console.error('Streaming error:', error);
    } finally {
      setIsStreaming(false); // Disable streaming state after process finishes
      scrollToDown();
    }
  };

  const handleStopStreaming = () => {
    if (controller) {
      controller.abort(); // Abort the current fetch
      setIsStreaming(false); // Disable the streaming state
      setChatHistory((state: any) => {
        const newState = [...state];
        newState[newState.length - 1] = {
          ...newState[newState.length - 1],
          isStreaming: false,
        };
        return newState;
      });
    }
  };

  const processStreamData = (chunk: string) => {
    const chuckThreadId = extractThreadNumber(chunk);
    if (chuckThreadId) {
      setThreadId(chuckThreadId);
      scrollToDown();
    }
    const cleanedChunk = cleanText(chunk);
    if (cleanedChunk === "rate_limit_error"){
        toast(t("rate_limit_error"))
    }
    else{
    setChatHistory((state: any) => {
      const newState = [...state];
      newState[newState.length - 1] = {
        ...newState[newState.length - 1],
        response: newState[newState.length - 1].response + cleanedChunk,
        isStreaming: chunk.includes('thread') ? true : false,
      };
      if (childRef.current) {
        // childRef.current.listRef.current.scrollToRow(-1);
      }
      return newState;
    });
  }
  };

  const handleChangeCountry = (country: string) => {
    scrollToDown();
    if (country === currentCountry) return;
    setCurrentCountry(country);
    changeAssistantCounty(country);
    setChatHistory((state: any[]) => {
      return [...state, { actor: 'system', country, id: uniqueId() }];
    });
  };

  const [openDialogVoice, setOpenDialogVoice] = useState(false);

  const handleOpenVoiceDialog = () => {
    setOpenDialogVoice(true);
  };

  const handleCloseVoiceDialog = () => {
    setOpenDialogVoice(false);
  };
  const voiceMutation = useVoiceToTextMutation();

  const handleSubmitVoice = (audioFile: File) => {
    handleCloseVoiceDialog();
    setChatHistory((state: any[]) => [...state, { actor: 'user', question: '', id: uniqueId(), isStreaming: true }]);
    voiceMutation.mutate(
      { body: { audio: audioFile } },
      {
        onSuccess(data, variables, context) {
          if (!data.error && data.message === 'success') {
            handleStartStreaming(data.text, true);
          }
        },
      }
    );
  };

  const handleSearch = (query: string) => {
    setSearchQuery({ query, total: 0, current: START_INDEX - 1 });
  };

  useEffect(() => {
    refetchHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const handleCloseSearch = () => {
    setSearchQuery({ query: '', total: 0, current: START_INDEX - 1 });
    setChatSearchHistory([]);
  };

  const handleChangeCurrent = (current: number) => {
    setSearchQuery((state) => {
      return { ...state, current: START_INDEX - current };
    });
  };

  const handleWelcomeSubmit = (text: string) => {
    handleStartStreaming(text);
  };

  const handleClearHistory = () => {
    mutationClearHistory.mutate(undefined, {
      onSuccess(data, variables, context) {
        if (data.message === 'success') {
          setChatHistory([]);
          refetch();
          setThreadId(null);
        }
      },
    });
  };

  const handleSuggestSubmit = (text: string) => {
    handleStartStreaming(text);
  };

  return (
    <Box className={classes.root}>
      <AssistantHeader
        country={currentCountry}
        onChangeCountry={handleChangeCountry}
        onSearchSubmit={handleSearch}
        onCancelSearch={handleCloseSearch}
      />
      {searchQuery.query === '' ? (
        <AssistantMessageList
          virtuoso={virtuoso}
          messages={chatHistory}
          startReached={fetchNextPage}
          showWelcome={!isLoading && data?.pages[0].messages.length === 0}
          onWelcomeSubmit={handleWelcomeSubmit}
          showSuggest={showSuggest}
          onSuggestSubmit={handleSuggestSubmit}
        />
      ) : (
        <SearchHighlightContext.Provider value={searchQuery}>
          <AssistantMessageList virtuoso={virtuoso} messages={chatSearchHistory} />
        </SearchHighlightContext.Provider>
      )}

      {searchQuery.query === '' && (
        <InputField
          onSubmit={handleStartStreaming}
          handleVoice={handleOpenVoiceDialog}
          onClearHistory={handleClearHistory}
          clearHistoryAvailability={chatHistory.length > 0}
        />
      )}
      {searchQuery.query !== '' && (
        <AssistantSearchBarControl total={countSearch} onChangeCurrent={handleChangeCurrent} />
      )}
      <AssistantStopGenerateButton isStreaming={isStreaming} handleClick={handleStopStreaming} />
      <AssistantVoiceToTextPopup
        open={openDialogVoice}
        handleClose={handleCloseVoiceDialog}
        handleSubmit={handleSubmitVoice}
      />
    </Box>
  );
};
