'use client';

import createSvgIcon from '@mui/icons-material/esm/utils/createSvgIcon';

export default createSvgIcon(
  <svg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.15987 12.9494H7.8393C5.6362 12.9494 3.85045 11.1851 3.85045 9.01046V4.77247C3.85045 2.59692 5.6362 0.833496 7.8393 0.833496H8.15987C10.363 0.833496 12.1496 2.59692 12.1496 4.77247V9.01046C12.1496 11.1851 10.363 12.9494 8.15987 12.9494ZM14.0154 8.88446C14.0154 8.39954 14.4132 8.00757 14.9034 8.00757C15.3935 8.00757 15.7913 8.39954 15.7913 8.88446C15.7913 12.8296 12.7668 16.0872 8.88811 16.5248V18.2899C8.88811 18.774 8.49029 19.1668 8.00012 19.1668C7.50906 19.1668 7.11213 18.774 7.11213 18.2899V16.5248C3.2325 16.0872 0.208008 12.8296 0.208008 8.88446C0.208008 8.39954 0.605827 8.00757 1.096 8.00757C1.58617 8.00757 1.98399 8.39954 1.98399 8.88446C1.98399 12.1596 4.68259 14.8245 8.00012 14.8245C11.3168 14.8245 14.0154 12.1596 14.0154 8.88446Z'
      fill='white'
      fillOpacity='0.74'
    />
  </svg>
);
