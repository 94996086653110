import { QuestionAnswer, Visibility } from '@mui/icons-material';
import { Theme } from '@mui/material';
import dayjs from 'dayjs';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useClasses } from 'utils/hooks/useClasses';
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const styles = ({ breakpoints, spacing, palette }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  reaction: {
    color: '#A5A5A5',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 700,
    '& svg': {
      width: 20,
      height: 20,
      paddingRight: spacing(0.5),
    },
  },
  dot: {
    padding: spacing(0, 1.75),
    color: 'rgba(168, 166, 171, 1)',
    [breakpoints.down('sm')]: {
      padding: spacing(0, 0.5),
    },
  },
  space: {
    flex: 1,
  },
  timeAgo: {
    color: '#A6A6A6',
  },
});

interface StoryStatisticsType {
  dateCreated: string;
  comments: number;
  views: number;
}

export const StoryStatistics: ComponentType<StoryStatisticsType> = ({ dateCreated, comments, views }) => {
  const classes = useClasses(styles);
  const { i18n } = useTranslation();

  const getLang = () => {
    return i18n.language;
  };
  const lang = getLang();

  try {
    if (lang === 'uk') {
      dayjs.locale(require(`dayjs/locale/uk`));
    } else if (lang === 'es') {
      dayjs.locale(require(`dayjs/locale/es`));
    } else {
      dayjs.locale(require(`dayjs/locale/en`));
    }
  } catch (e) {
    console.log(`Cannot load ${lang} language. Will be used english language`);
  }
  return (
    <div className={classes.root}>
      <div className={classes.reaction}>
        <QuestionAnswer></QuestionAnswer>
        {comments}
      </div>
      <div className={classes.dot}>•</div>
      <div className={classes.reaction}>
        <Visibility></Visibility>
        {views}
      </div>
      <div className={classes.space}></div>
      <div className={classes.timeAgo}>
        {
          // @ts-ignore
          dayjs.utc(dateCreated).fromNow(true)
        }
      </div>
    </div>
  );
};
