import { useQuery } from 'react-query';

import { axiosApiInstance as api } from 'utils';
import { Record } from 'types';

import { createRecordUI } from '../utils';

interface GetRecordByIdPayload {
  carId: string;
  recordId?: string;
}

const getRecordById = async ({ carId, recordId }: GetRecordByIdPayload): Promise<Record | undefined> => {
  if (!recordId) { return undefined}
  const { data } = await api.get(`/cars/${carId}/records/${recordId}`);

  return createRecordUI(data);
};

export const useRecordQuery = ({ carId, recordId }: GetRecordByIdPayload) => {
  return useQuery(
    ['records', recordId],
    () => getRecordById({ carId, recordId }),
  );
};


export const useRecordForCopyQuery = ({ carId, recordId }: GetRecordByIdPayload) => {
  return useQuery(
    ['recordsForCopy', recordId],
    () => getRecordById({ carId, recordId }),
  );
};
