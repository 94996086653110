import { useEffect, useState } from 'react';
import { useRemindersCarQuery } from 'services/reminders';
import { Reminder } from './../../../types';
interface useRemindersDataProps {
  carId: string;
  showCompleted: boolean;
}

export const useRemindersData = ({ carId, showCompleted }: useRemindersDataProps) => {
  const { data, isFetching, refetch } = useRemindersCarQuery({ carId });

  const [filteredReminders, setFilteredReminders] = useState<Reminder[]>([]);
  const [localChanges, setLocalChanges] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (data && !isFetching) {
      const updatedData = data.map((reminder: Reminder) => {
        if (localChanges.hasOwnProperty(reminder.id as string)) {
          return { ...reminder, is_completed: localChanges[reminder.id as string] };
        }
        return reminder;
      });
      if (showCompleted) {
        setFilteredReminders(updatedData);
      } else {
        setFilteredReminders(updatedData.filter((reminder: any) => !reminder.is_completed));
      }
    } else {
      setFilteredReminders([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetching, showCompleted]);

  const changeCompleteReminderById = (id: string) => {
    setLocalChanges((prevChanges) => {
      const currentStatus =
        prevChanges[id] !== undefined
          ? prevChanges[id]
          : data.find((reminder: Reminder) => reminder.id === id)?.is_completed;
      return { ...prevChanges, [id]: !currentStatus };
    });
  };

  return {
    data,
    filteredReminders,
    isFetching,
    changeCompleteReminderById,
    refetch,
  };
};
