'use client';

import createSvgIcon from '@mui/icons-material/esm/utils/createSvgIcon';

export default createSvgIcon(
  <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <defs>
      <linearGradient id='grad1' x1='0%' x2='100%' y1='0%' y2='100%'>
        <stop offset='0%' stopColor='#BB86FC' />
        <stop offset='50%' stopColor='#003CD6' />
        <stop offset='100%' stopColor='#00A2D6' />
      </linearGradient>
    </defs>
    <path
      fill='url(#grad1)'
      d='m19 9 1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25zm-7.5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12zM19 15l-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25z'
    />
  </svg>
);
