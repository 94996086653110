import { Box, Grid, Theme } from '@mui/material';
import getOr from 'lodash/fp/getOr';
import { ComponentType, useEffect, useState, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import store from 'store2';
import * as yup from 'yup';

import { Form, ImageUploaderMultiple, PageLoader, StyledButton, TextInput } from 'components';
import { DatePickerSelect } from 'components/Form/DatePickerSelect';
import dayjs from 'dayjs';
import { isNull } from 'lodash';
import { useCarQuery } from 'services';
import { NewRecordValues, Record, RecordAITypeShort, Volume } from 'types';
import { DEFAULT_CURRENCY, buildRecordFormImages, getTKey, volumeMetric, yupNumberValidator } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { useRecordFormImages } from '../hooks/useRecordFormImages';
const tKey = getTKey('record_form');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  column: {
    position: 'absolute',
    top: 30,
    right: 0,
  },
  refuel: {
    display: 'flex',
  },
  select: {
    padding: spacing(1, 1),
  },
});

interface RecordFormProps {
  onSubmit: (values: any) => void;
  defaultValues?: Record;
  category: string;
  editMode?: boolean;
  isSubmitting?: boolean;
  ai?: RecordAITypeShort;
  onRemove?: (id: string) => void;
}

export const recordFormValidationSchema = (t: TFunction): yup.AnyObjectSchema => {
  return yup.object().shape({
    title: yup.number().typeError(t('__validation__number')).required(t('__validation__required')),
    partName: yup.string().max(400, t('__validation__maximum_symbols', { length: 400 })),
    cost: yup.number().typeError(t('__validation__number')).required(t('__validation__required')),
    mileage: yupNumberValidator(t).max(9999999, t('__validation__maximum_9999999')),
    datePerformed: yup.date(),
  });
};

const createDefaultValues = (values?: any) => ({
  title: isNull(values?.title) ? '' : getOr('', 'title', values),
  partName: values?.partName
    ? getOr('', 'partName', values)
    : isNull(values?.latestFuelType)
    ? ''
    : getOr('', 'latestFuelType', values),
  cost: isNull(values?.cost) ? '' : getOr('', 'cost', values),
  details: isNull(values?.details) ? '' : getOr('', 'details', values),
  mileage: isNull(values?.mileage) ? '' : getOr('', 'mileage', values),
  datePerformed:
    values?.datePerformed === 'None'
      ? null
      : values && dayjs(values.datePerformed).isValid()
      ? dayjs(values.datePerformed)
      : dayjs(),
});

export const RecordFuelForm: ComponentType<RecordFormProps> = ({
  onSubmit,
  defaultValues,
  editMode,
  category,
  isSubmitting,
  ai,
  onRemove,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const settingsData = store('driverbook_settings');
  const { carId } = useParams<{ carId: string }>() as { carId: string };
  const { data: carData, isLoading } = useCarQuery(carId);
  const [values, setValues] = useState<any>(defaultValues);
  const { images, onImageChange } = useRecordFormImages(values);
  const localSettings = store('driverbook_settings');
  const isSubmittingRef = useRef(false);

  useEffect(() => {
    if (defaultValues) {
      const cloneData = JSON.parse(JSON.stringify(defaultValues));
      setValues(cloneData);
    }
  }, [defaultValues]);

  const handleSubmit = async (data: NewRecordValues) => {
    if (isSubmittingRef.current) return; 
    isSubmittingRef.current = true;
    const fd = new FormData();
    await buildRecordFormImages(fd, images, values);

    fd.append('category', category);
    fd.append('cost', data.cost);
    fd.append('details', data.details);
    fd.append('mileage', data.mileage.toString());
    fd.append('part_name', data.partName);
    fd.append('title', data.title);
    fd.append('date_performed', dayjs(data.datePerformed).format('YYYY-MM-DD HH:mm:ss'));
    if (ai) {
      fd.append('ai_type', ai);
    }
    onSubmit(fd);
  };

  const fuelCategoryUnit: { [category: string]: Volume | 'kWh' } = {
    refueling: settingsData?.volume_metric,
    recharging: 'kWh',
  };

  const getUnit = (category: string, plural?: boolean, prefix = '') =>
    t(tKey(prefix + volumeMetric[fuelCategoryUnit[category] as Volume] + (plural ? 's' : '')));

  return isLoading ? (
    <PageLoader />
  ) : (
    <Form
      onSubmit={handleSubmit}
      schema={recordFormValidationSchema(t)}
      defaultValues={createDefaultValues({ ...values, latestFuelType: carData?.latestFuelType })}
      mode='onChange'
      // recordForm={true}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextInput
                  name='cost'
                  label={`${t(tKey('total_cost'))}, ${settingsData ? settingsData.currency : DEFAULT_CURRENCY}`}
                  textFieldProps={{ type: 'number' }}
                  ai={Boolean(ai && values?.cost)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  name='title'
                  label={getUnit(category, false, 'total_')}
                  textFieldProps={{ type: 'number' }}
                  ai={Boolean(ai && values?.title)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              {category === 'refueling' ? (
                <>
                  <Grid item xs={6}>
                    <TextInput name='partName' label={t(tKey('fuel_type'))} ai={Boolean(ai && values?.partName)} />
                  </Grid>

                  <Grid item xs={6}>
                    <TextInput
                      name='details'
                      label={`${t(tKey('cost_per'), { unit: getUnit(category) })}, ${
                        settingsData ? settingsData.currency : DEFAULT_CURRENCY
                      }`}
                      textFieldProps={{ type: 'number' }}
                      ai={Boolean(ai && values?.details)}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={6}>
                    <TextInput
                      name='details'
                      label={`${t(tKey('cost_per'), { unit: getUnit(category) })}, ${
                        settingsData ? settingsData.currency : DEFAULT_CURRENCY
                      }`}
                      textFieldProps={{ type: 'number' }}
                      ai={Boolean(ai && values?.details)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      name='mileage'
                      label={`${t(tKey('current_mileage'))}, ${carData?.mileageMetric}`}
                      ai={Boolean(ai && (values?.mileage || values?.mileage === 0))}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePickerSelect
                      name='datePerformed'
                      label={`${t(tKey('date_performed'))}`}
                      format={localSettings.date_format}
                      ai={Boolean(ai && values?.datePerformed)}
                    />
                  </Grid>
                </>
              )}
            </Grid>

            {category === 'refueling' && (
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextInput
                    name='mileage'
                    label={`${t(tKey('current_mileage'))}, ${carData?.mileageMetric}`}
                    ai={Boolean(ai && (values?.mileage || values?.mileage === 0))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePickerSelect
                    name='datePerformed'
                    label={`${t(tKey('date_performed'))}`}
                    format={localSettings.date_format}
                    ai={Boolean(ai && values?.datePerformed)}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          <Box component={'div'} sx={{ display: { xs: 'block', md: 'none' } }}>
            <ImageUploaderMultiple
              images={images}
              onChange={onImageChange}
              editMode={editMode}
              data={defaultValues as Record}
            />
            {onRemove && (
              <Box sx={{ marginTop: 2 }} display='flex' justifyContent='center'>
                <StyledButton label={t(tKey('remove_record'))} type='button' onClick={onRemove} color='secondary' />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className={classes.column} sx={{ top: ai && { md: '-16px !important' } }}>
          <Box component={'div'} sx={{ display: { md: 'block', xs: 'none' } }}>
            <ImageUploaderMultiple
              images={images}
              onChange={onImageChange}
              editMode={editMode}
              data={defaultValues as Record}
            />
            {onRemove && (
              <Box sx={{ marginTop: 2 }} display='flex' justifyContent='center'>
                <StyledButton label={t(tKey('remove_record'))} type='button' onClick={onRemove} color='secondary' />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <Box mt={3} mb={4} textAlign='center' sx={{ display: ai ? 'none' : 'block' }}>
        <StyledButton
          className='submit-record-form'
          label={editMode ? t(tKey('edit_record')) : t(tKey('create_record'))}
          disabled={isSubmitting}
          type='submit'
        />
      </Box>
    </Form>
  );
};
