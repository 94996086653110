import i18n from 'i18next';
import { Currency, Volume } from '../types';

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const currencySymbol: Record<Currency, string> = {
  UAH: '₴',
  USD: '$',
  EUR: '€',
  PLN: 'zł',
  CAD: '$',
  AUD: '$',
  AED: 'د.إ',
  PHP: '₱',
  GBP: '£',
  NZD: '$',
  ZAR: 'R',
  SGD: '$',
  MXN: '$',
};

export const volumeMetric: Record<Volume | 'kWh', string> = {
  l: 'liter',
  g: 'gallon',
  kWh: 'kWh',
};

export const getTKey = (namespace: string) => {
  return (key: string): string => `${namespace || ''}_${key}`;
};

export const normalizeChipName = (label: number | string): JSX.Element | string => {
  if (label === '') {
    return i18n.t('__common__all_categories');
  }

  if (Number(label) < 12) {
    return i18n.t(`__month__${months[label as number]}`);
  }

  if (Number(label) > 12) {
    return label.toString();
  }

  return i18n.t(`__record_category__${label}`);
};

export const convertLitersToGallons = (liters: number) => {
  return liters * 0.264172;
};

export const convertLitersFromGallons = (gallons: number) => {
  return gallons / 0.264172;
};

export const decodeUnicodeString = (str: string) => {
  return str.replace(/\\u([\d\w]{4})/gi, (match, grp) => String.fromCharCode(parseInt(grp, 16)));
};
