import { Button } from '@mui/material';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useBlockUserMutation } from 'services/profile/v2/useBlockUserMutation';
import { useUnblockUserMutation } from 'services/profile/v2/useUnblockUserMutation';
import { useClasses } from 'utils/hooks/useClasses';

const styles = () => ({
  button: {
    fontSize: 12,
    borderRadius: 32,
    width: 120,
  },
});

interface UnblockButtonType {
  userId: string;
}

export const UnblockButton: ComponentType<UnblockButtonType> = ({ userId }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const [blockState, setBlockState] = useState(true);

  const mutationUnblock = useUnblockUserMutation();
  const mutationBlock = useBlockUserMutation();

  const handler = async () => {
    if (!blockState) {
      await mutationBlock.mutate(userId, {
        onSuccess(data, variables, context) {
          if (data.message === 'success') {
            setBlockState(!blockState);
            toast(t('block_user_success'));
          }
        },
      });
    } else {
      await mutationUnblock.mutate(userId, {
        onSuccess(data, variables, context) {
          if (data.message === 'success') {
            setBlockState(!blockState);
            toast(t('unblock_user_success'));
          }
        },
      });
    }
  };

  return (
    <Button
      className={classes.button}
      color={blockState ? 'error' : 'neutral'}
      variant='contained'
      size='small'
      onClick={handler}
    >
      {blockState ? t('__common__unblock') : t('__common__block')}
    </Button>
  );
};
