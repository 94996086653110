import uk from 'assets/landing-page/uk.svg';
import ukraine from 'assets/landing-page/ukraine.svg';
import spain from 'assets/landing-page/spain.svg';

import { ChangeEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'store2';
import { originalLanguage } from 'features/stories-feed/StoriesTypes';

type Language = { value: string; label: string; src?: any };
export const languageOptions: Language[] = [
  { value: 'uk', label: 'Українська', src: ukraine },
  { value: 'en', label: 'English', src: uk },
  { value: 'es', label: 'Español', src: spain },
];
export const languageName: Record<string, string> = { uk: 'Українська', en: 'English', es: 'Español' };
export const languageIcon: Record<string, string> = { uk: ukraine, en: uk, es: spain };

type useChangeLanguageData = () => {
  chosenLanguage: string;
  changeLanguage: ChangeEventHandler<any>;
};
export const useChangeLanguage: useChangeLanguageData = () => {
  const { i18n } = useTranslation();
  const getLang = (): originalLanguage => {
    const browserLang = window.navigator.language;
    if (browserLang === 'ru' || browserLang === 'uk') {
      return 'uk';
    }
    if (browserLang === 'es') {
      return 'es';
    }
    return 'en';
  };

  const lang = getLang();

  const [chosenLanguage, setChosenLanguage] = useState(store('driverbook_language') ?? i18n.language);

  const changeLanguage: ChangeEventHandler<any> = (event): void => {
    const { value } = event.target;

    //@ts-ignore
    gtag?.('event', 'language_changed', { language: value });

    setChosenLanguage(value);
    i18n.changeLanguage(value as string);
    store('driverbook_language', value);
  };

  useEffect(() => {
    if (lang && !store('driverbook_language')) {
      //@ts-ignore
      changeLanguage({ target: { value: lang } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    chosenLanguage,
    changeLanguage,
  };
};
