import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

export type newVersionPayload = {
  version: string;
};

const sendNewVersion = async (payload: newVersionPayload): Promise<any> => {
  try {
    const { data } = await api.post(`/engineroom/new-version`, payload)
    return data;
  } catch {
    return { error: true };
  }
};

export const useSendNewVersionMutation = () => {
  return useMutation(sendNewVersion);
};
