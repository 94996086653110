import { IComment } from 'features/stories-feed/StoriesTypes';
import { ComponentType } from 'react';
import { CommentItem } from './CommentItem';

interface CommentsListType {
  carId: string;
  storyId: string;
  comments: IComment[];
  authorized: boolean;
  list?: string;
}

export const CommentsList: ComponentType<CommentsListType> = ({ carId, storyId, comments, authorized, list }) => {
  return (
    <>
      {comments.map((comment) => {
        return (
          <CommentItem
            key={comment.id}
            comment={comment}
            carId={carId}
            storyId={storyId}
            authorized={authorized}
            list={list}
          />
        );
      })}
    </>
  );
};
