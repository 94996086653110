import { useMutation } from 'react-query';

import { Car } from 'types';
import { axiosWithFileInstance } from 'utils';

import { carMutationOptions } from '../carMutationOptions';

export type UpdateCarPayload = { id: string, formData: any };

const updateCar = async ({ id, formData }: UpdateCarPayload): Promise<Car> => {
  const { data } = await axiosWithFileInstance.put(`/cars/${id}`, formData);
  return data;
};

export const useUpdateCarMutation = () => {
  return useMutation(updateCar, carMutationOptions);
};
