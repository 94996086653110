import { useEffect, useState } from 'react';
import { ImageListType } from 'react-images-uploading';

import { Car } from 'types';
import { getDataBlob } from 'utils';

export const useCarFormImage = (defaultValues: Car | undefined) => {
  const [images, setImages] = useState<ImageListType>([]);

  useEffect(() => {
    const createFileFromUrl = async () => {
      if (defaultValues && defaultValues.image) {
        const result = await getDataBlob(defaultValues.image as string);
        const preparedList = [{ dataURL: defaultValues.image as string, file: result }];

        setImages(preparedList as any);
      }
    };

    createFileFromUrl().catch(console.error);
  }, [defaultValues]);

  const onImageChange = (imageList: ImageListType) => {
    setImages(imageList);
  };

  return {
    images,
    onImageChange,
  };
};
