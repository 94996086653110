import { useTranslation } from 'react-i18next';
import store from 'store2';

const COUNTRY_STORE_KEY = 'assistantCountry';
const DEFAULT_COUNTRY = 'es';

interface assistantLanguage {
  flagKey: string;
  code: string;
  labelKey: string;
  languageCodes: string[]; // Add language codes for each country
}

export const AssistantCountries: assistantLanguage[] = [
  {
    flagKey: 'Spain',
    code: 'es',
    labelKey: '__county__sp',
    languageCodes: ['es', 'es-ES'],
  },
  {
    flagKey: 'United Kingdom',
    code: 'gb',
    labelKey: '__county__uk',
    languageCodes: ['en-GB', 'en'],
  },
  {
    flagKey: 'Ukraine',
    code: 'uk',
    labelKey: '__county__ua',
    languageCodes: ['uk', 'ru-UA'],
  },
];

// Dynamically generate a union type from the 'code' property of the array
export type AssistantCountryCode = typeof AssistantCountries[number]['code'];

// Helper function to find country by i18lang
const findCountryByLanguage = (i18lang: string): string => {
  const country = AssistantCountries.find((lang) => lang.languageCodes.includes(i18lang));
  return country ? country.code : DEFAULT_COUNTRY;
};

const isValidAssistantCountry = (code: string): code is AssistantCountryCode => {
  return AssistantCountries.some((country) => country.code === code);
};

export const useAssistantCountry = (): string => {
  const { i18n } = useTranslation();

  const storedCountry = store(COUNTRY_STORE_KEY);
  if (storedCountry && isValidAssistantCountry(storedCountry)) {
    return storedCountry;
  }

  if (!isValidAssistantCountry(storedCountry)) {
    store.remove(COUNTRY_STORE_KEY);
  }

  // Use normalized i18next language code
  const currentLanguage = i18n.language || navigator.language;
  const countryCode = findCountryByLanguage(currentLanguage);

  // Store the resolved country code for future use
  store(COUNTRY_STORE_KEY, countryCode);
  return countryCode;
};

export const changeAssistantCounty = (county: string): void => {
  store(COUNTRY_STORE_KEY, county);
};

export const getFlagByCode = (code: string) => {
  const language = AssistantCountries.find((lang) => lang.code === code);

  if (language) {
    return language.flagKey;
  }
  return '';
};

export const getLabelByCode = (code: string) => {
  const language = AssistantCountries.find((lang) => lang.code === code);

  if (language) {
    return language.labelKey;
  }
  return '';
};

export const cleanText = (text: string) => {
  return text
    .replace(/【.+†source】/g, '')
    .replace(/【\d+:\d+†source】/g, '')
    .replace(/^thread_[\w\d]+/, '');
};
