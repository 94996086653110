import { Theme, Typography } from '@mui/material';
import { InnerMobileHeader, LeanLayout, Wrapper } from 'components';
import { useTranslation } from 'react-i18next';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  email: { color: palette.additionalPrimary[200] },
  container: {
    backgroundColor: 'rgba(42, 42, 42)',
    padding: spacing(3),
    borderRadius: 10,
  },
  listNone: {
    listStyle: 'none',
  },
});

const tKey = getTKey('privacy_policy');
export const PrivacyPolicy = () => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  return (
    <LeanLayout>
      <InnerMobileHeader title=' ' showBack />
      <Wrapper>
        <div className={classes.container}>
          <Typography variant='h5' align='center'>
            {t(tKey('heading'))}
          </Typography>
          <br />
          <Typography variant='body1'>{t(tKey('heading_description'))}</Typography>
          <br />
          <Typography variant='h6'>{t(tKey('data_collected'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('data_collected_1'))}</Typography>
          <ul>
            <li>
              <Typography variant='body1'>{t(tKey('data_collected_2'))}</Typography>
            </li>
            <li>
              <Typography variant='body1'>{t(tKey('data_collected_3'))}</Typography>
            </li>
            <li>
              <Typography variant='body1'>{t(tKey('data_collected_4'))}</Typography>
            </li>
          </ul>

          <br />
          <Typography variant='h6'>{t(tKey('purposes_of_data_collection'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('purposes_of_data_collection_1'))}</Typography>
          <ul>
            <li>
              <Typography variant='body1'>{t(tKey('purposes_of_data_collection_2'))}</Typography>
            </li>
            <li>
              <Typography variant='body1'>{t(tKey('purposes_of_data_collection_3'))}</Typography>
            </li>
            <li>
              <Typography variant='body1'>{t(tKey('purposes_of_data_collection_4'))}</Typography>
            </li>
          </ul>

          <br />
          <Typography variant='h6'>{t(tKey('legal_basis_for_data_processing'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('legal_basis_for_data_processing_1'))}</Typography>
          <ul>
            <li>
              <Typography variant='body1'>{t(tKey('legal_basis_for_data_processing_2'))}</Typography>
            </li>
            <li>
              <Typography variant='body1'>{t(tKey('legal_basis_for_data_processing_3'))}</Typography>
            </li>
            <li>
              <Typography variant='body1'>{t(tKey('legal_basis_for_data_processing_4'))}</Typography>
            </li>
          </ul>

          <br />
          <Typography variant='h6'>{t(tKey('data_sharing'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('data_sharing_1'))}</Typography>
          <ul>
            <li>
              <Typography variant='body1'>{t(tKey('data_sharing_2'))}</Typography>
            </li>
            <li>
              <Typography variant='body1'>{t(tKey('data_sharing_3'))}</Typography>
            </li>
          </ul>

          <br />
          <Typography variant='h6'>{t(tKey('data_transfers'))}</Typography>
          <br />

          <ul className={classes.listNone}>
            <li>
              <Typography variant='body1'>{t(tKey('data_transfers_1'))}</Typography>
            </li>
          </ul>

          <br />
          <Typography variant='h6'>{t(tKey('data_retention'))}</Typography>
          <br />

          <ul className={classes.listNone}>
            <li>
              <Typography variant='body1'>{t(tKey('data_retention_1'))}</Typography>
            </li>
          </ul>

          <br />
          <Typography variant='h6'>{t(tKey('user_rights'))}</Typography>
          <br />

          <Typography variant='body1'>{t(tKey('user_rights_1'))}</Typography>
          <ul className={classes.listNone}>
            <li>
              <Typography variant='body1'>{t(tKey('user_rights_2'))}</Typography>
            </li>
            <br />
            <li>
              <Typography variant='body1'>{t(tKey('user_rights_3'))}</Typography>
            </li>
            <br />
            <li>
              <Typography variant='body1'>{t(tKey('user_rights_4'))}</Typography>
            </li>
            <br />
            <li>
              <Typography variant='body1'>{t(tKey('user_rights_5'))}</Typography>
            </li>
            <br />
            <li>
              <Typography variant='body1'>{t(tKey('user_rights_6'))}</Typography>
            </li>
            <br />
            <li>
              <Typography variant='body1'>{t(tKey('user_rights_7'))}</Typography>
            </li>
            <br />
            <li>
              <Typography variant='body1'>{t(tKey('user_rights_8'))}</Typography>
            </li>
          </ul>

          <br />
          <Typography variant='h6'>{t(tKey('data_security'))}</Typography>
          <br />

          <ul className={classes.listNone}>
            <li>
              <Typography variant='body1'>{t(tKey('data_security_1'))}</Typography>
            </li>
            <br />
            <li>
              <Typography variant='body1'>{t(tKey('data_security_2'))}</Typography>
            </li>
            <br />
            <li>
              <Typography variant='body1'>
                {t(tKey('data_security_3_1'))}{' '}
                <a className={classes.email} href='mailto:support@driverbook.io'>
                  support@driverbook.io
                </a>
                . {t(tKey('data_security_3_2'))}
              </Typography>
            </li>
            <br />
            <li>
              <Typography variant='body1'>
                {t(tKey('data_security_4_1'))}{' '}
                <a className={classes.email} href='mailto:support@driverbook.io'>
                  support@driverbook.io
                </a>
                {t(tKey('data_security_4_2'))}
              </Typography>
            </li>
            <br />
            <li>
              <Typography variant='body1'>{t(tKey('data_security_5'))}</Typography>
            </li>
            <br />
            <li>
              <Typography variant='body1'>{t(tKey('data_security_6'))}</Typography>
            </li>
            <br />
            <li>
              <Typography variant='body1'>{t(tKey('data_security_7'))}</Typography>
            </li>
            <br />
            <li>
              <Typography variant='body1'>
                {t(tKey('data_security_8_1'))}{' '}
                <a className={classes.email} href='mailto:support@driverbook.io'>
                  support@driverbook.io
                </a>
                . {t(tKey('data_security_8_2'))}
              </Typography>
            </li>
          </ul>
          <br />
          <Typography variant='body1'>{t(tKey('thanks'))}</Typography>
        </div>
      </Wrapper>
    </LeanLayout>
  );
};
