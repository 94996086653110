import { Box, FormLabel, Grid, Theme } from '@mui/material';
import { isEqual } from 'lodash';
import { ComponentType, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import store from 'store2';
import * as yup from 'yup';

import { Form, FormRadio, StyledButton, queryClient } from 'components';
import { FormToggle } from 'components/Form/FormToggle';
import { useProfileSettingsMutation, useRecordsQuery } from 'services';
import { UserSettings, Volume } from 'types';
import { getTKey, yupStringValidator } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { VolumeChangeDialog } from './VolumeChangeDialog';

const tKey = getTKey('settings_date_metrics');

const dateFormatOptions = [
  { id: 0, label: 'dd/mm/yyyy', value: 'dd/mm/yyyy' },
  { id: 1, label: 'mm/dd/yyyy', value: 'mm/dd/yyyy' },
];

const volumeOptions = [
  { id: 0, value: 'l', label: '__liters' },
  { id: 1, value: 'g', label: '__gallons' },
];
export interface UpdateVolumePayload {
  fromVolume: Volume;
  toVolume: Volume;
}

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  column: {
    marginBottom: spacing(2),

    [breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
  formGroup: {
    padding: 0,
    width: '100%',
  },
  radioStyle: {
    justifyContent: 'space-between',
    margin: spacing(2, 0),
    width: '100%',
  },
  divider: {
    margin: spacing(2, 0),
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
  },
  subHeading: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 10,
    marginBottom: spacing(2),
  },
});

const validationSchema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    date_format: yupStringValidator(t),
    update_mileage_from_record_and_story: yup.boolean().required(),
    volume_metric: yupStringValidator(t),
  });

const DateMetricFormContent: ComponentType<{ values: UserSettings; onSubmit: (data: UserSettings) => void }> = ({
  values,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const { getValues, setValue, watch } = useFormContext();
  const volumeWatch = watch('volume_metric');
  const defaultVolume = values.volume_metric as Volume;
  const [showVolumeDialog, setShowVolumeDialog] = useState<boolean>(false);
  const [currentVolume, setCurrentVolume] = useState<Volume>(defaultVolume);
  const { data, isLoading } = useRecordsQuery();
  const userHasRecords = !isLoading && data;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userHasRecords && currentVolume !== volumeWatch) {
      setShowVolumeDialog(true);
    }

    if (!userHasRecords && currentVolume !== volumeWatch) {
      setValue('volume_metric', volumeWatch);
      setCurrentVolume(volumeWatch);
    }
  }, [currentVolume, volumeWatch, userHasRecords]); // eslint-disable-line react-hooks/exhaustive-deps

  const cancelVolumeHandler = (): void => {
    setValue('volume_metric', currentVolume);
    setCurrentVolume(currentVolume);
    setShowVolumeDialog(false);
  };

  const confirmVolumeHandler = (data: UpdateVolumePayload): void => {
    const newVolume = data.toVolume;
    setValue('volume_metric', newVolume);
    setCurrentVolume(newVolume);

    setLoading(true);
    onSubmit(getValues() as UserSettings);
  };

  return (
    <>
      {showVolumeDialog && (
        <VolumeChangeDialog
          from={currentVolume as Volume}
          to={volumeWatch}
          loading={loading}
          onClose={cancelVolumeHandler}
          onCancel={cancelVolumeHandler}
          onConfirm={confirmVolumeHandler}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.column}>
          <FormLabel className={classes.subHeading}>{t(tKey('date_format'))}</FormLabel>
          <FormRadio
            formControlLabelProps={{ labelPlacement: 'start', className: classes.radioStyle }}
            name='date_format'
            options={dateFormatOptions}
            defaultValue={values.date_format}
            controlClass={classes.formGroup}
          />
        </Grid>

        <Grid item xs={12}>
          <FormLabel className={classes.subHeading}>{t(tKey('volume'))}</FormLabel>
          <FormRadio
            formControlLabelProps={{ labelPlacement: 'start', className: classes.radioStyle }}
            name='volume_metric'
            options={volumeOptions}
            controlClass={classes.formGroup}
            optionsWithTranslations
          />
        </Grid>

        <Grid item xs={12}>
          <FormLabel className={classes.subHeading}>{t(tKey('mileage'))}</FormLabel>
          <FormToggle
            name='update_mileage_from_record_and_story'
            label={t(tKey('mileage_hint'))}
            controlClass={classes.formGroup}
            formControlLabelProps={{ labelPlacement: 'start', className: classes.radioStyle }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel className={classes.subHeading}>{t(tKey('stories'))}</FormLabel>
          <FormToggle
            name='is_story_audio_background_music_on'
            label={t(tKey('story_audio_background_music_hint'))}
            controlClass={classes.formGroup}
            formControlLabelProps={{ labelPlacement: 'start', className: classes.radioStyle }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box textAlign='center' mt={4} mb={4}>
            <StyledButton label={t(tKey('submit'))} type='submit' />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export const DateMetricForm: ComponentType<{ preloadedValues: UserSettings }> = ({ preloadedValues }) => {
  const { t } = useTranslation();
  const mutation = useProfileSettingsMutation();

  const handleSettingsSubmit = (data: UserSettings) => {
    if (!isEqual(preloadedValues, data)) {
      mutation.mutate(data, {
        onSuccess(res) {
          if (res?.error) {
            console.warn('Something went wrong');
          } else {
            queryClient.invalidateQueries('userSettings');
            store('driverbook_settings', data);
            toast(t(tKey('success_message')));
          }
        },
      });
    }
  };

  return (
    <Form onSubmit={handleSettingsSubmit} schema={validationSchema(t)} defaultValues={preloadedValues}>
      <DateMetricFormContent values={preloadedValues} onSubmit={handleSettingsSubmit} />
    </Form>
  );
};
