import { Grid } from "@mui/material";
import { ComponentType } from "react";
import { PublicCar } from "types";
import { VehicleItem } from "./VehicleItem";

interface VehiclesListType {
  vehicles: PublicCar[];
  className?: string,
}

export const VehiclesList: ComponentType<VehiclesListType> = ({vehicles, className}) => {

  vehicles = vehicles.sort((a, b) => {
    if (a.image === "" && b.image === "") {
      return 0;
    } else if (a.image === "") {
      return 1;
    } else if (b.image === "") {
      return -1;
    } else {
      return 0;
    }
  });

  return (
    <Grid container className={className} spacing={2} justifyContent='left'>
      {vehicles.map((vehicle,i) => {
        return (
          <Grid item xs={12} sm={6} key={i}>
            <VehicleItem vehicle={vehicle}/>
          </Grid>
        )
      })}
    </Grid>
  )
}