import { IItem } from "../components/AdminReview/AdminItemTypes"
import { useStoriesQuery } from "services/admin/hooks/useStoriesQuery";
import { filterItemDate, filterItemReportsOrder, filterItemStatus } from "../components/AdminDashboardTypes";

export type filtersType = {
    status: filterItemStatus,
    date: filterItemDate,
    reportsOrder: filterItemReportsOrder,
}

export type paginationType = {
    page: number,
    perPage: number,
}

type responseStoriesType =  {
    current_page: number,
    pages: number,
    perPage: number
    stories: IItem[],
    total: number,
};

type AdminFilteredStoriesType = (
    filters: filtersType,
    pagination: paginationType
) => {
    data: responseStoriesType;
    isFetching: boolean;
    refetch: () => void
}

export const AdminFilteredStories: AdminFilteredStoriesType = (
    filters,
    pagination
)=>{
    const {data, isFetching, refetch } = useStoriesQuery({...filters, ...pagination}, {});

    return {
        data,
        isFetching,
        refetch
    }
}