import { Box, Theme } from '@mui/material';
import { useClasses } from 'utils/hooks/useClasses';
const styles = ({ palette, breakpoints, spacing }: Theme) => ({
  recordLeft: {
    background: 'rgba(255, 203, 64, 0.1)',
    borderRadius: 40,
    minHeight: 33,
    padding: spacing(1, 2),
    margin: spacing(2, 0, 1),
    width: '100%',
  },
  upload: {
    width: '100&',
    height: 100,
    borderRadius: 5,
    background: 'rgba(200, 200, 200, 0.1)',
    marginTop: spacing(4),
  },
});
export const InvoiceFormLoader = () => {
  const classes = useClasses(styles);
  return (
    <Box>
      <Box className={classes.recordLeft}></Box>
      <Box className={classes.upload}></Box>
    </Box>
  );
};
