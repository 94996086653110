// import React, { ComponentType, useEffect } from 'react';
// import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { isLoggedIn } from 'utils';

// interface PrivateRouteProps {
//   component: ComponentType;
// }

// export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component }) => {
//   const location = useLocation();
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (!isLoggedIn()) {
//       navigate('/login', { replace: true, state: { from: location } });
//       return;
//     }
//   }, [location, navigate]);

//   return <Component />;
// };

import { Navigate, Outlet } from 'react-router-dom';

export const PrivateRoute = () => {
  return isLoggedIn() ? <Outlet /> : <Navigate to='/login' />;
};
