import { Divider, FormControl, FormControlLabel, FormControlLabelProps, FormGroup, Switch, Theme } from '@mui/material';
import { ComponentType } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useClasses } from 'utils/hooks/useClasses';

interface FormRadioProps {
  name: string;
  label: string;
  defaultValue?: boolean;
  formControlLabelProps?: Partial<FormControlLabelProps>;
  controlClass?: any;
}

const styles = ({ spacing }: Theme) => ({
  divider: {
    margin: spacing(1, 0),
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
  },
});
export const FormToggle: ComponentType<FormRadioProps> = ({ name, label, controlClass, formControlLabelProps }) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  const classes = useClasses(styles);

  return (
    <FormControl component='fieldset' className={controlClass}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <FormGroup {...field}>
              <FormControlLabel
                {...formControlLabelProps}
                control={<Switch color='primary' defaultChecked={field.value} />}
                label={t(label)}
              />
            </FormGroup>
          );
        }}
      />

      <Divider className={classes.divider} />
    </FormControl>
  );
};
