import { useQuery } from 'react-query';
import store from 'store2';

import { axiosApiInstance as api } from 'utils';
import { StoryDraft } from 'utils/trasformDraft';

export const getAllDrafts = async (carId: string): Promise<StoryDraft> => {
  const { data } = await api.get(`/cars/${carId}/stories/draft`);
  if (data?.id && data?.elements) {
    store.each(function (value, key: any) {
      if (String(key).startsWith('draft_images_')) {
        store.remove(key);
      }
    });
  }
  return data;
};

export const useDraftQuery = (carId: string, carSelected: boolean) => {
  return useQuery('allDrafts', () => getAllDrafts(carId), { enabled: carSelected });
};
