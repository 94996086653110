import { ComponentType } from "react";
import { IStory } from "../StoriesTypes";
import { StoryItem } from "./StoryItem";

interface StoriesListType {
  stories: IStory[],
  authorized: boolean
  openUnregisteredDialog?: () => void,
}

export const StoriesList: ComponentType<StoriesListType> = ({stories, authorized, openUnregisteredDialog})=>{

  
  return (
    <> 
      {stories.map(story=>{
        return (
          <StoryItem key={story.id} story={story} authorized={authorized} openUnregisteredDialog={openUnregisteredDialog}/>
        )
      })}
    </>
   
  )
}