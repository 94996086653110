import { Theme } from '@mui/material';
import clsx from 'clsx';
import { FollowButton } from 'components';
import dayjs from 'dayjs';
import { ComponentType } from 'react';
import { useNavigate } from 'react-router-dom';
import { countryFlags } from 'utils/country-flags';
import { useClasses } from 'utils/hooks/useClasses';
import { IUser } from '../StoriesTypes';
import { UserPicture } from './UserPicture';
import { UnblockButton } from 'components/UnblockButton/UnblockButton';
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const styles = ({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    maxWidth: '100vw',
    margin: spacing(1, 0),
    padding: spacing(1),
    borderRadius: 4,
    border: '1px solid rgba(255, 255, 255, 0.08)',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%), #121212',
    display: 'flex',
    flexDirection: 'row',
  },
  userWrap: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  gap: {
    flex: 1,
  },
  userDataWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  user: {
    flex: 3,
    fontSize: 16,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
  },
  flag: {
    marginLeft: spacing(0.5),
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
  },
  actionWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: spacing(1),
  },
  patron: {
    color: '#FFCB40',
  },
});

interface UserItemType {
  user: IUser;
  follow?: boolean;
  block?: boolean;
}

export const UserItem: ComponentType<UserItemType> = ({ user, follow, block }) => {
  const classes = useClasses(styles);
  const navigate = useNavigate();

  const goToUser = () => {
    navigate(`/user/${user.id}`);
  };

  return (
    <div className={classes.root}>
      <div className={classes.userWrap} onClick={goToUser}>
        <UserPicture user={user} />
        <div className={classes.userDataWrap}>
          <div className={clsx(classes.user, user.is_patron && classes.patron)}>
            {user.username}
            {user.country !== null && (
              <div className={classes.flag}>{user?.country ? countryFlags[user?.country] : ''}</div>
            )}
          </div>
        </div>
      </div>
      <div className={classes.gap}></div>
      <div className={classes.actionWrapper}>
        {follow && <FollowButton followed={user.already_following || false} userId={user.id} />}
        {block && <UnblockButton userId={user.id} />}
      </div>
    </div>
  );
};
