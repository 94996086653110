import { ComponentType, createContext, Dispatch, useReducer } from 'react';

import profileReducer, { State as ProfileState, initialState as profileInitialState } from './slice';

export interface Action {
  type: string;
  payload?: any;
}

// type ProfileReducer = (state: ProfileState, action: Action) => ProfileState;

const init = () => {
  return profileInitialState;
};

export type ProfileContextType = {
  state: ProfileState;
  dispatch: Dispatch<Action>;
};

export const ProfileContext = createContext({} as ProfileContextType);

const ProfileProvider: ComponentType = ({ children }) => {
  const [state, dispatch] = useReducer(profileReducer, profileInitialState, init);

  return <ProfileContext.Provider value={{ state, dispatch }}>{children}</ProfileContext.Provider>;
};

export default ProfileProvider;
