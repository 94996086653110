import { Theme } from '@mui/material';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useClasses } from 'utils/hooks/useClasses';

interface SearchSelectProps {
  name: string;
  options: any;
  placeholder: string;
  disabled?: boolean;
  onChange?: any;
  label?: string;
  labelBackground?: string;
}

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    marginBottom: 24,
  }),
  control: (provided: any) => ({
    ...provided,
    display: 'flex',
    minHeight: 56,
    backgroundColor: 'transparent',
    border: '1px solid rgba(255, 255, 255, 0.8)',
    borderRadius: 4,
  }),
  input: (provided: any) => ({
    ...provided,
    color: 'white',
  }),
  groupHeading: (provided: any) => ({
    ...provided,
    color: 'white',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 5,
    backgroundColor: '#282828',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: '1rem',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '1rem',
  }),
  option: (provided: any) => ({
    ...provided,
    backgroundColor: 'inherit',

    '&:hover': {
      backgroundColor: '#121212',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

const labelStyles = ({ spacing, palette }: Theme) => ({
  wrapSelect: {
    position: 'relative',
  },
  label: {
    fontSize: 11,
    position: 'absolute',
    transition: '0.2s ease',
  },
  labelUp: {
    top: -8,
    left: 12,
    color: palette.primary.main,
    padding: '0 5px',
  },
  labelDefault: {
    top: 17,
    left: 12,
    opacity: 0,
  },
});

export const SearchSelect: ComponentType<SearchSelectProps> = ({
  name,
  options,
  placeholder,
  onChange,
  disabled = false,
  label,
  labelBackground,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const classes = useClasses(labelStyles);
  return (
    <Controller
      render={({ field }) => (
        <div className={classes.wrapSelect}>
          <Select
            {...field}
            isDisabled={disabled}
            isClearable
            options={options}
            isSearchable
            styles={customStyles}
            onChange={(event) => {
              field.onChange(event);
              onChange?.();
            }}
            placeholder={t(`search_select_placeholder_${placeholder}`)}
          />
          {label && (
            <label
              style={{ background: labelBackground }}
              className={clsx(classes.label, [field.value ? classes.labelUp : classes.labelDefault])}
            >
              {label}
            </label>
          )}
        </div>
      )}
      name={name}
      control={control}
    />
  );
};
