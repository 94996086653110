import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';
const primaryColor = '#bb86fc';
const grayColor = 'rgba(255, 255, 255, 0.6)';
declare module '@mui/material/styles/createPalette' {
  interface TypeText {
    hint: string;
  }

  interface Palette {
    additionalPrimary: {
      '50': string;
      '100': string;
      '200': string;
      '300': string;
      '400': string;
      '500': string;
      '600': string;
      '700': string;
      '800': string;
      '900': string;
    };
    additionalSecondary: {
      '50': string;
      '100': string;
      '200': string;
      '300': string;
      '400': string;
      '500': string;
      '600': string;
      '700': string;
      '800': string;
      '900': string;
    };
  }

  interface PaletteOptions {
    additionalPrimary?: {
      '50'?: string;
      '100'?: string;
      '200'?: string;
      '300'?: string;
      '400'?: string;
      '500'?: string;
      '600'?: string;
      '700'?: string;
      '800'?: string;
      '900'?: string;
    };
    additionalSecondary?: {
      '50'?: string;
      '100'?: string;
      '200'?: string;
      '300'?: string;
      '400'?: string;
      '500'?: string;
      '600'?: string;
      '700'?: string;
      '800'?: string;
      '900'?: string;
    };
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    neutral: {
      main: '#C4C4C4',
      contrastText: '#444A5E',
    },
    inert: {
      main: '#A8A6AB',
      contrastText: '#444A5E',
    },
    primary: {
      main: primaryColor,
      dark: '#3700b3',
    },
    secondary: {
      main: '#03dac5',
    },
    additionalPrimary: {
      '50': '#f2e7fe',
      '100': '#dbb2ff',
      '200': '#bb86fc',
      '300': '#985eff',
      '400': '#7f39fb',
      '500': '#6200ee',
      '600': '#5600e8',
      '700': '#3700b3',
      '800': '#30009c',
      '900': '#23036a',
    },
    additionalSecondary: {
      '50': '#c8fff4',
      '100': '#70efde',
      '200': '#03dac5',
      '300': '#00c4b4',
      '400': '#00b3a6',
      '500': '#01a299',
      '600': '#019592',
      '700': '#018786',
      '800': '#017374',
      '900': '#005457',
    },
    background: {
      default: '#121212',
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.87)',
      hint: grayColor,
    },
    warning: {
      main: 'rgba(255, 80, 80, 1)',
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
      opacity: 0.4,
    },
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        filled: {
          color: 'rgba(0, 0, 0, 0.6)',
          backgroundColor: 'rgba(255, 255, 255, 0.87)',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          // color: 'rgba(255, 255, 255, 0.87)',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: grayColor,
        },
        filled: {
          color: primaryColor,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: grayColor,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: 'rgba(202, 196, 208, 0.7)',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: grayColor,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: grayColor,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: grayColor,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: '#121212',
        },
        paper: {
          maxHeight: 360,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: '#121212',
          color: '#fff',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: grayColor,
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        icon: {
          color: grayColor,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperFullScreen: {
          paddingTop: 'env(safe-area-inset-top)',
          paddingBottom: 'env(safe-area-inset-bottom)',
        },
        paper: {
          // backgroundColor: 'rgba(255, 255, 255, 0.12)',
          backgroundColor: '#2a2a2a',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'rgba(255, 255, 255, 0.74)',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: '#999999',
        },
        track: {
          background: '#616161',
        },

        root: {
          '&.Mui-checked': {
            color: primaryColor,
          },
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          backgroundColor: '#414141',
          '& .MuiTypography-caption': {
            color: 'lightgray',
          },
          '& .MuiButtonBase-root': {
            color: 'lightgray',
          },
          '& .MuiPickersToolbar-root': {
            background: '#BB86FC',
          },
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: '#BB86FC',
          '& .MuiTypography-root': {
            color: '#414141',
          },
        },
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          '& .MuiDialogActions-root': {
            background: '#414141',
          },
        },
      },
    },
  },
});
declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
    inert: Palette['primary'];
  }

  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
    inert?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    inert: true;
  }
}

declare module '@mui/material/Icon' {
  interface IconPropsColorOverrides {
    neutral: true;
    inert: true;
  }
}
