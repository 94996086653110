import { ComponentType } from "react"
import { Alert } from "./AlertTypes"
import { AlertItem } from "./AlertItem"

interface AlertSubListType { 
  alerts: Alert[] 
}
export const AlertSubList: ComponentType<AlertSubListType> = ({alerts}) => {

  return (
    <>
      {alerts.map(alert=>{
        return(
          <div key={alert.id}>
            <AlertItem alert={alert}/>
          </div>
        )
      })}
    </>
  ) 
} 