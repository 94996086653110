import { useQuery, UseQueryResult } from 'react-query';

import { axiosApiInstance as api } from 'utils';

const getLocations = async (): Promise<string[] | { error?: boolean }> => {
  try {
    const { data } = await api.get('data/countries');

    return data;
  } catch (err) {
    return { error: true };
  }
};

export const useLocations = (): UseQueryResult<string[]> => {
  return useQuery(['locations'], () => getLocations());
};
