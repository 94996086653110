import { CommonLayout, PageLoader } from 'components';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const SiriVoiceToRecordPage = () => {
  const navigate = useNavigate();
  const { carId } = useParams<{ carId: string }>();

  useEffect(() => {
    navigate(`/records/${carId}/voice`, { state: { siri: true } });
  });

  return (
    <CommonLayout>
      <PageLoader />
    </CommonLayout>
  );
};
