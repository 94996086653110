import { Box, Divider } from '@mui/material';
import { ComponentType } from 'react';
import { NewReminderValues } from 'types';
import { ReminderForm } from './ReminderForm';

interface ReminderAIFormType {
  records: NewReminderValues[];
  onRemove: (id: string) => void;
  aiType: 'vtr';
}

export const RemindersAIForm: ComponentType<ReminderAIFormType> = ({
  records,
  onRemove,
  aiType,
}) => {
  const handleRemove = (id: string) => {
    onRemove(id);
  };
   
  return (
    <Box>
      {records.map((record, i) => {
      
        return (
          <Box key={record.tmpId}>
            <ReminderForm
              defaultValues={record as NewReminderValues}
              key={record.tmpId}
              ai={aiType}
              onRemove={() => handleRemove(record.tmpId as string)}
            />
            {i === records.length - 1 ? (
              <Box sx={{ height: 32 }}></Box>
            ) : (
              <Divider sx={{ margin: '24px 0', borderColor: '#313131' }} />
            )}
          </Box>
        );
      })}
    </Box>
  );
};
