import { useEffect } from 'react';
import store from 'store2';

import { useProfileInfoQuery } from 'services';
import { useTranslation } from 'react-i18next';

export const useInitialLanguage = (): void => {
  const { i18n } = useTranslation();
  const storedLanguage = store('driverbook_language');
  const { data, isFetching } = useProfileInfoQuery();
  const databaseLanguage = data?.language;
  const availableLanguages = i18n.options.whitelist || [];
  const urlParams = new URLSearchParams(window.location.search);
  const paramLanguage = urlParams.get('hl');

  useEffect(() => {
    if ((!isFetching && databaseLanguage !== storedLanguage) || paramLanguage) {
      let newLanguage = databaseLanguage;
      if (paramLanguage && availableLanguages.includes(paramLanguage)) {
        newLanguage = paramLanguage;
      }
      i18n.changeLanguage(newLanguage as string);
      store('driverbook_language', newLanguage);
    }
  }, [isFetching, databaseLanguage, storedLanguage, paramLanguage]); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useInitialLanguageForGuest = (): void => {
  const { i18n } = useTranslation();
  const storedLanguage = store('driverbook_language');

  const availableLanguages = i18n.options.whitelist || [];
  const urlParams = new URLSearchParams(window.location.search);
  const paramLanguage = urlParams.get('hl');

  useEffect(() => {
    if (paramLanguage) {
      let newLanguage = storedLanguage;
      if (paramLanguage && availableLanguages.includes(paramLanguage)) {
        newLanguage = paramLanguage;
      }
      i18n.changeLanguage(newLanguage as string);
      store('driverbook_language', newLanguage);
    }
  }, [storedLanguage, paramLanguage]); // eslint-disable-line react-hooks/exhaustive-deps
};
