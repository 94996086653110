import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';
import { carReminderChangePayload } from './reminderPayloads';

export const completeReminder = async ({ carId, reminderId }: carReminderChangePayload) => {
  const { data } = await api.post(`cars/${carId}/reminders/${reminderId}/complete`);
  return data;
};

export const useCompleteReminderMutation = () => {
  return useMutation(completeReminder);
};
