import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme, Typography } from '@mui/material';
import { StoryPublishContext, initialState, setState } from 'features/stories/hooks/StoryPublishContext';
import { ComponentType, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
const tKey = getTKey('story_publish_dialog');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  progressBar: {
    width: '100%',
    height: 1,
    backgroundColor: 'rgba(3, 218, 197, 0.6)',
    position: 'relative',
    marginBottom: spacing(2),
  },
  progressBarFill: {
    height: 1,
    left: 0,
    position: 'absolute',
    backgroundColor: 'rgba(3, 218, 197, 1)',
  },
});
interface StoryPublishDialogType {
  open: boolean;
  onClose: () => void;
}
export const StoryPublishDialog: ComponentType<StoryPublishDialogType> = ({ open, onClose }) => {
  const { state, dispatch } = useContext(StoryPublishContext);
  const classes = useClasses(styles);
  const { t } = useTranslation();

  useEffect(() => {
    if (!process.env.REACT_APP_PUBLIC_WS_URL_STORY && open === true) {
      setTimeout(() => {
        dispatch(setState({ progress: 15, stage: 'uploading_images' }));
      }, 500);
      setTimeout(() => {
        dispatch(setState({ progress: 35, stage: 'verifying_story_requirements' }));
      }, 3000);
      setTimeout(() => {
        dispatch(setState({ progress: 70, stage: 'translating_texts' }));
      }, 3500);
      setTimeout(() => {
        dispatch(setState({ progress: 90, stage: 'final_adjustments' }));
      }, 4500);
      setTimeout(() => {
        dispatch(setState({ progress: 100, stage: 'completed' }));
      }, 5000);
      setTimeout(() => {
        setTimeout(() => {
          dispatch(setState(initialState));
        }, 3000);
      }, 6000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>{t(tKey('title'))}</DialogTitle>
        <DialogContent>
          {state && (
            <>
              <div className={classes.progressBar}>
                <div className={classes.progressBarFill} style={{ width: `${state?.progress || 0}%` }} />
              </div>
              <Typography variant='body1'>{t(tKey(state?.stage || 'default'))}</Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('__common__cancel')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// 0, "uploading_images"
// 35, "verifying_story_requirements"
// 75, "translating_texts"
// 95, "final_adjustments"
// 100, "completed"
