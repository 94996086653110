import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

const alertRead = async ({ids}: {ids: string[]}): Promise<any> =>{
  try{
    const { data } = await api.post('users/notifications/batch', {notification_ids: ids});
    return data;
  }
  catch (error){
    return { error: true };
  }
}

export const useAlertReadMutation = ()  => {
  return useMutation(alertRead);
}
