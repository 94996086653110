import { Box } from '@mui/material';
import { ComponentType, useEffect } from 'react';

import { SettingsHeader, Wrapper } from 'components';
import { useProfileInfoQuery } from 'services';
import { useNavigate } from 'react-router-dom';

export const SettingsLayout: ComponentType = ({ children }) => {
  const { data, isLoading } = useProfileInfoQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (data && !data?.email_confirmed) {
      navigate('/confirm-your-email', { replace: true });
    }
    if (data?.username === null) {
      navigate('/complete-signup', { replace: true });
    }
  }, [isLoading, data, navigate]);

  return (
    <>
      <Box component={'div'} sx={{ display: { md: 'block', xs: 'none' } }}>
        <SettingsHeader />
      </Box>
      <Wrapper>
        <Box my={4}>{children}</Box>
      </Wrapper>
    </>
  );
};
