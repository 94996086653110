import { Theme } from '@mui/material';
import { StyledButton } from 'components';
import { QRCodeCanvas } from 'qrcode.react';
import { ComponentType } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useClasses } from 'utils/hooks/useClasses';

interface GeneratedLinkProp {
  identifier: string;
  link: string;
  notificationText?: string;
}
const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: spacing(3),
    padding: spacing(2),
    backgroundColor: 'rgba(187, 134, 252, 0.12)',
    borderRadius: spacing(0.5),

    [breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  qrCodeWrapper: {
    marginRight: spacing(4),
    padding: spacing(1),
    backgroundColor: '#fff',
    '& > svg': {
      display: 'block',
    },
    [breakpoints.down('sm')]: {
      marginRight: spacing(0),
    },
  },
  textLink: {
    maxWidth: 'calc(100% - 152px)',
    overflow: 'hidden',
    fontSize: 18,
    wordBreak: 'break-all',
    [breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 10px)',
      fontSize: 14,
      marginBottom: spacing(2),
    },
  },
  btnWrapper: {
    textAlign: 'center',
  },
});

export const GeneratedLink: ComponentType<GeneratedLinkProp> = ({ identifier, link, notificationText }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const textLink = `${process.env.REACT_APP_PUBLIC_API_URL?.replace('/api/', '')}/${link}/${identifier}`;
  const toastMessage = notificationText ?? t('generated_link_success_message');

  return (
    <>
      <div className={classes.root}>
        <div className={classes.qrCodeWrapper}>
          <QRCodeCanvas value={textLink} size={90} />
        </div>
        <div className={classes.textLink}>{textLink}</div>
      </div>
      <div className={classes.btnWrapper}>
        <CopyToClipboard text={textLink} onCopy={() => toast(toastMessage)}>
          <StyledButton label={t('generated_link_copy_link')} />
        </CopyToClipboard>
      </div>
    </>
  );
};
