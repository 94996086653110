import { LikeDislikeResponse, StoryPayload } from 'features/stories-feed/StoriesTypes';
import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

const dislikeStory = async ({ carId, storyId }: StoryPayload): Promise<LikeDislikeResponse> => {
  const { data } = await api.post(`/cars/${carId}/stories/${storyId}/dislike`);
  return data;
};

export const useDislikeStoryMutation = () => {
  return useMutation(dislikeStory);
};
