import { useMutation } from 'react-query';

import { Car } from 'types';
import { axiosWithFileInstance } from 'utils';

import { carMutationOptions } from '../carMutationOptions';

const createCar = async (formData: any): Promise<Car> => {
  const { data } = await axiosWithFileInstance.post('/car', formData);
  return data;
};

export const useCreateCarMutation = () => {
  return useMutation(createCar, carMutationOptions);
};
