import { Theme } from '@mui/material';
import { AutoLoadContent, SpinFeedLoaderMini } from 'components';
import { UnregisteredPopup } from 'components/UnregisteredPopup/UnregisteredPopup';
import { ComponentType, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { useTimezoneQuery } from 'services/profile/hooks/useQueryTimezone';
import { useChangeTimezoneMutation } from 'services/profile/hooks/useTimezone';
import { storyPagination, storyPeriod, userPagination } from 'services/stories';
import { storySearchPagination, useStoriesSearchQuery } from 'services/stories/hooks/useStoriesSearchQuery';
import { useClasses } from 'utils/hooks/useClasses';
import { useStoriesData } from '.././hooks/useStoriesData';
import { originalLanguage } from '../StoriesTypes';
import { useUsersSearchData } from '../hooks/useUsersSearchData';
import { EmptyStories } from './EmptyStories';
import { StoriesList } from './StoriesList';
import { SearchBy, StorySearch } from './StorySearch';
import { StoryTabs } from './StoryTabs';
import { UsersList } from './UsersList';

const styles = ({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    width: '37vw',
    margin: 'auto',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
    [breakpoints.up('sm')]: {
      minWidth: 532,
    },
  },
  emptyUsers: {
    fontWeight: 700,
    margin: spacing(2, 0),
  },
  swipeContainer: {
    height: 'calc(100vh - 216px)',
  },
  bottomSpacer: {
    [breakpoints.down('md')]: {
      height: `calc(${spacing(8)}  + env(safe-area-inset-bottom))`,
    },
    [breakpoints.up('md')]: {
      height: `calc(${spacing(2)}  + env(safe-area-inset-bottom))`,
    },
  },
});

export const StoriesWrapper: ComponentType<{ authorized: boolean; language: originalLanguage }> = ({
  authorized,
  language,
}) => {
  const classes = useClasses(styles);
  const navigate = useNavigate();
  const { period } = useParams<{ period: storyPeriod }>();
  const { pathname } = useLocation();
  const isFollowDefault = pathname.split('/')[2] === 'follow';
  const [searchMode, setSearchMode] = useState<boolean>(false);
  const [userSearchMode, setUserSearchMode] = useState<boolean>(false);
  const { t } = useTranslation();
  const timezoneMutation = useChangeTimezoneMutation();

  const [pagination, setPagination] = useState<storyPagination>({
    language: !authorized ? language : undefined,
    page: 1,
    period: period || 'recent',
    following: isFollowDefault,
  });

  const [paginationSearch, setPaginationSearch] = useState<storySearchPagination>({
    language: !authorized ? language : undefined,
    page: 1,
    perPage: 5,
  });

  const [paginationUsersSearch, setPaginationUsersSearch] = useState<userPagination>({
    page: 1,
    perPage: 5,
    username: '',
  });

  const { data, fetchNextPage, isFetchingNextPage, isLoading, hasNextPage, refetch } = useStoriesData(pagination);
  const { data: timezoneData } = useTimezoneQuery();
  const {
    data: dataSearch,
    fetchNextPage: fetchNextPageSearch,
    isFetchingNextPage: isFetchingNextPageSearch,
    hasNextPage: hasNextPageSearch,
    refetch: refetchSearch,
    isLoading: isLoadingSearch,
  } = useStoriesSearchQuery(paginationSearch);

  const {
    data: dataUserSearch,
    fetchNextPage: fetchNextPageUserSearch,
    isFetchingNextPage: isFetchingNextPageUserSearch,
    isLoading: isLoadingUserSearch,
    refetch: refetchUserSearch,
    hasNextPage: hasNextPageUserSearch,
  } = useUsersSearchData(paginationUsersSearch);

  useEffect(() => {
    setPagination({ ...pagination, language });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  useEffect(() => {
    if (authorized && timezoneData !== undefined) {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

      if (!timezoneData?.timezone) {
        timezoneMutation.mutate({ timezone: timeZone });
      }

      if (timezoneData?.timezone && timezoneData.timezone !== timeZone) {
        timezoneMutation.mutate({ timezone: timeZone });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezoneData, authorized]);

  useEffect(() => {
    if (searchMode) {
      refetchSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationSearch]);

  useEffect(() => {
    if (userSearchMode) {
      refetchUserSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationUsersSearch.username]);

  const onChangeTab = (tab: string) => {
    setSearchMode(false);
    setUserSearchMode(false);
    if (tab === 'follow') {
      setPagination({ ...pagination, following: true });
    } else {
      const period: storyPeriod = tab as storyPeriod;
      setPagination({ ...pagination, period, following: false });
    }
  };

  const onSearchChange = (data: any, type: SearchBy) => {
    if (type === 'story') {
      setSearchMode(true);
      setUserSearchMode(false);
      if (typeof data === 'string') {
        setPaginationSearch({ ...paginationSearch, query: data });
      }
      if (typeof data === 'object') {
        const newPagination: { query?: string; make?: string; model?: string } = {};
        newPagination.query = data.sField;
        newPagination.make = data.make?.value;
        newPagination.model = data.model?.value;
        setPaginationSearch({ ...paginationSearch, ...newPagination });
      }
    }
    if (type === 'user') {
      setSearchMode(false);
      setUserSearchMode(true);
      if (typeof data === 'string') {
        setPaginationUsersSearch({ ...paginationSearch, username: data });
      }
      if (typeof data === 'object') {
        const newPagination: { username?: string } = {};
        if (data.sField) newPagination.username = data.sField;
        setPaginationUsersSearch({ ...paginationUsersSearch, ...newPagination });
      }
    }
  };

  const onReset = () => {
    setSearchMode(false);
    setUserSearchMode(false);
  };

  const [openUnregistered, setOpenUnregistered] = useState<boolean>(false);

  const changeTab = (tab: any) => {
    setPagination({ ...pagination, period: tab === 'follow' ? null : tab, following: tab === 'follow' });
    navigate('/stories/' + tab);
    const event = new CustomEvent('onChangeStoryTab', { detail: tab });
    dispatchEvent(event);
  };

  const handleNext = () => {
    if (pagination.following) {
      changeTab('d');
    } else if (pagination.period === 'recent') {
      changeTab('news');
    } else if (pagination.period === 'news' && authorized) {
      changeTab('follow');
    } else if (pagination.period === 'news' && !authorized) {
      changeTab('d');
    } else if (pagination.period === 'd') {
      changeTab('w');
    } else if (pagination.period === 'w') {
      changeTab('m');
    }
  };

  const handlePrevious = () => {
    if (pagination.period === 'm') {
      changeTab('w');
    } else if (pagination.period === 'w') {
      changeTab('d');
    } else if ((pagination.period === 'd' && !authorized) || pagination.following === true) {
      changeTab('news');
    } else if (pagination.period === 'd' && authorized) {
      changeTab('follow');
    } else if (pagination.period === 'news') {
      changeTab('recent');
    }
  };

  const myRef = useRef();

  const refPassthrough = (el: any) => {
    handlers.ref(el);
    myRef.current = el;
  };

  const handlers = useSwipeable({
    onSwipedRight: () => handlePrevious(),
    onSwipedLeft: () => handleNext(),
  });

  return (
    <div className={classes.root} data-nosnippet>
      <StorySearch onSearchChange={onSearchChange} authorized={authorized} reset={onReset} />
      {!searchMode && !userSearchMode && (
        <StoryTabs
          onChangeTab={onChangeTab}
          authorized={authorized}
          defaultTab={(isFollowDefault && 'follow') || period}
        />
      )}
      {!searchMode && !userSearchMode && (
        <>
          <div {...handlers} ref={refPassthrough} className={classes.swipeContainer}>
            {data?.pages[0]?.stories?.length !== 0 ? (
              data?.pages.map((page) => {
                return (
                  <div key={page.current_page}>
                    <StoriesList
                      stories={page.stories}
                      authorized={authorized}
                      openUnregisteredDialog={() => setOpenUnregistered(true)}
                    />
                  </div>
                );
              })
            ) : (
              <EmptyStories />
            )}
            <SpinFeedLoaderMini loading={isFetchingNextPage || isLoading} />
            <div className={classes.bottomSpacer}></div>
            {hasNextPage && <AutoLoadContent loading={isFetchingNextPage} onScrollToBottom={fetchNextPage} />}
          </div>
        </>
      )}
      {searchMode && (
        <>
          {dataSearch?.pages[0]?.stories?.length !== 0 ? (
            dataSearch?.pages.map((page) => {
              return (
                <div key={page.current_page}>
                  <StoriesList
                    stories={page.stories}
                    authorized={authorized}
                    openUnregisteredDialog={() => setOpenUnregistered(true)}
                  />
                </div>
              );
            })
          ) : (
            <EmptyStories />
          )}
          <SpinFeedLoaderMini loading={isFetchingNextPageSearch || isLoadingSearch} />
          <div className={classes.bottomSpacer}></div>
          {hasNextPageSearch && (
            <AutoLoadContent loading={isFetchingNextPageSearch} onScrollToBottom={fetchNextPageSearch} />
          )}
        </>
      )}
      {userSearchMode && (
        <>
          {dataUserSearch?.pages[0]?.users?.length !== 0 ? (
            dataUserSearch?.pages.map((page) => {
              return (
                <div key={page.current_page}>
                  <UsersList users={page.users} followers={true} />
                </div>
              );
            })
          ) : (
            <div className={classes.emptyUsers}>{t('story_feed_page_no_result_users')}</div>
          )}
          <SpinFeedLoaderMini loading={isFetchingNextPageUserSearch || isLoadingUserSearch} />
          <div className={classes.bottomSpacer}></div>
          {hasNextPageUserSearch && (
            <AutoLoadContent loading={isFetchingNextPageUserSearch} onScrollToBottom={fetchNextPageUserSearch} />
          )}
        </>
      )}
      <UnregisteredPopup
        open={openUnregistered}
        from={pathname}
        onClose={() => {
          setOpenUnregistered(false);
        }}
      />
    </div>
  );
};
