import { useQuery, UseQueryResult } from 'react-query';
import { axiosApiInstance as api } from 'utils';

const getPublicCarVolumeData = async (identifier: string): Promise<any> => {
  try {
    const { data } = await api.get('/book/public', { params: { identifier } });

    return data.volume_metric;
  } catch (err: any) {
    return { error: true };
  }
};

export const usePublicCarVolumeQuery = (identifier: string): UseQueryResult<any> => {
  return useQuery(['publicCarVolumeData'], () => getPublicCarVolumeData(identifier));
};
