import { ComponentType } from 'react';
import { useClasses } from 'utils/hooks/useClasses';
import { Theme } from '@mui/material';
import { Beenhere } from '@mui/icons-material';
import { IUserItem } from './AdminItemTypes';
import ImageUserPlaceholder from 'assets/image-user-placeholder.png';
import { countryFlags } from 'utils/country-flags';
import { CopyToClipboard } from 'components/CopyToClipboard/CopyToClipboard';
import { useNavigate } from 'react-router-dom';

const styles = ({ palette, spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  userProfileImageWrapper: {
    width: 40,
    minWidth: 40,
    height: 40,
    position: 'relative',
    marginRight: spacing(1),
    overflow: 'hidden',

    '& img': {
      borderRadius: 20,
    },
  },
  userVerifiedSign: {
    color: '#03DAC5',
    position: 'absolute',
    top: 1,
    right: 2,
    width: 11,
    height: 13,
  },
  userVerifiedSignBackground: {
    width: 7,
    height: 7,
    position: 'absolute',
    top: 3,
    right: 4,
    background: '#018786',
  },
  userImage: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 20,
    width: '100%',
    height: '100%',
  },
  userData: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  userNameWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    cursor: 'pointer',
  },
  username: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '19px',
    letterSpacing: '0.15px',
    color: 'rgba(255, 255, 255, 0.87)',
    '& span': {
      borderBottom: '1px solid',
    },
    height: 19,
    display: 'flex',
    alignItems: 'baseline',
  },

  userIdWrap: {
    color: palette.text.hint,
    fontSize: 12,
    height: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    overflow: 'hidden',
  },
  userId: {
    wordBreak: 'break-all',
    overflow: 'hidden',
  },
  flag: {
    fontSize: 12,
    marginLeft: 4,
  },
});

interface AdminUserInfoType {
  className?: string;
  user: IUserItem;
}

export const AdminUserInfo: ComponentType<AdminUserInfoType> = ({ className, user }) => {
  const classes = useClasses(styles);
  const navigate = useNavigate();

  return (
    <div className={className ?? ''}>
      <div className={classes.root}>
        <div className={classes.userProfileImageWrapper}>
          <div
            className={classes.userImage}
            style={{ backgroundImage: user.image !== '' ? `url(${user.image})` : `url(${ImageUserPlaceholder})` }}
          ></div>
          {user.is_trusted && (
            <>
              <div className={classes.userVerifiedSignBackground}></div>
              <Beenhere className={classes.userVerifiedSign} />
            </>
          )}
        </div>

        <div className={classes.userData}>
          <div
            className={classes.userNameWrap}
            onClick={() => {
              navigate(`/user/${user.id}`);
            }}
          >
            <div className={classes.username}>
              <span>{user.username}</span>
            </div>
            {user.country !== null && (
              <div className={classes.flag}>{user?.country ? countryFlags[user?.country] : ''}</div>
            )}
          </div>

          <div className={classes.userIdWrap}>
            <div>ID:&nbsp;</div>
            <div className={classes.userId}>
              <CopyToClipboard text={user.id} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
