import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  useAddCommentMutation,
  useCommentReportMutation,
  useDeleteCommentMutation,
  useDislikeCommentMutation,
  useLikeCommentMutation,
  useRemoveDislikeCommentMutation,
  useRemoveLikeCommentMutation,
  useUpdateCommentMutation,
} from 'services';
import { AddCommentPayload, CommentPayload, ReportCommentPayload, UpdateCommentPayload } from '../StoriesTypes';

type useCommentsActionsType = () => {
  like: (payload: CommentPayload) => void;
  dislike: (payload: CommentPayload) => void;
  removeLike: (payload: CommentPayload) => void;
  removeDislike: (payload: CommentPayload) => void;
  add: (payload: AddCommentPayload) => any;
  update: (payload: UpdateCommentPayload) => any;
  deleteComment: (payload: CommentPayload) => void;
  report: (payload: ReportCommentPayload) => void;
};

export const useCommentActions: useCommentsActionsType = () => {
  const mutationLike = useLikeCommentMutation();
  const mutationDislike = useDislikeCommentMutation();
  const mutationRemoveLike = useRemoveLikeCommentMutation();
  const mutationRemoveDislike = useRemoveDislikeCommentMutation();
  const mutationUpdate = useUpdateCommentMutation();
  const mutationDelete = useDeleteCommentMutation();
  const mutationReport = useCommentReportMutation();
  const mutationAdd = useAddCommentMutation();
  const { t } = useTranslation();

  const like = (payload: CommentPayload) => {
    mutationLike.mutate(payload);
  };

  const dislike = (payload: CommentPayload) => {
    mutationDislike.mutate(payload);
  };
  const removeLike = (payload: CommentPayload) => {
    mutationRemoveLike.mutate(payload);
  };

  const removeDislike = (payload: CommentPayload) => {
    mutationRemoveDislike.mutate(payload);
  };
  const add = (payload: AddCommentPayload) => {
    return new Promise((resolve) => {
      mutationAdd.mutate(payload, {
        onSuccess(data, variables, context) {
          resolve(data);
        },
        onError(error: any) {
          if (error.response.data.message === 'comment_banned') {
            toast(t('comment_banned'));
          }
        },
      });
    });
  };
  const update = (payload: UpdateCommentPayload) => {
    return new Promise((resolve) => {
      mutationUpdate.mutate(payload, {
        onSuccess(data, variables, context) {
          resolve(data);
        },
        onError(error: any) {
          if (error.response.data.message === 'comment_banned') {
            toast(t('comment_banned'));
          }
        },
      });
    });
  };

  const deleteComment = (payload: CommentPayload) => {
    return new Promise((resolve) => {
      mutationDelete.mutate(payload, {
        onSuccess(data, variables, context) {
          resolve(data);
        },
      });
    });
  };

  const report = (payload: ReportCommentPayload) => {
    mutationReport.mutate(payload, {
      onSuccess(data, variables, context){
        toast(t("report_story_success"))
      },
      onError(error: any) {
        if (error?.response?.data?.message === "report_already_exists_error")
        {
          toast(t("report_story_conflict"))
          return
        }
        toast(t("report_story_fail"))
      }
    });
  };

  return {
    add,
    deleteComment,
    like,
    removeDislike,
    removeLike,
    report,
    dislike,
    update,
  };
};
