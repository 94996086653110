import { Capacitor } from '@capacitor/core';
import { useMutation } from 'react-query';
import store from 'store2';
import { Share } from '@capacitor/share'
import { Filesystem, Directory } from '@capacitor/filesystem'


const BASE_URL = process.env.REACT_APP_PUBLIC_API_URL

const exportServiceBook = async ({ id, title, dialogTitle }: { id: string, title: any, dialogTitle: any; }): Promise<any> => {

  return new Promise((resolve, reject) => {
    const accessToken = store.get("accessToken")
    const xhr = new XMLHttpRequest();

    xhr.open('GET', `${BASE_URL}cars/${id}/records/export`, true);

    xhr.responseType = 'blob';

    xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);

    xhr.onload = function() {  
      if (xhr.status === 200) {
        const contentDisposition = xhr.getResponseHeader('Content-Disposition');
        let blob = new Blob([xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
        const fileName = contentDisposition?.split('filename=')[1]?.replace(/"/g, '') || 'service_book.xlsx';
    
        if (Capacitor.isNativePlatform()) {
          const fileReader = new FileReader();
          
          fileReader.onloadend = async () => {
            const result = fileReader?.result;
            let base64data = '';
    
            if (typeof result === 'string') {
              base64data = result.split(',')[1];
            } else {
              console.error('Error reading file data');
              return;
            }
            try {
              const writeResult = await Filesystem.writeFile({
                path: fileName,
                data: base64data,
                directory: Directory.Documents,
              });
              const fileUri = writeResult.uri;
          
            await Share.share({
              title: title,
              url: fileUri,
              dialogTitle: dialogTitle,
            });
            } catch (error) {
              console.error('Error sharing file:', error);
            }
          };
          fileReader.readAsDataURL(blob);
        } else {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }
      } else {
        console.error('Error downloading file:', xhr.statusText);
      }
    };
  
xhr.onerror = function () {
      reject(new Error('Request failed due to a network error'));
    };

    xhr.send();
  });
};

export const useExportServiceBookMutation = () => {
  return useMutation(exportServiceBook);
};
