import { ComponentType } from 'react';
import { Theme } from '@mui/material';
import { useClasses } from 'utils/hooks/useClasses';
import { IItem, IStoryElement } from './AdminItemTypes';
import store from 'store2';
import { AdminItemLikes } from './AdminItemLikes';
import { AdminStoryContentTexts } from './AdminStoryTexts';
import { AdminStoryContentImages } from './AdminStoryImages';
import { AdminStoryRecords } from './AdminStoryRecords';

enum translateTitleProp {
  'en' = 'en_title',
  'es' = 'es_title',
  'uk' = 'uk_title',
}

const styles = ({ palette, spacing }: Theme) => ({
  root: {
    padding: spacing(3),
    overflowY: 'scroll',
  },
  storyTitle: {
    fontWeight: '600',
    fontSize: spacing(3),
    lineHeight: spacing(3),
    letterSpacing: '0.15px',
    marginBottom: spacing(2.5),
  },
});

interface AdminStoryContentType {
  story: IItem;
  showTranslate: boolean;
}

export const AdminStoryContent: ComponentType<AdminStoryContentType> = ({ story, showTranslate }) => {
  const classes = useClasses(styles);
  const storedLanguage: 'en' | 'es' | 'uk' = store('driverbook_language') ?? 'en';
  const getSortedElements = (story?.elements || []).reduce((acc: IStoryElement[], element: IStoryElement) => {
    const index = acc.findIndex((e: IStoryElement) => e.position > element.position);
    acc.splice(index === -1 ? acc.length : index, 0, element);
    return acc;
  }, []);
  return (
    <div className={classes.root}>
      <div className={classes.storyTitle}>
        {showTranslate ? story[translateTitleProp[storedLanguage]] : story.title}
      </div>
      {/* eslint-disable-next-line array-callback-return */}
      {getSortedElements.map((element) => {
        if (element.element_type === 'original_text') {
          return <AdminStoryContentTexts key={element.id} texts={element.texts} showTranslate={showTranslate} />;
        }

        if (element.element_type === 'gallery') {
          return <AdminStoryContentImages key={element.id} images={element.images} />;
        }

        if (element.element_type === 'assisted_records') {
          return (
            <AdminStoryRecords
              key={element.id}
              records={element.assisted_records}
              isGroup={true}
              showTranslate={showTranslate}
            />
          );
        }

        if (element.element_type === 'records') {
          return <AdminStoryRecords key={element.id} records={element.records} showTranslate={showTranslate} />;
        }
      })}
      <AdminItemLikes item={story}></AdminItemLikes>
    </div>
  );
};
