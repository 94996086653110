import { useQuery, UseQueryResult } from 'react-query';

import { axiosApiInstance as api } from 'utils';

import { createCarUI } from '../../cars/utils';

const LINK_EXPIRED_ERROR = 'link expired or already claimed';

const getAssistedCarData = async (identifier: string): Promise<any> => {
  try {
    const { data } = await api.get('/assisted/data', { params: { identifier } });
    return createCarUI(data);
  } catch (err: any) {
    if (err.response.data.message === LINK_EXPIRED_ERROR) {
      return { error: true };
    }
  }
};

export const useAssistedCarQuery = (identifier: string): UseQueryResult<any> => {
  return useQuery(['assistedCarData'], () => getAssistedCarData(identifier), { cacheTime: 0 });
};
