import { carReminderChangePayload, useDeleteReminderMutation } from 'services/reminders';

export const useDeleteReminder = () => {
  const mutationsDelete = useDeleteReminderMutation();

  const deleteReminder = ({ carId, reminderId }: carReminderChangePayload) => {
    return new Promise((resolve, reject) => {
      mutationsDelete.mutate(
        { carId, reminderId },
        {
          onSuccess(data, variables, context) {
            resolve(data);
          },
        }
      );
    });
  };

  return {
    deleteReminder,
  };
};
