import { useUserBansWarnsQuery } from 'services/admin';
import { IBansWarns } from '../components/AdminDashboardTypes';

type useAdminUserBansWarnsType = (id: string) => {
  bansWarns: IBansWarns;
  isFetching: boolean;
  refetch: () => void;
};

export const useAdminUserBansWarns: useAdminUserBansWarnsType = (id) => {
  const { data: bansWarns, isFetching, refetch } = useUserBansWarnsQuery(id);

  return {
    bansWarns,
    isFetching,
    refetch,
  };
};
