import { useState } from "react"
import { IItem } from "../components/AdminReview/AdminItemTypes";


type useDetailsItemType = () => {
    openDetailsDialog: boolean,
    onOpenDetailsDialog: ()=>void,
    onCloseDetailsDialog: ()=>void,
    currentItem: IItem | null,
    setCurrentItem: (item: IItem | null) =>void
}

export const useDetailsItem: useDetailsItemType = () => {
    const [openDetailsDialog, setOpenDetailsDialog] = useState<boolean>(false);
    const [currentItem, setCurrentItem] = useState<IItem | null>(null);



    const onCloseDetailsDialog = () => {
        setOpenDetailsDialog(false);
        setCurrentItem(null);
    }
    const onOpenDetailsDialog = () => {
        setOpenDetailsDialog(true);
    }

    return {
        onOpenDetailsDialog,
        onCloseDetailsDialog,
        openDetailsDialog,
        currentItem,
        setCurrentItem
    }
}