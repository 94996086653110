import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  Theme,
  DialogActions,
} from '@mui/material';
import { IRecord } from 'features/stories-feed/StoriesTypes';
import { ComponentType } from 'react';
import { StoryRecords } from './StoryRecords';
import { theme } from 'theme';
import { ArrowBack, Close } from '@mui/icons-material';
import { useClasses } from 'utils/hooks/useClasses';
import { useTranslation } from 'react-i18next';
import { countTotalMoneySpendI, currencySymbol, formatDate } from 'utils';
import { Currency } from 'types';
import { TranslateContext } from '../StoryWrapper';
const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      background: '#121212',

      [breakpoints.up('sm')]: {
        background: '#2a2a2a',
        width: 390,
        height: 512,
      },
    },
  },
  title: {
    textTransform: 'capitalize',
  },
  backBtn: {
    color: palette.text.hint,
    width: 44,
    height: 44,
    [breakpoints.down('sm')]: {
      paddingRight: spacing(3),
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    marginBottom: spacing(2),
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 10,
    letterSpacing: 1.5,
    color: 'rgba(255, 255, 255, 0.6)',
  },
  dot: {
    display: 'inline-block',
    margin: spacing(0, 0.5),
    fontSize: 12,
    color: palette.additionalPrimary['200'],
  },
  groupTitle: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: '0.15px',
  },
});

interface RecordsGroupPopupProps {
  data: IRecord[];
  open: boolean;
  onClose: () => void;
}

export const StoryGroupRecordsPopup: ComponentType<RecordsGroupPopupProps> = ({ data, open, onClose }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const firstRecord = data[0];
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const title = t('story_group_records_popup_title');
  const totalExpenses = countTotalMoneySpendI(data?.flat());
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='xs'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullScreen={fullScreen}
      className={classes.dialog}
    >
      <DialogTitle>
        {fullScreen ? (
          <Grid container alignItems='center'>
            <IconButton onClick={onClose} className={classes.backBtn}>
              <ArrowBack />
            </IconButton>
            <div className={classes.title}>{title}</div>
          </Grid>
        ) : (
          <Grid container justifyContent='space-between' alignItems='center' marginTop={4}>
            <div className={classes.title}>{title}</div>
            <IconButton onClick={onClose} className={classes.backBtn}>
              <Close />
            </IconButton>
          </Grid>
        )}
      </DialogTitle>

      <DialogContent>
        <div className={classes.header}>
          <div className={classes.groupTitle}>
            {
              <TranslateContext.Consumer>
                {(noTranslate) =>
                  !firstRecord.do_not_translate && !noTranslate
                    ? firstRecord.translated_group_title
                    : firstRecord.group_title
                }
              </TranslateContext.Consumer>
            }
          </div>
          <div className={classes.info}>
            {formatDate(firstRecord.date_created)}
            <span className={classes.dot}>•</span>
            <div>
              {t('record_group_notes')} {data.length}
            </div>
            <span className={classes.dot}>•</span>
            <div>
              {totalExpenses} {currencySymbol[firstRecord.currency as Currency]}
            </div>
          </div>
        </div>
        <StoryRecords records={data} />
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};
