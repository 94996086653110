import { FetchNextPageOptions, InfiniteData, RefetchOptions } from 'react-query';
import { useStoryCommentsRecentQuery } from 'services';
import { CommentsResponse } from '../StoriesTypes';

type useStoriesDataType = (
  carId: string,
  storyId: string,
) => {
  recentComments?: InfiniteData<CommentsResponse>,
  hasNextPageRecentComments?: boolean,
  isFetchingNextPageRecentComments: boolean,
  isLoadingRecentComments: boolean,
  fetchNextPageRecentComments: (options?: FetchNextPageOptions)=>void,
  refetchRecentComments: (options?: RefetchOptions)=>void,
}

export const useCommentsRecent: useStoriesDataType = (carId, storyId) => {

  const {
    data: recentComments, 
    hasNextPage: hasNextPageRecentComments, 
    fetchNextPage: fetchNextPageRecentComments, 
    isFetchingNextPage: isFetchingNextPageRecentComments, 
    isLoading: isLoadingRecentComments, 
    refetch: refetchRecentComments
  } = useStoryCommentsRecentQuery({carId, storyId});


  return{
    recentComments,
    hasNextPageRecentComments,
    fetchNextPageRecentComments,
    isFetchingNextPageRecentComments,
    isLoadingRecentComments,
    refetchRecentComments,
  }
}