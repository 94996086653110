import { useQuery, UseQueryResult } from 'react-query';

import { axiosApiInstance as api } from 'utils';

const getRecordGroupQuery = async (identifier: string): Promise<any> => {
  const { data } = await api.get(`/records/group`, { params: { identifier } });

  return data;
};

export const useRecordGroupQuery = (groupId: string): UseQueryResult<any> => {
  return useQuery('groupData', () => getRecordGroupQuery(groupId));
};
