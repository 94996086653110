import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
const tKey = getTKey('reminder_delete_dialog');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    '& label': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  },
});

interface DeleteReminderDialogProps {
  open: boolean;
  cancel: () => void;
  remove: () => void;
}
export const DeleteReminderDialog: ComponentType<DeleteReminderDialogProps> = ({ open, cancel, remove }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);

  return (
    <Dialog open={open} className={classes.root}>
      <DialogTitle>{t(tKey('title'))}</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button onClick={cancel}>{t('__common__cancel')} </Button>
        <Button color='error' onClick={remove}>
          {t('__common__delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
