import { ArrowBack, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers/icons';
import clsx from 'clsx';
import { RecordCategory } from 'features/records/components/record-categories';
import React, { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('category_select_dialog');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    height: 56,
    border: '1px solid #FFFFFF99',
    borderRadius: 4,
    padding: '6px 12px',
    cursor: 'pointer',
  },
  errorState: {
    border: '1px solid red',
  },
  dialog: {
    '& .MuiPaper-root': {
      background: '#202020',

      [breakpoints.up('sm')]: {
        background: '#2a2a2a',
        width: 400,
        height: 600,
      },
    },
  },
  backBtn: {
    color: palette.text.hint,
    width: 44,
    height: 44,
    [breakpoints.down('sm')]: {
      paddingRight: spacing(3),
    },
  },
  searchField: {
    background: '#202020',
    border: '1px solid #FFFFFF13',
    '& input': {
      padding: spacing(1.25, 1.5),
    },
  },
  emptySelect: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: '#FFFFFF73',
  },
  chip: {
    margin: '2px',
    background: '#4B01D0',
    color: '#ffffff',
    '& .MuiChip-deleteIcon': {
      color: '#ffffff99',
    },
    '& .MuiChip-deleteIcon:hover': {
      color: '#ffffff',
    },
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectedCategories: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'scroll',
    maxWidth: '100%',
    scrollbarWidth: 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
    },
  },
});

interface CustomSelectWithDialogProps {
  options: RecordCategory[];
  values: string[];
  onChange: (selected: string[]) => void;
  error: boolean;
}

export const CategorySelectWithDialog: ComponentType<CustomSelectWithDialogProps> = ({
  options,
  values,
  onChange,
  error,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState<string[]>(values);
  const [searchQuery, setSearchQuery] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = (value: string) => {
    let newSelectedValues = [...selectedValues];
    if (newSelectedValues.includes(value)) {
      newSelectedValues = newSelectedValues.filter((item) => item !== value);
    } else {
      if (newSelectedValues.length < 3) {
        newSelectedValues.push(value);
      }
    }
    setSelectedValues(newSelectedValues);
    onChange(newSelectedValues);
  };

  const handleClear = () => {
    setSelectedValues([]);
    onChange([]);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredOptions = options.filter((option) => t(option.label).toLowerCase().includes(searchQuery.toLowerCase()));

  const handleDelete = (value: string) => {
    const newSelectedValues = selectedValues.filter((item) => item !== value);
    setSelectedValues(newSelectedValues);
    onChange(newSelectedValues);
  };

  return (
    <div>
      <Box onClick={handleClickOpen} className={clsx(classes.root, error && classes.errorState)}>
        {selectedValues.length === 0 ? (
          <Box className={classes.emptySelect}>
            <Typography component='div'>{t(tKey('select_placeholder'))}</Typography>
            <ArrowDropDownIcon />
          </Box>
        ) : (
          <Box display='flex' justifyContent='space-between' width='100%'>
            <Box className={classes.selectedCategories}>
              {selectedValues.map((value) => (
                <Chip
                  key={value}
                  label={t(options.find((option) => option.value === value)?.label || '')}
                  onDelete={() => handleDelete(value)}
                  className={classes.chip}
                />
              ))}
            </Box>
            <Box display='flex' alignItems='center'>
              <ArrowDropDownIcon />
            </Box>
          </Box>
        )}
      </Box>
      {error && <Typography>{t('__validation__required')}</Typography>}
      <Dialog open={open} onClose={handleClose} fullScreen={fullScreen} className={classes.dialog}>
        <DialogTitle>
          <Box display='flex' alignItems='center'>
            <IconButton onClick={handleClose} className={classes.backBtn}>
              <ArrowBack />
            </IconButton>
            <Box flexGrow={1}>
              <Typography variant='h6'>{t(tKey('title'))}</Typography>
            </Box>
            <Button onClick={handleClear}>{t(tKey('button_clear'))}</Button>
          </Box>
          <Box>
            <TextField
              className={classes.searchField}
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={t(tKey('search_placeholder'))}
              fullWidth
              margin='dense'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' style={{ color: '#A0A0A0' }}>
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <List>
            {filteredOptions.map((option) => (
              <ListItem
                key={option.value}
                value={option.value}
                onClick={() => handleToggle(option.value)}
                className={classes.listItem}
              >
                <ListItemText primary={t(option.label)} />
                <Checkbox edge='start' checked={selectedValues.includes(option.value)} tabIndex={-1} disableRipple />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('__common__ok')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
