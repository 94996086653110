import { ArrowBack } from '@mui/icons-material';
import { Button, Theme, Typography } from '@mui/material';
import { CommonLayout } from 'components';
import { GeneratedLink } from 'features/records/components/GeneratedLink';
import { capitalize } from 'lodash';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useCarQuery } from 'services';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { ShareCarForm } from './components/CarShareForm';
import { useCarShareData } from './components/useCarShareData';
import { useCarShare } from './useCarShare';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  container: {
    maxWidth: 800,
    margin: spacing(0, 'auto', 10),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 250,
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: spacing(0, 0, 3),
    fontSize: 25,
    fontWeight: 600,

    [breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  btnBack: {
    justifyContent: 'flex-start',
    minWidth: 48,
    color: 'rgba(255, 255, 255, 0.87)',
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    fontSize: 24,
    margin: spacing(2, 0, 2),
    fontWeight: 600,
    textAlign: 'center',

    [breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
});

const tKey = getTKey('share_car');

export const ShareCarPage: ComponentType = () => {
  const navigate = useNavigate();
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const { carId } = useParams<{ carId: string }>() as { carId: string };
  const { data: car } = useCarQuery(carId);
  const { identifier, errorMessage, handleOnShare } = useCarShare();
  const { lastIdentifier, count, countLoading } = useCarShareData(carId);
  const [validIdentifier, setValidIdentifier] = useState<string>('');

  const carName = `${capitalize(car?.make)} ${capitalize(car?.model)}`;

  useEffect(() => {
    if (identifier.length > 1) setValidIdentifier(identifier);
    else setValidIdentifier(lastIdentifier);
  }, [identifier, lastIdentifier]);

  return (
    <CommonLayout>
      <div className={classes.container}>
        <div>
          <Typography>
            <span className={classes.heading}>
              {/* @ts-ignore */}
              <Button size='small' className={classes.btnBack} onClick={() => navigate(-1)}>
                <ArrowBack width={16} height={16} />
              </Button>
              {t(tKey('title'), { carName })}
            </span>
          </Typography>
          <Typography variant='body1'>{t(tKey('description'))}</Typography>
        </div>
        <ShareCarForm onSubmit={handleOnShare} count={count} countLoading={countLoading} />
        {errorMessage && <span className={classes.error}>{errorMessage}</span>}
        {validIdentifier && (
          <GeneratedLink identifier={validIdentifier} link='book' notificationText={t(tKey('copied_toast'))} />
        )}
      </div>
    </CommonLayout>
  );
};
