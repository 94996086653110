import { Capacitor } from '@capacitor/core';
import { Box, Theme, Typography } from '@mui/material';
import { ImageLogo, LeanHeader, Wrapper } from 'components';
import { CookieBanner } from 'components/CookieBanner/CookieBanner';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import store from 'store2';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ breakpoints, spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 180px)',

    [breakpoints.up('md')]: {
      minHeight: 'calc(100vh - 130px)',
    },
  },
  logo: {
    marginBottom: spacing(3),
    textAlign: 'center',
  },
  content: {
    flexGrow: 1,
    marginBottom: 'auto',
  },
});

export const LeanLayout: ComponentType = ({ children }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const navigate = useNavigate();

  const isNativePlatform = Capacitor.isNativePlatform();
  const [cookieBannerVisible, setCookieBannerVisible] = useState(!store('driverbook_cookies'));
  return (
    <>
      <LeanHeader />
      <Wrapper>
        <div className={classes.root}>
          <Box
            component={'div'}
            sx={{ display: { xs: 'block', md: 'none' } }}
            className={classes.logo}
            onClick={() => navigate('/')}
          >
            <ImageLogo width={110} height={110} />
          </Box>
          <div className={classes.content}>{children}</div>
          <Typography variant='caption' align='center' display='block'>
            <span className={classes.copyright}>{t('__common__copyright')}</span>
          </Typography>
        </div>
      </Wrapper>
      {!isNativePlatform && cookieBannerVisible && <CookieBanner close={setCookieBannerVisible} />}
    </>
  );
};
