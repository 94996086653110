import { ComponentType, useState } from "react";
import { IReport } from "./AdminItemTypes";
import { Chip, Collapse, Divider, IconButton, Theme} from "@mui/material";
import { useClasses } from "utils/hooks/useClasses";
import dayjs from "dayjs";
import { ExpandMore } from "@mui/icons-material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "components/CopyToClipboard/CopyToClipboard";
import { getTKey } from "utils";
const tKey = getTKey('admin_report')

const styles = ({palette, spacing, breakpoints}: Theme) => ({
    root: {
        padding: spacing(3),
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    reportTitle: {
        fontSize: 20,
    },
    total: {
        fontSize: 12,
        color: palette.text.hint,
    },

    reportTableHeader:{
        marginTop: spacing(2),
        display: 'flex',
        flexDirection: 'row',
        color: palette.text.hint,
    },
    columnHeaderDate: {
        width: '10%',
    },
    columnHeaderUserId: {
        width: '30%',
    },
    columnHeaderId: {
        width: '30%',
    },
    columnHeaderReason: {
        width: '25%',
    },
    columnHeaderExpand: {
        width: '5%',
    }
    
})

interface AdminItemReportsType {
    reports: Array<IReport>
}

export const AdminItemReports: ComponentType<AdminItemReportsType> = ({
    reports,
}) => {
    const classes = useClasses(styles);
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.reportTitle}>{t(tKey('title'))}</div>
                <div className={classes.total}>{reports?.length} {t(tKey('total'))}</div>
            </div>
            <div className={classes.reportTableHeader}>
                <div className={classes.columnHeaderDate}>{t(tKey('table_date'))}</div>
                <div className={classes.columnHeaderUserId}>{t(tKey('table_user'))} ID</div>
                <div className={classes.columnHeaderId}>ID</div>
                <div className={classes.columnHeaderReason}>{t(tKey('table_reason'))}</div>
                <div className={classes.columnHeaderExpand}></div>
            </div>
            {reports?.map(report => {
                return (
                    <AdminItemReport key={report.id} report={report}/>
                )
            })
            }
        </div>
    )   
}

const stylesReport = ({palette, spacing, breakpoints}: Theme) => ({
    row:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    columnRowDate: {
        color: palette.text.hint,
        width: '10%',
    },
    columnRowUserId: {
        width: '29%',
        overflow: 'hidden',
        marginRight: '1%',
        fontSize: 12
    },
    columnRowId: {
        width: '29%',
        overflow: 'hidden',
        marginRight: '1%',
        fontSize: 12

    },
    columnRowReason: {
        width: '25%',
    },
    columnRowExpand: {
        width: '5%',
    },
    messageLabel: {
        color: palette.text.hint,
        fontSize: 12
    },
    divider: {
        margin: spacing(1, 0),
        background: palette.text.hint,
    },
    chip:{
        color: palette.text.primary,
        background: palette.additionalSecondary[700],
    },
    expandButtonOpen: {
        transform: 'rotate(180deg)',
    },
})

interface AdminStoryRecordsType {
    report: IReport,
}

export const AdminItemReport: ComponentType<AdminStoryRecordsType> = ({
    report,
}) => {
    const classes = useClasses(stylesReport);
    const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation();
    return (
        <div>
           <div className={classes.row}>
                <div className={classes.columnRowDate}>{dayjs(report.date_created).format('DD-MM-YYYY')}</div>
                <div className={classes.columnRowUserId}><CopyToClipboard text={report.reporter_id}/></div>
                <div className={classes.columnRowId}><CopyToClipboard text={report.id}/></div>
                <div className={classes.columnRowReason}>
                    <Chip label={report.subject} className={classes.chip} />
                </div>
                <div className={classes.columnRowExpand}>
                <IconButton
                        color='inherit'
                        onClick={() => {setExpanded(!expanded)}}
                        size='large'
                        className={clsx(classes.expandButton, {
                            [classes.expandButtonOpen]: expanded,
                          })}
                    >
                        <ExpandMore />
                    </IconButton>
                </div>
           </div>
           <Collapse in={expanded} timeout='auto' unmountOnExit>
                <div className={classes.reportContent}>
                    <div className={classes.messageLabel}>{t(tKey('table_message'))}:</div>
                    <div>{report.details}</div>
                </div>
                
           </Collapse>
           <Divider className={classes.divider}></Divider>
        </div>
        
    )   
}
