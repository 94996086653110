import { useMutation } from 'react-query';

import { queryClient } from 'components';
import { axiosApiInstance as api } from 'utils';

const deleteGroup = async (groupId: string): Promise<void> => {
  await api.delete(`/records/group/${groupId}`);
};

export const useDeleteRecordGroupMutation = () => {
  return useMutation(deleteGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries('records');
    },
  });
};
