import _ from 'lodash';
import { useMutation } from 'react-query';
import { Reminder, RecordAITypeShort } from 'types';
import { axiosApiInstance as api, mapKeysToCamelCase } from 'utils';

export type createRecordsFromVoiceType = {
  carId: string;
  body: createRecordsFromVoiceBody;
};

export type createRecordsFromVoiceBody = {
  audio: File;
  language: string;
};

type createRecordFromVoiceResponse = {
  type: RecordAITypeShort;
  records: Reminder[];
  error?: boolean;
};

export const createRecordsFromVoice = async ({
  body,
  carId,
}: createRecordsFromVoiceType): Promise<createRecordFromVoiceResponse> => {
  try {
    const formData = new FormData();
    formData.append('audio', body.audio);
    formData.append('language', body.language);
    let { data } = await api.post(`cars/${carId}/reminders/from-audio`, formData);
    let records = data.reminders.map((record: any) => mapKeysToCamelCase(record));
    records = records.map((record: any) => {
      return { ...record, tmpId: _.uniqueId() };
    });

    return {
      type: data.ai_type,
      records: records,
    };
  } catch (err) {
    return { error: true, type: 'vtr', records: [] };
  }
};

export const useCreateRemindersFromVoiceMutation = () => {
  return useMutation(createRecordsFromVoice);
};
