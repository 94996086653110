import { useMutation } from 'react-query';

import { queryClient } from 'components';
import { axiosApiInstance as api } from 'utils';

interface DeleteDraftPayload {
  carId: string;
  recordId: string;
  identifier: string;
}

const deleteDraft = async ({ carId, recordId, identifier }: DeleteDraftPayload): Promise<void> => {
  await api.delete(`/cars/${carId}/records/${recordId}/draft`, { params: { identifier } });
};

export const useDeleteDraftMutation = () => {
  return useMutation(deleteDraft, {
    onSuccess: () => {
      queryClient.invalidateQueries('drafts');
    },
  });
};
