import { Checkbox, Chip, Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'store2';

import { Currency, Record, UserSettings, Volume } from 'types';
import { currencySymbol, formatDate, getTKey, volumeMetric } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('record_item');

interface RecordItemProps {
  data: Record;
  mileageMetric: string | undefined;

  handleChange?: (checked: boolean, selected: Record) => void;
  value?: boolean;
}

const styles = ({ palette, spacing, breakpoints, transitions }: Theme) => ({
  root: {
    display: 'flex',
    marginBottom: spacing(1),
    padding: spacing(1, 2),
    backgroundColor: 'rgba(32,32,32,1)',
    border: '1px solid rgba(255, 255, 255, 0.08)',
    borderRadius: spacing(0.5),
    cursor: 'pointer',
    [breakpoints.down('sm')]: {
      padding: spacing(1),
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 10,
    letterSpacing: 1.5,
    color: 'rgba(255, 255, 255, 0.6)',
  },
  dot: {
    display: 'inline-block',
    margin: spacing(0, 0.5),
    fontSize: 12,
    color: palette.additionalPrimary['200'],
  },
  title: {
    fontWeight: 500,
    lineHeight: 2,
    letterSpacing: 1.5,
    textOverflow: 'ellipsis',
    width: 180,
    // maxHeight: 48,
    marginTop: 0,
    lineClamp: 2,
    overflow: 'hidden',
    fontSize: 'inherit',
    boxOrient: 'vertical',
    display: '-webkit-box',
    WebkitLineClamp: '2',
  },
  subtitle: {
    marginBottom: spacing(0.5),
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.6)',
  },

  cost: {
    order: 1,
    marginTop: 10,
    fontSize: 34,
    fontWeight: 500,
  },
  chip: {
    order: 1,
    flexShrink: 1,
    maxWidth: 130,
    marginLeft: spacing(2),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: palette.additionalSecondary['800'],
    color: palette.text.primary,
  },
  expandButton: {
    order: 3,
    marginLeft: 'auto',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: 'rotate(0deg)',
    transition: transitions.create('transform', {
      duration: transitions.duration.shortest,
    }),
  },
  expandButtonOpen: {
    color: palette.primary.main,
    transform: 'rotate(180deg)',
  },
  expandButtonStub: {
    order: 'unset',
    width: 48,
    marginLeft: 0,
  },
  divider: {
    margin: spacing(2, 0),
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
  },
  attachmentsRow: {
    marginTop: spacing(0.5),

    '& > div': {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
    },
  },
  details: {
    wordBreak: 'break-word',
    lineHeight: '24px',
    letterSpacing: '0.2px',
  },
});

export const RecordItem: ComponentType<RecordItemProps> = ({ data, mileageMetric, handleChange, value }) => {
  const { t } = useTranslation();
  const settingsData = store('driverbook_settings') as UserSettings;
  const classes = useClasses(styles);

  const fuelCategoryUnit: { [category: string]: Volume | 'kWh' } = {
    refueling: settingsData?.volume_metric,
    recharging: 'kWh',
  };

  const fuelCategory = (category: string) => category === 'refueling' || category === 'recharging';

  const getUnit = (plural?: boolean) =>
    t(tKey(volumeMetric[fuelCategoryUnit[data.category] as Volume] + (plural ? 's' : '')));

  return (
    <div className={classes.root} onClick={() => handleChange?.(!value, data)}>
      <div className={classes.header}>
        <div className={classes.info}>
          {formatDate(data.datePerformed)}
          <span className={classes.dot}>•</span>
          <div>
            {data.mileage}
            {` ${mileageMetric?.toUpperCase()}`}
          </div>
        </div>
        <div className={classes.title}>
          {fuelCategory(data.category) && `${getUnit(true)}: `}
          {data.title}
        </div>
        <div className={classes.cost}>
          {data.cost}
          {currencySymbol[settingsData?.currency as Currency]}
          <Chip label={t(`__record_category__${data.category}`)} className={classes.chip} />
        </div>
      </div>
      {handleChange && (
        <div>
          <Checkbox color='primary' checked={value} inputProps={{ 'aria-label': 'controlled' }} />
        </div>
      )}
    </div>
  );
};
