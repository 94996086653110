import { useMutation } from 'react-query';

import { axiosWithFileInstance } from 'utils';

import { DraftPayload } from '../types';

const updateDraft = async ({ carId, recordId, formData }: DraftPayload): Promise<any> => {
  const { data } = await axiosWithFileInstance.put(`/cars/${carId}/records/${recordId}/draft`, formData);

  return data;
};

export const useUpdateDraftMutation = () => {
  return useMutation(updateDraft);
};
