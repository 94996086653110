import { ImportContacts, LibraryBooks, FormatListBulleted, Menu } from '@mui/icons-material';
import { Badge, IconButton, Theme, Toolbar } from '@mui/material';
import { ComponentType, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { pulse } from 'animations/Pulse';
import clsx from 'clsx';
import { AddNewButton, Breadcrumbs, ImageLogo, MenuButton, Wrapper } from 'components';
import AssistantIconMono from 'components/CustomIcons/AssistantIconMono';
import { UnregisteredPopup } from 'components/UnregisteredPopup/UnregisteredPopup';
import { ProfileContext as Context } from 'store/profile/store';
import { getTKey, isLoggedIn } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('header_page');

const styles = ({ spacing }: Theme) => ({
  root: {
    backgroundColor: 'rgba(187, 134, 252, 0.12)',
  },
  toolbar: {
    padding: spacing(3, 0),
  },
  menuBtn: {
    marginRight: spacing(3),
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  breadcrumbs: {
    marginLeft: spacing(3.5),
  },
  addNewButtonWrapper: {
    width: 56,
  },
  badge: {
    '& span': {
      right: 3,
      top: 8,
      outline: '1px solid #27212f',
    },
  },
  pulse: {
    '& span:before': {
      content: '""',
      position: 'absolute',
      border: '2px solid #d32f2f',
      left: -1,
      right: -1,
      top: -1,
      bottom: -1,
      borderRadius: '50%',
      animation: `${pulse[0]} 1.5s infinite`,
    },
  },
});

export const CommonHeader: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const navigate = useNavigate();
  const { state } = useContext(Context);
  const [openUnregistered, setOpenUnregistered] = useState<boolean>(false);
  const isAuth = isLoggedIn();
  const [loginFrom, setLoginFrom] = useState('');

  const handleUnregisteredPopup = (from: string) => {
    setLoginFrom(from);
    setOpenUnregistered(true);
  };

  return (
    <div className={classes.root}>
      <Wrapper>
        <Toolbar disableGutters classes={{ root: classes.toolbar }}>
          <IconButton
            color='inherit'
            className={classes.menuBtn}
            onClick={() => {
              if (isAuth) {
                navigate('/settings');
              } else {
                setOpenUnregistered(true);
              }
            }}
            size='large'
          >
            <Badge
              color='error'
              variant='dot'
              invisible={!state.alertActive}
              className={clsx(classes.badge, [state.alertPulse && classes.pulse])}
            >
              <Menu />
            </Badge>
          </IconButton>
          <ImageLogo />
          <div className={classes.breadcrumbs}>
            <Breadcrumbs />
          </div>
          <nav className={classes.nav}>
            <MenuButton
              text={t(tKey('stories'))}
              startIcon={ImportContacts}
              to='/stories'
              openUnregisteredPopup={handleUnregisteredPopup}
            />
            {/* <MenuButton
              text={t(tKey('cars'))}
              startIcon={DriveEta}
              to='/cars'
              openUnregisteredPopup={handleUnregisteredPopup}
            /> */}
            <MenuButton
              text={t(tKey('records'))}
              startIcon={LibraryBooks}
              to='/records'
              openUnregisteredPopup={handleUnregisteredPopup}
            />
            {/* <MenuButton
              text={t(tKey('alerts'))}
              startIcon={Notifications}
              to='/alerts'
              badge={state.alertActive}
              pulse={state.alertPulse}
              isAlert={true}
              openUnregisteredPopup={handleUnregisteredPopup}
            /> */}
            <MenuButton
              text={t(tKey('reminders'))}
              startIcon={FormatListBulleted}
              to='/reminders'
              badge={Boolean(state.remindersActive?.length)}
              pulse={state.reminderPulse}
              isAlert={true}
              openUnregisteredPopup={handleUnregisteredPopup}
              badgeStyles={{ '& span': { top: 6, right: 20 } }}
            />
            <MenuButton
              text={t(tKey('assistant'))}
              startIcon={AssistantIconMono}
              to='/assistant'
              openUnregisteredPopup={handleUnregisteredPopup}
            />
            <div className={classes.addNewButtonWrapper}>
              <AddNewButton />
            </div>
          </nav>
        </Toolbar>
      </Wrapper>
      <UnregisteredPopup
        open={openUnregistered}
        from={loginFrom}
        onClose={() => {
          setOpenUnregistered(false);
        }}
      />
    </div>
  );
};
