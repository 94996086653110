import { useMutation } from 'react-query';

import { queryClient } from 'components';
import { axiosApiInstance as api } from 'utils';

export interface FeedbackFormValues {
  category: string;
  text: string;
}

const sendFeedback = async (feedbackInfo: FeedbackFormValues): Promise<any> => {
  const { data } = await api.post('/feedback', feedbackInfo);
  return data;
};

export const useFeedbackMutation = () => {
  return useMutation(sendFeedback, {
    onSuccess: () => {
      queryClient.invalidateQueries('userInfo');
    },
  });
};
