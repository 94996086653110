import { useQuery } from 'react-query';
import { axiosApiInstance as api } from 'utils';

export const storyAudioLimits = async (): Promise<string> => {
  const { data } = await api.get(`stories/audio-limit`);
  return data?.message;
};

export const useStoryAudioLimitsQuery = (options: any) => {
  return useQuery(['storyAudioLimits'], storyAudioLimits, { cacheTime: 0, ...options });
};
