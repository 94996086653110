import { StoryPayload } from 'features/stories-feed/StoriesTypes';
import { useMutation } from 'react-query';

import { axiosWithFileInstance as api} from 'utils';

const deleteStory = async ({ carId, storyId }: StoryPayload): Promise<any> => {
  const { data } = await api.delete(`/cars/${carId}/stories/${storyId}`);
  return data;
};

export const useDeleteStoryMutation = () => {
  return useMutation(deleteStory);
};
