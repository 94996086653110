import { useMutation } from 'react-query';

import { UsernameValues } from 'types';
import { axiosApiInstance as api } from 'utils';

const updateUsername = async (body: UsernameValues): Promise<any> => {
  try {
    await api.put('/users/username', body);
    return true;
  } catch (error: any) {
    return { error: error.response.data.message };
  }
};

export const useUpdateUsernameMutation = () => {
  return useMutation(updateUsername);
};
