import { AddCommentPayload } from 'features/stories-feed/StoriesTypes';
import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

const addComment = async ({ carId, storyId, body }: AddCommentPayload): Promise<any> => {
  const { data } = await api.post(`/cars/${carId}/stories/${storyId}/comment`, body);
  return data;
};

export const useAddCommentMutation = () => {
  return useMutation(addComment);
};
