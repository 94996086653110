import { useFollowUserMutation } from "services/alerts";

interface useFollowUserModifyType {
  follow(id: string, follow: boolean): void
}

type useFollowUserType = () => useFollowUserModifyType;
export const useFollowUserModify: useFollowUserType = () => {

  const mutationFollowUser = useFollowUserMutation();
  const follow = (id: string, follow: boolean) => {
    const followPromise = new Promise((resolve, reject)=>{
      mutationFollowUser.mutate({id, follow}, {
        onSuccess(data) {
          resolve(data);
        },
        onError(error) {
          reject(error);
        }
      });
    })
    return followPromise
    
  }

  return {
    follow,
  }
}