import { Description, Edit } from '@mui/icons-material';
import { Button, IconButton, Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ImagePlaceholder from 'assets/image-placeholder.jpg';
import { CarMenuButton } from 'components/CarMenuButton/CarMenuButton';
import { Car } from 'types';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('car_item');

interface CarItemProps {
  car: Car;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onTransfer: (id: string) => void;
  onShare: (id: string) => void;
}

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    border: '1px solid rgba(255, 255, 255, 0.08)',
    borderRadius: spacing(0.5),

    [breakpoints.up('md')]: {
      marginBottom: spacing(2),
    },
  },
  headerInfo: {
    flexGrow: 1,
    maxWidth: 'calc(100% - 96px)',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: spacing(2),
  },
  actions: {
    '& .MuiIconButton-root': {
      color: 'rgba(255, 255, 255, 0.6)',
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  make: {
    textTransform: 'capitalize',
  },
  category: {
    color: palette.text.hint,
  },
  footer: {
    padding: spacing(2),
  },
  image: {
    position: 'relative',
    height: 0,
    paddingTop: '56.25%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  recordsButton: {
    position: 'absolute',
    bottom: spacing(2),
    right: spacing(2),
    minWidth: spacing(4),
    padding: spacing(0.75, 1),
    color: 'rgba(0, 0, 0, 0.6)',
    borderRadius: spacing(2),
  },
  recordsCount: {
    marginLeft: spacing(0.5),
  },
});

export const CarItem: ComponentType<CarItemProps> = ({ car, onDelete, onEdit, onTransfer, onShare }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const navigate = useNavigate();

  const openRecordsPage = (): void => {
    navigate(`/records/${car.id}`);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerInfo}>
          <div className={classes.title} onClick={openRecordsPage}>
            <span className={classes.make}>{car.make}</span> {car.model}
          </div>
          <div>
            <span className={classes.category}>{t(tKey('year'))}:</span> {car.year}
          </div>
        </div>
        <div className={classes.actions}>
          <IconButton onClick={() => onEdit(car.id)} size='large'>
            <Edit />
          </IconButton>
          <CarMenuButton
            onDelete={() => onDelete(car.id)}
            onTransfer={() => onTransfer(car.id)}
            onShare={() => onShare(car.id)}
          />
        </div>
      </div>
      <div
        className={classes.image}
        style={{ backgroundImage: car.image ? `url(${car.image})` : `url(${ImagePlaceholder})` }}
        onClick={openRecordsPage}
      >
        <Button variant='contained' color='inherit' className={classes.recordsButton} onClick={openRecordsPage}>
          <Description />
          {car.numberOfRecords > 0 && <span className={classes.recordsCount}>{car.numberOfRecords}</span>}
        </Button>
      </div>
      <div className={classes.footer}>
        <div>
          <span className={classes.category}>{t(tKey('plate_number'))}:</span> {car.plate}
        </div>
        <div>
          <span className={classes.category}>
            {t(tKey('mileage'))} ({car.mileageMetric}):
          </span>{' '}
          {car.mileage}
          {/* <CopyToClipboard text={process.env.REACT_APP_PUBLIC_API_URL?.slice(0, -4) + 'siri-voice/' + car.id} onCopy={() => toast(t('generated_link_success_message'))}>
            <ContentCopyIcon style={{float:'right'}} fontSize='small'/>
          </CopyToClipboard> */}
        </div>
      </div>
    </div>
  );
};
