import { useEffect, useState } from 'react';
import { useCarShareQuery } from 'services';

type useCarShareType = (carId: string) => { lastIdentifier: string; count: number; countLoading: boolean };

export const useCarShareData: useCarShareType = (carId) => {
  const [count, setCount] = useState<number>(0);
  const [lastIdentifier, setLastIdentifier] = useState<string>('');
  const { data: shareData, isLoading: countLoading } = useCarShareQuery(carId);

  useEffect(() => {
    if (!countLoading) {
      setCount(shareData.amount);
      setLastIdentifier(shareData?.last_valid_book_identifier ?? '');
    }
  }, [shareData, countLoading]);

  return {
    lastIdentifier,
    count,
    countLoading,
  };
};
