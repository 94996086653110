import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { ComponentType, useState } from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface SelectBoxProps {
  name: string;
  label?: string;
  options: any;
  optionsWithTranslations?: boolean;
  className?: string;
  watchCallback?: (value: string) => void;
}

export const SelectBox: ComponentType<SelectBoxProps> = ({
  name,
  label,
  options,
  optionsWithTranslations,
  className,
  watchCallback,
}) => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext();
  const { errors } = useFormState();
  const hasError = Boolean(errors[name]);
  const helperText = errors[name]?.message;
  const [value, setValue] = useState(options[0].value);
  if (watchCallback) {
    const category = watch('category');
    watchCallback(category);
  }

  return (
    <>
      <FormControl sx={{ display: { md: 'none', xs: 'flex' } }} fullWidth variant='outlined' size='small'>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select
              size='small'
              native
              defaultValue={value}
              className={className ?? ''}
              {...field}
              value={value}
              id={name}
              label={label}
              error={hasError}
              onChange={(event) => {
                field.onChange(event);
                setValue(event.target.value);
              }}
            >
              {options.map((option: any) => {
                return (
                  <option key={option.value} value={option.value}>
                    {optionsWithTranslations ? t(option.label) : option.label}
                  </option>
                );
              })}
            </Select>
          )}
        />
      </FormControl>

      <Controller
        render={({ field }) => (
          <TextField
            sx={{ display: { md: 'block', xs: 'none' } }}
            {...field}
            select
            value={value}
            onChange={(event) => {
              field.onChange(event);
              setValue(event.target.value);
            }}
            fullWidth
            margin='normal'
            variant='outlined'
            label={label}
            error={hasError}
            helperText={helperText}
          >
            {options.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                {optionsWithTranslations ? t(option.label) : option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
        name={name}
        control={control}
      />
    </>
  );
};
