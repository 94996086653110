import { useMutation } from 'react-query';

import { queryClient } from 'components';
import { axiosApiInstance } from 'utils';

type RecordSecure = {
  group_id: string;
  title: string;
};

const updateRecordSecure = async (body: RecordSecure): Promise<void> => {
  const { data } = await axiosApiInstance.put(`/records/group/secure`, body);
  return data;
};

export const useUpdateRecordSecureMutation = () => {
  return useMutation(updateRecordSecure, {
    onSuccess: () => {
      queryClient.invalidateQueries('records');
    },
  });
};
