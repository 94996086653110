import {  Dialog, Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useClasses } from 'utils/hooks/useClasses';
import { IUser } from '../AdminDashboardTypes';
import { AdminUserDetails } from '../AdminUserDetails';

const styles = ({ palette, spacing }: Theme) => ({
    root: {
        width: 'calc(100vw - 160px)',
        height: 'calc(100vh - 180px)',
        display: 'flex',
        flexDirection: 'column',
    },
});

interface UserDetailsDialogProps {
  user: IUser | null
  open: boolean;
  onClose: () => void,
  refresh: () => void,
}

export const UserDetailsDialog: ComponentType<UserDetailsDialogProps> = ({
    user,
    open,
    onClose,
    refresh,
}) => {
  const classes = useClasses(styles);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='xl'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <div className={classes.root}>
        {user && 
            <AdminUserDetails user={user} onClose={onClose} refresh={refresh}/>}
      </div>
    </Dialog>
  );
};
