import { ComponentType, useContext, useState } from 'react';
import { Box, Theme } from '@mui/material';
import { useClasses } from 'utils/hooks/useClasses';
import { AssistantMessage } from './AssistantMessage';
import { LocalPolice, MinorCrash, ReceiptLong, Speed, SvgIconComponent } from '@mui/icons-material';
import { AssistantUserMessage } from './AssistantUserMessage';
import { useTranslation } from 'react-i18next';
import { ProfileContext } from 'store/profile/store';
import clsx from 'clsx';

const styles = ({ spacing, breakpoints, palette }: Theme) => ({
  root: {
    '& h4': {
      margin: 0,
    },
  },
  selected: {
    '& > div': {
      background: '#FFFFFF0D',
    },
  },
  suggestion: {
    '& > div ': {
      borderRadius: spacing(1),
      border: '1px solid #FFFFFF1A',
      cursor: 'pointer',
    },
  },
});

interface questionSuggestionsType {
  iconColor: string;
  icon: SvgIconComponent | React.FunctionComponent;
  text: string;
}

const questionSuggestions: questionSuggestionsType[] = [
  {
    iconColor: '#D839ED',
    icon: Speed,
    text: 'assistant_suggestion_variant_1',
  },
  {
    iconColor: '#FF981F',
    icon: ReceiptLong,
    text: 'assistant_suggestion_variant_2',
  },
  {
    iconColor: '#F54336',
    icon: MinorCrash,
    text: 'assistant_suggestion_variant_3',
  },
  {
    iconColor: '#3A89FF',
    icon: LocalPolice,
    text: 'assistant_suggestion_variant_4',
  },
];

interface AssistantWelcomeProps {
  onClickSuggestion: (question: string) => void;
}

export const AssistantWelcome: ComponentType<AssistantWelcomeProps> = ({ onClickSuggestion }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const [questions] = useState(questionSuggestions);
  const { state: profileState } = useContext(ProfileContext);

  const [selectedItem, setSelectedItem] = useState<number>();
  const handleSelectSuggest = (index: number) => {
    if (!selectedItem) {
      setSelectedItem(index);
      onClickSuggestion(t(questions[index].text));
    }
  };

  return (
    <Box className={classes.root}>
      <AssistantMessage
        country='uk'
        index={1}
        message={t('assistant_suggestion_welcome_text', { username: profileState.username })}
      />
      {questions.map((item, index) => (
        <Box
          key={index}
          className={clsx(selectedItem === index && classes.selected, classes.suggestion)}
          onClick={() => handleSelectSuggest(index)}
        >
          <AssistantUserMessage message={t(item.text)} index={index} Icon={item.icon} colorIcon={item.iconColor} />
        </Box>
      ))}
    </Box>
  );
};
