import { useQuery, UseQueryResult } from 'react-query';

import { axiosApiInstance as api } from 'utils';

const getAmountLeft = async (id: string): Promise<any> => {
  const { data } = await api.get(`/cars/${id}/record/assisted/generate`);

  return data;
};

export const useGeneratedLinksAmountQuery = (id: string): UseQueryResult<any> => {
  return useQuery(['generatedAmountLeft', id], () => getAmountLeft(id), { retry: false });
};
