import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

export const StyledToast = () => {
  const { t } = useTranslation();

  return (
    <ToastContainer
      position='bottom-center'
      autoClose={4000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
      closeButton={
        <>
          <Button color='primary' size='small'>
            {t('__common__close')}
          </Button>
        </>
      }
    />
  );
};
