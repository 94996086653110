import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('unfinished_draft');

const styles = () => ({
  paper: {
    maxWidth: 280,
    backgroundColor: '#333',
  },
  description: {
    color: 'rgba(255, 255, 255, 0.87)',
  },
  actions: {
    '& button': {
      letterSpacing: 1.25,
    },
  },
  loadingContainer: {
    minWidth: 412,
    minHeight: 60,
    textAlign: 'center',
  },
});

interface UnfinishedDraftProps {
  carName: string;
  onCancel: (params: { deleteDraft: boolean }) => void;
  onConfirm: () => void;
}

export const UnfinishedDraftDialog: ComponentType<UnfinishedDraftProps> = ({ carName, onCancel, onConfirm }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();

  return (
    <Dialog open={true} onClose={onCancel} classes={{ paper: classes.paper }}>
      <DialogTitle>{t(tKey('title'))}</DialogTitle>
      <DialogContent>
        <DialogContentText classes={{ root: classes.description }}>{t(tKey('desc'), { carName })}</DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button onClick={() => onCancel({ deleteDraft: true })} color='primary'>
          {t(tKey('remove'))}
        </Button>
        <Button onClick={() => onConfirm()} color='primary'>
          {t(tKey('restore'))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
