import { useQuery } from 'react-query';
import { axiosApiInstance as api, mapKeysToSnakeCase } from 'utils';

type payloadSafeUrl = {
  storyId: string;
  urlToCheck: string;
};

const safeUrl = async (body: payloadSafeUrl) => {
  const { data } = await api.post('data/safe-url', mapKeysToSnakeCase(body));
  return data;
};

export const useSafeUrlQuery = (body: payloadSafeUrl) => {
  return useQuery(['safe-url', body.storyId, body.urlToCheck], () => safeUrl(body), { cacheTime: 0, retry: 0 });
};
