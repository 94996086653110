import { Place } from '@mui/icons-material';
import { Box, Grid, Theme, Typography } from '@mui/material';
import userPlaceholder from 'assets/user-placeholder.jpeg';
import clsx from 'clsx';
import { FollowersDialog, VerifiedIcon } from 'components';
import { PageLoader } from 'components/PageLoader/PageLoader';
import { PatronLarge } from 'components/PatronBadges/PatronLarge';
import { Wrapper } from 'components/Wrapper/Wrapper';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePublicUserFollowers, useUserProfile } from 'services';
import { ProfileContext } from 'store/profile/store';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { nFormatter } from 'utils/n-formatter';

type UserProfileCardParams = {
  username: string;
};
const styles = ({ breakpoints, spacing }: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  count: {
    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'center',
  },
  subHeading: {
    fontWeight: '400',
    fontSize: 12,
    color: '#828796',
    textAlign: 'center',
  },
  userProfilePic: {
    borderRadius: 99,
    objectFit: 'cover',
    objectPosition: 'center',
  },
  imgContainer: {
    position: 'relative',
    width: 87,
  },
  username: {
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
  },
  usernamePatron: {
    color: '#FFCB40',
  },
  icon: {
    fontSize: 15,
    color: '#828796',
    marginRight: 12,
  },
  descContainer: {
    margin: spacing(4, 10, 0, 10),
    [breakpoints.down('sm')]: {
      margin: spacing(4, 2, 0, 2),
    },
  },
  desc: {
    marginTop: 10,
    overflowWrap: 'break-word',
  },
  location: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },
  followerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  followerContainerLeft: {
    alignItems: 'flex-end',
    [breakpoints.down('sm')]: {
      paddingRight: 30,
    },
  },
  followerContainerRight: {
    alignItems: 'flex-start',
    [breakpoints.down('sm')]: {
      paddingLeft: 30,
    },
  },
});

const tKey = getTKey('user_profile');
export const UserProfileCard = ({ username }: UserProfileCardParams) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const { data: profile, isLoading } = useUserProfile();
  const { state } = useContext(ProfileContext);
  const { data: followers, isLoading: loading, refetch } = usePublicUserFollowers(state.id);
  const imageUrl = profile?.image === '' ? userPlaceholder : profile?.image;

  const [openFollowersDialog, setOpenFollowersDialog] = useState(false);
  const [openFollowingsDialog, setOpenFollowingDialog] = useState(false);

  const handleFollow = () => {
    refetch();
  };

  useEffect(() => {
    document.addEventListener('follow', handleFollow);
    return () => {
      document.removeEventListener('follow', handleFollow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading && loading ? (
    <PageLoader />
  ) : (
    <Wrapper>
      <Grid container className={classes.container}>
        <Grid item xs={4} className={clsx(classes.followerContainer, classes.followerContainerLeft)}>
          <Box onClick={() => setOpenFollowersDialog(true)}>
            <Typography className={classes.count}>{nFormatter(followers?.followers ?? 0)}</Typography>
            <Typography className={classes.subHeading}>{t(tKey('followers'))}</Typography>
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.container}>
          <Box className={classes.imgContainer}>
            <img src={imageUrl} alt='Logo' width={87} height={87} className={classes.userProfilePic} />
            {profile?.is_trusted && <VerifiedIcon size='md' />}
          </Box>
        </Grid>
        <Grid item xs={4} className={clsx(classes.followerContainer, classes.followerContainerRight)}>
          <Box onClick={() => setOpenFollowingDialog(true)}>
            <Typography className={classes.count}>{nFormatter(followers?.following ?? 0)}</Typography>
            <Typography className={classes.subHeading}>{t(tKey('following'))}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.descContainer}>
        <Typography className={clsx(classes.username, profile?.is_patron && classes.usernamePatron)}>
          {username}
        </Typography>
        {profile?.bio && <Typography className={clsx(classes.desc, classes.subHeading)}>{profile.bio}</Typography>}{' '}
        {(profile?.city || profile?.country) && (
          <Box className={classes.location}>
            <Place className={classes.icon} />
            <Typography className={classes.subHeading}>{`${profile.city ? profile.city + ',' : ''} ${
              profile.country
            }`}</Typography>
          </Box>
        )}
        {profile?.is_patron && (
          <Grid display={'flex'} justifyContent='center' padding={1}>
            <PatronLarge />
          </Grid>
        )}
      </Box>
      {openFollowersDialog && (
        <FollowersDialog open={openFollowersDialog} onClose={() => setOpenFollowersDialog(false)} follows='followers' />
      )}
      {openFollowingsDialog && (
        <FollowersDialog
          open={openFollowingsDialog}
          onClose={() => setOpenFollowingDialog(false)}
          follows='followings'
        />
      )}
    </Wrapper>
  );
};
