import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useDraftsQuery, useUpdateDraftMutation } from 'services';
import { Record } from 'types';

type useEditAssistedRecordPageType = () => {
  currentDraft: Record | undefined;
  onSubmit: (data: any) => void;
  isSubmitting: boolean;
};

export const useEditAssistedRecordPage: useEditAssistedRecordPageType = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { carId, identifier, recordId } = useParams<{ carId: string; identifier: string; recordId: string }>() as {
    carId: string;
    identifier: string;
    recordId: string;
  };
  const [currentDraft, setCurrentDraft] = useState<Record>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { data: draftsData, isFetching, refetch } = useDraftsQuery(carId, identifier);
  const mutation = useUpdateDraftMutation();

  useEffect(() => {
    if (!isFetching && draftsData) {
      const findResult = draftsData.find((item: Record) => item.id === recordId);

      setCurrentDraft(findResult);
    }
  }, [isFetching, draftsData, recordId]);

  const onSubmit = (data: any) => {
    setIsSubmitting(true);

    mutation.mutate(
      { carId, recordId, formData: data },
      {
        onSuccess: () => {
          setIsSubmitting(false);
          refetch();
          toast(t('assisted_record_draft_updated'));
          navigate(`/assisted/${identifier}`);
        },
        onError: () => {
          console.warn('Something went wrong');
          setIsSubmitting(false);
        },
      }
    );
  };

  return {
    currentDraft,
    onSubmit,
    isSubmitting,
  };
};
