import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

export const patronUser = async (body: { [key: string]: boolean }): Promise<any> => {
  try {
    const { data } = await api.put(`/engineroom/patron`, [body]);
    return data;
  } catch (err) {
    return { error: true };
  }
};

export const usePatronUserMutation = () => {
  return useMutation(patronUser);
};
