import { useStoryReportMutation } from 'services/stories/hooks/useStoryReportMutation';
import { ReportStoryPayload } from '../StoriesTypes';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

type useStoryReportType = () => {
  report: (payload: ReportStoryPayload) => void;
};

export const useStoryReport: useStoryReportType = () => {
  const { t } = useTranslation();
  const mutation = useStoryReportMutation();

  const report = (payload: ReportStoryPayload) => {
    mutation.mutate(payload, {
      onSuccess(data, variables, context) {
        toast(t('report_story_success'));
      },
      onError(error: any, variables, context) {
        if (error?.response?.data?.message === "report_already_exists_error")
        {
          toast(t("report_story_conflict"))
          return
        }
        toast(t('report_story_fail'));
      },
    });
  };

  return {
    report,
  };
};
