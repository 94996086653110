import { InputBaseComponentProps, TextField, TextFieldProps } from '@mui/material';
import clsx from 'clsx';
import { ComponentType, useEffect } from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { useClasses } from 'utils/hooks/useClasses';

interface TextInputProps {
  name: string;
  label: string;
  textarea?: boolean;
  textFieldProps?: TextFieldProps;
  inputProps?: InputBaseComponentProps;
  customHelperText?: string;
  setHeight?: any;
}

const styles = () => ({
  root: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '& label.Mui-focused': {
      color: '#bb86fc',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#bb86fc',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.87)',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#bb86fc',
      },
    },
  },
  setHeight: {
    '&.MuiFormControl-root': {
      height: '85%',
    },
    '& .MuiInputBase-root': {
      padding: '10.5px 14px',
      height: '100%',
    },
  },
  removeArrows: {
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
});

export const TextInputControlled: ComponentType<TextInputProps> = ({
  name,
  label,
  textarea = false,
  textFieldProps,
  inputProps,
  customHelperText,
  setHeight,
}) => {
  const { control, setValue } = useFormContext();
  const { errors } = useFormState();
  const hasError = Boolean(errors[name]);
  const helperText = customHelperText ?? errors[name]?.message;

  const classes = useClasses(styles);

  useEffect(() => {
    if (textFieldProps?.value || textFieldProps?.value === '') {
      setValue(name, textFieldProps?.value);
    }
  }, [textFieldProps?.value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          {...textFieldProps}
          inputProps={{ ...inputProps }}
          {...(textarea && { multiline: true })}
          {...(textarea && { rows: 4 })}
          fullWidth
          margin='normal'
          label={label}
          variant='outlined'
          error={hasError}
          helperText={helperText}
          InputLabelProps={{ shrink: field.value ? true : false }}
          classes={{
            root: clsx(
              classes.root,
              setHeight && classes.setHeight,
              textFieldProps?.type === 'number' && classes.removeArrows
            ),
          }}
          autoComplete='off'
        />
      )}
    />
  );
};
