import { Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Bar, BarChart, LabelList, ResponsiveContainer } from 'recharts';
import store from 'store2';

import { getTKey, months, roundNumber } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { BoardFilters } from './DashboardFilters';

const tKey = getTKey('dashboard');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    padding: spacing(1, 2.5, 2),
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: spacing(0.5),
    border: '1px solid rgba(255, 255, 255, 0.08)',
  },
  header: {
    [breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 'auto',
    },
  },
  body: {
    flexGrow: 1,
    alignSelf: 'flex-end',
    height: 165,
    overflowX: 'auto',
    overflowY: 'hidden',

    [breakpoints.up('sm')]: {
      overflow: 'hidden',
    },
  },
  chartWrapper: {
    width: '175vw',
    height: '100%',

    [breakpoints.up('md')]: {
      width: '100%',
    },
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  infoExpanded: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  totalValue: {
    display: 'block',
    fontSize: 34,
    fontWeight: 500,
    color: '#00B3A6',

    [breakpoints.up('md')]: {
      display: 'initial',
      marginLeft: spacing(2),
      color: 'inherit',
    },
  },
  hint: {
    fontSize: 12,

    [breakpoints.down('md')]: {
      display: 'none',
    },
  },
});

const CustomizedDataLabel: ComponentType = (props: any) => {
  const { x, y, width, value } = props;

  return (
    <g>
      <text x={x + width / 2} y={y - 10} fill='#fff' textAnchor='middle' dominantBaseline='middle'>
        {value ? value : ''}
      </text>
    </g>
  );
};

interface DashboardChartProps {
  totalValue: number;
  data: any;
  mileageMetric: string | undefined;
  type?: 'expenses' | 'mileage';
  filters?: BoardFilters;
  currency?: string;
  publicChart?: boolean;
}

type ChartPoint = { name: number | string; value: number | string };

const mapChartData = (data: number[]): ChartPoint[] => {
  let tmp = [];
  for (let i = 0; i < 12; i++) {
    if (data[i] === undefined) {
      tmp.push({ name: i, value: '' });
    } else {
      tmp.push({ name: i, value: roundNumber(data[i]) });
    }
  }
  return tmp;
};

const CustomizedXAxisLabel: ComponentType = (props: any) => {
  const { t } = useTranslation();
  const { x, width, value } = props;

  return (
    <g>
      <text x={x + width / 2} y={160} fill='rgba(255, 255, 255, 0.6)' textAnchor='middle' dominantBaseline='middle'>
        {t(`__month__${months[value]}`)}
      </text>
    </g>
  );
};

export const DashboardChart: ComponentType<DashboardChartProps> = ({
  type = 'expenses',
  totalValue,
  data,
  mileageMetric,
  filters,
  currency,
  publicChart,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const navigate = useNavigate();
  const settingsData = store('driverbook_settings');
  const chartData = mapChartData(data);
  const { identifier } = useParams<{ identifier: string }>();

  const onClickHandler = (barData: any): void => {
    if (barData.value) {
      publicChart
        ? navigate(
            `/book/dashboard/details/${identifier}?year=${filters?.year}&month=${barData.name}&category=${filters?.category}`
          )
        : navigate(
            `/dashboard/${filters?.carId}?year=${filters?.year}&month=${barData.name}&category=${filters?.category}`
          );
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.info}>
          {type === 'expenses'
            ? `${t(tKey('expenses'))} (${publicChart ? currency : settingsData?.currency})`
            : `${t(tKey('mileage'))} (${mileageMetric})`}
          :<span className={classes.totalValue}>{roundNumber(totalValue)}</span>
        </div>

        {<div className={classes.hint}>{t('dashboard_click_hint')}</div>}
      </div>
      <div className={classes.body}>
        <div className={classes.chartWrapper}>
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart data={chartData} margin={{ top: 20, right: 0, bottom: 20, left: 0 }} key={Math.random()}>
              <Bar
                dataKey='value'
                label={<CustomizedDataLabel />}
                fill={type === 'expenses' ? '#00B3A6' : '#7F39FB'}
                radius={[4, 4, 0, 0]}
                onClick={onClickHandler}
                minPointSize={4}
              >
                <LabelList dataKey='name' content={<CustomizedXAxisLabel />} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};
