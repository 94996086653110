import { useEffect, useState } from 'react';
import { useCarTransferQuery } from 'services';

type useCarTransferType = () => { count: Number; countLoading: boolean };

export const useCarTransferData: useCarTransferType = () => {
  const [count, setCount] = useState<Number>(0);
  const { data: transferCount, isFetching: countLoading } = useCarTransferQuery();

  useEffect(() => {
    if (!countLoading && transferCount) {
      setCount(transferCount);
    }
  }, [transferCount, countLoading]);

  return {
    count,
    countLoading,
  };
};
