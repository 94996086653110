import { ComponentType } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { useClasses } from 'utils/hooks/useClasses';
import AssistantAvatarIcon from 'components/CustomIcons/AssistantAvatarIcon';
import { countryFlags } from 'utils/country-flags';
import { getFlagByCode } from 'utils/assistantLanguage';
const styles = ({ spacing, breakpoints, palette }: Theme) => ({
  root: {
    position: 'relative',
    '& svg': {
      width: 29,
      height: 29,
    },
  },
  country: {
    position: 'absolute',
    bottom: 0,
    transform: 'translate(40%, 30%)',
  },
});

interface assistantAvatarProps {
  country: string;
}

export const AssistantAvatar: ComponentType<assistantAvatarProps> = ({ country }) => {
  const classes = useClasses(styles);

  return (
    <Box className={classes.root}>
      <AssistantAvatarIcon />
      <Typography className={classes.country}>{countryFlags[getFlagByCode(country)]}</Typography>
    </Box>
  );
};
