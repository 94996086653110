import { useMutation } from 'react-query';

import { axiosApiInstance as api } from 'utils';

const changeDonotTranslateLanguage = async (body: string[]): Promise<any> => {
  try {
    const { data } = await api.post('/users/languages', { do_not_translate_languages: body });
    return data;
  } catch (error) {
    return { error: true };
  }
};

export const useChangeDonotTranslateLanguageMutation = () => {
  return useMutation(changeDonotTranslateLanguage);
};
