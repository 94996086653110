import { Theme, Typography } from '@mui/material';
import { ComponentType } from 'react';
import { useClasses } from 'utils/hooks/useClasses';
import EmptyShelves from 'assets/empty-shelves.png';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    textAlign: 'center',
  },
  iconWrapper: {
    marginBottom: spacing(3),
  },
  icon: {
    fontSize: 100,
  },
  text: {
    fontSize: 16,
    opacity: 1,

    [breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
  emptyRecords: {
    margin: spacing(6, 0, 2),
    maxWidth: 160,
  },
});

export const EmptyRecords: ComponentType<{ title: string }> = ({ title }) => {
  const classes = useClasses(styles);

  return (
    <div className={classes.root}>
      <div className={classes.iconWrapper}>
        <img className={classes.emptyRecords} src={EmptyShelves} alt='empty' />
      </div>
      <Typography variant='body1' color='inherit' className={classes.text}>
        {title}
      </Typography>
    </div>
  );
};
