import { ArrowBack } from '@mui/icons-material';
import FilterList from '@mui/icons-material/FilterList';
import { Badge, Button, ButtonGroup, Collapse, IconButton, Theme } from '@mui/material';
import clsx from 'clsx';
import { Form, StyledButton } from 'components';
import { SearchInputControlled } from 'components/Form/SearchInputControlled';
import { LanguageSwitch } from 'components/Language/LanguageSwitch';
import { UnregisteredPopup } from 'components/UnregisteredPopup/UnregisteredPopup';
import { MakesModelsFields } from 'features/cars/components/CarForm';
import { useCarMakesData } from 'features/cars/components/useCarMakesData';
import { TFunction } from 'i18next';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import * as yup from 'yup';
const tKey = getTKey('story_search');

const styles = ({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    borderRadius: 4,
    background: '#2E2E2E',
    padding: spacing(1),
    display: 'flex',
    flexDirection: 'column',
    marginBottom: spacing(1),
    [breakpoints.down('sm')]: {
      background: 'none',
      padding: 0,
    },
  },
  rootOpened: {
    [breakpoints.down('sm')]: {
      width: '100%',
      top: 0,
      left: 0,
      background: palette.background.default,
      zIndex: 8,
      margin: 0,
      padding: spacing(2),
      position: 'fixed',
    },
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  searchIconButton: {
    color: palette.text.hint,
  },
  collapseButton: {
    color: palette.text.primary,
  },
  collapseButtonOpened: {
    color: palette.primary.main,
  },
  collapseIconButton: {
    borderRadius: 4,
    marginLeft: spacing(0.5),
    '& .MuiBadge-dot': {
      top: 5,
      right: 5,
    },
  },
  collapseIconButtonOpened: {
    background: 'rgba(187, 134, 252, 0.08)',
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  additional: {
    marginTop: spacing(2.5),
  },
  additionalHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: spacing(0, 0.5),
  },
  headerTitle: {
    fontSize: 16,
  },
  searchBySwitch: {
    '& button': {
      fontSize: 14,
      textTransform: 'none',
      borderRadius: spacing(2),
      minWidth: 70,
      border: '1px solid rgba(255, 255, 255, 0.12)',
    },
    '& .MuiButton-outlinedPrimary': {
      color: 'rgba(232, 222, 248, 1)',
      background: 'rgba(98, 0, 238, 0.1)',
    },
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: spacing(2, 0, 0),
    width: '100%',
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      '& label': {
        background: '#121212 !important',
      },
    },

    '& > div': {
      width: `calc(50% - ${spacing(1.5)})`,

      [breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },

  actionButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
    margin: spacing(2, 0, 0),
    [breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  applyButton: {
    padding: `${spacing(1.5, 5)} !important`,
    [breakpoints.down('sm')]: {
      padding: `${spacing(1.5, 9)} !important`,
    },
  },
  resetButton: {
    marginRight: spacing(1),
    padding: spacing(1.5),
    borderRadius: spacing(3),
    letterSpacing: '1.25px',
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileHeader: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(0, 0, 2),
    display: 'none',
    [breakpoints.down('sm')]: {
      display: 'flex',
      paddingTop: 'env(safe-area-inset-top)',
    },
  },
  resetButtonMobile: {
    letterSpacing: '1.25px',
  },
  backButtonIcon: {
    color: palette.text.primary,
  },
  overlay: {
    [breakpoints.down('sm')]: {
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 5,
      height: '100vh',
      width: '100vw',
      background: palette.background.default,
      overflowY: 'hidden',
    },
  },
  mobileHeaderTitle: {
    fontWeight: 500,
    fontSize: 20,
    flex: 1,
    padding: spacing(0, 3),
  },
  hiddenCarFilter: {
    display: 'none',
  },
});

interface StorySearchType {
  onSearchChange: (data: any, type: SearchBy) => void;
  authorized: boolean;
  reset: () => void;
}

const validationSchema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    sField: yup.string(),
    make: yup.object().nullable(),
    model: yup.object().nullable(),
  });

export type SearchBy = 'story' | 'user';

export const StorySearch: ComponentType<StorySearchType> = ({ onSearchChange, authorized, reset }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { makes, makesLoading } = useCarMakesData();

  const [opened, setOpened] = useState(false);
  const [searchBy, setSearchBy] = useState<SearchBy>('story');
  const defaultValues = { sField: '', make: null, model: null };
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [openUnregistered, setOpenUnregistered] = useState<boolean>(false);
  const [resetValue, setResetValue] = useState(0);

  const checkPartlyFilledForm = (data: any) => {
    return data.sField || data.make || data.model ? true : false;
  };

  const onSubmit = (data: any) => {
    if (checkPartlyFilledForm(data)) {
      setOpened(false);
      setSubmitted(true);
      onSearchChange(data, searchBy);
    }
  };

  const onReset = () => {
    setOpened(false);
    setSubmitted(false);
    setResetValue(resetValue + 1);
    reset();
  };

  const onSearch = (search: string) => {
    if (search?.trim()) {
      setSubmitted(true);
      onSearchChange(search, searchBy);
      setOpened(false);
    }
  };

  const toggleSearchBy = (by: SearchBy) => {
    if (!authorized && by === 'user') {
      setOpenUnregistered(true);
      return;
    }
    setSearchBy(by);
  };

  useEffect(() => {
    if (opened && window.innerWidth < 600) {
      document.body.style.overflowY = 'hidden';
    }
    if (!opened) {
      document.body.style.removeProperty('overflow-y');
    }
  }, [opened]);

  return (
    <>
      <div className={clsx(classes.root, [opened && classes.rootOpened])}>
        {opened && (
          <div className={classes.mobileHeader}>
            <IconButton onClick={() => setOpened((prev) => !prev)}>
              <ArrowBack className={classes.backButtonIcon} />
            </IconButton>
            <div className={classes.mobileHeaderTitle}>{t(tKey('mobile_title'))}</div>
            <Button className={classes.resetButtonMobile} type='reset' onClick={onReset}>
              {t(tKey('reset_button'))}
            </Button>
          </div>
        )}
        <Form
          schema={validationSchema(t)}
          onSubmit={onSubmit}
          shouldReset={false}
          defaultValues={defaultValues}
          resetValue={resetValue}
        >
          <div className={classes.main}>
            {!authorized && <LanguageSwitch />}
            <SearchInputControlled
              label={t(tKey('search_label'))}
              name='sField'
              onSearch={onSearch}
              onReset={onReset}
            />
            <IconButton
              onClick={() => setOpened((prev) => !prev)}
              className={clsx(classes.collapseIconButton, [opened && classes.collapseIconButtonOpened])}
            >
              <Badge color='error' variant='dot' invisible={!submitted}>
                <FilterList className={opened ? classes.collapseButtonOpened : classes.collapseButton} />
              </Badge>
            </IconButton>
          </div>
          {!makesLoading && (
            <Collapse in={opened}>
              <div className={classes.additional}>
                <div className={classes.additionalHeader}>
                  <div className={classes.headerTitle}>{t(tKey('search_by_title'))}</div>
                  <ButtonGroup variant='outlined' aria-label='outlined button group' className={classes.searchBySwitch}>
                    <Button
                      color={searchBy === 'story' ? 'primary' : 'neutral'}
                      onClick={() => toggleSearchBy('story')}
                    >
                      {t(tKey('switch_stories'))}
                    </Button>
                    <Button color={searchBy === 'user' ? 'primary' : 'neutral'} onClick={() => toggleSearchBy('user')}>
                      {t(tKey('switch_users'))}
                    </Button>
                  </ButtonGroup>
                </div>
                <div className={clsx(classes.filters, [searchBy !== 'story' && classes.hiddenCarFilter])}>
                  <MakesModelsFields makes={makes} labelBackground='#2E2E2E' />
                </div>
                <div className={classes.actionButtons}>
                  <Button className={classes.resetButton} type='reset' onClick={onReset}>
                    {t(tKey('reset_button'))}
                  </Button>
                  <StyledButton label={t(tKey('apply_button'))} type='submit' className={classes.applyButton} />
                </div>
              </div>
            </Collapse>
          )}
        </Form>
        <UnregisteredPopup
          open={openUnregistered}
          from={pathname}
          onClose={() => {
            setOpenUnregistered(false);
          }}
        />
      </div>
      {opened && <div className={classes.overlay}></div>}
    </>
  );
};
