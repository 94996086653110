import { useQuery, UseQueryResult } from 'react-query';

import { Currency } from 'types';
import { axiosApiInstance as api } from 'utils';

interface ExchangeRatePayload {
  rate: number;
  explanation: string;
}

const getExchangeRate = async (fromCurrency: Currency, toCurrency: Currency): Promise<ExchangeRatePayload> => {
  const { data } = await api.get('/settings/currency', {
    params: {
      from_currency: fromCurrency,
      to_currency: toCurrency,
    },
  });
  return data;
};

export const useExchangeRateQuery = (
  fromCurrency: Currency,
  toCurrency: Currency
): UseQueryResult<ExchangeRatePayload> => {
  return useQuery(['exchangeRate', fromCurrency, toCurrency], () => getExchangeRate(fromCurrency, toCurrency));
};
