import { Button, CircularProgress, Theme } from '@mui/material';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useClasses } from 'utils/hooks/useClasses';
import { SvgIconComponent } from '@mui/icons-material';

interface StyledButtonProps {
  label: string;
  disabled?: boolean;
  onClick?: any;
  type?: 'button' | 'submit';
  color?: 'primary' | 'secondary';
  loading?: boolean;
  loadingLabel?: string;
  icon?: string;
  className?: string;
  LeftMuiIcon?: SvgIconComponent | React.FunctionComponent;
}

const styles = ({ spacing }: Theme) => ({
  root: {
    padding: spacing(1.5, 7.5),
    borderRadius: spacing(3),
    fontSize: 14,
    fontWeight: 600,
    color: '#000',
    letterSpacing: 1.25,

    '&.Mui-disabled': {
      backgroundColor: 'rgba(70,69,69,0.48)',
    },
  },
  icon: {
    height: 25,
    paddingLeft: spacing(2),
  },
  loading: {
    color: 'black',
    marginLeft: spacing(1),
  },
  leftMuiIcon: {
    marginRight: spacing(2.5)
  },
});

export const StyledButton: ComponentType<StyledButtonProps> = ({
  label,
  disabled = false,
  onClick,
  type = 'button',
  color = 'primary',
  loading = false,
  loadingLabel = '__common__loading',
  className,
  icon,
  LeftMuiIcon
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);

  return (
    <Button
      type={type}
      variant='contained'
      color={color}
      size='large'
      classes={{ root: clsx(classes.root, className) }}
      disabled={disabled}
      onClick={onClick}
    >
      {!loading && LeftMuiIcon ? ( <LeftMuiIcon className={classes.leftMuiIcon} /> ) : (<></>) }
      {loading ? t(loadingLabel) : label}
      {loading ? (
        <div>
          <CircularProgress classes={{ root: classes.loading }} size={15} />
        </div>
      ) : (
        icon && <img src={icon} alt='icon' className={classes.icon} />
      )}
    </Button>
  );
};
