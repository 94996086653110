import { useMutation } from 'react-query';

import { AssistedRecordIdentifier } from 'types';
import { axiosApiInstance as api } from 'utils';

const submitAssistedRecord = async (body: AssistedRecordIdentifier): Promise<any> => {
  const { data } = await api.post('/assisted/submit', body);

  return data;
};

export const useCreateAssistedRecordMutation = () => {
  return useMutation(submitAssistedRecord);
;}
