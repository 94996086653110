import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('google_connect_dialog');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  paper: {
    maxWidth: 460,
    backgroundColor: '#333',
  },
  description: {
    color: 'rgba(255, 255, 255, 0.87)',
  },
  actions: {
    '& button': {
      letterSpacing: 1.25,
    },
  },
});

interface ConnectGoogleDialogProps {
  open: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onConnect?: () => void;
  social: string;
}

export const ConnectGoogleDialog: ComponentType<ConnectGoogleDialogProps> = ({ open, onClose, onConnect, social }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle id='alert-dialog-title'>{t(tKey('title_' + social))}</DialogTitle>
      <DialogContent>
        <DialogContentText classes={{ root: classes.description }}>
          {t(tKey('description_' + social))}
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button onClick={onClose}>{t(tKey('disagree'))}</Button>
        <Button onClick={onConnect} autoFocus>
          {t(tKey('agree'))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
