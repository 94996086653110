import _ from 'lodash';
import { useMutation } from 'react-query';
import { Record, RecordAITypeShort } from 'types';
import { axiosApiInstance as api, mapKeysToCamelCase } from 'utils';

export type createRecordsFromInvoiceType = {
  carId: string;
  body: createRecordsFromInvoiceBody;
};

export type createRecordsFromInvoiceBody = {
  file: File;
  language: string;
};

type createRecordFromInvoiceResponse = {
  type: RecordAITypeShort;
  records: Record[];
  error?: boolean;
  errorData?: any;
};

export const createRecordsFromInvoice = async ({
  body,
  carId,
}: createRecordsFromInvoiceType): Promise<createRecordFromInvoiceResponse> => {
  try {
    const formData = new FormData();
    formData.append('file', body.file);
    formData.append('language', body.language);
    let { data } = await api.post(`cars/${carId}/records/from-invoice`, formData);

    let records = data.records.map((record: any) => mapKeysToCamelCase(record));
    records = records.map((record: any) => {
      return { ...record, tmpId: _.uniqueId() };
    });

    return {
      type: data.ai_type,
      records: records,
    };
  } catch (err: any) {
    return { error: true, type: 'itr', records: [], errorData: err.response.data };
  }
};

export const useCreateRecordsFromInvoiceMutation = () => {
  return useMutation(createRecordsFromInvoice);
};
