import { useQuery, UseQueryResult } from 'react-query';
import { UserProfile } from 'types';

import { axiosApiInstance as api } from 'utils';

const getPublicUserProfile = async (id: string): Promise<UserProfile | { error?: boolean }> => {
  const { data } = await api.get(`users/${id}/user-profile`);
  return data;
};

export const usePublicUserProfile = (id: string): UseQueryResult<UserProfile> => {
  return useQuery(['publicUserProfile', id], () => getPublicUserProfile(id), {
    cacheTime: 0,
    retry: 0,
  });
};
