import { getStoriesPayload } from 'features/admin-panel/components/AdminDashboardTypes';
import { useQuery, UseQueryOptions } from 'react-query';
import { axiosApiInstance as api, mapKeysToSnakeCase } from 'utils';

export const getStories = async (body: getStoriesPayload) => {
  try {
    const { data } = await api.get(`/engineroom/stories`, { params: mapKeysToSnakeCase(body) });
    return data;
  } catch (err) {
    return { error: true };
  }
};

export const useStoriesQuery = (
  body: getStoriesPayload,
  options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>
) => {
  return useQuery('adminGetStories', () => getStories(body), options);
};
