import { Badge, Theme } from '@mui/material';
import { ComponentType, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import store from 'store2';
import { useClasses } from 'utils/hooks/useClasses';
import { menuItems } from './menuItems';
import patronIcon from 'assets/Patron-Icon.svg';
import clsx from 'clsx';
import { Capacitor } from '@capacitor/core';
import { ProfileContext as Context } from 'store/profile/store';
import { pulse } from 'animations/Pulse';
import { hasKey } from 'utils';
const styles = ({ palette, spacing }: Theme) => ({
  root: {
    padding: 5,
  },
  tab: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    minHeight: 48,
    padding: spacing(1.5, 2),
    borderRadius: spacing(0.5),
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'none',
    textDecoration: 'none',
    color: palette.text.primary,

    '& .MuiSvgIcon-root': {
      marginRight: spacing(4),
      color: palette.text.hint,
    },
  },
  patron: {
    color: '#FFCB40',
    '& > img': {
      marginRight: spacing(4),
    },
  },
  selected: {
    backgroundColor: '#4B01D0',
  },
  badge: {
    '& span': {
      right: 36,
      top: 8,
      outline: '1px solid #27212f',
    },
  },
  pulse: {
    '& span:before': {
      content: '""',
      position: 'absolute',
      border: '2px solid #d32f2f',
      left: -1,
      right: -1,
      top: -1,
      bottom: -1,
      borderRadius: '50%',
      animation: `${pulse[0]} 1.5s infinite`,
    },
  },
});

const TabLabel: ComponentType<{ label: string }> = ({ label }) => {
  const { t } = useTranslation();

  return <div>{t(label)}</div>;
};
type SettingsMenuDesktopProps = {
  startDemo: () => void;
};

export const SettingsMenuDesktop: ComponentType<SettingsMenuDesktopProps> = ({ startDemo }) => {
  const classes = useClasses(styles);
  const country = store('driverbook_country');
  const validCountry = country === 'poland' || country === 'ukraine';
  const { state } = useContext(Context);

  const getNavLinkClass = ({ isActive }: { isActive: boolean }) => {
    return isActive ? `${classes.tab} ${classes.selected}` : classes.tab;
  };
  return (
    <>
      {!Capacitor.isNativePlatform() && (
        <NavLink
          key={'patron'}
          to={{ pathname: 'https://buymeacoffee.com/driverbook' }}
          target='_blank'
          className={clsx(classes.tab, classes.patron)}
        >
          <img src={patronIcon} alt='Logo' width={24} height={24}></img>
          <TabLabel label={'patron_become'} />
        </NavLink>
      )}
      {menuItems.map(({ to, label, icon: Icon, isBadge, keyBadge, keyPulse }) => {
        if (!validCountry && to === '/calculator') {
          return <></>;
        } else if (to)
          return (
            <NavLink key={to} to={to} className={getNavLinkClass}>
              {isBadge &&
              keyBadge !== undefined &&
              keyPulse !== undefined &&
              hasKey(state, keyBadge) &&
              hasKey(state, keyPulse) ? (
                <Badge
                  color='error'
                  variant='dot'
                  invisible={!state[keyBadge]}
                  className={clsx(classes.badge, [state[keyPulse] && classes.pulse])}
                >
                  <Icon />
                </Badge>
              ) : (
                <Icon />
              )}
              <TabLabel label={label} />
            </NavLink>
          );
        else {
          return (
            <div key={label} className={classes.tab} onClick={() => startDemo()}>
              <Icon />
              <TabLabel label={label} />
            </div>
          );
        }
      })}
    </>
  );
};
