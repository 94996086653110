import { Box, Divider, Grid, Theme, Typography } from '@mui/material';
import { ComponentType } from 'react';
import { Trans } from 'react-i18next';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
const tKey = getTKey('record_how_to');
const styles = ({ palette, breakpoints, spacing }: Theme) => ({
  box: {
    background: '#252525',
    borderRadius: 4,
    padding: spacing(3, 2),
    margin: spacing(0, 0, 4),
  },
  title: {
    textTransform: 'uppercase',
    color: palette.text.hint,
    marginBottom: spacing(2),
  },
  step: {
    padding: spacing(1, 0),
  },
  number: {
    width: 36,
    height: 36,
    minWidth: 36,
    minHeight: 36,
    borderRadius: 40,
    background: '#017374',
  },
  divider: {
    background: '#313131',
    marginTop: spacing(2),
  },
});

export type Step = {
  number: number;
  textKey: string;
  text?: any;
};

type HowToType = 'invoice' | 'voice';

interface AIHowToType {
  title: string;
  type: HowToType;
  steps: Step[];
}

export const AIHowTo: ComponentType<AIHowToType> = ({ steps, title, type }) => {
  const classes = useClasses(styles);

  return (
    <Box className={classes.box}>
      <Typography variant='body2' className={classes.title}>
        {title}
      </Typography>
      {steps.map((step, i) => {
        return (
          <Box key={step.number}>
            <Box className={classes.step}>
              <Grid display='flex'>
                <Box className={classes.number} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  <Typography variant='body1'>{step.number}</Typography>
                </Box>
                <Box
                  className={classes.text}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  marginLeft={2}
                >
                  <Typography variant='body1'>
                    <Trans i18nKey={tKey(type + '_' + step.textKey)}>{step.text}</Trans>
                  </Typography>
                </Box>
              </Grid>

              {i !== steps.length - 1 && <Divider className={classes.divider} />}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
