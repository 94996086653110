import { ComponentType } from 'react';

import { AssistedLayout } from 'components';

export const AssistedRecordsPage: ComponentType = () => {
  return (
    <AssistedLayout>
      {' '}
    </AssistedLayout>
  );
};
