import { useBanUserMutation, useWarnUserMutation, wrapBanUserPayload, wrapWarnUserPayload } from 'services/admin';
import { useTrustUserMutation } from 'services/admin/hooks/useTrustUserMutation';
import { useUntrustUserMutation } from 'services/admin/hooks/useUntrustUserMutation';
import { unblockUserPayload, useUnblockUserMutation } from 'services/admin/hooks/useUnblockUserMutation';
import { useCorrectUserMutation } from 'services/admin/hooks/useCorrectUserMutation';
import { ElementToCorrect } from '../components/AdminReview/AdminItemTypes';
import { useApproveUserMutation } from 'services/admin/hooks/useApproveUserMutation';
import { BanType } from '../components/AdminDashboardTypes';
import { usePatronUserMutation } from 'services/admin/hooks/usePatronUserMutation';

type useAdminUserModerateType = () => {
  banUser: (payload: wrapBanUserPayload) => Promise<any>;
  warnUser: (payload: wrapWarnUserPayload) => Promise<any>;
  trustUser: (id: string) => Promise<any>;
  untrustUser: (id: string) => Promise<any>;
  unblockUser: (id: string, from: BanType) => Promise<any>;
  profileApprove: (id: string) => Promise<any>;
  profileCorrect: (id: string, elements: ElementToCorrect[]) => Promise<any>;
  patronUser: (email: string, value: boolean) => Promise<any>;
};

export const useAdminUserModerate: useAdminUserModerateType = () => {
  const mutationBanUser = useBanUserMutation();
  const mutationWarnUser = useWarnUserMutation();
  const mutationTrustUser = useTrustUserMutation();
  const mutationUntrustUser = useUntrustUserMutation();
  const mutationUnBlockUser = useUnblockUserMutation();
  const mutationApproveProfile = useApproveUserMutation();
  const mutationCorrectProfile = useCorrectUserMutation();
  const mutationPatronUser = usePatronUserMutation();
  const banUser = (payload: wrapBanUserPayload) => {
    return new Promise((resolve) => {
      mutationBanUser.mutate(payload, {
        onSuccess(data) {
          resolve(data);
        },
      });
    });
  };

  const warnUser = (payload: wrapWarnUserPayload) => {
    return new Promise((resolve) => {
      mutationWarnUser.mutate(payload, {
        onSuccess(data) {
          resolve(data);
        },
      });
    });
  };

  const trustUser = (id: string) => {
    return new Promise((resolve) => {
      mutationTrustUser.mutate(id, {
        onSuccess(data) {
          resolve(data);
        },
      });
    });
  };

  const untrustUser = (id: string) => {
    return new Promise((resolve) => {
      mutationUntrustUser.mutate(id, {
        onSuccess(data) {
          resolve(data);
        },
      });
    });
  };

  const unblockUser = (id: string, from: BanType) => {
    const body: unblockUserPayload = {
      id,
      isCommentBanned: !(from === 'comment'),
      isStoryBanned: !(from === 'story'),
      isProfileBanned: !(from === 'profile'),
      isCarBanned: !(from === 'car'),
    };
    return new Promise((resolve) => {
      mutationUnBlockUser.mutate(body, {
        onSuccess(data) {
          resolve(data);
        },
      });
    });
  };

  const profileApprove = (id: string) => {
    return new Promise((resolve) => {
      mutationApproveProfile.mutate(id, {
        onSuccess(data) {
          resolve(data);
        },
      });
    });
  };

  const profileCorrect = (id: string, elements: ElementToCorrect[]) => {
    return new Promise((resolve) => {
      mutationCorrectProfile.mutate(
        { id, elements },
        {
          onSuccess(data) {
            resolve(data);
          },
        }
      );
    });
  };

  const patronUser = (email: string, value: boolean) => {
    return new Promise((resolve) => {
      mutationPatronUser.mutate(
        { [email]: value },
        {
          onSuccess(data) {
            resolve(data);
          },
        }
      );
    });
  };

  return {
    banUser,
    warnUser,
    trustUser,
    untrustUser,
    unblockUser,
    profileApprove,
    profileCorrect,
    patronUser,
  };
};
