import { IUser } from 'features/stories-feed/StoriesTypes';
import { useInfiniteQuery } from 'react-query';
import { axiosApiInstance as api, mapKeysToSnakeCase } from 'utils';

export interface userPagination {
  username: string;
  language?: string;
  page: number;
  perPage: number;
}

export type usersSearchResponse = {
  current_page: number;
  per_page: number;
  users: IUser[];
};

const cacheTime = 1000 * 60 * 3;

export const getUsers = async (page: number, pagination: userPagination): Promise<usersSearchResponse> => {
  pagination = { ...pagination, page };
  const { data } = await api.get('/users/search', { params: mapKeysToSnakeCase(pagination) });
  return data;
};

export const useUsersSearchQuery = (pagination: userPagination) => {
  return useInfiniteQuery(['getStories', pagination], ({ pageParam: page = 1 }) => getUsers(page, pagination), {
    cacheTime: cacheTime,
    getNextPageParam(lastPage, allPages) {
      return lastPage.users.length > 0 ? lastPage.current_page + 1 : undefined;
    },
    enabled: false,
  });
};
