import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Theme, Typography } from '@mui/material';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

type CarMenuProps = {
  onDelete: () => void;
  onTransfer: () => void;
  onShare: () => void;
};

const tKey = getTKey('car_menu');

const styles = ({ spacing }: Theme) => ({
  menuPaper: {
    backgroundColor: 'transparent',
  },
  menu: {
    '& .MuiMenu-list': {
      backgroundColor: 'rgba(42, 42, 42)',
      borderRadius: 4,
      minWidth: 168,
      boxShadow: '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)',
    },
    '& .MuiMenuItem-root': {
      padding: spacing(1, 2, 1),
      backgroundColor: 'rgba(42, 42, 42)',
    },
  },
});

export const CarMenuButton: ComponentType<CarMenuProps> = ({ onDelete, onTransfer, onShare }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handleClose();
    onDelete();
  };
  return (
    <>
      <IconButton aria-label='more' aria-controls='long-menu' aria-haspopup='true' onClick={handleClick} size='large'>
        <MoreVert />
      </IconButton>

      <Menu
        elevation={10}
        // getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
        classes={{ paper: classes.menuPaper }}
      >
        <MenuItem onClick={onShare}>
          <Typography variant='body2'>{t(tKey('share_link'))}</Typography>
        </MenuItem>
        <MenuItem onClick={onTransfer}>
          <Typography variant='body2'>{t(tKey('transfer'))}</Typography>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <Typography variant='body2'>{t(tKey('delete'))}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
