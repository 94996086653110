import { Clear, Search } from '@mui/icons-material';
import { FormControl, IconButton, InputBaseComponentProps, TextField, TextFieldProps, Theme } from '@mui/material';
import clsx from 'clsx';
import { ChangeEvent, ComponentType, useEffect } from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { useClasses } from 'utils/hooks/useClasses';

interface TextInputProps {
  name: string;
  label: string;
  textarea?: boolean;
  textFieldProps?: TextFieldProps;
  inputProps?: InputBaseComponentProps;
  setHeight?: any;
  onSearch: (value: string) => void;
  onReset: () => void;
}

const styles = ({ palette }: Theme) => ({
  root: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '& label.Mui-focused': {
      color: '#bb86fc',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#bb86fc',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.87)',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#bb86fc',
      },
    },
  },
  setHeight: {
    '&.MuiFormControl-root': {
      height: '85%',
    },
    '& .MuiInputBase-root': {
      padding: '10.5px 14px',
      height: '100%',
    },
  },
  searchIconButton: {
    color: palette.text.hint,
    position: 'relative',
    right: 18,
    '&.Mui-disabled': {
      color: palette.text.hint,
    },
  },
  searchField: {
    width: '100%',
    background: '#202020',
    borderRadius: 4,
    margin: 0,
    border: '1px solid rgba(255, 255, 255, 0.08)',
    '& fieldset': {
      border: 0,
    },
    display: 'flex',
    flexDirection: 'row',
  },
});

export const SearchInputControlled: ComponentType<TextInputProps> = ({
  name,
  label,
  textarea = false,
  textFieldProps,
  inputProps,
  setHeight,
  onSearch,
  onReset,
}) => {
  const { control, setValue } = useFormContext();
  const { errors } = useFormState();
  const hasError = Boolean(errors[name]);

  const classes = useClasses(styles);

  useEffect(() => {
    setValue(name, textFieldProps?.value);
  }, [textFieldProps?.value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl variant='outlined' className={classes.searchField}>
          <TextField
            spellCheck={false}
            variant='outlined'
            {...field}
            {...textFieldProps}
            inputProps={{ ...inputProps }}
            {...(textarea && { multiline: true })}
            {...(textarea && { rows: 4 })}
            fullWidth
            size='small'
            placeholder={label}
            error={hasError}
            value={field.value || ''}
            classes={{
              root: clsx(classes.root, setHeight && classes.setHeight),
            }}
            autoComplete='one-time-code'
            onChangeCapture={(event: ChangeEvent<HTMLInputElement>) => {
              if (event.target.value === '') {
                onReset();
              }
            }}
          />
          {field.value && (
            <IconButton className={classes.searchIconButton} onClick={onReset} aria-label='clear'>
              <Clear />
            </IconButton>
          )}
          <IconButton
            className={classes.searchIconButton}
            aria-label='search'
            edge='end'
            type='submit'
            onClick={() => onSearch(field.value)}
            disabled={!field.value}
          >
            <Search />
          </IconButton>
        </FormControl>
      )}
    />
  );
};
