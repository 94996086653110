import { PushNotifications } from '@capacitor/push-notifications';
import { ComponentType, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const FirebaseNotificationsComponent: ComponentType = () => {
  const navigate = useNavigate();

  useEffect(() => {
    PushNotifications.removeAllListeners();
    PushNotifications.addListener('pushNotificationReceived', (notification) => {
      const data = notification?.data;
      if (data.click_action === 'OPEN_ACTIVITY' && data.page) {
        navigate(data.page);
      } else {
        navigate('/alerts');
      }
    });
    PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      const data = notification?.notification?.data;
      if (
        data.click_action === 'OPEN_ACTIVITY' &&
        data.page &&
        (data.type === 'weekly_story' || data.type === 'reminder' || data.type === 'system_story')
      ) {
        navigate(data.page);
      } else if (data.click_action === 'OPEN_DYNAMIC_LINK' && data.type === 'new_version') {
        window.open(data.link);
      } else {
        navigate('/alerts');
      }
    });
    return () => {
      PushNotifications.removeAllListeners();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
