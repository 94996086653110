import { useQuery, UseQueryResult } from 'react-query';
import { getDataWithExpiration, saveDataWithExpiration } from 'store/expiredStore';

const getUserCountry = async (): Promise<any> => {
  const country = getDataWithExpiration('driverbook_country_se');
  if (country) {
    return new Promise((resolve) => {
      resolve(country);
    });
  } else {
    return new Promise(async (resolve, reject) => {
      await fetch('https://ipapi.co/json')
        .then((res) => res.json())
        .then((response) => {
          const countryResp = response?.country_name?.toLowerCase() ?? '';
          saveDataWithExpiration('driverbook_country_se', countryResp, 1440);
          resolve(countryResp);
        })
        .catch((er) => {
          reject(er);
        });
    });
  }
};

export const useUserCountry = (): UseQueryResult<any> => {
  return useQuery(['userCountry'], () => getUserCountry());
};
