import { useMutation } from 'react-query';

import { queryClient } from 'components';
import { axiosApiInstance } from 'utils';

interface RecordGroupPayload {
  id: string;
  identifier: string;
  title: string;
}

const updateGroupTitle = async ({ id, identifier, title }: RecordGroupPayload): Promise<any> => {
  const { data } = await axiosApiInstance.put(`/records/group`, { id, identifier, title });

  return data;
};

export const useRecordGroupMutation = () => {
  return useMutation(updateGroupTitle, {
    onSuccess: () => {
      queryClient.invalidateQueries('groupData');
    },
  });
};
