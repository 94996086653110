import io, { Socket } from 'socket.io-client';

const URL = process.env.REACT_APP_PUBLIC_WS_URL_STORY || '';

let socketStory: undefined | Socket;

if (URL) {
  socketStory = io(URL, { transports: ['websocket'], reconnection: true, secure: true });
}

export { socketStory };
