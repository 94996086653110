import { getRemindersCarPayload } from './reminderPayloads';
import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

export const readAllReminders = async ({ carId }: getRemindersCarPayload) => {
  const { data } = await api.post(`cars/${carId}/reminders/read`);
  return data;
};

export const useReadAllRemindersMutation = () => {
  return useMutation(readAllReminders);
};
