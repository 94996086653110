import { Theme, Typography } from '@mui/material';
import { InnerMobileHeader, LeanLayout, Wrapper } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  email: { color: palette.additionalPrimary[200] },
  container: {
    backgroundColor: 'rgba(42, 42, 42)',
    padding: spacing(3),
    borderRadius: 10,
  },
  listNone: {
    listStyle: 'none',
  },
});

const tKey = getTKey('terms_of_use');
export const TermsOfUse = () => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  return (
    <LeanLayout>
      <InnerMobileHeader title=' ' showBack />
      <Wrapper>
        <div className={classes.container}>
          <Typography variant='h5'>{t(tKey('heading'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('heading_description_1'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('heading_description_2'))}</Typography>
          <br />
          <Typography variant='h5'>{t(tKey('usage_eligibility'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('usage_eligibility_1'))}</Typography>
          <br />
          <ul>
            <li>
              <Typography variant='body1'>{t(tKey('usage_eligibility_2'))}</Typography>
            </li>
            <li>
              <Typography variant='body1'>{t(tKey('usage_eligibility_3'))}</Typography>
            </li>
            <li>
              <Typography variant='body1'>{t(tKey('usage_eligibility_4'))}</Typography>
            </li>
          </ul>
          <br />
          <Typography variant='h5'>{t(tKey('privacy'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('privacy_1'))}</Typography>
          <br />
          <Typography variant='h5'>{t(tKey('content'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('content_1'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('content_2'))}</Typography>
          <br />
          <Typography variant='h5'>{t(tKey('rights'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('rights_1'))}</Typography>
          <br />
          <ul className={classes.listNone}>
            <li>
              <Typography variant='body1'>1. {t(tKey('rights_2'))}</Typography>
            </li>
            <li>
              <Typography variant='body1'>2. {t(tKey('rights_3'))}</Typography>
            </li>
            <li>
              <Typography variant='body1'>3. {t(tKey('rights_4'))}</Typography>
            </li>
            <li>
              <Typography variant='body1'>4. {t(tKey('rights_5'))}</Typography>
            </li>
          </ul>
          <br />
          <Typography variant='h5'>{t(tKey('using'))}</Typography>
          <br />
          <Typography variant='body1'>
            {t(tKey('using_1'))}{' '}
            <Link to='privacy-policy' className={classes.email}>
              {t(tKey('using_privacy_policy'))}
            </Link>{' '}
            {t(tKey('using_2'))}
          </Typography>
          <br />
          <Typography variant='body1'>{t(tKey('rights_3'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('rights_4'))}</Typography>
          <br />
          <Typography variant='h6'>{t(tKey('account_for_access'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('account_for_access_1'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('account_for_access_2'))}</Typography>
          <br />
          <Typography variant='h6'>{t(tKey('permission'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('permission_1'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('permission_2'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('permission_3'))}</Typography>
          <br />
          <Typography variant='h6'>{t(tKey('ending'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('ending_1'))}</Typography>
          <br />
          <Typography variant='h5'>{t(tKey('disclaimers'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('disclaimers_1'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('disclaimers_2'))}</Typography>
          <br />
          <ul>
            <li>
              <Typography variant='body1'>{t(tKey('disclaimers_3'))}</Typography>
            </li>
            <li>
              <Typography variant='body1'>{t(tKey('disclaimers_4'))}</Typography>
            </li>
            <li>
              <Typography variant='body1'>{t(tKey('disclaimers_5'))}</Typography>
            </li>
            <li>
              <Typography variant='body1'>{t(tKey('disclaimers_6'))}</Typography>
            </li>
          </ul>
          <br />
          <Typography variant='body1'>{t(tKey('disclaimers_7'))}</Typography>
          <br />
          <Typography variant='h5'>{t(tKey('limitation'))}</Typography>
          <br />
          <Typography variant='body1'>{t(tKey('limitation_1'))}</Typography>
          <br />
          <Typography variant='h5'>{t(tKey('general'))}</Typography>
          <br />
          <Typography variant='body1'>
            {t(tKey('general_1'))}{' '}
            <Link to='terms-of-use' target='_blank' className={classes.email}>
              {t(tKey('terms_of_use'))}
            </Link>
            {t(tKey('general_2'))}
          </Typography>
          <br />
          <Typography variant='body1'>{t(tKey('general_3'))}</Typography>
          <br />
          <Typography variant='body1'>
            {t(tKey('general_4'))}{' '}
            <a className={classes.email} href='mailto:support@driverbook.io'>
              support@driverbook.io
            </a>
          </Typography>
          <br />
          <Typography variant='body1'>{t(tKey('general_5'))}</Typography>
        </div>
      </Wrapper>
    </LeanLayout>
  );
};
