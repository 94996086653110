import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('save_story');

const styles = ({ spacing }: Theme) => ({
  paper: {
    maxWidth: 360,
    backgroundColor: '#333',
  },
  description: {
    color: 'rgba(255, 255, 255, 0.87)',
  },
  actions: {
    '& button': {
      letterSpacing: 1.25,
    },
  },
  loading: {
    color: 'white',
    marginLeft: spacing(0.5),
  },
});

interface SaveStoryProps {
  onLeave: () => void;
  onClose: () => void;
}

export const SaveStoryDialog: ComponentType<SaveStoryProps> = ({ onLeave, onClose }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();

  return (
    <Dialog open={true} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>{t(tKey('title'))}</DialogTitle>
      <DialogContent>
        <DialogContentText classes={{ root: classes.description }}>{t(tKey('desc'))}</DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button onClick={() => onLeave()} color='primary'>
          {t(tKey('action_leave'))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
