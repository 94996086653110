import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
} from '@mui/material';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { BanType, IUser } from '../AdminDashboardTypes';
import { Close } from '@mui/icons-material';

const tKey = getTKey('admin_dialog_user_unblock');

const styles = ({ palette, spacing }: Theme) => ({
  titleWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  description: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
  actions: {
    '& button': {
      letterSpacing: 1.25,
    },
  },
  field: {
    margin: spacing(2.75, 0),
  },
  helperText: {
    padding: spacing(0, 2.5),
    color: palette.text.hint,
    fontSize: 12,
  },
  closeButton: {
    color: palette.text.hint,
  },
});

interface UserUnblockDialogProps {
  user: IUser | null;
  open: boolean;
  onClose: () => void;
  onConfirm: (from: string) => void;
}

const fromOptions: BanType[] = ['story', 'comment', 'profile', 'car'];

export const UserUnblockDialog: ComponentType<UserUnblockDialogProps> = ({ user, open, onClose, onConfirm }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();

  const [from, setFrom] = useState<string>('');

  const handleChangeFrom = (event: SelectChangeEvent) => {
    setFrom(event.target.value as string);
  };

  const checkForDisabled = (type: BanType) => {
    if (type === 'story' && user?.is_story_banned) {
      return false;
    }
    if (type === 'comment' && user?.is_comment_banned) {
      return false;
    }
    if (type === 'profile' && user?.is_profile_banned) {
      return false;
    }
    if (type === 'car' && user?.is_car_banned) {
      return false;
    }
    return true;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='sm'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title' className={classes.titleWrap}>
        {t(tKey('title'))}
        <IconButton onClick={onClose}>
          <Close className={classes.closeButton} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>{t(tKey('description'))}</div>
        <FormControl fullWidth className={classes.field}>
          <InputLabel id='ban-from-select-label'>{t(tKey('from_label'))}</InputLabel>
          <Select
            labelId='ban-from-select-label'
            id='ban-from-select'
            value={from}
            label={t(tKey('from_label'))}
            onChange={handleChangeFrom}
          >
            {fromOptions.map((fromOption) => {
              return (
                <MenuItem key={fromOption} disabled={checkForDisabled(fromOption)} value={fromOption}>
                  {t('admin_dialog_user_unblock_' + fromOption)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button onClick={onClose} color='inherit'>
          {t('__common__cancel')}
        </Button>
        <Button onClick={() => onConfirm(from)} color='secondary' disabled={!from}>
          {t('__common__confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
