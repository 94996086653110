import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

export const approveUser = async (id: string): Promise<any> => {
  try {
    const { data } = await api.post(`/engineroom/users/${id}/approve`);
    return data;
  } catch (err) {
    return { error: true };
  }
};

export const useApproveUserMutation = () => {
  return useMutation(approveUser);
};
