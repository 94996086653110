import { PushNotifications } from '@capacitor/push-notifications';
import store from 'store2';
import { axiosApiInstance as api } from 'utils';
export const firebaseNotificationsRegister = () => {
  PushNotifications.addListener('registration', (token) => {
    api.post('/users/notifications/push', { registration_id: token.value });
    store('driverbook_fcm', token.value);
  });

  PushNotifications.requestPermissions().then((result) => {
    if (result.receive === 'granted') {
      PushNotifications.register();
    }
  });
  // Listen for registration error event
  PushNotifications.addListener('registrationError', (error) => {
    console.error('Push registration error: ' + JSON.stringify(error));
  });
};

export const firebaseNotificationsUnregister = () => {
  api.delete('/users/notifications/push', { data: { registration_id: store.get('driverbook_fcm') } });
  store.remove('driverbook_fcm');
};
