import { Theme, Typography } from '@mui/material';
import { ComponentType } from 'react';

import { useClasses } from 'utils/hooks/useClasses';

interface DashboardTileProps {
  heading: string;
  count: string | number;
}

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: spacing(2.5, 3),
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: spacing(0.5),
    border: '1px solid rgba(255, 255, 255, 0.08)',

    [breakpoints.up('md')]: {
      height: '100%',
      minHeight: 185,
    },
  },
});

export const DashboardTile: ComponentType<DashboardTileProps> = ({ heading, count }) => {
  const classes = useClasses(styles);

  return (
    <div className={classes.root}>
      <Typography variant='body2'>{heading}</Typography>
      <Typography variant='h4'>{count}</Typography>
    </div>
  );
};
