import { ArrowBack } from '@mui/icons-material';
import { IconButton, Theme } from '@mui/material';
import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useClasses } from 'utils/hooks/useClasses';
const styles = ({ breakpoints, spacing, palette }: Theme) => ({
  root: {
    height: 56,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: spacing(0.75),
    [breakpoints.down('sm')]: {
      padding: spacing(0),
    },
  },
  back: {
    color: palette.text.hint,
  },
  errorMessage: {
    padding: spacing(2),
    textAlign: 'center',
  },
});
export const NotFoundStory: ComponentType<{ error: any }> = (error) => {
  const classes = useClasses(styles);
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {}, []);
  return (
    <div>
      <div className={classes.topBar}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack className={classes.back} />
        </IconButton>
      </div>
      <div className={classes.errorMessage}>{t('story_error_not_found')}</div>
    </div>
  );
};
