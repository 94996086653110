import { ComponentType, useContext } from 'react';
import { Box, Theme } from '@mui/material';
import { useClasses } from 'utils/hooks/useClasses';
import animationData from './messageLoader.json';
import Lottie from 'lottie-react';
import { markHighlightText } from 'utils';
import { SearchHighlightContext } from '../SearchHighlightContext';
import MarkdownIt from 'markdown-it';
import { SvgIconComponent } from '@mui/icons-material';

const md = MarkdownIt();

const styles = ({ spacing, breakpoints, palette }: Theme) => ({
  root: {
    width: 'fit-content',
    background: '#2E2E2E',
    borderRadius: spacing(1, 0.25, 1, 1),
    padding: spacing(1, 1.5),
    margin: spacing(2, 2, 2, 'auto'),
    maxWidth: '70%',
  },
  loader: {
    width: 45,
    height: 25,
    margin: 'auto 0',
  },
  message: {
    '& p': {
      margin: 0,
    },
    '& ol, & ul': {
      paddingLeft: spacing(2.5),
    },
    '& img': {
      maxWidth: 250,
    },
  },
});

interface assistantUserMessageProps {
  message: string;
  isLoading?: boolean;
  index: number;
  Icon?: SvgIconComponent | React.FunctionComponent;
  colorIcon?: string;
}

export const AssistantUserMessage: ComponentType<assistantUserMessageProps> = ({
  message,
  isLoading,
  index,
  Icon,
  colorIcon,
}) => {
  const classes = useClasses(styles);
  const context = useContext(SearchHighlightContext);
  const renderedMessage = context.query
    ? markHighlightText(md.render(message), context.query, context.current === index)
    : md.render(message);
  return (
    <Box className={classes.root}>
      <Box display='flex' flexDirection='row'>
        <Box sx={{ minWidth: Icon ? 32 : 0 }}>{Icon && colorIcon && <Icon style={{ color: colorIcon }} />}</Box>
        <Box>
          <div className={classes.message} dangerouslySetInnerHTML={{ __html: renderedMessage }}></div>
        </Box>
      </Box>
      {isLoading && <Lottie animationData={animationData} className={classes.loader} />}
    </Box>
  );
};
