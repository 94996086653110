import { useQuery } from 'react-query';

import { axiosApiInstance as api } from 'utils';

export const getAllRecords = async () => {
  const { data } = await api.get('/users/has-records');
  return data.has_records;
}

export const useRecordsQuery = () => {
  return useQuery('allRecords', () => getAllRecords());
};
