import { ArrowBack, Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { Form, FormCheckbox, SelectBox, TextInputControlled } from 'components';
import { TFunction } from 'i18next';
import { ComponentType, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';
import { getTKey, yupStringValidator } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import * as yup from 'yup';
const tKey = getTKey('report_story_dialog');

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      background: '#202020',
      [breakpoints.up('sm')]: {
        background: '#2a2a2a',
        width: 390,
        height: 512,
      },
    },
  },
  title: {
    textTransform: 'capitalize',
  },
  backBtn: {
    color: palette.text.hint,
    width: 44,
    height: 44,
    [breakpoints.down('sm')]: {
      paddingRight: spacing(3),
    },
  },
  mobileTitleBlock: {
    width: 'unset',
  },
  content: {
    paddingTop: spacing(2),
  },
});

interface ReportStoryDialogType {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
}

const validationSchema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    subject: yupStringValidator(t).required(t('__validation__required')),
    details: yupStringValidator(t).notRequired(),
    block_user: yup.boolean(),
  });

export const ReportDialog: ComponentType<ReportStoryDialogType> = ({ open, onClose, onSubmit }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const title = t(tKey('title'));

  const ref = useRef(null);

  const reasonOptions: Array<{ value: string; label: string }> = [
    { value: 'spam', label: tKey('spam') },
    { value: 'terrorism', label: tKey('terrorism') },
    { value: 'hate_speech', label: tKey('hate_speech') },
    { value: 'sexual_content', label: tKey('sexual_content') },
    { value: 'false_information', label: tKey('false_information') },
    { value: 'something_else', label: tKey('something_else') },
  ];

  return (
    <Dialog open={open} fullScreen={fullScreen} className={classes.dialog} onClose={onClose}>
      <Form
        schema={validationSchema(t)}
        defaultValues={{ subject: 'spam', details: '', block_user: false }}
        onSubmit={onSubmit}
      >
        <DialogTitle>
          {fullScreen ? (
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Grid item container direction='row' alignItems='center' className={classes.mobileTitleBlock}>
                <IconButton onClick={onClose} className={classes.backBtn}>
                  <ArrowBack />
                </IconButton>
                <div className={classes.title}>{title}</div>
              </Grid>
              <Grid item>
                <Button type='submit'>{t('__common__send')}</Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container justifyContent='space-between' alignItems='center' marginTop={4}>
              <div className={classes.title}>{title}</div>
              <IconButton onClick={onClose} className={classes.backBtn}>
                <Close />
              </IconButton>
            </Grid>
          )}
        </DialogTitle>
        <DialogContent ref={ref}>
          <div className={classes.content}>
            <SelectBox
              name='subject'
              options={reasonOptions}
              label={t(tKey('subject_label'))}
              optionsWithTranslations
            />
            <TextInputControlled
              inputProps={{ maxLength: 300 }}
              name='details'
              label={t(tKey('details_label'))}
              textarea
            />
            <FormCheckbox name='block_user' label={t(tKey('block_user'))} />
          </div>
        </DialogContent>
        {!fullScreen && (
          <DialogActions>
            <Button onClick={onClose}>{t('__common__cancel')}</Button>
            <Button type='submit'>{t('__common__send')}</Button>
          </DialogActions>
        )}
      </Form>
    </Dialog>
  );
};
