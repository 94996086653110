import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider, Theme } from '@mui/material/styles';
import { ComponentType, Suspense, useEffect } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation, useNavigate } from 'react-router-dom';

import store from 'store2';

import i18n from './i18n';
import ProfileProvider from './store/profile/store';
import { theme } from './theme';

import '@mui/styles';
import {
  // CountryRoute,
  PrivateRoute,
  ReactQueryClientProvider,
  ScrollToTop,
  Sockets,
  StyledToast,
} from 'components';
import { AdminRoute } from 'components/AdminRoute/AdminRoute';
import { AdminLoginPage } from 'features/account/AdminLoginPage';
import { UnconfirmedEmailPage } from 'features/account/UnconfirmedEmailPage';
import { AdminCommentsPage } from 'features/admin-panel/AdminCommentsPage';
import { AdminLogout } from 'features/admin-panel/AdminLogout';
import { AdminReviewPage } from 'features/admin-panel/AdminReviewPage';
import { AdminStoriesPage } from 'features/admin-panel/AdminStoriesPage';
import { AdminUsersPage } from 'features/admin-panel/AdminUsersPage';
import { AdminNewVersionPage } from 'features/admin-panel/components/AdminNewVersionPage';
import { AlertsPage } from 'features/alerts/AlertsPage';
// import { GasCalculator } from 'features/calculator';
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { SocketsStory } from 'components/Sockets/SocketsStory';
import { SocialUsernamePage } from 'features/account/SocialUsernamePage';
import { ShareCarPage } from 'features/cars/ShareCarPage';
import { TransferCarPage } from 'features/cars/TransferCarPage';
import { PublicDashboardDetailsPage } from 'features/dashboard/PublicDashboardDetailsPage';
import { PublicDashboardPage } from 'features/dashboard/PublicDashboardPage';
import { CookiePolicy } from 'features/help/cookie-policy/CookiePolicy';
import { PrivacyPolicy } from 'features/help/privacy-policy/PrivacyPolicy';
import { TermsOfUse } from 'features/help/terms-of-use/TermsOfUse';
import { PublicRecordErrorPage } from 'features/records/PublicRecordErrorPage';
import { PublicRecordsPage } from 'features/records/PublicRecordsPage';
import { Documents } from 'features/settings/Tabs/Documents';
import { LogoutTab } from 'features/settings/Tabs/LogoutTab';
import { StoriesPage } from 'features/stories-feed/StoriesPage';
import { StoryPage } from 'features/stories-feed/StoryPage';
import { CreateStoryPage } from 'features/stories/CreateStoryPage';
import { EditStoryPage } from 'features/stories/EditStoryPage';
import { StoryPublishProvider } from 'features/stories/hooks/StoryPublishContext';
import { PublicUserPage } from 'features/users/PublicUserPage';
import { useUserIsBannedCreationQuery } from 'services/stories/useUserIsBannedQuery';
import { isLoggedIn } from 'utils';
// import { firebaseNotificationsListener } from 'utils/firebaseNotifications';
import MetaTags from 'components/MetaTags/MetaTegs';
import { AssistantPage } from 'features/assistant/AssistantPage';
import { InvoiceToRecordPage } from 'features/records/InvoinceToRecordPage';
import { SiriVoiceToRecordPage } from 'features/records/SiriVoiceToRecordPage';
import { VoiceToRecordPage } from 'features/records/VoiceToRecordPage';
import { ReminderCreatePage } from 'features/reminders/ReminderCreatePage';
import { RemindersDashboardPage } from 'features/reminders/RemindersDashboardPage';
import { RemindersRootPage } from 'features/reminders/RemindersRootPage';
import { VoiceToReminderPage } from 'features/reminders/VoiceToReminderPage';
import { useTranslation } from 'react-i18next';
import { FirebaseNotificationsComponent } from 'utils/firebaseNotificationsComp';
import { usePwa } from 'utils/pwa';
import { ConfirmEmailPage } from './features/account/ConfirmEmailPage';
import { ForgotPasswordPage } from './features/account/ForgotPasswordPage';
import { LoginPage } from './features/account/LoginPage';
import { RegistrationPage } from './features/account/RegistrationPage';
import { RegistrationSuccessPage } from './features/account/RegistrationSuccessPage';
import { ResetPasswordPage } from './features/account/ResetPasswordPage';
import { AssistedRecordsPage } from './features/assisted-records/AssistedRecordsPage';
import { AssistedRecordsStatusPage } from './features/assisted-records/AssistedRecordsStatusPage';
import { CreateAssistedRecordPage } from './features/assisted-records/CreateAssistedRecordPage';
import { EditAssistedRecordPage } from './features/assisted-records/EditAssistedRecordPage';
import { CarsPage } from './features/cars/CarsPage';
import { CreateCarPage } from './features/cars/CreateCarPage';
import { EditCarPage } from './features/cars/EditCarPage';
import { DashboardDetailsPage } from './features/dashboard/DashboardDetailsPage';
import { DashboardPage } from './features/dashboard/DashboardPage';
import { CreateRecordPage } from './features/records/CreateRecordPage';
import { EditRecordPage } from './features/records/EditRecordPage';
import { GenerateAssistedRecordPage } from './features/records/GenerateAssistedRecordPage';
import { RecordsDashboardPage } from './features/records/RecordsDashboardPage';
import { RecordsRootPage } from './features/records/RecordsRootPage';
import { SettingsPage } from './features/settings/SettingsPage';
import { ProfileTab } from 'features/settings/Tabs/ProfileTab';
import { DateAndMetricTab } from 'features/settings/Tabs/DateAndMetricTab';
import { SettingsRootPage } from 'features/settings/SettingsRootPage';
import { ProfileSettings } from 'features/settings/Tabs/ProfileSettings';
import { SettingsTab } from 'features/settings/Tabs/SettingsTab';
import { SigninTab } from 'features/settings/Tabs/SigninTab';
import { LanguageTab } from 'features/settings/Tabs/LanguageTab';
import { FeedbackTab } from 'features/settings/Tabs/FeedbackTab';
import { PasswordTab } from 'features/settings/Tabs/PasswordTab';
import { CurrencyTab } from 'features/settings/Tabs/CurrencyTab';
import { NotificationsTab } from 'features/settings/Tabs/NotificationsTab';
import { BlockedUsersTab } from 'features/settings/Tabs/BlockedUsersTab';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
  interface TypeText {
    hint: string;
  }
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

interface StoryCreateRouteProps {
  component: ComponentType;
}

export const StoryCreateRoute: React.FC<StoryCreateRouteProps> = ({ component: Component }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuth = isLoggedIn();
  const { data: isBanned, isFetched: isFetchedStoryBanned } = useUserIsBannedCreationQuery();

  useEffect(() => {
    if (!isAuth) {
      navigate('/login', { state: { from: location } });
      return;
    }

    if (isFetchedStoryBanned && isBanned?.is_story_banned) {
      navigate('/stories/recent');
      return;
    }
  }, [isAuth, isBanned, isFetchedStoryBanned, navigate, location]);

  return <CreateStoryPage />;
};

const storedLanguage = store('driverbook_language');
if (storedLanguage) i18n.changeLanguage(storedLanguage as string);

export const App: ComponentType = () => {
  const isPwa = usePwa();
  const { t } = useTranslation();

  useEffect(() => {
    if (isPwa) {
      //@ts-ignore
      gtag?.('event', 'using_pwa', { method: 'using_pwa' });
    } else {
      //@ts-ignore
      gtag?.('event', 'using_browser', { method: 'using_browser' });
    }
  }, [isPwa]);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      CapacitorApp.addListener('backButton', ({ canGoBack }) => {
        if (!canGoBack) {
          CapacitorApp.exitApp();
        } else {
          window.history.back();
        }
      });
      // firebaseNotificationsListener();
    }
    return () => {
      if (Capacitor.isNativePlatform()) {
        CapacitorApp.removeAllListeners();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <StyledToast />
            <ReactQueryClientProvider>
              <ProfileProvider>
                <StoryPublishProvider>
                  <Router>
                    {Capacitor.isNativePlatform() && <FirebaseNotificationsComponent />}
                    <Sockets />
                    <SocketsStory />
                    <ScrollToTop />

                    <MetaTags title={t('app_title')} description={t('app_description')} />
                    <Routes>
                      <Route element={<PrivateRoute />}>
                        <Route path='/assistant' element={<AssistantPage />} />
                        <Route path='/assistant' element={<AssistantPage />} />
                        <Route path='/cars' element={<CarsPage />} />
                        <Route path='/cars/:carId/edit' element={<EditCarPage />} />
                        <Route path='/siri-voice/:carId' element={<SiriVoiceToRecordPage />} />

                        <Route path='/cars/new' element={<CreateCarPage />} />
                        <Route path='/cars/:carId/transfer' element={<TransferCarPage />} />
                        <Route path='/cars/:carId/share' element={<ShareCarPage />} />
                        <Route path='/records/:carId/edit/:recordId' element={<EditRecordPage />} />
                        <Route path='/records/:carId/generate' element={<GenerateAssistedRecordPage />} />
                        <Route path='/records/:carId/new' element={<CreateRecordPage />} />
                        <Route path='/records/:carId/:recordId/copy' element={<CreateRecordPage />} />
                        <Route path='/records/:carId/invoice' element={<InvoiceToRecordPage />} />
                      </Route>
                      <Route path='/records/:carId/voice' element={<VoiceToRecordPage />} />
                      <Route path='/records/:carId' element={<RecordsDashboardPage />} />
                      <Route path='/records' element={<RecordsRootPage />} />
                      <Route path='/dashboard' element={<DashboardPage />} />
                      <Route path='/dashboard/:carId' element={<DashboardDetailsPage />} />
                      <Route path='/settings/logout' element={<LogoutTab />} />
                      <Route path='/documents' element={<Documents />} />
                      <Route element={<PrivateRoute />}>
                        <Route path='/settings' element={<SettingsPage />}>
                          <Route index element={<SettingsRootPage />} />
                          <Route path='profile' element={<ProfileTab />} />
                          <Route path='setProfile' element={<ProfileSettings />} />
                          <Route path='general' element={<SettingsTab />} />
                          <Route path='language' element={<LanguageTab />} />
                          <Route path='feedback' element={<FeedbackTab />} />
                          <Route path='logout' element={<LogoutTab />} />
                          <Route path='password' element={<PasswordTab />} />
                          <Route path='sign_in' element={<SigninTab />} />
                          <Route path='date_metric' element={<DateAndMetricTab />} />
                          <Route path='currency' element={<CurrencyTab />} />
                          <Route path='notifications' element={<NotificationsTab />} />
                          <Route path='blocked_users' element={<BlockedUsersTab />} />
                        </Route>
                      </Route>
                      <Route path='/stories/new' element={<StoryCreateRoute component={CreateStoryPage} />} />
                      <Route path='/stories/edit/:carId/:storyId' element={<EditStoryPage />} />
                      {/* <CountryRoute path='/calculator' component={GasCalculator} />  */}
                      <Route path='/confirm-your-email' element={<UnconfirmedEmailPage />} />
                      <Route path='/alerts' element={<AlertsPage />} />
                      <Route path='/user/:userId' element={<PublicUserPage />} />
                      <Route path='/assisted/error' element={<AssistedRecordsStatusPage type='error' />} />
                      <Route path='/assisted/success' element={() => <AssistedRecordsStatusPage type='success' />} />
                      <Route path='/assisted/:identifier/:carId/edit/:recordId' element={<EditAssistedRecordPage />} />
                      <Route path='/assisted/:identifier/:carId/new' element={<CreateAssistedRecordPage />} />
                      <Route path='/assisted/:identifier' element={<AssistedRecordsPage />} />
                      <Route path='/public/error' element={<PublicRecordErrorPage />} />
                      <Route path='/book/dashboard/details/:identifier' element={<PublicDashboardDetailsPage />} />
                      <Route path='/book/dashboard/:identifier' element={<PublicDashboardPage />} />
                      <Route path='/book/:identifier' element={<PublicRecordsPage />} />
                      <Route path='/login' element={<LoginPage />} />
                      <Route path='/complete-signup' element={<SocialUsernamePage />} />
                      <Route path='/signup' element={<RegistrationPage />} />
                      <Route path='/signup-success' element={<RegistrationSuccessPage />} />
                      <Route path='/confirm-email/:token' element={<ConfirmEmailPage />} />
                      <Route path='/forgot-password' element={<ForgotPasswordPage />} />
                      <Route path='/reset/:token' element={<ResetPasswordPage />} />
                      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                      <Route path='/cookie-policy' element={<CookiePolicy />} />
                      <Route path='/terms-of-use' element={<TermsOfUse />} />
                      <Route path='/stories/:carId/:storyId' element={<StoryPage />} />
                      <Route path='/stories/follow' element={<StoriesPage />} />
                      <Route path='/stories/news' element={<StoriesPage />} />
                      <Route path='/stories/:period' element={<StoriesPage />} />
                      <Route path='/stories' element={<StoriesPage />} />
                      <Route path='/reminders' element={<RemindersRootPage />} />
                      <Route path='/reminders/:carId' element={<RemindersDashboardPage />} />
                      <Route path='/reminders/:carId/:reminderId/details' element={<RemindersDashboardPage />} />
                      <Route path='/reminders/:carId/new' element={<ReminderCreatePage />} />
                      <Route path='/reminders/:carId/voice' element={<VoiceToReminderPage />} />
                      <Route path='/reminders/:carId/edit/:reminderId' element={<ReminderCreatePage />} />
                      <Route path='/reminders/:carId/:reminderId/copy' element={<ReminderCreatePage />} />
                      <Route path='/secret/machineroom/login' element={<AdminLoginPage />} />
                      <Route element={<AdminRoute />}>
                        <Route path='/secret/machineroom/review' element={<AdminReviewPage />} />
                        <Route path='/secret/machineroom/logout' element={<AdminLogout />} />
                        <Route path='/secret/machineroom/review' element={<AdminReviewPage />} />
                        <Route path='/secret/machineroom/stories' element={<AdminStoriesPage />} />
                        <Route path='/secret/machineroom/comments' element={<AdminCommentsPage />} />
                        <Route path='/secret/machineroom/users' element={<AdminUsersPage />} />
                        <Route path='/secret/machineroom/new-version' element={<AdminNewVersionPage />} />
                        <Route path='/secret/machineroom' element={<AdminStoriesPage />} />
                      </Route>
                      <Route path='' element={<StoriesPage />} />
                      <Route path='*' element={<Navigate to='/' />} />
                    </Routes>
                  </Router>
                </StoryPublishProvider>
              </ProfileProvider>
            </ReactQueryClientProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </Suspense>
  );
};
