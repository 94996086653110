import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import { UpdateCurrencyPayload, useExchangeRateQuery } from 'services';
import { Currency } from 'types';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('currency_change_dialog');

const styles = () => ({
  paper: {
    maxWidth: 460,
    backgroundColor: '#333',
  },
  description: {
    color: 'rgba(255, 255, 255, 0.87)',
  },
  actions: {
    '& button': {
      letterSpacing: 1.25,
    },
  },
  loadingContainer: {
    minWidth: 412,
    minHeight: 60,
    textAlign: 'center',
  },
});

interface CurrencyChangeDialogProps {
  from: Currency;
  to: Currency;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: (data: UpdateCurrencyPayload) => void;
}

export const CurrencyChangeDialog: ComponentType<CurrencyChangeDialogProps> = ({
  from,
  to,
  onClose,
  onCancel,
  onConfirm,
}) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const { data, isFetching } = useExchangeRateQuery(from, to);

  return (
    <Dialog open={true} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogContent>
        {isFetching ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          <DialogContentText classes={{ root: classes.description }}>
            {t(tKey('description'))} 1 {from} = {data?.rate} {to}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button onClick={onCancel} color='primary'>
          {t(tKey('cancel'))}
        </Button>
        <Button onClick={() => onConfirm({ fromCurrency: from, toCurrency: to, rate: data?.rate })} color='primary'>
          {t(tKey('convert'))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
