import { Dispatch, SetStateAction, useState } from 'react';

type useRecordGroupTitleType = (defaultTitle: string) => {
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  handleTitleChange: (value: string) => void;
};

export const useRecordGroupTitle: useRecordGroupTitleType = (defaultTitle) => {
  const [title, setTitle] = useState<string>(defaultTitle ?? '');

  const handleTitleChange = (value: string): void => {
    if (value.length <= 50) {
      setTitle(value);
    }
  };

  return {
    title,
    setTitle,
    handleTitleChange,
  };
};
