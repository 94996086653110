import { Theme } from '@mui/material';
import clsx from 'clsx';

import { ComponentType, CSSProperties, DetailedHTMLProps, HTMLAttributes } from 'react';
import { useClasses } from 'utils/hooks/useClasses';
import { cssValue } from 'utils/unit-converter';

export type LengthType = number | string;

export interface LoaderSizeProps extends CommonProps {
  size?: LengthType;
}

interface CommonProps extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  color?: string;
  loading?: boolean;
  cssOverride?: CSSProperties;
  speedMultiplier?: number;
}

const createAnimation = (loaderName: string, frames: string, suffix: string): string => {
  const animationName = `react-spinners-${loaderName}-${suffix}`;

  if (typeof window === 'undefined' || !window.document) {
    return animationName;
  }

  const styleEl = document.createElement('style');
  document.head.appendChild(styleEl);
  const styleSheet = styleEl.sheet;

  const keyFrames = `
    @keyframes ${animationName} {
      ${frames}
    }
  `;

  if (styleSheet) {
    styleSheet.insertRule(keyFrames, 0);
  }

  return animationName;
};

const puff = [
  createAnimation('PuffLoader', '0% {transform: scale(0)} 100% {transform: scale(1.0)}', 'puff-1'),
  createAnimation('PuffLoader', '0% {opacity: 1} 100% {opacity: 0}', 'puff-2'),
];

type PuffLoaderProps = {
  loading?: boolean;
};
const styles = ({ palette, spacing }: Theme) => ({
  main: {
    position: 'absolute',
    height: cssValue(30),
    width: cssValue(30),
    border: `thick solid ${palette.additionalPrimary[100]}`,
    borderRadius: '50%',
    opacity: '1',
    top: '0',
    left: '0',
    animationFillMode: 'both',
    animation: `${puff[0]}, ${puff[1]}`,
    animationDuration: `${2 / 1}s`,
    animationIterationCount: 'infinite',
    animationTimingFunction: 'cubic-bezier(0.165, 0.84, 0.44, 1), cubic-bezier(0.3, 0.61, 0.355, 1)',
  },
  wrapper: {
    display: 'inherit',
    position: 'relative',
    width: cssValue(30),
    height: cssValue(30),
  },
  dot: {
    height: '10px',
    position: 'absolute',
    width: '10px',
    top: '33%',
    left: '33%',
    backgroundColor: '#B27BFC',
    borderRadius: 100,
  },
});

export const PuffLoader: ComponentType<PuffLoaderProps> = ({ loading = true }) => {
  const classes = useClasses(styles);

  if (!loading) {
    return null;
  }

  return (
    <span className={classes.wrapper}>
      <span className={clsx(classes.main)} />
      <span className={clsx(classes.main)} />
      <div className={clsx(classes.dot)}></div>
    </span>
  );
};
