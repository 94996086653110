import { InfiniteData } from 'react-query';
import { useThreadCommentQuery } from 'services';
import { ThreadCommentResponse } from '../StoriesTypes';

type useCommentThreadType = (
  carId: string,
  storyId: string,
  commentId: string,
  list: string
) => {
  threadPages?: InfiniteData<ThreadCommentResponse>;
  isFetchingNextPageThread: boolean;
  hasNextPageThread?: boolean;
  fetchNextPageThread: () => void;
  isLoadingThread: boolean;
};

export const useCommentThread: useCommentThreadType = (carId, storyId, commentId, list) => {
  const {
    data: threadPages,
    isFetchingNextPage: isFetchingNextPageThread,
    hasNextPage: hasNextPageThread,
    fetchNextPage: fetchNextPageThread,
    isLoading: isLoadingThread,
  } = useThreadCommentQuery({ carId, storyId, commentId, list });

  return {
    threadPages,
    hasNextPageThread,
    isFetchingNextPageThread,
    fetchNextPageThread,
    isLoadingThread,
  };
};
