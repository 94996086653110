import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';
import { carReminderChangePayload } from './reminderPayloads';

export const deleteReminder = async ({ carId, reminderId }: carReminderChangePayload) => {
  const { data } = await api.delete(`cars/${carId}/reminders/${reminderId}`);
  return data;
};

export const useDeleteReminderMutation = () => {
  return useMutation(deleteReminder);
};
