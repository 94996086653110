import { ComponentType } from "react";
import { IUser } from "../StoriesTypes";
import { Theme } from "@mui/material";
import { useClasses } from "utils/hooks/useClasses";
import clsx from "clsx";
import { VerifiedIcon } from "components";

const styles = ({spacing, palette, breakpoints}: Theme) => ({
  userProfileImageWrapper: {
    width: 40,
    minWidth: 40,
    height: 40,
    position: 'relative',
    marginRight: spacing(1),
    overflow: 'hidden',

    '& img': {
        borderRadius: 20,
    }
  },
  userImage: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 20,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 19,
  },
  userImagePlaceholder: {
    background: 'rgba(25, 25, 25, 1)',
  },
})


interface UserPictureType {
  user: IUser,
}

export const UserPicture: ComponentType<UserPictureType> = ({user}) => {
  const classes = useClasses(styles);

  return (
    <div className={classes.userProfileImageWrapper}>
      <div 
        className={clsx(classes.userImage, [!user.image && classes.userImagePlaceholder])} 
        style={{backgroundImage: user.image !== "" ? `url(${user.image})` : `url(${''})`}}
      >
        {!user.image && '😎'}
      </div>
      {user.is_trusted && 
        <VerifiedIcon size="sm"/>
      }
    </div> 
  )
}