import { Car, CarData } from 'types';
import { mapKeysToCamelCase } from 'utils';

export const createCarUI = (data: CarData): Car => {
  const { ...rest } = data;

  return {
    ...mapKeysToCamelCase(rest),
  };
};
