import { useAlertsQuery } from "services/alerts/hooks/useAlertsQuery";
import { Alert } from "./components/AlertTypes";


type AlertDataResponse = {
  alerts: Alert[],
  current_page: number,
}


type useAlertsDataType = (
  pagination: {page: number}
)=> {
  data: AlertDataResponse;
  isFetching: boolean;
  isLoading: boolean;
  refetch: () => void;
}


export const useAlertsPage: useAlertsDataType = (pagination) => {

  const {data, isFetching, refetch, isLoading } = useAlertsQuery(pagination, {cacheTime: 0});

  return {
    data,
    isFetching,
    isLoading,
    refetch
  }
}