import { queryClient } from 'components';
import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

export type ShareCarPayload = { days: number; carId: string; show_optional: boolean };

const shareCar = async ({ days, carId, show_optional }: ShareCarPayload): Promise<any> => {
  const { data } = await api.post(`/cars/${carId}/records/public`, { valid_for_days: days, show_optional });
  return data;
};

export const useCarShareMutation = () => {
  return useMutation(shareCar, {
    onSuccess: () => {
      queryClient.invalidateQueries('carShareAmount');
    },
  });
};
