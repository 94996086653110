import { ArrowBack, ArrowDropDown, Cancel, Close, Search } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, Menu, MenuItem, OutlinedInput, Theme, Typography } from '@mui/material';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AssistantCountries, AssistantCountryCode, getFlagByCode, getLabelByCode } from 'utils/assistantLanguage';
import { countryFlags } from 'utils/country-flags';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ spacing, breakpoints, palette }: Theme) => ({
  root: {
    padding: spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&  button svg': {
      color: palette.text.hint,
    },
  },
  buttonClose: {
    [breakpoints.up('md')]: {
      display: 'none',
    },
  },
  buttonCounty: {
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  countyLabel: {
    display: 'flex',
    alignItems: 'center',
    color: palette.text.hint,
    paddingLeft: spacing(0.5),
  },
  countryMenu: {
    '& .MuiPaper-root': {
      left: '50% !important',
      transform: 'translateX(-50%) !important',
      background: '#212121',
    },
    '& .MuiList-root': {
      padding: 0,
    },
  },
  currentCountryMenuItem: {
    background: '#6200EE1A',
  },
  countryMenuItem: {
    background: '#2E2E2E',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

interface AssistantHeaderProps {
  country: AssistantCountryCode;
  onChangeCountry: (country: string) => void;
  onSearchSubmit: (query: string) => void;
  onCancelSearch: () => void;
}

export const AssistantHeader: ComponentType<AssistantHeaderProps> = ({
  country,
  onChangeCountry,
  onSearchSubmit,
  onCancelSearch,
}) => {
  const classes = useClasses(styles);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [mode, setMode] = useState<'default' | 'search'>('default');
  const [anchorMenuCountry, setAnchorMenuCountry] = useState<null | HTMLElement>(null);
  const openMenuCountry = Boolean(anchorMenuCountry);

  const handleClose = () => {
    if (window.history.length === 1) {
      navigate('/stories/recent');
    } else {
      navigate(-1);
    }
  };

  const handleOpenCountryMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorMenuCountry(event.currentTarget);
  };
  const handleCloseCountryMenu = () => {
    setAnchorMenuCountry(null);
  };
  const handleChangeCountry = (event: any) => {
    onChangeCountry(event);
    setAnchorMenuCountry(null);
  };

  const handleStartSearchMode = () => {
    setMode('search');
  };

  const handleCancelSearchMode = () => {
    setMode('default');
    setSearchValue('');
    onCancelSearch();
  };

  const onKeyDownSearchField = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (searchValue.length > 0) {
        onSearchSubmit(searchValue);
      }
      console.log('enter key was pressed');
    }
  };

  const handleCancelInput = () => {
    setSearchValue('');
  };
  return (
    <Box className={classes.root}>
      {mode === 'default' && (
        <>
          <IconButton onClick={handleClose} className={classes.buttonClose}>
            <Close />
          </IconButton>
          <div
            className={classes.buttonCounty}
            onClick={handleOpenCountryMenu}
            id='country-button'
            aria-controls={openMenuCountry ? 'country-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={openMenuCountry ? 'true' : undefined}
          >
            {countryFlags[getFlagByCode(country)]}{' '}
            <Typography className={classes.countyLabel}>
              {t(getLabelByCode(country))} <ArrowDropDown />
            </Typography>
          </div>
          <Menu
            open={openMenuCountry}
            id='country-menu'
            anchorEl={anchorMenuCountry}
            onClose={handleCloseCountryMenu}
            className={classes.countryMenu}
            MenuListProps={{
              'aria-labelledby': 'country-button',
            }}
          >
            {AssistantCountries.map((item) => (
              <MenuItem
                key={item.code}
                className={country === item.code ? classes.currentCountryMenuItem : classes.countryMenuItem}
                onClick={() => handleChangeCountry(item.code)}
              >
                {countryFlags[getFlagByCode(item.code)]} {t(getLabelByCode(item.code))}
              </MenuItem>
            ))}
          </Menu>
          <IconButton onClick={handleStartSearchMode}>
            <Search />
          </IconButton>
        </>
      )}
      {mode === 'search' && (
        <>
          <IconButton onClick={handleCancelSearchMode}>
            <ArrowBack />
          </IconButton>
          <OutlinedInput
            fullWidth
            size='small'
            placeholder='Search messages'
            onKeyDown={onKeyDownSearchField}
            value={searchValue}
            onChange={(e: any) => setSearchValue(e.target.value)}
            endAdornment={
              searchValue !== '' && (
                <InputAdornment position='end'>
                  <IconButton edge='end' onClick={handleCancelInput}>
                    <Cancel />
                  </IconButton>
                </InputAdornment>
              )
            }
          />
        </>
      )}
    </Box>
  );
};
