// Utility function to validate YouTube URL
export const validateYouTubeUrl = (url: string) => {
  const pattern = /^(https?:\/\/)?((www|m)\.)?(youtube\.com|youtu\.be)(\/[^\s]*)?$/;
  return pattern.test(url);
};

export const getYoutubeIdFromUrl = (url: string): string | undefined => {
  const regExp = /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=))([^#&?]*)/;
  const match = url.match(regExp);
  return match ? match[1] : undefined;
};

export const getAvailableYoutubeCoverUrl = async (videoId: string): Promise<string | undefined> => {
  const qualities = ['maxresdefault', 'hqdefault', 'mqdefault', 'sddefault', '1default'];
  return `https://img.youtube.com/vi/${videoId}/${qualities[1]}.jpg`;
};

export const getYoutubeVideoIdFromCoverUrl = (url: string | undefined): string | undefined => {
  if (!url) return undefined;
  const match = url.match(/\/vi\/([^]+)\/([^?]*)\.jpg/);
  return match ? match[1] : undefined;
};
