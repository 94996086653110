import { useMutation } from 'react-query';

import { queryClient } from 'components';
import { axiosWithFileInstance } from 'utils';

const updateUserProfileInfo = async (body: any): Promise<any> => {
  try {
    const { data } = await axiosWithFileInstance.put('users/user-profile', body);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message };
  }
};

export const useUserProfileInfoMutation = () => {
  return useMutation(updateUserProfileInfo, {
    onSuccess: () => {
      queryClient.invalidateQueries('userProfile');
    },
  });
};
