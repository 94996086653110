import { useQuery, UseQueryResult } from 'react-query';

import { axiosApiInstance as api } from 'utils';

export interface PublicUserFollowers {
  following: number;
  followers:  number;
}

const getPublicUserFollowers = async (userId: string): Promise<PublicUserFollowers | { error?: boolean }> => {
  try {
    const { data } = await api.get(`users/${userId}/follows`);
    return data;
  } catch (err) {
    return { error: true };
  }
};

export const usePublicUserFollowers = (userId: string): UseQueryResult<PublicUserFollowers> => {
  return useQuery(['userPublicFollowers', userId], () => getPublicUserFollowers(userId), {
    cacheTime: 0,
  });
};
