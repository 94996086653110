import { ComponentType } from 'react'
import { useClasses } from 'utils/hooks/useClasses';
import { Theme} from '@mui/material';
import { IItem } from './AdminItemTypes';
import {ThumbUpAlt, ThumbDownAlt} from '@mui/icons-material'

const styles = ({palette, spacing}: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'end',
        marginTop: 18,
        '& div': {
            margin: '0 5px',
        },
    },
    dislikeIcon: {
        marginLeft: 5
    }

})

interface AdminItemLikesType {
    item: IItem
}

export const AdminItemLikes: ComponentType<AdminItemLikesType> = ({
    item
}) => {
    const classes = useClasses(styles);
    const likes = item?.likes?.length || 0;
    const dislikes = item?.dislikes?.length || 0;

    return ( 
        <div className={classes.root}>
            <ThumbUpAlt/>
            <div>{likes}</div>
            <ThumbDownAlt className={classes.dislikeIcon}/>
            <div>{dislikes}</div>
        </div>
    )
}
