import { useLikeStoryMutation, useRemoveLikeStoryMutation, useDislikeStoryMutation, useRemoveDislikeStoryMutation } from "services/stories";
import { StoryPayload } from "../StoriesTypes";

type useLikeDislikeStoryType = () => {
  like: (data: StoryPayload) => void,
  removeLike: (data: StoryPayload) => void,
  dislike: (data: StoryPayload) => void,
  removeDislike: (data: StoryPayload) => void,
}

export const useLikeDislikeStory:useLikeDislikeStoryType = () => {
  const likeMutation = useLikeStoryMutation();
  const removeLikeMutation = useRemoveLikeStoryMutation();
  const dislikeMutation = useDislikeStoryMutation();
  const removeDislikeMutation = useRemoveDislikeStoryMutation();

  const like = (data: StoryPayload) => {
    likeMutation.mutate(data);
  }
  
  const removeLike = (data: StoryPayload) => {
    removeLikeMutation.mutate(data);
  }
  
  const dislike = (data: StoryPayload) => {
    dislikeMutation.mutate(data);
  }
  
  const removeDislike = (data: StoryPayload) => {
    removeDislikeMutation.mutate(data);
  }
  
  return {
    like,
    removeLike,
    dislike,
    removeDislike,
  }
}