import { Theme } from '@mui/material';
import { CommonLayout } from 'components';
import { useParams } from 'react-router-dom';
import { useClasses } from 'utils/hooks/useClasses';
import { EditStoryForm } from './EditStoryForm';

const styles = ({ spacing, breakpoints }: Theme) => ({
  container: {
    width: '50%',
    margin: 'auto',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
    [breakpoints.up('sm')]: {
      minWidth: 532,
    },
  },
});

export const EditStoryPage = () => {
  const classes = useClasses(styles);
  const { carId, storyId } = useParams<{ carId: string; storyId: string }>();
  return (
    <CommonLayout>
      <div className={classes.container}>
        <EditStoryForm carId={carId as string} storyId={storyId as string} />
      </div>
    </CommonLayout>
  );
};
