import { useState } from 'react';
import { BanType, IUser } from '../components/AdminDashboardTypes';
import { useAdminUserModerate } from './useAdminUserModerate';

type useAdminUnblockUserType = () => {
  onOpenUnblockUserDialog: (user: IUser) => void;
  openUnblockUserDialog: boolean;
  onUnblockUser: (from: BanType) => void;
  unblockedUser: IUser | null;
  onCloseUnblockUserDialog: () => void;
};

export const useAdminUnblockUser: useAdminUnblockUserType = () => {
  const [openUnblockUserDialog, setOpenUnblockUserDialog] = useState<boolean>(false);
  const [unblockedUser, setUnblockedUser] = useState<IUser | null>(null);

  const { unblockUser } = useAdminUserModerate();

  const onUnblockUser = async (from: BanType) => {
    if (!unblockedUser) return;
    await unblockUser(unblockedUser.id, from);
    setUnblockedUser(null);
    setOpenUnblockUserDialog(false);
  };

  const onOpenUnblockUserDialog = (user: IUser) => {
    setUnblockedUser(user);
    setOpenUnblockUserDialog(true);
  };

  const onCloseUnblockUserDialog = () => {
    setUnblockedUser(null);
    setOpenUnblockUserDialog(false);
  };

  return {
    unblockedUser,
    onUnblockUser,
    openUnblockUserDialog,
    onOpenUnblockUserDialog,
    onCloseUnblockUserDialog,
  };
};
