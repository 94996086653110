import { Close, Delete } from '@mui/icons-material';
import { Box, Button, Grid, Modal, Theme, Typography } from '@mui/material';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageUploading from 'react-images-uploading';
import { toast } from 'react-toastify';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { Camera } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';

const styles = ({ breakpoints, spacing, palette }: Theme) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  container: {
    backgroundColor: 'rgba(46, 46, 46, 1)',
    width: 548,
    margin: 9,
    borderRadius: 4,
    padding: spacing(5, 3, 1),
    position: 'relative',
    [breakpoints.down('sm')]: {
      padding: spacing(2, 2, 1),
    },
  },
  title: {
    fontSize: 20,
    fontWeight: '500',
  },
  note: {
    fontSize: 12,
    color: palette.text.hint,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
    marginBottom: 10,
  },

  footer: {
    marginTop: spacing(5),
    minHeight: 40,
  },
  footerContent: {
    display: 'flex',
    flexDirection: 'row-reverse',
    '& button': {
      padding: '10px 25px',
    },
  },
  close: {
    cursor: 'pointer',
  },
  imagesContainer: {
    marginTop: 15,
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
    borderRadius: 8,
    padding: spacing(1),
    marginTop: 2,
    height: 66,
  },
  uploadButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
    borderRadius: 8,
    padding: 8,
    marginTop: 2,
    height: 64,
  },
  image: {
    maxWidth: 86,
    maxHeight: 50,
  },
  trash: {
    width: 40,
    cursor: 'pointer',
  },
  button: {
    color: 'rgba(178, 123, 252, 0.4) !important',
  },
});
const tKey = getTKey('image_upload_modal');

interface ImageUploadModalType {
  position: number;
  carId: string;
  open: boolean;
  sendImages: (images: any[]) => void;
  onClose: () => void;
}
export const ImageUploadModal: ComponentType<ImageUploadModalType> = ({
  open,
  carId,
  position,
  onClose,
  sendImages,
}) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const [images, setImages] = useState<any[]>([]);

  const onChange = (imageList: any[]) => {
    setImages(imageList);
  };

  const onError = (errors: any, files: any) => {
    if (errors.maxNumber) {
      toast(t(tKey('max_count')));
      setImages([...images, ...files.slice(0, 5 - images.length)]);
    }
    if (errors.acceptType) {
      toast(t(tKey('unsupported_format')));
    }
  };

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      Camera.requestPermissions({ permissions: ['photos', 'camera'] });
    }
  }, []);

  return (
    <Modal open={open} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      <Box className={classes.main}>
        <Box className={classes.container}>
          <Box className={classes.header}>
            <Typography className={classes.title}> {t(tKey('title'))}</Typography>
            <Close className={classes.close} onClick={onClose} />
          </Box>
          <Typography className={classes.note}>{t(tKey('note'))}</Typography>
          <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={5}
            dataURLKey='data_url'
            acceptType={['jpg', 'jpeg', 'gif', 'png']}
            onError={onError}
          >
            {({ imageList, onImageUpload, onImageRemove }) => (
              <>
                <Grid container className={classes.imagesContainer}>
                  {imageList.map((image, index) => (
                    <Grid item xs={12} className={classes.imageContainer} key={index}>
                      <img className={classes.image} src={image['data_url']} alt='' />
                      <Delete className={classes.trash} onClick={() => onImageRemove(index)} />
                    </Grid>
                  ))}
                  {imageList.length < 5 && (
                    <Grid item xs={12} className={classes.uploadButton}>
                      <Button color='secondary' onClick={onImageUpload}>
                        {t(tKey('upload'))}
                      </Button>
                    </Grid>
                  )}
                </Grid>

                <Box className={classes.footer}>
                  <Box className={classes.footerContent}>
                    <Button
                      classes={{ disabled: classes.button }}
                      disabled={imageList.length === 0}
                      onClick={() => {
                        sendImages(imageList);
                        onClose();
                      }}
                    >
                      {t(tKey('add'))}
                    </Button>
                    <Button onClick={onClose}>{t(tKey('cancel'))} </Button>
                  </Box>
                </Box>
              </>
            )}
          </ImageUploading>
        </Box>
      </Box>
    </Modal>
  );
};
