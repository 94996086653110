import { useQuery, UseQueryOptions } from 'react-query';
import { isArray } from 'lodash';

import { Record } from 'types';
import { axiosApiInstance as api } from 'utils';

import { createRecordUI } from '../utils';

export const getRecords = async (carId: string): Promise<Record[]> => {
  const { data } = await api.get(`/cars/${carId}/records`);
  const arrayOfRecords: Record[] = [];

  data.records.forEach((item: any) => {
    if (isArray(item)) {
      const mapped = item.map(createRecordUI) as Record[];
      arrayOfRecords.push(mapped as any);
    } else {
      arrayOfRecords.push(createRecordUI(item));
    }
  });

  return arrayOfRecords;
};

export const getDashboardRecords = async (carId: string) => {
  const { data } = await api.get(`/cars/${carId}/records/dashboard`);

  return data.records.map(createRecordUI);
};

export const useRecordsByCarQuery = (
  carId: string,
  page: 'dashboard' | 'records',
  options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>
) => {
  if (!carId) {
    carId = '1';
  }
  const isDashboardPage = page === 'dashboard';

  return useQuery(
    [isDashboardPage ? 'dashboardRecords' : 'records', carId],
    () => (isDashboardPage ? getDashboardRecords(carId) : getRecords(carId)),
    options
  );
};
