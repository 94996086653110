import { FetchNextPageOptions, InfiniteData, RefetchOptions } from 'react-query';
import { storiesFeedResponse, storyPagination, useStoriesFeedQuery } from 'services/stories';

type useStoriesDataType = (pagination: storyPagination) => {
  data?: InfiniteData<storiesFeedResponse>;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  fetchNextPage: (options?: FetchNextPageOptions) => void;
  refetch: (options?: RefetchOptions) => void;
};

export const useStoriesData: useStoriesDataType = (pagination) => {
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading, refetch } = useStoriesFeedQuery(pagination);
  return {
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  };
};
