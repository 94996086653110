import { ComponentType, useState } from 'react';

import { Delete } from '@mui/icons-material';
import { Box, Button, FormHelperText, IconButton, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ImageUploading, { ImageListType } from 'react-images-uploading';

import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { SpinFeedLoaderMini } from 'components/SpinFeedLoader/SpinFeedLoader';
import { Record } from 'types';

const tKey = getTKey('image_uploader');
const MAXIMUM_IMAGES_QUANTITY = 5;
const MAXIMUM_IMAGES_SIZE_IN_BYTES = 10000000;

interface ImageUploaderMultipleProps {
  images: ImageListType;
  onChange: any;
  editMode?: boolean;
  data: Record;
}

const styles = ({ palette, spacing }: Theme) => ({
  root: {
    marginTop: spacing(1),

    '& img': {
      width: '100%',
      maxWidth: 120,
    },
  },
  title: {
    marginRight: spacing(1),
    fontSize: 16,
    fontWeight: 600,
  },
  hint: {
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.6)',
  },
  uploadedImage: {
    position: 'relative',
    width: '33%',
    marginRight: spacing(0.5),

    '&:hover $uploadedImageFile': {
      filter: 'brightness(0.6)',
    },
  },
  uploadedImageFile: {
    paddingBottom: '68%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  deleteButtonWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: palette.text.hint,
  },
});

export const ImageUploaderMultiple: ComponentType<ImageUploaderMultipleProps> = ({
  images,
  onChange,
  editMode,
  data,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);

  const [recordImageAdded, setRecordImageAdded] = useState(true);

  const [shouldRenderIcon, setShouldRenderIcon] = useState(false);

  const renderIcon = () => {
    setShouldRenderIcon(true);
  };

  return (
    <div className={classes.root}>
      <ImageUploading
        value={images}
        onChange={onChange}
        acceptType={['jpg', 'jpeg', 'png']}
        multiple
        maxNumber={MAXIMUM_IMAGES_QUANTITY}
        maxFileSize={MAXIMUM_IMAGES_SIZE_IN_BYTES}
      >
        {({ imageList, onImageUpload, onImageRemove, onImageRemoveAll, errors }) => (
          <>
            <Box display='flex' alignItems='center' mb={1.5}>
              <span className={classes.title}>{t(tKey('attachments'))}:</span>
              {imageList.length < 5 && (
                <Button variant='text' color='primary' onClick={onImageUpload}>
                  {t(tKey('attach_file'))}
                </Button>
              )}
            </Box>
            {imageList.length > 0 ? (
              <Box display='flex' mb={0.5}>
                {imageList.map((image, index) => (
                  <div key={index} className={classes.uploadedImage}>
                    <img src={image.dataURL} onLoad={() => renderIcon()} style={{ display: 'none' }} alt='' />
                    <div
                      className={classes.uploadedImageFile}
                      style={{
                        backgroundImage: `url(${image.dataURL})`,
                      }}
                    />
                    {shouldRenderIcon && (
                      <div className={classes.deleteButtonWrapper}>
                        <IconButton
                          color='inherit'
                          onClick={() => {
                            onImageRemove(index);
                            setRecordImageAdded(false);
                          }}
                          size='large'
                        >
                          <Delete />
                        </IconButton>
                      </div>
                    )}
                  </div>
                ))}
              </Box>
            ) : recordImageAdded && data && data.imageOne !== '' && data.imageOne !== undefined ? (
              <SpinFeedLoaderMini loading={true} />
            ) : null}
            {errors && (
              <FormHelperText error>
                {errors.maxNumber && <span>{t(tKey('error_number'), { maxNumber: MAXIMUM_IMAGES_QUANTITY })}</span>}
                {errors.acceptType && <span>{t(tKey('error_type'))}</span>}
                {errors.maxFileSize && (
                  <span>{t(tKey('error_size'), { maxFileSize: MAXIMUM_IMAGES_SIZE_IN_BYTES / 1000000 + ' MB' })}</span>
                )}
              </FormHelperText>
            )}
            <div className={classes.hint}>{t(tKey('types_hint'))}</div>
          </>
        )}
      </ImageUploading>
    </div>
  );
};
