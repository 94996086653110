import { ComponentType } from 'react';

import { PublicRecordsHeader, Wrapper } from 'components';

export const PublicRecordsLayout: ComponentType = ({ children }) => {
  return (
    <>
      <PublicRecordsHeader />
      <Wrapper>{children}</Wrapper>
    </>
  );
};
