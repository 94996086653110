import { Event } from '@mui/icons-material';
import { InputAdornment, Theme } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import clsx from 'clsx';
import AutoAwesome from 'components/CustomIcons/AutoAwesome';
import { Dayjs } from 'dayjs';
import { ComponentType, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useClasses } from 'utils/hooks/useClasses';
import { enUS, esES, ukUA } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/uk';
import 'dayjs/locale/es';

const locales: Record<string, any> = {
  en: enUS.components.MuiLocalizationProvider.defaultProps.localeText,
  uk: ukUA.components.MuiLocalizationProvider.defaultProps.localeText,
  es: esES.components.MuiLocalizationProvider.defaultProps.localeText,
};
const styles = ({ spacing }: Theme) => ({
  root: {
    margin: spacing(2, 0, 1),
    width: '100%',
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '& label.Mui-focused': {
      color: '#bb86fc',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#bb86fc',
    },
    '& .MuiOutlinedInput-root': {
      width: '100%',
      '& fieldset': {
        borderColor: '',
        width: '100%',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.87)',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#bb86fc',
      },
    },
    '& .MuiButtonBase-root': {
      color: 'grey',
    },
    '& .MuiFormLabel-root': {
      maxWidth: 'calc(100% - 42px)',
    },
  },
  setHeight: {
    '&.MuiFormControl-root': {
      height: '85%',
    },
    '& .MuiInputBase-root': {
      padding: '10.5px 14px',
      height: '100%',
    },
  },
  ai: {
    '&::before': {
      content: `""`,
      position: 'absolute',
      width: 'calc(100% - 16px)',
      height: 'calc(100% - 16px)',
      margin: 8,
      borderRadius: 2,
      background: 'rgba(187, 134, 252, 0.1)',
    },
  },
});

interface DatePickerSelectProps {
  name: string;
  label: string;
  format?: string;
  setHeight?: any;
  datePickerProps?: any;
  ai?: boolean;
}

export const DatePickerSelect: ComponentType<DatePickerSelectProps> = ({
  name,
  label,
  format,
  setHeight,
  datePickerProps: textFieldProps,
  ai,
}) => {
  const classes = useClasses(styles);
  const { control } = useFormContext();
  const [value, setLocalValue] = useState<Dayjs | null>();
  const { i18n } = useTranslation();
  const [language] = useState(i18n.language === 'en' ? 'en-gb' : i18n.language);
  const { t } = useTranslation();
  useEffect(() => {
    setLocalValue(textFieldProps?.value as Dayjs);
  }, [textFieldProps?.value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language} localeText={locales[i18n.language]}>
          <DesktopDatePicker
            {...field}
            name={name}
            sx={{ display: { md: 'block', xs: 'none' } }}
            label={label}
            className={clsx(classes.root, setHeight && classes.setHeight, ai && classes.ai)}
            defaultValue={value}
            format={format?.toLocaleUpperCase()}
            onChange={(newValue) => {
              field.onChange(newValue);
              setLocalValue(newValue);
            }}
            disableFuture
            slots={{ openPickerIcon: ai ? AutoAwesome : Event }}
          />
          <MobileDatePicker
            {...field}
            name={name}
            sx={{ display: { md: 'none', xs: 'block' } }}
            label={label}
            className={clsx(classes.root, setHeight && classes.setHeight)}
            localeText={{ toolbarTitle: t('__date_picker__toolbar_title') }}
            defaultValue={value}
            format={format?.toLocaleUpperCase()}
            onChange={(newValue) => {
              field.onChange(newValue);
              setLocalValue(newValue);
            }}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: ai && (
                    <InputAdornment position='end'>
                      <AutoAwesome />
                    </InputAdornment>
                  ),
                },
              },
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
};
