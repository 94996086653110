import { Beenhere } from "@mui/icons-material";
import { Theme } from "@mui/material";
import { ComponentType } from "react";
import { useClasses } from "utils/hooks/useClasses";


type VerifiedIconSizeType = 'sm' | 'md'

export const VerifiedIcon: ComponentType<{size: VerifiedIconSizeType}> = ({size}) => {

  const styles = ({palette}: Theme) => ({
    verified: {
      color: palette.secondary.main,
      position: 'absolute',
      top: 0,
      right: 0,
      ...(size === 'sm' && {
        right: 2,
        width: 16,
        height: 14,
      }),
      ...(size === 'md' && {
        width: 25,
      })
    },
    background: {
      background: '#018786',
      position: 'absolute',
      ...(size === 'sm' && {
        width: 10,
        height: 8,
        top: 2,
        right: 5,
      }),
      ...(size === 'md' && {
        width: 16,
        height: 15,
        top: 2,
        right: 4,
      })

    },
  })

  const classes = useClasses(styles);

  return (
    <>
      <div className={classes.background}></div>
      <Beenhere className={classes.verified} />
    </>
  )
}