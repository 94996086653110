import { Capacitor } from '@capacitor/core';
import { ArrowForward } from '@mui/icons-material';
import { Box, Divider, Theme, Typography } from '@mui/material';
import { InnerMobileHeader } from 'components';
import { settingMenuItems } from 'components/SettingsMenu/menuItems';
import { RemoveAccountDialog } from 'components/v2/Settings';
import { random } from 'lodash';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDeleteProfileMutation } from 'services';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  subHeading: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 10,
  },
  menuItem: {
    fontSize: 16,
    fontWeight: '500',
  },
  menuContainer: {
    display: 'flex',
    color: 'rgba(255, 255, 255, 0.87)',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: spacing(2, 0),
    cursor: 'pointer',
  },
  divider: {
    margin: spacing(2, 0),
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
  },
  link: {
    textDecorationLine: 'none',
  },
});

export const SettingsTab: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const mutation = useDeleteProfileMutation();
  const [settingsMenuItems, setSettingsMenuItems] = useState(settingMenuItems);
  //  to: '/settings/logout',
  useEffect(() => {
    //@ts-ignore
    gtag?.('event', 'visited_settings', { method: 'visited_settings' });

    if (Capacitor.isNativePlatform()) {
      let newState = { ...settingMenuItems };
      if (newState.settings_account.find((el) => el.to === '/settings/notifications') === undefined) {
        newState.settings_account.push({
          label: 'setting_notifications',
          to: '/settings/notifications',
        });
        setSettingsMenuItems(newState);
      }
    }
  }, []);

  const deleteAccount = () => {
    mutation.mutate(undefined, {
      onSuccess(data, variables, context) {
        if (!data.error) {
          navigate('/settings/logout');
        } else {
          toast(t('__common__error'));
        }
      },
      onError(error, variables, context) {
        toast(t('__common__error'));
      },
    });
  };

  return (
    <>
      {open && (
        <RemoveAccountDialog
          onCancel={() => setOpen(false)}
          onClose={() => setOpen(false)}
          onConfirm={() => deleteAccount()}
        />
      )}
      <InnerMobileHeader title={t('settings_settings')} />

      {Object.entries(settingsMenuItems).map(([subheading, items]) => {
        return (
          <div key={subheading + random()}>
            <Typography className={classes.subHeading}>{t(subheading)}</Typography>
            {items.map((item) => (
              <NavLink className={classes.link} to={item.to} key={item.to}>
                <Box className={classes.menuContainer}>
                  <Typography className={classes.menuItem}>{t(item.label)}</Typography>
                  <ArrowForward width={16} height={16} />
                </Box>
                <Divider className={classes.divider} />
              </NavLink>
            ))}
          </div>
        );
      })}
      <Box className={classes.menuContainer} onClick={() => setOpen(true)}>
        <Typography className={classes.menuItem}>{t('setting_remove_account')}</Typography>
        <ArrowForward width={16} height={16} />
      </Box>
      <Divider className={classes.divider} />
    </>
  );
};
