import { Theme, Typography } from '@mui/material';
import { StyledButton } from 'components/StyledButton/StyledButton';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

interface AssistedRecordInstructionProps {
  hideReminder: () => void;
}
const styles = ({ breakpoints, spacing, palette }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 780,
    minHeight: 600,
    margin: spacing(0, 'auto'),
    padding: spacing(4),
    backgroundColor: 'rgba(255,255,255,0.08)',
    [breakpoints.down('md')]: {
      padding: spacing(2),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconWrapper: {
    marginBottom: spacing(1),
  },
  icon: {
    fontSize: 100,
  },
  text: {
    fontSize: 16,
    opacity: 1,
    color: palette.additionalPrimary[100],

    [breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
  instruction: {
    width: '100%',
    [breakpoints.down('md')]: {
      textAlign: 'justify',
      paddingRight: spacing(2),
    },
  },
  span: {
    color: palette.additionalSecondary[100],
  },
  watch: {
    margin: spacing(2),
  },
});

const tKey = getTKey('assisted_record_instruction');
export const AssistedRecordInstruction: ComponentType<AssistedRecordInstructionProps> = ({ hideReminder }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.iconWrapper}>
          <span className={classes.icon}>🤓</span>
        </div>
        <Typography variant='body1' align='center' className={classes.text}>
          {t(tKey('remind_you'))}
        </Typography>
        <ul>
          <li>
            <Typography variant='body1' className={classes.instruction}>
              {t(tKey('one'))}
            </Typography>
          </li>

          <li>
            <Typography variant='body1' className={classes.instruction}>
              {t(tKey('two'))}
            </Typography>
          </li>
          <li>
            <Typography variant='body1' className={classes.instruction}>
              {t(tKey('three'))}
            </Typography>
          </li>
        </ul>
        <StyledButton label={t(tKey('hide_reminder'))} onClick={hideReminder} />
      </div>
    </div>
  );
};
