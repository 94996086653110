import { ComponentType, useContext } from 'react';
import { Box, Theme } from '@mui/material';
import { useClasses } from 'utils/hooks/useClasses';
import { AssistantAvatar } from './AssistantAvatar';
import animationData from './messageLoader.json';
import markdownit from 'markdown-it';
import Lottie from 'lottie-react';
import { SearchHighlightContext } from '../SearchHighlightContext';
import { markHighlightText } from 'utils';
const md = markdownit();

const styles = ({ spacing, breakpoints, palette }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'start',
    padding: spacing(2),
  },
  message: {
    paddingTop: spacing(0.5),
    paddingLeft: spacing(1),
    '& p': {
      margin: 0,
    },
    '& ol, & ul': {
      paddingLeft: spacing(2.5),
    },
    '& img': {
      maxWidth: 250,
    },
  },
  loader: {
    width: 45,
    height: 25,
    margin: 'auto 0',
  },
});

interface assistantMessageProps {
  country: string;
  message: string;
  isLoading?: boolean;
  index: number;
}

export const AssistantMessage: ComponentType<assistantMessageProps> = ({ country, message, isLoading, index }) => {
  const classes = useClasses(styles);
  const context = useContext(SearchHighlightContext);
  const renderedMessage = context.query
    ? markHighlightText(md.render(message), context.query, context.current === index)
    : md.render(message);

  return (
    <Box className={classes.root}>
      <AssistantAvatar country={country} />
      <div className={classes.message} dangerouslySetInnerHTML={{ __html: renderedMessage }}></div>
      {isLoading && <Lottie animationData={animationData} className={classes.loader} />}
    </Box>
  );
};
