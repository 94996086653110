import { Flag, GTranslate, Reply, ThumbDownAlt, ThumbUpAlt } from '@mui/icons-material';
import { Button, IconButton, Theme } from '@mui/material';
import clsx from 'clsx';
import { SpinFeedLoaderMini } from 'components';
import { PatronSmall } from 'components/PatronBadges/PatronSmall';
import { UnregisteredPopup } from 'components/UnregisteredPopup/UnregisteredPopup';
import dayjs from 'dayjs';
import { IComment, LikeStateType } from 'features/stories-feed/StoriesTypes';
import { useCommentActions } from 'features/stories-feed/hooksComments/useCommentActions';
import { useCommentThread } from 'features/stories-feed/hooksComments/useCommentThread';
import { ComponentType, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ProfileContext } from 'store/profile/store';
import { useClasses } from 'utils/hooks/useClasses';
import { UserPicture } from '../../UserPicture';
import { ReportDialog } from '../ReportDialog';
import { CommentManageButton } from './CommentManageButton';
import { CommentsList } from './CommentsList';

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const styles = ({ breakpoints, spacing, palette }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: spacing(2),
  },
  isDeleted: {
    display: 'none',
  },
  commentBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  body: {
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerAdditional: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
  },
  timeAgo: {
    textAlign: 'end',
    color: 'rgba(255, 255, 255, 0.38)',
    fontSize: 12,
  },
  userImageWrapper: {
    display: 'contents',
    cursor: 'pointer',
  },
  username: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '18px',
    cursor: 'pointer',
    display: 'flex',
  },
  usernamePatron: {
    color: '#FFCB40',
  },
  partonWrapper: {
    paddingLeft: spacing(1),
  },
  flagBtn: {
    padding: spacing(0, 0, 0, 1),
  },
  flagBtnIcon: {
    width: 18,
    height: 18,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: spacing(1),
  },
  reactions: {
    display: 'flex',
    flexDirection: 'row',
  },
  reaction: {
    color: '#A5A5A5',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 700,
    paddingLeft: spacing(1),
    '& svg': {
      width: 18,
      height: 18,
      paddingRight: spacing(0.5),
      cursor: 'pointer',
    },
  },
  hasUserReact: {
    color: palette.primary.main,
    '& svg': {
      color: palette.primary.main,
    },
  },
  firstReply: {
    paddingTop: spacing(2),
    paddingLeft: 58,
  },
  replyInputWrapper: {
    paddingLeft: 48,
  },
  replyCommentsWrapper: {
    paddingTop: spacing(2),
    paddingLeft: 58,
  },
  buttonsActions: {
    '& button': {
      padding: spacing(0.25, 0.5),
      marginRight: spacing(1),
      '& svg': {
        maxWidth: 20,
      },
    },
  },
});

interface CommentItemType {
  carId: string;
  storyId: string;
  comment: IComment;
  authorized: boolean;
  list?: string;
}

export const CommentItem: ComponentType<CommentItemType> = ({ carId, storyId, comment, authorized, list }) => {
  const classes = useClasses(styles);
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const [likes, setLikes] = useState(comment.likes);
  const [dislikes, setDislikes] = useState(comment.dislikes);
  const [likeState, setLikeState] = useState<LikeStateType>(
    comment.is_liked ? 'liked' : comment.is_disliked ? 'disliked' : null
  );

  const [openReport, setOpenReport] = useState(false);
  const [openUnregistered, setOpenUnregistered] = useState<boolean>(false);
  const [commentsInThread, setCommentsInThread] = useState(
    comment?.comments_in_thread_count && comment?.comments_in_thread_count > 0
      ? comment.comments_in_thread_count - 1
      : 0
  );
  const [noTranslate, setNoTranslate] = useState(comment.do_not_translate);
  const { state } = useContext(ProfileContext);
  const [editing, setEditing] = useState(false);
  const [repliedComments, setRepliedComments] = useState<IComment[]>([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const [doNotTranslate, setDoNotTranslate] = useState(comment.do_not_translate);
  const navigate = useNavigate();
  const lang = i18n.language;

  try {
    if (lang === 'uk') {
      dayjs.locale(require(`dayjs/locale/uk`));
    } else if (lang === 'es') {
      dayjs.locale(require(`dayjs/locale/es`));
    } else {
      dayjs.locale(require(`dayjs/locale/en`));
    }
  } catch (e) {
    console.log(`Cannot load ${lang} language. Will be used english language`);
  }

  const { like, removeLike, dislike, removeDislike, report, deleteComment } = useCommentActions();

  const { threadPages, fetchNextPageThread, isFetchingNextPageThread, isLoadingThread } = useCommentThread(
    carId,
    storyId,
    comment.id,
    list || ''
  );

  useEffect(() => {
    setLikes(comment.likes);
    setDislikes(comment.dislikes);
    setLikeState(comment.is_liked ? 'liked' : comment.is_disliked ? 'disliked' : null);
  }, [comment]);

  useEffect(() => {
    const editedComment = (event: any) => {
      const editResponse = event.detail;
      if (editResponse.id === comment.id) {
        setEditing(false);
        comment.text = editResponse.text;
        if (editResponse.translated_text) {
          comment.translated_text = editResponse.translated_text;
        }
        setDoNotTranslate(editResponse.do_not_translate);
      }
    };
    window.addEventListener('editedComment', editedComment);
    return () => {
      window.removeEventListener('editedComment', editedComment);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitReport = (data: any) => {
    report({
      body: data,
      storyId,
      carId,
      commentId: comment.id,
    });
    setOpenReport(false);
  };

  const reportHandler = () => {
    if (!authorized) {
      setOpenUnregistered(true);
      return;
    }
    setOpenReport(true);
  };

  const replyHandle = () => {
    if (!authorized) {
      setOpenUnregistered(true);
      return;
    }
    const event = new CustomEvent('onReplyStart', { detail: comment });
    window.dispatchEvent(event);

    const sentReply = (event: any) => {
      let replyResponse = event.detail;
      if (replyResponse?.id) {
        setRepliedComments([replyResponse, ...repliedComments]);
      }
      window.removeEventListener('sentReply', sentReply);
    };
    window.addEventListener('sentReply', sentReply);
  };

  const translateHandler = () => {
    setNoTranslate(!noTranslate);
  };

  const onLike = () => {
    if (!authorized) {
      setOpenUnregistered(true);
      return;
    }
    if (likeState === 'liked') {
      removeLike({ carId, storyId, commentId: comment.id });
      setLikes(likes - 1);
      setLikeState(null);
    } else if (likeState === 'disliked') {
      like({ carId, storyId, commentId: comment.id });
      setLikes(likes + 1);
      setDislikes(dislikes - 1);
      setLikeState('liked');
    } else if (likeState === null) {
      like({ carId, storyId, commentId: comment.id });
      setLikes(likes + 1);
      setLikeState('liked');
    }
  };

  const onDislike = () => {
    if (!authorized) {
      setOpenUnregistered(true);
      return;
    }
    if (likeState === 'liked') {
      dislike({ carId, storyId, commentId: comment.id });
      setLikes(likes - 1);
      setDislikes(dislikes + 1);
      setLikeState('disliked');
    } else if (likeState === 'disliked') {
      removeDislike({ carId, storyId, commentId: comment.id });
      setDislikes(dislikes - 1);
      setLikeState(null);
    } else if (likeState === null) {
      dislike({ carId, storyId, commentId: comment.id });
      setDislikes(dislikes + 1);
      setLikeState('disliked');
    }
  };

  useEffect(() => {
    if (threadPages && threadPages?.pages.length > 0) {
      const total = threadPages.pages[threadPages?.pages.length - 1].total;
      const lastPageCount = threadPages.pages[threadPages?.pages.length - 1].thread_comments.length;
      const left = total - (threadPages?.pages.length - 1) * 10 - lastPageCount;
      setCommentsInThread(left);
    }
  }, [threadPages]);

  const handleLoadThreads = () => {
    if (!authorized) {
      setOpenUnregistered(true);
      return;
    }
    fetchNextPageThread();
  };

  const goToUser = () => {
    if (authorized) {
      navigate(`/user/${comment.user.id}`);
    }
    if (!authorized) {
      setOpenUnregistered(true);
    }
  };

  const handleEdit = () => {
    if (!editing) {
      const startEditEvent = new CustomEvent('startEdit', { detail: { text: comment.text, id: comment.id } });
      dispatchEvent(startEditEvent);
    }
    if (editing) {
      const endEditEvent = new CustomEvent('endEdit', { detail: { id: comment.id } });
      dispatchEvent(endEditEvent);
    }
    setEditing(!editing);
  };

  const handleDelete = async () => {
    comment.is_deleted = true;
    await deleteComment({ carId, storyId, commentId: comment.id });
    setIsDeleted(true);
    toast(t('comment_deleted'));
    const deleteEvent = new CustomEvent('deleteComment');
    window.dispatchEvent(deleteEvent);
  };

  return (
    <div className={isDeleted ? classes.isDeleted : classes.root}>
      <div className={classes.commentBox}>
        <div className={classes.userImageWrapper} onClick={goToUser}>
          <UserPicture user={comment.user} />
        </div>
        <div className={classes.body}>
          <div className={classes.header}>
            <div
              className={clsx(classes.username, comment.user.is_patron && classes.usernamePatron)}
              onClick={goToUser}
            >
              {comment.user.username}
              {comment.user.is_patron && (
                <div className={classes.partonWrapper}>
                  <PatronSmall />
                </div>
              )}
            </div>
            <div className={classes.headerAdditional}>
              <div className={classes.timeAgo}>
                {
                  // @ts-ignore
                  dayjs.utc(comment.date_created).fromNow(true)
                }
              </div>
              {state.id !== comment.user_id && (
                <IconButton onClick={reportHandler} className={classes.flagBtn}>
                  {
                    //@ts-ignore
                    <Flag className={classes.flagBtnIcon} color='inert' />
                  }
                </IconButton>
              )}
              {state.id === comment.user_id && <CommentManageButton onDelete={handleDelete} onEdit={handleEdit} />}
            </div>
          </div>

          <CommentText
            comment={comment}
            translate={!(noTranslate || doNotTranslate || i18n.language === comment.original_language)}
          />

          <div className={classes.actions}>
            <div className={classes.buttonsActions}>
              <IconButton onClick={replyHandle}>
                {
                  //@ts-ignore
                  <Reply color='inert' />
                }
              </IconButton>
              {!comment.do_not_translate && (
                <IconButton onClick={translateHandler}>
                  {
                    //@ts-ignore
                    <GTranslate color={!noTranslate ? 'inert' : 'primary'} />
                  }
                </IconButton>
              )}
            </div>
            <div className={classes.reactions}>
              <div className={clsx(classes.reaction, [likeState === 'liked' && classes.hasUserReact])}>
                <ThumbUpAlt className={classes.icon} onClick={onLike} />
                {likes}
              </div>
              <div className={clsx(classes.reaction, [likeState === 'disliked' && classes.hasUserReact])}>
                <ThumbDownAlt className={classes.icon} onClick={onDislike} />
                {dislikes}
              </div>
            </div>
          </div>
        </div>
      </div>
      {repliedComments.length !== 0 && (
        <div className={!comment?.replies_to_id ? classes.replyCommentsWrapper : classes.none}>
          <CommentsList comments={repliedComments} carId={carId} storyId={storyId} authorized={authorized} />
        </div>
      )}
      {comment.first_reply && (
        <div className={classes.firstReply}>
          {!threadPages && (
            <CommentItem carId={carId} storyId={storyId} comment={comment.first_reply} authorized={authorized} />
          )}
          {threadPages?.pages[0].thread_comments?.length !== 0 &&
            threadPages?.pages.map((page, i) => {
              return (
                <CommentsList
                  key={i}
                  carId={carId}
                  storyId={storyId}
                  comments={page.thread_comments}
                  authorized={authorized}
                />
              );
            })}
          {(isFetchingNextPageThread || isLoadingThread) && <SpinFeedLoaderMini loading={true} />}
          {commentsInThread !== undefined && commentsInThread > 1 && !isFetchingNextPageThread && (
            <Button color='inert' onClick={handleLoadThreads}>
              {t('comment_item_see_more')} ({commentsInThread - 1})
            </Button>
          )}
        </div>
      )}

      <ReportDialog open={openReport} onClose={() => setOpenReport(false)} onSubmit={onSubmitReport} />
      <UnregisteredPopup
        open={openUnregistered}
        from={pathname}
        onClose={() => {
          setOpenUnregistered(false);
        }}
      />
    </div>
  );
};

const stylesText = ({ breakpoints, spacing, palette }: Theme) => ({
  text: {
    wordBreak: 'break-word',
  },
  mention: {
    color: palette.primary.main,
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontWeight: 700,
    cursor: 'pointer',
  },
});
const CommentText: ComponentType<{ comment: IComment; translate: boolean }> = ({ comment, translate }) => {
  const navigate = useNavigate();
  const classes = useClasses(stylesText);
  const [originalText, setOriginalText] = useState(comment.text);
  const [translatedText, setTranslatedText] = useState(comment.translated_text);

  const splitStringWithMentions = (input: string): string[] => {
    const regex = /(@\w+)/g;
    const substrings = input.split(regex);
    return substrings;
  };

  const [originalParts, setOriginalParts] = useState(splitStringWithMentions(originalText));
  const [translatedParts, setTranslatedParts] = useState(splitStringWithMentions(translatedText || ''));

  const getUserIdByUsername = (username: string) => {
    return comment.mentions[username];
  };

  const handleUserClick = (username: string) => {
    const userId = getUserIdByUsername(username.slice(1));
    navigate(`/user/${userId}`);
  };

  useEffect(() => {
    const editedComment = (event: any) => {
      const editResponse = event.detail;
      if (editResponse.id === comment.id) {
        setOriginalText(editResponse.text);
        setTranslatedText(editResponse.translated_text);
        setOriginalParts(splitStringWithMentions(editResponse.text));
        setTranslatedParts(splitStringWithMentions(editResponse.translated_text || ''));
      }
    };
    window.addEventListener('editedComment', editedComment);
    return () => {
      window.removeEventListener('editedComment', editedComment);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(translate ? translatedParts : originalParts).map((part: string) => {
        if (part.startsWith('@')) {
          return (
            <span
              className={classes.mention}
              key={part}
              onClick={() => {
                handleUserClick(part);
              }}
            >
              {part}
            </span>
          );
        } else {
          return <span key={part} className={classes.text} dangerouslySetInnerHTML={{ __html: part }} />;
        }
      })}
    </>
  );
};
