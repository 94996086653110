import { useMutation } from 'react-query';

import { axiosApiInstance as api, mapKeysToSnakeCase } from 'utils';

export interface SetDemoTourPayload {
  tourSkipped?: boolean;
  tourFinished?: boolean;
}

const SetDemoTour = async (payload: SetDemoTourPayload): Promise<any> => {
  const { data } = await api.put('/user/tour', mapKeysToSnakeCase(payload));
  return data;
};

export const useSetDemoTourMutation = () => {
  return useMutation(SetDemoTour);
};
