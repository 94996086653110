import { useMutation } from 'react-query';

import { axiosApiInstance as api, mapKeysToCamelCase } from 'utils';

export type GoogleConnectResponse = {
  message: string;
  error?: string;
};

type GoogleConnectRequest = {
  email: string;
  google_id: string;
  language?: string;
  access_token: string;
};

const connectGoogle = async (body: GoogleConnectRequest): Promise<Partial<GoogleConnectResponse>> => {
  try {
    const { data } = await api.post('/users/google-connect', body);
    return mapKeysToCamelCase({ ...data, google_access_token: body.access_token });
  } catch (error: any) {
    return { error: error.response.data.message };
  }
};

export const useGoogleConnectMutation = () => {
  return useMutation(connectGoogle);
};
