import React, { ComponentType, useEffect } from 'react';
import { FormProvider, useForm, ValidationMode } from 'react-hook-form';
import type Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

interface FormProps {
  defaultValues?: any;
  schema: Yup.AnyObjectSchema;
  onSubmit?: (data: any) => void;
  shouldReset?: boolean;
  mode?: keyof ValidationMode | undefined;
  resetValue?: any;
  onChange?: any;
  recordForm?: boolean | undefined;
}

export const Form: ComponentType<FormProps> = ({
  defaultValues,
  schema,
  onSubmit,
  shouldReset = false,
  mode = 'onSubmit',
  children,
  resetValue,
  onChange,
  recordForm
}) => {
  const methods = useForm({ defaultValues, resolver: yupResolver(schema), mode });
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (shouldReset) {
      reset(defaultValues);
    }
  }, [defaultValues, shouldReset]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (resetValue) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValue]);

  const onChangeForm = (data: any) => {
    if (onChange) {
      onChange(data);
    }
  };

  useEffect(() => {
    if (recordForm) {
    for (const [key, value] of Object.entries(defaultValues)) {
        methods.setValue(key, value, {
            shouldValidate: false,
            shouldDirty: true
        })
    }
  }
}, [defaultValues, methods, recordForm])


  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit ? handleSubmit(onSubmit) : () => false} onChange={onChangeForm}>
        {children}
      </form>
    </FormProvider>
  );
};
