import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/slider"
import { PauseCircle, PlayCircle, SlowMotionVideo } from '@mui/icons-material';
import { Box, Theme, Typography } from '@mui/material';
import { UnregisteredPopup } from 'components/UnregisteredPopup/UnregisteredPopup';
import { UpgradeDialog } from 'components/UpgrageDialog/UpgradeDialog';
import { ComponentType, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useStoryAudioLimitsQuery, useStoryAudioQuery } from 'services';
import { ProfileContext } from 'store/profile/store';
import { getTKey, isLoggedIn } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
const tKey = getTKey('story_listen');
const styles = ({ spacing, breakpoints, palette }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    padding: spacing(1, 2),
    borderRadius: 40,
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    marginBottom: spacing(3),
  },
  skeletonBox: {
    display: 'flex',
    height: 20,
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    '& > div': {
      height: 20,
      backgroundSize: '200% 100%',
      margin: spacing(0, 6, 0, 0),
      flexGrow: 1,
      borderRadius: 10,
      backgroundImage: `linear-gradient(
        to right,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.1) 100%
      )`,
      animation: '2s shimmer linear infinite',
      '@keyframes shimmer': {
        '100%': {
          backgroundPositionX: '-200%',
        },
      },
    },
  },
  controlContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  playerState: {
    padding: spacing(0, 2),
    flexGrow: 1,
  },
  icon: {
    width: 20,
    height: 20,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  upgrade: {
    color: '#FFCB40',
    fontWeight: 700,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  sliderTrack: { 
    background: 'rgba(255, 255, 255, 0.57)', 
    height: 2 
  },
  sliderThumb: {
    background: 'white',
    width: 8,
    height: 8,
    borderRadius: 4,
    top: 0,
    outline: 0,
  },
});

interface PlayerStoryProps {
  carId: string;
  storyId: string;
}

export const PlayerStory: ComponentType<PlayerStoryProps> = ({ carId, storyId }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isAuth = isLoggedIn();
  const { data: limits } = useStoryAudioLimitsQuery({ enabled: isAuth });
  const { data: audioData, refetch } = useStoryAudioQuery(carId, storyId, { enabled: isAuth });
  const audioPlayer = useRef<HTMLAudioElement | any>();
  const [openUnregistered, setOpenUnregistered] = useState<boolean>(false);
  const [state, setState] = useState(0);
  //0 - loading
  //1 - player available
  //2 - playing
  //3 - not available
  //4 - limit
  //5 - short text

  const [statePlayer, setStatePlayer] = useState(0);
  const [duration, setDuration] = useState('');
  const [currentTime, setCurrentTime] = useState('00:00');

  const [position, setPosition] = useState(0);

  const { state: profileState } = useContext(ProfileContext);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [countRefresh, setCountRefresh] = useState(0); //max 10
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  let timer: any = useRef();
  useEffect(() => {
    if (audioData === 'Error') {
      clearInterval(timer.current);
      setState(3);
    }
    if (audioData !== null) {
      if (timer.current) {
        setTimeout(() => {
          setState(1);
          setDuration(formatTime(Math.round(audioPlayer.current?.duration)));
        }, 1000);

        clearInterval(timer.current);
      }
    }
    if (!isAuth) {
      setState(1);
    }
    if (limits === 'limit_not_reached' && audioData !== null && duration !== '') {
      setState(1);
      setDuration(formatTime(Math.round(audioPlayer.current?.duration)));
    }
    if (audioData === null) {
      refetchAudio();
    }
    if (limits === 'limit_exceeded') {
      setState(4);
    }
    if (audioData === 'short_text') {
      setState(5);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limits, audioData, audioPlayer, duration, isAuth]);

  const refetchAudio = () => {
    setState(3);
    if (!timer.current) {
      timer.current = setInterval(() => {
        refetch();
        setCountRefresh((count) => {
          if (count > 7) {
            clearInterval(timer.current);
          }
          return count + 1;
        });
      }, 5000);
    }
  };

  useEffect(() => {
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  const handlePlayerPlay = () => {
    if (!isAuth) {
      setOpenUnregistered(true);
      return;
    }
    setState(2);
    setStatePlayer(1);
    setIsAudioPlaying(true);
    audioPlayer.current?.play();
  };
  const handlePlayerStop = () => {
    setStatePlayer(0);
    audioPlayer.current.pause();
  };

  const onPlaying = () => {
    setCurrentTime(formatTime(Math.round(audioPlayer.current.currentTime)));
    setDuration(formatTime(Math.round(audioPlayer.current?.duration)));
    setPosition(audioPlayer.current.currentTime);
    if (audioPlayer.current.paused) {
      setStatePlayer(0);
      setPosition(Math.round(audioPlayer.current.currentTime));
    }
  };

  const formatTime = (time: any) => {
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    return formattedTime;
  };

  const handleChangePositionSlider = (value: number) => {
    if (audioPlayer.current && value !== null && value !== undefined && value > -1) {
      audioPlayer.current.currentTime = value;
      setPosition(value as number);
      setCurrentTime(formatTime(Math.round(value)));
    }
  };

  return (
    <>
      <Box className={classes.root}>
        {state === 0 && (
          <Box className={classes.skeletonBox}>
            <Box></Box>
          </Box>
        )}
        {(state === 1 || state === 2) && (
          <Box className={classes.content}>
            {statePlayer === 0 ? (
              <PlayCircle className={classes.icon} sx={{ cursor: 'pointer' }} onClick={handlePlayerPlay} />
            ) : (
              <PauseCircle className={classes.icon} sx={{ cursor: 'pointer' }} onClick={handlePlayerStop} />
            )}
            <Box className={classes.playerState}>
              {state === 1 && <Typography variant='body2'>{t(tKey('play'))}</Typography>}
              {state === 2 && (

                <Slider
                  id='slider'
                  aria-label='time-indicator'
                  min={0}
                  value={position}
                  max={Math.round(audioPlayer.current?.duration || 0)}
                  colorScheme='teal'
                  onChange={handleChangePositionSlider}
                  isDisabled={isAudioPlaying}
                >
                  <SliderTrack sx={{ background: 'rgba(255, 255, 255, 0.57)', height: 2 }}>
                    <SliderFilledTrack sx={{ height: 2, background: '#ffffff' }} />
                  </SliderTrack>
                  <SliderThumb className={classes.sliderThumb} />
                </Slider>
              )}
            </Box>
            {isAuth && currentTime && duration && (
              <Typography variant='body2' sx={{ fontFamily: 'monospace' }}>
                {currentTime + ' / '}
                {duration}
              </Typography>
            )}
          </Box>
        )}
        {state === 3 && (
          <Box className={classes.content}>
            <SlowMotionVideo sx={{ color: 'rgba(255, 255, 255, 0.38)' }} className={classes.icon} />
            <Typography variant='body2' className={classes.playerState}>
              {t(tKey('not_available'))}
            </Typography>
          </Box>
        )}
        {state === 4 && (
          <Box className={classes.content}>
            <Typography variant='body2' className={classes.playerState}>
              {t(tKey('limits_reached'))}
            </Typography>
            {!profileState.is_patron ? (
              <Typography className={classes.upgrade} onClick={() => setHelpDialogOpen(true)}>
                {t(tKey('upgrade'))}
              </Typography>
            ) : (
              <>🙌 🫂</>
            )}
          </Box>
        )}
        {state === 5 && (
          <Box className={classes.content}>
            <SlowMotionVideo sx={{ color: 'rgba(255, 255, 255, 0.38)' }} className={classes.icon} />
            <Typography variant='body2' className={classes.playerState}>
              {t(tKey('short_text'))}
            </Typography>
          </Box>
        )}
      </Box>
      {audioData && (
        <audio
          ref={audioPlayer}
          src={audioData}
          onTimeUpdate={onPlaying}
          onDurationChange={() => {
            setDuration(formatTime(Math.round(audioPlayer.current?.duration)));
          }}
        ></audio>
      )}
      <UpgradeDialog open={helpDialogOpen} onClose={() => setHelpDialogOpen(false)} />
      {!isAuth && (
        <UnregisteredPopup
          open={openUnregistered}
          from={pathname}
          onClose={() => {
            setOpenUnregistered(false);
          }}
        />
      )}
    </>
  );
};
