import { useQuery, UseQueryResult } from 'react-query';
import { PublicCar } from 'types';

import { axiosApiInstance as api } from 'utils';

const getPublicUserVehicles = async (id: string): Promise<PublicCar[] | { error?: boolean }> => {
  try {
    const { data } = await api.get(`users/${id}/user-profile/vehicles`);
    return data;
  } catch (err) {
    return { error: true };
  }
};

export const usePublicUserVehicles = (id: string): UseQueryResult<PublicCar[]> => {
  return useQuery(['publicUseVehicles', id], () => getPublicUserVehicles(id), {
    cacheTime: 0,
  });
};
