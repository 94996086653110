import { Button, Dialog, DialogContent, DialogTitle, Grid, Theme } from '@mui/material';
import { Form } from 'components/Form/Form';
import { TextInput } from 'components/Form/TextInput';
import { TFunction } from 'i18next';
import { ComponentType, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useProfileInfoMutation } from 'services';
import { getTKey, yupStringValidator } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import * as yup from 'yup';
const tKey = getTKey('update_email_dialog');

const styles = ({ breakpoints, palette, spacing }: Theme) => ({
  dialogContent:{
    padding: spacing(1)
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    marginBottom: spacing(1),
    padding: spacing(1, 2, 0),

    '& .MuiButton-root': {
      marginLeft: 'auto',
      letterSpacing: 1.25,
      whiteSpace: 'nowrap',
    },

    [breakpoints.up('md')]: {
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: spacing(1, 2),
    },
    [breakpoints.down('sm')]: {
      padding: spacing(1, 0),

    }
  },
  title: {
    fontSize: 20,
    color: palette.text.primary,
    marginBottom: spacing(1),
    [breakpoints.up('md')]: {
      width: 'auto',
      paddingRight: spacing(1),
    },
    [breakpoints.down('sm')]: {
      fontSize: 16,
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    marginLeft: 'auto',
  }
});
interface UpdateEmailDialogType {
    email: string; 
    open: boolean;
    onClose: () => void;
}

export const UpdateEmailDialog: ComponentType<UpdateEmailDialogType> = (
{ 
  email,
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const mutation = useProfileInfoMutation();

  const updateEmail = (submit: {email: string}) => {
    mutation.mutate({email: submit.email}, {
      onSuccess(){
        toast(t(tKey('toast')))
      },
      onError(){
        toast(t(tKey('toast_fail')))
      },
      onSettled(){
        onClose();
      }
    })
  }

  const validationSchema = useMemo(() => {
    return (t: TFunction): yup.AnyObjectSchema =>
    yup.object().shape({
        email: yupStringValidator(t).email(t('__validation__email')),
    });
  },[]);

  return (
    <Dialog
    open={open}
    onClose={onClose}
    maxWidth='md'
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
    
    >
    <DialogTitle id='alert-dialog-title'>{t(tKey('title'))}</DialogTitle>
    <DialogContent className={classes.dialogContent}>
        <div className={classes.root}>
          <Form 
            schema={validationSchema(t)} 
            defaultValues={{email: email || ''}} 
            onSubmit={updateEmail}
          >
            <Grid className={classes.form}>
              <TextInput
                customHelperText={t(tKey('email_hint'))}
                name='email'
                label={t(tKey('email'))}
                inputProps={{
                  autoCapitalize: 'none',
                  
                }}
              />
              <div className={classes.actions}>
                <Button onClick={onClose} color='primary'>
                  {t(tKey('cancel'))}
                </Button>
                <Button type='submit' color='primary'>
                  {t(tKey('update'))}
                </Button>
                
             </div>
              
            </Grid>
          </Form>
        </div>
    </DialogContent>
    
    </Dialog>
      
  );
};
