import dayjs from 'dayjs';
import { isArray, isEmpty, uniq } from 'lodash';
import { ImageListType } from 'react-images-uploading';
import { Maybe } from 'yup/lib/types';

import type { BoardFilters } from 'features/dashboard/components/DashboardFilters';
import { Record } from 'types';

import { Filters } from 'components/Stories/useCarRecords';
import { Stats } from 'features/dashboard/components/DashboardStatItem';
import { compareFiles } from './files';
import { roundNumber } from './formatters';
import { IRecord } from 'features/stories-feed/StoriesTypes';

export const countTotalMoneySpend = (records: Record[] = []) => {
  const result = records.reduce((acc, record) => {
    acc += Number(record.cost);
    return acc;
  }, 0);

  return roundNumber(result);
};

export const countTotalMoneySpendI = (records: IRecord[] = []) => {
  const result = records.reduce((acc, record) => {
    acc += Number(record.cost);
    return acc;
  }, 0);

  return roundNumber(result);
};

export const getAllYears = (records: Record[] = []) => {
  const currentYear = dayjs().year();
  const years = records.map((record) => {
    const date = dayjs(record.datePerformed);
    return date.isValid() ? date.year() : NaN;
  }).filter(year => !isNaN(year)); // Filter out any NaN values

  if (!years.includes(currentYear)) {
    years.push(currentYear);
  }
  return uniq(years);
};

export const getAllMonths = (records: Record[] = []) => {
  const currentMonth = dayjs().month();
  const months = records.map((record) => dayjs(record.datePerformed).month());

  if (!months.includes(currentMonth)) {
    months.push(currentMonth);
  }

  return uniq(months);
};

export const getAllCategories = (records: Record[] = []) => {
  const categories = records.map((record) => record.category);
  return uniq(categories);
};

export const filterByCategory = (records: Record[], category: string) => {
  return records.filter((record) => {
    if (isArray(record)) {
      const inner = record.filter((item) => {
        return item.category === category;
      });

      return !isEmpty(inner);
    } else {
      return record.category === category;
    }
  });
};

export const filterByYear = (records: Record[], year: number) => {
  return records.filter((record) => {
    if (isArray(record)) {
      const inner = record.filter((item) => {
        return dayjs(item.datePerformed).year() === year;
      });

      return !isEmpty(inner);
    } else {
      return dayjs(record.datePerformed).year() === year;
    }
  });
};

export const filterByMonth = (records: Record[], month: number) => {
  return records.filter((record) => {
    if (isArray(record)) {
      const inner = record.filter((item) => {
        return dayjs(item.datePerformed).month() === month;
      });

      return !isEmpty(inner);
    } else {
      return dayjs(record.datePerformed).month() === month;
    }
  });
};

export const filterByType = (records: Record[], type: string) => {
  return records.filter((record) => {
    return (type === 'local' && !isArray(record)) || (type === 'assisted' && isArray(record));
  });
};
export const getFilteredRecordsByCar = (
  filters: BoardFilters,
  records: Record[] = []
): { filteredRecords: Record[]; filteredRecordsForStats: Record[] } => {
  const { category, year } = filters;
  let filteredRecords = [...records];
  let filteredRecordsForStats = [...records];

  if (category !== 'all') {
    filteredRecords = filterByCategory(filteredRecords, category);
  }
  if (year) {
    filteredRecords = filterByYear(filteredRecords, year);
    filteredRecordsForStats = filterByYear(filteredRecordsForStats, year);
  }

  return { filteredRecords, filteredRecordsForStats };
};

export const getFilteredRecordsForStories = (
  filters: Filters,
  records: Record[] = []
): { filteredRecords: Record[] } => {
  const { category, year, month, type } = filters;
  let filteredRecords = [...records];

  if (category !== 'all') {
    filteredRecords = filterByCategory(filteredRecords, category);
  }
  if (year) {
    filteredRecords = filterByYear(filteredRecords, year);
  }

  if (month !== 'all') {
    filteredRecords = filterByMonth(filteredRecords, Number(month));
  }

  if (type !== 'all') {
    filteredRecords = filterByType(filteredRecords, type);
  }

  return { filteredRecords };
};

export const aggregateExpensesByMonth = (records: Record[]): number[] => {
  // TODO: end refactoring
  return records.reduce((acc: number[], { datePerformed, cost }) => {
    const numberCost = Number(cost);
    const month = dayjs(datePerformed).month();
    const prev = acc[month];

    acc[month] = prev ? prev + numberCost : numberCost;
    return acc;
  }, []);
};

export const aggregateMileageByMonth = (records: Record[]): any => {
  // TODO: end refactoring
  return records.reduce((acc: any, { datePerformed, mileage }) => {
    const numberMileage = Number(mileage);
    const month = dayjs(datePerformed).month();
    const prev = acc[month];

    if (prev) {
      acc[month].push(numberMileage);
    } else {
      acc[month] = [numberMileage];
    }

    return acc;
  }, []);
};

export const calculateMileageByMonth = (data: any): any => {
  let tmp = [];

  for (let i = 0; i < 12; i++) {
    if (data[i] === undefined) {
      tmp.push({ name: i, value: 50.01 });
    } else {
      if (data[i].length === 1) {
        tmp.push({ name: i, value: data[i] });
      }
      if (data[i].length > 1) {
        tmp.push({ name: i, value: data[i] });
      }
    }
  }

  return tmp;
};

export const getTotalExpenses = (records: Record[] | undefined): number => {
  if (records) {
    return records.reduce((acc: number, record) => {
      acc += Number(record.cost);
      return acc;
    }, 0);
  }

  return 0;
};

export const getTotalMileage = (records: Record[]): number => {
  return records.reduce((acc: number, record) => {
    acc += Number(record.mileage);
    return acc;
  }, 0);
};

export const calcAverageMonthlyExpenses = (records: Record[], year: number): number => {
  const months = year === dayjs().year() ? dayjs().month() + 1 : 12;
  const result = getTotalExpenses(records) / months;
  return Math.round(result);
};

export const calcAverageMonthlyMileage = (records: Record[]): number => {
  const result = getTotalMileage(records) / 12;
  return Math.round(result);
};

export const buildRecordFormImages = async (
  fd: FormData,
  images: ImageListType,
  defaultValues: Maybe<Record>
): Promise<void> => {
  // if (images?.length > 0) {
  if (images[0]?.file) {
    const file = await compareFiles(defaultValues?.imageOne as string, images[0].file);
    // @ts-ignore
    fd.append('image_one', file, images[0].file.name);
  } else {
    fd.append('image_one', '');
  }
  if (images[1]?.file) {
    const file = await compareFiles(defaultValues?.imageTwo as string, images[1].file);
    // @ts-ignore
    fd.append('image_two', file, images[1].file.name);
  } else {
    fd.append('image_two', '');
  }
  if (images[2]?.file) {
    const file = await compareFiles(defaultValues?.imageThree as string, images[2].file);
    // @ts-ignore
    fd.append('image_three', file, images[2].file.name);
  } else {
    fd.append('image_three', '');
  }
  if (images[3]?.file) {
    const file = await compareFiles(defaultValues?.imageThree as string, images[3].file);
    // @ts-ignore
    fd.append('image_four', file, images[2].file.name);
  } else {
    fd.append('image_four', '');
  }
  if (images[4]?.file) {
    const file = await compareFiles(defaultValues?.imageThree as string, images[4].file);
    // @ts-ignore
    fd.append('image_five', file, images[2].file.name);
  } else {
    fd.append('image_five', '');
  }
  // }
};

export const aggregateSpendingByCategory = (records: Record[]): any => {
  type data = {
    [category: string]: Stats;
  };
  const spending: data = records.reduce((acc: data, { category, cost }) => {
    const numberCost = Number(cost);
    const prev = acc[category];

    if (prev) {
      const prevCount = Number(prev.count);
      const totalSpending = numberCost + prevCount;
      acc[category] = { ...acc[category], count: totalSpending };
    } else {
      acc[category] = { title: category, count: numberCost };
    }

    return acc;
  }, {} as data);

  return Object.values(spending)
    .sort((a, b) => Number(b.count) - Number(a.count))
    .slice(0, 3);
};

export const aggregateSpendingByFrequency = (records: Record[]): any => {
  type data = {
    [category: string]: Stats;
  };
  const spending: data = records.reduce((acc: data, { category }) => {
    const prev = acc[category];

    if (prev) {
      const prevCount = Number(prev.count);
      const totalNotes = 1 + prevCount;
      acc[category] = { ...acc[category], count: totalNotes };
    } else {
      acc[category] = { title: category, count: 1 };
    }

    return acc;
  }, {} as data);

  return Object.values(spending)
    .sort((a, b) => Number(b.count) - Number(a.count))
    .slice(0, 3);
};

enum imagesEnum {
  image_one = 0,
  image_two = 1,
  image_three = 2,
  image_four = 3,
  image_five = 4,
}

export const createNestedFormData = (formDataArray: any) => {
  const newFormData = new FormData();
  formDataArray.forEach((item: any, index: number) => {
    const entries =
      typeof item.entries === 'function' && item.entries() ? Array.from(item.entries()) : Object.entries(item);
    //@ts-ignore
    entries.forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((nestedValue, nestedIndex) => {
          const nestedKey = `${key}_${index}_${nestedIndex}`;
          newFormData.append(nestedKey, nestedValue);
        });
      } else {
        const nestedKey = `${key}_${index}`;
        if (['image_one', 'image_two', 'image_three', 'image_four', 'image_five'].includes(key)) {
          newFormData.append(`file_${index}_${imagesEnum[key]}`, value);
        } else {
          newFormData.append(nestedKey, value);
        }
      }
    });
  });

  return newFormData;
};
