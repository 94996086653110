import { SquareRounded } from '@mui/icons-material';
import { Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ spacing, breakpoints, palette }: Theme) => ({
  root: {
    position: 'fixed',
    bottom: 100,
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#252525',
    padding: spacing(1),
    borderRadius: spacing(1),
    border: '#FFFFFF26 0.5px solid',
    textWrap: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    zIndex: 1000,
  },
  stopButtonInner: {
    color: 'white',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  stopButtonIcon: {
    fontSize: 26.5,
    color: '#FF5050',
    marginRight: spacing(1),
  },
});

interface AssistantStopGenerateButtonProps {
  isStreaming: boolean;
  handleClick: () => void;
}

export const AssistantStopGenerateButton: ComponentType<AssistantStopGenerateButtonProps> = ({
  isStreaming,
  handleClick,
}) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();

  return (
    <>
      {isStreaming && (
        <div className={classes.root}>
          <button onClick={handleClick} className={classes.stopButtonInner}>
            <SquareRounded className={classes.stopButtonIcon} />
            {t('assistant_button_stop_generating')}
          </button>
        </div>
      )}
    </>
  );
};
