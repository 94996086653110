import { createSlice } from '@reduxjs/toolkit';

export type State = {
  id: string;
  username: string;
  email: string;
  email_confirmed: boolean;
  date_created: string;
  language: string;
  role: string;
  is_patron?: boolean;
  alertActive: boolean;
  alertPulse: boolean;
  remindersActive: string[];
  reminderPulse: boolean;
};

export const initialState: State = {
  id: '',
  username: '',
  email: '',
  email_confirmed: false,
  date_created: '',
  language: '',
  role: '',
  alertActive: false,
  alertPulse: false,
  remindersActive: [],
  reminderPulse: false,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile(state, action) {
      return { ...state, ...action.payload };
    },
    setLanguage(state, action) {
      return { ...state, language: action.payload };
    },
    setAlertActive(state, action) {
      return { ...state, alertActive: action.payload };
    },
    setAlertPulse(state, action) {
      return { ...state, alertPulse: action.payload };
    },
    setRemindersActive(state, action) {
      return { ...state, remindersActive: action.payload };
    },
    setReminderPulse(state, action) {
      return { ...state, reminderPulse: action.payload };
    },
  },
});

export const { setProfile, setLanguage, setAlertActive, setAlertPulse, setRemindersActive, setReminderPulse } =
  profileSlice.actions;

export default profileSlice.reducer;
