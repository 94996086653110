import { isArray } from 'lodash';
import { useQuery } from 'react-query';
import { createCarUI } from 'services/cars/utils';
import { Record } from 'types';

import { axiosApiInstance as api } from 'utils';
import { createRecordUI } from '../../records/utils';

export const getAllPublicRecords = async (identifier: string) => {
  try {
    const { data } = await api.get('book/public/dashboard', { params: { identifier } });
    const arrayOfRecords: Record[] = [];

    data.records.forEach((item: any) => {
      if (isArray(item)) {
        const mapped = item.map(createRecordUI) as Record[];
        arrayOfRecords.push(mapped as any);
      } else {
        arrayOfRecords.push(createRecordUI(item));
      }
    });

    return { car: createCarUI(data?.car), records: arrayOfRecords };
  } catch (err: any) {
   
  }
};

export const usePublicRecordsQuery = (identifier: string) => {
  return useQuery('publicRecords', () => getAllPublicRecords(identifier));
};
