import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import packageJson from '../package.json';

const buildDateGreaterThan = (latestDate: number, currentDate: number) => {
  const momLatestDateTime = dayjs(latestDate);
  const momCurrentDateTime = dayjs(currentDate);

  return momLatestDateTime.isAfter(momCurrentDateTime);
};

function forceSWupdate() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.update();
      }
    });
  }
}

function withClearCache(Component: any) {
  function ClearCacheComponent(props: any) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    useEffect(() => {
      fetch('/meta.json')
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate;

          const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate);

          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        });
    }, []);

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }

      forceSWupdate();
      // delete browser cache and hard reload
      window.location.reload();
    };

    return <>{isLatestBuildDate ? <Component {...props} /> : ''}</>;
  }

  return ClearCacheComponent;
}

export default withClearCache;
