import { useMutation } from 'react-query';

import { axiosApiInstance } from 'utils';

export type PublishStoryDraftPayload = { carId: string; elements: any };

const publishStoryDraft = async ({ carId, elements }: PublishStoryDraftPayload): Promise<any> => {
  const { data } = await axiosApiInstance.post(`cars/${carId}/stories`, elements);
  return data;
};

export const usePublishStoryDraft = () => {
  return useMutation(publishStoryDraft);
};
