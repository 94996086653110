import { Divider, FormControl, FormControlLabel, FormControlLabelProps, Radio, RadioGroup } from '@mui/material';
import { ComponentType } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useClasses } from 'utils/hooks/useClasses';

const styles = () => ({
  divider: {
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
  },
});
interface FormRadioProps {
  name: string;
  options: Record<any, any>[];
  defaultValue?: string;
  optionsWithTranslations?: boolean;
  formControlLabelProps?: Partial<FormControlLabelProps>;
  controlClass?: any;
}
export const FormRadio: ComponentType<FormRadioProps> = ({
  name,
  options,
  defaultValue,
  optionsWithTranslations,
  formControlLabelProps,
  controlClass,
}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const classes = useClasses(styles);
  return (
    <FormControl component='fieldset' className={controlClass}>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({ field }) => (
          <RadioGroup {...field}>
            {options.map((option) => (
              <span key={option.id}>
                <FormControlLabel
                  {...formControlLabelProps}
                  key={option.id}
                  value={option.value}
                  control={<Radio color='primary' />}
                  label={optionsWithTranslations ? t(option.label) : option.label}
                />

                <Divider className={classes.divider} />
              </span>
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};
