import { FilterList } from '@mui/icons-material';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { isNumber } from 'lodash';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AdaptiveSelect, InnerMobileHeader, StyledButton } from 'components';
import { Record, RecordsFiltersInterface } from 'types';
import { getAllCategories, getAllYears, getTKey, months } from 'utils';

import clsx from 'clsx';
import { useClasses } from 'utils/hooks/useClasses';
import { FilterChip } from './FilterChip';
import { theme } from 'theme';

const tKey = getTKey('record_form');

interface RecordsFiltersProps {
  data: Record[];
  filters: RecordsFiltersInterface;
  filteredRecordsQuantity?: number;
  isFaded?: boolean;
  onFiltersChange: (filters: Partial<RecordsFiltersInterface>) => void;
}
const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    marginBottom: spacing(0.5),
  },
  isFaded: {
    position: 'relative',
    opacity: 0.4,

    '&::before': {
      position: 'absolute',
      zIndex: 5,
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      content: '""',
    },
  },
  formControl: {
    margin: spacing(1),
    minWidth: 120,
    '& .MuiSelect-select': {
      width: 'unset',
    },
  },
  mobileFiltersLine: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(2),
  },
  mobileFiltersWrapper: {
    position: 'fixed',
    zIndex: 5,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: spacing(2, 4, 2, 2),
    backgroundColor: '#121212',
    paddingTop: 'env(safe-area-inset-top)',
  },
  mobileChips: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    overflowX: 'auto',
    scrollbarWidth: 'none',

    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  quantityTitle: {
    margin: spacing(3, 0, 2),
    textAlign: 'center',
  },
  buttonWrapper: {
    textAlign: 'center',
  },
});

const FilterFields: ComponentType<RecordsFiltersProps> = ({ data, filters, onFiltersChange }) => {
 
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const allRecordsYears = getAllYears(data);
  const allRecordsCategories = getAllCategories(data);
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (key: keyof RecordsFiltersInterface) => (event: SelectChangeEvent) => {
    const value = event.target.value;
    onFiltersChange({ [key]: key === 'category' ? value : isNumber(value) ? Number(value) : value });
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth variant='outlined' size='small' className={classes.formControl}>
          <InputLabel htmlFor='year'>{t(tKey('year'))}</InputLabel>
          <AdaptiveSelect
            id='year'
            label={t(tKey('year'))}
            emptyValue={t(tKey('all_years'))}
            options={allRecordsYears}
            value={filters.year}
            onChange={handleChange('year')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth variant='outlined' size='small' className={classes.formControl}>
          <InputLabel htmlFor='month'>{t(tKey('month'))}</InputLabel>
          {mobile ? (
            <Select
              size='small'
              sx={{ display: { xs: 'block', md: 'none' } }}
              id='month'
              value={filters.month.toString()}
              onChange={handleChange('month')}
              label={t(tKey('month'))}
            >
              <MenuItem value='all'>
                <span>{t(tKey('all_months'))}</span>
              </MenuItem>
              {months.map((month, idx) => (
                <MenuItem key={month} value={idx}>
                  {t(`__month__${month}`)}
                </MenuItem>
                ))}
            </Select>
           ) : (
            <Select
              size='small'
              sx={{ display: { md: 'block', xs: 'none' } }}
              id='month'
              value={filters.month.toString()}
              renderValue={(value) => (value ? t(`__month__${months[parseInt(value)]}`) : '')}
              onChange={handleChange('month')}
              label={t(tKey('month'))}
            >
              <MenuItem value='all'>
                <span>{t(tKey('all_months'))}</span>
              </MenuItem>
              {months.map((month, idx) => (
                <MenuItem key={month} value={idx}>
                  {t(`__month__${month}`)}
                </MenuItem>
              ))}
            </Select>
            )}  
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth size='small' className={classes.formControl}>
          <InputLabel htmlFor='category'>{t(tKey('category'))}</InputLabel>
          <AdaptiveSelect
            id='category'
            label={t(tKey('category'))}
            emptyValue={t(tKey('all_categories'))}
            options={allRecordsCategories}
            value={filters.category}
            onChange={handleChange('category')}
            optionLabelTKey='__record_category__'
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export const RecordsFilters: ComponentType<RecordsFiltersProps> = ({
  data,
  filters,
  filteredRecordsQuantity,
  isFaded,
  onFiltersChange,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const [mobileFiltersShown, setMobileFiltersShown] = useState<boolean>(false);
  const flattenData = data.flat();

  const resetFilterHandler = (type: 'year' | 'month' | 'category'): void => {
    onFiltersChange({ [type]: 'all' });
  };

  return data && data.length ? (
    <div className={clsx({ [classes.isFaded]: isFaded })}>
      <Box component={'div'} sx={{ display: { xs: 'block', md: 'none' } }}>
        <div className={classes.mobileFiltersLine}>
          <IconButton color='inherit' onClick={() => setMobileFiltersShown(!mobileFiltersShown)} size='large'>
            <FilterList />
          </IconButton>
          <div className={classes.mobileChips}>
            {filters.year !== 'all' ? (
              <FilterChip type='years' label={filters.year} onReset={() => resetFilterHandler('year')} />
            ) : (
              <FilterChip type='years' label='all' />
            )}
            {filters.month !== 'all' ? (
              <FilterChip
                type='months'
                label={t(`__month__${months[filters.month as number]}`)}
                onReset={() => resetFilterHandler('month')}
              />
            ) : (
              <FilterChip type='months' label='all' />
            )}
            {filters.category !== 'all' ? (
              <FilterChip
                type='categories'
                label={t(`__record_category__${filters.category}`)}
                onReset={() => resetFilterHandler('category')}
              />
            ) : (
              <FilterChip type='categories' label='all' />
            )}
          </div>
        </div>
        {mobileFiltersShown && (
          <div className={classes.mobileFiltersWrapper}>
            <InnerMobileHeader title={t(tKey('filters'))} onLeftClick={() => setMobileFiltersShown(false)} />
            <FilterFields data={flattenData} filters={filters} onFiltersChange={onFiltersChange} />
            <div className={classes.quantityTitle}>
              {t(tKey('filtered_quantity'), { quantity: filteredRecordsQuantity })}
            </div>
            <div className={classes.buttonWrapper}>
              <StyledButton label={t(tKey('show_results'))} onClick={() => setMobileFiltersShown(false)} />
            </div>
          </div>
        )}
      </Box>

      <Box component={'div'} sx={{ display: { md: 'block', xs: 'none' } }}>
        <FilterFields data={flattenData} filters={filters} onFiltersChange={onFiltersChange} />
      </Box>
    </div>
  ) : null;
};
