import { useQuery } from 'react-query';

import { User } from 'types';
import { axiosApiInstance as api } from 'utils';

export const getProfileInfo = async (): Promise<User> => {
  const { data } = await api.get('/user-info');
  return data;
};

export const useProfileInfoQuery = () => {
  return useQuery('userInfo', getProfileInfo, { cacheTime: 0 });
};
