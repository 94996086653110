import { useQuery } from 'react-query';
import { axiosApiInstance as api } from 'utils';
import { getRemindersCarPayload } from './reminderPayloads';

export const getRemindersCar = async ({ carId }: getRemindersCarPayload) => {
  const { data } = await api.get(`cars/${carId}/reminders`);

  return data;
};

export const useRemindersCarQuery = ({ carId }: getRemindersCarPayload) => {
  return useQuery(['remindersCar', carId], () => getRemindersCar({ carId }), { cacheTime: 0 });
};
