export type RecordCategory = { value: string; label: string };

export const recordCategories: RecordCategory[] = [
  { value: 'refueling', label: '__record_category__refueling' },
  { value: 'recharging', label: '__record_category__recharging' },
  { value: 'wash', label: '__record_category__wash' },
  { value: 'maintenance', label: '__record_category__maintenance' },
  { value: 'interior', label: '__record_category__interior' },
  { value: 'AC/heating', label: '__record_category__heating' },
  { value: 'body', label: '__record_category__body' },
  { value: 'brake', label: '__record_category__brake' },
  { value: 'electrical', label: '__record_category__electrical' },
  { value: 'engine', label: '__record_category__engine' },
  { value: 'exhaust', label: '__record_category__exhaust' },
  { value: 'fuel', label: '__record_category__fuel' },
  { value: 'ignition', label: '__record_category__ignition' },
  { value: 'lighting', label: '__record_category__lighting' },
  { value: 'lpg', label: '__record_category__lpg' },
  { value: 'multimedia', label: '__record_category__multimedia' },
  { value: 'steering', label: '__record_category__steering' },
  { value: 'suspension', label: '__record_category__suspension' },
  { value: 'tires', label: '__record_category__tires' },
  { value: 'transmission', label: '__record_category__transmission' },
  { value: 'insurance', label: '__record_category__insurance' },
  { value: 'other', label: '__record_category__other' },
];
