import { Box, Theme, Typography } from '@mui/material';
import EmptyStory from 'assets/empty-stories.png';
import { StyledButton } from 'components';
import { ComponentType, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getTKey, isLoggedIn } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { UnregisteredPopup } from 'components/UnregisteredPopup/UnregisteredPopup';

const styles = ({ breakpoints, spacing, palette }: Theme) => ({
  image: {
    height: 146,
  },
  desc: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: spacing(0, 8),
    gap: 10,
  },
  title: {
    fontWeight: '600',
    fontSize: 24,

    textAlign: 'center',
  },
  subtitle: {
    fontWeight: '400',
    fontSize: 14,

    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: spacing(3),
  },
  button: {
    padding: spacing(1, 2),
  },
});

const tKey = getTKey('empty_story');

export const EmptyStories: ComponentType<{ onlyTitle?: boolean }> = ({ onlyTitle = false }) => {
  const classes = useClasses(styles);
  const [openUnregistered, setOpenUnregistered] = useState<boolean>(false);
  const isAuth = isLoggedIn();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCreateStory = () => {
    if (isAuth) {
      navigate('/stories/new');
    } else {
      setOpenUnregistered(true);
    }
  };

  return (
    <Box sx={{ marginTop: 8 }}>
      <Box className={classes.desc}>
        <img src={EmptyStory} alt='icon' className={classes.image} />
        <Typography className={classes.title}>{t(tKey('title'))}</Typography>
        {!onlyTitle && <Typography className={classes.subtitle}>{t(tKey('desc'))}</Typography>}
      </Box>
      {!onlyTitle && (
        <Box className={classes.buttonContainer}>
          <StyledButton
            className={classes.button}
            label={t(tKey('create_story'))}
            onClick={handleCreateStory}
          ></StyledButton>
        </Box>
      )}
      <UnregisteredPopup
        open={openUnregistered}
        from={'/stories/new'}
        onClose={() => {
          setOpenUnregistered(false);
        }}
      />
    </Box>
  );
};
