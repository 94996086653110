import { FetchNextPageOptions, InfiniteData, RefetchOptions } from 'react-query';
import { useStoryCommentsPopularQuery } from 'services';
import { CommentsResponse } from '../StoriesTypes';

type useStoriesDataType = (
  carId: string,
  storyId: string,
) => {
  popularComments?: InfiniteData<CommentsResponse>,
  hasNextPagePopularComments?: boolean,
  isFetchingNextPagePopularComments: boolean,
  isLoadingPopularComments: boolean,
  fetchNextPagePopularComments: (options?: FetchNextPageOptions)=>void,
  refetchPopularComments: (options?: RefetchOptions)=>void,
}

export const useCommentsPopular: useStoriesDataType = (carId, storyId) => {

  const {
    data: popularComments, 
    hasNextPage: hasNextPagePopularComments, 
    fetchNextPage: fetchNextPagePopularComments, 
    isFetchingNextPage: isFetchingNextPagePopularComments, 
    isLoading: isLoadingPopularComments, 
    refetch: refetchPopularComments
  } = useStoryCommentsPopularQuery({carId, storyId});


  return{
    popularComments,
    hasNextPagePopularComments,
    fetchNextPagePopularComments,
    isFetchingNextPagePopularComments,
    isLoadingPopularComments,
    refetchPopularComments,
  }
}