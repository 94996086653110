import { AxiosError } from 'axios';
import { UseMutationOptions } from 'react-query';

import { queryClient } from 'components';
import { Car } from 'types';

import { UpdateCarPayload } from './hooks/useUpdateCarMutation';

export const carMutationOptions: UseMutationOptions<Car, AxiosError, UpdateCarPayload> = {
  onSuccess: () => {
    queryClient.invalidateQueries('cars');
  },
};
