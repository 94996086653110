import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

const followUser = async ({id, follow}: {id: string, follow: boolean}): Promise<any> =>{
  try{
    const { data } = await api.post(`users/${id}/${follow ? 'follow': 'unfollow'}`, );
    return data;
  }
  catch (error){
    return { error: true };
  }
}

export const useFollowUserMutation = ()  => {
  return useMutation(followUser);
}
