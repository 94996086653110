import { Box, Grid, Theme } from '@mui/material';
import getOr from 'lodash/fp/getOr';
import { ComponentType, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import store from 'store2';

import { Form, ImageUploaderMultiple, PageLoader, StyledButton, TextInput } from 'components';
import { recordFormValidationSchema } from 'features/records/components/RecordDefaultForm';
import { useRecordFormImages } from 'features/records/hooks/useRecordFormImages';
import { useAssistedCarQuery } from 'services';
import { NewRecordValues, Record } from 'types';
import { buildRecordFormImages, getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { DatePickerSelect } from 'components/Form/DatePickerSelect';
import dayjs from 'dayjs';

const tKey = getTKey('record_form');

const styles = ({ spacing, breakpoints }: Theme) => ({
  column: {
    [breakpoints.up('md')]: {
      position: 'absolute',
      top: spacing(-2.2),
      right: 0,
    },
    marginTop: spacing(-2),
  },
});

const FormStateUpdater: ComponentType<{
  recordId: string;
  defaultValues: Record | undefined;
  localMileage: number;
}> = ({ recordId, defaultValues, localMileage }) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    if (defaultValues && defaultValues.id === recordId) {
      setValue('title', defaultValues.title);
      setValue('partName', defaultValues.partName);
      setValue('cost', defaultValues.cost);
      setValue('details', defaultValues.details);
      setValue('mileage', defaultValues.mileage);
    }
  }, [recordId, defaultValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!defaultValues && localMileage) {
      setValue('mileage', localMileage);
    }
  }, [defaultValues, localMileage]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

interface AssistedRecordDefaultFormProps {
  onSubmit: (values: any) => void;
  defaultValues?: Record;
  editMode?: boolean;
  isSubmitting?: boolean;
  category: string;
}

const createDefaultValues = (mileage: number, defaultCategory: string, values?: Record) => ({
  title: getOr('', 'title', values),
  partName: getOr('', 'partName', values),
  cost: getOr('', 'cost', values),
  details: getOr('', 'details', values),
  mileage: mileage ? mileage : values?.mileage ? values?.mileage : '',
  datePerformed:
    values?.datePerformed === 'None'
      ? null
      : values && dayjs(values.datePerformed).isValid()
      ? dayjs(values.datePerformed)
      : dayjs(),
});

export const AssistedRecordDefaultForm: ComponentType<AssistedRecordDefaultFormProps> = ({
  onSubmit,
  defaultValues,
  editMode,
  isSubmitting,
  category,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const localStorageMileage = store('driverbook_draftMileage');
  const { identifier, recordId } = useParams<{ identifier: string; recordId: string }>() as {
    identifier: string;
    recordId: string;
  };
  const [localMileage, setLocalMileage] = useState<number>(0);
  const { data: carData, isLoading: carDataLoading } = useAssistedCarQuery(identifier);
  const { images, onImageChange } = useRecordFormImages(defaultValues);

  useEffect(() => {
    if (!editMode && !carDataLoading && carData && localStorageMileage) {
      if (localStorageMileage.carId === carData.id) {
        setLocalMileage(localStorageMileage.mileage);
      } else {
        store.remove('driverbook_draftMileage');
      }
    }
  }, [carDataLoading, carData, localStorageMileage, editMode]);

  const handleSubmit = async (data: NewRecordValues) => {
    const fd = new FormData();

    await buildRecordFormImages(fd, images, defaultValues);

    fd.append('category', category);
    fd.append('cost', data.cost);
    fd.append('details', data.details);
    fd.append('mileage', data.mileage.toString());
    fd.append('part_name', data.partName);
    fd.append('title', data.title);
    fd.append('date_performed', dayjs(data.datePerformed).format('YYYY-MM-DD HH:mm:ss'));
    fd.append('identifier', identifier);

    if (!localStorageMileage) {
      store('driverbook_draftMileage', { carId: carData.id, mileage: data.mileage });
    }

    onSubmit(fd);
  };

  return carDataLoading ? (
    <PageLoader />
  ) : (
    <Form
      onSubmit={handleSubmit}
      schema={recordFormValidationSchema(t)}
      defaultValues={createDefaultValues(localMileage, 'refueling', defaultValues)}
      mode='onChange'
    >
      <FormStateUpdater recordId={recordId} defaultValues={defaultValues} localMileage={localMileage} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput name='title' label={t(tKey('title'))} />
          <TextInput name='details' label={t(tKey('details'))} textarea />
          <TextInput name='mileage' label={`${t(tKey('current_mileage'))}, ${carData?.mileageMetric}`} />
          <DatePickerSelect
            name='datePerformed'
            label={`${t(tKey('date_performed'))}`}
            datePickerProps={{ value: dayjs() }}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.column}>
          <TextInput name='partName' label={t(tKey('item_name'))} />
          <TextInput name='cost' label={`${t(tKey('price'))}, ${carData.currency}`} />
          <ImageUploaderMultiple
            images={images}
            onChange={onImageChange}
            editMode={editMode}
            data={defaultValues as Record}
          />
        </Grid>
      </Grid>
      <Box mt={3} mb={4} textAlign='center'>
        <StyledButton
          label={editMode ? t(tKey('edit_record')) : t(tKey('create_record'))}
          disabled={isSubmitting}
          loading={isSubmitting}
          type='submit'
        />
      </Box>
    </Form>
  );
};
