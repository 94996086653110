import { IItem } from './AdminReview/AdminItemTypes';

export type filterItemStatus = 'in_review' | 'approved' | 'hidden';
export type filterItemReportsOrder = 'asc' | 'desc';
export type filterItemDate = 'newest' | 'oldest';

export type getStoriesPayload = {
  status: filterItemStatus;
  page: number;
  perPage: number;
  date: filterItemDate;
  reportsOrder: filterItemReportsOrder;
};

export type getCommentsPayload = getStoriesPayload;

export type filtersType = {
  status: filterItemStatus;
  date: filterItemDate;
  reportsOrder: filterItemReportsOrder;
};

export type paginationType = {
  page: number;
  perPage: number;
};

export type responseStoriesType = {
  current_page: number;
  pages: number;
  perPage: number;
  stories: IItem[];
  total: number;
};

export type responseCommentsType = {
  current_page: number;
  pages: number;
  perPage: number;
  comments: IItem[];
  total: number;
};

export type responseUsersType = {
  current_page: number;
  pages: number;
  perPage: number;
  users: IItem[];
  total: number;
};

export type filterUserStatus = 'active' | 'blocked';

export type getUsersPayload = {
  page: number;
  perPage: number;
  status: filterUserStatus;
  trustedOnly: boolean;
  usernameOrId?: string;
};

export type IUser = {
  id: string;
  username: string;
  email: string;
  country: string | null;
  stories: number;
  followers: number;
  warnings: number;
  is_trusted: boolean;
  story_ban_note: IUserBanNote;
  comment_ban_note: IUserBanNote;
  is_story_banned: boolean;
  story_banned_until: string;
  is_comment_banned: boolean;
  comment_banned_until: string;
  is_profile_banned: boolean;
  profile_banned_until: string;
  last_banned_date: string;
  is_car_banned: boolean;
  car_banned_until: string;
  image: string;
  is_patron: boolean;
};

export type IUserBanNote = {
  note: string;
  ban_date: string;
  date_created: string;
};

export type BanType = 'story' | 'comment' | 'profile' | 'car';

export interface IBan {
  id: string;
  user_id: string;
  note: string;
  type: BanType;
  ban_date: string;
  banned_until: string;
  is_deleted: boolean;
  date_created: boolean;
}

export interface IWarn {
  id: string;
  reason: string;
  date_created: string;
}

export interface IBansWarns {
  bans: IBan[];
  warnings: IWarn[];
}

export const durationsBan: string[] = ['24_hours', '30_days', 'forever'];
export type DurationType = typeof durationsBan[number];
