import { IStoryText } from './AdminItemTypes';
import { ComponentType } from 'react';
import store from 'store2';

enum translateTextProp {
  'en' = 'en_text',
  'es' = 'es_text',
  'uk' = 'uk_text',
}

interface AdminStoryContentTextsType {
  texts: Array<IStoryText>;
  showTranslate: boolean;
}

export const AdminStoryContentTexts: ComponentType<AdminStoryContentTextsType> = ({ texts, showTranslate }) => {
  const storedLanguage: 'en' | 'es' | 'uk' = store('driverbook_language') ?? 'en';

  return (
    <>
      {texts.map((text) => {
        return <div key={text.id}>{showTranslate ? text[translateTextProp[storedLanguage]] : text.original_text}</div>;
      })}
    </>
  );
};
