import { Theme } from '@mui/material';
import { CommonLayout } from 'components';
import { useClasses } from 'utils/hooks/useClasses';
import { CreateStoryForm } from './CreateStoryForm';

const styles = ({ spacing, breakpoints }: Theme) => ({
  container: {
    width: '50%',
    margin: 'auto',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
    [breakpoints.up('sm')]: {
      minWidth: 532,
    },
  },
});

export const CreateStoryPage = () => {
  const classes = useClasses(styles);

  return (
    <CommonLayout>
      <div className={classes.container}>
        <CreateStoryForm />
      </div>
    </CommonLayout>
  );
};
