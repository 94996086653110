import { useMutation } from 'react-query';


import { axiosApiInstance as api, mapKeysToCamelCase } from 'utils';


const useUnlockLogin = async (body: {email_username: string}) => {
  try {
    const { data } = await api.post('/unlock-login/resend', body);
    return mapKeysToCamelCase(data);
  } catch (error: any) {
    return { error: error.response.data.message };
  }
};

export const useUnlockLoginMutation = () => {
  return useMutation(useUnlockLogin);
};
