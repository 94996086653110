import {
  demoImage1,
  demoImage2,
  demoImage3,
  demoImage4,
  demoImage5,

} from 'assets/demo-images';

export const imagesMD = [
  {
    src: demoImage1,
    key: 'demo_one',
  },
  {
    src: demoImage2,
    key: 'demo_two',
  },
  {
    src: demoImage3,
    key: 'demo_three',
  },
  {
    src: demoImage4,
    key: 'demo_four',
  },
  {
    src: demoImage5,
    key: 'demo_five',
  },
];

