import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
  Theme,
} from '@mui/material';
import clsx from 'clsx';
import { ComponentType, MouseEvent, useState } from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { useClasses } from 'utils/hooks/useClasses';

interface PasswordInputProps {
  name: string;
  label: string;
  helperText?: string;
  inputProps?: OutlinedInputProps;
  inputPropsClassName?: string;
}

const styles = ({ palette, spacing }: Theme) => ({
  margin: {
    margin: spacing(2, 0, 1),
  },
  textField: {
    width: '100%',
    '& label.Mui-focused': {
      color: '#bb86fc',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#bb86fc',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.87)',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#bb86fc',
      },
    },
    '& .Mui-disabled': {
      pointerEvents: 'none',
      opacity: 0.3,
      color: 'unset',
    },
  },
  showBtn: {
    color: palette.text.hint,
  },
});

export const PasswordInput: ComponentType<PasswordInputProps> = ({
  name,
  label,
  helperText,
  inputProps,
  inputPropsClassName,
}) => {
  const classes = useClasses(styles);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { control } = useFormContext();
  const { errors } = useFormState();
  const hasError = Boolean(errors[name]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Controller
      render={({ field }) => (
        <FormControl className={clsx(classes.margin, classes.textField)} variant='outlined'>
          <InputLabel error={hasError} disabled={inputProps?.disabled}>
            {label}
          </InputLabel>
          <OutlinedInput
            {...field}
            {...inputProps}
            type={showPassword ? 'text' : 'password'}
            fullWidth
            label={label}
            error={hasError}
            inputProps={{
              autoCapitalize: 'none',
              className: inputPropsClassName,
            }}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  className={classes.showBtn}
                  size='large'
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {(helperText || hasError) && (
            <FormHelperText margin='dense' error={hasError}>
              {helperText ?? errors[name]?.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
      name={name}
      control={control}
    />
  );
};
