import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

export type getTextFromVoiceType = {
  body: getTextFromVoiceBody;
};

export type getTextFromVoiceBody = {
  audio: File;
};

type getTextFromVoiceResponse = {
  message: string;
  text: string;
  error?: any;
};

export const getTextFromVoice = async ({ body }: getTextFromVoiceType): Promise<getTextFromVoiceResponse> => {
  try {
    const formData = new FormData();
    formData.append('audio', body.audio);
    let { data } = await api.post(`assistant/audio-converter`, formData);
    return data;
  } catch (err) {
    return { error: true, message: '', text: '' };
  }
};

export const useVoiceToTextMutation = () => {
  return useMutation(getTextFromVoice);
};
