import { useEffect, useState } from 'react';

import { Record, RecordsFiltersInterface } from 'types';
import { filterByCategory, filterByMonth, filterByYear } from 'utils';

type useFilteredPublicRecordsProps = (
  filters: RecordsFiltersInterface,
  carData: { data: Record[]; isFetching: boolean }
) => {
  records: Record[];
  filteredRecords: Record[];
  lastMileage: number;
};

export const useFilteredPublicRecords: useFilteredPublicRecordsProps = ({ year, month, category }, carData) => {
  const [filteredRecords, setFilteredRecords] = useState<Record[]>([]);
  const [lastMileage, setLastMileage] = useState<number>(0);
  const { data, isFetching } = carData;

  useEffect(() => {
    if (!isFetching && data?.length) {
      const flattenData = data.flat();
      let filteredRecords = [...data];

      setLastMileage(Math.max(...flattenData.map((record: Record) => record.mileage)));

      if (year && year !== 'all') {
        filteredRecords = filterByYear(filteredRecords, Number(year));
      }

      if (month !== 'all') {
        filteredRecords = filterByMonth(filteredRecords, Number(month));
      }

      if (category && category !== 'all') {
        filteredRecords = filterByCategory(filteredRecords, category);
      }

      setFilteredRecords(filteredRecords);
    } else if (!isFetching && data?.length === 0) {
      setFilteredRecords([]);
      setLastMileage(0);
    }
  }, [data, isFetching, year, month, category]); // eslint-disable-line react-hooks/exhaustive-deps

  return { records: data, filteredRecords, lastMileage };
};
