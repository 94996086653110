import { Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CommonLayout, InnerMobileHeader } from 'components';
import { useCreateCarMutation } from 'services';
import { getTKey } from 'utils';

import { useClasses } from 'utils/hooks/useClasses';
import { CarForm } from './components/CarForm';
import { useCarMakesData } from './components/useCarMakesData';
import { Car } from 'types';

const tKey = getTKey('car_form');

const styles = ({ spacing }: Theme) => ({
  container: {
    maxWidth: 800,
    margin: spacing(0, 'auto'),
  },
});

export const defaultCar: Car = {
  make: '',
  model: '',
  year: 0,
  plate: '',
  mileage: 0,
  mileageMetric: '',
  numberOfRecords: 0,
  dateCreated: '',
  id: '',
  userId: '',
  image: null,
  currency: '',
  volumeMetric: undefined,
  latestFuelType: null,
};

export const CreateCarPage: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const navigate = useNavigate();
  const mutation = useCreateCarMutation();
  const { makes, makesLoading } = useCarMakesData();

  const onSubmit = (data: any) => {
    mutation.mutate(data, {
      onSuccess: () => {
        //@ts-ignore
        gtag?.('event', 'vehicle_added', { method: 'vehicle_added' });
        toast(t(tKey('car_added')));
        navigate('/cars');
      },
    });
  };

  return (
    <CommonLayout>
      <div className={classes.container}>
        <InnerMobileHeader title={t(tKey('new_car'))} />
        {!makesLoading && makes && <CarForm makes={makes} onSubmit={onSubmit} car={defaultCar} />}
      </div>
    </CommonLayout>
  );
};
