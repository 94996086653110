import { Theme } from '@mui/material';
import patronIcon from 'assets/Patron-Icon.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ palette, spacing }: Theme) => ({
  link: {
    display: 'inline-flex',
    padding: '0px 8px 0px 0px',
    alignItems: 'center',
    gap: spacing(1),
    borderRadius: spacing(5),
    background: 'rgba(255, 203, 64, 0.10)',
    height: 16,
    textDecoration: 'none',
  },
  label: {
    background: 'linear-gradient(180deg, #FFFCF3 32%, #FFCB40 74%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: 12,
    fontWeight: 400,
    textDecoration: 'none',
  },
});

export const PatronMedium = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  return (
    <Link className={classes.link} to={{ pathname: 'https://buymeacoffee.com/driverbook' }} target='_blank'>
      <img src={patronIcon} alt='Logo' width={16} height={16} />
      <span className={classes.label}>{t('patron_label')}</span>
    </Link>
  );
};
