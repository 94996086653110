import { Close } from '@mui/icons-material';
import { IconButton, Switch, Theme } from '@mui/material';
import clsx from 'clsx';
import { StyledButton } from 'components';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { useAdminUserModerate, useAdminWarnBanUser } from '../hooks';
import { useAdminUnblockUser } from '../hooks/useAdminUnblockUser';
import { BanType, DurationType, IUser } from './AdminDashboardTypes';
import { ItemActionType } from './AdminReview/AdminItemTypes';
import { AdminUserInfo } from './AdminReview/AdminUserInfo';
import { AdminUserBansWarns } from './AdminUserBansWarns';
import { UserBanDialog, UserUnblockDialog } from './Dialogs';
const tKey = getTKey('admin_user_details');
const styles = ({ palette, spacing }: Theme) => ({
  root: {
    flex: 1,
    background: '#202020',
    display: 'flex',
    flexDirection: 'row',
  },
  userInfo: {
    flex: 1,
    borderRight: '1px solid #484848',
    display: 'flex',
    flexDirection: 'column',
  },
  userSummary: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(3),
  },
  summaryItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: spacing(2, 0),
    borderBottom: '1px solid #484848',
  },
  details: {
    flex: 2,
    padding: spacing(3),
  },
  userBox: {
    borderBottom: '1px solid #484848',
    paddingBottom: spacing(1),
    margin: spacing(3, 3, 0, 3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  detailsHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  detailsTitle: {
    fontSize: 20,
    fontWeight: 500,
  },
  space: {
    flex: 1,
  },
  closeButton: {
    color: palette.text.hint,
  },
  hideButton: {
    background: '#FF5050',
  },
  actionButton: {
    padding: spacing(0.5, 1.5),
    margin: '0 4px',
  },
  bansWarnsWrap: {
    maxHeight: `calc(100vh - ${spacing(36)})`,
    overflow: 'scroll',
  },
});

interface AdminUserDetailsType {
  user: IUser;
  onClose: () => void;
  refresh: () => void;
}

export const AdminUserDetails: ComponentType<AdminUserDetailsType> = ({ user, onClose, refresh }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);

  const { trustUser, untrustUser, patronUser } = useAdminUserModerate();

  const onChangeTrust = async (user: IUser) => {
    if (user.is_trusted) {
      await untrustUser(user.id);
    } else {
      await trustUser(user.id);
    }
    user.is_trusted = !user.is_trusted;
  };

  const onChangePatron = async (user: IUser) => {
    if (user.is_patron) {
      await patronUser(user.email, false);
    } else {
      await patronUser(user.email, true);
    }
    user.is_patron = !user.is_patron;
  };

  const { onBan, openBanUserDialog, setHiddenUser, onCloseBanDialog, onBanOpenDialog } = useAdminWarnBanUser();

  const { onCloseUnblockUserDialog, onOpenUnblockUserDialog, onUnblockUser, openUnblockUserDialog, unblockedUser } =
    useAdminUnblockUser();

  const onBanUserDialogAction = () => {
    setHiddenUser(user.id);
    onBanOpenDialog();
  };

  return (
    <div className={classes.root}>
      <div className={classes.userInfo}>
        <div className={classes.userBox}>
          <AdminUserInfo user={user} />
        </div>
        <div className={classes.userSummary}>
          <div className={classes.summaryItem}>
            <div>{t(tKey('email'))}</div>
            <div>{user.email}</div>
          </div>
          <div className={classes.summaryItem}>
            <div>{t(tKey('stories'))}</div>
            <div>{user.stories}</div>
          </div>
          <div className={classes.summaryItem}>
            <div>{t(tKey('followers'))}</div>
            <div>{user.followers}</div>
          </div>
          <div className={classes.summaryItem}>
            <div>{t(tKey('trusted'))}</div>
            <div>
              <Switch size='small' checked={user.is_trusted} onChange={() => onChangeTrust(user)} />
            </div>
          </div>
          <div className={classes.summaryItem}>
            <div>{t('patron_label')}</div>
            <div>
              <Switch size='small' checked={user.is_patron} onChange={() => onChangePatron(user)} />
            </div>
          </div>
          <div className={classes.summaryItem}>
            <div>{t(tKey('warnings'))}</div>
            <div>{user.warnings}</div>
          </div>
        </div>
      </div>
      <div className={classes.details}>
        <div className={classes.detailsHeader}>
          <div className={classes.detailsTitle}>{t(tKey('title'))}</div>
          <div className={classes.space}></div>
          <div className={classes.banButtonWrap}>
            {(user.is_comment_banned === true || user.is_story_banned === true) && (
              <StyledButton
                label={t('admin_user_list_action_restore')}
                color='secondary'
                className={classes.actionButton}
                onClick={() => onOpenUnblockUserDialog(user)}
              />
            )}
            {user.is_comment_banned === false && user.is_story_banned === false && (
              <StyledButton
                label={t('admin_user_list_action_ban')}
                className={clsx(classes.actionButton, classes.hideButton)}
                onClick={onBanUserDialogAction}
              />
            )}
          </div>
          <IconButton onClick={onClose}>
            <Close className={classes.closeButton} />
          </IconButton>
        </div>
        <div className={classes.bansWarnsWrap}>
          <AdminUserBansWarns user={user.id} />
        </div>
      </div>
      <UserBanDialog
        onClose={onCloseBanDialog}
        onConfirm={async (ban_from: ItemActionType, duration: DurationType, note: string) => {
          await onBan(ban_from, duration, note);
          await refresh();
          onClose();
        }}
        open={openBanUserDialog}
      />
      <UserUnblockDialog
        user={unblockedUser}
        onClose={onCloseUnblockUserDialog}
        onConfirm={async (from) => {
          await onUnblockUser(from as BanType);
          await refresh();
          onClose();
        }}
        open={openUnblockUserDialog}
      />
    </div>
  );
};
