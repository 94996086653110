import { useAlertReadMutation } from "services/alerts";

interface useAlertsModifyType {
  readAlert(ids: string[]): void
}

type useAlertsPageType = () => useAlertsModifyType;
export const useAlertsModify: useAlertsPageType = () => {

  const mutationReadAlert = useAlertReadMutation();
  const readAlert = (ids: string[]) => {
    mutationReadAlert.mutate({ids});
  }

  return {
   readAlert,
  }
}