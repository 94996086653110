import { useMutation } from 'react-query';

import { axiosApiInstance as api } from 'utils';

const confirmEmail = async (payload: any): Promise<any> => {
  const { data } = await api.post('/email-confirm-request', payload);
  return data;
};

export const useConfirmEmailMutation = () => {
  return useMutation(confirmEmail);
};
