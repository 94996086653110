import { ComponentType, useEffect, useRef } from 'react';
import Lottie from 'lottie-react';
import animationData from '../SpinFeedLoader/loader.json';
import { useClasses } from 'utils/hooks/useClasses';
import { useMediaQuery } from '@mui/material';
import { theme } from 'theme';
import clsx from 'clsx';
import useCustomMatch from 'utils/hooks/useCustomMatch';

const styles = {
  container: {
    position: 'fixed',
    zIndex: 1400,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#121212',
  },
  desktop: {
    top: 104,
    height: 'calc(100% - 104px)',
  },
  mobile: {
    bottom: 64,
    height: 'calc(100% - 64px - env(safe-area-inset-bottom))',
  },
  mobileFull: {
    height: '100%',
  },
};

export const PageLoader: ComponentType = () => {
  const classes = useClasses(styles);
  const lottieRef = useRef<any>(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const isMobileFull = useCustomMatch(['/settings', '/login', '/signup']);

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.goToAndPlay(27, true);
    }
  }, []);

  return (
    <div
      className={clsx(
        classes.container,
        fullScreen ? (isMobileFull ? classes.mobileFull : classes.mobile) : classes.desktop
      )}
    >
      <Lottie animationData={animationData} lottieRef={lottieRef} />
    </div>
  );
};
