import { Close } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Theme,
} from '@mui/material';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { ElementToCorrect } from '../AdminReview/AdminItemTypes';

const tKey = getTKey('admin_dialog_warn_profile');
const pcKey = getTKey('profile_correct');

const styles = ({ palette, spacing }: Theme) => ({
  description: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
  titleWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  closeButton: {
    color: palette.text.hint,
  },
  actions: {
    '& button': {
      letterSpacing: 1.25,
    },
  },
  field: {
    margin: spacing(2.75, 0),
  },
  helperText: {
    padding: spacing(0, 2.5),
    color: palette.text.hint,
    fontSize: 12,
  },
  radioStyle: {
    justifyContent: 'space-between',
    margin: spacing(1, 0),
    width: '100%',
  },
  control: {
    width: '100%',
    overflowY: 'scroll',
    height: 270,
  },
});

interface ProfileWarnDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (elementsToCorrect: ElementToCorrect[]) => void;
}

export const ProfileWarnDialog: ComponentType<ProfileWarnDialogProps> = ({ open, onClose, onConfirm }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();

  const checkboxOptions: { value: string; label: string }[] = [
    { value: 'image', label: t(pcKey('image')) },
    { value: 'bio', label: t(pcKey('bio')) },
    { value: 'username', label: t(pcKey('username')) },
    { value: 'location', label: t(pcKey('location')) },
  ];

  const [checkboxData, setCheckboxData] = useState<{ [key: string]: boolean }>({
    bio: false,
    location: false,
    image: false,
    username: false,
  });

  const handleCheckboxChange = (event: any) => {
    const { name, checked } = event.target;
    setCheckboxData({ ...checkboxData, [name]: checked });
  };

  const handleConfirm = () => {
    const selectedCheckboxes = Object.keys(checkboxData).filter((key) => checkboxData[key]) as ElementToCorrect[];
    onConfirm(selectedCheckboxes);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='xs'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title' className={classes.titleWrap}>
        {t(tKey('title'))}
        <IconButton onClick={onClose}>
          <Close className={classes.closeButton} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl component='fieldset' className={classes.control}>
          <FormGroup>
            {checkboxOptions.map((option) => (
              <FormControlLabel
                labelPlacement='start'
                className={classes.radioStyle}
                key={option.value}
                label={option.label}
                control={
                  <Checkbox
                    name={option.value}
                    color='primary'
                    onChange={handleCheckboxChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
              />
            ))}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button onClick={onClose} color='inherit'>
          {t('__common__cancel')}
        </Button>
        <Button onClick={handleConfirm} color='error'>
          {t('__common__confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
