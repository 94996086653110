import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';
import { updateReminderPayload } from './reminderPayloads';

const updateReminder = async ({ carId, reminderId, body }: updateReminderPayload) => {
  const { data } = await api.put(`cars/${carId}/reminders/${reminderId}`, body);
  return data;
};

export const useUpdateReminderMutation = () => {
  return useMutation(updateReminder);
};
