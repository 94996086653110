import { useMutation } from 'react-query';

import { axiosApiInstance as api, mapKeysToCamelCase } from 'utils';

export type GoogleLoginResponse = {
  accessToken: string;
  username: string;
  error?: string;
};

type GoogleLoginRequest = {
  email: string;
  google_id: string;
  language: string;
  access_token: string;
};

const login = async (body: GoogleLoginRequest): Promise<Partial<GoogleLoginResponse>> => {
  try {
    const { data } = await api.post('/users/google-login', body);
    return mapKeysToCamelCase({ ...data, google_access_token: body.access_token });
  } catch (error: any) {
    return { error: error.response.data.message };
  }
};

export const useGoogleLoginMutation = () => {
  return useMutation(login);
};
