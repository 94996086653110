import { useUsersQuery } from 'services/admin/hooks/useUsersQuery';
import { IUser, filterUserStatus } from '../components/AdminDashboardTypes';
import { responseUsersType } from '../components/AdminDashboardTypes';

export type filtersType = {
  status: filterUserStatus;
  trustedOnly: boolean;
  usernameOrId?: string;
};

export type paginationType = {
  page: number;
  perPage: number;
};

type useAdminUsersDataType = (
  filters: filtersType,
  pagination: paginationType
) => {
  users: IUser[];
  isFetching: boolean;
  data: responseUsersType;
  refetch: () => void;
};

export const useAdminUsers: useAdminUsersDataType = (filters, pagination) => {
  const { data, isFetching, refetch } = useUsersQuery({ ...filters, ...pagination }, {});
  var users = data?.users;

  return {
    users,
    isFetching,
    data,
    refetch,
  };
};
