import { StoryPayload } from 'features/stories-feed/StoriesTypes';
import { useMutation } from 'react-query';
import { axiosApiInstance as api } from 'utils';

const viewStory = async ({ carId, storyId }: StoryPayload): Promise<any> => {
  const { data } = await api.post(`/cars/${carId}/stories/${storyId}/view`);
  return data;
};

export const useStoryViewMutation = () => {
  return useMutation(viewStory);
};
