import React, { ComponentType } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Car } from 'types';
import { ConfirmationDialog } from 'components';

import { CarItem } from './CarItem';
// import { CarFact } from './CarFact';
import { useCarsList } from '../useCarsList';

interface CarsListProps {
  cars: Car[];
}

export const CarsList: ComponentType<CarsListProps> = ({ cars }) => {
  const { t } = useTranslation();
  const {
    idToDelete,
    handleClose,
    handleConfirmDelete,
    handleOnEdit,
    handleOnDelete,
    handleOnTransfer,
    handleOnShare
  } = useCarsList();
  const listLength = cars.length;
  const listWithoutFirstCar = cars.slice(1, listLength);

  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid item xs={12} md={8} lg={7}>
        <Grid container spacing={2}>
          <ConfirmationDialog
            description={t('confirm_dialog_car')}
            open={Boolean(idToDelete)}
            onConfirm={handleConfirmDelete}
            onClose={handleClose}
          />
          <Grid item xs={12} md={6}>
            <CarItem
              car={cars[0]}
              onEdit={handleOnEdit}
              onDelete={handleOnDelete}
              onTransfer={handleOnTransfer}
              onShare={handleOnShare}
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <CarFact />
          </Grid> */}
          {listLength > 1 &&
            listWithoutFirstCar.map((car) => (
              <Grid item xs={12} md={6} key={car.id}>
                <CarItem car={car} onEdit={handleOnEdit} onDelete={handleOnDelete} onTransfer={handleOnTransfer} onShare={handleOnShare} />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
