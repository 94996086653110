import { MoreVert } from '@mui/icons-material';
import { ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Theme } from '@mui/material';
import { ConfirmationDialog } from 'components';
import { ComponentType, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ breakpoints, spacing, palette }: Theme) => ({
  moreBtn: {
    padding: 0,
  },
  moreMenu: {
    borderRadius: 4,
    background: '#2E2E2E',
    '& .MuiMenu-paper': {
      background: '#2E2E2E',
    },
    '& .MuiList-root': {
      minWidth: 128,
      background: '#2E2E2E',
      borderRadius: 4,
    },
    '& .MuiButtonBase-root': {
      background: '#2E2E2E',
    },
  },
});

interface CommentManageButtonType {
  onEdit: () => void;
  onDelete: () => void;
}

export const CommentManageButton: ComponentType<CommentManageButtonType> = ({ onEdit, onDelete }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const handleEdit = () => {
    setOpen(false);
    onEdit();
  };

  const handleDelete = () => {
    setOpen(false);
    setOpenDeleteDialog(true);
  };

  const onDeleteConfirm = () => {
    setOpen(false);
    setOpenDeleteDialog(false);
    onDelete();
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <IconButton
        id='comment-manage-button'
        aria-controls={open ? 'comment-manage-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleToggle}
        className={classes.moreBtn}
        ref={anchorRef}
      >
        {
          //@ts-ignore
          <MoreVert className={classes.flagBtnIcon} color='inert' />
        }
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='bottom-start'
        transition
        disablePortal
        style={{ zIndex: 10 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper className={classes.moreMenu}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='composition-menu'
                  aria-labelledby='composition-button'
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={handleEdit}>{t('__common__edit')}</MenuItem>
                  <MenuItem onClick={handleDelete}>{t('__common__delete')}</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <ConfirmationDialog
        title={t('__common__confirm')}
        description={t('comments_delete_confirmation_text')}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={onDeleteConfirm}
        open={openDeleteDialog}
      />
    </div>
  );
};
