import { Box, Theme, Typography } from '@mui/material';
import { UpgradeDialog } from 'components/UpgrageDialog/UpgradeDialog';
import { ComponentType, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileContext } from 'store/profile/store';
import { RecordAITypeFull } from 'types';
import { getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
const tKey = getTKey('record_ai');
const styles = ({ palette, breakpoints, spacing }: Theme) => ({
  root: {
    background: 'rgba(255, 203, 64, 0.1)',
    borderRadius: 40,
    minHeight: 33,
    padding: spacing(1, 2),
    margin: spacing(2, 0, 1),
  },
  upgrade: {
    color: '#FFCB40',
    fontWeight: 700,
    textDecoration: 'none',
    cursor: 'pointer'
  },
});

interface AIRecordLeftType {
  type: RecordAITypeFull;
  description?: string;
  count: number;
}

export const AIRecordLeft: ComponentType<AIRecordLeftType> = ({ type, description, count }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const { state } = useContext(ProfileContext);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  return (
    <>
      <Box className={classes.root} display='flex' alignItems={'center'} justifyContent={'space-between'}>
        {count === 0 ? (
          t(tKey('records_none'))
        ) : state.is_patron ? (
          <Typography variant='body1'>
            {count === 1 && t(tKey('records_left_week_one_' + type), { count })}
            {[2, 3, 4].includes(count) && t(tKey('records_left_week_two_' + type), { count })}
            {count > 4 && t(tKey('records_left_week_many_' + type), { count })}
          </Typography>
        ) : (
          <Typography variant='body1'>
            {count === 1 && t(tKey('records_left_month_one_' + type), { count })}
            {[2, 3, 4].includes(count) && t(tKey('records_left_month_two_' + type), { count })}
            {count > 4 && t(tKey('records_left_month_many_' + type), { count })}
          </Typography>
        )}

        {/* {!state.is_patron ?
          (<Typography className={classes.upgrade} onClick={() => setHelpDialogOpen(true)}>
          {t(tKey('upgrade'))}
          </Typography>) : <>🙌 🫂</>} */}
          <span style={{fontSize: 20}}>✨</span>
      </Box>
      {description && (
        <Typography variant='body2' color={'gray'} textAlign='center' margin='0 8px'>
          {description}
        </Typography>
      )}
      <UpgradeDialog open={helpDialogOpen} onClose={() => setHelpDialogOpen(false)} />
    </>
  );
};
