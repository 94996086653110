import { useMutation } from 'react-query';

import { queryClient } from 'components';
import { axiosApiInstance as api } from 'utils';

const deleteRecord = async ({ carId, id }: {
  carId: string;
  id: string;
}): Promise<void> => {
  await api.delete(`/cars/${carId}/records/${id}`);
};

export const useDeleteRecordMutation = () => {
  return useMutation(deleteRecord, {
    onSuccess: (_, { carId }) => {
      queryClient.invalidateQueries(['records', carId]);
      queryClient.invalidateQueries('allRecords');
    },
  });
}