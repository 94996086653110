import { Typography } from '@mui/material';
import { InnerMobileHeader } from 'components';
import { UsersList } from 'features/stories-feed/components/UsersList';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlockedUsersQuery } from 'services/profile/v2/useBlockedUsersQuery';

export const BlockedUsersTab: ComponentType = () => {
  const { t } = useTranslation();
  const { data: blockedUsers, isLoading } = useBlockedUsersQuery();
  return (
    <>
      <InnerMobileHeader title={t('settings_blocked_users_title')} showBack />
      <Typography variant='body2' color='lightgray'>
        {t('settings_blocked_users_note')}
      </Typography>
      {!isLoading && blockedUsers && <UsersList users={blockedUsers} block={true} />}
    </>
  );
};
