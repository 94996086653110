import { useQuery, UseQueryResult } from 'react-query';

import { Record } from 'types';
import { axiosApiInstance as api } from 'utils';

import { createRecordUI } from '../../records/utils';

const getDrafts = async (carId: string, identifier: string): Promise<Record[]> => {
  const { data } = await api.get(`/cars/${carId}/records/draft`, { params: { identifier } });

  return data.records.map(createRecordUI);
};

export const useDraftsQuery = (carId: string, identifier: string): UseQueryResult<any> => {
  return useQuery(['drafts'], () => getDrafts(carId, identifier), { enabled: !!carId });
};
