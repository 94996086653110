import { Close, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  FilledInput,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Modal,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@mui/material';
import { ComponentType, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOptions } from 'types';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ breakpoints, spacing, palette }: Theme) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  container: {
    backgroundColor: palette.grey[800],
    width: 406,
    height: 520,
    padding: spacing(4, 3),
    position: 'relative',
    [breakpoints.down('md')]: {
      padding: spacing(0, 3),
      margin: spacing(0, 5),
      height: 354,
    },
  },
  title: {
    fontSize: 20,
    fontWeight: '500',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [breakpoints.down('md')]: {
      display: 'none',
    },
  },
  search: {
    width: '100%',
    margin: spacing(3, 0),

    backgroundColor: 'rgba(32, 32, 32, 1)',
  },
  input: {
    paddingTop: 15,
  },
  icon: {
    color: 'rgba(202, 196, 208, 0.7)',
  },
  control: {
    width: '100%',
    overflowY: 'scroll',
    height: 270,
    [breakpoints.down('md')]: {
      height: 190,
    },
  },
  radioStyle: {
    justifyContent: 'space-between',
    margin: spacing(1, 0),
    width: '100%',
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    width: '90%',
  },
  footerContent: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  divider: {
    margin: spacing(2, 0),
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
  },
  close: {
    cursor: 'pointer',
  },
});
interface ModalSearchSelectType {
  open: boolean;
  title: string;
  options: SelectOptions[];
  name: string;
  defaultValue: any;
  onChange: any;
  onClose: any;
}
export const ModalSearchSelect: ComponentType<ModalSearchSelectType> = ({
  open,
  title,
  options,
  name,
  onChange,
  defaultValue,
  onClose,
}) => {
  const classes = useClasses(styles);
  const [value, setValue] = useState(defaultValue);
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const filteredOptions = useMemo(() => {
    if (search) {
      return options.filter((option) => option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
    }
    return options;
  }, [search, options]);

  return (
    <Modal open={open} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      <Box className={classes.main}>
        <Box className={classes.container}>
          <Box className={classes.header}>
            <Typography className={classes.title}>{title}</Typography>
            <Close className={classes.close} onClick={onClose} />
          </Box>
          <FilledInput
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            className={classes.search}
            inputProps={{
              autoCapitalize: 'none',
              className: classes.input,
            }}
            placeholder='Search'
            endAdornment={
              <InputAdornment position='end'>
                <Search className={classes.icon} />
              </InputAdornment>
            }
          />
          <FormControl component='fieldset' className={classes.control}>
            <RadioGroup
              aria-label='unit'
              name={name}
              value={value}
              onChange={(newValue) => setValue(newValue.target.value)}
            >
              {filteredOptions.map((option) => (
                <FormControlLabel
                  labelPlacement='start'
                  className={classes.radioStyle}
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  control={<Radio color='primary' />}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <Box className={classes.footer}>
            <Divider className={classes.divider} />
            <Box className={classes.footerContent}>
              <Button
                onClick={() => {
                  onChange({ target: { value } });
                  onClose();
                }}
              >
                {t('search_apply')}
              </Button>
              <Button onClick={onClose}>{t('search_cancel')} </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
