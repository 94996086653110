import { Box } from '@mui/material';
import { IStory } from 'features/stories-feed/StoriesTypes';
import { ComponentType } from 'react';
import { getYoutubeIdFromUrl } from 'utils/YouTubeUtil';

interface StoryContentVideoType {
  element: IStory;
}

export const StoryContentVideo: ComponentType<StoryContentVideoType> = ({ element }) => {
  return (
    <div>
      <Box style={{ position: 'relative', width: '100%', overflow: 'hidden', paddingTop: '56.25%' }}>
        {element?.youtube_video_url && (
          <iframe
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              width: '100%',
              height: '100%',
              border: 'none',
            }}
            src={`https://www.youtube.com/embed/${getYoutubeIdFromUrl(element.youtube_video_url)}`}
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            title='Embedded youtube'
          />
        )}
      </Box>
    </div>
  );
};
