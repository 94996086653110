import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { Checkbox, Theme } from '@mui/material';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'store2';

import { Currency, Record, UserSettings } from 'types';
import { countTotalMoneySpend, currencySymbol, formatDate } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

interface RecordsGroupProps {
  data: Record[];

  handleChange?: (checked: boolean, selected: Record[]) => void;
  value?: boolean;
}

const styles = ({ palette, spacing, breakpoints, transitions }: Theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    backgroundColor: 'rgba(65,65,65,1)',
    minHeight: 89,
    padding: spacing(2, 2, 1),
    borderRadius: spacing(0.5),
    cursor: 'pointer',
    boxShadow: '0px 6px 0px -1px #333, 0px 12px 0px -2px #242424',
  },
  selectRoot: {
    marginBottom: spacing(1.5),
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 10,
    letterSpacing: 1.5,
    color: 'rgba(255, 255, 255, 0.6)',
  },
  infoNumberNotes: {
    fontSize: 10,
    letterSpacing: 1.5,
    color: 'rgba(255, 255, 255, 0.6)',
    marginTop: spacing(1),
  },
  dot: {
    display: 'inline-block',
    margin: spacing(0, 0.5),
    fontSize: 12,
    color: palette.additionalPrimary['200'],
  },
  title: {
    width: '100%',
    marginTop: spacing(1),
    fontSize: 20,
    fontWeight: 500,
    color: 'rgba(255, 255, 255, 0.6)',
    letterSpacing: 1.5,

    [breakpoints.up('sm')]: {
      textOverflow: 'ellipsis',
      maxHeight: 48,
      marginTop: 0,
      lineClamp: 2,
      overflow: 'hidden',
      fontSize: 'inherit',
      boxOrient: 'vertical',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
    },
  },
  rootWrap: {
    position: 'relative',
  },
});

export const RecordsGroup: ComponentType<RecordsGroupProps> = ({ data, handleChange, value }) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);

  const settingsData = store('driverbook_settings') as UserSettings;
  const totalExpenses = countTotalMoneySpend(data?.flat());
  const firstRecord = data[0];

  return (
    <div className={clsx(classes.rootWrap, [handleChange && classes.selectRoot])}>
      <div className={classes.root} onClick={() => handleChange?.(!value, data)}>
        <div className={classes.header}>
          <div className={classes.info}>
            {formatDate(firstRecord.dateCreated)}
            <span className={classes.dot}>•</span>
            <div>
              {totalExpenses} {currencySymbol[settingsData?.currency as Currency]}
            </div>
          </div>
          <div className={classes.title}>{firstRecord.groupTitle}</div>
          <div className={classes.infoNumberNotes}>
            {t('record_group_notes')} {data.length}
          </div>
        </div>
        {handleChange && (
          <div>
            <Checkbox
              color='primary'
              checked={value}
              inputProps={{ 'aria-label': 'controlled' }}
              icon={<RadioButtonUnchecked />}
              checkedIcon={<RadioButtonChecked />}
            />
          </div>
        )}
      </div>
    </div>
  );
};
