import { Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { TextLogo } from 'components';

import store from 'store2';
import { useClasses } from 'utils/hooks/useClasses';
import { menuItems } from './menuItems';
import patronIcon from 'assets/Patron-Icon.svg';
import clsx from 'clsx';
import { Capacitor } from '@capacitor/core';

const styles = ({ palette, spacing }: Theme) => ({
  root: {
    position: 'fixed',
    zIndex: 10,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    backgroundColor: '#121212',
  },
  overlay: {
    paddingTop: 40,
    flex: 1,
  },
  wrapper: {
    boxShadow: 'inset 0 0 4em rgba(10, 10, 10, 0.7)',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    overflow: 'scroll',
  },
  header: {
    padding: spacing(3, 2),
    borderBottom: '1px solid rgba(255, 255, 255, 0.16)',
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 48,
    cursor: 'pointer',
    padding: spacing(1.5, 2),
    borderRadius: spacing(0.5),
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'none',
    textDecoration: 'none',
    color: palette.text.primary,

    '& .MuiSvgIcon-root': {
      marginRight: spacing(4),
      color: palette.text.hint,
    },

    '&:first-of-type': {
      marginTop: spacing(1),
    },

    '&:last-of-type': {
      marginBottom: spacing(1),
    },
  },
  patron: {
    color: '#FFCB40',
    '& > img': {
      marginRight: spacing(4),
    },
  },
  selected: {
    backgroundColor: 'rgba(187, 134, 252, 0.08)',
    color: palette.primary.main,
  },
});

type SettingsMenuMobileProps = {
  startDemo: () => void;
  onClose: () => void;
};
const TabLabel: ComponentType<{ label: string }> = ({ label }) => {
  const { t } = useTranslation();

  return <div>{t(label)}</div>;
};

export const SettingsMenuMobile: ComponentType<SettingsMenuMobileProps> = ({ onClose, startDemo }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const country = store('driverbook_country');
  const validCountry = country === 'poland' || country === 'ukraine';
  const getNavLinkClass = ({ isActive }: { isActive: boolean }) => {
    return isActive ? `${classes.tab} ${classes.selected}` : classes.tab;
  };
  return (
    <div className={classes.root}>
      <div className={classes.overlay} onClick={onClose} />
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <TextLogo withVersion />
        </div>
        {!Capacitor.isNativePlatform() && (
          <NavLink
            key={'patron'}
            to={{ pathname: 'https://buymeacoffee.com/driverbook' }}
            target='_blank'
            className={clsx(classes.tab, classes.patron)}
          >
            <img src={patronIcon} alt='Logo' width={24} height={24}></img>
            <TabLabel label={t('patron_become')} />
          </NavLink>
        )}
        {menuItems.map(({ to, label, icon: Icon, withArrow }) => {
          if (!validCountry && to === '/calculator') {
            return <></>;
          }
          if (to)
            return (
              <NavLink key={to} to={to} className={getNavLinkClass}>
                <Icon />
                <TabLabel label={label} />
              </NavLink>
            );
          else {
            return (
              <div key={label} className={classes.tab} style={{ marginBottom: 0 }} onClick={startDemo}>
                <Icon />
                <TabLabel label={label} />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
