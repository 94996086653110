import { useQuery } from 'react-query';
import store from 'store2';
import { axiosApiInstance as api } from 'utils';

export const storyAudio = async (carId: string, storyId: string): Promise<string | null> => {
  try {
    const storedLanguage = store('driverbook_language') || 'en';
    const { data } = await api.get(`cars/${carId}/stories/${storyId}/audio?language=${storedLanguage}`, {
      responseType: 'blob',
    });
    if (data.type === 'application/json') {
      let response = JSON.parse(await data.text());
      if (response.message === 'short_text') {
        return response.message;
      }
      return null;
    }
    if (data.type === 'audio/mpeg') {
      const audioObjectUrl = URL.createObjectURL(data);
      return audioObjectUrl;
    }
    return data;
  } catch (error) {
    return 'Error';
  }
};

export const useStoryAudioQuery = (carId: string, storyId: string, options: any) => {
  return useQuery(['storyAudio', carId, storyId], () => storyAudio(carId, storyId), { cacheTime: 0, ...options });
};
