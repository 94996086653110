import { CommonLayout } from 'components';
import { ComponentType, useEffect, useState } from 'react';

import { CommonPublicLayout } from 'components/Layout/CommonLayoutPublic';
import { useChangeLanguage, useInitialLanguage, useInitialLanguageForGuest, useInitialSettings } from 'lib';
import { useTranslation } from 'react-i18next';
import { isLoggedIn } from 'utils';
import { originalLanguage } from './StoriesTypes';
import { StoriesWrapper } from './components/StoriesWrapper';

export const StoriesPage: ComponentType = () => {
  const authorized = isLoggedIn();
  const { i18n } = useTranslation();
  const { chosenLanguage } = useChangeLanguage();
  const [lang, setLang] = useState(chosenLanguage);

  useEffect(() => {
    i18n.on('languageChanged', (language) => {
      setLang(language);
    });
    return () => {
      i18n.off('languageChanged');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return authorized ? (
    <CommonLayout>
      <StoriesWrapper authorized={authorized} language={lang as originalLanguage} />
      <InitAuthStoryPage />
    </CommonLayout>
  ) : (
    <CommonPublicLayout>
      <StoriesWrapper authorized={authorized} language={lang as originalLanguage} />
      <InitStoryPageCommon />
    </CommonPublicLayout>
  );
};

const InitAuthStoryPage: ComponentType = () => {
  useInitialSettings();
  useInitialLanguage();
  return <div></div>;
};

const InitStoryPageCommon: ComponentType = () => {
  useInitialLanguageForGuest();
  return <div></div>;
};
