import store from 'store2';

export const assistanceStream = async (body: any, onChunk: (chunk: string) => void, signal: any): Promise<void> => {
  const url = `${process.env.REACT_APP_PUBLIC_API_URL}assistant`;
  const token = store('accessToken');

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
      signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const reader = response.body?.getReader();
        const decoder = new TextDecoder();

        // Check if streaming is supported
        if (reader) {
          const processStream = async () => {
            let done = false;

            // Read the stream
            while (!done) {
              const { value, done: streamDone } = await reader.read();
              done = streamDone;

              // If there's data, decode and call the onChunk callback
              if (value) {
                const chunk = decoder.decode(value, { stream: true });
                onChunk(chunk); // Call the callback with the chunk
                // console.log('Stream chunk received:', chunk);
              }
            }

            resolve(); // Resolve the promise when the stream ends
          };

          processStream().catch(reject); // Handle any errors in the processing
        } else {
          reject('Stream reading not supported');
        }
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          // Handle fetch abortion (if you want to provide a specific message)
          console.log('Fetch aborted');
          resolve(); // Resolve to ensure proper exit
        } else {
          reject(error);
        }
      });
  });
};
