import { IText } from 'features/stories-feed/StoriesTypes';
import { ComponentType } from 'react';
import { useClasses } from 'utils/hooks/useClasses';
import { TranslateContext } from '../StoryWrapper';
import Linkify from 'linkify-react';

const styles = () => ({
  text: {
    whiteSpace: 'pre-line',
  },
});
interface StoryContentTextsType {
  texts: Array<IText>;
}

const linkStyle = {
  color: '#BB86FC',
  cursor: 'pointer',
  wordWrap: 'break-word',
};

const renderLink = ({ attributes, content }: any) => {
  const { href } = attributes;

  const openExternalLink = () => {
    const event = new CustomEvent('openExternalLink', { detail: { link: href } });
    window.dispatchEvent(event);
  };

  return (
    <>
      {/* @ts-ignore */}
      <span style={linkStyle} onClick={openExternalLink}>
        {content}
      </span>
    </>
  );
};

export const StoryContentTexts: ComponentType<StoryContentTextsType> = ({ texts }) => {
  const classes = useClasses(styles);

  return (
    <>
      {texts.map((text) => {
        return (
          <div key={text.id} className={classes.text}>
            <TranslateContext.Consumer>
              {(noTranslate) =>
                !text.do_not_translate && !noTranslate ? (
                  <Linkify options={{ render: renderLink }}>{text.translated_text}</Linkify>
                ) : (
                  <Linkify options={{ render: renderLink }}>{text.original_text}</Linkify>
                )
              }
            </TranslateContext.Consumer>
          </div>
        );
      })}
    </>
  );
};
