import { Grid, Theme, Typography } from '@mui/material';
import { ComponentType } from 'react';
import { useClasses } from 'utils/hooks/useClasses';
import { DashboardStatItem, Stats } from './DashboardStatItem';

interface DashboardStatsCardProps {
  heading: string;
  data: Stats[];
  color?: string;
}

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: spacing(2.5, 2),
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: spacing(0.5),
    border: '1px solid rgba(255, 255, 255, 0.08)',

    [breakpoints.up('md')]: {
      height: '100%',
      minHeight: 185,
    },
  },
});

export const DashboardStatCard: ComponentType<DashboardStatsCardProps> = ({ heading, data, color = 'primary' }) => {
  const classes = useClasses(styles);

  return (
    <div className={classes.root}>
      <Typography variant='body2'>{heading}</Typography>
      <Grid container spacing={2}>
        {data.map((item) => (
          <Grid container key={item.title} item xs={4}>
            <DashboardStatItem data={item} color={color} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
