import { FileDownload, LibraryBooks, MonetizationOn, Speed } from '@mui/icons-material';
import { Box, Chip, IconButton, Theme } from '@mui/material';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useExportServiceBookMutation } from 'services';
import store from 'store2';

import { Currency, Record } from 'types';
import { countTotalMoneySpend, currencySymbol, getTKey } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const tKey = getTKey('records_totals');

interface RecordsTotalsProps {
  data: Record[];
  mileageMetric: string | undefined;
  lastMileage: number;
  publicRecord?: boolean;
  currency?: string;
}

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    marginBottom: spacing(3),
    marginTop: spacing(2),
  },
  chip: {
    marginRight: spacing(1),
    marginBottom: spacing(0.5),
  },
  text: {
    maxWidth: '85%',
    margin: '0 auto',
    textAlign: 'center',
    alignContent: 'center',
  },
});

export const RecordsTotals: ComponentType<RecordsTotalsProps> = ({
  data,
  mileageMetric,
  lastMileage,
  currency,
  publicRecord,
}) => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const settingsData = store('driverbook_settings');
  const flattenData = data.flat();
  const exportMutation = useExportServiceBookMutation();

  const handleExport = () => {
    const id = flattenData[0].carId;
    exportMutation.mutate(
      { id, title: t('records_export_title'), dialogTitle: t('records_export_dialog_title') },
      {
        onSuccess(data, variables, context) {
          console.log(data);
          const xmlData = data;
          const blob = new Blob([xmlData], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'service_book.xlsx';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        },
      }
    );
  };

  return flattenData && flattenData.length ? (
    <div className={classes.root}>
      <Box component={'div'} sx={{ display: { xs: 'flex', md: 'none' } }}>
        <div className={classes.text}>
          {t(tKey('mobile_title'), {
            mileage: lastMileage,
            mileageMetric,
            costs: countTotalMoneySpend(flattenData),
            symbol: currencySymbol[(publicRecord ? currency : settingsData?.currency) as Currency],
          })}
        </div>
        <Box>
          <IconButton className={classes.buttonDownload} color='secondary' onClick={handleExport}>
            <FileDownload />
          </IconButton>
        </Box>
      </Box>

      <Box component={'div'} sx={{ display: { md: 'flex', xs: 'none' }, justifyContent: 'space-between' }}>
        <Box>
          <Chip avatar={<Speed />} label={`${t(tKey('last_mileage'))}: ${lastMileage}`} className={classes.chip} />
          <Chip
            avatar={<MonetizationOn />}
            label={`${t(tKey('total_spent'))}: ${countTotalMoneySpend(flattenData)}`}
            className={classes.chip}
          />
          <Chip
            avatar={<LibraryBooks />}
            label={`${t(tKey('total_records'))}: ${flattenData.length}`}
            className={classes.chip}
          />
        </Box>
        <Box>
          <IconButton className={classes.buttonDownload} color='secondary' onClick={handleExport}>
            <FileDownload />
          </IconButton>
        </Box>
      </Box>
    </div>
  ) : null;
};
