import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { Checkbox, Grid, Theme } from '@mui/material';
import { STORY_ELEMENT_TYPE } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';
import { Story } from './AddStoryContent';

const styles = ({ breakpoints, spacing, palette }: Theme) => ({
  imagesContainer: {
    marginTop: 15,
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
    borderRadius: 8,
    padding: 8,
    marginTop: 2,
    height: 81,
  },
  image: {
    maxWidth: 86,
    maxHeight: 65,
  },
});

export const SelectCoverImage = ({
  elements,
  setCover,
  coverIndex,
}: {
  elements: Story[];
  coverIndex: { elementIndex: number; imageIndex: number };
  setCover: (coverIndex: { elementIndex: number; imageIndex: number }) => void;
}) => {
  const classes = useClasses(styles);
  return (
    <Grid container className={classes.imagesContainer}>
      {elements.reduce((prev, element, index) => {
        if (element.elementType === STORY_ELEMENT_TYPE.GALLERY || element.elementType === STORY_ELEMENT_TYPE.VIDEO) {
          const images = element.images?.map((image, imageIndex) => (
            <Grid
              key={index}
              item
              xs={12}
              className={classes.imageContainer}
              onClick={() => setCover({ elementIndex: index, imageIndex })}
            >
              <img className={classes.image} src={image.dataUrl} alt='' key={image.url + '12'} />
              <Checkbox
                color='primary'
                checked={image.isCover}
                inputProps={{ 'aria-label': 'controlled' }}
                icon={<RadioButtonUnchecked />}
                checkedIcon={<RadioButtonChecked />}
                key={image.url}
              />
            </Grid>
          ));
          return [...prev, ...(images ?? [])];
        }
        return [...prev];
      }, [] as JSX.Element[])}
    </Grid>
  );
};
