import { queryClient } from 'components';
import { useEffect, useState } from 'react';
import { useCities } from 'services/profile/v2/useCities';
import { SelectOptions } from 'types';

export const useSearchInCountries = (chosenCountry: SelectOptions): { cities: SelectOptions[] } => {
  const { data: cities, isLoading } = useCities(chosenCountry?.value);
  const [filteredCities, setFilteredCities] = useState<SelectOptions[]>([]);

  useEffect(() => {
    queryClient.invalidateQueries('cities');
  }, [chosenCountry]);

  useEffect(() => {
    if (!isLoading && cities && cities.length) {
      setFilteredCities(cities?.map((i) => ({ label: i, value: i })) ?? []);
    }
  }, [cities, isLoading]);
  return { cities: filteredCities };
};
