import { LeanLayout } from 'components';
import { ComponentType } from 'react';
import { SocialUsernameForm } from './SocialUsernameForm';
import store from 'store2';
import { Theme, Typography } from '@mui/material';
import { useClasses } from 'utils/hooks/useClasses';
import { getTKey } from 'utils';
import { useTranslation } from 'react-i18next';
const tKey = getTKey('signin');
const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  formContainer: {
    margin: spacing(0, 'auto', 4),

    [breakpoints.up('md')]: {
      maxWidth: 588,
      padding: spacing(4, 12),
      backgroundColor: 'rgba(187, 134, 252, 0.08)',
    },
  },
  title: {
    marginBottom: spacing(1),
    fontWeight: 600,
    letterSpacing: '0.4px',
  },
  subtitle: {
    marginBottom: spacing(4),
    opacity: '0.8',
    letterSpacing: '0.2px',
  },
});

export const SocialUsernamePage: ComponentType = () => {
  const { t } = useTranslation();
  const classes = useClasses(styles);
  const user = {
    additionalUserInfo: {
      profile: {
        id: store('google_id') || store('apple_id'),
      },
    },
  };
  return (
    <LeanLayout>
      <div className={classes.formContainer}>
        <Typography variant='h4' align='center' className={classes.title}>
          {t(tKey('title'))}
        </Typography>
        <Typography variant='body2' align='center' className={classes.subtitle}>
          {t(tKey('description'))}
        </Typography>
        <SocialUsernameForm user={user} isApple={store('apple_id') !== null} isGoogle={store('google_id') !== null} />
      </div>
    </LeanLayout>
  );
};
