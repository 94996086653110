import { useQuery } from 'react-query';
import { axiosApiInstance as api } from 'utils';

export const checkUnreadReminders = async () => {
  const { data } = await api.get(`cars/reminders/read`);
  return data;
};

export const useCheckUnreadRemindersQuery = () => {
  return useQuery(['checkUnreadReminders'], () => checkUnreadReminders());
};
