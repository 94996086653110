import { useQuery } from 'react-query';
import { axiosApiInstance as api } from 'utils';

const userWarnsQuery = async (id: string) => {
  try {
    if(!id) return {};
    const { data } = await api.get(`/engineroom/users/${id}/warn`);
    return  data;
  } catch (err) {
    return { error: true };
  }
};

export const useUserWarnsQuery = (id: string)=> {
  return useQuery('getWarns', () => userWarnsQuery(id));
};
