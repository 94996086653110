import { FormControlLabel, Radio, RadioGroup, RadioProps } from '@mui/material';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useClasses } from 'utils/hooks/useClasses';

interface RadioSwitchProps {
  name: string;
  defaultValue: any;
  values: { value: string; label: string }[];
}

const styles = {
  radioGroup: {
    border: '1px solid rgba(255, 255, 255, 0.12)',
    display: 'inline-flex',
    borderRadius: 4,
    marginTop: 16,
  },
  formControlLabel: {
    position: 'relative',
    width: 48,
    height: 48,
    margin: 0,
    justifyContent: 'center',

    '& .MuiFormControlLabel-label': {
      fontWeight: 600,
      color: 'rgba(255, 255, 255, 0.6)',
      textTransform: 'lowercase',
    },
  },
};

const switchStyles = {
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    padding: 0,

    '& .MuiIconButton-label': {
      height: '100%',
    },

    '&.Mui-checked ~ .MuiFormControlLabel-label': {
      color: '#fff',
    },

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    borderRadius: 4,

    root: {
      '& .Mui-focusVisible &': {
        outline: '1px auto rgba(19, 124, 189, .6)',
        outlineOffset: 2,
      },
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: 'rgba(98, 0, 238, 0.12)',

    'input:hover ~ &': {
      backgroundColor: 'rgba(98, 0, 238, 0.12)',
    },
  },
};

function StyledSwitch(props: RadioProps) {
  const classes = useClasses(switchStyles);

  return (
    <Radio
      className={classes.root}
      disableRipple
      color='default'
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export const RadioSwitch: ComponentType<RadioSwitchProps> = ({ name, defaultValue, values }) => {
  const classes = useClasses(styles);
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <RadioGroup {...field} defaultValue={defaultValue || values[0].value} row className={classes.radioGroup}>
          {values.map(({ value, label }) => (
            <FormControlLabel
              key={value}
              value={value}
              control={<StyledSwitch />}
              label={label}
              className={classes.formControlLabel}
            />
          ))}
        </RadioGroup>
      )}
    />
  );
};
