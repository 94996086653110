import { Clipboard } from '@capacitor/clipboard';
import { Capacitor } from '@capacitor/core';

export const pasteText = async () => {
  if (Capacitor.isNativePlatform()) {
    const { type, value } = await Clipboard.read();
    if (type === 'text/plain') {
      return value;
    }
    return '';
  } else {
    const text = await navigator.clipboard.readText();
    return text;
  }
};
