import { Box, Tab, Tabs, Theme } from '@mui/material';
import { ComponentType, useContext, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { Settings } from '@mui/icons-material';
import { AutoLoadContent, InnerMobileHeader, SpinFeedLoaderMini } from 'components';
import { UserProfileCard } from 'components/v2';
import { EmptyStories } from 'features/stories-feed/components/EmptyStories';
import { StoriesList } from 'features/stories-feed/components/StoriesList';
import { PublicUserPageContext } from 'features/users/PublicUserPageContext';
import { useTranslation } from 'react-i18next';
import { useUserProfile } from 'services';
import { useStoriesLikedQuery } from 'services/stories/hooks/useStoriesLiked';
import { useUserStoriesQuery } from 'services/stories/hooks/useUsersStoriesQuery';
import { ProfileContext } from 'store/profile/store';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ palette, spacing, breakpoints }: Theme) => ({
  btnBack: {
    color: 'rgba(255, 255, 255, 0.87)',
  },
  container: {
    margin: spacing(2),
    flexDirection: 'row-reverse',
  },
  tabs: {
    marginTop: spacing(3.25),
    width: '100%',
    '& .MuiTab-root': {
      color: palette.text.hint,
      fontSize: 14,
      fontWeight: 600,
      padding: spacing(1.75, 1.5),
      letterSpacing: '1.25px',
      lineHeight: '16px',
      width: '50%',
    },
    '& .Mui-selected': {
      color: palette.text.primary,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#6200EE',
    },
  },
  vehicles: {
    marginTop: spacing(0),
  },
  root: {
    margin: '0 auto',
    width: '37vw',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
    [breakpoints.up('sm')]: {
      minWidth: 532,
    },
  },
});

export type ProfileTabType = 'stories' | 'liked';

export const ProfileTab: ComponentType = () => {
  const { data: profile } = useUserProfile();
  const classes = useClasses(styles);
  const { t } = useTranslation();
  useEffect(() => {
    //@ts-ignore
    gtag?.('event', 'visited_profile', { method: 'visited_profile' });
  }, []);

  const { state } = useContext(ProfileContext);

  const {
    data: stories,
    isLoading: isLoadingStories,
    isFetchingNextPage: isFetchingNextPageStories,
    hasNextPage: hasNextPageStories,
    fetchNextPage: fetchNextPageStories,
  } = useUserStoriesQuery(state.id);

  const {
    data: storiesLiked,
    isLoading: isLoadingLiked,
    isFetchingNextPage: isFetchingNextPageLiked,
    fetchNextPage: fetchNextPageLiked,
    hasNextPage: hasNextPageLiked,
  } = useStoriesLikedQuery();

  const [tab, setTab] = useState<ProfileTabType>('stories');

  const handleTab = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue as ProfileTabType);
  };

  const { Provider } = PublicUserPageContext;

  return (
    <>
      <InnerMobileHeader
        rightIcon={
          <NavLink to={'/settings/setProfile'} className={classes.btnBack}>
            <Settings />
          </NavLink>
        }
      />
      <div className={classes.root}>
        <Box className={classes.container} sx={{ display: { xs: 'none', md: 'flex' } }}>
          <NavLink to='/settings/setProfile' state={{ from: 'profileTab' }} className={classes.btnBack}>
            <Settings />
          </NavLink>
        </Box>
        <UserProfileCard username={profile?.username ?? 'username'} />

        <Tabs variant='scrollable' value={tab} onChange={handleTab} aria-label='profile tabs' className={classes.tabs}>
          <Tab LinkComponent={Link} value={'stories'} label={t('user_page_tab_title_stories')} />
          <Tab LinkComponent={Link} value={'liked'} label={t('user_page_tab_title_liked')} />
        </Tabs>
        {tab === 'stories' && (
          <Provider value={{ isPublicUserPage: true }}>
            {stories?.pages[0]?.stories?.length !== 0 ? (
              stories?.pages.map((page, i) => {
                return (
                  <div key={i}>
                    <StoriesList stories={page.stories} authorized={true} />
                  </div>
                );
              })
            ) : (
              <EmptyStories />
            )}
            <SpinFeedLoaderMini loading={isFetchingNextPageStories || isLoadingStories} />
            {hasNextPageStories && (
              <AutoLoadContent loading={isFetchingNextPageStories} onScrollToBottom={fetchNextPageStories} />
            )}
          </Provider>
        )}
        {tab === 'liked' && (
          <>
            {storiesLiked?.pages[0]?.stories?.length !== 0 ? (
              storiesLiked?.pages.map((page, i) => {
                return (
                  <div key={i}>
                    <StoriesList stories={page.stories} authorized={true} />
                  </div>
                );
              })
            ) : (
              <EmptyStories />
            )}
            <SpinFeedLoaderMini loading={isFetchingNextPageLiked || isLoadingLiked} />
            {hasNextPageLiked && (
              <AutoLoadContent loading={isFetchingNextPageLiked} onScrollToBottom={fetchNextPageLiked} />
            )}
          </>
        )}
      </div>
    </>
  );
};
