import { Theme } from "@mui/material";
import { ComponentType, useState } from "react";
import { useTranslation } from "react-i18next";
import { useClasses } from "utils/hooks/useClasses";
import { IItem, ItemActionType } from "./AdminReview/AdminItemTypes";
import dayjs from "dayjs";
import clsx from "clsx";
import { ConfirmationDialog, StyledButton } from "components";
import { useAdminCommentModerate, useAdminStoryModerate, useAdminWarnBanUser, useDetailsItem } from "../hooks";
import { ItemHiddenDialog, UserBanDialog, UserWarnDialog } from "./Dialogs";
import { ItemDetailsDialog } from "./Dialogs/ItemDetailsDialog";
import { getTKey } from "utils";
const tKey = getTKey('admin_content_list');
const styles = ({palette, spacing}: Theme) => ({
    header: {
        color: palette.text.hint,
        display: "flex",
        flexDirection: "row",
        padding: spacing(1, 2, 1),
        textTransform: 'uppercase'
    },
    contentRow: {
        display: "flex",
        flexDirection: "row",
        background: '#202020',
        border: '1px solid rgba(255, 255, 255, 0.06)',
        borderRadius: 4,
        padding: spacing(2.5, 2),
        margin: spacing(0.5, 0),
        alignItems: 'center',
    },
    contentColumn: {
        width: '38%',
        margin: '0 1%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    createdColumn: {
        width: '13%',
        margin: '0 1%'
    },
    reportsColumn: {
        width: '13%',
        margin: '0 1%'
    },
    actionColumn: {
        width: '28%',
        margin: '0 1%',
        display: "flex",
        flexDirection: "row",
        justifyContent: 'end',
    },
    contentTitle:{
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    hintCell: {
        color: palette.text.hint,
    },
    hideButton:{
        background: '#FF5050',
    },
    actionButton: {
        padding: spacing(0.5, 1.5),
        margin: spacing(0, 0.5),
        textTransform: 'none'
    },
    detailsButton: {
        background: '#C4C4C4',
    }
})

interface ContentListType {
    items: IItem[],
    refreshItems: () => void
}
export const ContentList: ComponentType<ContentListType> = ({items, refreshItems}) => {
    const { t } = useTranslation();
    const classes = useClasses(styles);
    const {
        onBan, 
        onWarn,
        onBanOpenDialog,
        openBanUserDialog,
        openHiddenDialog,
        setOpenHiddenDialog,
        hiddenType,
        setHiddenType,
        setHiddenUser,
        openWarnUserDialog,
        onWarnOpenDialog,
        setHiddenItemId,
        onCloseBanDialog,
        onCloseWarnDialog,
        hiddenUser,
        setHiddenItem,
        hiddenItem,
        onOpenHiddenDialog
    } = useAdminWarnBanUser()

    const { approveStory } = useAdminStoryModerate()
    const { approveComment } = useAdminCommentModerate();

    const { 
        onCloseDetailsDialog,
        onOpenDetailsDialog,
        openDetailsDialog,
        currentItem,
        setCurrentItem
    } = useDetailsItem()

    const [openRestoreDialog, setOpenRestoreDialog] = useState<boolean>(false);
    const [restoreItem, setRestoreItem] = useState<IItem | null>(null);
    const [openApproveDialog, setOpenApproveDialog] = useState<boolean>(false);
    const [approveItem, setApproveItem] = useState<IItem | null>(null);

    const approve = async (item: IItem) => {
        if(item.type === 'story'){
            await approveStory(item.id);
        }
        if(item.type === 'comment'){
            await approveComment(item.id);
        }
        await refreshItems();
    }

    const handleApprove = (item: IItem) => {
        setApproveItem(item)
        setOpenApproveDialog(true)
    }

    const handleHide = async (item: IItem) => {
        setHiddenUser(item.user_id);
        setHiddenItemId(item.id);
        setHiddenItem(item);
        if(item.type === 'story') setHiddenType('creating_story');
        if(item.type === 'comment') setHiddenType('commenting');
        onOpenHiddenDialog(item);
    }

    const handleRestore = async (item: IItem) => {
        setRestoreItem(item);
        setOpenRestoreDialog(true);
    }
    const handleDetails = (item: IItem) => {
        setCurrentItem(item);
        onOpenDetailsDialog();
    }

    const onBanUser = async (ban_from: ItemActionType, duration: string, note: string ) => {
        await onBan(ban_from, duration, note);
        await refreshItems();
    }
    const onWarnUser = async(note: string ) => {
        await onWarn(note);
        await refreshItems();
    }

    const onRestoreItem = async () => {
        if(restoreItem)
        await approve(restoreItem);
        setOpenRestoreDialog(false);
    }

    const onApproveItem = async () => {
        if(approveItem)
        await approve(approveItem);
        setOpenApproveDialog(false);
    }


    return (
        <div>
            <div className={classes.header}>
                <div className={classes.contentColumn}>{t(tKey('header_content'))}</div>
                <div className={classes.createdColumn}>{t(tKey('header_created'))}</div>
                <div className={classes.reportsColumn}>{t(tKey('header_reports'))}</div>
                <div className={classes.actionColumn}></div>
            </div>
            {items.map((item)=>{
                return (
                    <div key={item.id} className={classes.contentRow}>
                        <div className={clsx(classes.contentColumn, classes.contentTitle)} onClick={()=>handleDetails(item)}>{item.title ?? item.text}</div>
                        <div className={clsx(classes.createdColumn, classes.hintCell)}>{dayjs(item.date_created).format('DD-MM-YYYY')}</div>
                        <div className={clsx(classes.reportsColumn, classes.hintCell)}>{item.reports_count}</div>
                        <div className={classes.actionColumn}>
                            
                            {(item.is_approved === false && item.is_hidden === false) && 
                                <StyledButton 
                                    label={t('admin_content_list_action_approve')} 
                                    color='secondary' 
                                    className={clsx(classes.actionButton)}
                                    onClick={()=>handleApprove(item)}
                                    />
                            }
                            {((item.is_approved === true && item.is_hidden === false) 
                            || (item.is_approved === false && item.is_hidden === false)) && 
                                <StyledButton 
                                    label={t('admin_content_list_action_hide')} 
                                    className={clsx(classes.actionButton, classes.hideButton)} 
                                    onClick={()=>handleHide(item)}
                                    />
                            }
                            {item.is_hidden === true && 
                                <StyledButton 
                                label={t('admin_content_list_action_restore')} 
                                color='secondary' 
                                className={clsx(classes.actionButton)}
                                onClick={()=>handleRestore(item)}
                                />
                            }
                            {(item.is_approved === true || item.is_hidden === true) && 
                                <StyledButton 
                                label={t('admin_content_list_action_details')} 
                                className={clsx(classes.actionButton, classes.detailsButton)}
                                onClick={()=>handleDetails(item)}
                                />
                            }
                        </div>
                    </div>
                )
            })}
            {hiddenUser && 
            <ItemHiddenDialog
                open={openHiddenDialog}
                onClose={() => setOpenHiddenDialog(false)} 
                onBan={onBanOpenDialog}
                onWarn={onWarnOpenDialog}
                reason={hiddenType}
                userId={hiddenUser}
                user={hiddenItem?.user}
            />}
            <UserBanDialog
                onClose={onCloseBanDialog}
                onConfirm={onBanUser}
                open={openBanUserDialog}
            />
            <UserWarnDialog
                onClose={onCloseWarnDialog}
                onConfirm={onWarnUser}
                open={openWarnUserDialog}
            />
            <ItemDetailsDialog
                open={openDetailsDialog}
                onClose={onCloseDetailsDialog}
                item={currentItem}
                onHide={handleHide}
                onRestore={handleRestore}
            />
            <ConfirmationDialog
                title={t(`admin_restore_dialog_title_${restoreItem?.type ?? 'story'}`)}
                description={t('admin_restore_dialog_description')+("\n"+(restoreItem?.title ?? restoreItem?.text))}
                open={openRestoreDialog}
                onConfirm={onRestoreItem}
                onClose={()=>setOpenRestoreDialog(false)}
                confirmText={t('__common__confirm')}
            />
            <ConfirmationDialog
                title={t(`admin_approve_dialog_title_${approveItem?.type ?? 'story'}`)}
                description={t('admin_approve_dialog_description')+("\n"+(approveItem?.title ?? approveItem?.text))}
                open={openApproveDialog}
                onConfirm={onApproveItem}
                onClose={()=>setOpenApproveDialog(false)}
                confirmText={t('__common__confirm')}
            />
        </div>
    )
}