import { ComponentType, useEffect, useState } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { useClasses } from 'utils/hooks/useClasses';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import clsx from 'clsx';
import { getTKey } from 'utils';
import { useTranslation } from 'react-i18next';
const tKey = getTKey('assistance_search_bar');
const styles = ({ spacing, breakpoints, palette }: Theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    [breakpoints.up('md')]: {
      maxWidth: 643,
      margin: '0 auto',
    },
    [breakpoints.down('md')]: {
      minHeight: 64,
      // padding: spacing(1, 2, 2, 2),
    },
    [breakpoints.up('md')]: {
      minHeight: 88,
      // padding: spacing(2),
    },
  },
  main: {
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#2E2E2E',
    border: '1px solid #FFFFFF1A',
    padding: spacing(1, 2),
    [breakpoints.up('md')]: {
      maxWidth: 643,
      margin: '0 auto',
    },
  },
  buttonGroup: {
    display: 'flex',
  },
  button: {
    width: 36,
    height: 36,
    border: '0.5px solid #FFFFFF26',
    borderRadius: spacing(1),
    background: '#252525',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: spacing(1),
    '& svg': {
      color: '#FFFFFF73',
      width: 20,
    },
  },
  buttonActive: {
    background: '#2A2A2A',
    boxShadow:
      '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 3px 14px 2px rgba(0, 0, 0, 0.12),0px 8px 10px 1px rgba(0, 0, 0, 0.14);',
    '& svg': {
      color: '#FFFFFF',
    },
    transition: 'transform 0.1s ease, box-shadow 0.2s ease',
    '&:hover': {
      transform: 'scale(1.02)', // Slightly larger on hover
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4), 0px 3px 10px rgba(255, 255, 255, 0.2)', // Increase shadow for a fancier effect
    },
  },
  resultText: {
    color: '#FFFFFF73',
  },
});

interface AssistantSearchBarControlProps {
  total: number;
  onChangeCurrent: (current: number) => void;
}

export const AssistantSearchBarControl: ComponentType<AssistantSearchBarControlProps> = ({
  total,
  onChangeCurrent,
}) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();

  const [current, setCurrent] = useState(1);

  useEffect(() => {
    if (total > 0) {
      setCurrent(1);
    }
  }, [total]);

  const handlePrevious = () => {
    setCurrent((state: number) => {
      if (state === 1) {
        return 1;
      } else if (state <= total) {
        onChangeCurrent(state - 1);
        return state - 1;
      }
      return state;
    });
  };
  const handleNext = () => {
    setCurrent((state: number) => {
      if (state === total) {
        return total;
      } else if (state < total) {
        onChangeCurrent(state + 1);
        return state + 1;
      }
      return state;
    });
  };
  return (
    <div className={classes.root}>
      <Box className={classes.main}>
        <Box className={classes.resultText}>
          {total > 0 ? (
            <Typography>
              {current} {t(tKey('of'))} {total}
            </Typography>
          ) : (
            <Typography>{t(tKey('no_results'))}</Typography>
          )}
        </Box>
        <Box className={classes.buttonGroup}>
          <Box
            className={clsx(classes.button, total > 0 && current !== 1 && classes.buttonActive)}
            onClick={handlePrevious}
          >
            <ExpandMore />
          </Box>
          <Box
            className={clsx(classes.button, total > 0 && current < total && classes.buttonActive)}
            onClick={handleNext}
          >
            <ExpandLess />
          </Box>
        </Box>
      </Box>
    </div>
  );
};
