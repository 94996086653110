import { Box, Divider, FormControl, Grid, Theme, Typography } from '@mui/material';
import { ComponentType, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ArrowForward } from '@mui/icons-material';
import { InnerMobileHeader, queryClient } from 'components';
import { ModalSearchMultiSelect } from 'components/Form/ModalSearchMultiSelect';
import { ModalSearchSelect } from 'components/Form/ModalSearchSelect';
import { languageName, languageOptions, useChangeLanguage } from 'lib';
import {
  useChangeDonotTranslateLanguageMutation,
  useChangeProfileLanguageMutation,
  useProfileInfoQuery,
} from 'services';
import { useGetDoNotTranslate } from 'services/profile/hooks/useGetDoNotTranslate';
import { getTKey, googleLanguages } from 'utils';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ spacing }: Theme): { [key: string]: React.CSSProperties } => ({
  menuItem: {
    fontSize: 16,
    fontWeight: '500',
  },
  menuItemSub: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 12,
    fontWeight: '400',
  },
  menuContainer: {
    display: 'flex',
    color: 'rgba(255, 255, 255, 0.87)',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: spacing(2, 0),
    cursor: 'pointer',
  },
  divider: {
    margin: spacing(2, 0),
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
  },
  form: {
    width: '100%',
  },
});

const tKey = getTKey('settings_language');
export let chosenLang = localStorage.getItem('driverbook_language') || '';
export const LanguageTab: ComponentType = () => {
  const { t } = useTranslation();
  const { chosenLanguage, changeLanguage } = useChangeLanguage();
  const { data } = useProfileInfoQuery();
  const { data: languages, isLoading } = useGetDoNotTranslate();
  const mutation = useChangeProfileLanguageMutation();
  const languageDonotTranslateMutation = useChangeDonotTranslateLanguageMutation();
  const [initialLanguage, setInitialLanguage] = useState(data?.language);
  const classes = useClasses(styles);
  const [open, setOpen] = useState(false);
  const [openDontTranslate, setOpenDontTranslate] = useState(false);
 
  const onChangeLanguage = (data: any) => {
    chosenLang = data.target.value ;
    if (initialLanguage && initialLanguage !== chosenLang) {
      mutation.mutate(
        { language: chosenLang },
        {
          onSuccess() {
            changeLanguage(data);
            setInitialLanguage(chosenLang);
            toast(t('settings_profile_success_message'));
          },
        }
      );
    }
  };

  const changeDontTranslateLanguage = (values: string[]) => {
    languageDonotTranslateMutation.mutate(values, {
      onSuccess() {
        toast(t('settings_profile_success_message'));
        queryClient.invalidateQueries('doNotTranslate');
      },
    });
  };

  const doNotTranslateLanguages = useMemo(
    () =>
      languages?.reduce((prev: any, curr: any) => {
        const label = googleLanguages().find((choice: any) => choice.value === curr.language)?.label;
        if (prev.length === 0 && label) {
          return label;
        } else if (label) {
          return prev + ', ' + label;
        } else return prev;
      }, '' as string),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [languages, chosenLang]
  );

  return (
    <>
      {open && (
        <ModalSearchSelect
          open={open}
          onClose={() => setOpen(false)}
          title={t(tKey('select_language'))}
          name='language'
          defaultValue={chosenLanguage}
          onChange={onChangeLanguage}
          options={languageOptions}
        />
      )}
      {!isLoading && openDontTranslate && (
        <ModalSearchMultiSelect
          open={openDontTranslate}
          onClose={() => setOpenDontTranslate(false)}
          title={t(tKey('select_languages'))}
          name='language'
          defaultValue={languages.map((language: any) => language.language)}
          onChange={changeDontTranslateLanguage}
          options={googleLanguages()}
        />
      )}
      <InnerMobileHeader title={t(tKey('title'))} showBack />
      <FormControl component='fieldset' className={classes.form}>
        <Grid item xs={12}>
          <Box className={classes.menuContainer} onClick={() => setOpen(true)}>
            <Box>
              <Typography className={classes.menuItem}>{t(tKey('content_language'))}</Typography>
              <Typography className={classes.menuItemSub}>{languageName[chosenLanguage]}</Typography>
            </Box>
            <ArrowForward width={16} height={16} />
          </Box>
          <Divider className={classes.divider} />
          {!isLoading && (
            <>
              <Box className={classes.menuContainer} onClick={() => setOpenDontTranslate(true)}>
                <Box>
                  <Typography className={classes.menuItem}>{t(tKey('dont_translate'))}</Typography>
                  <Typography className={classes.menuItemSub}>{doNotTranslateLanguages}</Typography>
                </Box>
                <ArrowForward width={16} height={16} />
              </Box>
              <Divider className={classes.divider} />
            </>
          )}
        </Grid>
      </FormControl>
    </>
  );
};
