import React from 'react';

export const usePwa = () => {
  const [status, setStatus] = React.useState(false);

  React.useEffect(() => {
    // @ts-ignore
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone) {
      setStatus(true);
    }
  }, [status]);

  return status;
};
