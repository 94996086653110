import { ComponentType } from 'react';
import { Theme } from '@mui/material';
import { useClasses } from 'utils/hooks/useClasses';
import { IItem } from './AdminItemTypes';
import store from 'store2';
import { AdminItemLikes } from './AdminItemLikes';

enum translateTextProp {
  'en' = 'en_text',
  'es' = 'es_text',
  'uk' = 'uk_text',
}

const styles = ({ palette, spacing }: Theme) => ({
  root: {
    padding: spacing(3),
    overflowY: 'scroll',
  },
});

interface AdminStoryCommentType {
  comment: IItem;
  showTranslate: boolean;
}

export const AdminCommentContent: ComponentType<AdminStoryCommentType> = ({ comment, showTranslate }) => {
  const classes = useClasses(styles);
  const storedLanguage: 'en' | 'es' | 'uk' = store('driverbook_language') ?? 'en';

  return (
    <div className={classes.root}>
      <div>{showTranslate ? comment[translateTextProp[storedLanguage]] : comment.text}</div>
      <AdminItemLikes item={comment}></AdminItemLikes>
    </div>
  );
};
