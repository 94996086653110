import { useMutation } from 'react-query';

import { axiosApiInstance as api } from 'utils';

const blockUser = async (id: string): Promise<any> => {
  try {
    const { data } = await api.post(`/users/${id}/block`);
    return data;
  } catch (error) {
    return { error: true };
  }
};

export const useBlockUserMutation = () => {
  return useMutation(blockUser);
};
