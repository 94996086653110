import { Box, Grid, Switch, Theme, Typography } from '@mui/material';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserNotificationsMutation } from 'services';
import { UserNotifications } from 'types';
import { useClasses } from 'utils/hooks/useClasses';

const styles = ({ palette, spacing }: Theme) => ({
  toggleContainer: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
});

interface NotificationsFormType {
  notificationsData: UserNotifications;
}

export const NotificationsForm: ComponentType<NotificationsFormType> = ({ notificationsData }) => {
  const classes = useClasses(styles);
  const { t } = useTranslation();
  const [notificationsSettings, setNotificationsSettings] = useState(notificationsData);
  const mutation = useUserNotificationsMutation();

  const handleToggleNotification = (name: string, checked: boolean) => {
    mutation.mutate(
      { [name]: checked },
      {
        onSuccess(data, variables, context) {
          setNotificationsSettings({ ...notificationsSettings, [name]: checked });
        },
      }
    );
  };


  return (
    <>
      <Grid item xs={12} mt={4} className={classes.toggleContainer}>
        <Box>
          <Typography>{t('settings_notifications_story_got_liked')}</Typography>
        </Box>
        <Switch
          color='primary'
          checked={notificationsSettings.story_got_liked}
          onChange={(event, checked) => handleToggleNotification('story_got_liked', checked)}
        />
      </Grid>
      <Grid item xs={12} mt={4} className={classes.toggleContainer}>
        <Box>
          <Typography>{t('settings_notifications_comment_got_liked')}</Typography>
        </Box>
        <Switch
          color='primary'
          checked={notificationsSettings.comment_got_liked}
          onChange={(event, checked) => handleToggleNotification('comment_got_liked', checked)}
        />
      </Grid>
      <Grid item xs={12} mt={4} className={classes.toggleContainer}>
        <Box>
          <Typography>{t('settings_notifications_story_got_disliked')}</Typography>
        </Box>
        <Switch
          color='primary'
          checked={notificationsSettings.story_got_disliked}
          onChange={(event, checked) => handleToggleNotification('story_got_disliked', checked)}
        />
      </Grid>
      <Grid item xs={12} mt={4} className={classes.toggleContainer}>
        <Box>
          <Typography>{t('settings_notifications_comment_got_disliked')}</Typography>
        </Box>
        <Switch
          color='primary'
          checked={notificationsSettings.comment_got_disliked}
          onChange={(event, checked) => handleToggleNotification('comment_got_disliked', checked)}
        />
      </Grid>
      <Grid item xs={12} mt={4} className={classes.toggleContainer}>
        <Box>
          <Typography>{t('settings_notifications_story_got_commented')}</Typography>
        </Box>
        <Switch
          color='primary'
          checked={notificationsSettings.story_got_commented}
          onChange={(event, checked) => handleToggleNotification('story_got_commented', checked)}
        />
      </Grid>
      <Grid item xs={12} mt={4} className={classes.toggleContainer}>
        <Box>
          <Typography>{t('settings_notifications_comment_got_commented')}</Typography>
        </Box>
        <Switch
          color='primary'
          checked={notificationsSettings.comment_got_commented}
          onChange={(event, checked) => handleToggleNotification('comment_got_commented', checked)}
        />
      </Grid>
      <Grid item xs={12} mt={4} className={classes.toggleContainer}>
        <Box>
          <Typography>{t('settings_notifications_tagged_in_comment')}</Typography>
        </Box>
        <Switch
          color='primary'
          checked={notificationsSettings.tagged_in_comment}
          onChange={(event, checked) => handleToggleNotification('tagged_in_comment', checked)}
        />
      </Grid>
      <Grid item xs={12} mt={4} className={classes.toggleContainer}>
        <Box>
          <Typography>{t('settings_notifications_followed')}</Typography>
        </Box>
        <Switch
          color='primary'
          checked={notificationsSettings.followed}
          onChange={(event, checked) => handleToggleNotification('followed', checked)}
        />
      </Grid>
      <Grid item xs={12} mt={4} className={classes.toggleContainer}>
        <Box>
          <Typography>{t('settings_notifications_weekly_story')}</Typography>
        </Box>
        <Switch
          color='primary'
          checked={notificationsSettings.weekly_story}
          onChange={(event, checked) => handleToggleNotification('weekly_story', checked)}
        />
      </Grid>
      <Grid item xs={12} mt={4} className={classes.toggleContainer}>
        <Box>
          <Typography>{t('settings_notifications_system_story')}</Typography>
        </Box>
        <Switch
          color='primary'
          checked={notificationsSettings.system_story}
          onChange={(event, checked) => handleToggleNotification('system_story', checked)}
        />
      </Grid>
      <Grid item xs={12} mt={4} className={classes.toggleContainer}>
        <Box>
          <Typography>{t('settings_notifications_new_version')}</Typography>
        </Box>
        <Switch
          color='primary'
          checked={notificationsSettings.new_version}
          onChange={(event, checked) => handleToggleNotification('new_version', checked)}
        />
      </Grid>
    </>
  );
};
