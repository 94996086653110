import { ComponentType, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import store from 'store2';
import secureLocalStorage from 'react-secure-storage';

export const AdminLogout: ComponentType = () => {
  useEffect(() => {
    //@ts-ignore
    gtag?.('event', 'logout', { method: 'logout' });

    return () => {
      store.remove('accessToken');
      store.remove('refreshToken');
      store.remove('driverbook_google_user');
      store.remove('googleAccessToken');
      secureLocalStorage.removeItem('app_id_hash');
    };
  }, []);

  return <Navigate to='/secret/machineroom/login' />;
};
