import { useQuery, UseQueryResult } from 'react-query';

import { Car } from 'types';
import { axiosApiInstance as api } from 'utils';

import { createCarUI } from '../utils';

const getCarById = async (id: string): Promise<Car> => {
  const { data } = await api.get(`/cars/${id}`);

  return createCarUI(data);
};

export const useCarQuery = (id: string): UseQueryResult<Car> => {
  return useQuery(['cars', id], () => getCarById(id), { cacheTime: 0 });
};
